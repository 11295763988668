/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { useCallback, useEffect, useState } from "react"

export default function useProductKitsAndParts({ product, client }: any) {
  const [kitProducts, setKitProducts] = useState<any[]>([])
  const [partProducts, setPartProducts] = useState<any[]>([])

  const productAvailableQty = (handle: any) => {
    const qtyQuery = client.graphQLClient.query((root: any) => {
      root.add(
        "collectionByHandle",
        { args: { handle: `${handle}` } },
        (collecionQuery: any) => {
          collecionQuery.addConnection(
            "products",
            { args: { first: 50 } },
            (productQuery: any) => {
              productQuery.add("title")
              productQuery.add("handle")
              productQuery.add("description")
              productQuery.add("descriptionHtml")
              productQuery.add("id")
              productQuery.add("totalInventory")
              productQuery.addConnection(
                "metafields",
                { args: { first: 20 } },
                (metafield: any) => {
                  metafield.add("key")
                  metafield.add("value")
                },
              )
              productQuery.addConnection(
                "variants",
                { args: { first: 30 } },
                (variantQuery: any) => {
                  variantQuery.add("id")
                  variantQuery.add("sku")
                  variantQuery.add("title")
                  variantQuery.add("availableForSale")
                  variantQuery.add("quantityAvailable")
                  variantQuery.add("currentlyNotInStock")
                  variantQuery.addConnection(
                    "metafields",
                    { args: { first: 6 } },
                    (metafield: any) => {
                      metafield.add("key")
                      metafield.add("value")
                    },
                  )
                },
              )
            },
          )
        },
      )
    })

    return client.graphQLClient
      .send(qtyQuery)
      .then((res: any) =>
        JSON.parse(JSON.stringify(res.model.collectionByHandle)),
      )
      .catch(() => null)
  }

  const getProductAvailableQty = async () => {
    let kitsCollectionId
    let partsCollectionId
    product.metafields.forEach((metafield: any) => {
      if (metafield.key === "custom-kits-collection") {
        kitsCollectionId = metafield.value
      } else if (metafield.key === "custom-parts-collection") {
        partsCollectionId = metafield.value
      }
    })
    const kits = await productAvailableQty(kitsCollectionId)
    const parts = await productAvailableQty(partsCollectionId)
    if (kits) {
      setKitProducts(kits.products)
    }
    if (kits) {
      setPartProducts(parts.products)
    }
  }

  useEffect(() => {
    getProductAvailableQty()
  }, [])

  const addInvetoryQuantityFromClientSide = (
    serverData: any,
    clientData: any,
  ) =>
    serverData.map((item: any) => {
      const { variants } = item
      if (variants) {
        let newVariants = [...variants]
        const find = clientData?.find((p: any) => p.handle === item.handle)
        let { totalInventory } = item
        if (find?.variants) {
          totalInventory = find.totalInventory
          newVariants = variants.map((variant: any, index: number) => ({
            ...variant,
            inventoryQuantity: find.variants[index]?.quantityAvailable,
            availableForSale: find.variants[index]?.availableForSale,
            metafields: variant.metafields.map((metafield: any) => {
              const findMetafield = find.variants[index]?.metafields.find(
                (m: any) => m.key === metafield.key,
              )
              if (findMetafield) {
                return findMetafield
              }
              return metafield
            }),
          }))
        }

        return {
          ...item,
          totalInventory,
          variants: newVariants,
        }
      }
      return item
    })

  const addClientDataKit = useCallback(
    (serverData: any) =>
      addInvetoryQuantityFromClientSide(serverData, kitProducts),
    [kitProducts],
  )
  const addClientDataPart = useCallback(
    (serverData: any) =>
      addInvetoryQuantityFromClientSide(serverData, partProducts),
    [partProducts],
  )

  return {
    kitProducts,
    partProducts,
    addClientDataKit,
    addClientDataPart,
  }
}
