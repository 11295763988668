import React, { useEffect, useState, useContext } from "react"
import PropTypes from "prop-types"
import { LiteChaserContext } from "../../context/LiteChaserContext"
import { capitalizeFirstLetter } from "../../utils/stringFormatter"
import Tooltip from "../Tooltip"

const LCPrimarySection = ({
  disable,
  heading,
  constraintList,
  selectedConstraint,
  constraintSetter,
  itemNums,
  highLight,
  version,
  tooltipText,
  setImageIndex,
  kitPrices,
}) => (
  <div className="flex flex-col w-full">
    <div className="flex flex-row">
      <h3 className="ml-2 font-sans font-bold font-condensed mt-8 mb-4 lc-primarysection-heading">
        Select {heading}
        &#58;
      </h3>
      <div className="pt-[0.2rem] pl-1 hidden primary-tooltip">
        <Tooltip tooltipText={tooltipText} />
      </div>
    </div>
    <div className="flex flex-row justify-between w-full lc-primary-row">
      <div
        className={`h-full w-[100px] m-2 lc-14-primary-image hidden ${heading}`}
      >
        <img
          src=""
          alt="phoneModels"
          className="flex w-[100px] w-full border-r-[1px] border-solid border-black pr-2 pb-2 top-0 "
        />
      </div>
      {version.includes("14") ? (
        <div className="lc-primary-innerrow">
          {constraintList.map((item, index) => {
            // Render primary section buttons, such as, Phone Model and Kit buttons
            const {
              setSelectedCase,
              selectedKit,
              setTotalPrice,
              setKitIncludesSet,
            } = useContext(LiteChaserContext)

            const [itemName] = useState(
              capitalizeFirstLetter(item.split("-").join(" ")),
            )
            const [bgColor, setBgColor] = useState("border-lc-black")

            // Unless a specific highlighted color is given, default to lc-black
            useEffect(() => {
              if (item !== selectedConstraint) {
                setBgColor("border-lc-black")
              } else {
                setBgColor(`bg-${highLight} border-${highLight} text-white`)
              }
            })

            // On select, set constraint to selected name, such as, filmmaking kit
            const selectPrimarySection = e => {
              e.preventDefault()

              const selectorName = item.toLowerCase()

              setImageIndex(index)

              if (
                (selectorName.includes("kit") &&
                  selectorName.includes("custom")) ||
                selectedKit.includes("custom")
              ) {
                setSelectedCase("")
                setTotalPrice(0)
                setKitIncludesSet(new Set())
              } else if (selectorName.includes("kit")) {
                const initialColor = "BLACK"
                setSelectedCase(initialColor)
              }
              constraintSetter(item)
            }

            return (
              <button
                className={`w-1/${
                  itemNums < 4 ? itemNums : 4
                } h-[60px] font-bold font-condensed border-[1px] text-sm m-2 pt-3 pb-2 px-4 uppercase ${bgColor} ${
                  disable ? "opacity-50" : ""
                } lc-primaryButton`}
                type="button"
                id={item}
                key={item}
                onClick={selectPrimarySection}
                disabled={disable}
              >
                <span>{itemName}</span>
                {kitPrices ? (
                  <span className="text-xs font-condensed secondProductTitle">
                    {kitPrices[index]}
                  </span>
                ) : (
                  ""
                )}
              </button>
            )
          })}
        </div>
      ) : (
        constraintList.map(item => {
          // Render primary section buttons, such as, Phone Model and Kit buttons
          const {
            setSelectedCase,
            selectedKit,
            setTotalPrice,
            setKitIncludesSet,
          } = useContext(LiteChaserContext)

          const [itemName] = useState(
            capitalizeFirstLetter(item.split("-").join(" ")),
          )
          const [bgColor, setBgColor] = useState("border-lc-black")

          // Unless a specific highlighted color is given, default to lc-black
          useEffect(() => {
            if (item !== selectedConstraint) {
              setBgColor("border-lc-black")
            } else {
              setBgColor(`bg-${highLight} border-${highLight} text-white`)
            }
          })

          // On select, set constraint to selected name, such as, filmmaking kit
          const selectPrimarySection = e => {
            e.preventDefault()

            const selectorName = item.toLowerCase()

            if (
              (selectorName.includes("kit") &&
                selectorName.includes("custom")) ||
              selectedKit.includes("custom")
            ) {
              setSelectedCase("")
              setTotalPrice(0)
              setKitIncludesSet(new Set())
            } else if (selectorName.includes("kit")) {
              const initialColor = "BLACK"
              setSelectedCase(initialColor)
            }
            constraintSetter(item)
          }

          return (
            <button
              className={`w-1/${
                itemNums < 4 ? itemNums : 4
              } h-[60px] font-bold font-condensed border-[1px] text-sm m-2 pt-3 pb-2 px-4 uppercase ${bgColor} ${
                disable ? "opacity-50" : ""
              } lc-primaryButton`}
              type="button"
              id={item}
              key={item}
              onClick={selectPrimarySection}
              disabled={disable}
            >
              {itemName}
            </button>
          )
        })
      )}
    </div>
  </div>
)

export default LCPrimarySection

LCPrimarySection.propTypes = {
  disable: PropTypes.bool,
  heading: PropTypes.string,
  constraintList: PropTypes.arrayOf(PropTypes.string),
  selectedConstraint: PropTypes.string,
  constraintSetter: PropTypes.func,
  itemNums: PropTypes.number,
  highLight: PropTypes.string,
}

LCPrimarySection.defaultProps = {
  disable: false,
  heading: "Set Heading",
  constraintList: ["N/A", "N/A"],
  selectedConstraint: "N/A",
  constraintSetter: () => {},
  itemNums: 2,
  highLight: "paloverde",
}
