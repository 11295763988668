import React from "react"

const PlayIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="220"
    height="220"
    viewBox="0 0 291 291"
    className="m-auto"
  >
    <g
      data-name="Play Icon"
      opacity="0.4"
      transform="rotate(90 1355.35 671.35)"
    >
      <g
        fill="none"
        stroke="#fff"
        strokeWidth="4"
        transform="translate(684 1735.7)"
      >
        <circle cx="145.5" cy="145.5" r="145.5" stroke="none" />
        <circle cx="145.5" cy="145.5" r="143.5" />
      </g>
      <path
        fill="#fff"
        d="M65.276 2.934a2 2 0 013.449 0l63.5 108.053A2 2 0 01130.5 114H3.5a2 2 0 01-1.724-3.013z"
        transform="translate(762 1812.7)"
      />
    </g>
  </svg>
)

export default PlayIcon
