/* eslint-disable */
import React, { useState } from "react"
import { Link } from "gatsby"
import "./support.scss"
import Seo from "../../components/seo"
import { useLocation } from "@reach/router"
import { FormspreeProvider } from "@formspree/react"
import countriesData from "../../data/countries.json"

const ResellerApplication = () => {
  const location = useLocation()
  const [isSuccess, setIsSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [showError, setshowError] = useState(false)
  const [isError, setIsError] = useState("")

  const [fields, setFields] = useState({
    name: "",
    email: "",
    company_name: "",
    country: "",
    business_type: "",
    company_website: "",
    resell_categories: "",
    question: "",
  })

  const handleInputChange = e => {
    setFields({ ...fields, business_type: e.target.value })
  }

  const handleResellChange = (e) => {
    const selectedValue = e.target.value;
    setFields({ ...fields, resell_categories: selectedValue });
  };

  const handleChangeCountry = e => {
    setFields({ ...fields, country: e.target.value })
  }

  const submitHandler = async event => {
    event.preventDefault()

    try {
      const formData = {
        name: fields.name,
        email: fields.email,
        company_name: fields.company_name,
        country: fields.country,
        business_type: fields.business_type,
        company_website: fields.company_website,
        resell_categories: fields.resell_categories,
        question: fields.question,
      }

      const response = await fetch(
        process.env.GATSBY_FORMSPREE_RESELLER_APPLICATION,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        },
      )

      if (response.ok) {
        setIsSuccess(true)
        setSuccessMessage(
          "Thank you very much for applying to be a PolarPro Reseller or Distributor. We will respond to your application within 1 business day.",
        )
      } else {
        setshowError(true)
        setIsError("Failed to submit form data")
      }
    } catch (error) {
      setshowError(true)
      setIsError("Error submitting form data:")
    }
  }

  return (
    <div id="reseller-application">
      <Seo title="Reseller Application" />
      <img
        src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/support-header-Image.jpg?v=144240260665312199781582676319"
        className="wideBanner w-full mb-[0px]"
      />
      <div class="mobile-only support-time">
        <p>Live Support Available M-F 9AM-5PM PST</p>
      </div>
      <div className="support-wrapper">
        <img
          src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/reseller-icon.png?v=91569854687158790421582586871"
          className=""
        />
        <p className="title-text">Reseller Application:</p>
        <p className="subtitle-text">
          We’re highly committed to the success of our dealers, to inquire about
          becoming an authorized PolarPro reseller or distributor please take a
          moment to fill out the following information. Upon completion a
          representative will be in contact within 2 business days
        </p>
      </div>

      <div>
        <FormspreeProvider
          project={process.env.GATSBY_FORMSPREE_RESELLER_APPLICATION}
        >
          <div className="w-full">
            <div className="bg-white py-12 w-full">
              <div className="w-full flex flex-col justify-center">
                <form
                  onSubmit={submitHandler}
                  action={process.env.GATSBY_FORMSPREE_RESELLER_APPLICATION}
                  method="POST"
                  className="flex flex-col gap-4 w-[40%] mx-auto"
                >
                  <div className="relative  flex justify-center  mb-1 pt-6">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs font-semibold">Name*</label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        name="name"
                        required
                        value={fields.name}
                        onChange={e =>
                          setFields({ ...fields, name: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="relative mb-1  flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs font-semibold">Email*</label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="email"
                        required
                        name="email"
                        value={fields.email}
                        onChange={e =>
                          setFields({ ...fields, email: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="relative mb-1  flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs font-semibold">Country*</label>
                      <select
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                        name="country"
                        value={fields.country}
                        onChange={handleChangeCountry}
                      >
                        <option value="" disabled>Select a country</option>
                        {countriesData.map((item, index) => (
                          <option key={index} value={item.country}>
                            {item.country}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="relative mb-1  flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs font-semibold">
                        Company Name:*
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        name="company_name"
                        required
                        value={fields.company_name}
                        onChange={e =>
                          setFields({ ...fields, company_name: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="relative mb-1 flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs font-semibold">
                        Business Type:*
                      </label>
                      <select
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        name="business_type"
                        value={fields.business_type}
                        onChange={handleInputChange}
                      >
                        <option value="Reseller">Reseller</option>
                        <option value="Distributor">Distributor</option>
                      </select>
                    </div>
                  </div>

                  <div className="relative mb-1  flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs font-semibold">
                        Company Website:*
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        name="company_website"
                        value={fields.company_website}
                        onChange={e =>
                          setFields({
                            ...fields,
                            company_website: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="relative mb-1 flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs font-semibold">
                        Resell Categories*
                      </label>
                      <select
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        name="resell_categories"
                        value={fields.resell_categories}
                        onChange={handleResellChange}
                      >
                        <option value="Drone">Drone</option>
                        <option value="Action Camera">Action Camera</option>
                        <option value="DSLR / Mirrorless">
                          DSLR / Mirrorless
                        </option>
                        <option value="Mobile">Mobile</option>
                        <option value="AllTheAbove">All the above</option>
                      </select>
                    </div>
                  </div>

                  <div className="relative mb-1  flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs font-semibold">
                        Do you sell on Amazon or Ebay?*
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        name="question"
                        value={fields.question}
                        onChange={e =>
                          setFields({ ...fields, question: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className=" text-white px-[150px] opacity-90 hover:opacity-100 transition-opacity py-4 font-sans  rounded-md mt-10 bg-paloverde font-bold"
                    >
                      Submit
                    </button>
                  </div>
                  {isSuccess && (
                    <p className="bg-green-400 rounded p-5 text-green-900">
                      {successMessage}
                    </p>
                  )}
                  {showError && (
                    <p className="bg-red-400 rounded p-5 text-red-900">
                      {isError}
                    </p>
                  )}
                </form>
              </div>
            </div>
          </div>
        </FormspreeProvider>
      </div>
      <div
        data-store-id="d8c33251-f8ab-40c1-aa86-2220d1eca2ba"
        data-form-id="ed106836-9728-4f13-8f41-c8ba8b02f462"
      />
      <div className="support-bottom-banner">
        <p>Still can't find what you're looking for?</p>

        <span>Let us Know!</span>
        <Link
          to={`/support/contact-us/${location.search ? location.search : ""}`}
        >
          Contact Us
        </Link>
      </div>
    </div>
  )
}

export default ResellerApplication
