// extracted by mini-css-extract-plugin
export var dataLeft = "style-module--dataLeft--086a6";
export var dataRight = "style-module--dataRight--223c9";
export var dynamicNewBanner = "style-module--dynamicNewBanner--dc86e";
export var dynamicNewBannerRight = "style-module--dynamicNewBannerRight--c5094";
export var helixMobilePlayBtn = "style-module--helixMobilePlayBtn--0af10";
export var helixOverview = "style-module--helixOverview--2d3bb";
export var introData = "style-module--introData--2be41";
export var introDataRight = "style-module--introDataRight--488c7";
export var leftStrike = "style-module--leftStrike--57208";
export var productHero = "style-module--productHero--3bd77";
export var sandBackg = "style-module--sandBackg--0a5e1";