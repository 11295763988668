/* eslint-disable */
import React, { useState, useEffect } from "react"
// import { Link } from "gatsby";
import loadable from "@loadable/component"
import * as style from "./style.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import { useStoryblok } from "../../utils/storyblok"
import DynamicComponents from "../DynamicComponents"
import useScrollBlock from "../../hooks/useScrollBlock"
import HelixVariantCart from "./helixVariantCart"

import PlayIcon from "../Icons/PlayIcon"

const ModalVideo = loadable(() => import("react-modal-video"))

const BelowLCP15Overview = ({
  product: variantProduct,
  dynamicATCProducts,
}) => {
  const [showVideo, setShowVideo] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()
  const [selectedTitle, setSelectedTitle] = useState(null)

  const scrollUp = e => {
    e.preventDefault()
    window.scrollTo({
      top: 0,
      behaviour: "smooth",
    })
  }

  useEffect(() => {
    if (showVideo) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [showVideo])

  const pageData = useStaticQuery(graphql`
    query {
      storyblokEntry(full_slug: { eq: "/products/iphone-15-case" }) {
        id
        name
        content
        slug
        full_slug
        uuid
        created_at
      }
      shopifyProduct(handle: { eq: "iphone-15-case" }) {
        handle
        title
      }
    }
  `)

  const product = pageData.shopifyProduct
  const story = pageData.storyblokEntry
    ? useStoryblok(pageData.storyblokEntry)
    : { content: null }

  const stickyNavComponent =
    story.content && story.content["sticky_nav"]
      ? story.content["sticky_nav"].map(blok => (
        <>
          {blok ? (
            <div {...sbEditable(blok)} className="sticky top-0 z-50">
              <DynamicComponents
                blok={blok}
                product={product}
                key={blok._uid}
              />
            </div>
          ) : null}
        </>
      ))
      : null

  return (
    <div className={`${style.lcp15Overview} overflow-hidden`}>
      <div class={`${style.productHero} text-white flex flex-col lg:flex-row`}>
        <div class={`${style.introData}`}>
          <div class="video-container">
            <a
              href="#data"
              id="inline"
              className={`${style.MobilePlayBtn} cta-round opacity-90 hover:opacity-100`}
              onClick={e => {
                e.preventDefault()
                setShowVideo(true)
              }}
            >
              <PlayIcon />
            </a>
          </div>
        </div>
        {/* modal */}
        <ModalVideo
          channel="youtube"
          videoId="_fPLWakRAzE?si=uzqdtKyCBJhK5cwI"
          isOpen={showVideo}
          onClose={() => setShowVideo(false)}
        />
      </div>
      <div className={`${style.breakBanner} bg-black py-28`}>
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Designed_To_Help_You_Tell_Your_Story_Title.png?v=1694105229"
          alt=""
        />
        <div className={`${style.dataRight} pl-5 hidden lg:block`}>
          <img
            class="w-4/5 m-0"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/QUICK_RELEASE_DEFENDER_PLATE_FOR_FULL_CAMERA_PROTECTION..png?v=1694039467"
            alt=""
          />
        </div>
      </div>

      <div className={`${style.sandBackg} feature-block max-w-full m-auto`}>
        <div className="flex items-center flex-col lg:flex-row">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black  lg:leading-[1.1rem] text-2xl lg:text-sm xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-white mb-2 lg:md-8">
              Full Lens Protection & Quick Release Mechanism
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-white">
              The Defender Plate was designed to fully protect your iPhone 15
              lenses when your phone is exposed to the elements while not
              shooting, such as mounted to handlebars. The quick release plate
              lets you pop it off quickly to get the shot.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-white m-0">
              The Defender Plate was designed to fully protect your iPhone 15
              lenses when your phone is exposed to the elements while not
              shooting, such as mounted to handlebars. The quick release plate
              lets you pop it off quickly to get the shot.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <video
              autoPlay={true}
              muted={true}
              loop={true}
              playsInline={true}
              className="block w-full"
            >
              <source src="https://cdn.shopify.com/videos/c/o/v/8ea3b586bc694b40bac4844cf9098be3.mp4" />
            </video>
          </div>
        </div>
        <img
          class="w-full m-0 hidden lg:block"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP-15-BANNER-2-DESKTOP.webp?v=1694039820"
          alt=""
        />
        <img
          class="w-full m-0 block lg:hidden"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP-15-BANNER-2-MOBILE.webp?v=1694039828"
          alt=""
        />
        <div className="flex items-center flex-col lg:flex-row-reverse">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black text-3xl lg:leading-[1.1rem] lg:text-base xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-white mb-2 lg:md-8">
              3 Quick Release Filter Options
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-white">
              1. LCP 15 CP, VND, or UV
              <br />
              2. 67mm Threaded Filter Adaptor
              <br />
              3. LightChaser 13/14 Filter Adaptor
              <br />
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-white m-0">
              1. LCP 15 CP, VND, or UV
              <br />
              2. 67mm Threaded Filter Adaptor
              <br />
              3. LightChaser 13/14 Filter Adaptor
              <br />
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <video
              autoPlay={true}
              muted={true}
              loop={true}
              playsInline={true}
              className="block w-full"
            >
              <source src="https://cdn.shopify.com/videos/c/o/v/2eb3de802b4740b3917a9f5e907a8823.mp4" />
            </video>
          </div>
        </div>
      </div>

      <div
        className={`${style.breakBanner} ${style.leftStrike} ${style.ofset} bg-black py-28`}
      >
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Full_Metal_Construction_Bombproof_Protection_Title_5de6e866-66f9-41fe-9e11-0bff55ff6020.png?v=1694105229"
          alt=""
        />
        <div
          className={`${style.dataLeft} ${style.ofset} pr-5 hidden lg:block`}
        >
          <img
            class="w-full m-0 inline-block"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/FROM_MINIMALIST_TO_FULL_FEATURED_TURN_YOUR_IPHONE_15_INTO_A_PRODUCTION_READY_RIG..png?v=1694039467"
            alt=""
          />
        </div>
      </div>

      <div className={`${style.sandBackg} feature-block2 max-w-full m-auto`}>
        <div className="flex items-center flex-col lg:flex-row">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black lg:leading-[1.1rem] text-3xl lg:text-base xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-white mb-2 lg:md-8">
              Grip and Bluetooth Shutter Compatible
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-white">
              The LightChaser 15 Case uses the same grip and bluetooth from the
              LCP14. The grip is easy to install, remove, and adjust with the
              push of a button.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-white m-0">
              The LightChaser 15 Case uses the same grip and bluetooth from the
              LCP14. The grip is easy to install, remove, and adjust with the
              push of a button.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="w-full m-0 block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/GRIP-SHUTTER-BANNER-DESKTOP-1.webp?v=1694041111"
              alt=""
            />
          </div>
        </div>

        <img
          class="w-full m-0 hidden lg:block"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP-15-BANNER-6-DESKTOP.webp?v=1694040724"
          alt=""
        />
        <img
          class="w-full m-0 block lg:hidden"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP-15-BANNER-6-MOBILE.webp?v=1694040713"
          alt=""
        />

        <div className="flex items-center flex-col lg:flex-row-reverse">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black lg:leading-[1.1rem] text-3xl lg:text-base xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-white mb-2 lg:md-8">
              MagSafe compatible for your favorite chargers and mounts
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-white">
              A new stronger magnet array makes charging and mounting to your
              favorite MagSafe accessories easy and secure.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-white m-0">
              A new stronger magnet array makes charging and mounting to your
              favorite MagSafe accessories easy and secure.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="w-full m-0 block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/MAGSAFE-COMPATIBLE-DESKTOP.webp?v=1694041111"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className={`${style.breakBanner} bg-black py-28`}>
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP15_Series_Filters_Components_Title.png?v=1694105229"
          alt=""
        />
        <div className={`${style.dataRight} pl-5 hidden lg:block`}>
          <img
            class="w-2/3 m-0"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/LITECHASER_15_PRO_FILTERS_COMPONENTS..png?v=1694039466"
            alt=""
          />
        </div>
      </div>

      <div className="grid grid-cols-2 auto-cols-fr sm:grid-cols-2 md:grid-cols-3 xl-1600:grid-cols-4 gap-6 md:gap-6 xl:gap-10 bg-black text-center px-[2vw] pb-20">
        {[...dynamicATCProducts].map((variant, ind) => {
          return (
            <HelixVariantCart
              variant={variant}
              product={variantProduct}
              sliderImages={variant?.image?.originalSrc}
            />
          )
        })}
      </div>

      <div className="launchCommercial">
        <div className="flex flex-col lg:flex-col">
          <div>
            <img
              className="m-auto hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/1.webp?v=1704481890"
              alt=""
            />
            <img
              className="m-auto block lg:hidden w-full"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/1_8db734e4-cff6-4499-98b2-4fdb244bc91e.webp?v=1704481899"
              alt=""
            />
          </div>
          <div>
            <img
              className="m-auto hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/2_e197dec9-9c28-4f4e-ab84-b193ff327ebf.webp?v=1704481890"
              alt=""
            />
            <img
              className="m-auto block lg:hidden w-full"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/2_f9fc87dd-70dc-4911-b69d-08b4a3fa9b4f.webp?v=1704481899"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BelowLCP15Overview
