/* eslint-disable */
import React, { useState, useEffect } from "react"
// import { Link } from "gatsby";
import loadable from "@loadable/component"
import * as style from "./style.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import { useStoryblok } from "../../utils/storyblok"
import DynamicComponents from "../DynamicComponents"
import image0 from "../../images/helixImages/image0.webp"
import image1 from "../../images/helixImages/image1.webp"
import image2 from "../../images/helixImages/image2.webp"
import useScrollBlock from "../../hooks/useScrollBlock"
import HelixVariantCart from "./helixVariantCart"

import PlayIcon from "../Icons/PlayIcon"

const ModalVideo = loadable(() => import("react-modal-video"))

const BelowHelixMaglockMckinnonEdOverview = ({
  product: variantProduct,
  cleanHelixProducts,
  dynamicATCProducts,
}) => {
  const [showVideo, setShowVideo] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()
  const [selectedTitle, setSelectedTitle] = useState(null)

  const helixDescription = [
    {
      title: "BRASS CONSTRUCTION + RUBBER TRIM",
      description:
        "All Helix components feature full brass construction for smooth connections + a rubber trim ring integrated into each filter provides seamless ergonomic grip.",
    },
    {
      title: "MAGLOCK\u2122 TECHNOLOGY",
      description:
        "Unlike other magnetic filters on the market, Helix MagLock&#8482; is comprised of magnetic design that keeps your filters and defender caps securely in place until you are ready to move them.",
    },
    {
      title: "THREAD BASES",
      description:
        "Available in 67mm, 72mm, 77mm, and 82mm sizes, the Helix Thread Bases act as the magnetic mounting point for all Helix filters. Each Thread Base can be equipped with a front Defender cap for lens protection when no filter is installed.",
    },
    {
      title: "DEFENDER 360\u00B0 PROTECTION",
      description:
        "Each Helix filter includes front and rear Defender caps that utilize the same magnetic + quater turn locking design as the filter themselves, keeping the ecosystem completely protected in any environment both on and off your lens.",
    },
  ]
  const scrollUp = e => {
    e.preventDefault()
    window.scrollTo({
      top: 0,
      behaviour: "smooth",
    })
  }

  useEffect(() => {
    if (showVideo) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [showVideo])

  const pageData = useStaticQuery(graphql`
    query {
      storyblokEntry(full_slug: { eq: "/products/recon-vnd-matte-box" }) {
        id
        name
        content
        slug
        full_slug
        uuid
        created_at
      }
      shopifyProduct(handle: { eq: "recon-vnd-matte-box" }) {
        handle
        title
      }
    }
  `)

  const product = pageData.shopifyProduct

  const story = pageData.storyblokEntry
    ? useStoryblok(pageData.storyblokEntry)
    : { content: null }

  const stickyNavComponent =
    story.content && story.content["sticky_nav"]
      ? story.content["sticky_nav"].map(blok => (
        <>
          {blok ? (
            <div {...sbEditable(blok)} className="sticky top-0 z-50">
              <DynamicComponents
                blok={blok}
                product={product}
                key={blok._uid}
              />
            </div>
          ) : null}
        </>
      ))
      : null

  return (
    <div className={`${style.helixOverview} overflow-hidden`}>
      <div class={`${style.productHero} text-white flex flex-col lg:flex-row`}>
        <div class={`${style.introDataLeft}`}>
          <div class="video-container">
            <a
              href="#data"
              id="inline"
              className={`${style.helixMobilePlayBtn} cta-round opacity-90 hover:opacity-100`}
              onClick={e => {
                e.preventDefault()
                setShowVideo(true)
              }}
            >
              <PlayIcon />
            </a>
          </div>
        </div>
        <div class={`${style.introDataRight} pl-0 lg:pl-20`}>
          <img
            className="w-2/3"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Helix-McKinnon-Logomark.png?v=1688676524"
            alt="Helix mckinnon"
          />
          <div className="w-11/12	">
            <p className="hidden lg:block">
              Our newest innovation in filter design is here. Ushering in a new
              era of camera filters and lens protection, Helix MagLock™ was
              built by creators, for creators.
            </p>
            <p className="block lg:hidden">
              Our newest innovation in filter design is here. Ushering in a new
              era of camera filters and lens protection, Helix MagLock™ was
              built by creators, for creators.
            </p>
          </div>
        </div>
        <ModalVideo
          channel="youtube"
          videoId="KS5EXfhsSmM"
          isOpen={showVideo}
          onClose={() => setShowVideo(false)}
        />
      </div>
      <div className={`${style.breakBanner} bg-black py-28`}>
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Designed-To-Help-You-Tell-Your-Story-Mobile.png?v=1688666305"
          alt=""
        />
        <div className={`${style.dataRight} pl-5 hidden lg:block`}>
          <img
            class="w-1/2 m-0"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Designed-To-Help-You-Tell-Your-Story.png?v=1688061731"
            alt=""
          />
        </div>
      </div>

      <div className={`${style.sandBackg} feature-block max-w-full m-auto`}>
        <div className="flex items-center flex-col lg:flex-row">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black  lg:leading-[1.1rem] text-2xl lg:text-sm xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-obsidian mb-2 lg:md-8">
              5x Faster Than Thread-On Filters — 10x More Secure Than Magnetic
              Filters
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-obsidian">
              Simply install the thread base and get shooting. Helix features a
              magnetic + quarter turn locking design ensuring your filter is
              safely locked in place while in-use.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-obsidian m-0">
              Simply install the thread base and get shooting. Helix features a
              magnetic + quarter turn locking design ensuring your filter is
              safely locked in place while in-use.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <video
              autoPlay={true}
              muted={true}
              loop={true}
              playsInline={true}
              className="block w-full"
            >
              <source src="https://cdn.shopify.com/videos/c/o/v/6dd0e5d7df6649ee8938db315a01c11a.mp4" />
            </video>
          </div>
        </div>
        <img
          class="w-full m-0 hidden lg:block"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Helix-McKinnon-Image-Block-2-Desktop.webp?v=1688073677"
          alt=""
        />
        <img
          class="w-full m-0 block lg:hidden"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Helix-McKinnon-Image-Block-2-Mobile.webp?v=1688666305"
          alt=""
        />
        <div className="flex items-center flex-col lg:flex-row-reverse">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black text-3xl lg:leading-[1.1rem] lg:text-base xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-obsidian mb-2 lg:md-8">
              Instant Filter Swaps Across Your Entire Lens Arsenal
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-obsidian">
              Helix MagLock™ makes it possible to switch filters even faster
              than your can change lenses, seamlessly integrating into your
              workflow.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-obsidian m-0">
              Helix MagLock™ makes it possible to switch filters even faster
              than your can change lenses, seamlessly integrating into your
              workflow.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <video
              autoPlay={true}
              muted={true}
              loop={true}
              playsInline={true}
              className="block w-full"
            >
              <source src="https://cdn.shopify.com/videos/c/o/v/c69a51c0617d46fa917b7c04f8fc54a9.mp4" />
            </video>
          </div>
        </div>
      </div>

      <div
        className={`${style.breakBanner} ${style.leftStrike} bg-black py-28`}
      >
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Full-Metal-Construction-Bombproof-Protection-Mobile.png?v=1688666305"
          alt=""
        />
        <div className={`${style.dataLeft} pr-5 hidden lg:block`}>
          <img
            class="w-1/2 m-0 inline-block"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Full-Metal-Construction-Bombproof-Protection.png?v=1688063352"
            alt=""
          />
        </div>
      </div>

      <div className={`${style.sandBackg} feature-block2 max-w-full m-auto`}>
        <div className="flex items-center flex-col lg:flex-row">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black lg:leading-[1.1rem] text-3xl lg:text-base xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-obsidian mb-2 lg:md-8">
              Bulletproof Filter & Lens Protection
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-obsidian">
              Included with all Helix filters and available for the thread
              bases, Defender caps ensure your glass is always protected on the
              toughest shoots.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-obsidian m-0">
              Included with all Helix filters and available for the thread
              bases, Defender caps ensure your glass is always protected on the
              toughest shoots.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <video
              autoPlay={true}
              muted={true}
              loop={true}
              playsInline={true}
              className="block w-full"
            >
              <source src="https://cdn.shopify.com/videos/c/o/v/b274cf95f72f4e64aadda0009a9bd449.mp4" />
            </video>
          </div>
        </div>

        <img
          class="w-full m-0 hidden lg:block"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Helix-McKinnon-Image-Block-3-Desktop.webp?v=1688074489"
          alt=""
        />
        <img
          class="w-full m-0 block lg:hidden"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Helix-McKinnon-Image-Block-3-Mobile.webp?v=1688666307"
          alt=""
        />

        <div className="flex items-center flex-col lg:flex-row-reverse">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black lg:leading-[1.1rem] text-3xl lg:text-base xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-obsidian mb-2 lg:md-8">
              Industry-Leading VND Color Reproduction & Color Accuracy
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-obsidian">
              Featuring our most advanced glass technology to date, resulting in
              razor-sharp optics with zero color shift in all lighting
              conditions.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-obsidian m-0">
              Featuring our most advanced glass technology to date, resulting in
              razor-sharp optics with zero color shift in all lighting
              conditions.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="w-full m-0 block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Industry-Leading-VND-Color-Block-Desktop.webp?v=1688074518"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="bg-black w-full flex flex-col lg:flex-row pt-[100px]">
        <div className=" w-full px-[10px] sm:px-[50px] lg:w-1/2">
          <p className="hilex-title mb-0">EXPLORE -</p>
          <p className="hilex-main-title text-[24px] lg:[20px] xl:text-[24px] 2xl:text-[30px]">
            ADDITIONAL SYSTEM DETAILS
          </p>
          {helixDescription.map((content, index) => (
            <div key={content.title}>
              <div className="flex justify-between border-t border-[#DDDDD7] pt-4">
                <p className="font-medium text-[20px] lg:[16px] xl:text-[20px] 2xl:text-[24px]  text-[#DDDDD7] w-[90%]">
                  {content.title}
                </p>
                {selectedTitle !== index ? (
                  <p
                    className="font-light text-[#DDDDD7] cursor-pointer text-3xl"
                    onClick={() => setSelectedTitle(index)}
                  >
                    +
                  </p>
                ) : (
                  <p
                    className="font-light text-white cursor-pointer text-3xl pr-2"
                    onClick={() => setSelectedTitle(null)}
                  >
                    -
                  </p>
                )}
              </div>
              {selectedTitle === index ? (
                <p className="text-[#DDDDD7] text-[13px] lg:text-[11px] xl:text-[12px] leading-4 2xl:text-[16px]">
                  {content.description}
                </p>
              ) : null}
            </div>
          ))}
        </div>
        <div className="w-full lg:w-1/2">
          {(selectedTitle === null) | 0 ? (
            <img
              className="w-full h-auto object-cover"
              src={image1}
              alt={"helixImg"}
            />
          ) : null}
          {selectedTitle === 0 ? (
            <img
              className="w-full h-auto object-cover"
              src={image1}
              alt={"helixImg"}
            />
          ) : null}
          {selectedTitle === 1 ? (
            <video
              autoPlay={true}
              muted={true}
              loop={true}
              playsInline={true}
              className="w-full h-auto object-cover"
              src={
                "https://cdn.shopify.com/videos/c/o/v/367db56ef36f4b2a99b05f16bae8435b.mp4"
              }
              alt={"helixVideo"}
            />
          ) : null}
          {selectedTitle === 2 ? (
            <img
              className="w-full h-auto object-cover"
              src={image2}
              alt={"helixImg"}
            />
          ) : null}
          {selectedTitle === 3 ? (
            <img
              className="w-full h-auto object-cover"
              src={image0}
              alt={"helixImg"}
            />
          ) : null}
        </div>
      </div>

      <div className={`${style.breakBanner} bg-black py-28`}>
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Helix-McKinnon-Series-Filters-And-Components-Mobile.png?v=1688666305"
          alt=""
        />
        <div className={`${style.dataRight} pl-5 hidden lg:block`}>
          <img
            class="w-2/3 m-0"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Helix-McKinnon-Series-Filters-And-Components.png?v=1688070167"
            alt=""
          />
        </div>
      </div>

      <div className="grid grid-cols-2 auto-cols-fr sm:grid-cols-2 md:grid-cols-3 xl-1600:grid-cols-4 gap-6 md:gap-6 xl:gap-10 bg-black text-center px-[2vw] pb-20">
        {[...variantProduct.variants, ...dynamicATCProducts]
          .filter(item => !item?.handle?.includes("burkard"))
          .map((variant, ind) => {
            return (
              <HelixVariantCart
                variant={variant}
                product={variantProduct}
                sliderImages={variant?.image?.originalSrc}
              />
            )
          })}
      </div>

      <div className="launchCommercial">
        <div className="flex flex-col lg:flex-col">
          <div>
            <img
              className="m-auto hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Helix-McKinnon-Image-Block-4-Desktop.webp?v=1688075198"
              alt=""
            />
            <img
              className="m-auto block lg:hidden w-full"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Helix-McKinnon-Image-Block-4-Mobile.webp?v=1688666307"
              alt=""
            />
          </div>
          <div>
            <img
              className="m-auto hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Helix-McKinnon-Image-Block-5-Desktop.webp?v=1688075199"
              alt=""
            />
            <img
              className="m-auto block lg:hidden w-full"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Helix-McKinnon-Image-Block-5-Mobile.png?v=1688666308"
              alt=""
            />
          </div>
        </div>
      </div>
    </div >
  )
}

export default BelowHelixMaglockMckinnonEdOverview
