// extracted by mini-css-extract-plugin
export var imageContainer = "style-module--imageContainer--4e958";
export var introDataLeft = "style-module--introDataLeft--14bb3";
export var introDataRight = "style-module--introDataRight--732fe";
export var leftRightBackg = "style-module--leftRightBackg--97d4c";
export var lensProtection = "style-module--lens-protection--807fd";
export var playIcon = "style-module--play-icon--07f8e";
export var productHeroDefault = "style-module--productHeroDefault--b2764";
export var productImage = "style-module--productImage--5c835";
export var thumbnailContainer = "style-module--thumbnail-container--1f0f1";
export var title = "style-module--title--ce8dd";
export var video = "style-module--video--80ec7";
export var videoContainer = "style-module--videoContainer--00af2";
export var youtubeVideo = "style-module--youtubeVideo--e035e";