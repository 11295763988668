/* eslint-disable */
import React, { useEffect, useState } from "react"
import { FaArrowUp } from "react-icons/fa"

function BackToTopButton() {
  const [backToTopButton, setBackToTopButton] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 1000) {
        setBackToTopButton(true)
      } else {
        setBackToTopButton(false)
      }
    })
  }, [])
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behaviour: "smooth",
    })
  }

  return (
    <div>
      {backToTopButton ? (
        <button
          type="button"
          onClick={scrollUp}
          className="backToTop fixed z-10 bottom-[20px] left-[7vw] md:left-[20px] w-[50px] h-[50px] bg-[#5B5B5B] hover:bg-paloverde block justify-center flex items-center rounded-tl-xl rounded-br-xl"
        >
          <FaArrowUp className="btt-arrow text-white" />
        </button>
      ) : (
        ""
      )}
    </div>
  )
}

export default BackToTopButton
