/*
 * This helper script updates the shipping hour notice in product page.
 * It takes in a callback function that updates the shipping notice state.
 */
// Set permanent shipping hour
const shipmentNoticeSetter = (setShippingHours, shipmentMessage) => {
  const deliveryGaps = parseFloat(shipmentMessage)
  const currentDay = new Date().getDay()
  let days = ""
  const getDeliveryDay = (currentDay, weekend, shipmentNumber) => {
    const weekdays = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ]
    const weekendLowerCase = weekend.map(day => day?.toLowerCase())
    const currentIndex = weekdays?.findIndex(
      day => day?.toLowerCase() === currentDay?.toLowerCase(),
    )
    let countDays
    countDays = 0
    let deliveryIndex = currentIndex
    while (countDays < shipmentNumber) {
      deliveryIndex = (deliveryIndex + 1) % weekdays.length
      if (!weekendLowerCase.includes(weekdays[deliveryIndex]?.toLowerCase())) {
        countDays += 1
      }
    }
    while (weekendLowerCase.includes(weekdays[deliveryIndex]?.toLowerCase())) {
      deliveryIndex = (deliveryIndex + 1) % weekdays.length
    }

    return weekdays[deliveryIndex]
  }

  const shippingMessageSetter = (diff, day) =>
    `order in the next ${diff} to ship on ${day}`

  const today = new Date()

  const currentTime = today.getHours()

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]
  days = daysOfWeek[currentDay]

  setShippingHours(24 - currentTime)
  const hoursRemaning = 24 - currentTime

  const day = getDeliveryDay(days, ["Saturday", "Sunday"], deliveryGaps)

  setShippingHours(shippingMessageSetter(`${hoursRemaning}  hours`, day))
}
export default shipmentNoticeSetter
