import React, { useEffect, useState } from "react"
import * as style from "./style.module.scss"
import { render } from "storyblok-rich-text-react-renderer"
import PlayIcon from "../Icons/PlayIcon"
import ModalVideo from "react-modal-video"
import useScrollBlock from "../../hooks/useScrollBlock"

function checkFileType(filename: any) {
  const extension = filename?.split?.(".")?.pop?.()?.toLowerCase?.()

  const videoExtensions = ["mp4", "avi", "mov", "mkv"]

  if (videoExtensions.includes(extension)) {
    return "video"
  }
  return "image"
}

function LeftRightHighlights(props: any) {
  const [playVideo, setPlayVideo] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()
  useEffect(() => {
    if (playVideo) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [playVideo])
  const getYoutubeVideoId = (url: string) => {
    const videoIdMatch = url && url.match(/youtu\.be\/([a-zA-Z0-9_-]+)/)
    if (videoIdMatch && videoIdMatch.length >= 2) {
      return videoIdMatch[1]
    }
  }
  const getYoutubeVideoInfo = (url: string) => {
    const videoIdMatch =
      url &&
      url?.match(
        /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/,
      )
    const timeMatch = url && url?.match(/[?&]t=(\d+)/)

    if (videoIdMatch && videoIdMatch?.length >= 2) {
      return {
        videoId: videoIdMatch[1],
        startTime:
          timeMatch && timeMatch?.length >= 2
            ? parseInt(timeMatch[1], 10)
            : undefined,
      }
    }
  }

  const videoUrl = getYoutubeVideoInfo(props?.videoUrl?.url)
  return (
    <div className="overflow-hidden w-full">
      <div
        className={`${style.leftRightBackg} feature-block max-w-full m-auto`}
      >
        <div
          className={`flex items-center flex-col ${
            props.reverse ? "lg:flex-row-reverse" : "lg:flex-row"
          }`}
        >
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black  lg:leading-[1.1rem] text-2xl lg:text-sm xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-white mb-2 lg:md-8">
              {props.title}
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-white">
              {props.description ? render(props.description) : ""}
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-white m-0">
              {props.description ? render(props.description) : ""}
            </p>
          </div>
          {props?.videoUrl?.url?.length && props?.backgroundImage?.filename ? (
            <>
              <div
                className={`${style.productHeroDefault} h-auto w-full lg:items-center lg:w-2/3 text-white lg:flex flex-col lg:flex-row block`}
                style={{
                  background: `url(${props?.backgroundImage?.filename})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <div className={`${style.introDataLeft}`}>
                  {!!props?.videoUrl?.url?.length ? (
                    <div className="video-container flex justify-center item-center">
                      <center>
                        <button
                          className={`${style.PlayBtn} cta-round opacity-90 hover:opacity-100`}
                          onClick={e => {
                            e.preventDefault()
                            setPlayVideo(true)
                          }}
                        >
                          <PlayIcon />
                        </button>
                      </center>
                    </div>
                  ) : null}
                </div>
              </div>
              {playVideo ? (
                <ModalVideo
                  channel="youtube"
                  videoId={getYoutubeVideoId(props?.videoUrl?.url) as any}
                  isOpen={playVideo}
                  onClose={() => setPlayVideo(false)}
                  youtube={{
                    start: videoUrl?.startTime as any,
                    autoplay: true,
                    playinline: true,
                  }}
                />
              ) : null}
            </>
          ) : (
            <div className="w-full lg:w-2/3">
              {checkFileType(props.media.filename) === "video" ? (
                <video
                  autoPlay={true}
                  muted={true}
                  loop={true}
                  playsInline={true}
                  className="block w-full"
                  src={props?.media?.filename}
                >
                  <source src={props.media.filename} />
                </video>
              ) : (
                <>
                  <img
                    className="w-full m-0 hidden lg:block"
                    src={props.media.filename}
                    alt="lensProduct"
                  />
                  <img
                    className="w-full m-0 block lg:hidden"
                    src={props.media.filename}
                    alt="lensProduct"
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default LeftRightHighlights
