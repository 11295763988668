const DEV = {
  productVariantEndPointUrl:
    "http://localhost:9999/.netlify/functions/country-currency-variants",
  productEndPointUrl:
    "http://localhost:9999/.netlify/functions/country-currency",
  functionsEndpoint: "http://localhost:9999/.netlify/functions",
}

const PROD = {
  productVariantEndPointUrl: "/.netlify/functions/country-currency-variants",
  productEndPointUrl: "/.netlify/functions/country-currency",
  functionsEndpoint: "/.netlify/functions",
}

export const productVariantEndPointUrl = PROD.productVariantEndPointUrl
export const productEndPointUrl = PROD.productEndPointUrl
export const functionsEndpoint = PROD.functionsEndpoint
