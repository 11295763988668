import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import StoreLocatorComp from "../../components/StoreLocator"
const StoreLocator = () => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])
  const { allStoryblokEntry } = useStaticQuery(graphql`
    query {
      allStoryblokEntry(filter: { name: { eq: "store_locations" } }) {
        edges {
          node {
            name
            content
          }
        }
      }
    }
  `)

  if (loaded && typeof window !== "undefined")
    return (
      <div className="w-full min-h-screen flex">
        <StoreLocatorComp allStoryblokEntry={allStoryblokEntry} />
      </div>
    )
  return null
}

export default StoreLocator
