/* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label */
/* eslint-disable */
import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react"
import PropTypes from "prop-types"
import { render } from "storyblok-rich-text-react-renderer"
import { Helmet } from "react-helmet"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import { Link } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import { CgChevronRight as ChevronIcon } from "react-icons/cg"
// import { StoreContext } from '../context/StoreContext';
import AddToCart from "../components/AddToCart"
import "./DefaultCustomProduct.scss"
import "./lc14.scss"
import Seo from "../components/seo"
import FeaturedProductImagesCarousel from "../components/FeaturedProductImagesCarousel/index"
import collectionHandles from "../utils/collectionHandles"
import YotpoStarRatings from "../components/YotpoStarRatings/index"
import Tab from "../components/Tab/index"
import shipmentNoticeSetter from "../utils/shipmentNoticeHelper"

import DynamicComponents from "../components/DynamicComponents"
import { useStoryblok } from "../utils/storyblok"
import { LiteChaserContext } from "../context/LiteChaserContext"
import { StoreContext } from "../context/StoreContext"
import DynamicPartsSectionComponent from "../components/DynamicPartsSectionComponent"
import LCPrimarySection from "../components/LCPrimarySection"
import DynamicBelowcontents from "../components/DynamicBelowContents"
import KitIncludesBox from "../components/KitIncludesBox"
import NewBadge from "../components/NewBadge"
import useProductKitsAndParts from "../hooks/useProductKitsAndParts"
import { useLocation } from "@reach/router"

const LitechaserProduct = props => {
  const location = useLocation()

  const {
    phoneModels,
    listPhoneModels,
    convertPhoneModelsList2Set,
    selectedPhoneModel,
    selectedKitHash,
    kitProductsByHash,
    selectPhoneModel,
    disableKitSelection,
    kits,
    lineItems,
    lineItemsSet,
    listKits,
    selectedKit,
    totalPrice,
    totalComparePrice,
    selectKit,
    extractPartsProducts,
    extractKitsProducts,
    updateLineItemsSet,
    preorderDate,
  } = useContext(LiteChaserContext)

  const { client } = useContext(StoreContext)

  const { pageContext } = props
  const {
    product,
    kitsCollection,
    partsCollection,
    storyblokEntry,
    recommendedProducts,
    shipmentnoticemessagedata
  } = pageContext

  const {
    title,
    shopifyId,
    description,
    // descriptionHtml,
    media: images,
    collections,
  } = product

  const { addClientDataKit, addClientDataPart } = useProductKitsAndParts({
    product,
    client,
  })

  // global state that keeps track of product version
  const [version, setVersion] = useState(product.title)

  // global state that keeps track of all selected products' availability
  const [available, setAvailable] = useState(false)

  // global state that keeps track if any products have been selected,
  // used to manage AddToCart state "Select Kit"
  // const [noProductSelected, setNoProductSelected] = useState(true);

  // global state that keeps track if any of products selected is preorder,
  // also keeps track of the final preorder date
  const [preorder, setPreorder] = useState({
    preorder: false,
    preorderDate: "",
  })

  // state tht keeps track of all selected products' total price,
  // defaults to being undefined to hide price html on load / when no products are selected
  // const [price, setPrice] = useState('');

  // state imported from shippingHours "time left" for available, non-preorder orders
  const [shippingHours, setShippingHours] = useState("Now")

  const story =
    storyblokEntry && storyblokEntry.length > 0
      ? useStoryblok(storyblokEntry[0].node)
      : { content: null }

  // DEV PURPOSE - LOG STORYBLOK ENTRY DATA
  // console.log('This is storyblok story', story)

  // creates custom kits and parts array that keep track of each product selected
  const kitProductsArr = [...kitsCollection.products]
  const partProductsArr = [...partsCollection.products]

  // array of all images needed to be loaded into FeaturedProductImagesCarousel component
  // const customImages = [];

  const [imageIndex, setImageIndex] = useState(0)

  // states that keep track of each selected product along with product data from gatsby-node.js
  const [kitProductsState, setKitProducts] = useState([...kitProductsArr])
  const [partProductsState, setPartProducts] = useState([...partProductsArr])
  const kitProducts = useMemo(
    () => addClientDataKit(kitProductsState),
    [kitProductsState, addClientDataKit],
  )
  const partProducts = useMemo(
    () => addClientDataPart(partProductsState),
    [partProductsState, addClientDataPart],
  )

  const checkAvailablity = useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        const result = fetchedProduct
          ? fetchedProduct.variants.filter(
            variantProduct =>
              variantProduct.id ===
              kitProductsByHash[selectedKitHash].variants[0].storefrontId,
          )
          : []

        if (result.length > 0) {
          setAvailable(result[0].available)
          // setDisable(!result[0].available);
          if (preorderDate !== "") {
            setPreorder({ preorder: true, preorderDate: preorderDate })
          }
        }
      })
    },
    [selectedKitHash, client.product],
  )

  useEffect(() => {
    if (
      selectedKit &&
      selectedPhoneModel &&
      selectedKitHash &&
      !selectedKit.includes("custom")
    ) {
      const shopId = kitProductsByHash[selectedKitHash].storefrontId
      checkAvailablity(shopId)
    }
  }, [selectedKitHash, checkAvailablity])

  useEffect(() => {
    if (selectedKit.includes("custom") && lineItems.length > 0) {
      if (version.includes("14")) {
        setPreorder({ preorder: true, preorderDate: "December 30th, 2022" })
      }
      setAvailable(true)
    }
  }, [selectedKit, lineItems])

  useEffect(() => {
    if (selectedKit.includes("custom")) {
      updateLineItemsSet(new Set())
    }
  }, [selectedKit])

  useEffect(() => {
    if (story.content && story.content.phone_models) {
      const phoneModelData = story.content.phone_models.trim().split(",")

      const phoneModelDataTrimmed = []
      phoneModelData.forEach(phone => {
        phoneModelDataTrimmed.push(phone.trim().toLowerCase())
      })

      listPhoneModels(phoneModelDataTrimmed)
      convertPhoneModelsList2Set(new Set(phoneModelDataTrimmed))

      if (story.content.kits) {
        const kitsDataTrimmed = story.content.kits
          .trim()
          .split(",")
          .map(kit => {
            return kit.trim()
          })

        listKits(kitsDataTrimmed)
      }
    }
  }, [])

  useEffect(() => {
    extractPartsProducts(partProducts)
  }, [partProducts])

  useEffect(() => {
    extractKitsProducts(kitProducts)
  }, [kitProducts])

  useEffect(() => {
    // console.log(version);
    // console.log(theme);
    // if (version.includes('14')) {
    //   setPreorder({ preorder: true, preorderDate: 'Dec 25, 2022' })
    // }
    // console.log('preorder', preorder);
  }, [version])

  // useEffect(() => {
  //   console.log('preorder', preorder);
  // }, [preorder])

  /* Breadcrumb collection set setup */
  // Default to the first collection
  let collectionIndex = 0

  if (collections?.length > 1) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < collections?.length; i++) {
      if (collectionHandles.has(collections?.[i]?.handle)) {
        collectionIndex = i
        break
      }
    }
  }

  // Extract Product Shopify Id
  const productShopifyId = shopifyId.slice(shopifyId.lastIndexOf("/") + 1)

  // Load Yotpo Reviews Script
  useEffect(() => {
    /* eslint-disable-next-line */
    ; (function e() {
      var e = document.createElement("script")
        ; (e.type = "text/javascript"),
          (e.async = true),
          (e.src =
            "//staticw2.yotpo.com/qNmEg7HN0QiYfqn6n5DHRUGCtTUGNWa1GfSVsHRS/widget.js")
      var t = document.getElementsByTagName("script")[0]
      t.parentNode.insertBefore(e, t)
    })()
  })

  const shipmentMessageStory = shipmentnoticemessagedata?.data?.storyblokEntry
    ? useStoryblok(shipmentnoticemessagedata?.data?.storyblokEntry)
    : { content: null }
  const offsetDays = shipmentMessageStory?.content?.offset_days || 2

  useEffect(() => {
    shipmentNoticeSetter(setShippingHours, offsetDays)
    const shipmentTimer = setInterval(() => {
      shipmentNoticeSetter(setShippingHours, offsetDays)
    }, 5000)

    return () => clearTimeout(shipmentTimer)
  })

  let theme = <></>

  let pageStyle = ""

  if (version.includes("14")) {
    theme = (
      <div>
        <link rel="stylesheet" href="./lc14.css" />
      </div>
    )
    pageStyle = "litechaser-14"
  } else if (
    version.includes("11") ||
    version.includes("12") ||
    version.includes("13")
  ) {
    theme = (
      <div>
        <link rel="stylesheet" href="./DefaultCustomProduct.css" />
      </div>
    )
  }

  let notice = <></>

  // if (available) {
  //   notice = (
  //     <div>
  //       <span className="text-paloverde">IN STOCK! </span>
  //       <span>
  //         Order
  //         {` ${shippingHours}`}
  //         .
  //       </span>
  //     </div>
  //   );
  // } else if (preorder.preorder) {
  //   notice = (
  //     <div>
  //       <span className="text-paloverde">PREORDER NOW! </span>
  //       <span>
  //         Order ships
  //         {` ${preorder.preorderDate}`}
  //         .
  //       </span>
  //     </div>
  //   );
  // } else if (selectedKitHash && lineItemsSet.size > 0 && !available) {
  //   notice = <span className="text-red">OUT OF STOCK</span>;
  // }

  if (!available) {
    notice = <span className="text-red">OUT OF STOCK</span>
  } else if (preorder.preorder) {
    notice = (
      <div>
        <span className="text-paloverde">RESERVE TODAY! </span>
        <span>
          Estimated to ship
          {` ${preorder.preorderDate}`}.
        </span>
      </div>
    )
  } else {
    notice = (
      <div>
        <span className="text-paloverde">IN STOCK! </span>
        <span>
          Order
          {` ${shippingHours}`}.
        </span>
      </div>
    )
  }

  const stickyNavComponent =
    story.content && story.content["sticky_nav"]
      ? story.content["sticky_nav"].map(blok => (
        <>
          {blok ? (
            <div {...sbEditable(blok)} className="relative">
              <DynamicComponents
                blok={blok}
                product={product}
                key={blok._uid}
              />
            </div>
          ) : null}
        </>
      ))
      : null

  const DynamicPartsSectionComponents =
    story.content && story.content["parts_section"]
      ? story.content["parts_section"].map(blok => (
        <>
          {blok ? (
            <div {...sbEditable(blok)}>
              <DynamicPartsSectionComponent
                blok={blok}
                key={blok._uid}
                products={partProducts}
              />
            </div>
          ) : null}
        </>
      ))
      : null

  const BelowContentSectionComponents =
    story.content && story.content["below_content"]
      ? story.content["below_content"].map(blok => (
        <>
          {blok ? (
            <div {...sbEditable(blok)}>
              <DynamicBelowcontents blok={blok} key={blok._uid} />
            </div>
          ) : null}
        </>
      ))
      : null

  useEffect(() => {
    const script = document.createElement("script")
    script.src =
      "https://staticw2.yotpo.com/qNmEg7HN0QiYfqn6n5DHRUGCtTUGNWa1GfSVsHRS/widget.js"
    script.async = true
    document.head.appendChild(script)

    script.onload = e => {
      window.yotpo.refreshWidgets()
    }

    return () => {
      script.remove()
    }
  })

  const newBadge =
    story.content && story.content["newBadge"] ? <NewBadge /> : null

  return (
    <>
      <Helmet>{theme}</Helmet>
      {images.length ? (
        <Seo
          title={title}
          description={description}
          image={getSrc(images[0].image.gatsbyImageData)}
        />
      ) : (
        <Seo title={title} description={description} />
      )}
      <div className="sticky top-0 z-50">{stickyNavComponent}</div>
      <div
        className="w-full pt-12 md:pt-90px pb-30px mb-[30px] px-[15px] md:px-[6vw] xl:mx-auto xl:px-[30px] max-w-[1180px]"
        id={`${pageStyle}`}
      >
        <div className="relative max-w-screen-xl m-auto pl-[15px] pr-[15px] lc-wrapper">
          {/** ************************************* */}
          {/** *** Left Section of Product Page **** */}
          {/** ************************************* */}

          {/* Breadcrumb */}
          <div className="flex absolute -top-14 text-sm items-center flex-row">
            <Link
              to={`/${location.search ? location.search : ""}`}
              className="hover:underline hover:pointer mr-3 mt-1 text-obsidian text-xs font-medium"
            >
              Home
            </Link>
            {collections?.length ? (
              <ChevronIcon
                className="mt-[0.2rem] mr-3 text-obsidian"
                size={8}
              />
            ) : (
              ""
            )}
            {collections?.length ? (
              <Link
                to={`collections/${collections?.[collectionIndex]?.handle}/${location.search ? location.search : ""
                  }`}
                className="hover:underline hover:pointer mr-3 mt-1 text-obsidian text-xs font-medium"
              >
                {collections?.[collectionIndex]?.title}
              </Link>
            ) : (
              ""
            )}
            {/* <ChevronIcon className="mr-2 text-grey-dark" size={12} /> */}
            <p className="mr-3 mt-1 mb-0 text-obsidian text-xs font-medium">
              <span className="mr-3">/</span>
              {product.title}
            </p>
          </div>
          {newBadge ? <div className="md:hidden">{newBadge}</div> : null}
          <div className="grid grid-cols-1 md:grid-cols-2 mt-4">
            {/* Product Image Carousel Starts */}
            <div className="max-w-[545px]">
              <FeaturedProductImagesCarousel
                images={images}
                title={title}
                imageIndex={imageIndex}
              />
            </div>
            {/* Product Image Carousel Ends */}

            {/** ************************************** */}
            {/** *** Right Section of Product Page **** */}
            {/** ************************************** */}

            <div className="p-0 md:pl-30px max-w-[545px] md:pb-[25px] lc14-right">
              {/* Product Title, description, configuration, options, etc... */}
              {newBadge ? (
                <div className="hidden md:block">{newBadge}</div>
              ) : null}
              <h1 className="header mb-2 text-xl font-extrabold font-condensed">
                {title}
              </h1>
              {/* <p className="mb-0 text-xxs text-grey">SKU: {variantSKU}</p> */}
              {/* Yotpo Star Ratings */}
              <YotpoStarRatings
                product={product}
                productShopifyId={productShopifyId}
              />
              <h2 className="priceValue mt-1 font-semibold text-xl mb-4 inline">
                <span>
                  {totalPrice > 0 ? "$" : null}
                  {totalPrice > 0 ? totalPrice / 100 : null}
                </span>
              </h2>
              <h2 className="comparePriceValue inline line-through italic ml-[15px] text-xl">
                <span>
                  {totalComparePrice > 0 ? "$" : null}
                  {totalComparePrice > 0 ? totalComparePrice / 100 : null}
                </span>
              </h2>
              {/* In stock message: 3 possible states - noProductSelected (hides message);
                  all product(s) selected are avaialable (displays in stock message);
                  one or more product(s) selected is not available/preorder status -
                  (displays preorder message with final preorder date);
                  note: out of stock products that aren't available or preorder are not selectable */}
              <p className="text-xs font-bold font-condensed mt-4">
                {lineItems.length > 0 ? notice : null}
              </p>
              <div className="mb-4">
                <LCPrimarySection
                  heading={"Phone Model"}
                  constraintList={phoneModels}
                  selectedConstraint={selectedPhoneModel}
                  constraintSetter={selectPhoneModel}
                  itemNums={phoneModels.length}
                  highLight={"lc-black"}
                  version={version}
                  tooltipText={
                    story.content["phones_tooltip"]
                      ? story.content["phones_tooltip"]
                      : ""
                  }
                  setImageIndex={function () {
                    return
                  }}
                />

                <LCPrimarySection
                  disable={disableKitSelection}
                  heading={"Kit"}
                  constraintList={kits}
                  selectedConstraint={selectedKit}
                  constraintSetter={selectKit}
                  itemNums={kits.length}
                  version={version}
                  tooltipText={
                    story.content["kits_tooltip"]
                      ? story.content["kits_tooltip"]
                      : ""
                  }
                  kitPrices={
                    story.content["prices"]
                      ? story.content["prices"].split(",")
                      : null
                  }
                  setImageIndex={setImageIndex}
                />
              </div>

              <div
                className={
                  selectedPhoneModel && selectedKit ? "block" : "hidden"
                }
              >
                {DynamicPartsSectionComponents}
                <KitIncludesBox />
              </div>

              {/* eslint-disable-next-line */}
              <div className="self-end items-center w-full flex-col justify-between xl:flex-row xl:w-auto mt-12">
                <div className="justify-end xl:justify-center w-full mb-2 xl:w-auto xl:mb-0">
                  <AddToCart
                    productLists={lineItems}
                    available={available}
                    noProductSelected={lineItemsSet.size === 0}
                    preorder={preorder.preorder}
                    style={{ maxWidth: "none" }}
                    price={(totalPrice / 100).toFixed(2).toString()}
                    productName={product.title}
                    categoryName={collections?.[collectionIndex]?.title}
                  />
                </div>
                {/* //Add To Wish List
                    <div className="flex justify-end xl:justify-center w-full xl:w-auto">
                    <AddToWishlist />
                    </div>
                    */}
              </div>
            </div>

            <div className="metaSection mt-8 hidden">
              <span className="labelFont">Type</span>
              <span className="tagList">
                <Link
                  to={`${product.productTypeSlug}>}/${location.search ? location.search : ""
                    }`}
                >
                  {product.productType}
                </Link>
              </span>
              <span className="labelFont">Tags</span>
              <span className="tagList">
                {product.tags.map(tag => (
                  <Link
                    to={`/search?t=${tag}/${location.search ? location.search : ""
                      }`}
                  >
                    {tag}
                  </Link>
                ))}
              </span>
            </div>
            <br />
            <div
              className={`product-description rte main-desc`}
            // style={selectedKit ? { marginTop: "30px" } : { marginTop: "-180px" }}
            >
              {render(story.content?.description)}
            </div>
          </div>
        </div>
      </div>
      <div>{BelowContentSectionComponents}</div>

      <Tab
        product={product}
        productShopifyId={productShopifyId}
        recommendedProducts={recommendedProducts}
      />
    </>
  )
}

export default LitechaserProduct

LitechaserProduct.propTypes = {
  pageContext: PropTypes.shape({
    product: PropTypes.shape({
      title: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
      variants: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          inventoryQuantity: PropTypes.string.isRequired,
          availableForSale: PropTypes.string.isRequired,
          inventoryPolicy: PropTypes.string.isRequired,
          selectedOptions: PropTypes.objectOf(PropTypes.string).isRequired,
          sku: PropTypes.string.isRequired,
          price: PropTypes.string.isRequired,
          storefrontId: PropTypes.string.isRequired,
          metafields: PropTypes.arrayOf(PropTypes.string).isRequired,
        }),
      ),
      shopifyId: PropTypes.string.isRequired,
      storefrontId: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      descriptionHtml: PropTypes.string.isRequired,
      collections: PropTypes.arrayOf(PropTypes.string).isRequired,
      productTypeSlug: PropTypes.string.isRequired,
      productType: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    kitsCollection: PropTypes.shape({
      description: PropTypes.string.isRequired,
      descriptionHtml: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      handle: PropTypes.string.isRequired,
      image: PropTypes.objectOf(PropTypes.stiring).isRequired,
      products: PropTypes.arrayOf(PropTypes.string).isRequired,
      metafields: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    partsCollection: PropTypes.shape({
      description: PropTypes.string.isRequired,
      descriptionHtml: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      handle: PropTypes.string.isRequired,
      image: PropTypes.objectOf(PropTypes.stiring).isRequired,
      products: PropTypes.arrayOf(PropTypes.string).isRequired,
      metafields: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
  }).isRequired,
}
