/* eslint-disable */

import { Link, navigate } from "@reach/router"
import React, { useEffect, useState } from "react"
import { FaRegTimesCircle } from "react-icons/fa"
import useAuth from "../../hooks/useAuth"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { useLocation } from "@reach/router"
import productShopifyIdDecoder from "../../utils/shopifyProductIdDecoder"
import FormError from "../FormError"
import { functionsEndpoint } from "../../config"
import axiosLib from "axios"

const AddNewShippingAddress = () => {
  const location = useLocation()

  const isBrowser = typeof window !== "undefined"
  const { auth, setAuth } = useAuth()
  const privateAxios = useAxiosPrivate()
  const [fields, setFields] = useState({
    firstName: "",
    lastName: "",
    company: "",
    address1: "",
    address2: "",
    city: "",
    country: "",
    province: "",
    zip: "",
    phone: "",
    default: false,
  })
  const [msg, setMsg] = useState("")
  const submitHandler = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    setMsg("")
    await axiosLib
      .post(functionsEndpoint + "/create-address", fields, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(result => {
        if (result?.data?.customerAddress) {
          navigate(
            `/account/shipping-addresss/${
              location.search ? location.search : ""
            }`,
          )
          return
        }
        setMsg("An error occured")
      })
      .catch(err => {
        const message = JSON.parse(JSON.stringify(err))?.message
        setMsg(message || "An error occured")
      })
  }
  useEffect(() => {
    if (!auth?.accessToken || !auth?.email) {
      axiosLib
        .get(functionsEndpoint + "/account", {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(async result => {
          const {
            id,
            email,
            firstName,
            lastName,
            defaultAddress,
            phone,
            metafields,
          } = result.data?.customer
          // Properly format retrieved data
          const customerShopifyId = await productShopifyIdDecoder(id)
          const birthday = metafields?.edges?.filter?.(
            (metafield: any) => metafield?.key === "birthDate",
          )
          const birthdayStr =
            birthday?.length > 0
              ? birthday?.[0]?.value
                  ?.replace?.(/-/g, "/")
                  ?.toDateString?.()
                  ?.substr?.(4)
              : "---"

          // Update auth info
          setAuth?.({
            ...auth,
            id: customerShopifyId,
            email: email,
            firstName: firstName,
            lastName: lastName,
            defaultAddress: defaultAddress,
            phone: phone,
            birthday: birthdayStr,
          })
        })
        .catch(err => {
          console.error("error retrieving data", err.message)
          if (!err?.response) {
            console.error("No Server Response")
          } else if (err.response?.status === 400) {
            console.error("Bad Request")
          } else if (err.response?.status === 401) {
            console.error("Unauthorized")
          } else if (err.reponse?.status === 403) {
            console.error("Forbidden")
          } else if (err.response.status === 500) {
            console.error(
              `Internal server error occured. Message from server: ${err.message}`,
            )
          } else {
            console.error(
              `Unknown error occured. Message from server: ${err.message}`,
            )
          }
        })
    }
  }, [])
  return (
    <div className="flex flex-col min-h-screen w-full lg:justify-center items-center">
      <div className="flex justify-between items-center w-full pb-1 border-b-4 border-black px-6 2xl:px-0 lg:max-w-4xl">
        <div className="flex items-center">
          <p className="uppercase text-lg text-obsidian font-extrabold mb-0">
            ADD A NEW SHIPPING ADDRESS
          </p>
        </div>
        <button onClick={() => navigate(-1)}>
          <FaRegTimesCircle className="w-6 h-6 text-obsidian hover:text-paloverde" />
        </button>
      </div>
      {msg?.length ? <FormError msg={msg} /> : null}
      <form
        onSubmit={submitHandler}
        className="flex flex-col items-center w-full px-6 2xl:px-0 lg:max-w-4xl"
      >
        <div className="mt-4 flex flex-col lg:flex-row justify-between items-center w-full space-y-4 lg:space-y-0 lg:space-x-4">
          <div className="border-2 border-black px-3 pt-2 w-full">
            <p className="mb-0">First Name</p>
            <input
              placeholder="John"
              className="w-full pb-2 bg-transparent"
              value={fields.firstName}
              required
              onChange={e =>
                setFields({ ...fields, firstName: e.target.value })
              }
            />
          </div>
          <div className="border-2 border-black px-3 pt-2 w-full">
            <p className="mb-0">Last Name</p>
            <input
              placeholder="Doe"
              className="w-full pb-2 bg-transparent"
              value={fields.lastName}
              required
              onChange={e => setFields({ ...fields, lastName: e.target.value })}
            />
          </div>
        </div>
        <div className="mt-4 flex justify-between items-center w-full space-x-4">
          <div className="border-2 border-black px-3 pt-2 w-full">
            <p className="mb-0">Company</p>
            <input
              placeholder=""
              type="text"
              className="w-full pb-2 bg-transparent"
              value={fields.company}
              onChange={e => setFields({ ...fields, company: e.target.value })}
            />
          </div>
        </div>
        <div className="mt-4 flex justify-between items-center w-full space-x-4">
          <div className="border-2 border-black px-3 pt-2 w-full">
            <p className="mb-0">Address 1</p>
            <input
              placeholder=""
              type="text"
              className="w-full pb-2 bg-transparent"
              value={fields.address1}
              required
              onChange={e => setFields({ ...fields, address1: e.target.value })}
            />
          </div>
        </div>
        <div className="mt-4 flex justify-between items-center w-full space-x-4">
          <div className="border-2 border-black px-3 pt-2 w-full">
            <p className="mb-0">Address 2</p>
            <input
              placeholder=""
              type="text"
              className="w-full pb-2 bg-transparent"
              value={fields.address2}
              onChange={e => setFields({ ...fields, address2: e.target.value })}
            />
          </div>
        </div>
        <div className="mt-4 flex flex-col md:flex-row justify-between items-center w-full md:space-x-4 space-y-4 md:space-y-0">
          <div className="border-2 border-black px-3 pt-2 w-full">
            <p className="mb-0">City</p>
            <input
              placeholder="Huntington Beach"
              className="w-full pb-2 bg-transparent"
              value={fields.city}
              required
              onChange={e => setFields({ ...fields, city: e.target.value })}
            />
          </div>
          <div className="border-2 border-black px-3 pt-2 w-full">
            <p className="mb-0">Country</p>
            <input
              placeholder="United States"
              className="w-full pb-2 bg-transparent"
              value={fields.country}
              required
              onChange={e => setFields({ ...fields, country: e.target.value })}
            />
          </div>
        </div>
        <div className="mt-4 flex justify-between items-center w-full space-x-4">
          <div className="border-2 border-black px-3 pt-2 w-full">
            <p className="mb-0">Province</p>
            <input
              placeholder="California"
              type="text"
              className="w-full pb-2 bg-transparent"
              value={fields.province}
              required
              onChange={e => setFields({ ...fields, province: e.target.value })}
            />
          </div>
        </div>
        <div className="mt-4 flex flex-col lg:flex-row justify-between items-center w-full space-y-4 lg:space-y-0 lg:space-x-4">
          <div className="border-2 border-black px-3 pt-2 w-full">
            <p className="mb-0">Postal / Zip</p>
            <input
              placeholder="92646"
              className="w-full pb-2 bg-transparent"
              value={fields.zip}
              required
              onChange={e => setFields({ ...fields, zip: e.target.value })}
            />
          </div>
          <div className="border-2 border-black px-3 pt-2 w-full">
            <p className="mb-0">Phone (000-000-0000)</p>
            <input
              placeholder="000-000-0000"
              className="w-full pb-2 bg-transparent"
              value={fields.phone}
              required
              onChange={e => setFields({ ...fields, phone: e.target.value })}
            />
          </div>
        </div>
        <div className="mt-4 flex justify-between items-center w-full space-x-4">
          <button
            type="button"
            onClick={() => setFields({ ...fields, default: !fields.default })}
            className="mb-0 flex items-center border-2 border-gray-800"
          >
            <p className="mb-0 border-r-2 border-gray-800 m-0 px-10 py-3 text-lg leading-8 font-bold">
              Set As Default Address
            </p>
            <div
              className="px-5 py-4 mb-0 h-full flex justify-center items-center"
              style={{ opacity: fields.default ? 1 : 0 }}
            >
              <p className="mb-0 text-14 font-extrabold">X</p>
            </div>
          </button>
        </div>
        <button className="text-11 mt-6 tracking-widest min-w-200px w-full bg-obsidian text-white p-2 font-bold font-expanded uppercase hover:bg-paloverde mb-4">
          ADD ADDRESS
        </button>
      </form>
    </div>
  )
}

export default AddNewShippingAddress
