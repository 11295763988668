/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"
import * as style from "./style.module.scss"

const QuartzlineRenderAnimation = ({ blok }) => {
  const { renderAnimation, thickHeading, thinHeading, sentence } = blok

  return (
    <div
      className={`${style.featureContainer} px-[30px] mx-auto max-w-[1180px] mb-20`}
    >
      <div className={style.text}>
        <h2>{thickHeading}</h2>
        <h3>{thinHeading}</h3>
        <p>{sentence}</p>
      </div>
      <div className={style.animation}>
        <video width="100%" height="100%" autoPlay muted loop>
          <source src={renderAnimation} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  )
}

export default QuartzlineRenderAnimation

QuartzlineRenderAnimation.propTypes = {
  blok: PropTypes.shape({
    renderAnimation: PropTypes.string,
    thickHeading: PropTypes.string,
    thinHeading: PropTypes.string,
    sentence: PropTypes.string,
  }),
}

QuartzlineRenderAnimation.defaultProps = {
  blok: {
    renderAnimation: "",
    thickHeading: "MISSING THICK HEADING",
    thinHeading: "MISSING THIN HEADING",
    sentence: "MISSING A SENTENCE",
  },
}
