import React from "react"

function ImageComponent(props: any) {
  const isVideo = (url: string) => {
    const videoExtensions = ["mp4", "webm", "ogg"]
    const extension = url.split(".").pop()
    return videoExtensions.includes(extension || "")
  }

  const selectedURL = props?.image?.filename || props?.Image?.filename
  const selectedMobileUrl =
    props?.mobileImage?.filename || props?.mobileImage?.filename
  const selectedAlt =
    props?.alt || props?.Alt || props?.image?.alt || props?.Image?.alt

  return (
    <div className="flex w-full justify-center">
      {isVideo(selectedURL) ? (
        <>
          <video
            autoPlay={true}
            muted={true}
            loop={true}
            playsInline={true}
            src={selectedURL}
            className="w-full !mb-0 hidden md:block"
          >
            <source src={selectedURL} type="video/mp4" />
          </video>
          <video
            autoPlay={true}
            muted={true}
            loop={true}
            playsInline={true}
            src={selectedMobileUrl}
            className="w-full !mb-0 block md:hidden"
          >
            <source src={selectedMobileUrl} type="video/mp4" />
          </video>
        </>
      ) : (
        <>
          <img
            src={selectedURL}
            className="w-full !mb-0 hidden md:block bg-black"
            alt={selectedAlt}
          />
          <img
            src={selectedMobileUrl}
            className="w-full !mb-0 block md:hidden"
            alt={selectedAlt}
          />
        </>
      )}
    </div>
  )
}

export default ImageComponent
