/* eslint-disable */
import React, { useEffect } from "react"
import { useLocation } from "@reach/router"

import { navigate } from "gatsby"
import { Link } from "gatsby"
import * as style from "./404.module.scss"
import Seo from "../components/seo"
import SecondaryButton from "../components/SecondaryButton"

const NotFoundPage = () => (
  useEffect(() => {
    // Redirect logic
    const { pathname } = window.location
    if (pathname.includes("/products")) {
      navigate("/")
    }
  }, []),
  (
    <>
      <Seo title="404: Not found" />
      <div className="flex flex-col">
        <div className="w-full flex justify-center items-center relative">
          <img
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/404-Hero.webp?v=1668466803"
            className="hidden md:block m-0 h-auto md:h-[380px] lg:h-[auto] object-none md:object-cover"
          />
          <img
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/404-Hero-Mobile.webp?v=1668466815"
            className="block md:hidden m-0"
          />
          <div className="absolute w-[75vw]">
            <h2 className="uppercase font-black text-2xl m-0 md:text-5xl text-white pb-4">
              404 - Page Not Found
            </h2>
            <p className="text-white font-expanded">
              We Recently enchanced our website and the link you clicked has
              been changed.
            </p>
            <SecondaryButton
              text="View Home Page"
              link="/"
              className="align-left"
              buttonStyle="white"
            />
          </div>
        </div>
        <div className="w-full pb-12 pt-7 md:pt-28 relative w-[85vw] m-auto">
          <div className="hidden md:block">
            <h3 className="text-obsidian m-0 text-5xl font-black">
              Lets get you pointed back{" "}
            </h3>
            <h3 className="text-obsidian text-5xl font-black">
              in the right <span className="text-paloverde">direction:</span>
            </h3>
          </div>
          <div className="block md:hidden">
            <h3 className="text-obsidian m-0 text-3xl font-black">
              Lets get you
            </h3>
            <h3 className="text-obsidian m-0 text-3xl font-black">
              pointed back in
            </h3>
            <h3 className="text-obsidian text-3xl font-black m-0">
              the right <span className="text-paloverde">direction:</span>
            </h3>
          </div>
          <div className="relative flex flex-col md:flex-row pt-7 md:pt-20 pb-0 md:pb-32">
            <a
              href="/collections/new-arrivals"
              className={`${style.featuredCollectionItem} ${style.newArrivals} h-[44vw] md:h-[28vw] bg-cover w-[auto] md:w-[22vw] mb-4 md:mb-10 md:mb-0 flex flex-col items-center justify-center relative`}
            >
              <span className="relative z-10">
                <h3 className="font-black text-white text-2xl md:text-xl lg:text-2xl m-2 text-center">
                  New Arrivals
                </h3>
                <p
                  className={`${style.hoverTag} font-expanded font-normal text-xs md:text-xxs lg:text-[.65rem] text-white opacity-0 text-center`}
                >
                  Explore our latest innovations.
                </p>
              </span>
            </a>
            <a
              href="/collections/helix-filters"
              className={`${style.featuredCollectionItem} ${style.proSystems} h-[44vw] md:h-[28vw] bg-cover w-[auto] md:w-[22vw] mb-4 md:mb-0 flex flex-col items-center justify-center relative`}
            >
              <span className="relative z-10">
                <h3 className="font-black text-white text-2xl md:text-xl lg:text-2xl m-2 text-center">
                  Helix Maglock
                </h3>
                <p
                  className={`${style.hoverTag} font-expanded font-normal text-xs md:text-xxs lg:text-[.65rem] text-white opacity-0 text-center`}
                >
                  A fully seamless filter experience.
                </p>
              </span>
            </a>
            <a
              href="/collections/filters"
              className={`${style.featuredCollectionItem} ${style.filters} h-[44vw] md:h-[28vw] bg-cover w-[auto] md:w-[22vw] mb-4 md:mb-0 flex flex-col items-center justify-center relative`}
            >
              <span className="relative z-10">
                <h3 className="font-black text-white text-2xl md:text-xl lg:text-2xl m-2 text-center">
                  Filters
                </h3>
                <p
                  className={`${style.hoverTag} font-expanded font-normal text-xs md:text-xxs lg:text-[.65rem] text-white opacity-0 text-center`}
                >
                  Explore our full line of filters.
                </p>
              </span>
            </a>
            <a
              href="/collections/mobile"
              className={`${style.featuredCollectionItem} ${style.mobile} h-[44vw] md:h-[28vw] bg-cover w-[auto] md:w-[22vw] mb-4 md:mb-0 flex flex-col items-center justify-center relative`}
            >
              <span className="relative z-10">
                <h3 className="font-black text-white text-2xl md:text-xl lg:text-2xl m-2 text-center">
                  Mobile
                </h3>
                <p
                  className={`${style.hoverTag} font-expanded font-normal text-xs md:text-xxs lg:text-[.65rem] text-white opacity-0 text-center`}
                >
                  Maximize your mobile creative experience.
                </p>
              </span>
            </a>
            <a
              href="/collections/gear"
              className={`${style.featuredCollectionItem} ${style.gear} h-[44vw] md:h-[28vw] bg-cover w-[auto] md:w-[22vw] mb-4 md:mb-0 flex flex-col items-center justify-center relative`}
            >
              <span className="relative z-10">
                <h3 className="font-black text-white text-2xl md:text-xl lg:text-2xl m-2 text-center">
                  Gear
                </h3>
                <p
                  className={`${style.hoverTag} font-expanded font-normal text-xs md:text-xxs lg:text-[.65rem] text-white opacity-0 text-center`}
                >
                  Industry leading tools + accessories.
                </p>
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  )
)

export default NotFoundPage
