import React from "react"

const FontPalettePage = () => (
  <>
    <div className="w-full p-8">
      <h1 className="font-bold">Font Palette for Designers and Developers</h1>
      <p className="w-full xl:w-2/3">
        This font gallery was prepared for designers and developers in PolarPro
        to reference. Primary font family is &lsquo;Helvetica LT Pro&rsquo; and
        Display font family is &lsquo;Codec Cold&rsquo; Regular style, Condensed
        style, and Expanded style are displayed below.
      </p>
    </div>
    <div className="w-full p-8 flex-col xl:flex xl:flex-row">
      <div className="w-full xl:w-auto">
        <h2 className="font-bold">Primary Font: Helvetica Neue LT Pro</h2>
        <h4>Regular Style</h4>
        <div>
          <span className="font-thin" contentEditable="true">
            Helvetica Neue LT Pro Thin{" "}
          </span>
          <br />
          <span className="font-extralight" contentEditable="true">
            Helvetica Neue LT Pro Extra Light{" "}
          </span>
          <br />
          <span className="font-light" contentEditable="true">
            Helvetica Neue LT Pro Light{" "}
          </span>
          <br />
          <span className="font-normal" contentEditable="true">
            Helvetica Neue LT Pro Regular{" "}
          </span>
          <br />
          <span className="font-medium" contentEditable="true">
            Helvetica Neue LT Pro Medium{" "}
          </span>
          <br />
          <span className="font-bold" contentEditable="true">
            Helvetica Neue LT Pro Bold{" "}
          </span>
          <br />
          <span className="font-extrabold" contentEditable="true">
            Helvetica Neue LT Pro Extra Bold{" "}
          </span>
          <br />
          <span className="font-black" contentEditable="true">
            Helvetica Neue LT Pro Black{" "}
          </span>
          <br />
        </div>
        <br />
        <h4>
          Regular Style -<em> italic</em>
        </h4>
        <div>
          <span className="font-thin italic" contentEditable="true">
            Helvetica Neue LT Pro Thin Italic{" "}
          </span>
          <br />
          <span className="font-extralight italic" contentEditable="true">
            Helvetica Neue LT Pro Extra Light Italic{" "}
          </span>
          <br />
          <span className="font-light italic" contentEditable="true">
            Helvetica Neue LT Pro Light Italic{" "}
          </span>
          <br />
          <span className="italic" contentEditable="true">
            Helvetica Neue LT Pro Italic{" "}
          </span>
          <br />
          <span className="font-medium italic" contentEditable="true">
            Helvetica Neue LT Pro Medium Italic{" "}
          </span>
          <br />
          <span className="font-bold italic" contentEditable="true">
            Helvetica Neue LT Pro Bold Italic{" "}
          </span>
          <br />
          <span className="font-extrabold italic" contentEditable="true">
            Helvetica Neue LT Pro Extra Bold Italic{" "}
          </span>
          <br />
          <span className="font-black italic" contentEditable="true">
            Helvetica Neue LT Pro Black Italic{" "}
          </span>
          <br />
        </div>
        <br />
        <h4>Condensed Style</h4>
        <div>
          <span className="font-thin font-condensed" contentEditable="true">
            Helvetica Neue LT Pro Thin{" "}
          </span>
          <br />
          <span
            className="font-extralight font-condensed"
            contentEditable="true"
          >
            Helvetica Neue LT Pro Extra Light{" "}
          </span>
          <br />
          <span className="font-light font-condensed" contentEditable="true">
            Helvetica Neue LT Pro Light{" "}
          </span>
          <br />
          <span className="font-normal font-condensed" contentEditable="true">
            Helvetica Neue LT Pro Regular{" "}
          </span>
          <br />
          <span className="font-medium font-condensed" contentEditable="true">
            Helvetica Neue LT Pro Medium{" "}
          </span>
          <br />
          <span className="font-bold font-condensed" contentEditable="true">
            Helvetica Neue LT Pro Bold{" "}
          </span>
          <br />
          <span
            className="font-extrabold font-condensed"
            contentEditable="true"
          >
            Helvetica Neue LT Pro Extra Bold{" "}
          </span>
          <br />
          <span className="font-black font-condensed" contentEditable="true">
            Helvetica Neue LT Pro Black{" "}
          </span>
          <br />
        </div>
        <br />
        <h4>
          Condensed Style -<em> italic</em>
        </h4>
        <div>
          <span
            className="font-thin font-condensed italic"
            contentEditable="true"
          >
            Helvetica Neue LT Pro Thin{" "}
          </span>
          <br />
          <span
            className="font-extralight font-condensed italic"
            contentEditable="true"
          >
            Helvetica Neue LT Pro Extra Light{" "}
          </span>
          <br />
          <span
            className="font-light font-condensed italic"
            contentEditable="true"
          >
            Helvetica Neue LT Pro Light{" "}
          </span>
          <br />
          <span
            className="font-normal font-condensed italic"
            contentEditable="true"
          >
            Helvetica Neue LT Pro Regular{" "}
          </span>
          <br />
          <span
            className="font-medium font-condensed italic"
            contentEditable="true"
          >
            Helvetica Neue LT Pro Medium{" "}
          </span>
          <br />
          <span
            className="font-bold font-condensed italic"
            contentEditable="true"
          >
            Helvetica Neue LT Pro Bold{" "}
          </span>
          <br />
          <span
            className="font-extrabold font-condensed italic"
            contentEditable="true"
          >
            Helvetica Neue LT Pro Extra Bold{" "}
          </span>
          <br />
          <span
            className="font-black font-condensed italic"
            contentEditable="true"
          >
            Helvetica Neue LT Pro Black{" "}
          </span>
          <br />
        </div>
        <br />
        <h4>Expanded Style</h4>
        <div>
          <span className="font-thin font-expanded" contentEditable="true">
            Helvetica Neue LT Pro Thin{" "}
          </span>
          <br />
          <span
            className="font-extralight font-expanded"
            contentEditable="true"
          >
            Helvetica Neue LT Pro Extra Light{" "}
          </span>
          <br />
          <span className="font-light font-expanded" contentEditable="true">
            Helvetica Neue LT Pro Light{" "}
          </span>
          <br />
          <span className="font-normal font-expanded" contentEditable="true">
            Helvetica Neue LT Pro Regular{" "}
          </span>
          <br />
          <span className="font-medium font-expanded" contentEditable="true">
            Helvetica Neue LT Pro Medium{" "}
          </span>
          <br />
          <span className="font-bold font-expanded" contentEditable="true">
            Helvetica Neue LT Pro Bold{" "}
          </span>
          <br />
          <span className="font-extrabold font-expanded" contentEditable="true">
            Helvetica Neue LT Pro Extra Bold{" "}
          </span>
          <br />
          <span className="font-black font-expanded" contentEditable="true">
            Helvetica Neue LT Pro Black{" "}
          </span>
          <br />
        </div>
        <br />
        <h4>
          Expanded Style -<em> italic</em>
        </h4>
        <div>
          <span
            className="font-thin font-expanded italic"
            contentEditable="true"
          >
            Helvetica Neue LT Pro Thin{" "}
          </span>
          <br />
          <span
            className="font-extralight font-expanded italic"
            contentEditable="true"
          >
            Helvetica Neue LT Pro Extra Light{" "}
          </span>
          <br />
          <span
            className="font-light font-expanded italic"
            contentEditable="true"
          >
            Helvetica Neue LT Pro Light{" "}
          </span>
          <br />
          <span
            className="font-normal font-expanded italic"
            contentEditable="true"
          >
            Helvetica Neue LT Pro Regular{" "}
          </span>
          <br />
          <span
            className="font-medium font-expanded italic"
            contentEditable="true"
          >
            Helvetica Neue LT Pro Medium{" "}
          </span>
          <br />
          <span
            className="font-bold font-expanded italic"
            contentEditable="true"
          >
            Helvetica Neue LT Pro Bold{" "}
          </span>
          <br />
          <span
            className="font-extrabold font-expanded italic"
            contentEditable="true"
          >
            Helvetica Neue LT Pro Extra Bold{" "}
          </span>
          <br />
          <span
            className="font-black font-expanded italic"
            contentEditable="true"
          >
            Helvetica Neue LT Pro Black{" "}
          </span>
          <br />
        </div>
      </div>
    </div>
  </>
)

export default FontPalettePage
