/* eslint-disable */
import React from "react"
import { FaArrowRight } from "react-icons/fa"

const ImageCarouselArrow = ({
  onClick,
  right,
  subCarousel,
  imagesLength,
  ...rest
}) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest

  const ariaLabelDirection = right ? "next" : "previous"

  const rightArrow =
    "right-0 bg-obsidian hover:bg-paloverde md:right-[2vw] lg:right-[6vw] bottom-[-20px] flex items-center justify-center rounded-tl-2xl rounded-br-2xl border-8 border-white"
  const leftArrow = "left-0 bg-obsidian hidden"

  let buttonStyle = `
    block absolute w-14 h-14
    transition hover:transition
    bg-center bg-no-repeat
    ${right ? rightArrow : leftArrow}
  `

  return (
    <button
      type="button"
      data-role="none"
      className={buttonStyle}
      aria-label={`${ariaLabelDirection}`}
      onClick={() => onClick()}
    >
      <FaArrowRight color="white" size="23" />
    </button>
  )
}

export default ImageCarouselArrow
