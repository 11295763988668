/* eslint-disable  */

import React, { FC, SyntheticEvent, useEffect, useState } from "react"
import { navigate, Link } from "gatsby"
import { useLocation } from "@reach/router"

import { AiOutlineArrowRight } from "react-icons/ai"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import useAuth from "../../hooks/useAuth"
import { FaChevronRight, FaEnvelope, FaPhone } from "react-icons/fa"
import "../../styles/profile.scss"
import WishListIcon from "../../assets/WishListIcon"
import ShoppingCartIcon from "../../assets/ShoppingCartIcon"
import PhoneIcon from "../../assets/PhoneIcon"
import productShopifyIdDecoder from "../../utils/shopifyProductIdDecoder"
import MobileAccountSidebar from "../AccountSidebar/mobile"
import { functionsEndpoint } from "../../config"
import axiosLib from "axios"

const ProfileHub: FC<RoutedHTMLAttr> = () => {
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const axios = useAxiosPrivate()
  const { auth, setAuth } = useAuth()
  const [tel, setTel] = useState("")
  const [email, setEmail] = useState("")
  const [dividend, setDividend] = useState("")

  const fetchData = async () => {
    setLoading(true)
    try {
      const result = await axiosLib.get(functionsEndpoint + "/account", {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
      const {
        id,
        email,
        firstName,
        lastName,
        defaultAddress,
        phone,
        metafields,
      } = result.data?.customer
      const customerShopifyId = productShopifyIdDecoder(id)
      const profilePic = metafields?.filter?.((item: any) => {
        return item?.key === "profilePicture"
      })?.[0]?.value
      setAuth?.({
        ...auth,
        id: customerShopifyId,
        email: email,
        firstName: firstName,
        lastName: lastName,
        defaultAddress: defaultAddress,
        phone: phone,
        profilePic,
      })
      setDividend(
        result?.data?.loyalty?.credit_balance_in_customer_currency || "$0",
      )
      setLoading(false)
    } catch (err: any) {
      console.error("error retrieving data", err.message)
      if (!err?.response) {
        console.error("No Server Response")
      } else if (err.response?.status === 400) {
        console.error("Bad Request")
      } else if (err.response?.status === 401) {
        console.error("Unauthorized")
      } else if (err.reponse?.status === 403) {
        console.error("Forbidden")
      } else if (err.response.status === 500) {
        console.error(
          `Internal server error occured. Message from server: ${err.message}`,
        )
      } else {
        console.error(
          `Unknown error occured. Message from server: ${err.message}`,
        )
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (auth?.email) {
      setEmail(auth.email)
    }
    if (auth?.phone) {
      setTel(auth.phone)
    }
  }, [auth])

  const handleLogout = (e: SyntheticEvent) => {
    e.preventDefault()
    axiosLib
      .get(functionsEndpoint + "/logout", {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(res => {
        setAuth?.({})
        typeof window !== "undefined"
          ? localStorage.setItem("loggedIn", "false")
          : null
        navigate(`/${location.search ? location.search : ""}`)
      })
      .catch(err => {
        console.error(
          err.message,
          "But, your session has been closed, and logged out.",
        )
        setAuth?.({})
        typeof window !== "undefined"
          ? localStorage.setItem("loggedIn", "false")
          : null
        navigate(`/${location.search ? location.search : ""}`)
      })
  }

  return (
    <div className="w-full">
      <MobileAccountSidebar />
      <div className="w-full relative flex flex-col justify-center items-center">
        <div
          id="cover-photo-display"
          className="cover-photo-wrapper account-landing-bg"
          style={{
            backgroundColor: "grey",
          }}
        />
        <div className="w-full px-6 2xl:px-0 flex flex-col items-center">
          <div className="bg-white w-full relative -top-24 lg:max-w-4xl shadow-md py-8 rounded-sm px-6 flex flex-col lg:flex-row justify-between items-center">
            <div className="flex flex-col lg:flex-row text-center lg:text-left items-center">
              <img
                src={
                  auth?.profilePic ||
                  "https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/default_user_img.png?v=114262558971094814821581460669"
                }
                id="upload-cover-photo"
                className="object-cover rounded-full w-24 h-24 mb-0"
              />
              <div className="lg:ml-4 profile-data-container mt-4 lg:mt-0">
                <p className="mb-0">Welcome,</p>

                {loading ? (
                  <div role="status" className="mt-1 pl-2">
                    <svg
                      aria-hidden="true"
                      className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-white"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <h1 className="mb-0">
                    {auth?.firstName} {auth?.lastName}
                  </h1>
                )}
              </div>
            </div>
            <button
              onClick={handleLogout}
              className="mb-0 flex items-center border-2 border-gray-800 mt-4 lg:mt-0"
            >
              <p className="mb-0 border-r-2 border-gray-800 m-0 px-4 lg:px-10 py-2 text-lg leading-8 font-bold">
                Sign Out
              </p>
              <div className="px-2 mb-0 h-full flex justify-center items-center">
                <AiOutlineArrowRight fontWeight={800} fontSize={18} />
              </div>
            </button>
          </div>
          <div className="w-full flex flex-col lg:flex-row justify-between lg:max-w-4xl items-start relative -top-16 lg:space-x-6">
            <div className="bg-white w-full lg:max-w-2xl shadow-sm py-8 rounded-sm px-6 flex flex-col justify-between items-center h-full">
              <div className="flex w-full justify-between items-center border-b border-gray-300 pb-4">
                <p className="text-xl font-extrabold mb-0">Dividend</p>
                <Link
                  to={`/account/polarpro-rewards/${
                    location.search ? location.search : ""
                  }`}
                  className="text-md font-bold mb-0"
                >
                  More info
                </Link>
              </div>
              <div className="w-full flex flex-col justify-center items-center mt-12">
                {loading ? (
                  <div role="status" className="mt-1 pl-2">
                    <svg
                      aria-hidden="true"
                      className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-white"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <h1 className="mb-0 text-3xl font-extrabold">{dividend}</h1>
                )}

                <p className="mb-0 text-lg tracking-tighter font-extrabold">
                  DIVIDEND BALANCE
                </p>
                <Link
                  to={`/${location.search ? location.search : ""}`}
                  className="text-11 tracking-widest min-w-200px w-full bg-obsidian text-white p-2 text-center font-bold font-expanded uppercase hover:bg-paloverde mt-8"
                >
                  SHOP NOW
                </Link>
              </div>
            </div>
            <div className="bg-white w-full lg:max-w-2xl shadow-sm mt-8 lg:mt-0 py-8 pb-16 rounded-sm px-6 flex flex-col justify-between items-center h-full">
              <div className="flex w-full justify-between items-center border-b border-gray-300 pb-4">
                <p className="text-xl font-extrabold mb-0">Account Info</p>
                <Link
                  to={`/account/edit/${location.search ? location.search : ""}`}
                  className="text-md font-bold mb-0"
                >
                  Edit
                </Link>
              </div>
              <div className="w-full flex flex-col justify-center items-start mt-12">
                <div className="border-2 border-gray-800 flex items-start justify-between w-full">
                  <div className="px-4 py-2 flex justify-center items-center">
                    <FaEnvelope fontSize={24} />
                  </div>
                  <div className="flex justify-center items-center w-full border-l-2 border-gray-800">
                    <input
                      placeholder="johndoe@gmail.com"
                      value={email ? email : ""}
                      disabled
                      onChange={e => setEmail(e.target.value)}
                      className="py-2 pl-2 pr-5 text-left w-full focus:outline-none"
                    />
                  </div>
                </div>
                <div className="border-2 border-gray-800 flex items-start justify-between w-full mt-4">
                  <div className="px-4 py-2 flex justify-center items-center">
                    <FaPhone fontSize={24} />
                  </div>
                  <div className="flex justify-center items-center w-full border-l-2 border-gray-800">
                    <input
                      placeholder="000-000-0000"
                      value={tel}
                      disabled
                      onChange={e => setTel(e.target.value)}
                      className="py-2 pl-2 pr-5 text-left w-full focus:outline-none"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-hub-wrapper hidden">
        <div className="profile-hub-sections-wrapper">
          <span className="text-paloverde text-xs">
            &#42; You may view your reward balance at checkout
          </span>
          <div className="hub-shipping-address-wrapper hub-content-section">
            <div className="hub-shipping-address-title-wrapper">
              <h2 className="hub-content-title">Shipping Address</h2>
              <div className="hub-to-service-link-wrapper">
                <a
                  className="hub-to-service-link"
                  href="https://www.polarpro.com/account/shipping-address/"
                >
                  Edit
                </a>
                <span className="hub-to-service-link-pipe"> | </span>
                <a
                  className="hub-to-service-link"
                  href="https://www.polarpro.com/account/shipping-address/"
                >
                  View Addresses
                </a>
              </div>
            </div>
            <div className="hub-shipping-address-display-wrapper">
              <div className="hub-shipping-address-display">
                {auth?.defaultAddress ? (
                  <>
                    <p className="hub-shipping-address">
                      {auth?.defaultAddress.address1}{" "}
                      {auth?.defaultAddress.address2}
                      <br />
                      {auth?.defaultAddress.city}{" "}
                      {auth?.defaultAddress.province_code}{" "}
                      {auth?.defaultAddress.zip}
                    </p>
                    <div className="hub-user-phone-wrapper">
                      {/* <PhoneIcon className="hub-phone-icon"/> */}
                      <PhoneIcon width={18} />
                      <span className="hub-user-phone">{auth?.phone}</span>
                    </div>
                  </>
                ) : (
                  <a
                    href="https://www.polarpro.com/account/shipping-address/"
                    className="hub-my-products-address-wrapper"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="hub-plus-sign"
                      width="65"
                      height="65"
                      viewBox="0 0 65 65"
                    >
                      <g
                        id="Group_1847"
                        data-name="Group 1847"
                        transform="translate(-1013 -1691)"
                      >
                        <g
                          id="Ellipse_105"
                          className="hub-plus-sign-hover-stroke"
                          data-name="Ellipse 105"
                          transform="translate(1013 1691)"
                          fill="none"
                          stroke="#707070"
                          stroke-width="8"
                        >
                          <circle cx="32.5" cy="32.5" r="32.5" stroke="none" />
                          <circle cx="32.5" cy="32.5" r="28.5" fill="none" />
                        </g>
                        <rect
                          id="Rectangle_413"
                          className="hub-plus-sign-hover-fill"
                          data-name="Rectangle 413"
                          width="36"
                          height="8"
                          transform="translate(1028 1720)"
                          fill="#707070"
                        />
                        <rect
                          id="Rectangle_436"
                          className="hub-plus-sign-hover-fill"
                          data-name="Rectangle 436"
                          width="36"
                          height="8"
                          transform="translate(1042 1742) rotate(-90)"
                          fill="#707070"
                        />
                      </g>
                    </svg>
                    <span className="hub-address-icon-text">
                      ADD A NEW ADDRESS
                    </span>
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="hub-bottom-section-wrapper">
            <div className="hub-bottom-left-section-wrapper">
              <div className="hub-bottom-left-section">
                <div className="hub-polarpro-dividend-wrapper hub-content-section">
                  <div className="hub-dividend-title-wrapper">
                    <h2 className="hub-content-title">PolarPro Dividend</h2>
                    <div className="hub-to-service-link-wrapper">
                      <Link
                        className="hub-to-service-link"
                        to={`/pages/polarpro-rewards/${
                          location.search ? location.search : ""
                        }`}
                      >
                        More Info
                      </Link>
                    </div>
                  </div>
                  <div className="hub-dividend-balance-wrapper">
                    <div className="hub-dividend-balance-display">
                      <div
                        id="customer-point-balance"
                        className="swell-data-balance"
                      >
                        <i className="fa fa-spin fa-spinner"></i>
                      </div>
                      <p className="hub-dividend-text">Reward Balance</p>
                    </div>
                  </div>
                </div>
                <div className="hub-wish-list-wrapper hub-content-section pb-[10px]">
                  <div className="hub-wish-list-icon-wrapper">
                    <WishListIcon />
                    {/* <WishListIcon alt="Wish List Icon" className="hub-wish-list-icon" /> */}
                  </div>
                  <div className="hub-golden-title-wrapper">
                    <h2 className="hub-content-title golden">Wish List</h2>
                  </div>
                  <hr className="content-section-line-breaker--wish-list" />
                  <div className="hub-content-section-selection-wrapper">
                    <Link
                      to={`/pages/wish-list/${
                        location.search ? location.search : ""
                      }`}
                      className="hub-content-section-selection"
                    >
                      <p>View List</p>
                      <FaChevronRight className="hub-content-section-selection-arrow" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="hub-bottom-right-section-wrapper hub-content-section">
              <div className="hub-bottom-right-section">
                <div className="hub-my-products-top-wrapper">
                  <div className="hub-my-products-icon-wrapper ">
                    {/* <ShoppingCartIcon alt="Shopping Cart Icon" className="hub-my-products-icon" /> */}
                    <ShoppingCartIcon />
                  </div>
                  <div className="hub-content-section-selection-wrapper--products"></div>
                </div>
                <div className="hub-golden-title-wrapper">
                  <h2 className="hub-content-title golden">My Products</h2>
                </div>
                <hr className="content-section-line-breaker my-products-line-breaker lg:mb-[117px]" />
                <div className="hub-content-section-selections-wrapper">
                  <div className="hub-content-section-selection-wrapper">
                    <a
                      href="https://www.polarpro.com/support/track-order/"
                      className="hub-content-section-selection"
                    >
                      <p>Order History</p>
                      <FaChevronRight className="hub-content-section-selection-arrow" />
                    </a>
                  </div>
                  <hr className="content-section-selection-divider" />
                  <div className="hub-content-section-selection-wrapper">
                    <a
                      href="https://polarpro.loopreturns.com/#/"
                      target="_blank"
                      className="hub-content-section-selection"
                    >
                      <p>Returns</p>
                      <FaChevronRight className="hub-content-section-selection-arrow" />
                    </a>
                  </div>
                  <hr className="content-section-selection-divider" />
                  <div className="hub-content-section-selection-wrapper mb-[10px]">
                    <Link
                      to={`/support/contact-us/${
                        location.search ? location.search : ""
                      }`}
                      className="hub-content-section-selection"
                    >
                      <p>Contact Us</p>
                      <FaChevronRight className="hub-content-section-selection-arrow" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileHub
