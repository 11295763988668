import * as React from "react"
// Import buttons components here
import PrimaryButton from "../../components/PrimaryButton"
import SecondaryButton from "../../components/SecondaryButton"
import AddToCart from "../../components/AddToCart"
import AddToWishlist from "../../components/AddToWishlist"

// Import logos components here
// import WrittenLogoMark from '../../components/Icons/WrittenLogoMark';
// import TeardropIcon from '../../components/Icons/TeardropIcon';
// import StandardIcon from '../../components/Icons/StandardIcon';
// import AccountIcon from '../../components/Icons/AccountIcon';
// import BasketIcon from '../../components/Icons/BasketIcon';
// import CurrencyDropdownIcon from '../../components/Icons/CurrencyDropdownIcon';
// import SearchIcon from '../../components/Icons/SearchIcon';
// import FacebookIcon from '../../components/Icons/FacebookIcon';
// import InstagramIcon from '../../components/Icons/InstagramIcon';
// import TwitterIcon from '../../components/Icons/TwitterIcon';
// import VimeoIcon from '../../components/Icons/VimeoIcon';
// import YoutubeIcon from '../../components/Icons/YoutubeIcon';
// import CashBackIcon from '../../components/Icons/CashBackIcon';
// import CashBackIconWithText from '../../components/Icons/CashBackIconWithText';
// import AdventureAssuranceIcon from '../../components/Icons/AdventureAssuranceIcon';
// import ShippingIcon from '../../components/Icons/ShippingIcon';
// import ShippingIconWithText from '../../components/Icons/ShippingIconWithText';
// import ChevronUpIcon from '../../components/Icons/ChevronUpIcon';
// import ChevronDownIcon from '../../components/Icons/ChevronDownIcon';

const ComponentPalettePage = () => (
  <div className="m-2">
    <h1>Component Palette for Designers and Developers</h1>
    <div>
      <h2>Buttons</h2>
      <h4>Primary Buttons</h4>
      <div>
        <PrimaryButton text="this is a text" link="#" />
      </div>
      <br />
      <h4>Primary Buttons on Dark Background</h4>
      <div className="bg-obsidian p-10">
        {/* options for style : whiteBorder / goldBorder */}
        <PrimaryButton
          text="Explore the collection"
          link="#"
          buttonStyle="whiteBorder"
        />
        <br />
        <br />
        <PrimaryButton
          text="Explore the collection"
          link="#"
          buttonStyle="goldBorder"
        />
      </div>
      <br />
    </div>
    <div>
      <h4>Secondary Buttons</h4>
      <div>
        <SecondaryButton text="shop Now" link="#" />
      </div>
      <br />
      <h4>Secondary Buttons on Dark Background</h4>
      <div className="bg-obsidian p-10">
        <SecondaryButton text="shop Now" link="#" buttonStyle="white" />
        <br />
        <br />
        <SecondaryButton text="shop Now" link="#" buttonStyle="gold" />
      </div>
      <br />
    </div>
    <br />
    <div>
      <h4>ADD TO CART Buttons</h4>
      <p className="text-12">
        ADD TO CART button requires product availability and variant ID to be
        passed in as props. Those are passed in as: available, variantId. You
        may also pass in an integer for quantity, boolean value for preorder and
        backorder props.
      </p>
      <div className="p-10">
        <AddToCart available variantId="32789908750420" />
        <br />
        <br />
        <AddToCart available={false} variantId="32789908750420" />
        <br />
        <br />
        <AddToCart available={false} variantId="32789908750420" preorder />
        <br />
        <br />
        <AddToCart available={false} variantId="32789908750420" backorder />
      </div>
      <br />
      <h4>ADD TO WISHLIST Buttons</h4>
      <p className="text-12">
        ADD TO WISHLIST button requires product ID and variant ID to be passed
        in as props. Those are passed in as: productId, variantId. You may also
        pass in a integer for quantity, string value for customer ID.
      </p>
      <div className="p-10">
        <AddToWishlist
          customerId="123456789"
          productId="4750903836756"
          variantId="32789908750420"
        />
        <br />
        <br />
        <AddToWishlist productId="4750903836756" variantId="32789908750420" />
      </div>
      <br />
    </div>
    <br />
    {/* <div>
      <h2>Logos and Icons</h2>
      <h4>Logos</h4>
      <div>
        Logos go here
        <h6>Written Logomark:</h6>
        <WrittenLogoMark />
        <h6>Teardrop Icon:</h6>
        <TeardropIcon />
        <h6>Default Size Standard Icon:</h6>
        <StandardIcon />
        <h6>Standard Icon with passed-in prop width 50:</h6>
        <StandardIcon width={50} />
      </div>
      <br />
      <h4>Icons</h4>
      <div>
        Icons go here
        <h6>Account Icon:</h6>
        <AccountIcon />
        <h6>Basket Icon:</h6>
        <BasketIcon />
        <h6>CurrencyDropdown Icon:</h6>
        <CurrencyDropdownIcon />
        <h6>Search Icon:</h6>
        <SearchIcon />
        <h6>Facebook Icon:</h6>
        <FacebookIcon />
        <h6>Instagram Icon:</h6>
        <InstagramIcon />
        <h6>Twitter Icon:</h6>
        <TwitterIcon />
        <h6>Vimeo Icon:</h6>
        <VimeoIcon />
        <h6>Youtube Icon:</h6>
        <YoutubeIcon />
        <h6>CashBack Icon:</h6>
        <CashBackIcon />
        <h6>CashBack Icon With Text:</h6>
        <CashBackIconWithText />
        <h6>AdventureAssurance Icon:</h6>
        <AdventureAssuranceIcon />
        <h6>AdventureAssurance Icon With Text:</h6>
        <AdventureAssuranceIconWithText />
        <h6>Shipping Icon:</h6>
        <ShippingIcon />
        <h6>Shipping Icon With Text:</h6>
        <ShippingIconWithText />
        <h6>Chevron Up</h6>
        <ChevronUpIcon width={35} />
        <h6>Chevron Down</h6>
        <ChevronDownIcon width={35} />
      </div>
      <br />
    </div> */}
  </div>
)

export default ComponentPalettePage
