/* eslint-disable */

import { useLocation } from "@reach/router"
import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

const truncateDescription = (text, maxWords = 11) => {
    const plainText = text.replace(/<\/?[^>]+>/gi, "")
    const words = plainText.split(/\s+/)
    const truncatedWords = words.slice(0, maxWords).join(" ")
    return words.length > maxWords ? `${truncatedWords}...` : truncatedWords
}
const SearchInfo = React.memo(props => {
    const location = useLocation()

    const { url, image, title, description, price, compareAt } = props
    const truncatedDescription = truncateDescription(description)
    return (
        <Link
            to={`/products/${url}`}
            className=""
        >
            <div className="product-card mb-[10px]">
                <div className="productImage bg-white px-[25%] rounded-md bg-white">
                    <img src={image} />
                </div>

                <div className="hoverDataContainer">
                    <span className="text-base text-[#1C1C1C] font-black font-expanded block uppercase mb-0 mt-[20px]">
                        {title}
                    </span>
                    <div className="text-base text-gray-400 mt-2 md:mt-5">
                        {truncatedDescription}
                    </div>
                    <div className="flex items-center">
                        <p className="text-base text-[#1C1C1C] font-bold font-condensed mt-5 pr-1">$ {price}</p>
                        {compareAt > 0 ? <p className="line-through pl-2 italic mt-5 text-[#ababab] font-bold">$ {compareAt}</p> : null}
                    </div>
                </div>
            </div>
        </Link>
    )
})

export default SearchInfo
