/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"

const BuildQuality = ({ blok }) => {
  const { title, subTitle, renderImage, hoverImage } = blok

  return (
    <div className="mt-20">
      <p className="display-font font-black text-obsidian text-center mb-2 text-[18.75px]">
        {title}
      </p>
      <p className="text-center font-medium text-sm text-dim-gray mb-2">
        {subTitle}
      </p>
      <hr className="w-[100px] text-center h-[2px] bg-paloverde mx-auto mb-20 mt-2" />
      <div className="relative w-full">
        <img className="w-full" src={renderImage} />
        <img className="absolute left-0 top-0 w-full" src={hoverImage} />
      </div>
    </div>
  )
}

export default BuildQuality

BuildQuality.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  renderImage: PropTypes.string,
  hoverImage: PropTypes.string,
}

BuildQuality.defaultProps = {
  title: "Build Quality",
  subTitle: "Crafted to help create the ultimate visual experience",
  renderImage: "",
  hoverImage:
    "https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/UV-DESKTOP-BUILD-QUALITY-HOVERED.png?v=107774812689501350651616624484",
}
