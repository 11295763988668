import { Link } from "gatsby"
import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
interface State {
  [key: string]: boolean
}

const SupportComponent = () => {
  const { allStoryblokEntry } = useStaticQuery(graphql`
    query {
      allStoryblokEntry(filter: { name: { eq: "faqs" } }) {
        edges {
          node {
            name
            content
          }
        }
      }
    }
  `)
  const questionAnswers = allStoryblokEntry.edges[0].node.content
  const content = JSON.parse(questionAnswers)

  const [isOpen, setIsOpen] = useState<State>({})

  const toggleOpen = (index: string) => {
    setIsOpen({ ...isOpen, [index]: !isOpen[index] })
  }

  const scrollSmoothlyToTarget = () => {
    const targetElement = document.getElementById("scrollTarget")
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <div className="w-full bg-[#F0F0F0]">
      <div
        className="w-full py-10 flex justify-center items-center mx-auto bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: "url(/images/BACKGROUND.webp)" }}
      >
        <div className="w-full flex justify-center flex-col lg:max-w-4xl md:max-w-xl max-w-lg">
          <div className=" w-full md:px-0 px-[8%]">
            <h1 className="text-white font-sans text-4xl md:text-[48px] font-bold text-center font-condensed font-expanded pt-9">
              Support
            </h1>
          </div>
          <div className="md:flex grid grid-cols-2 w-full md:justify-start items-center md:flex-wrap pt-12 sm:gap-14 gap-5">
            <div className="relative flex flex-col justify-center items-center">
              <Link className="m-0" to="/support/track-order/">
                <div className="hover:border hover:border-yellow-500 hover:border-[3px] rounded-[25px] overflow-hidden border-transparent border-[3px]">
                  <img
                    className="mb-0 support_img"
                    src="/images/TRACK-ORDER.png"
                    alt=""
                  />
                </div>
              </Link>
              <h4 className="text-white text-center md:text-lg text-sm font-bold pt-4 font-expanded">
                TRACK ORDER
              </h4>
            </div>

            <div
              className="relative flex flex-col justify-center items-center cursor-pointer "
              onClick={scrollSmoothlyToTarget}
            >
              <Link to="https://polarpro.zendesk.com/hc/en-us" target="_blank">
                <div className="hover:border hover:border-yellow-500 hover:border-[3px] border-transparent border-[3px] rounded-[25px] overflow-hidden">
                  <img
                    className="mb-0 support_img"
                    src="/images/FAQ.png"
                    alt=""
                  />
                </div>
              </Link>
              <h4 className="text-white text-center md:text-lg text-sm pt-4 font-bold font-expanded">
                FAQ
              </h4>
            </div>
            <div className="relative cursor-pointer flex flex-col justify-center items-center">
              <Link to="/support/store-locator">
                <div className="hover:border hover:border-yellow-500 hover:border-[3px] border-transparent border-[3px] rounded-[25px] overflow-hidden">
                  <img
                    className="mb-0 support_img"
                    src="/images/DEALER-LOCATOR.png"
                    alt=""
                  />
                </div>
                <h4 className="text-white md:text-lg text-sm text-center pt-4 font-bold font-expanded">
                  DEALER LOCATOR
                </h4>
              </Link>
            </div>
            <div className="relative flex flex-col justify-center items-center cursor-pointer">
              <Link className="m-0" to="/support/contact-us/" target="_blank">
                <div className="hover:border hover:border-yellow-500  hover:border-[3px] border-transparent border-[3px] rounded-[25px] overflow-hidden">
                  <img
                    className="mb-0 support_img"
                    src="/images/EMAIL-US.png"
                    alt=""
                  />
                </div>
                <h4 className="text-white text-center md:text-lg text-sm pt-4 font-bold font-expanded">
                  EMAIL US
                </h4>
              </Link>
            </div>
            <div className="relative flex flex-col justify-center items-center cursor-pointer">
              <Link
                className="m-0"
                to="https://polarpro.loopreturns.com/#/"
                target="_blank"
              >
                <div className="hover:border hover:border-yellow-500 hover:border-[3px] border-transparent border-[3px] rounded-[25px] overflow-hidden">
                  <img
                    className="mb-0 support_img"
                    src="/images/RETURNS.png"
                    alt=""
                  />
                </div>
                <h4 className="text-white text-center md:text-xl text-sm pt-4 font-bold font-expanded">
                  RETURNS
                </h4>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div
        className=" py-12 w-full flex justify-center items-center"
        id="scrollTarget"
      >
        <div className="w-full flex justify-center flex-col lg:max-w-4xl md:max-w-xl max-w-md lg:px-2 px-0">
          <div className=" w-full">
            <h1 className="font-sans text-start font-bold text-3xl md:text-[40px] md:px-0 px-3 text-center font-condensed font-expanded">
              Top FAQ’s
            </h1>
          </div>
          <div className="grid grid-cols-1 overflow-y-auto">
            <div className="h-[300px]">
              {content?.faqs?.map((qa: any, index: any) => (
                <div
                  key={index}
                  className={`rounded w-[95%] mx-auto  ${
                    index % 2 === 0 ? "bg-white" : ""
                  }`}
                >
                  <div
                    className="flex justify-between cursor-pointer py-2 items-center"
                    onClick={() => toggleOpen(index.toString())}
                  >
                    <div className="md:text-md text-[10.96px] font-bold font-helvetica-neue text-black px-4 font-condensed font-expanded">
                      {qa?.title.toUpperCase()}
                    </div>
                    <div className={`text-xl px-4 `}>
                      {isOpen[index] ? "-" : "+"}
                    </div>
                  </div>
                  <div
                    style={{
                      maxHeight: isOpen[index] ? "400px" : "20px",
                      transition: isOpen[index]
                        ? "opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, max-height 0.5s ease-in-out,hidden 0.5s ease-in-out"
                        : "",
                    }}
                  >
                    {isOpen[index] && (
                      <div className=" px-4 pb-6 md:text-md text-sm font-helvetica-neue text-[#888888] font-condensed font-expanded">
                        {qa?.description}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupportComponent
