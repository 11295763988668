/* eslint-disable */
import React from "react"

const ImageCarouselArrow = ({
  onClick,
  right,
  subCarousel,
  imagesLength,
  ...rest
}) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest

  const ariaLabelDirection = right ? "next" : "previous"

  const rightArrow =
    'right-0 bg-[url("//cdn.shopify.com/s/files/1/1050/9944/t/167/assets/arrow-right.svg?v=17741746230017736812")]'
  const leftArrow =
    'left-0 bg-[url("//cdn.shopify.com/s/files/1/1050/9944/t/167/assets/arrow-left.svg?v=16024850607537662625")]'

  let buttonStyle = `
    block absolute w-8 h-7
    opacity-50 hover:opacity-100
    transition hover:transition
    bg-center bg-no-repeat bg-transparent
    ${right ? rightArrow : leftArrow}
  `

  return (
    <button
      type="button"
      data-role="none"
      className={buttonStyle}
      aria-label={`${ariaLabelDirection}`}
      onClick={() => onClick()}
    />
  )
}

export default ImageCarouselArrow
