import React from "react"
import PropTypes from "prop-types"
import "./style.scss"

const TileContents = ({ blok }) => {
  /* eslint-disable camelcase */
  /* eslint-disable */
  const {
    first_heading,
    first_subtitle,
    full_banner_desktop,
    full_banner_mobile,
    second_heading,
    second_subtitle,
    left_banner_desktop,
    left_banner_mobile,
    third_heading,
    third_subtitle,
    right_banner_desktop,
    right_banner_mobile,
  } = blok

  return (
    <>
      <div className="below-section-litechaser13">
        <div className="full-width-container">
          <div
            style={{
              backgroundImage: `url("${full_banner_desktop}")`,
            }}
            className="full-banner bg-[image:var(--fullbanner-desktop)] md:bg-[image:var(--fullbanner-mobile)]"
          >
            <div className="text-wrapper">
              <p className="title">{first_heading}</p>
              <p className="sub-title">{first_subtitle}</p>
            </div>
          </div>
        </div>
        <div className="full-width-container">
          <div
            style={{
              backgroundImage: `url("${left_banner_desktop}")`,
            }}
            className="left-banner bg-[image:var(--leftbanner-desktop)] md:bg-[image:var(--leftbanner-mobile)]"
          >
            <div className="text-wrapper">
              <p className="title">{second_heading}</p>
              <p className="sub-title">{second_subtitle}</p>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url("${right_banner_desktop}")`,
            }}
            className="right-banner bg-[image:var(--rightbanner-desktop)] md:bg-[image:var(--rightbanner-mobile)]"
          >
            <div className="text-wrapper">
              <p className="title">{third_heading}</p>
              <p className="sub-title">{third_subtitle}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TileContents

TileContents.propTypes = {
  blok: PropTypes.shape({
    first_heading: PropTypes.string.isRequired,
    first_subtitle: PropTypes.string.isRequired,
    full_banner_desktop: PropTypes.string.isRequired,
    full_banner_mobile: PropTypes.string.isRequired,
    second_heading: PropTypes.string.isRequired,
    second_subtitle: PropTypes.string.isRequired,
    left_banner_desktop: PropTypes.string.isRequired,
    left_banner_mobile: PropTypes.string.isRequired,
    third_heading: PropTypes.string.isRequired,
    third_subtitle: PropTypes.string.isRequired,
    right_banner_desktop: PropTypes.string.isRequired,
    right_banner_mobile: PropTypes.string.isRequired,
  }).isRequired,
}
