import React from "react"
import PropTypes from "prop-types"

const ImageBanner = ({ blok }) => {
  const { desktopImage, desktopImageAlt, mobileImage, mobileImageAlt } = blok

  return (
    <div className="w-full relative mt-20">
      <img
        src={desktopImage}
        className="hidden md:block"
        alt={desktopImageAlt}
      />
      <img src={mobileImage} className="md:hidden" alt={mobileImageAlt} />
    </div>
  )
}

export default ImageBanner

ImageBanner.propTypes = {
  blok: PropTypes.shape({
    desktopImage: PropTypes.string,
    mobileImage: PropTypes.string,
    desktopImageAlt: PropTypes.string,
    mobileImageAlt: PropTypes.string,
  }),
}

ImageBanner.defaultProps = {
  blok: {
    desktopImage: "",
    mobileImage: "",
    desktopImageAlt: "Hero Image for desktop",
    mobileImageAlt: "Hero Image for mobile",
  },
}
