/* eslint-disable */
import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
// import Ellipse from '../../images/ellipse_10.svg';

const ElementsSizeChart = props => {
  const { data } = props
  const [toggleRight, setToggleRight] = useState(false)
  const toggleMetric = e => {
    e.preventDefault()
    setToggleRight(!toggleRight)
  }

  return (
    <div id="clothing-size-and-fit-modal">
      <div className="wrappers">
        <h1>Size Guide</h1>
        <hr className="offset" />
        <div className="toggle-wrapper">
          <div
            className={`toggler ${toggleRight ? "right" : ""}`}
            onClick={toggleMetric}
          >
            <span className="in">IN</span>
            {/* <Ellipse className="left" /> */}
            <StaticImage
              src="../../images/ellipse_10.svg"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              imgClassName="left"
              alt="Toggle"
            />
            <span className="cm">CM</span>
          </div>
        </div>
        {toggleRight ? (
          <table className="cm">
            <thead>
              <tr>
                <th>Size</th>
                <th>Chest</th>
                <th>Waist</th>
                <th>Body Length</th>
                <th>Sleeve</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>S</th>
                <th>89-94</th>
                <th>71-76</th>
                <th>70</th>
                <th>84-86</th>
              </tr>
              <tr>
                <th>M</th>
                <th>97-102</th>
                <th>79-84</th>
                <th>73</th>
                <th>86-89</th>
              </tr>
              <tr>
                <th>L</th>
                <th>107-112</th>
                <th>87-91</th>
                <th>75</th>
                <th>89-91</th>
              </tr>
              <tr>
                <th>XL</th>
                <th>114-122</th>
                <th>94-102</th>
                <th>77</th>
                <th>91-94</th>
              </tr>
              <tr>
                <th>XXL</th>
                <th>124-135</th>
                <th>104-112</th>
                <th>80</th>
                <th>94-97</th>
              </tr>
            </tbody>
          </table>
        ) : (
          <table className="in">
            <thead>
              <tr>
                <th>Size</th>
                <th>Chest</th>
                <th>Waist</th>
                <th>Body Length</th>
                <th>Sleeve</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>S</th>
                <th>35-37</th>
                <th>28-30</th>
                <th>27.5</th>
                <th>33-34</th>
              </tr>
              <tr>
                <th>M</th>
                <th>38-40</th>
                <th>31-33</th>
                <th>28.5</th>
                <th>34-35</th>
              </tr>
              <tr>
                <th>L</th>
                <th>42-44</th>
                <th>34-36</th>
                <th>29.5</th>
                <th>35-36</th>
              </tr>
              <tr>
                <th>XL</th>
                <th>45-48</th>
                <th>37-40</th>
                <th>30.5</th>
                <th>36-37</th>
              </tr>
              <tr>
                <th>XXL</th>
                <th>49-53</th>
                <th>41-44</th>
                <th>31.5</th>
                <th>37-38</th>
              </tr>
            </tbody>
          </table>
        )}
        <h1>How To Measure</h1>
        <p className="gold-text">
          *Chest, waist, and sleeve are based on
          <u>your</u> body measurement.
        </p>
        <p className="gold-text">
          *Body length is based on
          <u>garment</u> measurements.
        </p>
        <hr />
        <p className="caption">
          <b>Chest - </b>
          Measure around your chest under the armpit.
        </p>
        <p className="caption">
          <b>Waist - </b>
          Measure around your waist just above the hips.
        </p>
        <p className="caption">
          <b>Sleeve - </b>
          Measure from the center of the neck down to the wrist.
        </p>
        <p className="caption">
          <b>Body Length - </b>
          Measured from the high-shoulder top to bottom.
        </p>
      </div>
    </div>
  )
}

export default ElementsSizeChart
