import React from "react"
import * as style from "./style.module.scss"

const NewBadge = () => (
  <div
    className={`
      bg-paloverde text-white font-bold text-sm ${style.newIcon}
    `}
  >
    New
  </div>
)

export default NewBadge
