/* eslint-disable  */

import { Link } from "@reach/router"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { FaRegTimesCircle } from "react-icons/fa"
import useAuth from "../../hooks/useAuth"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import productShopifyIdDecoder from "../../utils/shopifyProductIdDecoder"
import FormError from "../FormError"
import { useLocation } from "@reach/router"
import { functionsEndpoint } from "../../config"
import axiosLib from "axios"

const defaultFields = {
  firstName: "",
  lastName: "",
  company: "",
  address1: "",
  address2: "",
  city: "",
  country: "",
  province: "",
  zip: "",
  phone: "",
  default: false,
}

const UpdateShippingAddress = () => {
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [fields, setFields] = useState(defaultFields)
  const isBrowser = typeof window !== "undefined"
  const { auth, setAuth } = useAuth()
  const [found, setFound] = useState(true)
  const privateAxios = useAxiosPrivate()

  const getShippingAddress = async () => {
    await axiosLib
      .post(
        functionsEndpoint + "/addresses",
        {},
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then(result => {
        if (result.data?.length) {
          const query = new URLSearchParams(window.location.search).get("id")
          if (query?.length) {
            const selected = result.data.filter((address: Address) => {
              const clipped = address.id
                .split("gid://shopify/MailingAddress/")?.[1]
                ?.split("?")[0]
              const clippedQuery = query
                .split("gid://shopify/MailingAddress/")?.[1]
                ?.split("?")[0]
              if (clipped === clippedQuery) {
                return address
              }
              return false
            })?.[0]
            if (selected) {
              setFields({
                address1: selected.address1,
                address2: selected.address2,
                city: selected.city,
                company: selected.company,
                country: selected.country,
                default: selected.defaultAddress || false,
                firstName: selected?.firstName || auth?.firstName || "",
                lastName: selected?.lastName || auth?.lastName || "",
                phone: selected.phone,
                province: selected.province,
                zip: selected.zip,
              })
              setFound(true)
            } else {
              setFound(false)
            }
          } else {
            setFields(defaultFields)
            setFound(false)
          }
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  useEffect(() => {
    setLoading(true)
    if (!auth?.accessToken || !auth?.email) {
      axiosLib
        .get(functionsEndpoint + "/account", {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(result => {
          const {
            id,
            email,
            firstName,
            lastName,
            defaultAddress,
            phone,
            metafields,
          } = result.data?.customer
          setLoading(false)
          // Properly format retrieved data
          const customerShopifyId = productShopifyIdDecoder(id)
          const birthday = metafields?.edges?.filter?.(
            (metafield: any) => metafield.key === "birthDate",
          )
          const birthdayStr =
            birthday?.length > 0
              ? birthday?.[0]?.value
                  ?.replace?.(/-/g, "/")
                  ?.toDateString?.()
                  .substr?.(4)
              : "---"

          // Update auth info
          setAuth?.({
            ...auth,
            id: customerShopifyId,
            email: email,
            firstName: firstName,
            lastName: lastName,
            defaultAddress: defaultAddress,
            phone: phone,
            birthday: birthdayStr,
          })
        })
        .catch(err => {
          console.error("error retrieving data", err.message)
          if (!err?.response) {
            console.error("No Server Response")
          } else if (err.response?.status === 400) {
            console.error("Bad Request")
          } else if (err.response?.status === 401) {
            console.error("Unauthorized")
          } else if (err.reponse?.status === 403) {
            console.error("Forbidden")
          } else if (err.response.status === 500) {
            console.error(
              `Internal server error occured. Message from server: ${err.message}`,
            )
          } else {
            console.error(
              `Unknown error occured. Message from server: ${err.message}`,
            )
          }
        })
    } else {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (auth?.id) {
      getShippingAddress()
    }
  }, [auth])

  useEffect(() => {
    if (!found) {
      navigate(-1)
    }
  }, [found])
  const [msg, setMsg] = useState("")
  const submitHandler = async (event: React.SyntheticEvent) => {
    setUpdateLoading(true)
    event.preventDefault()
    const id = new URLSearchParams(window.location.search).get("id")
    setMsg("")
    await axiosLib
      .post(
        functionsEndpoint + "/update-address",
        {
          id,
          ...fields,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then(result => {
        if (result?.data?.customerAddress) {
          navigate(
            `/account/shipping-address/${
              location.search ? location.search : ""
            }`,
          )
          return
        }
        setUpdateLoading(false)
        setMsg("An error occured")
      })
      .catch(err => {
        const message = JSON.parse(JSON.stringify(err))?.message
        setMsg(message || "An error occured")
      })
  }
  if (!found || !fields?.address1) return null
  return (
    <>
      {loading ? (
        <div role="status" className="mt-6 flex justify-center items-center">
          <svg
            aria-hidden="true"
            className="w-24 h-24 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div className="flex flex-col min-h-screen w-full lg:justify-center items-center">
          <div className="flex justify-between items-center w-full pb-1 border-b-4 border-black max-w-4xl px-6 2xl:px-0 lg:max-w-4xl">
            <div className="flex items-center">
              <p className="uppercase text-lg text-obsidian font-extrabold mb-0">
                UPDATE SHIPPING ADDRESS
              </p>
            </div>
            <button onClick={() => navigate(-1)}>
              <FaRegTimesCircle className="w-6 h-6 text-obsidian hover:text-paloverde" />
            </button>
          </div>
          {msg?.length ? <FormError msg={msg} /> : null}
          <form
            onSubmit={submitHandler}
            className="flex flex-col items-center w-full px-6 2xl:px-0 lg:max-w-4xl"
          >
            <div className="mt-4 flex flex-col lg:flex-row justify-between items-center w-full space-y-4 lg:space-y-0 lg:space-x-4">
              <div className="border-2 border-black px-3 pt-2 w-full">
                <p className="mb-0">First Name</p>
                <input
                  placeholder="John"
                  className="w-full pb-2 bg-transparent"
                  value={fields.firstName}
                  required
                  onChange={e =>
                    setFields({ ...fields, firstName: e.target.value })
                  }
                />
              </div>
              <div className="border-2 border-black px-3 pt-2 w-full">
                <p className="mb-0">Last Name</p>
                <input
                  placeholder="Doe"
                  className="w-full pb-2 bg-transparent"
                  value={fields.lastName}
                  required
                  onChange={e =>
                    setFields({ ...fields, lastName: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="mt-4 flex justify-between items-center w-full space-x-4">
              <div className="border-2 border-black px-3 pt-2 w-full">
                <p className="mb-0">Company</p>
                <input
                  placeholder=""
                  type="text"
                  className="w-full pb-2 bg-transparent"
                  value={fields.company}
                  required
                  onChange={e =>
                    setFields({ ...fields, company: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="mt-4 flex justify-between items-center w-full space-x-4">
              <div className="border-2 border-black px-3 pt-2 w-full">
                <p className="mb-0">Address 1</p>
                <input
                  placeholder=""
                  type="text"
                  value={fields.address1}
                  required
                  onChange={e =>
                    setFields({ ...fields, address1: e.target.value })
                  }
                  className="w-full pb-2 bg-transparent"
                />
              </div>
            </div>
            <div className="mt-4 flex justify-between items-center w-full space-x-4">
              <div className="border-2 border-black px-3 pt-2 w-full">
                <p className="mb-0">Address 2</p>
                <input
                  placeholder=""
                  type="text"
                  className="w-full pb-2 bg-transparent"
                  value={fields.address2}
                  onChange={e =>
                    setFields({ ...fields, address2: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="mt-4 flex flex-col md:flex-row justify-between items-center w-full md:space-x-4 space-y-4 md:space-y-0">
              <div className="border-2 border-black px-3 pt-2 w-full">
                <p className="mb-0">City</p>
                <input
                  placeholder="Huntington Beach"
                  className="w-full pb-2 bg-transparent"
                  value={fields.city}
                  required
                  onChange={e => setFields({ ...fields, city: e.target.value })}
                />
              </div>
              <div className="border-2 border-black px-3 pt-2 w-full">
                <p className="mb-0">Country</p>
                <input
                  placeholder="United States"
                  className="w-full pb-2 bg-transparent"
                  value={fields.country}
                  required
                  onChange={e =>
                    setFields({ ...fields, country: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="mt-4 flex justify-between items-center w-full space-x-4">
              <div className="border-2 border-black px-3 pt-2 w-full">
                <p className="mb-0">Province</p>
                <input
                  placeholder="California"
                  type="text"
                  className="w-full pb-2 bg-transparent"
                  value={fields.province}
                  required
                  onChange={e =>
                    setFields({ ...fields, province: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="mt-4 flex flex-col lg:flex-row justify-between items-center w-full space-y-4 lg:space-y-0 lg:space-x-4">
              <div className="border-2 border-black px-3 pt-2 w-full">
                <p className="mb-0">Postal / Zip</p>
                <input
                  placeholder="92646"
                  className="w-full pb-2 bg-transparent"
                  value={fields.zip}
                  required
                  onChange={e => setFields({ ...fields, zip: e.target.value })}
                />
              </div>
              <div className="border-2 border-black px-3 pt-2 w-full">
                <p className="mb-0">Phone (000-000-0000)</p>
                <input
                  placeholder="000-000-0000"
                  className="w-full pb-2 bg-transparent"
                  value={fields.phone}
                  required
                  onChange={e =>
                    setFields({ ...fields, phone: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="mt-4 flex justify-between items-center w-full space-x-4">
              <button
                type="button"
                onClick={() =>
                  setFields({ ...fields, default: !fields.default })
                }
                className="mb-0 flex items-center border-2 border-gray-800"
              >
                <p className="mb-0 border-r-2 border-gray-800 m-0 px-10 py-3 text-lg leading-8 font-bold">
                  Set As Default Address
                </p>
                <div
                  className="px-5 py-4 mb-0 h-full flex justify-center items-center"
                  style={{ opacity: fields.default ? 1 : 0 }}
                >
                  <p className="mb-0 text-14 font-extrabold">X</p>
                </div>
              </button>
            </div>
            <button className="text-11 flex justify-center mt-6 tracking-widest min-w-200px w-full bg-obsidian text-white p-2 font-bold font-expanded uppercase hover:bg-paloverde mb-4 items-center">
              {updateLoading ? (
                <div
                  role="status"
                  className="flex justify-content-center items-center"
                >
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-white"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "   UPDATE ADDRESS"
              )}
            </button>
          </form>
        </div>
      )}
    </>
  )
}

export default UpdateShippingAddress
