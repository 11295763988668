import React from "react"
import PropTypes from "prop-types" // eslint-disable-line
import * as style from "./style.module.scss"

const QuartzlineFilterSpecs = ({ blok }) => {
  const { title, subTitle } = blok

  return (
    <div className={style.specContainer}>
      <p className="display-font font-black text-obsidian text-center mb-2 text-[18.75px]">
        {title}
      </p>
      <p className="text-center font-medium text-sm text-dim-gray mb-2">
        {subTitle}
      </p>
      <hr className="text-center w-[100px] h-[2px] bg-paloverde mx-auto mb-20 mt-2" />
      <div className={style.chartComparison}>
        <div className="version">
          <table className="w-full">
            <tr>
              <th> </th>
              <th>ND & UV</th>
              <th>ND/PL & CP</th>
            </tr>
            {/* <!-- glass spec --> */}
            <tr>
              <td className="left-th">Glass Material:</td>
              <td>Fused Quartz</td>
              <td>Fused Quartz</td>
            </tr>
            <tr>
              <td className="left-th">Frame Material:</td>
              <td>Aluminium</td>
              <td>Aluminium</td>
            </tr>
            <tr>
              <td className="left-th">Available Thread Sizes:</td>
              <td>67mm, 77mm, 82mm</td>
              <td>67mm, 77mm, 82mm</td>
            </tr>
            {/* <!-- build spec --> */}
            <tr>
              <td className="left-th">Diameter:</td>
              <td>
                <p>67 = 70mm</p>
                <p>77 = 80mm</p>
                <p>82 = 85mm</p>
              </td>
              <td>
                <p>67 = 70.5mm</p>
                <p>77 = 80.5mm</p>
                <p>82 = 85.5mm</p>
              </td>
            </tr>
            <tr>
              <td className="left-th">Depth:</td>
              <td>
                <p>67 = 6.5mm</p>
                <p>77 = 6.5mm</p>
                <p>82 = 6.5mm</p>
              </td>
              <td>
                <p>67 = 7.5mm</p>
                <p>77 = 7.5mm</p>
                <p>82 = 7.5mm</p>
              </td>
            </tr>
            <tr>
              <td className="left-th">Weight:</td>
              <td>
                <p>67 = 29g</p>
                <p>77 = 37g</p>
                <p>82 = 37.5g</p>
              </td>
              <td>
                <p>67 = 32.9g</p>
                <p>77 = 38.8g</p>
                <p>82 = 43.5g</p>
              </td>
            </tr>
          </table>
        </div>
        <div className="compare">
          {/* <!-- compare signature--> */}
          <table className="w-full mt-[50px]">
            <tr>
              <th>filter Type:</th>
              <th>Stop Reduction</th>
              <th>Optical Density</th>
              <th>Transmission %</th>
            </tr>
            <tr>
              <td className="left-th">UV</td>
              <td>0</td>
              <td>0.0</td>
              <td>99.9%</td>
            </tr>
            <tr>
              <td className="left-th">CP</td>
              <td>1.6</td>
              <td>0.4</td>
              <td>33%</td>
            </tr>
            <tr>
              <td className="left-th">ND8</td>
              <td>3</td>
              <td>0.9</td>
              <td>12.5%</td>
            </tr>

            <tr>
              <td className="left-th">ND8/PL</td>
              <td>3</td>
              <td>0.9</td>
              <td>12.5%</td>
            </tr>
            <tr>
              <td className="left-th">ND16</td>
              <td>4</td>
              <td>1.2</td>
              <td>6.25%</td>
            </tr>
            <tr>
              <td className="left-th">ND16/PL</td>
              <td>4</td>
              <td>1.2</td>
              <td>6.25%</td>
            </tr>
            <tr>
              <td className="left-th">ND64</td>
              <td>6</td>
              <td>1.8</td>
              <td>1.5%</td>
            </tr>
            <tr>
              <td className="left-th">ND64/PL</td>
              <td>6</td>
              <td>1.8</td>
              <td>1.5%</td>
            </tr>
            <tr>
              <td className="left-th">ND1000</td>
              <td>10</td>
              <td>3.0</td>
              <td>0.1%</td>
            </tr>
            <tr>
              <td className="left-th">ND1000/PL</td>
              <td>10</td>
              <td>3.0</td>
              <td>0.1%</td>
            </tr>
            <tr>
              <td className="left-th">ND100k</td>
              <td>16.6</td>
              <td>5</td>
              <td>0.001%</td>
            </tr>
          </table>
          {/* <!-- compare signature end--> */}
        </div>
      </div>
    </div>
  )
}

export default QuartzlineFilterSpecs
