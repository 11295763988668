// extracted by mini-css-extract-plugin
export var breakBanner = "style-module--breakBanner--67df4";
export var dataLeft = "style-module--dataLeft--7b8fd";
export var dataRight = "style-module--dataRight--deb68";
export var helixMobilePlayBtn = "style-module--helixMobilePlayBtn--aca81";
export var helixOverview = "style-module--helixOverview--b4574";
export var introData = "style-module--introData--78ee0";
export var introDataLeft = "style-module--introDataLeft--d8dc4";
export var introDataRight = "style-module--introDataRight--e8e60";
export var introRight = "style-module--introRight--ef2d5";
export var leftStrike = "style-module--leftStrike--4b225";
export var productHero = "style-module--productHero--cd948";
export var sandBackg = "style-module--sandBackg--caed1";