/* eslint-disable */
import React from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

const FooterMenuItem = ({ blok }) => {
  const location = useLocation()

  const { link, label } = blok

  return (
    <li className="w-full mb-[2px]">
      <Link to={`${link}/${location.search ? location.search : ""}`}>
        {label}
      </Link>
    </li>
  )
}

export default FooterMenuItem
