/* eslint-disable */
import React, { useEffect, useState } from "react"
import axios from "axios"
import { Link, graphql, navigate, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"
import "./track-order.scss"
import { useLocation } from "@reach/router"
import { functionsEndpoint } from "../../config"

const Tracktor = () => {
  const location = useLocation()

  const [pageContent, setPageContent] = useState(null)
  const [orderNumber, setOrderNumber] = useState(null)
  const [preOrderDate, setPreorderDate] = useState("")

  const [orderUrl, setOrderUrl] = useState(null)
  const [deliverToInfo, setDeliverToInfo] = useState({
    deliverTo: null,
    deliveryAddress: null,
  })
  const [ordered, setOrdered] = useState({ status: false, date: null })
  const [orderReady, setOrderReady] = useState({ status: false, date: null })
  const [inTransit, setInTransit] = useState({ status: false, date: null })
  const [outForDelivery, setOutForDelivery] = useState({
    status: false,
    date: null,
  })
  const [delivered, setDelivered] = useState({ status: false, date: null })
  const [progressBarStatus, setProgressBarStatus] = useState(null)
  const [packageContents, setPackageContents] = useState([])

  const [errorMessage, setErrorMessage] = useState("")

  const [userOrderNumberInput, setUserOrderNumberInput] = useState("")
  const [userEmailInput, setUserEmailInput] = useState("")

  const [showTrackingMap, setShowTrackingMap] = useState(false)

  const [trackingInfo, setTrackingInfo] = useState({
    carrier: null,
    tracking_number: null,
    tracking_url: null,
    date: null,
  })
  const [shopifyOrder, setShopifyOrder] = useState(null)

  const [orderedShortDate, setOrderedShortDate] = useState("")
  const [orderReadyShortDate, setOrderReadyShortDate] = useState("")
  const [inTransitShortDate, setInTransitShortDate] = useState("")
  const [outForDeliveryShortDate, setOutForDeliveryShortDate] = useState("")
  const [deliveredShortDate, setDeliveredShortDate] = useState("")

  const [latestUpdate, setLatestUpdate] = useState({ date: "", time: "" })
  const [productId, setProductId] = useState("")

  const handleUserOrderNumberInputChange = event => {
    setUserOrderNumberInput(event.target.value)
  }

  function resetTrackingOrderData() {
    setOrderNumber(null)
    setOrderUrl(null)
    setDeliverToInfo({ deliverTo: null, deliveryAddress: null })
    setOrderReady({ status: false, date: null })
    setInTransit({ status: false, date: null })
    setOutForDelivery({ status: false, date: null })
    setDelivered({ status: false, date: null })
    setProgressBarStatus(null)
    setPackageContents([])
    setTrackingInfo({
      carrier: null,
      tracking_number: null,
      tracking_url: null,
      date: null,
    })
    setShopifyOrder(null)

    // setOrderedShortDate('')
    // setOrderReadyShortDate('')
    // setInTransitShortDate('')
    // setOutForDeliveryShortDate('')
    // setDeliveredShortDate('')
  }

  const [
    allProductsVariantsWithMetaFields,
    setAllProductsVariantsWithMetaFields,
  ] = useState([])
  const getProductById = async (
    id,
    productId,
    variant_id,
    variant_product_id,
  ) => {
    return await axios
      .post(functionsEndpoint + "/get-product-by-id", {
        id,
        product_id: productId,
        variant_id,
        variant_product_id,
      })
      .then(res => {
        return res.data
      })
      .catch(err => {
        return {}
      })
  }

  const handleUserSubmit = async e => {
    e.preventDefault()
    axios
      .post(
        functionsEndpoint + "/shopifyGetOrderByName",
        JSON.stringify({ data: { userOrderNumber: userOrderNumberInput } }),
      )
      .then(async res => {
        console.log(res, "ress")
        setUserEmailInput(""), setUserOrderNumberInput("")
        setProductId(res.data.data.orderData.orders[0].line_items[0].product_id)
        const lineItemsPromises =
          res.data.data.orderData.orders[0].line_items.map(async lineItem => {
            const response = await getProductById(
              lineItem.product_id,
              `gid://shopify/Product/${lineItem.product_id}`,
              lineItem.variant_id,
              `gid://shopify/ProductVariant/${lineItem.variant_id}`,
            )
            return response
          })
        const response = await Promise.all(lineItemsPromises)
        setAllProductsVariantsWithMetaFields(response)
        if (res.data.data.orderData.orders.length === 1) {
          resetTrackingOrderData()
          const orderData = res.data.data.orderData.orders[0]
          setShopifyOrder(orderData)
          setOrderNumber(userOrderNumberInput)
          setShowTrackingMap(true)
          setErrorMessage("")
        } else {
          setErrorMessage("Invalid Order Number or Email.")
        }
      })
      .catch(err => {
        setErrorMessage(
          "This service is currently down. Please try again later.",
        )
        return "Server API Get Request Failed"
      })
  }

  const handleUserEmailInputChange = async event => {
    setUserEmailInput(event.target.value)
  }

  function updatePackageContents(lineItems) {
    console.log(lineItems, "fefe")
    let packageContentsArr = []
    lineItems.forEach(lineItem => {
      let currentItem = {
        ...lineItem,
        productTitle: lineItem.title,
        productQuantity: lineItem.quantity,
        variantId: lineItem.admin_graphql_api_id,
      }
      packageContentsArr.push(currentItem)
    })
    setPackageContents(packageContentsArr)

    let preOrderInfo

    if (typeof window !== undefined) {
      const updatedPreOrderInfo = localStorage.getItem("preOrderInfo")
      preOrderInfo = JSON.parse(updatedPreOrderInfo)

      const newValue =
        preOrderInfo &&
        preOrderInfo?.filter(value =>
          packageContentsArr[0]?.variantId?.includes(value?.variantId),
        )
      if (newValue?.length > 0) {
        setPreorderDate(newValue[0]?.preorderDate)
      }
    }
  }

  function updateTrackingOrderStatuses(order) {
    const fulfillmentStatus = order.fulfillment_status
    const fulfillmentsArr = order.fulfillments
    let shipmentStatus
    let lastUpdatedAt
    let fulfillmentObj = null
    if (fulfillmentsArr.length > 0) fulfillmentObj = fulfillmentsArr[0]
    if (fulfillmentObj) {
      shipmentStatus = fulfillmentObj.shipment_status
      lastUpdatedAt = fulfillmentObj.updated_at
    }
    let progressBar = "tracktorProgressBarOrdered"
    let date1 = new Date(order.created_at).toDateString()
    let time1 = new Date(order.created_at).toLocaleTimeString()
    setOrdered({ status: true, date: date1, time: time1 })

    switch (shipmentStatus) {
      case null:
        progressBar = "tracktorProgressBarOrderReady"
        let date = new Date(lastUpdatedAt).toDateString()
        let time = new Date(lastUpdatedAt).toLocaleTimeString()
        setOrderReady({ status: true, date: date, time: time })
        break
      case "in_transit":
        progressBar = "tracktorProgressBarShipped"
        let date2 = new Date(lastUpdatedAt).toDateString()
        let time2 = new Date(lastUpdatedAt).toLocaleTimeString()
        setInTransit({ status: true, date: date2, time: time2 })
        break
      case "out_for_delivery":
        progressBar = "tracktorProgressBarOutForDelivery"
        let date3 = new Date(lastUpdatedAt).toDateString()
        let time3 = new Date(lastUpdatedAt).toLocaleTimeString()
        setOutForDelivery({ status: true, date: date3, time: time3 })
        break
      case "delivered":
        progressBar = "tracktorProgressBarDelivered"
        let date4 = new Date(lastUpdatedAt).toDateString()
        let time4 = new Date(lastUpdatedAt).toLocaleTimeString()
        setDelivered({ status: true, date: date4, time: time4 })
        break
    }
    setProgressBarStatus(progressBar)
  }

  function updateCarrierInfo(fulfillment) {
    let date = new Date(fulfillment.updated_at).toDateString()
    // date = JSON.stringify(date).slice(1,-1);
    let carrier = JSON.stringify(fulfillment.tracking_company).slice(1, -1)
    let tracking_number = JSON.stringify(fulfillment.tracking_number).slice(
      1,
      -1,
    )
    let tracking_url = JSON.stringify(fulfillment.tracking_url).slice(1, -1)

    let dhl_tracking_url

    if (tracking_url.includes("odfl")) {
      dhl_tracking_url = `https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=${tracking_number}`

      tracking_url = dhl_tracking_url
    }

    setTrackingInfo({
      carrier: carrier,
      tracking_number: tracking_number,
      tracking_url: tracking_url,
      date: date,
    })
  }

  function updateDeliverToInfo(order) {
    const addressObj = order.shipping_address
    let person = addressObj?.name
    let province = ""
    if (addressObj?.province_code) province = addressObj.province_code

    let address = `${addressObj?.address1}, ${addressObj?.city}, ${province}, ${addressObj?.country_code}, ${addressObj?.zip}`
    setDeliverToInfo({ deliverTo: person, deliveryAddress: address })
  }

  useEffect(() => {
    if (shopifyOrder) {
      updateTrackingOrderStatuses(shopifyOrder)
      updatePackageContents(shopifyOrder.line_items)
      updateDeliverToInfo(shopifyOrder)
      if (shopifyOrder.fulfillments.length > 0) {
        updateCarrierInfo(shopifyOrder.fulfillments[0])
      }
      setOrderUrl(shopifyOrder.order_status_url)
    }
  }, [shopifyOrder])

  useEffect(() => {
    let updateTime
    let updateDate

    if (ordered.status) {
      let shortDate = ordered.date.substring(4, 10)
      setOrderedShortDate(shortDate)
      updateTime = ordered.time
      updateDate = ordered.date
    }
    if (orderReady.status) {
      setOrderReadyShortDate(orderReady.date.substring(4, 10))
      updateTime = orderReady.time
      updateDate = orderReady.date
    } else if (inTransit.status) {
      setOrderReadyShortDate(ordered.date.substring(4, 10))
      setInTransitShortDate(inTransit.date.substring(4, 10))
      updateTime = inTransit.time
      updateDate = inTransit.date
    } else if (outForDelivery.status) {
      setOrderReadyShortDate(ordered.date.substring(4, 10))
      setInTransitShortDate(outForDelivery.date.substring(4, 10))
      setOutForDeliveryShortDate(outForDelivery.date.substring(4, 10))
      updateTime = outForDelivery.time
      updateDate = outForDelivery.date
    } else if (delivered.status) {
      setOrderReadyShortDate(ordered.date.substring(4, 10))
      setInTransitShortDate(delivered.date.substring(4, 10))
      setOutForDeliveryShortDate(delivered.date.substring(4, 10))
      setDeliveredShortDate(delivered.date.substring(4, 10))
      updateTime = delivered.time
      updateDate = delivered.date
    }
    setLatestUpdate({ date: updateDate, time: updateTime })
  }, [ordered])

  return (
    <div>
      <div
        className="w-full py-10   bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: "url(/images/BACKGROUND.webp)" }}
      >
        <Helmet>
          <link
            rel="stylesheet"
            href="//www.theshoppad.com/apps/tracktor/assets/client/css/style.css"
          />
        </Helmet>
        <h1 className="text-center md:text-[50px] text-[30px] text-[#ffffff] font-sans pt-10">
          Track Your Order
        </h1>
        <div className="w-full flex flex-col justify-center">
          <form
            className="flex items-center justify-center w-full flex-col"
            method="GET"
            action="https://polarpro.myshopify.com/apps/tracktor"
          >
            <div className="flex  justify-center w-full  mb-1 pt-1">
              <input
                placeholder="Order Number*"
                value={userOrderNumberInput}
                onChange={handleUserOrderNumberInputChange}
                type="text"
                name="order"
                className="md:w-[719px] w-[90%] py-2 px-1  rounded-sm pl-2 lg:placeholder-[#000000] placeholder-[#888888]  placeholder:font-helvetica-neue"
              />
            </div>
            <div className="flex justify-center w-full  mb-1 pt-2">
              <input
                placeholder="Email*"
                value={userEmailInput}
                onChange={handleUserEmailInputChange}
                type="email"
                name="email"
                className="md:w-[719px] w-[90%] py-2 px-1  rounded-sm pl-2 lg:placeholder-[#000000] placeholder-[#888888]  placeholder:font-helvetica-neue"
              />
            </div>
            <div className="mt-8">
              <button
                type="submit"
                onClick={handleUserSubmit}
                className=" text-white px-[60px] py-4  font-sans  rounded-md  bg-paloverde opacity-90 hover:opacity-100 transition-opacity font-bold"
              >
                Track
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className="tracktorWrapper px-[35px] themeLight"
        id="tracktor"
        style={{ display: showTrackingMap ? "inline-block" : "none" }}
      >
        <hr></hr>
        <p className="text-center font-bold text-[34px] md:text-[40px] font-condensed mb-[50px]">
          Order #{orderNumber}
        </p>
        <p className="text-center font-bold text-[30px] md:text-[38px] font-condensed mb-[30px]">
          Package 1 of 1
        </p>
        {delivered.status ? (
          <p className="text-center md:text-left font-bold text-[30px] font-condensed mb-[30px]">
            Delivered {delivered.date}
          </p>
        ) : (
          ""
        )}
        <section id="tracktorOrderStatus">
          <div id="tracktorProgress" class="custom-steps-0">
            <span class={progressBarStatus}></span>
            <dl class="stepOrdered">
              <dt>
                <span>Ordered</span>
              </dt>
              <dd>{orderedShortDate}</dd>
            </dl>
            <dl class="stepReady">
              <dt>
                <span>Order Ready</span>
              </dt>
              <dd>{orderReadyShortDate}</dd>
            </dl>
            <dl class="stepShipped">
              <dt>
                <span>In Transit</span>
              </dt>
              <dd>{inTransitShortDate}</dd>
            </dl>
            <dl class="stepInroute">
              <dt>
                <span>Out for Delivery</span>
              </dt>
              <dd>{outForDeliveryShortDate}</dd>
            </dl>
            <dl class="stepComplete">
              <dt>
                <span>Delivered</span>
              </dt>
              <dd>{deliveredShortDate}</dd>
            </dl>
          </div>
        </section>
        <div class="tracktorFulfillmentCol1">
          <p className="text-center font-bold text-[38px] font-condensed mb-[30px]">
            Shipping Details
          </p>
          <ul class="by-day-details">
            {delivered.status || outForDelivery.status || inTransit.status ? (
              <li>
                <p className="text-center font-bold text-[16px] font-condensed">
                  {latestUpdate.date}
                </p>
                <ul>
                  {delivered.status ? (
                    <li>
                      <dl className="text-center font-bold text-[16px] font-condensed">
                        <dt>{latestUpdate.time}</dt>
                        <dd>Delivered</dd>
                      </dl>
                    </li>
                  ) : (
                    ""
                  )}
                  {delivered.status || outForDelivery.status ? (
                    <li>
                      <dl className="text-center font-bold text-[16px] font-condensed">
                        <dt>{outForDelivery.time}</dt>
                        <dd>Package has been sent out for final delivery</dd>
                      </dl>
                    </li>
                  ) : (
                    ""
                  )}
                  {delivered.status ||
                    outForDelivery.status ||
                    inTransit.status ? (
                    <li>
                      <dl className="text-center font-bold text-[16px] font-condensed">
                        <dt>{inTransit.time}</dt>
                        <dd>Package has been shipped for transit</dd>
                      </dl>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>
            ) : (
              ""
            )}
            <li>
              <p className="text-center font-bold text-[16px] font-condensed">
                {ordered.date}
              </p>
              <ul>
                {delivered.status ||
                  outForDelivery.status ||
                  inTransit.status ? (
                  <li>
                    <dl className="text-center font-bold text-[16px] font-condensed">
                      <dt>{inTransit.time}</dt>
                      <dd>
                        Packaged has been picked up by carrier - Costa Mesa, CA,
                        US
                      </dd>
                    </dl>
                  </li>
                ) : (
                  ""
                )}
                {delivered.status ||
                  outForDelivery.status ||
                  inTransit.status ||
                  orderReady.status ? (
                  <li>
                    <dl className="text-center font-bold text-[16px] font-condensed">
                      <dt>{orderReady.time}</dt>
                      <dd>
                        Order has been packaged and is ready to be picked up by
                        carrier
                      </dd>
                    </dl>
                  </li>
                ) : (
                  ""
                )}
                {delivered.status ||
                  outForDelivery.status ||
                  inTransit.status ||
                  orderReady.status ? (
                  <li>
                    <dl className="text-center font-bold text-[16px] font-condensed">
                      <dt>{orderReady.time}</dt>
                      <dd>Shipment information sent to carrier</dd>
                    </dl>
                  </li>
                ) : (
                  ""
                )}
                <li>
                  <dl className="text-center font-bold text-[16px] font-condensed">
                    <dt>{ordered.time}</dt>
                    <dd>Ordered.</dd>
                  </dl>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="tracktorFulfillmentCol2 text-center">
          <p className="text-center font-bold text-[38px] font-condensed mb-[30px]">
            Package Info
          </p>
          <div className="after-map">
            <label className="font-bold text-[14px] font-condensed">
              Delivering To:
            </label>
            <p className="font-bold text-[16px] font-condensed">
              {deliverToInfo.deliverTo
                ? deliverToInfo.deliveryAddress
                : "No shippable items."}
            </p>
            <section class="carrierDetails !mb-[0px]">
              <label className="font-bold text-[14px] font-condensed">
                Carrier:
              </label>
              <p className="font-bold text-[16px] font-condensed">
                {trackingInfo.carrier == "ul"
                  ? "Carrier Info could not be determined."
                  : trackingInfo.carrier}
                {trackingInfo.carrier == null ? "Pending" : ""}
              </p>
            </section>
            <section class="trackingNumber !mb-[0px]">
              <label className="font-bold text-[14px] font-condensed">
                Tracking Number:
              </label>
              <p className="font-bold text-[16px] font-condensed">
                <Link
                  to={`${trackingInfo.tracking_url}/${location.search ? location.search : ""
                    }`}
                  target="_blank"
                  className="underline"
                >
                  Link to {trackingInfo.carrier}
                </Link>
              </p>
            </section>
            {trackingInfo.carrier !== "ul" && trackingInfo.carrier !== null ? (
              <section class="trackingDetails !mb-[0px]">
                <label className="font-bold text-[14px] font-condensed">
                  Further Details:
                </label>
                <p className="font-bold text-[16px] font-condensed">
                  <Link
                    to={`/${trackingInfo.tracking_url}/${location.search ? location.search : ""
                      }`}
                    target="_blank"
                    className="underline"
                  >
                    Link to {trackingInfo.carrier}
                  </Link>
                </p>
              </section>
            ) : (
              ""
            )}
            <section class="orderDetails !mb-[0px]">
              <label className="font-bold text-[14px] font-condensed">
                Order Status Details:
              </label>
              <p className="font-bold text-[16px] font-condensed">
                <Link
                  to={`${orderUrl}/${location.search ? location.search : ""}`}
                  target="_blank"
                  className="underline"
                >
                  Link to Order Page
                </Link>
              </p>
            </section>
            <section class="fulfillmentContents">
              <label className="font-bold text-[16px] font-condensed mb-4">
                Package Contents
              </label>
              {packageContents.map(item => {
                const toFilter = allProductsVariantsWithMetaFields || []
                const preOrderDateV = toFilter.filter(value => {
                  return value.variants.filter(v => v.id === item.variant_id)
                    ?.length
                })?.[0]?.variants?.[0]?.metafields?.[0]?.value

                return (
                  <div className="flex flex-col ">
                    {preOrderDateV !== undefined && (
                      <p className="mb-0 text-[10px] text-paloverde font-semibold">
                        Reserved item.
                        <span className="text-black ml-[2px]">
                          Estimated to ship {preOrderDateV}
                        </span>
                      </p>
                    )}
                    <p className="font-bold text-[16px] font-condensed">
                      x{item.productQuantity} {item.productTitle}
                    </p>
                  </div>
                )
              })}
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tracktor
