/* eslint-disable */
import React, { useState } from "react"
import PropTypes from "prop-types"
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
// import { render } from 'storyblok-rich-text-react-renderer';
import DynamicComponents from "../DynamicComponents" // eslint-disable-line
import * as style from "./style.module.scss"

const ModularFilterInfoTile = ({ blok, lastChildIndex }) => {
  const {
    image,
    imageAlt,
    filterModelFullName,
    filterModelAbbreviation,
    filterDescription,
    listingTitle,
    listings,
    itemName,
    itemSubName,
  } = blok

  const [showOverlay, setShowOverlay] = useState(false)

  return (
    <div
      className={`${style.filterContainer} ${lastChildIndex <= 5 ? `` : ""}`}
    >
      <div className={style.filterItem}>
        <img className={style.picture} src={image} alt={imageAlt} />
        <div
          className={`${style.overlay} ${
            showOverlay ? style.activeViewing : ""
          }`}
        >
          <div className={style.tag}>
            About <span>Filter</span>
          </div>
          <p className={`font-bold text-xl ${style.filterName}`}>
            {filterModelFullName} <span>{filterModelAbbreviation}</span>
          </p>
          <p className={style.desc}>{filterDescription}</p>

          <p className={style.listingTitle}>{listingTitle}</p>
          <ul className={`m-0 ${style.listing}`}>
            {listings.map(
              (
                blok,
                index, // eslint-disable-line
              ) => (
                <div
                  {...sbEditable(blok)} // eslint-disable-line
                >
                  <DynamicComponents
                    blok={blok}
                    key={blok._uid} // eslint-disable-line
                    index={index}
                  />
                </div>
              ),
            )}
          </ul>
        </div>
      </div>
      <div className={style.filterDesc}>
        <p className={style.itemName}>{itemName}</p>
        <p className={style.subName}>{itemSubName}</p>
        <div className={style.icon}>
          <FaPlusCircle
            className={`w-[30px] h-[30px] ${showOverlay ? "hidden" : "block"}`}
            onClick={() => setShowOverlay(true)}
          />
          <FaMinusCircle
            className={`w-[30px] h-[30px] ${showOverlay ? "block" : "hidden"}`}
            ariaHidden="true"
            onClick={() => setShowOverlay(false)}
          />
        </div>
      </div>
    </div>
  )
}

export default ModularFilterInfoTile
