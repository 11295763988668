/* eslint-disable */
import React, { useState, useRef } from "react"
import { useStoryblok } from "../../utils/storyblok"
import "./support.scss"
import Seo from "../../components/seo"
import { FormspreeProvider } from "@formspree/react"

const Warranty = ({ pageContext }) => {
  const { storyblokEntry } = pageContext

  const [fields, setFields] = useState({
    name: "",
    email: "",
    order_number: "",
    sku: "",
    description: "",
    shipping_adress: "",
    city: "",
    country: "",
    damaged_product: null,
    purchase_receipt: null,
  })

  const story = storyblokEntry
    ? useStoryblok(storyblokEntry.node)
    : { content: null }
  const profilePictureRef = useRef(null)

  let supportPageType = "warranty"

  const saveFileForDamagedProduct = async file => {
    setFields({ ...fields, damaged_product: file })
  }

  const saveFileForPurchase = async file => {
    setFields({ ...fields, purchase_receipt: file })
  }
  const [isSuccess, setIsSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [showError, setshowError] = useState(false)
  const [isError, setIsError] = useState("")

  const submitHandler = async event => {
    event.preventDefault()

    try {
      const formData = {
        name: fields.name,
        email: fields.email,
        sku: fields.sku,
        order_number: fields.order_number,
        shipping_adress: fields.shipping_adress,
        city: fields.city,
        country: fields.country,
        damaged_product: fields.damaged_product.name,
        description: fields.description,
      }

      const response = await fetch(process.env.GATSBY_FORMSPREE_WARRANTY, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })

      if (response.ok) {
        setIsSuccess(true)
        setSuccessMessage(
          "Thank you, our Support team will respond within 24 business hours.",
        )
      } else {
        setshowError(true)
        setIsError("Failed to submit form data")
      }
    } catch (error) {
      setshowError(true)
      setIsError("Failed to submit form data")
    }
  }

  const warranty_form = (
    <div>
      <FormspreeProvider project={process.env.GATSBY_FORMSPREE_WARRANTY}>
        <div className="w-full">
          <div className="bg-white py-12 w-full">
            <div className="w-full flex flex-col justify-center">
              <form
                onSubmit={submitHandler}
                action={process.env.GATSBY_FORMSPREE_WARRANTY}
                method="POST"
                className="flex flex-col gap-4 w-[40%] mx-auto"
              >
                <div className="relative  flex justify-center  mb-1 pt-6">
                  <div className="flex flex-col gap-1 w-full">
                    <label className="text-xs font-semibold">Name*</label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="name"
                      required
                      value={fields.name}
                      onChange={e =>
                        setFields({ ...fields, name: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="relative mb-1  flex justify-center">
                  <div className="flex flex-col gap-1 w-full">
                    <label className="text-xs font-semibold">Email*</label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="email"
                      required
                      name="email"
                      value={fields.email}
                      onChange={e =>
                        setFields({ ...fields, email: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="relative mb-1  flex justify-center">
                  <div className="flex flex-col gap-1 w-full">
                    <label className="text-xs font-semibold">
                      Product Name / SKU*
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="productName"
                      required
                      value={fields.sku}
                      onChange={e =>
                        setFields({ ...fields, sku: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="relative mb-1  flex justify-center">
                  <div className="flex flex-col gap-1 w-full">
                    <label className="text-xs font-semibold">
                      Order Number*
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="orderNumber"
                      value={fields.order_number}
                      onChange={e =>
                        setFields({ ...fields, order_number: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="relative mb-1  flex justify-center">
                  <div className="flex flex-col gap-1 w-full">
                    <label className="text-xs font-semibold">
                      Shipping Address for Replacement*
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="orderNumber"
                      value={fields.shipping_adress}
                      onChange={e =>
                        setFields({
                          ...fields,
                          shipping_adress: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="relative mb-1  flex justify-center">
                  <div className="flex flex-col gap-1 w-full">
                    <label className="text-xs font-semibold">
                      City / Postal Code*
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="orderNumber"
                      value={fields.city}
                      onChange={e =>
                        setFields({ ...fields, city: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="relative mb-1  flex justify-center">
                  <div className="flex flex-col gap-1 w-full">
                    <label className="text-xs font-semibold">Country*</label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="orderNumber"
                      value={fields.country}
                      onChange={e =>
                        setFields({ ...fields, country: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="flex justify-center mt-1 ">
                  <div className="flex flex-col gap-1 w-full">
                    <label className="text-xs font-semibold">
                      Image of Damaged Product*
                    </label>
                    <input
                      type="file"
                      className="hidden"
                      id="fileInput"
                      name="attachment"
                      accept="*"
                      ref={profilePictureRef}
                      max={10000000}
                      onChange={e => {
                        if (e.target.files?.length) {
                          const selectedFileType = e.target.files[0].type
                          const acceptedTypes = [
                            "image/jpeg",
                            "image/png",
                            "video/mp4",
                            "video/quicktime",
                          ]
                          if (acceptedTypes.includes(selectedFileType)) {
                            saveFileForDamagedProduct(e.target.files[0])
                          }
                        }
                      }}
                    />
                    <div className="flex items-center">
                      <label
                        htmlFor="fileInput"
                        className=" border-[1px] text-xs border-gray-900/20 text-black font-normal py-2 px-4 rounded cursor-pointer"
                      >
                        Browse...
                      </label>
                      <span className="ml-3">No file is selected</span>
                    </div>
                  </div>
                </div>

                <div className="flex justify-center mt-1 ">
                  <div className="flex flex-col gap-1 w-full">
                    <label className="text-xs font-semibold">
                      Proof of Purchase Receipt
                    </label>
                    <input
                      type="file"
                      className="hidden"
                      id="fileInput"
                      name="attachment"
                      accept="*"
                      ref={profilePictureRef}
                      max={10000000}
                      onChange={e => {
                        if (e.target.files?.length) {
                          const selectedFileType = e.target.files[0].type
                          const acceptedTypes = [
                            "image/jpeg",
                            "image/png",
                            "video/mp4",
                            "video/quicktime",
                          ]
                          if (acceptedTypes.includes(selectedFileType)) {
                            saveFileForPurchase(e.target.files[0])
                          }
                        }
                      }}
                    />
                    <div className="flex items-center">
                      <label
                        htmlFor="fileInput"
                        className=" border-[1px] text-xs border-gray-900/20 text-black font-normal py-2 px-4 rounded cursor-pointer"
                      >
                        Browse...
                      </label>
                      <span className="ml-3">No file is selected</span>
                    </div>
                  </div>
                </div>

                <div className="flex justify-center mt-1 ">
                  <div className="flex flex-col gap-1 w-full">
                    <label className="text-xs font-semibold">
                      Description*
                    </label>
                    <textarea
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      required
                      name="help"
                      value={fields.description}
                      onChange={e =>
                        setFields({ ...fields, description: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="flex justify-center">
                  <button
                    type="submit"
                    className=" text-white px-[150px] opacity-90 hover:opacity-100 transition-opacity py-4 font-sans  rounded-md mt-10 bg-paloverde font-bold"
                  >
                    Submit
                  </button>
                </div>
                {isSuccess && (
                  <p className="bg-paloverde rounded p-5 text-white">
                    {successMessage}
                  </p>
                )}
                {showError && (
                  <p className="bg-red-400 rounded p-5 text-red-900">
                    {isError}
                  </p>
                )}
              </form>
            </div>
          </div>
        </div>
      </FormspreeProvider>
    </div>
  )

  const warranty_notice = (
    <div className="notes">
      <span className="title">Terms:</span>
      <p>
        PolarPro’s Adventure Assurance™ covers PolarPro products under their
        intended use. This warranty does not cover the negligent use of any
        product, including; scratches or cracks on filter lenses from improper
        use or storage, or damage from mishandling or dropping the product.
        <br></br>
        <br></br>
        PolarPro’s Adventure Assurance™ is non-transferable and is applicable
        to the original purchaser only. It cannot be extended to cover
        second-hand purchases from unauthorized resellers.
        <br></br>
        <br></br>
        All warranty claims must include a dated proof of purchase with
        description of product sold. Items bought from unauthorized resellers
        will not be covered under warranty. Any product that has been altered or
        modified in a way not approved by PolarPro is not covered by this
        warranty.
      </p>
    </div>
  )

  return (
    <div id={supportPageType}>
      <Seo title={story.content?.title} />
      <img
        src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/support-header-Image.jpg?v=144240260665312199781582676319"
        className="wideBanner w-full mb-[0px]"
      />
      <div class="mobile-only support-time">
        <p>Live Support Available M-F 9AM-5PM PST</p>
      </div>
      <div className="support-wrapper">
        <img
          src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/warranty-icon.png?v=48868144515333842801582586863"
          className=""
        />
        <p className="title-text">Warranty</p>
        <p className="title-text">Adventure Assurance™</p>
        <p className="subtitle-text">
          PolarPro promises that its products are free from manufacturer defects
          in materials or workmanship for the lifetime of the product. Need to
          make a claim?
        </p>
      </div>
      {warranty_form}
      {warranty_notice}
      <div class="support-bottom-banner">
        <p>Still can't find what you're looking for?</p>

        <span>Let us Know!</span>
        <a href="/support/contact-us">Contact Us</a>
      </div>
    </div>
  )
}

export default Warranty
