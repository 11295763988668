/* eslint-disable */

import { useLocation } from "@reach/router"
import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import YotpoStarRatings from "../YotpoStarRatings"
import ProductCardSearchCard from "./ProductCardSearch"

const ProductCardSearch = React.memo(props => {
  const location = useLocation()

  const { product } = props
  const [allProducts, setAllProducts] = useState()
  useEffect(() => {
    setAllProducts(product)
  }, [product])

  let lowestComparePrice = ""

  if (product.variants) {
    let lowestPricedVariant = product.variants[0]
    product.variants.forEach(variant => {
      if (!lowestPricedVariant.compareAtPrice) {
        lowestPricedVariant = variant
      } else if (variant.compareAtPrice) {
        if (parseFloat(variant.price) < parseFloat(lowestPricedVariant.price)) {
          lowestPricedVariant = variant
        }
      }
    })

    if (lowestPricedVariant.compareAtPrice)
      lowestComparePrice = `$${lowestPricedVariant.compareAtPrice}`
  }

  // make price displayed up to 2 decimal (cents)
  const lowestVariantPrice = parseFloat(
    product.priceRangeV2?.minVariantPrice?.amount,
  ).toFixed(2)

  const shopifyIdExtractor = id => id?.slice(id.lastIndexOf("/") + 1)
  const productShopifyId = shopifyIdExtractor(product.shopifyId)
  const [overviewPageArr, setOverviewPageArr] = useState([])
  const productUrl = product?.images
    ? product?.images?.[0]?.src
    : product?.media?.[0]?.image?.src ||
    (product?.media && product?.media[0]?.preview?.image?.src)

  return (
    <Link
      to={
        overviewPageArr?.length > 0
          ? overviewPageArr[0].value
          : `/products/${product.handle}/${location.search ? location.search : ""
          }`
      }
      className=""
    >
      <div className="product-card mb-[10px]">
        <div className="productImage bg-white px-[25%] rounded-md bg-white">
          <img src={productUrl} />
        </div>

        <div className="hoverDataContainer">
          <span className="text-base text-[#1C1C1C] font-black font-expanded block uppercase mb-0 mt-[20px]">
            {product.title}
          </span>
          <div className="">
            <YotpoStarRatings
              product={product}
              productShopifyId={productShopifyId}
            />
          </div>

          {/*description */}
          <p className="text-base text-gray-400 mt-2 md:mt-5">
            {product?.description.slice(0, 60)}...
          </p>
          {product ? <ProductCardSearchCard product={product} /> : null}
        </div>
      </div>
    </Link>
  )
})

export default ProductCardSearch
