/* eslint-disable */
import React, { useState } from "react"
import "./tabstyle.scss"
import YotpoReviewsWidget from "../YotpoReviewsWidget"
import ProductRecommendation from "../ProductRecommendation"

const Tabs = props => {
  const { product, productShopifyId, recommendedProducts } = props
  const [toggleState, setToggleState] = useState(1)

  const toggleTab = index => {
    setToggleState(index)
  }

  let toggleActive1 = toggleState === 1 ? "tabs active-tabs" : "tabs"
  let toggleActive2 = toggleState === 2 ? "tabs active-tabs" : "tabs"

  return (
    <div className="tab-container pt-24">
      <div className="bloc-tabs flex grid-cols-2 w-full m-auto justify-center">
        <button
          className={`py-3 px-10 text-center font-condensed font-extrabold bg-white uppercase text-[16px] !z-0 ${toggleActive1}`}
          onClick={() => toggleTab(1)}
        >
          Complete Your Setup
        </button>
        <button
          className={`py-3 px-10 text-center font-condensed font-extrabold bg-white uppercase text-[16px] !z-0 ${toggleActive2}`}
          onClick={() => toggleTab(2)}
        >
          Customer Reviews
        </button>
      </div>

      <div className="content-tabs border-t relative pt-30px">
        <div
          className={`ProductRecommendation relative max-w-screen-xl m-auto px-7 3xl:px-0 ${
            toggleState === 1 ? "content active-content" : "content"
          }`}
        >
          <ProductRecommendation
            product={product}
            recommendedProducts={recommendedProducts}
          />
        </div>

        <div
          className={`relative max-w-screen-xl m-auto pl-30px pr-30px ${
            toggleState === 2 ? "content  active-content" : "content"
          }`}
        >
          <YotpoReviewsWidget
            product={product}
            productShopifyId={productShopifyId}
          />
        </div>
      </div>
    </div>
  )
}

export default Tabs
