/* eslint-disable */
import React, { useState, createContext, useEffect } from "react"
import PropTypes from "prop-types"

// const defaultValues = {
//   userId: '',
//   sessionId: '',
//   loggedIn: false,
//   auth: { authCookie: '' },
//   setAuth: () => {},
// };

export interface Address {
  address1: string
  address2: string
  city: string
  country: string
  zip: string
  province_code: string
}

interface AuthContextType {
  id?: string
  birthday?: string
  user?: string
  pwd?: string
  accessToken?: string
  loggedIn?: boolean
  firstName?: string
  lastName?: string
  defaultAddress?: Address
  email?: string
  phone?: string
  orders?: any
  profilePic?: string
}

export const AuthContext = createContext<{
  auth?: AuthContextType
  setAuth?: React.Dispatch<React.SetStateAction<AuthContextType>>
}>({})

export const AuthProvider = ({ children }: any) => {
  const [auth, setAuth] = useState({
    // user: '',
    // pwd: '',
    // accessToken: '',
    // loggedIn: JSON.parse(localStorage.getItem("persist") ?? 'false') || false,
    // firstName: '',
    // lastName: '',
    // defaultAddress: '',
    // email: '',
    // phone: '',
    // orders: {}
  })
  // const [loggedIn, setLoggedIn] = useState(false);
  // const [persist, setPersist] = useState(JSON.parse(localStorage.getItem("persist")) || false);

  // const [firstName, setFirstName] = useState('');
  // const [lastName, setLastName] = useState('');
  // const [defaultAddress, setDefaultAddress] = useState({});
  // const [email, setEmail] = useState('');
  // const [phone, setPhone] = useState('');
  // const [orders, setOrders] = useState([]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
      }}
      // loggedIn,
      // setLoggedIn,
      // persist,
      // setPersist,
      // firstName,
      // setFirstName,
      // lastName,
      // setLastName,
      // defaultAddress,
      // setDefaultAddress,
      // phone,
      // setPhone,
      // email,
      // setEmail,
      // orders,
      // setOrders,
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
