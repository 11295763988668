/* eslint-disable */
import React from "react"
import { reducingImpact, heading } from "./style.module.scss"

const QLSustainabilitySection = ({ blok }) => (
  <section className="mt-20">
    <p className={heading}>{blok.title}</p>
    <div className={reducingImpact} />
  </section>
)

export default QLSustainabilitySection
