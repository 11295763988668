/* eslint-disable */
import React from "react"
import { render } from "storyblok-rich-text-react-renderer"
import { Link } from "gatsby"

const FAQItem = ({ blok }) => {
  const { FAQTitle, Content } = blok
  return (
    <div className="item">
      <p className="font-bold">{FAQTitle}</p>
      <p className="faqData">{render(Content)}</p>
    </div>
  )
}

export default FAQItem
