import React, { useState } from "react"

function AdditionalBlock(props: any) {
  const [selectedTitle, setSelectedTitle] = useState<number | null>(null)

  const isVideo = (url: string) => {
    const videoExtensions = ["mp4", "webm", "ogg"]
    const extension = url.split(".").pop()
    return videoExtensions.includes(extension || "")
  }

  return (
    <div className="flex w-full justify-center bg-black">
      <div className="bg-black w-full flex flex-col lg:flex-row">
        <div className=" w-full px-[10px] sm:px-[50px] lg:w-1/2 mt-10">
          <p className="hilex-title mb-0">{props.title}</p>
          <p className="hilex-main-title text-[24px] lg:[20px] xl:text-[24px] 2xl:text-[30px]">
            {props.description}
          </p>
          {props.list.map((content: any, index: any) => (
            <div key={content.title}>
              <div className="flex justify-between border-t border-[#DDDDD7] pt-4">
                <p className="font-medium text-[20px] lg:[16px] xl:text-[20px] 2xl:text-[24px]  text-[#DDDDD7] w-[90%]">
                  {content.title}
                </p>
                {selectedTitle !== index ? (
                  <p
                    className="font-light text-[#DDDDD7] cursor-pointer text-3xl"
                    onClick={() => setSelectedTitle(index)}
                  >
                    +
                  </p>
                ) : (
                  <p
                    className="font-light text-white cursor-pointer text-3xl pr-2"
                    onClick={() => setSelectedTitle(null)}
                  >
                    -
                  </p>
                )}
              </div>
              {selectedTitle === index ? (
                <p className="text-[#DDDDD7] text-[13px] lg:text-[11px] xl:text-[12px] leading-4 2xl:text-[16px]">
                  {content.description}
                </p>
              ) : null}
            </div>
          ))}
        </div>
        <div className="w-full lg:w-1/2">
          {isVideo(props?.list?.[selectedTitle || 0]?.image?.filename) ? (
            <video
              autoPlay
              loop
              playsInline={true}
              className="w-full h-auto object-cover mb-0"
              src={props.list[selectedTitle || 0].image.filename}
            />
          ) : (
            <img
              className="w-full h-auto object-cover mb-0"
              src={props.list[selectedTitle || 0].image.filename}
              alt={"fourbyfivemchImg"}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default AdditionalBlock
