/* eslint-disable */
import React, { useEffect } from "react"
import { useStoryblok } from "../utils/storyblok"
import DynamicComponentsV2 from "../components/DynamicComponentsV2"
import Seo from "../components/seo"
import * as style from "./index.module.scss"

// const IndexPage = ({ data }) => {
//   let story = data.storyblokEntry;
//   story = useStoryblok(story,);
//   const storyContent = story.content;

const IndexPage = ({ pageContext }) => {
  const { storyblokEntry, productCardsData2, exploreData1123 } = pageContext

  const story = storyblokEntry
    ? useStoryblok(storyblokEntry)
    : { content: null }

  // Load Yotpo Reviews Script
  useEffect(() => {
    const script = document.createElement("script")
    script.src =
      "https://staticw2.yotpo.com/qNmEg7HN0QiYfqn6n5DHRUGCtTUGNWa1GfSVsHRS/widget.js"
    script.async = true
    document.head.appendChild(script)

    script.onload = e => {
      window.yotpo.refreshWidgets()
    }

    return () => {
      script.remove()
    }
  })

  return (
    <>
      <Seo title="PolarPro®" />
      {/* bf promo banner index */}

      <div className={style.indexHomepage}>
        <div
          className={`${style.polarproIndexHero} relative flex flex-col items-center justify-end md:justify-center`}
        >
          {/* <video width="100%" height="100%" autoPlay muted loop playsInline className="hidden sm:hidden md:block">
            <source src={story.content.desktop_hero_video} type="video/webm" />
            Your browser does not support the video tag.
          </video> */}
          <DynamicComponentsV2
            components={story?.content?.Components}
            productCardsData2={exploreData1123}
          />
        </div>
        <div
          className={`${style.polarproIndexHero} hidden relative flex flex-col items-center justify-end md:justify-center`}
        >
          {/* <video width="100%" height="100%" autoPlay muted loop playsInline className="hidden sm:hidden md:block">
            <source src={story.content.desktop_hero_video} type="video/webm" />
            Your browser does not support the video tag.
          </video> */}
          {/* <video width="100%" height="100%" autoPlay muted loop playsInline className="block sm:block md:hidden">
            <source src={story.content.mobile_hero_video} type="video/webm" />
            Your browser does not support the video tag.
          </video> */}
        </div>
      </div>
    </>
  )
}

export default IndexPage
