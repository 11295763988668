import React from "react"
import Carousel from "react-multi-carousel"
import IndexImageCarouselArrow from "../IndexImageCarouselArrow"
import DynamicComponents from "../DynamicComponents"
import { sbEditable } from "@storyblok/storyblok-editable"
import "react-multi-carousel/lib/styles.css"
import "./style.scss"
import * as styles from "../../../src/templates/index.module.scss"

function GearUp(props: any) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1023, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      partialVisibilityGutter: 100,
    },
  }

  const GearUpSliderItem = props.GearUpSlider.map((blok: any) => (
    <>
      {blok ? (
        <div {...sbEditable(blok)} className="relative  w-full h-full !text-sm">
          <DynamicComponents blok={blok} key={blok._uid} />
        </div>
      ) : null}
    </>
  ))
  return (
    <div
      className={`${styles.gearUpCtaSlider} flex flex-col sm:flex-col lg:flex-row overflow-hidden py-24 md:py-12`}
    >
      <div
        className={`${styles.tagLine} lg:w-3/12 items-center justify-left md:justify-center flex pl-[20px] md:pl-0 lg:pl-28 mb-24 md:mb-12`}
      >
        <h3 className="font-black text-obsidian text-4xl md:text-3xl xl:text-3xl 2xl:text-5xl m-0">
          {props?.Title.split(" ")
            .slice(0, props?.Title?.split(" ").length - 1)
            .join(" ")}
          <br />
          <span className={`$${styles.tagLine} text-paloverde`}>
            {props?.Title?.split(" ").pop()}
          </span>
        </h3>
      </div>
      <div
        className={`${styles.slider} relative lg:w-9/12 left-[0vw] lg:left-[6vw]`}
      >
        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={false}
          infinite={true}
          removeArrowOnDeviceType={["mobile"]}
          containerClass={styles.sliderContainer}
          autoPlay
          autoPlaySpeed={3000}
          customRightArrow={
            <IndexImageCarouselArrow
              right
              onClick={undefined}
              subCarousel={undefined}
              imagesLength={undefined}
            />
          }
          customLeftArrow={
            <IndexImageCarouselArrow
              right={false}
              onClick={undefined}
              subCarousel={undefined}
              imagesLength={undefined}
            />
          }
        >
          {GearUpSliderItem}
        </Carousel>
      </div>
    </div>
  )
}

export default GearUp
