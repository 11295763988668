/* eslint-disable */
import React from "react"
import { useStoryblok } from "../../utils/storyblok"
import "./support.scss"
import Seo from "../../components/seo"
import ContactSupport from "../../components/ContactSupport"

const ContactUs = ({ pageContext }) => {
  const { storyblokEntry, product } = pageContext

  const story = storyblokEntry
    ? useStoryblok(storyblokEntry.node)
    : { content: null }

  let supportPageType = "contact-us"

  return (
    <div id={supportPageType}>
      <Seo title={story.content?.title} />
      <ContactSupport />
    </div>
  )
}

export default ContactUs
