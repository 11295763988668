/* eslint-disable */
import React, { useState } from "react"
import {
  attentionToDetail,
  ip12Features,
  mainTitle,
  featureItem,
  LCPiP12MainBanner,
  youAskedWeListened,
  titleWithSub,
  titleSub,
  mobileBreak,
  mobileOptimizedFilters,
  desktopOnly,
  mobileOnly,
  listing,
  listingTitle,
  listingItem,
  bulletsOverlays,
  bottomHalf,
  top,
  activeViewing,
  text,
  tag,
  overlay,
  content,
  activeContent,
  desc,
  filterName,
  compareVnd,
  vndItem,
  textGold,
  left,
  right,
  allNewCase,
  icon,
  picture,
  highlight,
  first,
  animateContainer,
  item,
  divider,
  bottom,
  filterContainer,
  filterItem,
  filterDesc,
  itemName,
  subName,
  gripOrientations,
  sideBySide,
  sideBySideWithListing,
  orientations,
  render,
  renderVertical,
  firstStack,
  secondStack,
  newForiP12,
  container,
  oneThirds,
  twoThirds,
  availableKit,
  reducingImpactSection,
  buildYourKit,
  reducingImpact,
  filmmakingKit,
  buyBtn,
  directorKit,
  alignContainer,
  middle,
  overlayTwo,
} from "./style.module.scss"
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa"

const LCP12BelowContent = () => {
  const [viewCP, setViewCP] = useState(false)
  const [viewVND, setViewVND] = useState(false)
  const [viewMist, setViewMist] = useState(false)
  const [viewMist35, setViewMist35] = useState(false)

  return (
    <div className="below-content">
      <div className={ip12Features}>
        <h3 className={mainTitle}>
          LiteChaser Pro{" "}
          <span>
            | iPhone<sup>®</sup> 12 Edition
          </span>
        </h3>

        <div className={`${featureItem} ${LCPiP12MainBanner}`}>
          <h2>
            The Only Minimalist Filter <br className={mobileBreak} />
            System For iPhone<sup>®</sup> 12
          </h2>
          <p>Everything you need, nothing you don't.</p>
        </div>

        <div className={`${featureItem} ${youAskedWeListened}`}>
          <div className={titleWithSub}>
            <h3>You Asked, We Listened.</h3>
            <p className={titleSub}>
              The completely redesigned LiteChaser Pro{" "}
              <br className={mobileBreak} />
              for iPhone<sup>®</sup> 12
            </p>
          </div>

          <div className={listingItem}>
            <img
              className={desktopOnly}
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/You-Asked-We-Listened-Banner-1.jpg?v=17865018251222163044"
            />
            <img
              className={mobileOnly}
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/New-Mount-Design-m.jpg?v=1281443647134143592"
            />
            <div className={text}>
              <h2>
                Moment<sup>®</sup> Lens Compatibility +
              </h2>
              <h3>Improved LCP12 Filter-Mount Design</h3>
              <span className={textGold}>
                (LCP12 Filters not compatible with Moment Lenses Installed)
              </span>
            </div>
          </div>
          <div className={`${listingItem} ${right} md:flex md:justify-end`}>
            <div className={`${text} ${left}`}>
              <h2>NEW BLUETOOTH SHUTTER REMOTE</h2>
              <h3>SEAMLESSLY ATTACHES TO THE GRIP</h3>
            </div>
            <img
              className={`${right} float-right ${desktopOnly}`}
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/You-Asked-We-Listened-Banner-2.jpg?v=5567682237696965525"
            />
            <img
              className={mobileOnly}
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/Bluetooth-Shutter-Control-m.jpg?v=1191396834637513183"
            />
          </div>
          <div className={listingItem}>
            <img
              className={desktopOnly}
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/You-Asked-We-Listened-Banner-3.jpg?v=9588300101249838982"
            />
            <img
              className={mobileOnly}
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/All-New-Case-Design-m.jpg?v=3711656624806379157"
            />
            <div className={`${text} ${allNewCase}`}>
              <h2>MAGSAFE&trade; Magnet Array +</h2>
              <h3>Compatible with all MAGSAFE Chargers and Mounts</h3>
            </div>
          </div>
        </div>

        {/* <!-- mobile optimized filter section --> */}
        <div className={`${featureItem} ${mobileOptimizedFilters}`}>
          <h3>
            <span className={highlight}>Mobile-Optimized Filters</span>
          </h3>
          <div
            className={`${content} ${activeContent}`}
            id="mobile-optimized-content"
          >
            <div className={filterContainer}>
              <div className={filterItem}>
                <img
                  className={picture}
                  src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/lc-CP.png?v=5632236039309989437"
                />
                <div className={`${overlay} ${viewCP ? activeViewing : ""}`}>
                  <div className={tag}>
                    About <span>Filter</span>
                  </div>
                  <p className={filterName}>
                    Circular Polarizer <span>(CP)</span>
                  </p>
                  <p className={desc}>
                    The essential filter for capturing deeper colors and
                    reducing glare, reflections and haze from your photos.
                    Rotatable to ensure you can completely control its effect
                    based on your composition.
                  </p>

                  <p className={listingTitle}>Camera Compatibility:</p>
                  <ul className={listing}>
                    <li>
                      <span>&#12297;&#12297;</span> Ultra Wide
                    </li>
                    <li>
                      <span>&#12297;&#12297;</span> Wide
                    </li>
                    <li>
                      <span>&#12297;&#12297;</span> Telephoto
                    </li>
                  </ul>
                </div>
              </div>
              <div className={filterDesc}>
                <p className={itemName}>CP</p>
                <p className={subName}>Circular Polarizer</p>
                <div className={icon}>
                  <FaPlusCircle
                    className={`w-[30px] h-[30px] ${
                      viewCP ? "hidden" : "block"
                    }`}
                    onClick={() => setViewCP(true)}
                  />
                  <FaMinusCircle
                    className={`w-[30px] h-[30px] ${
                      viewCP ? "block" : "hidden"
                    }`}
                    ariaHidden="true"
                    onClick={() => setViewCP(false)}
                  />
                </div>
              </div>
            </div>

            <div className={filterContainer}>
              <div className={filterItem}>
                <img
                  className={picture}
                  src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/VND-3-5.png?v=8533767724127042380"
                />
                <div className={`${overlay} ${viewVND ? activeViewing : ""}`}>
                  <div className={tag}>
                    About <span>Filter</span>
                  </div>
                  <p className={filterName}>
                    VND <span>3-5 Stop</span>
                  </p>
                  <p className={desc}>
                    Our Most successful filter to date and shrunk down to fit in
                    your pocket. This aluminum-framed variable nd filter is
                    engineered specifically for mobile filmmakers so you can
                    lower shutter speeds to cinematic levels.
                  </p>

                  <p className={listingTitle}>Camera Compatibility:</p>
                  <ul className={listing}>
                    <li>
                      <span>&#12297;&#12297;</span> Wide
                    </li>
                    <li>
                      <span>&#12297;&#12297;</span> Telephoto
                    </li>
                  </ul>
                </div>
              </div>
              <div className={filterDesc}>
                <p className={itemName}>VND</p>
                <p className={subName}>3-5 Stop</p>
                <div className={icon}>
                  <FaPlusCircle
                    className={`w-[30px] h-[30px] ${
                      viewVND ? "hidden" : "block"
                    }`}
                    onClick={() => setViewVND(true)}
                  />
                  <FaMinusCircle
                    className={`w-[30px] h-[30px] ${
                      viewVND ? "block" : "hidden"
                    }`}
                    ariaHidden="true"
                    onClick={() => setViewVND(false)}
                  />
                </div>
              </div>
            </div>

            <div className={filterContainer}>
              <div className={filterItem}>
                <img
                  className={picture}
                  src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/Mist-Diffusion.png?v=6542223224103519587"
                />
                <div className={`${overlay} ${viewMist ? activeViewing : ""}`}>
                  <div className={tag}>
                    About <span>Filter</span>
                  </div>
                  <p className={filterName}>Mist Filter</p>
                  <p className={desc}>
                    Give your content a dream-like cinematic glow and increased
                    production value. Mist evens out highlights and excels at
                    giving human subjects a polished appearance without
                    sacrificing image sharpness.
                  </p>

                  <p className={listingTitle}>Camera Compatibility:</p>
                  <ul className={listing}>
                    <li>
                      <span>&#12297;&#12297;</span> Ultra Wide
                    </li>
                    <li>
                      <span>&#12297;&#12297;</span> Wide
                    </li>
                    <li>
                      <span>&#12297;&#12297;</span> Telephoto
                    </li>
                  </ul>
                </div>
              </div>
              <div className={filterDesc}>
                <p className={itemName}>Mist</p>
                <p className={subName}>Diffusion</p>
                <div className={icon}>
                  <FaPlusCircle
                    className={`w-[30px] h-[30px] ${
                      viewMist ? "hidden" : "block"
                    }`}
                    onClick={() => setViewMist(true)}
                  />
                  <FaMinusCircle
                    className={`w-[30px] h-[30px] ${
                      viewMist ? "block" : "hidden"
                    }`}
                    ariaHidden="true"
                    onClick={() => setViewMist(false)}
                  />
                </div>
              </div>
            </div>

            <div className={filterContainer}>
              <div className={filterItem}>
                <img
                  className={picture}
                  src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/Mist-3-5-Diffusion-VND.png?v=17190295673969856245"
                />
                <div
                  className={`${overlay} ${viewMist35 ? activeViewing : ""}`}
                >
                  <div className={tag}>
                    About <span>Filter</span>
                  </div>
                  <p className={filterName}>
                    Mist <span>3-5 Stop</span>
                  </p>
                  <p className={desc}>
                    The first of its kind, a diffusion + VND in a single
                    aluminum frame. Revolutionizing the ability to combine both
                    filters in one, complete light control while adding a soft,
                    angelic, and creative look to your footage.
                  </p>

                  <p className={listingTitle}>Camera Compatibility:</p>
                  <ul className={listing}>
                    <li>
                      <span>&#12297;&#12297;</span> Wide
                    </li>
                    <li>
                      <span>&#12297;&#12297;</span> Telephoto
                    </li>
                  </ul>
                </div>
              </div>
              <div className={filterDesc}>
                <p className={itemName}>Mist 3-5</p>
                <p className={subName}>Diffusion VND</p>
                <div className={icon}>
                  <FaPlusCircle
                    className={`w-[30px] h-[30px] ${
                      viewMist35 ? "hidden" : "block"
                    }`}
                    onClick={() => setViewMist35(true)}
                  />
                  <FaMinusCircle
                    className={`w-[30px] h-[30px] ${
                      viewMist35 ? "block" : "hidden"
                    }`}
                    ariaHidden="true"
                    onClick={() => setViewMist35(false)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- mobile optimized filter section end --> */}

        <div className={gripOrientations}>
          {/* <!-- which right for you section  --> */}
          <div
            className={`${featureItem} ${sideBySideWithListing} ${orientations}`}
          >
            <h3>
              <span className={highlight}>
                Introducing Two <br className={mobileBreak} />
                New Grip Orientations
              </span>
            </h3>
            <div className={`${compareVnd} ${firstStack}`}>
              <div className={vndItem}>
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Grip1.jpg?v=1604876777"
                  className={render}
                />
                <p className={desc}>
                  Eight different grip positions ensure you are comfortable no
                  matter your shooting style.
                </p>
              </div>
              <div className={vndItem}>
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Grip2.jpg?v=1604876777"
                  className={render}
                />
                <p className={desc}>
                  Flip the grip to the front side for confident self filming
                  using iPhone 12's powerful rear cameras.
                </p>
              </div>
            </div>
          </div>

          <div className={`${featureItem} ${sideBySideWithListing}`}>
            <div className={`${compareVnd} ${secondStack}`}>
              <div className={vndItem}>
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Grip3.jpg?v=1604876777"
                  className={renderVertical}
                />
                <p className={desc}>
                  Set your camera on a self-timer and shoot vertically without
                  the need for a tripod.
                </p>
              </div>
              <div className={vndItem}>
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Grip4.jpg?v=1604876778"
                  className={renderVertical}
                />
                <p className={desc}>Confidently shoot photos vertically.</p>
              </div>
            </div>
          </div>

          {/* <!-- which right for you section end--> */}
        </div>

        <div className={`${featureItem} ${attentionToDetail} ${first}`}>
          <h3>
            <span className={highlight}>Attention to Detail</span>
          </h3>
          <p>LiteChaser Pro</p>
          <h2>Case</h2>
          <div className={animateContainer}>
            <img
              className=""
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/lc-Case-render.jpg?v=16918363677810951074"
            />
            <img
              className={`${bulletsOverlays} ${item}`}
              data-aos="zoom-out"
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/lc-Case-bullets.png?v=8619311812938221820"
            />
          </div>
        </div>

        <div className={divider}></div>

        <div className={`${featureItem} ${attentionToDetail}`}>
          <p>LiteChaser Pro</p>
          <h2>Grip</h2>
          <div className={animateContainer}>
            <img
              className=""
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/lc-Grip-render.jpg?v=3826286870672020852"
            />
            <img
              className={`${bulletsOverlays} ${item}`}
              data-aos="zoom-out"
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/lc-Grip-bullets.png?v=2258061723535961337"
            />
          </div>
        </div>

        <div className={`${featureItem} ${newForiP12}`}>
          <h3>
            <span className={highlight}>
              New For iPhone<sup>®</sup> 12
            </span>
          </h3>
          <div className={`${container} w-full`}>
            <div id={bottomHalf}></div>
            <div className={oneThirds}>
              <img
                className=""
                src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/Mini-Defender-Block.jpg?v=5903075793104885231"
              />
              <p>Mini Defender Lens Cover</p>
            </div>
            <div className={oneThirds}>
              <img
                className=""
                src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/Filter-Case-Block.jpg?v=16982444988110212845"
              />
              <p>Protective Filter Case</p>
            </div>
            <div className={oneThirds}>
              <img
                className=""
                src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/Case-Colorways-Block.jpg?v=7433004386294067182"
              />
              <p>3 Case Colorways</p>
            </div>
          </div>
        </div>

        {/* <!-- side by side section start --> */}
        <h3 className={`${mainTitle} ${availableKit}`}>Available Kits</h3>

        <div className={`${featureItem} ${filmmakingKit} ${sideBySide}`}>
          <div className={twoThirds}>
            <img
              className={top}
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/Filmmaking-Kit-BG.jpg?v=2665703389096179594"
            />
            <img
              className={overlay}
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/LCP-IP12-FilmmakingKit.png?v=13972821435396948379"
            />
          </div>
          <div className={oneThirds}>
            <h2>Filmmaking Kit</h2>
            <span>Includes Case + Grip + VND 3-5 Filter</span>
            <p>
              The essentials needed to capture more professional looking content
              with the iPhone12.
            </p>
            <a href="#" className={buyBtn}>
              Buy Now
            </a>
          </div>
        </div>

        <div className={`${featureItem} ${directorKit} ${sideBySide}`}>
          <div className={oneThirds}>
            <div className={alignContainer}>
              <h2>Director's Kit</h2>
              <span>
                Includes Case + Grip + <br className={mobileBreak} />
                VND 3-5 + Mist 3-5 + Bluetooth Shutter
              </span>
              <p>
                The complete ecosystem of LiteChaser gear for those looking to
                create high-end mobile content.
              </p>
              <a href="#" className={buyBtn}>
                Buy Now
              </a>
            </div>
          </div>
          <div className={twoThirds}>
            <img
              className={middle}
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/Directors-Kit-BG.jpg?v=9365240143648059258"
            />
            <img
              className={overlayTwo}
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/Render-LCP-IP12-Directors-Kit.png?v=12994485768899666536"
            />
          </div>
        </div>

        <div className={`${featureItem} ${buildYourKit} ${sideBySide}`}>
          <div className={twoThirds}>
            <img
              className={bottom}
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/Build-Your-Own-Kit.jpg?v=13244929760320288538"
            />
          </div>
          <div className={oneThirds}>
            <h2>Build Your Own</h2>
            <span>Customized to your creativity</span>
            <a href="#" className={buyBtn}>
              Buy Now
            </a>
          </div>
        </div>

        {/* <!-- side by side section end --> */}

        <div className={`${featureItem} ${reducingImpactSection}`}>
          <h3>
            <span className={highlight}>Reducing Our Impact</span>
          </h3>
          <div className={reducingImpact}></div>
        </div>
      </div>
    </div>
  )
}

export default LCP12BelowContent
