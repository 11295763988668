// extracted by mini-css-extract-plugin
export var MobilePlayBtn = "style-module--MobilePlayBtn--73657";
export var breakBanner = "style-module--breakBanner--693a5";
export var dataLeft = "style-module--dataLeft--91b4c";
export var dataRight = "style-module--dataRight--5c408";
export var fourbyfiveMchOverview = "style-module--fourbyfiveMchOverview--0f63a";
export var introData = "style-module--introData--f76ea";
export var introDataRight = "style-module--introDataRight--c1724";
export var leftStrike = "style-module--leftStrike--f698a";
export var productHero = "style-module--productHero--779dc";
export var sandBackg = "style-module--sandBackg--2456a";