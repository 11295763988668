import React, { useState, useEffect } from "react"
import "./style.scss"
import YotpoStarRatings from "../YotpoStarRatings"
// import { useCurrencyExchange } from "../../context/CurrencyExchange"
import axios from "axios"
import { productEndPointUrl } from "../../config"

const shopifyIdExtractor = (id: any) => id.slice(id.lastIndexOf("/") + 1)

const RecommndedProduct = ({ recommendedProduct }: any) => {
  // const { userCountry } = useCurrencyExchange()
  // const [currencyExchangedPrice, setCurrencyExchangedPrice] = useState(null)
  // const [countryCurrency, setCountryCurrency] = useState("")

  const productShopifyId = shopifyIdExtractor(recommendedProduct.shopifyId)

  // const getproductCurrency = async () => {
  //   if (userCountry?.cca2) {
  //     try {
  //       const response = await axios.post(
  //         productEndPointUrl,
  //         {
  //           country: userCountry?.cca2,
  //           productId: recommendedProduct?.shopifyId,
  //         },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           withCredentials: true,
  //         },
  //       )

  //       if (response?.data?.product) {
  //         setCountryCurrency(
  //           response.data?.product?.data?.product?.variants?.edges?.[0]?.node
  //             ?.price?.currencyCode,
  //         )
  //         setCurrencyExchangedPrice(
  //           response.data?.product?.data?.product?.variants?.edges?.[0]?.node
  //             ?.price?.amount,
  //         )
  //       }
  //     } catch (err: any) {
  //       return {
  //         statusCode: 500,
  //         body: JSON.stringify({
  //           failed: true,
  //           message: err.message,
  //         }),
  //       }
  //     }
  //   }
  // }

  // useEffect(() => {
  //   if (userCountry?.cca2) {
  //     getproductCurrency()
  //   }
  // }, [userCountry])

  return (
    <div className="w-full">
      <a href={`/products/${recommendedProduct.handle}`}>
        <div className="product-image-container">
          <div className="image-wrapper">
            <img
              src={recommendedProduct.featuredImage.originalSrc}
              className="product-image"
            />
          </div>
        </div>
        <div className="flex flex-col items-center">
          {/* @ts-ignore */}
          <YotpoStarRatings
            product={recommendedProduct}
            productShopifyId={productShopifyId}
          />
          <p className="font-black text-[14px] mb-0 mt-2">
            {recommendedProduct.title}
          </p>
          <p className="text-[14px]">
            From{" "}
            {/* {currencyExchangedPrice ? (
              <>
                {countryCurrency === "USD" ? "$" : countryCurrency + " "}
                {currencyExchangedPrice}
              </>
            ) : (
              <>{`$${recommendedProduct?.priceRangeV2?.minVariantPrice?.amount}`}</>
            )} */}
            {`$${recommendedProduct?.priceRangeV2?.minVariantPrice?.amount}`}
          </p>
        </div>
      </a>
    </div>
  )
}

export default RecommndedProduct
