/* eslint-disable */
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import Seo from "../../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import { useStoryblok } from "../../utils/storyblok"
import DynamicComponents from "../../components/DynamicComponents"
import loadable from "@loadable/component"
import useScrollBlock from "../../hooks/useScrollBlock"

import "./basecampOverview.scss"
const ModalVideo = loadable(() => import("react-modal-video"))

const BasecampOverview = () => {
  // Load Widgetic Script
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://widgetic.com/sdk/sdk.js"
    script.async = true
    document.head.appendChild(script)

    script.onload = e => {
      window.Widgetic.UI.parse()
    }

    return () => {
      script.remove()
    }
  })

  const [showVideo, setShowVideo] = useState(false)
  const [showVideo2, setShowVideo2] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    if (showVideo || showVideo2) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [showVideo, showVideo2])

  const pageData = useStaticQuery(graphql`
    query {
      storyblokEntry(full_slug: { eq: "pages/basecamp-matte-box" }) {
        id
        name
        content
        slug
        full_slug
        uuid
        created_at
      }
      shopifyProduct(handle: { eq: "basecamp-ultralight-matte-box-system" }) {
        handle
        title
      }
    }
  `)

  const product = pageData.shopifyProduct

  const story = pageData.storyblokEntry
    ? useStoryblok(pageData.storyblokEntry)
    : { content: null }

  const stickyNavComponent =
    story.content && story.content["sticky_nav"]
      ? story.content["sticky_nav"].map(blok => (
          <>
            {blok ? (
              <div {...sbEditable(blok)} className="sticky top-0 z-50">
                <DynamicComponents
                  blok={blok}
                  product={product}
                  key={blok._uid}
                />
              </div>
            ) : null}
          </>
        ))
      : null

  return (
    <>
      <Seo title="Basecamp Overview" />
      <Helmet>
        <script
          async
          type="text/javascript"
          src="https://widgetic.com/sdk/sdk.js"
        />
      </Helmet>
      <div className="sticky top-0 z-50">{stickyNavComponent}</div>
      <div id="basecamp-v3">
        <div class="basecamp-hero container-wrapper">
          <div class="hero-text vertical-center">
            <h2 className="display-font-logo font-bold">
              The Industry's Lightest Matte Box System.
            </h2>
            <p>
              The complete BaseCamp system is built to take your content to epic
              levels in the most demanding conditions. Featuring the lightest
              114mm setup in the industry and a diverse lineup of filters and
              mounting options, BaseCamp is the go-to matte box system for
              hobbyists and pros alike.
            </p>
          </div>
        </div>

        <div id="bc-st-video" class="basecamp-video container-wrapper">
          <div class="content-container">
            <div class="intro-text">
              <div class="intro">
                <span className="font-bold">Meet</span>
              </div>
              <div class="title">
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/BASECAMP_Logo.svg?v=1644460159"
                  alt="video-heading-image"
                />
              </div>
              <div class="text">
                <p>
                  Renan Ozturk and Taylor Rees have built a career challenging
                  the elements to capture epic footage of rarely seen places.
                  Several of those excursions have involved a BaseCamp, the
                  point in their journey which exemplifies taking things to the
                  next level. We couldn’t think of anyone better to put our
                  Ultralight BaseCamp Matte Box to the test on a real alpine
                  shoot.
                </p>
              </div>
            </div>
            <div class="featuring">
              <div class="title">
                <span class="dash">-</span>
                <span class="text font-bold">Featuring</span>
              </div>
              <div class="names">
                Renan Ozturk
                <br /> + Taylor Rees
              </div>
              <img
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/Renan-Ozturk.png?v=1644459957"
                alt="renan-ozturk-image"
              />
              <img
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/Taylor-Rees.png?v=1644459956"
                id="taylor"
                alt="taylor-rees-image"
              />
            </div>

            <a
              href="#data"
              id="inline"
              className="play-button x-y-center"
              onClick={e => {
                e.preventDefault()
                setShowVideo(true)
              }}
            />
            {/* <!-- Modal --> */}
            <ModalVideo
              channel="vimeo"
              videoId="372459516"
              isOpen={showVideo}
              onClose={() => setShowVideo(false)}
            />

            <div class="video-bg-img vertical-center">
              <img
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/BC-Video-Section-Thumbnail-Shape-Desktop-.5x_0.5x.png?v=1644268352"
                alt="video-thumbnail"
              />
            </div>
            <div class="mobile-only featuring-mobile">
              <div class="pictures">
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Renan-Ozturk.png?v=1644459957"
                  alt="renan-ozturk-image"
                />
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Taylor-Rees.png?v=1644459956"
                  id="taylor"
                  alt="taylor-rees-image"
                />
              </div>
              <p className="font-bold">Renan Ozturk & Taylor Ress</p>
            </div>
          </div>
        </div>

        <div class="basecamp-highlight">
          <h3 className="display-font-logo font-bold section-title">
            3 Stages of Light Control, Unlimited Creative Potential
          </h3>

          <div class="highlight-container">
            <img
              class="desktop-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/BC-3-Stages-Banner-Desktop.jpg?v=1644361765"
              alt=""
            ></img>
            <img
              class="mobile-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/BC-3-Stages-Light-Control-Mobile.jpg?v=1644361765"
              alt=""
            ></img>
          </div>
        </div>

        <div class="basecamp-highlight">
          <h3 className="display-font-logo font-bold section-title section-title">
            Engineered For Gimbal & Aerial Applications
          </h3>

          <div class="highlight-container contained">
            <img
              class="desktop-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/BC-Lightetst-114mm-Matte-Box-Desktop.jpg?v=1644361765"
              alt=""
            />
            <img
              class="mobile-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/BC-Lightest-114mm-Matte-Box-Mobile.jpg?v=1644361765"
              alt=""
            />

            <img
              class="desktop-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/BC-Proprietary-Filter-Designs-Desktop.jpg?v=1644361765"
              alt=""
            />
            <img
              class="mobile-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/BC-Proprietary-Filters-Mobile.jpg?v=1644361765"
              alt=""
            />
          </div>
        </div>

        <div class="multi-options">
          <h3 className="display-font-logo font-bold section-title section-title">
            Multiple Lens Connection Options
          </h3>
          <div class="feature-item option-3-display">
            <div class="features-container">
              <div class="one-third">
                <img
                  class=""
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/BC-Thread-Plates-Desktop.jpg?v=1644352280"
                />
                <p className="font-extrabold">Thread Plates</p>
                <span class="sub-text">
                  (Used to connect BaseCamp to lenses with filter threads)
                </span>
              </div>
              <div class="one-third">
                <img
                  class=""
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/BC-Clamp-Rings-Desktop.jpg?v=1644352279"
                />
                <p className="font-extrabold">Clamp Rings</p>
                <span class="sub-text">
                  (Full set includes 80mm, 87mm, 95mm, 100mm, 104mm, & 110mm)
                </span>
              </div>
              <div class="one-third">
                <img
                  class=""
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/BC-Rail-Mounts-Desktop.jpg?v=1644352279"
                />
                <p className="font-extrabold">Rail Mounts</p>
                <span class="sub-text">
                  (LWS, 15mm Studio, and 19mm Studio rail mount options
                  available)
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="diffusion compare-container three-items">
          <h3 className="display-font-logo font-bold section-title">
            Professional Diffusion, 3 Strength Options
          </h3>

          <div class="side-by-side">
            <div class="one-third">
              <p className="feature-title font-extrabold">Mist-L [1-8]</p>
              <span>
                Our lightest diffusion option at 1/8 strength. Can be used in
                most applications to help soften the sharpness of digital
                sensors.
              </span>
            </div>
            <div class="two-third offset-top">
              <widgetic
                class="desktop-only"
                id="62015913ecb2a1ff298b4567"
                resize="fill-width"
                width="2374"
                height="925"
                autoscale="on"
                adaptive="414"
              ></widgetic>
              <widgetic
                class="mobile-only"
                id="62015aaaecb2a1ba2a8b4567"
                resize="fill-width"
                width="1284"
                height="1788"
                autoscale="on"
                adaptive="414"
              ></widgetic>
            </div>
          </div>

          <div class="side-by-side col-reverse">
            <div class="two-third">
              <widgetic
                class="desktop-only"
                id="6201594fecb2a11b2a8b456b"
                resize="fill-width"
                width="2374"
                height="925"
                autoscale="on"
                adaptive="414"
              ></widgetic>
              <widgetic
                class="mobile-only"
                id="62015ae2ecb2a1d82a8b4567"
                resize="fill-width"
                width="1284"
                height="1788"
                autoscale="on"
                adaptive="414"
              ></widgetic>
            </div>
            <div class="one-third">
              <p className="feature-title font-extrabold">Mist [1/4]</p>
              <span>
                Smooths highlights and gives a subtle bloom to light sources.
                1/4 diffusion strength.
              </span>
            </div>
          </div>

          <div class="side-by-side">
            <div class="one-third">
              <p className="feature-title font-extrabold">Mist-H [1/2]</p>
              <span>
                This heavier 1/2 diffusion strength has a strong diffused look.
                Ideal for creative applications such as music videos or dream
                sequences.
              </span>
            </div>
            <div class="two-third offset-bottom">
              <widgetic
                class="desktop-only"
                id="6201597decb2a1402a8b4569"
                resize="fill-width"
                width="2374"
                height="925"
                autoscale="on"
                adaptive="414"
              ></widgetic>
              <widgetic
                class="mobile-only"
                id="62015b0becb2a1f82a8b4567"
                resize="fill-width"
                width="1284"
                height="1788"
                autoscale="on"
                adaptive="414"
              ></widgetic>
            </div>
          </div>
        </div>

        <div class="morphic compare-container">
          <h3 className="display-font-logo font-bold section-title">
            Circular & Rectangular Streak Filters
          </h3>

          <div class="side-by-side offset">
            <div class="one-third">
              <p className="feature-title font-extrabold">BlueMorphic</p>
              <span>
                BlueMorphic creates anamorphic-style blue streaks, available in
                Stage 1 Circular and Stage 2/3 Rectangular options.
              </span>
            </div>
            <div class="two-third offset-top">
              <widgetic
                class="desktop-only"
                id="62015816ecb2a17e298b456c"
                resize="fill-width"
                width="2374"
                height="925"
                autoscale="on"
                adaptive="414"
              ></widgetic>
              <widgetic
                class="mobile-only"
                id="62015a22ecb2a1572a8b4571"
                resize="fill-width"
                width="1284"
                height="1788"
                autoscale="on"
                adaptive="414"
              ></widgetic>
            </div>
          </div>

          <div class="side-by-side col-reverse">
            <div class="two-third offset-bottom">
              <widgetic
                class="desktop-only"
                id="6201588decb2a17e298b4578"
                resize="fill-width"
                width="2374"
                height="925"
                autoscale="on"
                adaptive="414"
              ></widgetic>
              <widgetic
                class="mobile-only"
                id="62015a7cecb2a1572a8b4576"
                resize="fill-width"
                width="1284"
                height="1788"
                autoscale="on"
                adaptive="414"
              ></widgetic>
            </div>
            <div class="one-third">
              <p className="feature-title font-extrabold">GoldMorphic</p>
              <span>
                GoldMorphic produces warm stylized streaks, available in both
                Stage 1 Circular and Stage 2/3 Rectangular options.
              </span>
            </div>
          </div>
        </div>

        <div class="morphic compare-container">
          <h3 className="display-font-logo font-bold section-title">
            Graduated Neutral Density Filters
          </h3>

          <div class="side-by-side offset">
            <div class="one-third">
              <p className="feature-title font-extrabold">ND4-GR</p>
              <span>
                Featuring a stop reduction from 2 down to 0, ideal for balancing
                exposure of the sky and foreground in most scenarios.
              </span>
            </div>
            <div class="two-third offset-top">
              <widgetic
                class="desktop-only"
                id="620159baecb2a1442a8b4567"
                resize="fill-width"
                width="2374"
                height="925"
                autoscale="on"
                adaptive="414"
              ></widgetic>
              <widgetic
                class="mobile-only"
                id="62015b38ecb2a1f22a8b4567"
                resize="fill-width"
                width="1284"
                height="1788"
                autoscale="on"
                adaptive="414"
              ></widgetic>
            </div>
          </div>

          <div class="side-by-side col-reverse">
            <div class="two-third offset-bottom">
              <widgetic
                class="desktop-only"
                id="620159edecb2a1472a8b456b"
                resize="fill-width"
                width="2374"
                height="925"
                autoscale="on"
                adaptive="414"
              ></widgetic>
              <widgetic
                class="mobile-only"
                id="62015b65ecb2a1232b8b4567"
                resize="fill-width"
                width="1284"
                height="1788"
                autoscale="on"
                adaptive="414"
              ></widgetic>
            </div>
            <div class="one-third">
              <p className="feature-title font-extrabold">ND8-GR</p>
              <span>
                Featuring a stop reduction from 3 down to 0, perfect for
                balancing exposure of the sky and foreground in harsher light.
              </span>
            </div>
          </div>
        </div>

        <div class="quote">
          <img
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/BaseCamp-Stage-3-Renan-Quote.jpg?v=1610506617"
            alt=""
            class="desktop-only"
          />
          <img
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/BC-Renan-Quote-Mobile.jpg?v=1611004539"
            class="mobile-only"
            alt=""
          />
        </div>

        <div class="system-overview">
          <img
            class="mobile-only"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/BC-Glamour-Shot-1-Mobile.jpg?v=1644541524"
            alt=""
          />
          <div class="overview-1">
            <div class="data vertical-center">
              <h3 className="font-extrabold">System Overview</h3>
              <a
                href="#data"
                id="system-overview-play-btn"
                className="play-button small"
                onClick={e => {
                  e.preventDefault()
                  setShowVideo2(true)
                }}
              />
            </div>
            {/* modal */}
            <ModalVideo
              channel="vimeo"
              videoId="504875296"
              isOpen={showVideo2}
              onClose={() => setShowVideo2(false)}
            />
          </div>
          <div class="overview-2">
            <img
              class="desktop-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/BC-Glamour-Shot-2-Desktop.jpg?v=1644534558"
              alt=""
            />
            <img
              class="mobile-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/BC-Glamour-Shot-2-Mobile.jpg?v=1644541524"
              alt=""
            />
            <img
              class="mobile-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/BC-Glamour-Shot-2.5-Mobile.jpg?v=1644541524"
              alt=""
            />
          </div>
          <div class="overview-3">
            <img
              class="desktop-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/BC-Glamour-Shot-3-Desktop.jpg?v=1644534558"
              alt=""
            />
            <img
              class="mobile-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/BC-Glamour-Shot-3-Mobile.jpg?v=1644541524"
              alt=""
            />
          </div>
        </div>

        <div class="build-kit">
          <img
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/BC-Build-Your-Kit-Mark.png?v=1644621104"
            alt=""
            class="be-prepare"
          />

          <a
            href="/products/basecamp-ultralight-matte-box-system"
            className="bc-build-btn font-extrabold"
          >
            Select Your System Here
          </a>
        </div>
      </div>
    </>
  )
}

export default BasecampOverview
