/* eslint-disable */
import React from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

const LinkItem = ({ blok }) => {
  const { link, display_name } = blok
  const location = useLocation()

  return (
    <li className="font-bold text-sm">
      <Link to={`${link}/${location.search ? location.search : ""}`}>
        {display_name}
      </Link>
    </li>
  )
}

export default LinkItem
