import React from "react"
import * as style from "../GearUp/index.module.scss"
import { sbEditable } from "@storyblok/storyblok-editable"
import DynamicComponents from "../DynamicComponents"

function FeaturedCollection(props: any) {
  const FeaturedCollectionItem = props?.Collections.map((blok: any) => (
    <>
      {blok ? (
        <div {...sbEditable(blok)} className="relative">
          <DynamicComponents blok={blok} key={blok._uid} />
        </div>
      ) : null}
    </>
  ))
  return (
    <div className={`${style.featuredCollections} my-20`}>
      <h2 className="text-center text-obsidian text-4xl font-black mb-20">
        {props?.Title}
      </h2>
      <div className="flex flex-col md:flex-row w-[90vw] m-auto justify-around">
        {FeaturedCollectionItem}
      </div>
    </div>
  )
}

export default FeaturedCollection
