import * as React from "react"

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  onIncrement: () => void
  onDecrement: () => void
}
const NumericInput = ({
  onIncrement,
  onDecrement,
  className,
  disabled,
  name = "Quantity:",
  value,
  ...props
}: Props) => {
  return (
    <div>
      <p className="mb-0 font-medium text-[#1C1C1C] text-sm">{name}</p>
      <div className="w-24 h-8 border border-[#1C1C1C] rounded-[30px] flex justify-between items-center px-1.5 mt-1">
        <button
          onClick={onDecrement}
          disabled={disabled}
          className=" w-4 h-4 m-0 p-0 pt-0.5 rounded-full flex justify-center items-center border border-[#1C1C1C] text-[#1C1C1C] disabled:border-[#949494] disabled:text-[#949494]"
        >
          -
        </button>
        <input
          value={value}
          disabled={disabled}
          {...props}
          className={`pl-2 w-8 relative right-1 text-sm text-center font-normal pt-1`}
        />
        <button
          onClick={onIncrement}
          disabled={disabled}
          className="w-4 h-4 m-0 p-0 pt-0.5 rounded-full flex justify-center items-center border border-[#1C1C1C] text-[#1C1C1C] disabled:border-[#949494] disabled:text-[#949494]"
        >
          +
        </button>
      </div>
    </div>
  )
}

export default NumericInput
