// extracted by mini-css-extract-plugin
export var breakBanner = "style-module--breakBanner--a86a9";
export var dataLeft = "style-module--dataLeft--7a9c8";
export var dataRight = "style-module--dataRight--b88b2";
export var helixBuOverview = "style-module--helixBuOverview--4a9b2";
export var helixMobilePlayBtn = "style-module--helixMobilePlayBtn--fe652";
export var introData = "style-module--introData--c241b";
export var introDataRight = "style-module--introDataRight--6b81f";
export var leftStrike = "style-module--leftStrike--c4214";
export var productHero = "style-module--productHero--fac37";
export var sandBackg = "style-module--sandBackg--28e87";