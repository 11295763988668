// extracted by mini-css-extract-plugin
export var imageContainer = "style-module--imageContainer--6046f";
export var introDataBottom = "style-module--introDataBottom--5bb5a";
export var introDataLeft = "style-module--introDataLeft--ffa77";
export var introDataRight = "style-module--introDataRight--90130";
export var introDataTop = "style-module--introDataTop--7067e";
export var playIcon = "style-module--play-icon--a712a";
export var productHeroBannerMobile = "style-module--productHeroBannerMobile--f0e1f";
export var productHeroDefault = "style-module--productHeroDefault--c1fcf";
export var productImage = "style-module--productImage--9997e";
export var thumbnailContainer = "style-module--thumbnail-container--3e400";
export var title = "style-module--title--73465";
export var video = "style-module--video--16d42";
export var videoContainer = "style-module--videoContainer--23caa";
export var youtubeVideo = "style-module--youtubeVideo--4d26a";