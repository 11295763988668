import React from "react"
import ProductCard2 from "../ProductCard2"
import * as styles from "./index.module.scss"
import SecondaryButton from "../SecondaryButton"

function Explore(props: any) {
  const { productCardsData3 } = props

  const shopText = props?.ExploreFullCollection?.content
    .map((item: { content: any }) => item?.content)
    .flat()

  return (
    <div className={`${styles.threadOnCamera} bg-[#f6f6f6] pt-20`}>
      <h2 className="text-center text-obsidian text-4xl font-black mt-0 mb-14 mx-[20px]">
        {props?.Title}
      </h2>
      <div className="flex flex-col lg:flex-row w-full justify-start lg:justify-center lg:items-start ">
        <div
          className={`${styles.products} lg:w-9/12 flex flex-col md:flex-row h-auto mb-0 lg:mb-20`}
        >
          {productCardsData3?.map((product: any) => (
            <ProductCard2 key={product.id} product={product} />
          ))}
        </div>
        <div
          className={`lg:w-3/12 items-start pt-24 md:pt-10 lg:pt-10 pb-24 flex px-12 flex-col`}
        >
          <h3 className="font-black text-obsidian text-5xl md:text-3xl xl:text-3xl 2xl:text-5xl">
            {shopText?.[0]?.text}
            <br />
            {shopText?.[1]?.text}
            {shopText?.[2]?.text}
            <br />
            <span className="text-paloverde">{shopText?.[3]?.text}</span>
          </h3>
          <SecondaryButton
            text={props?.ShopNow || "Shop Now"}
            link={props.linkItem || "/collections/thread-on-filters"}
            //@ts-ignore
            className="align-left"
            buttonStyle="obsidian"
          />
        </div>
      </div>
    </div>
  )
}

export default Explore
