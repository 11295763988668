import React from "react"
import * as style from "./style.module.scss"
function SectionTitle(props: any) {
  const alignClass = !props.align_start ? style.sectionleftStrike : ""
  return (
    <div className={`${style.sectionTitleStyles} overflow-hidden w-full`}>
      <div
        className={`${style.sectionBannerStyles} bg-black py-28 ${alignClass}`}
      >
        <div
          className={`flex flex-col justify-center lg:justify-start items-center lg:items-start text-center lg:text-left lg:text-start lg:absolute mt-0 lg:-mt-7 w-full lg:w-auto ${!props.align_start ? "right-1/2 px-[30px]" : "left-1/2 px-[30px]"
            }`}
        >
          <span className="text-white tracking-normal font-expanded font-bold text-md lg:text-xs relative -top-1">
            {props.title}
            {/* <span className=" pl-1 tracking-[-10px]">---</span> */}
          </span>
          <span className="text-[#c99e1a] font-extrabold font-condensed mx-10 lg:mx-0 text-3xl lg:text-2xl 2xl:text-3xl">
            {props.description}
          </span>
        </div>
      </div>
    </div>
  )
}

export default SectionTitle
