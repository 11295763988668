/* eslint-disable */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
// import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"
import "./style.scss"
import { useLocation } from "@reach/router"

const LegacyMavic2 = ({ blok, product }) => {
  const location = useLocation()

  const { title } = blok

  const { metafields, shopifyId } = product

  const productId = shopifyId.slice(shopifyId.lastIndexOf("/") + 1)

  const [oneWholeVideo, setOneWholeVideo] = useState("")
  const [oneWhole, setOneWhole] = useState("")

  useEffect(() => {
    metafields.forEach(metafield => {
      const { key, value } = metafield

      if (key === "one-whole-video") {
        setOneWholeVideo(value)
      } else if (key === "one-whole") {
        setOneWhole(value)
      }
    })
  })

  const renderThreeIcons =
    productId === "1488127459412" || productId === "1488936435796"

  return (
    <div className="below-content wrapper">
      <div className="additional-overview" id={title}>
        <div className="large--one-whole">
          <div className="product-overview">
            <div className="features-banner header">
              <h3>WHAT&apos;S INCLUDED</h3>
            </div>
          </div>

          {/* <!-- Coatings sections --> */}
          <div className="features-banner features-section wrapper">
            <div className="grid-uniform">
              {/* <!-- displaying 2 diferent features listing depending on products --> */}
              {/* <!-- this is currently hard coded based on the product id  --> */}
              {renderThreeIcons ? null : (
                <div className="grid__item large--one-quarter">
                  <StaticImage
                    loading="eager"
                    alt="Polarization Markers"
                    src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/Filter-markers-icon.png?v=26508526364565776461560298132"
                    className="block"
                    style={{ maxHeight: "100px" }}
                  />
                  <span>Polarization Markers</span>
                </div>
              )}
              <div
                className={`grid__item ${renderThreeIcons ? "large--one-third" : "large--one-quarter"
                  }`}
              >
                <StaticImage
                  loading="eager"
                  alt="Premium Magnetic Case"
                  src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/magnetic-case.png?v=134025917052324994591560298212"
                  className="block"
                  style={{ maxHeight: "100px" }}
                />
                <span>Premium Magnetic Case</span>
              </div>
              <div
                className={`grid__item ${renderThreeIcons ? "large--one-third" : "large--one-quarter"
                  }`}
              >
                <StaticImage
                  loading="eager"
                  alt="Aircraft Aluminum Construction"
                  src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/aluminum-construction.png?v=144287063318782874931560298162"
                  className="block"
                  style={{ maxHeight: "100px" }}
                />
                <span>Aircraft Aluminum Construction</span>
              </div>
              <div
                className={`grid__item ${renderThreeIcons ? "large--one-third" : "large--one-quarter"
                  }`}
              >
                <StaticImage
                  loading="eager"
                  alt="Stock UV Tool"
                  src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/stock_uv_tool.png?v=104686664913884307261560298232"
                  className="block"
                  style={{ maxHeight: "100px" }}
                />
                <span>Stock UV Tool</span>
              </div>
            </div>
          </div>
          {/* <!-- Coatings sections end --> */}

          {oneWholeVideo.length > 0 ? (
            <div dangerouslySetInnerHTML={{ __html: oneWholeVideo }} />
          ) : null}
        </div>
      </div>
      {/* <!--additional overview end--> */}

      {/* {{ product.metafields["global"]["one-whole-video"] }} */}

      <div className="product-overview">
        <div className="features-banner header">
          <h3>FEATURES</h3>
        </div>
      </div>

      {/* <!-- Coatings sections --> */}
      <div className="features-banner coatings-section wrapper">
        {/* <!-- <img src="{{ 'CS_Header.png' | asset_url }}" className='ql-icon'> --> */}
        <div className="grid-uniform">
          <div className="grid__item large--one-third push-left">
            <StaticImage
              loading="lazy"
              alt="Anti-reflection"
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/anti-reflection.png?v=78869553166251837711560298163"
              className="block"
              style={{ maxHeight: "100px" }}
            />
            <span>ANTI-REFLECTION</span>
          </div>
          <div className="grid__item large--one-third">
            <StaticImage
              loading="lazy"
              alt="Anti-water"
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/anti-water.png?v=77846576156076869381560298163"
              className="block"
              style={{ maxHeight: "100px" }}
            />
            <span>ANTI-WATER</span>
          </div>
          <div className="grid__item large--one-third push-right">
            <StaticImage
              loading="lazy"
              alt="Anti-oil"
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/anti-oil.png?v=68360722118162097291560298163"
              className="block"
              style={{ maxHeight: "100px" }}
            />
            <span>ANTI-OIL</span>
          </div>
        </div>
        <StaticImage
          loading="lazy"
          alt="Filter Features Desktop"
          src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/cs_coatings_chart_banner.png?v=174674081915610842331560298178"
          className="coatings-chart-banner desktop-only"
        />
        <StaticImage
          loading="lazy"
          alt="Filter Features Mobile"
          src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/Coatings_Banner_mavic2_mobile.png?v=51472881303166855901560298128"
          className="coatings-chart-banner mobile-only"
        />
      </div>
      {/* <!-- Coatings sections end --> */}

      <div className="product-overview">
        <div
          className="large--one-whole"
          dangerouslySetInnerHTML={{ __html: oneWhole }}
        />
      </div>
      {/* <!--product-overview end--> */}

      {/* <div className="buying-guide">
        <div className="product-overview">
          <div className="features-banner header">
            <h3>FILTER COLLECTIONS</h3>
          </div>
        </div>
        <div className="wrapper">
          <div className="grid-uniform">
            <div className="grid__item medium--one-whole large--one-third">
              <div className="section-title">
                Starter
              </div>
              <Link
                to="/products/mavic-2-pro-filters-shutter-collection"
                className="item-product shutterCollectionTile"
              />
              <Link
                to="/products/dji-mavic-2-filters-vivid"
                className="item-product vividCollectionTile"
              />
            </div>

            <div className="grid__item medium--one-whole large--one-third">
              <div className="section-title">
                Expansion
              </div>
              <Link
                to="/products/dji-mavic-2-pro-filters-1"
                className="item-product LimitedCollectionTile"
              />
            </div>
          </div>
        </div>
      </div> */}

      <div className="features-banner lifetime-banner">
        <div className="wrapper">
          <div className="feature-img">
            <StaticImage
              loading="lazy"
              alt="Aircraft Warranty"
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/Aircraft-Warranty-Badge.png?v=148533241780711738491560298124"
            // imgClassName="feature-img"
            />
          </div>
          <div className="feature-text">
            <div className="inner-container">
              <h3>AIRCRAFT WARRANTY</h3>
              <span>
                Guarantees against any damage to the gimbal or camera{" "}
              </span>
              <span className="">caused by our filters.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LegacyMavic2

LegacyMavic2.propTypes = {
  blok: PropTypes.shape({
    title: PropTypes.string,
  }),
  product: PropTypes.shape({
    metafields: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ).isRequired,
    shopifyId: PropTypes.string.isRequired,
  }).isRequired,
}

LegacyMavic2.defaultProps = {
  blok: { title: "Mavic 2" },
}
