/* eslint-disable */
import { sbEditable } from "@storyblok/storyblok-editable"
import React from "react"
import TileContents from "../TileContents"
import LCP12BelowContent from "../LCP12BelowContent"

const Components = {
  TileContents,
  LCP12BelowContent,
}

const DynamicBelowcontents = ({ blok }) => {
  const blokComponent = blok ? blok.component : null

  if (!blokComponent) return null

  if (typeof Components[blokComponent] !== "undefined") {
    const Component = Components[blokComponent]
    return (
      <div {...sbEditable(blok)}>
        <Component blok={blok} />
      </div>
    )
  }
  return (
    <p>
      The component <strong>{blokComponent}</strong> has not been created yet.
    </p>
  )
}

export default DynamicBelowcontents
