import * as React from "react"

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  onIncrement: () => void
  onDecrement: () => void
}

const HorizontalNumericInput = ({
  onIncrement,
  onDecrement,
  className,
  disabled,
  ...props
}: Props) => {
  return (
    <div className="flex items-center justify-center  input-shadow ">
      <button
        disabled={disabled}
        className="w-5 h-5 cursor-pointer text-lg p-0 m-0 flex justify-center items-center rounded-full border-solid text-obsidian place-items-center hover:bg-paloverde hover:text-white"
        aria-label="Increment"
        onClick={onDecrement}
      >
        -
      </button>
      <input
        disabled={disabled}
        type="numeric"
        className={`w-5 grid col-start-1 col-span-2 text-[13px] row-span-2 border-none border-black border-r-2 font-md text-obsidian text-center bg-transparent ${className}`}
        {...props}
      />
      <button
        disabled={disabled}
        className="w-5 h-5 cursor-pointer text-lg p-0 m-0 flex justify-center items-center rounded-full border-solid text-obsidian place-items-center hover:bg-paloverde hover:text-white"
        aria-label="Increment"
        onClick={onIncrement}
      >
        +
      </button>
    </div>
  )
}

export default HorizontalNumericInput
