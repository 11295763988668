/* eslint-disable */
//@ts-nocheck
import React, { useEffect, useState, useRef } from "react"
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/pagination"
import "./slide.css"
import * as style from "./slide.module.scss"
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules"

function Slider(props: any) {
  const { id, title, images } = props
  const slides = images?.map(image => ({
    title,
    image: image.Image.filename,
  }))

  const swiperRef = useRef(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const [activeTitle, setActiveTitle] = useState(slides[0]?.title)

  useEffect(() => {
    const swiperInstance = swiperRef.current?.swiper ?? null

    const handleSlideChange = () => {
      const newIndex = swiperInstance?.realIndex ?? 0
      setActiveIndex(newIndex)
      setActiveTitle(slides[newIndex]?.title)
    }
    if (swiperInstance) {
      swiperInstance.on("slideChange", handleSlideChange)
    }
    return () => {
      if (swiperInstance) {
        swiperInstance.off("slideChange", handleSlideChange)
      }
    }
  }, [slides])

  const handleBulletClick = index => {
    const swiperInstance = swiperRef.current?.swiper ?? null
    if (swiperInstance) {
      swiperInstance.slideToLoop(index)
    }
  }

  return (
    <div>
      <div className="flex flex-col w-full justify-center items-center bg-black py-2">
        <div className="w-full">
          <div className={`${style.slideTitle} slide-title font-helvetica-neue text-white text-center text-[17px] md:text-[22px] font-black font-expanded`}>
            {activeTitle}
          </div>
        </div>
      </div>
      <div className="relative bg-black px-[1px] sm:px-10 pb-20">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          initialSlide={0}
          slideToClickedSlide={true}
          zoom={{ maxRatio: 2, toggle: true }}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={{ el: `.swiper-pagination-${id}`, clickable: true }}
          navigation={{
            nextEl: `.swiper-button-next-${id}`,
            prevEl: `.swiper-button-prev-${id}`,
          }}
          loop
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="mySwiper"
          ref={swiperRef}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <div className="relative flex flex-col items-center">
                <img
                  src={slide.image}
                  alt={slide.title}
                  className="w-full !h-[300px] !object-contain"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="flex justify-center items-center mt-1">
          {slides.map((_, index) => (
            <div
              key={index}
              className={`custom-bullet ${index === activeIndex ? "active" : ""
                }`}
              onClick={() => handleBulletClick(index)}
            ></div>
          ))}
        </div>
        <div className="w-full relative justify-center items-center top-[21px]">
          <div
            className={`swiper-button-prev swiper-button-prev-${id}`}
            style={{
              position: "absolute",
              bottom: "17px",
              left: "calc(50% - 140px)",
              zIndex: 10,
              cursor: "pointer",
            }}
          >
            <FaArrowLeftLong className="hidden sm:block !text-[#CEC9C9]" />
          </div>
          <div
            className={`swiper-button-next swiper-button-next-${id}`}
            style={{
              position: "absolute",
              bottom: "18px",
              right: "calc(50% - 140px)",
              zIndex: 10,
              cursor: "pointer",
              marginRight: "10px",
            }}
          >
            <FaArrowRightLong className="hidden sm:block !text-[#CEC9C9]" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slider
