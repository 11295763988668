/* eslint-disable  */

import { Link, navigate } from "@reach/router"
import React, { FC, useEffect, useState } from "react"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { useLocation } from "@reach/router"

import { FaRegTimesCircle, FaPlus, FaRegCopy } from "react-icons/fa"
import "../../styles/profile.scss"
import { getDate } from "../../utils/formatDate"

const Rewards: FC<RoutedHTMLAttr> = () => {
  const location = useLocation()
  const axios = useAxiosPrivate()

  const [dividend, setDividend] = useState("")
  const [referralLink, setReferralLink] = useState("")
  const [isCopied, setIsCopied] = useState(false)
  const [history, setHistory] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axios
      .get("/.netlify/functions/rewards")
      .then(result => {
        setDividend(
          result?.data?.loyalty?.credit_balance_in_customer_currency || "$0",
        )
        setReferralLink(
          `https://rwrd.io/${result?.data?.loyalty?.referral_code?.code || ""}`,
        )
        setHistory(result?.data?.loyalty?.history_items || [])
        setLoading(false)
      })
      .catch(() => setLoading(false))
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const steps = [
    {
      title: "$5 OFF THEIR FIRST ORDER.",
      desc: "Simply send your friend the URL generated below, they will then get a unique code for $5 off their first order.",
    },
    {
      title: "HOW TO RECEIVE YOUR CREDIT",
      desc: "They must then create a PolarPro account and make a purchase in order for you to receive your $5 credit.",
    },
    {
      title: "UNLIMITED FRIEND INVITES",
      desc: "There’s no limit to friend invites..refer away!",
    },
  ]

  const copyContent = async () => {
    try {
      await navigator.clipboard.writeText(referralLink)
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 3000)
    } catch (err) {
      console.error("Failed to copy: ", err)
    }
  }
  if (loading) {
    return (
      <div className="w-full min-h-screen flex justify-center items-center">
        <div role="status">
          <svg
            aria-hidden="true"
            className="w-24 h-24 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  }
  return (
    <div className="flex flex-col min-h-screen w-full justify-center items-center">
      <div className="flex justify-between items-center px-6 2xl:px-0 w-full pb-1 border-b-4 border-black max-w-4xl">
        <div className="flex items-center">
          <p className="uppercase text-lg text-obsidian font-extrabold mb-0">
            ABOUT DIVDEND BALANCE
          </p>
        </div>
        <button onClick={() => navigate(-1)}>
          <FaRegTimesCircle className="w-6 h-6 text-obsidian hover:text-paloverde" />
        </button>
      </div>
      <div className="flex flex-col mt-6 px-6 2xl:px-0 items-center w-full lg:max-w-4xl">
        <div className="w-full bg-[#1c1c1c] text-center lg:text-left py-8 px-4 lg:px-10">
          <h1 className="text-36 font-normal text-white mb-2">
            <span className="font-extrabold">{dividend}</span>
          </h1>
          <p className="text-[#C99E1A] font-extrabold opacity-80 mb-3 uppercase">
            DIVIDEND BALANCE
          </p>
          <Link
            to={`/collections/shop-all/${
              location.search ? location.search : ""
            }`}
            className="inline-block text-white uppercase text-sm font-primary"
          >
            Start Shopping
          </Link>
        </div>
        <div className="bg-white w-full pt-6 pb-12 px-4 lg:px-8">
          <div>
            <h1 className="font-extrabold mb-2">Rewards</h1>
            <p className="max-w-lg">
              Shopping at PolarPro.com has its benefits. Members receive 5%
              credit back on every dollar spent, plus “Refer a Friend” bonus
              cash!
            </p>
            <div className="border-t-2 border-gray-600 mb-6"></div>
            <h3 className="text-[#C99E1A] font-extrabold mb-2">
              Refer a Friend:
            </h3>
            <h1 className="font-black text-[black] mb-3">How It Works</h1>
            <ol>
              {steps.map((item, key) => (
                <li
                  key={key}
                  className="font-black text-lg text-[black] mt-6"
                  style={{ listStyleType: "decimal" }}
                >
                  <h4 className="mb-1 font-extrabold text-lg text-[black]">
                    {item.title}
                  </h4>
                  <p className="font-normal max-w-lg text-base text-[#1C1C1C]">
                    {item.desc}
                  </p>
                </li>
              ))}
            </ol>
            <div>
              <h3 className="text-[black] font-extrabold">Share Your Link</h3>
              <div>
                <input
                  defaultValue={referralLink}
                  className="border-2  border-[#1C1C1C] p-2 text-[#1C1C1C] font-bold min-w-[300px]"
                />
              </div>
              <button onClick={copyContent} className="flex items-center  mt-2">
                <div className="border-2 flex border-[#1C1C1C] h-[48px] py-2 px-6 text-[#1C1C1C] font-bold">
                  {isCopied ? "Copied" : "Copy"}
                </div>
                <div className="border-[#1C1C1C] border-2 h-[48px] p-2 ml-[-1px]">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 1H4C2.9 1 2 1.9 2 3V17H4V3H16V1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM19 21H8V7H19V21Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="bg-white w-full mt-6 pt-6 pb-12 px-4 lg:px-8">
          <div>
            <h1 className="font-extrabold mb-2 text-3xl">Transaction Status</h1>
            <div className="overflow-x-scroll lg:overflow-x-hidden w-full mt-4">
              <table className="w-full min-w-[1024px] lg:min-w-[auto]">
                <thead className="bg-[#1C1C1C] border-2 border-[#1C1C1C] text-sm text-white">
                  <th className="!px-4">
                    <td>Transaction / Status</td>
                  </th>
                  <th className="!px-4">
                    <td>Date</td>
                  </th>
                  <th className="!px-4">
                    <td>Reward</td>
                  </th>
                </thead>
                <tbody>
                  {history?.length
                    ? history.map((item: any, key) => (
                        <tr key={key} className="border-2 border-[#1C1C1C]">
                          <td className="!px-4">{item.status}</td>
                          <td className="!px-4">{getDate(item.created_at)}</td>
                          <td className="!px-4">{item.action}</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Rewards
