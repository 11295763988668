/* eslint-disable */
// react/no-danger
import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import loadable from "@loadable/component"
import Seo from "../../components/seo"

import "../../styles/goldenHour.scss"

const LoadableMiniPodcastPlayer = loadable(() =>
  import("../../components/podcastPlayer/miniPodcastPlayer"),
)
const LoadablePodcastPlayer = loadable(() =>
  import("../../components/podcastPlayer/podcastPlayer"),
)

const GoldenHourPodcastPage = () => {
  const popularArticles = []

  // Query golden hour podcast data
  const data = useStaticQuery(graphql`
    query articles {
      storefront {
        blog(handle: "Golden-Hour-Podcast") {
          articles(first: 250, reverse: true) {
            edges {
              node {
                title
                contentHtml
                src: metafield(
                  namespace: "global"
                  key: "golden-hour-podcast-src"
                ) {
                  key
                  value
                }
                episode: metafield(
                  namespace: "global"
                  key: "golden-hour-episode"
                ) {
                  key
                  value
                }
                itunes: metafield(
                  namespace: "global"
                  key: "golden-hour-itunes-link"
                ) {
                  key
                  value
                }
                spotify: metafield(
                  namespace: "global"
                  key: "golden-hour-spotify-link"
                ) {
                  key
                  value
                }
                profession: metafield(
                  namespace: "global"
                  key: "golden-hour-profession"
                ) {
                  key
                  value
                }
                tags
              }
            }
          }
        }
      }
    }
  `)

  let featuredPodcastSrc
  let featuredEp
  let featuredArticle

  // Filter through golden hour podcasts for featured and popular episodes
  data.storefront.blog.articles.edges.forEach(article => {
    if (article.node.tags.includes("Most-Popular")) {
      popularArticles.push(article.node)
    } else if (article.node.tags.includes("Featured")) {
      featuredArticle = article.node

      // Initialize metafields data
      featuredPodcastSrc = article.node.src.value
      featuredEp = article.node.episode.value
    }
  })

  return (
    <>
      <Seo title="Golden Hour Podcast" />
      {/* Golden Hour Banner */}
      <div className="goldenHourBanner">
        <div className="bannerContent">
          <div className="golden-hour-logo-wrapper">
            <StaticImage
              className="focusLogo"
              width={500}
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/GoldenHour-Sunset-op.png?v=13798000506172318013"
              placeholder="tracedSVG"
            />
          </div>
          <h3>
            <span>Hosted By</span> Dave Maze
          </h3>
          <div className="subBanner">
            <div className="availableAt">
              <p>
                New Episodes Every <br />
                <span>Tuesday Morning</span>
              </p>
              <Link
                name="apple-podcast-button"
                className="itunesPodcastButton"
                target="_blank"
                to="https://podcasts.apple.com/ca/podcast/golden-hour/id1454412220"
                rel="noreferrer"
              />
              <Link
                name="spotify-button"
                className="spotifyPodcastButton"
                target="_blank"
                to="https://open.spotify.com/show/3tybKZ2Z37EDmz9oaBB3DO"
                rel="noreferrer"
              />
              <Link
                name="google-podcast-button"
                className="googlePodcastButtonGH"
                target="_blank"
                to="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8yNTQzMzQucnNz"
                rel="noreferrer"
              />
            </div>
            <div className="desc">
              Golden Hour is where filmmakers and photographers come together to
              inspire, educate, and share real authentic stories direct from the
              world&apos;s most talented creative community.
            </div>
          </div>
        </div>
      </div>
      {/* Golden Hour Podcasts */}
      <div className="golden-hour-content">
        <div className="featured-recent">
          <p className="most-recent">
            Most Recent Episode -{" "}
            <span>
              Ep
              {featuredEp}.
            </span>
          </p>
          <p className="who">{featuredArticle.title}</p>
          <p
            className="article-content"
            dangerouslySetInnerHTML={{ __html: featuredArticle.contentHtml }}
          />
          <div className="gh-player-section">
            <LoadablePodcastPlayer featuredPodcastSrc={featuredPodcastSrc} />
          </div>
        </div>
        <div className="episodes-list-container">
          <div className="intro">
            <p>Most.</p>
            <p>Popular.</p>
            <p>Episodes.</p>
            <div className="listen-full-length">
              <p className="header">Listen to the full-length episodes here:</p>
              <Link
                name="apple-podcast-button"
                class="apple-podcast-button"
                target="_blank"
                to="https://podcasts.apple.com/ca/podcast/golden-hour/id1454412220"
              />
              <Link
                name="spotify-button"
                class="spotify-button"
                target="_blank"
                to="https://open.spotify.com/show/3tybKZ2Z37EDmz9oaBB3DO"
              />
              <Link
                name="google-podcast-button"
                class="google-podcast-button"
                target="_blank"
                to="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8yNTQzMzQucnNz"
              />
            </div>
          </div>
          <div className="popular-ep-list" style={{ zIndex: 999 }}>
            {popularArticles.map((article, index) => (
              <div
                article={article}
                index={index}
                key={`popular-episodes-${index}`}
              >
                <LoadableMiniPodcastPlayer article={article} index={index} />
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Meet the host */}
      <div className="meet-the-host">
        <div className="bio">
          <h2>Meet The Host.</h2>
          <p>
            Content creator and Golden Hour host, Dave Maze, has worked over the
            last decade in the photo and video industry. Dave has participated
            in a range of different projects ranging from large scale
            productions to running a full-time YouTube channel.
          </p>
        </div>
        <div className="picture">
          <img
            id="dave-maze"
            alt="Dave Maze"
            src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/Headshot-Dave.png?v=13319562619302685244"
          />
        </div>
      </div>
    </>
  )
}

export default GoldenHourPodcastPage
