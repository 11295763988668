/* eslint-disable */
import React from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

const LargeMenuItem = ({ blok }) => {
  const { link, label } = blok
  const location = useLocation()

  return (
    <li
      className={`relative text-2xl font-extrabold border-b-[1px] border-[#C8C8C8] h-[50px] md:pt-[9px] mb-3 pb-2 md:pl-0 md:mr-[15%] hover:text-paloverde`}
    >
      <Link
        to={`${link}/${location.search ? location.search : ""}`}
        className="h-full"
      >
        {label}
      </Link>
    </li>
  )
}

export default LargeMenuItem
