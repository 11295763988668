// extracted by mini-css-extract-plugin
export var MobilePlayBtn = "style-module--MobilePlayBtn--10656";
export var basecampOverview = "style-module--basecampOverview--488d0";
export var breakBanner = "style-module--breakBanner--57787";
export var dataLeft = "style-module--dataLeft--3a1e5";
export var dataRight = "style-module--dataRight--94c34";
export var introData = "style-module--introData--9d63c";
export var introDataRight = "style-module--introDataRight--52f1e";
export var leftStrike = "style-module--leftStrike--12a51";
export var productHero = "style-module--productHero--eacc8";
export var sandBackg = "style-module--sandBackg--4ba67";