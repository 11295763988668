/* eslint-disable */

import React, { useState } from "react"
import Seo from "../../components/seo"
import * as style from "./lcp14.module.scss"

import { useStaticQuery, graphql } from "gatsby"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import { useStoryblok } from "../../utils/storyblok"
import DynamicComponents from "../../components/DynamicComponents"
import KlaviyoLcp14Signup from "../../components/klaviyoLcp14Signup/klaviyoLcp14Signup"

const lcp14Signup = () => {
  const pageData = useStaticQuery(graphql`
    query {
      storyblokEntry(full_slug: { eq: "pages/lcp14-signup" }) {
        id
        name
        content
        slug
        full_slug
        uuid
        created_at
      }
    }
  `)

  const story = pageData.storyblokEntry
    ? useStoryblok(pageData.storyblokEntry)
    : { content: null }

  const stickyNavComponent =
    story.content && story.content["sticky_nav"]
      ? story.content["sticky_nav"].map(blok => (
        <>
          {blok ? (
            <div {...sbEditable(blok)} className="sticky top-0 z-50">
              <DynamicComponents
                blok={blok}
                product={{ handle: "lcp14-signup", title: "lcp14-signup" }}
                key={blok._uid}
              />
            </div>
          ) : null}
        </>
      ))
      : null

  return (
    <>
      <Seo title="Flare App Overview" />

      {/* sticky nav */}
      <div className="sticky top-0 z-50">{stickyNavComponent}</div>

      <div className={style.lcp14Signup}>
        <img
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP14-Hero-Banner-Mobile.webp?v=1661894550"
          alt=""
          className="relative sm:block md:hidden w-screen m-0"
        />
        <img
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP14-Hero-Banner-Desktop.webp?v=1661894551"
          alt=""
          className="relative hidden sm:hidden md:block w-screen m-0"
        />
        <div className={`${style.data} absolute`}>
          {/* <div className="tag bg-grey-dark text-white font-bold text-sm inline-block px-4 pb-[2px] pt-[7px] rounded-tl-lg rounded-br-lg"></div> */}
          <div className="title font-black text-white text-5xl md:text-3xl lg:text-5xl mt-4">
            Coming Early October
          </div>
          <div className="sub-title font-expanded text-white text-md md:text-sm lg:text-md w-full lg:w-[24vw] mt-2">
            The Ultimate Camera Rig for your{" "}
            <span className="text-paloverde">iPhone® 14.</span> Sign up to be
            notified on launch.
          </div>
          <div className={`${style.klaviyoSignup} mt-8`}>
            <KlaviyoLcp14Signup />
          </div>
        </div>
      </div>
    </>
  )
}

export default lcp14Signup
