// extracted by mini-css-extract-plugin
export var active = "flareAppOverview-module--active--b6bb7";
export var bottom = "flareAppOverview-module--bottom--416fc";
export var clearfix = "flareAppOverview-module--clearfix--622ca";
export var desktopOnly = "flareAppOverview-module--desktop-only--3c381";
export var download = "flareAppOverview-module--download--442bb";
export var flareOverview = "flareAppOverview-module--flareOverview--46fd3";
export var flareSlider = "flareAppOverview-module--flareSlider--e537f";
export var getApp = "flareAppOverview-module--getApp--c6963";
export var hero = "flareAppOverview-module--hero--c7b5a";
export var item = "flareAppOverview-module--item--c726b";
export var mobileOnly = "flareAppOverview-module--mobile-only--22368";
export var owlDot = "flareAppOverview-module--owl-dot--3bfc8";
export var owlDots = "flareAppOverview-module--owl-dots--e95ba";
export var owlItem = "flareAppOverview-module--owlItem--94ef2";
export var seeFlare = "flareAppOverview-module--seeFlare--6aa85";
export var slideNumber = "flareAppOverview-module--slideNumber--3bf66";
export var slides = "flareAppOverview-module--slides--1ad33";
export var subText = "flareAppOverview-module--subText--37181";
export var text = "flareAppOverview-module--text--e8692";
export var textBlurp = "flareAppOverview-module--textBlurp--a3124";
export var verticalCenter = "flareAppOverview-module--verticalCenter--b3c45";
export var xYCenter = "flareAppOverview-module--x-y-center--b1dcf";
export var yellow = "flareAppOverview-module--yellow--aede8";