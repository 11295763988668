import React from "react"
import PropTypes from "prop-types"

const QuartzlineTitle = ({ blok }) => (
  <div className="relative w-full text-center my-20">
    <h2 className="w-[45%] uppercase display-font m-auto text-grey-dark font-light">
      <strong className="display-font font-black text-obsidian">
        {blok.strong_title}{" "}
      </strong>
      {blok.normal_title}
    </h2>
  </div>
)

export default QuartzlineTitle

QuartzlineTitle.propTypes = {
  blok: PropTypes.shape({
    strong_title: PropTypes.string,
    normal_title: PropTypes.string,
  }),
}

QuartzlineTitle.defaultProps = {
  blok: PropTypes.shape({
    strong_title: "",
    normal_title: "",
  }),
}
