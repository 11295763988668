/* eslint-disable */
import React, { useState, useEffect } from "react"
import loadable from "@loadable/component"
import "./style.scss"
import useScrollBlock from "../../hooks/useScrollBlock"

const ModalVideo = loadable(() => import("react-modal-video"))

const BelowQuartzlineCollection = props => {
  const { product, blok } = props

  const [oneWhole, setOneWhole] = useState(null)
  const [oneWholeTwo, setOneWholeTwo] = useState(null)
  const [oneWholeSpecs, setOneWholeSpecs] = useState(null)
  const [oneWholeTips, setOneWholeTips] = useState(null)
  const [oneWholeWhenToUse, setOneWholeWhenToUse] = useState(null)

  useEffect(() => {
    product.metafields.forEach(metafield => {
      if (metafield.key === "one-whole") {
        setOneWhole(metafield.value)
      } else if (metafield.key === "one-whole-two") {
        setOneWholeTwo(metafield.value)
      } else if (metafield.key === "one-whole-specs") {
        setOneWholeSpecs(metafield.value)
      } else if (metafield.key === "one-whole-tips") {
        setOneWholeTips(metafield.value)
      } else if (metafield.key === "one-whole-when-to-use") {
        setOneWholeWhenToUse(metafield.value)
      }
    })
  })
  const [showVideo, setShowVideo] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    if (showVideo) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [showVideo])

  return (
    <>
      <div className="product-overview">
        <div className="large--one-whole">{oneWhole}</div>
      </div>
      {/* <!--product-overview end--> */}
      <div className="additional-overview">
        <div className="large--one-whole">{oneWholeTwo}</div>
      </div>
      {/* <!--additional overview end--> */}
      <div className="tech-specs">
        <div className="large--one-whole clearfix">
          {oneWholeSpecs ? (
            <>
              <h3>Tech Specs</h3>
              {oneWholeSpecs}
            </>
          ) : null}
        </div>
      </div>
      {/* <!--tech-specs--> */}
      <div className="tips-field">
        <div className="large--one-whole clearfix">
          {oneWholeTips ? (
            <>
              <h3>Tips</h3>
              {oneWholeTips}
            </>
          ) : null}
        </div>
      </div>
      {/* <!--tips--> */}
      <div className="how-to-use">
        <div className="large--one-whole clearfix">
          {oneWholeWhenToUse ? (
            <>
              <h3>When To Use</h3>
              {oneWholeWhenToUse}
            </>
          ) : null}
        </div>
      </div>

      <div className="quartzline-features">
        <img
          className="vnd-logo"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/QuartzLine-Logomark.png?v=1647295706"
        />
        <div className="video-container">
          <a
            href="#data"
            id="inline"
            className="play-btn"
            onClick={e => {
              e.preventDefault()
              setShowVideo(true)
            }}
          />

          {/* <!-- Modal --> */}
          <ModalVideo
            channel="youtube"
            videoId="6xQCO6XGvA0"
            isOpen={showVideo}
            onClose={() => setShowVideo(false)}
          />
          {/* <a href="#" className='play-btn'></a> */}
          {/* <iframe id="video" width="420" height="315" src="//www.youtube.com/embed/6xQCO6XGvA0?rel=0" frameborder="0"
            allowfullscreen></iframe> */}
        </div>
        <div className="feature-item right-vnd">
          <h3>
            <span className="highlight">Which filter is right for</span> You?
          </h3>
          <div className="compare-vnd">
            <div className="item-vnd">
              <p className="stop">ND</p>
              <p></p>
              <img
                className="vnd-render"
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/ql-ND1000-Render.png?v=1647295749"
              />
              <ul>
                <li>Control your exposure in most lighting conditions</li>
                <li>Powerful light control with perfect color neutrality</li>
              </ul>
            </div>
            <div className="item-vnd">
              <p className="stop">ND/PL</p>
              <p></p>
              <img
                className="vnd-render"
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/ql-ND8_PL-Render.png?v=1647295802"
              />
              <ul>
                <li>Working in environments with glare/reflections/haze</li>
                <li>
                  Reduce shutter speed and improve color contrast + saturation
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="testimonial-slide">
          <div
            className="testimonial lazyload-bg lazyload-ani"
            style={{
              backgroundImage:
                'url("https://cdn.shopify.com/s/files/1/1050/9944/files/quote-bg-ql.jpg?v=1647295844")',
            }}
          >
            <h4>These Filters Changed Everything For Me</h4>
            <p className="quote">{blok.quote}</p>
            <p className="quote-by">- {blok.quote_by}</p>
          </div>
          <div
            className="testimonial-img lazyload-bg lazyload-ani"
            style={{
              backgroundImage:
                'url("https://cdn.shopify.com/s/files/1/1050/9944/files/garret-king-quote-img-ql.jpg?v=1647295865")',
            }}
          ></div>
        </div>
        <div className="feature-item fused-quartz-vnd">
          <h3>
            <span className="highlight">Fused Quartz</span> Glass
          </h3>
          <div
            className="banner-img lazyload-bg lazyload-ani"
            style={{
              backgroundImage:
                'url("https://cdn.shopify.com/s/files/1/1050/9944/files/ql-glass-banner.jpg?v=1647295904")',
            }}
          >
            <div className="wrapper-text">
              <div className="ribbon"></div>
              <p className="feature right-align">
                Featuring an ultra-low refractive index, resolving up to 400mp
                sensors.
              </p>
            </div>
          </div>
        </div>
        <div className="feature-item color-neutrality">
          <h3>
            <span className="highlight">Perfect</span> Color Neutrality
          </h3>
          <div
            className="banner-img lazyload-bg lazyload-ani"
            style={{
              backgroundImage:
                'url("https://cdn.shopify.com/s/files/1/1050/9944/files/ql-powerfull-light-control.jpg?v=1647295944")',
            }}
          >
            <div className="wrapper-text">
              <div className="ribbon"></div>
              <p className="feature">
                New coating processes produce zero color shift.
              </p>
            </div>
          </div>
        </div>
        <div className="feature-item preset-stop-vnd comparison">
          <h3>
            <span className="highlight">In The</span> Field
          </h3>
          <div className="wrapper-b-a-slider">
            <widgetic-embed
              id="5d9e54edecb2a18f2c8b4567"
              resize="fill-width"
              width="3000"
              height="919"
              autoscale="on"
              adaptive="414"
            ></widgetic-embed>
            <br />
            <widgetic-embed
              id="5d9e5571ecb2a1b22c8b4569"
              resize="fill-width"
              width="3000"
              height="919"
              autoscale="on"
              adaptive="414"
            ></widgetic-embed>
          </div>
        </div>

        {/* <!-- chart comparison start--> */}
        <div className="feature-item">
          <h3>
            <span className="highlight">Tech</span> Specs
          </h3>
          <div className="chart-comparison ql-table-spec wrapper">
            <table style={{ width: "100%" }}>
              <tr>
                <th>Available Thread</th>
                <th>Frame Material</th>
                <th>Glass Material</th>
                <th>Diameter ND & UV</th>
                <th>Diameter ND / PL</th>
                <th>Depth ND & UV</th>
                <th>Depth ND / PL</th>
                <th>Weight ND & UV</th>
                <th>Weight PL & ND</th>
              </tr>

              <tr>
                <td>67mm</td>
                <td>Aluminium</td>
                <td>Fused Quartz</td>
                <td>70mm</td>
                <td>70.5mm</td>
                <td>6.5mm</td>
                <td>7.5mm</td>
                <td>29g</td>
                <td>32.9g</td>
              </tr>

              <tr>
                <td>77mm</td>
                <td>Aluminium</td>
                <td>Fused Quartz</td>
                <td>80mm</td>
                <td>80.5mm</td>
                <td>6.5mm</td>
                <td>7.5mm</td>
                <td>37g</td>
                <td>38.8g</td>
              </tr>

              <tr>
                <td>82mm</td>
                <td>Aluminum</td>
                <td>Fused Quartz</td>
                <td>85mm</td>
                <td>85.5mm</td>
                <td>6.5mm</td>
                <td>7.5mm</td>
                <td>37.5g</td>
                <td>43.5g</td>
              </tr>
            </table>
          </div>
          {/* <!-- chart comparison end--> */}
          <br />
          <div className="chart-comparison ql-table-spec wrapper">
            <table style={{ width: "100%" }}>
              <tr>
                <th>Filter Type</th>
                <th>Stop Reduction</th>
                <th>Optical Density</th>
                <th>Transmission %</th>
              </tr>

              <tr>
                <td>UV</td>
                <td>0</td>
                <td>0.0</td>
                <td>99.9%</td>
              </tr>
              <tr>
                <td>CP</td>
                <td>1.6</td>
                <td>0.4</td>
                <td>33%</td>
              </tr>
              <tr>
                <td>ND8</td>
                <td>3</td>
                <td>0.9</td>
                <td>12.5%</td>
              </tr>
              <tr>
                <td>ND8/PL</td>
                <td>3</td>
                <td>0.9</td>
                <td>12.5%</td>
              </tr>
              <tr>
                <td>ND16</td>
                <td>4</td>
                <td>1.2</td>
                <td>6.25%</td>
              </tr>
              <tr>
                <td>ND16/PL</td>
                <td>4</td>
                <td>1.2</td>
                <td>6.25%</td>
              </tr>
              {/* <!-- <tr>
                <td>ND32</td>
                <td>ND32</td>
                <td>1.5</td>
                <td>1.5</td>
              </tr> --> */}
              <tr>
                <td>ND64</td>
                <td>6</td>
                <td>1.8</td>
                <td>1.5%</td>
              </tr>
              <tr>
                <td>ND64/PL</td>
                <td>6</td>
                <td>1.8</td>
                <td>1.5%</td>
              </tr>
              <tr>
                <td>ND1000</td>
                <td>10</td>
                <td>3.0</td>
                <td>0.1%</td>
              </tr>
              <tr>
                <td>ND1000/PL</td>
                <td>10</td>
                <td>3.0</td>
                <td>0.1%</td>
              </tr>
              <tr>
                <td>ND100K</td>
                <td>15</td>
                <td>4.5</td>
                <td>0.002%</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default BelowQuartzlineCollection
