/* eslint-disable */
import React, {
  // useContext,
  useState,
  useEffect,
} from "react"
import "./lc14-visualizer.scss"

// <av-product id="my-product" namespace="polarpro-assets" name="pp-product-configurator">
// <av-binding src="https://builds.dopple.io/atlatl-visual-component/releases/current/bindings/shopify/index.js"/>

const Lc14Visualizer = () => {
  // Builder Rules
  // 1. Cinema Grip can only be added when the LCP system = Cinema
  // 2. For Cinema LCP system, bluetooth button can only be added when a Grip has been selected

  // Options are 'cinema' and 'minimalist'
  const [lcpSystem, setLcpSystem] = useState("cinema")

  // Options are 'side', 'dual', 'overslung', and 'none'
  const [gripType, setGripType] = useState("none")

  const [btShutterButton, setBtShutterButton] = useState(false)

  const [vndFilter, setVndFilter] = useState(false)

  // These functions will handle updating the current selection, and can do anything else you want when a new selection is made too
  function changeCase(value) {
    console.log("Case system changed")
    setLcpSystem(value)
  }
  function changeGrip(value) {
    console.log("Grip setup changed")
    setGripType(value)
  }
  function changeBtShutterButton(value) {
    console.log("Shutter button changed")
    setBtShutterButton(value)
  }
  function changeVndFilter(value) {
    console.log("Filter changed")
    setVndFilter(value)
  }

  // tracking all states here
  useEffect(() => {
    console.log("lcpSystem", lcpSystem)
    console.log("gripType", gripType)
    console.log("btShutterButton", btShutterButton)
    console.log("vndFilter", vndFilter)
  }, [lcpSystem, gripType, btShutterButton, vndFilter])

  const uiSections = [
    {
      heading: "Select Case System",
      subheading: null,
      property: "lcpSystem",
      mobile: "mobile-show",
      changeHandler: value => changeCase(value),
      type: "radio",
      options: [
        {
          name: "Cage System",
          mobileName: "Cage",
          value: "cinema",
          thumbnail:
            "https://cdn.shopify.com/s/files/1/1050/9944/files/LCP14-Cage-Sleeve.png?v=1667254224",
          isDefault: true,
        },
        {
          name: "Minimalist Case System",
          mobileName: "Case",
          value: "minimalist",
          thumbnail:
            "https://cdn.shopify.com/s/files/1/1050/9944/files/LCP14-Minimalist.png?v=1667254691",
        },
      ],
    },
    {
      heading: "Handle Orientations",
      subheading: "(Compatible with the Cage System only)",
      property: "gripType",
      mobile: "mobile-hide",
      changeHandler: value => changeGrip(value),
      type: "radio",
      lockedCondition: lcpSystem !== "cinema", // This will "lock" and disable this section's options from being selected if this condition is true
      options: [
        {
          name: "None",
          mobileName: "",
          value: "none",
          thumbnail:
            "https://cdn.shopify.com/s/files/1/1050/9944/files/None-Tab-Image.png?v=1667343818",
          isDefault: true,
        },
        {
          name: "Side",
          mobileName: "",
          value: "side",
          thumbnail:
            "https://cdn.shopify.com/s/files/1/1050/9944/files/LCP14-Cinema-Side.png?v=1667255050",
        },
        {
          name: "Overslung",
          mobileName: "",
          value: "overslung",
          thumbnail:
            "https://cdn.shopify.com/s/files/1/1050/9944/files/LCP14-Cinema-Overslung.png?v=1667255089",
        },
        {
          name: "Dual",
          mobileName: "",
          value: "dual",
          thumbnail:
            "https://cdn.shopify.com/s/files/1/1050/9944/files/LCP14-Cinema-Dual.png?v=1667255125",
        },
      ],
    },
    {
      heading: "Attachments",
      subheading: null,
      property: "btShutterButton",
      mobile: "mobile-hide",
      changeHandler: value => changeBtShutterButton(value),
      type: "checkbox",
      lockedCondition: lcpSystem === "cinema" && gripType === "none",
      options: [
        {
          name: "Bluetooth Shutter Button",
          mobileName: "",
          thumbnail:
            "https://cdn.shopify.com/s/files/1/1050/9944/files/Bluetooth_Shutter_Button.png?v=1667255173",
          isDefault: false, // Since this is a single option and therefore a checkbox, this will set whether or not it's checked by default
        },
      ],
    },
    {
      heading: "Filter",
      subheading: null,
      property: "vndFilter",
      mobile: "mobile-hide",
      changeHandler: value => changeVndFilter(value),
      type: "checkbox",
      lockedCondition: null,
      options: [
        {
          name: "VND Filter",
          mobileName: "",
          thumbnail:
            "https://cdn.shopify.com/s/files/1/1050/9944/files/VND-Filter.png?v=1667255184",
          isDefault: false, // Since this is a single option and therefore a checkbox, this will set whether or not it's checked by default
        },
      ],
    },
  ]

  return (
    <div className="md:flex w-10/12 m-auto md:max-h-[1000px] mainDoppleWrapper">
      <atlatl-visual
        id="visual-component"
        client-id="4b823bf2-0204-466c-a1ba-6a79428b1cfa"
        loading-screen-type="spinningCube"
      >
        <av-product namespace="polarpro-assets" name="pp-lcp14-builder">
          <av-binding src="https://builds.dopple.io/atlatl-visual-component/releases/current/bindings/dom/index.js"></av-binding>
        </av-product>
        <av-native-ui>
          <av-ar native-button></av-ar>
        </av-native-ui>
      </atlatl-visual>

      <div
        className="dopple-selectors-wrapper text-white bg-visualizer-platter"
        id="dopple-configurator"
      >
        {/* Main heading */}
        <p className="bg-charcoal text-white py-2 px-7 font-black text-lg mb-0 dopple-main-heading">
          Configurations
        </p>
        <div style={{}}>
          {/* Create each section (i.e. each group of radios/checkboxes) with heading above */}
          {uiSections.map((section, sectionIndex) => (
            <div key={sectionIndex} className={`${section.mobile}`}>
              {/* Section heading */}
              <p className="bg-visualizer-grey py-2 text-[13px] text-white mb-0 px-7 font-condensed font-bold mobile-hide">
                {section.heading}
              </p>

              {/* Container for the radio/checkbox options */}
              <div
                className={`visualizer-palette-section p-5`}
                data-av-property={
                  section.type === "radio" ? section.property : null
                }
              >
                {/* Create each radio/checkbox option */}
                {section.options.map((option, optionIndex) => (
                  // This label will serve as the "button" that the user selects but do not style it with CSS
                  <label key={optionIndex}>
                    {/*
                      This input should be visually hidden with the following CSS to keep it accessible:

                      border: 0 !important;
                      clip: rect(0, 0, 0, 0) !important;
                      height: 1px !important;
                      margin: -1px !important;
                      overflow: hidden !important;
                      padding: 0 !important;
                      position: absolute !important;
                      white-space: nowrap !important;
                      width: 1px !important;
                    */}
                    <input
                      type={section.type}
                      name={section.property}
                      data-av-property={
                        section.type === "checkbox" ? section.property : null
                      }
                      data-av-value={
                        section.type === "radio" ? option.value : null
                      }
                      defaultChecked={option.isDefault}
                      onChange={() => section.changeHandler(option.value)}
                      disabled={section.lockedCondition} // Input will be disabled if this section's "locked condition" is true
                    />

                    {/*
                      This span serves as the visible button, and its active/highlighted state can be style like this:

                      input:checked + .visualizer-label {
                        background-color: #F00;
                        color: #FFF;
                      }
                    */}
                    <span className="btn btn-default visualizer-label">
                      {/* This thumbnail image for the option can be positioned however you like */}
                      <img
                        src={option.thumbnail}
                        alt={"Thumbnail for " + option.name}
                        className="selector-icon"
                      />
                      <span className="mobile-hide">{option.name}</span>
                      <span className="mobile-show desktop-hide">
                        {option.mobileName}
                      </span>
                      {/* This lock icon will be displayed only if this section's "locked condition" is true */}
                      <img
                        style={{
                          display: section.lockedCondition ? "initial" : "none",
                        }}
                        src="https://cdn.shopify.com/s/files/1/1050/9944/files/Icon_Lock_2x_ffb5e6cf-d016-4c39-aa59-dc33e4d5784a.png?v=1667422474"
                        alt="Lock icon"
                        className="lock-icon"
                      />
                    </span>
                  </label>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Lc14Visualizer
