/* eslint-disable */
import React, { useState, useEffect } from "react"
// import { Link } from "gatsby";
import loadable from "@loadable/component"
import * as style from "./style.module.scss"
// import { render } from 'storyblok-rich-text-react-renderer';
import { RiCheckboxCircleFill } from "react-icons/ri"
import useScrollBlock from "../../hooks/useScrollBlock"

const ModalVideo = loadable(() => import("react-modal-video"))

const BelowPmvndCollection = () => {
  const [showVideo, setShowVideo] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    if (showVideo) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [showVideo])

  return (
    <div className={`${style.vnd2PmFeatures} overflow-hidden`}>
      <h3 className={`${style.mainTitle} display-font-logo font-bold`}>
        PMVND <span className="font-light">| Edition II</span>
      </h3>

      <div className={style.videoContainer}>
        {/* <a href="#" className={style.playBtn}></a> */}

        <a
          href="#data"
          id="inline"
          className={`${style.playBtn}`}
          onClick={e => {
            e.preventDefault()
            setShowVideo(true)
          }}
        />

        {/* <!-- Modal --> */}
        <ModalVideo
          channel="vimeo"
          videoId="462728373"
          ratio="2.3:1"
          isOpen={showVideo}
          onClose={() => setShowVideo(false)}
        />

        {/* <iframe id="video" width="100%" height="100%" style='background:#000;' src="https://player.vimeo.com/video/462728373?color=c99e1a&byline=0&portrait=0" frameborder="0" allowfullscreen></iframe> */}
      </div>

      {/* now avail in two var */}
      <div
        className={`${style.featureItem} ${style.rightVnd} ${style.availableInTwo}`}
      >
        <h3>
          <span className={`${style.highlight} display-font-logo font-bold`}>
            Now Available in Two Variations
          </span>
        </h3>
        <div className={style.compareVnd}>
          <div className={style.itemVnd}>
            <p className={`${style.variation} font-black`}>
              Signature Edition II
            </p>
            <p className={`${style.stopSub} ${style.grey}`}>
              The gold-standard of VNDs
            </p>
            <img
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/PMVND-EDII-2-5-3.png?v=12119883561644255413"
              className={style.vndRenderImg}
            />
            <p className={style.desc}>
              The adventure-proven Signature Edition gets a brand new spin.
              Reimagined with a unique haptic feedback mechanism allowing
              effortless stop-value changes without taking your eye off the
              shot.
            </p>
          </div>
          <div className={style.itemVnd}>
            <p className={`${style.variation} ${style.mist} font-black`}>
              Mist Edition II
            </p>
            <p className={`${style.stopSub} ${style.grey}`}>
              The Industry's first Diffusion + VND
            </p>
            <img
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/PMVND-MIST-2-5-3.png?v=17962796231209594730"
              className={style.vndRenderImg}
            />
            <p className={style.desc}>
              The first of its kind, a diffusion + VND in a single aluminum
              frame. Revolutionizing the ability to combine both filters in one,
              the Mist Edition II features haptic feedback and complete light
              control while adding a soft, angelic, and creative look to your
              footage.
            </p>
          </div>
        </div>
      </div>
      {/* now avail in two var end */}

      <div className={`${style.featureItem} ${style.animation}`}>
        <h3>
          <span className={`${style.highlight} display-font-logo font-bold`}>
            All-New Frame Design
          </span>
        </h3>
        <video width="100%" height="100%" autoPlay muted loop playsInline>
          <source
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Haptic-Feedback-Banner-White-Compressed.mp4?v=1600807441"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      <div
        className={`${style.featureItem} ${style.hapticFeedback} ${style.sideBySide}`}
      >
        <div className={style.twoThirdBox}>
          <img
            src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/Haptic-Feedback-Banner.jpg?v=933339975902887677"
            className={`${style.top} haptic-img`}
          />
        </div>
        <div className={style.oneThirdBox}>
          <h2 className="font-black">Haptic Feedback</h2>
          <p>
            Feel each stop adjustment effortlessly snap into position without
            taking your eye off the shot.
          </p>
        </div>
      </div>

      <div
        className={`${style.featureItem} ${style.presetStopRange} ${style.sideBySide}`}
      >
        <div className={style.oneThirdBox}>
          <div className={style.alignContainer}>
            <h2 className="font-black">Preset Stop Range</h2>
            <p>
              Eliminates cross-polarization and vignetting down to 16mm focal
              length lenses.
            </p>
          </div>
        </div>
        <div className={style.twoThirdBox}>
          <div className={style.bottom}>
            <widgetic-embed
              id="5f627ccbecb2a1740c8b4569"
              resize="fill-width"
              width="2374"
              height="924"
              autoscale="on"
              adaptive="414"
            ></widgetic-embed>
          </div>
        </div>
      </div>

      <div className={`${style.featureItem} ${style.defender360}`}>
        <div className={style.titleWithSub}>
          <h3 className="display-font-logo font-bold">
            Defender360<sup>TM</sup>
          </h3>
          <p className={style.titleSub}>
            360° of filter protection, featuring a waterproof and dustproof
            seal.
          </p>
        </div>

        <video width="100%" height="100%" autoPlay loop muted playsInline>
          <source
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Defender360-Opening-Banner-White-Compressed.mp4?v=1600807822"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className={`${style.featureItem} ${style.multiStorage}`}>
        <h3>
          <span className={`${style.highlight} display-font-logo font-bold`}>
            Multiple Storage Configurations
          </span>
        </h3>
        <div className={style.container}>
          <div id={style.bottomHalf}></div>
          <div className={style.oneHalf}>
            <img src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/On-Camera.jpg?v=962169106041514532" />
            <p className="font-extrabold">On-Camera Storage</p>
          </div>
          <div className={style.oneHalf}>
            <img src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/Off-Camera.jpg?v=15076679694029750710" />
            <p className="font-extrabold">Off-Camera Storage</p>
          </div>
        </div>
      </div>

      <div className={`${style.featureItem} ${style.vndDiffusionFilter}`}>
        <h3>
          <span className={`${style.highlight} display-font-logo font-bold`}>
            The Industry's First VND + Diffusion Filter
          </span>
        </h3>
        <widgetic-embed
          id="5f6285d5ecb2a11e128b4568"
          resize="fill-width"
          width="3000"
          height="842"
          autoscale="on"
          adaptive="414"
        ></widgetic-embed>
        <widgetic
          id="5f6bcaafecb2a141128b4569"
          resize="fill-width"
          width="415"
          height="461"
          autoscale="on"
          adaptive="414"
          className="mobile-only"
        ></widgetic>
        <p>
          The <span>Mist Edition II</span> produces a dreamy, smooth, angelic
          look that provides an added layer of production value to your footage.
          Giving highlights a softer look, this filter excels at providing
          subjects with a polished appearance without sacrificing contrast and
          color accuracy. Ideal for creative shots in documentaries, short
          films, music videos, and other commercial projects.
        </p>
      </div>
      <div className={`${style.featureItem} ${style.noStacking}`}>
        <h3>
          <span className={`${style.highlight} display-font-logo font-bold`}>
            Two Effects, One filter, No stacking
          </span>
        </h3>
        <img src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/Stacked-vs-MIST-Desktop.jpg?v=795413922885315759" />
        <p>
          An industry first; you can now shoot diffusion and VND without
          stacking filters, cross-polarization, or vignetting.
        </p>
      </div>

      {/* which right for you section */}
      <div
        className={`${style.featureItem} ${style.rightVnd} ${style.whichIsRight}`}
      >
        <h3>
          <span className={`${style.highlight} display-font-logo font-bold`}>
            Which is right for You?
          </span>
        </h3>
        <div className={style.compareVnd}>
          <div className={style.itemVnd}>
            <p className={`${style.stop} font-bold`}>
              Signature Edition |<span className="font-light"> 2-5 Stop</span>
            </p>
            <img
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/PMVND-EDII-2-5-2.png?v=8892795556105192881"
              className={style.vndRenderImg}
            />
            <ul className="font-bold">
              <li>Control your shutter speed in most lighting conditions</li>
              <li>Ideal for run-n-gun videography/filmmaking</li>
            </ul>
          </div>
          <div className={style.itemVnd}>
            <p className={`${style.stop} ${style.mist} font-bold`}>
              Mist Edition |<span className="font-light"> 2-5 Stop</span>
            </p>
            <img
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/PMVND-MIST-2-5-2.png?v=8408898288362679624"
              className={style.vndRenderImg}
            />
            <ul className="font-bold">
              <li>Shutter speed control in most lighting conditions</li>
              <li>Softens digital sharpness for a smooth, ethereal look</li>
              <li>
                Increase light halation with 1/8<sup>th</sup> diffusion strength
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={`${style.featureItem} ${style.rightVnd}`}>
        <div className={`${style.compareVnd} ${style.secondStack}`}>
          <div className={style.itemVnd}>
            <p className={`${style.stop} font-bold`}>
              Signature Edition |<span className="font-light"> 6-9 Stop</span>
            </p>
            <img
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/PMVND-EDII-6-9-2.png?v=7650173435490130258"
              className={style.vndRenderImg}
            />
            <ul className="font-bold">
              <li>
                Working in bright conditions with wide apertures (f1.4 - f2.8)
              </li>
              <li>Recording in LOG formats with an ISO exceeding 400</li>
            </ul>
          </div>
          <div className={style.itemVnd}>
            <p className={`${style.stop} ${style.mist} font-bold`}>
              Mist Edition |<span className="font-light"> 6-9 Stop</span>
            </p>
            <img
              src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/PMVND-MIST-6-9-2.png?v=10993547598046893170"
              className={style.vndRenderImg}
            />
            <ul className="font-bold">
              <li>For filming in bright conditions with wide-open apertures</li>
              <li>Softens digital sharpness for a smooth, ethereal look</li>
              <li>
                Increase light halation with 1/8<sup>th</sup> diffusion strength
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* which right for you section end */}

      {/* techspec start */}
      {/* chart comparison start */}
      <div className={style.featureItem}>
        <h3>
          <span className={style.darkHighlight}>Tech Specs</span>
        </h3>
        <div className={style.chartComparison}>
          <div className={style.version}>
            <table>
              <tr>
                <th></th>
                <th>2-5 Stop</th>
                <th>6-9 Stop</th>
              </tr>
              <tr>
                <td className={`${`${style.leftTh} font-bold`} font-bold`}>
                  Glass Type:
                </td>
                <td>CinemaSeries™</td>
                <td>CinemaSeries™</td>
              </tr>
              <tr>
                <td className={`${style.leftTh} font-bold`}>
                  Filter Factor Range:
                </td>
                <td>ND4 to ND32</td>
                <td>ND64 to ND512</td>
              </tr>
              <tr>
                <td className={`${style.leftTh} font-bold`}>
                  Optical Density Range:
                </td>
                <td>0.6 to 1.5</td>
                <td>1.8 to 2.7</td>
              </tr>
              <tr>
                <td className={`${style.leftTh} font-bold`}>
                  Available Thread Sizes:
                </td>
                <td>67mm, 77mm, 82mm</td>
                <td>67mm, 77mm, 82mm</td>
              </tr>
              <tr>
                <td className={`${style.leftTh} font-bold`}>Frame Material:</td>
                <td>Aluminum</td>
                <td>Aluminum</td>
              </tr>
              <tr>
                <td className={`${style.leftTh} font-bold`}>Diameter:</td>
                <td>
                  <p>67mm = 73.6mm</p>
                  <p>77mm = 84mm</p>
                  <p>82mm = 89mm</p>
                </td>
                <td>
                  <p>67mm = 73.6mm</p>
                  <p>77mm = 84mm</p>
                  <p>82mm = 89mm</p>
                </td>
              </tr>
              <tr>
                <td className={`${style.leftTh} font-bold`}>Depth:</td>
                <td>
                  <p>67mm = 8.40mm</p>
                  <p>77mm = 8.48mm</p>
                  <p>82mm = 8.48mm</p>
                </td>
                <td>
                  <p>67mm = 8.40mm</p>
                  <p>77mm = 8.48mm</p>
                  <p>82mm = 8.48mm</p>
                </td>
              </tr>
              <tr>
                <td className={`${style.leftTh} font-bold`}>Weight:</td>
                <td>
                  <p>67mm = 41g</p>
                  <p>77mm = 50g</p>
                  <p>82mm = 56g</p>
                </td>
                <td>
                  <p>67mm = 41g</p>
                  <p>77mm = 50g</p>
                  <p>82mm = 56g</p>
                </td>
              </tr>
            </table>
          </div>
          <div class="compare">
            {/* compare signature */}
            <table>
              <tr>
                <th>FEATURES:</th>
                <th class={style.polarYellow}>
                  SIGNATURE <span>EDITION II</span>
                </th>
                <th class={style.polarYellow}>
                  MIST <span>EDITION II</span>
                </th>
                <th class={style.polarYellow}>
                  SIGNATURE <span>EDITION I</span>
                </th>
              </tr>
              <tr>
                <td className={`${style.leftTh} font-bold`}>
                  PRESET STOP RANGE
                </td>
                <td>
                  <RiCheckboxCircleFill />
                </td>
                <td>
                  <RiCheckboxCircleFill />
                </td>
                <td>
                  <RiCheckboxCircleFill />
                </td>
              </tr>
              <tr>
                <td className={`${style.leftTh} font-bold`}>
                  ZERO VIGNETTING <span>(ABOVE 16mm FOCAL LENGTHS)</span>
                </td>
                <td>
                  <RiCheckboxCircleFill />
                </td>
                <td>
                  <RiCheckboxCircleFill />
                </td>
                <td>
                  <RiCheckboxCircleFill />
                </td>
              </tr>
              <tr>
                <td className={`${style.leftTh} font-bold`}>HAPTIC FEEDBACK</td>
                <td>
                  <RiCheckboxCircleFill />
                </td>
                <td>
                  <RiCheckboxCircleFill />
                </td>
                <td></td>
              </tr>

              <tr>
                <td className={`${style.leftTh} font-bold`}>
                  DEFENDER360&trade; CASE INCLUDED
                </td>
                <td>
                  <RiCheckboxCircleFill />
                </td>
                <td>
                  <RiCheckboxCircleFill />
                </td>
                <td></td>
              </tr>
              <tr>
                <td className={`${style.leftTh} font-bold`}>
                  LIGHT HALATION / DIFFUSION
                </td>
                <td></td>
                <td>
                  <RiCheckboxCircleFill />
                </td>
                <td></td>
              </tr>
              <tr>
                <td className={`${style.leftTh} font-bold`}>
                  ECO-FRIENDLY PACKAGING
                </td>
                <td>
                  <RiCheckboxCircleFill />
                </td>
                <td>
                  <RiCheckboxCircleFill />
                </td>
                <td></td>
              </tr>
            </table>
            {/* compare signature end */}
          </div>
        </div>
        {/* chart comparison end */}
      </div>
      {/* techspec start end */}

      <div className={style.featureItem}>
        <h3>
          <span className={`${style.highlight} display-font-logo font-bold`}>
            Reducing Our Impact
          </span>
        </h3>
        <div className={style.reducingImpact}></div>
      </div>
    </div>
  )
}

export default BelowPmvndCollection
