// extracted by mini-css-extract-plugin
export var base1Slider = "style-module--base1Slider--ea3ff";
export var base2Slider = "style-module--base2Slider--b0980";
export var baseKit = "style-module--baseKit--3dc27";
export var directorsKit = "style-module--directorsKit--6b7b6";
export var highlightContainer = "style-module--highlightContainer--3f3e9";
export var introData = "style-module--introData--52de5";
export var launchCommercialBg = "style-module--launchCommercialBg--e8e86";
export var nextConfigBtn = "style-module--nextConfigBtn--8fd6a";
export var productHero = "style-module--productHero--f86ea";
export var reconCustomDotSlider = "style-module--reconCustomDotSlider--f92e7";
export var reconList = "style-module--reconList--2eb68";
export var reconVndOverview = "style-module--reconVndOverview--d69b4";
export var sectionTitleRecon = "style-module--sectionTitleRecon--73cc5";
export var sixConfig = "style-module--sixConfig--35b4f";
export var stage2Filters = "style-module--stage2Filters--fa248";
export var videoContainer = "style-module--video-container--1a426";