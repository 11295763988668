/* eslint-disable */
import * as React from "react"
import PropTypes from "prop-types"

const AddToWishlist = props => {
  const { customerId = "", productId, variantId, quantity } = props

  const loginURL = "https://www.polarpro.com/account/login"

  const addToWishlist = e => {
    e.preventDefault()
    console.log(
      "Following data were passed in: \nProduct ID:",
      productId,
      "\nVariant ID:",
      variantId,
      "\nCustomer ID:",
      customerId,
      "\nQuantity:",
      quantity,
    )

    if (customerId === "") {
      window.open(loginURL)
    }
  }

  let buttonStyle =
    "text-11 tracking-widest min-w-200px w-full bg-grey hover:bg-obsidian text-white p-2 font-bold font-expanded uppercase"

  if (customerId === "") {
    buttonStyle = `${buttonStyle} hover:opacity-40 opacity-60`
  } else {
    buttonStyle = `${buttonStyle} hover:bg-paloverde hover:border-paloverde hover:text-white`
  }

  return (
    <button
      type="button"
      className={`hidden ${buttonStyle}`}
      onClick={addToWishlist}
    >
      ADD TO WISHLIST
    </button>
  )
}

export default AddToWishlist

// Validate prop types
AddToWishlist.propTypes = {
  customerId: PropTypes.string,
  productId: PropTypes.string.isRequired,
  variantId: PropTypes.string.isRequired,
  quantity: PropTypes.string,
}

AddToWishlist.defaultProps = {
  customerId: "",
  quantity: "1",
}
