/* eslint-disable */
import React, { useContext, useState, useEffect, useCallback } from "react"
import { LiteChaserContext } from "../../context/LiteChaserContext"
import { StoreContext } from "../../context/StoreContext"

const LiteChaserPartsSelector = ({ product }) => {
  const { variants } = product
  const unformattedVariantId = variants[0].shopifyId
  const variantStorefrontId = variants[0].storefrontId
  const [variantId] = useState(
    unformattedVariantId.slice(unformattedVariantId.lastIndexOf("/") + 1),
  )

  // const [productId] = useState(shopifyId.slice(shopifyId.lastIndexOf('/')));
  const [disable, setDisable] = useState(false)
  const [mainName, setMainName] = useState("")
  const [subName, setSubName] = useState("")
  const [subNameStyle, setSubNameStyle] = useState("text-xxs")
  const [partStyle, setPartStyle] = useState("")

  const [bgColor, setBgColor] = useState("bg-paloverde")
  const [toggle, setToggle] = useState(false)

  const [promo, setPromo] = useState(false)
  const [priceValue, setPriceValue] = useState("0.00")

  const [available, setAvailable] = useState(true)

  const {
    selectedKitHash,
    selectedPhoneModel,
    kitProductsByHash,
    kitIncludesSet,
    lineItemsSet,
    totalPrice,
    setTotalPrice,
    setKitIncludesSet,
    updateLineItemsSet,
  } = useContext(LiteChaserContext)

  const { client } = useContext(StoreContext)

  const checkAvailablity = useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        // const result = fetchedProduct ? fetchedProduct.variants.filter(
        //   (variantProduct) => variantProduct.id === productId,
        // ) : [];

        // if (result.length > 0) {
        //   setAvailable(result[0].available);
        //   setDisable(!result[0].available);
        // }
        setAvailable(fetchedProduct.availableForSale)
        setDisable(!fetchedProduct.availableForSale)
      })
    },
    [variantStorefrontId, client.product],
  )

  useEffect(() => {
    checkAvailablity(product.storefrontId, variantStorefrontId)
  }, [variantStorefrontId, checkAvailablity, product.storefrontId])

  useEffect(() => {
    const tempMain = product?.customMetafield?.selector_name?.main
    const tempSub = product?.customMetafield?.selector_name?.sub

    setMainName(tempMain)
    setSubName(tempSub)
  })

  useEffect(() => {
    if (product.customMetafield?.model === undefined) {
      setPartStyle("")
    } else if (
      selectedPhoneModel &&
      selectedPhoneModel.toLowerCase() !==
        product.customMetafield?.model?.toLowerCase()
    ) {
      let tempLineItemsSet = lineItemsSet
      tempLineItemsSet.delete(variantStorefrontId)
      updateLineItemsSet(tempLineItemsSet)
      setPartStyle("hidden")
    } else {
      setPartStyle("")
    }

    // if (selectedCasesSet.has(mainName) && selectedPhoneModel === product.customMetafield.model.toLowerCase() && selectedKit.toLowerCase().includes('custom')) {
    //   let tempLineItemsSet = lineItemsSet;
    //   tempLineItemsSet.add(variantStorefrontId);
    //   updateLineItemsSet(tempLineItemsSet);
    // }
    // else {
    //   let tempLineItemsSet = lineItemsSet;
    //   tempLineItemsSet.delete(variantStorefrontId);
    //   updateLineItemsSet(tempLineItemsSet);
    // }
  }, [selectedPhoneModel])

  useEffect(() => {
    if (subName.length > 10) {
      setSubNameStyle("text-xxxs")
    }
  })

  useEffect(() => {
    if (selectedKitHash) {
      if (
        kitProductsByHash[selectedKitHash] &&
        kitProductsByHash[selectedKitHash].kit_items_set &&
        kitProductsByHash[selectedKitHash]["kit_items_set"].has(variantId)
      ) {
        setToggle(true)
        setDisable(true)
      } else {
        if (available) {
          setToggle(false)
          setDisable(false)
        }
      }

      if (
        kitProductsByHash[selectedKitHash] &&
        kitProductsByHash[selectedKitHash].promo_items_set &&
        kitProductsByHash[selectedKitHash]["promo_items_set"].has(variantId)
      ) {
        setPromo(true)
      } else {
        setPromo(false)
      }
    }
  }, [selectedKitHash])

  const onSelect = e => {
    e.preventDefault()
    let newLineItemsSet = lineItemsSet
    let tempTotalPrice = totalPrice
    const tempKitIncludesSet = new Set(kitIncludesSet)
    const itemName = product.title

    if (!toggle) {
      newLineItemsSet.add(variantStorefrontId)
      tempKitIncludesSet.add(itemName.slice(0, itemName.lastIndexOf("|") - 1))
      tempTotalPrice += Number.parseInt(
        Number.parseFloat(product.variants[0].price) * 100,
      )
      setTotalPrice(tempTotalPrice)
    } else {
      newLineItemsSet.delete(variantStorefrontId)
      tempKitIncludesSet.delete(
        itemName.slice(0, itemName.lastIndexOf("|") - 1),
      )
      tempTotalPrice -= Number.parseInt(
        Number.parseFloat(product.variants[0].price) * 100,
      )
      setTotalPrice(tempTotalPrice)
    }

    setKitIncludesSet(tempKitIncludesSet)
    updateLineItemsSet(newLineItemsSet)
    setToggle(!toggle)
  }

  useEffect(() => {
    if (available && !promo) {
      setPriceValue("$" + product.priceRangeV2.maxVariantPrice.amount)
    } else if (available && promo) {
      setPriceValue("FREE")
    } else {
      setPriceValue("OUT OF STOCK")
    }
  }, [available])

  return (
    <button
      className={`w-[100px] h-[100px] p-2 m-2 ${
        toggle
          ? "bg-paloverde text-white border-paloverde border-[1px]"
          : "border-obsidian border-[1px]"
      } ${partStyle} ${available ? "opacity-100" : "opacity-50"} lc-partBox`}
      disabled={disable}
      onClick={onSelect}
    >
      <span className="font-bold text-xs font-condensed firstProductTitle">
        {mainName}
      </span>
      <br />
      <span className={`font-bold ${subNameStyle}`}>{subName}</span>
      {subName ? <br /> : null}
      <span className="font-bold text-xs font-condensed secondProductTitle">
        {priceValue}
      </span>
    </button>
  )
}

export default LiteChaserPartsSelector
