import React, { useState, useEffect } from "react"
// eslint-disable-next-line import/no-unresolved
import { useLocation } from "@reach/router"
import axois from "axios"
import ProductCardSearch from "../components/ProductCardSearch"
import { getValuesFromQuery } from "../utils/searchHelper"
import SearchInfo from "../PredectiveSearch"

const SearchPageTemplate = () => {
  const indexName = "Pages"
  const [loading, setLoading] = useState(true)
  const [allProducts, setAllProducts] = useState([])
  const [searchResults, setSearchResults] = useState([])
  const [error, setError] = useState(null)
  const location = useLocation()

  const searchQuery = getValuesFromQuery(location.search).term

  const allProductsSearch = async () => {
    setLoading(true)
    try {
      const response = await axois.post(
        "/.netlify/functions/allProductsSearch",
        { q: searchQuery },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )

      if (response.status !== 200) {
        setAllProducts([])
        setLoading(false)
        return
      }

      const products = response?.data?.response?.products?.edges || []
      setAllProducts(products)
      setLoading(false)
    } catch (err) {
      setError(err)
      setLoading(false)
      console.error("Error fetching all products:", err)
    }
  }
  useEffect(() => {
    allProductsSearch()
  }, [location.href])

  return (
    <div className="px-5 md:px-20 bg-[#f6f6f6] pb-10 md:pb-20">
      <h1 className="pt-4 mb-0 text-sm font-semibold">
        Home <span className="font-light text-sm">/ Search</span>
      </h1>
      <h1 className="text-xl font-bold">Search Results</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {allProducts.length > 0 ? (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
              {allProducts.map((item, index) => (
                <SearchInfo
                  title={item?.node?.title}
                  url={item.node?.handle}
                  image={item?.node?.featuredImage?.url}
                  description={item?.node?.descriptionHtml}
                  price={item?.node?.priceRange?.minVariantPrice?.amount}
                  compareAt={
                    item?.node?.compareAtPriceRange?.minVariantPrice?.amount
                  }
                />
              ))}
            </div>
          ) : (
            <div>
              <p className="font-sans font-black text-xl mb-4">
                No Results found
              </p>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default SearchPageTemplate
