/* eslint-disable */
import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { render } from "storyblok-rich-text-react-renderer"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import { useStoryblok } from "../../utils/storyblok"
import DynamicComponents from "../../components/DynamicComponents"
import "./support.scss"
import Seo from "../../components/seo"

const Shipping = ({ pageContext }) => {
  const { storyblokEntry, product } = pageContext

  const story = storyblokEntry
    ? useStoryblok(storyblokEntry.node)
    : { content: null }

  console.log("pageContext", pageContext)

  const shipping_tables = (
    <div className="shipping-chart">
      <h3 className="odd">
        <span className="title-wrapper">United States</span>
      </h3>
      <div className="section odd united-states">
        <table className="table-wrapper full table--responsive">
          <thead>
            <tr>
              <th></th>
              <th>Fedex Ground 4-5 days</th>
              <th>USPS First Class 5-6 days</th>
              <th>USPS Priority 2-3 days</th>
              <th>FedEx Std Overnight* 2-3 days</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-label="">$0-$129.99</td>
              <td data-label="Fedex Ground 4-5 days">Free</td>
              <td data-label="USPS First Class 5-6 days">Free</td>
              <td data-label="USPS Priority 2-3 days">$8.00</td>
              <td data-label="FedEx Std Overnight* 2-3 days">$25.00</td>
            </tr>
            <tr>
              <td data-label="">$130-250</td>
              <td data-label="Fedex Ground 4-5 days">Free</td>
              <td data-label="USPS First Class 5-6 days">Free</td>
              <td data-label="USPS Priority 2-3 days">$15.00</td>
              <td data-label="FedEx Std Overnight* 2-3 days">$25.00</td>
            </tr>
            <tr>
              <td data-label="">$250.01-$550</td>
              <td data-label="Fedex Ground 4-5 days">Free</td>
              <td data-label="USPS First Class 5-6 days">Free</td>
              <td data-label="USPS Priority 2-3 days">$15.00</td>
              <td data-label="FedEx Std Overnight* 2-3 days">$25.00</td>
            </tr>
            <tr>
              <td>$550(+)</td>
              <td data-label="Fedex Ground 4-5 days">Free</td>
              <td data-label="USPS First Class 5-6 days">Free</td>
              <td data-label="USPS Priority 2-3 days">$20.00</td>
              <td data-label="FedEx Std Overnight* 2-3 days">$40.00</td>
            </tr>
          </tbody>
        </table>
        <p className="asterisk">
          *Please note FedEx Overnight shipments sent on Friday may arrive
          Monday.
        </p>
      </div>
      <h3 className="even">
        <span className="title-wrapper">Australia</span>
      </h3>
      <div className="section even">
        <table className="table-wrapper full table--responsive">
          <thead>
            <tr>
              <th></th>
              <th>DHL Express 3-8 Days</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>$0-$129.99</td>
              <td data-label="DHL Express 3-8 Days">$25.00</td>
            </tr>
            <tr>
              <td>$130-250</td>
              <td data-label="DHL Express 3-8 Days">$25.00</td>
            </tr>
            <tr>
              <td>$250.01-$550</td>
              <td data-label="DHL Express 3-8 Days">$25.00</td>
            </tr>
            <tr>
              <td>$550(+)</td>
              <td data-label="DHL Express 3-8 Days">$25.00</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h3 className="odd">
        <span className="title-wrapper">Canada</span>
      </h3>
      <div className="section odd">
        <table className="table-wrapper full table--responsive">
          <thead>
            <tr>
              <th></th>
              <th>DHL Express 2-4 Days</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>$0-$129.99</td>
              <td data-label="DHL Express 2-4 Days">$25.00</td>
            </tr>
            <tr>
              <td>$130-250</td>
              <td data-label="DHL Express 2-4 Days">$25.00</td>
            </tr>
            <tr>
              <td>$250.01-$550</td>
              <td data-label="DHL Express 2-4 Days">$25.00</td>
            </tr>
            <tr>
              <td>$550(+)</td>
              <td data-label="DHL Express 2-4 Days">$25.00</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h3 className="even">
        <span className="title-wrapper">UK | France | Germany</span>
      </h3>
      <div className="section even">
        <table className="table-wrapper full table--responsive">
          <thead>
            <tr>
              <th></th>
              <th>DHL Express 3-8 Days</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>$0-$129.99</td>
              <td data-label="DHL Express 3-8 Days">$25.00</td>
            </tr>
            <tr>
              <td>$130-250</td>
              <td data-label="DHL Express 3-8 Days">$25.00</td>
            </tr>
            <tr>
              <td>$250.01-$550</td>
              <td data-label="DHL Express 3-8 Days">$25.00</td>
            </tr>
            <tr>
              <td>$550(+)</td>
              <td data-label="DHL Express 3-8 Days">$25.00</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h3 className="odd">
        <span className="title-wrapper">Japan | Hong Kong | Singapore</span>
      </h3>
      <div className="section odd">
        <table className="table-wrapper full table--responsive">
          <thead>
            <tr>
              <th></th>
              <th>DHL Express 2-4 Days</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>$0-$129.99</td>
              <td data-label="DHL Express 2-4 Days">$25.00</td>
            </tr>
            <tr>
              <td>$130-250</td>
              <td data-label="DHL Express 2-4 Days">$25.00</td>
            </tr>
            <tr>
              <td>$250.01-$550</td>
              <td data-label="DHL Express 2-4 Days">$25.00</td>
            </tr>
            <tr>
              <td>$550(+)</td>
              <td data-label="DHL Express 2-4 Days">$25.00</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h3 className="even">
        <span className="title-wrapper">UAE</span>
      </h3>
      <div className="section even">
        <table className="table-wrapper full table--responsive">
          <thead>
            <tr>
              <th></th>
              <th>DHL Express 3-8 Days</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>$0-$129.99</td>
              <td data-label="DHL Express 3-8 Days">$25.00</td>
            </tr>
            <tr>
              <td>$130-250</td>
              <td data-label="DHL Express 3-8 Days">$25.00</td>
            </tr>
            <tr>
              <td>$250.01-$550</td>
              <td data-label="DHL Express 3-8 Days">$25.00</td>
            </tr>
            <tr>
              <td>$550(+)</td>
              <td data-label="DHL Express 3-8 Days">$25.00</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h3 className="odd">
        <span className="title-wrapper">Rest of World</span>
      </h3>
      <div className="section odd">
        <table className="table-wrapper full table--responsive">
          <thead>
            <tr>
              <th></th>
              <th>DHL Express 2-4 Days</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>$0-$129.99</td>
              <td data-label="DHL Express 2-4 Days">$25.00</td>
            </tr>
            <tr>
              <td>$130-250</td>
              <td data-label="DHL Express 2-4 Days">$25.00</td>
            </tr>
            <tr>
              <td>$250.01-$550</td>
              <td data-label="DHL Express 2-4 Days">$25.00</td>
            </tr>
            <tr>
              <td>$550(+)</td>
              <td data-label="DHL Express 2-4 Days">$25.00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )

  const int_shipping_notice = (
    <div className="notes">
      <div className="narrow-wrapper">
        <span className="title">International shipping notes:</span>
        <p>
          *International shipping times can vary based on customs processing.
          DHL rates are guaranteed to arrive within the time frame above.
          <br></br>
          <br></br>
          *PolarPro does not include import duties, taxes, and charges in the
          final cost of any order. These charges are the buyer’s responsibility.
          We recommend checking with your country’s customs office to determine
          what these additional costs will be prior to ordering. If you’d prefer
          to buy locally, please check out our{" "}
          <a href="/apps/store-locator">store locator</a>.
        </p>
      </div>
    </div>
  )

  let supportPageType = "shipping"

  return (
    <div id={supportPageType}>
      <Seo title={story.content?.title} />
      <img
        src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/support-header-Image.jpg?v=144240260665312199781582676319"
        className="wideBanner w-full mb-[0px]"
      />
      <div className="support-wrapper px-12">
        <img
          src="https://cdn.shopify.com/s/files/1/1050/9944/t/167/assets/shipping-icon.png?v=87779525415086140131582586854"
          className=""
        />
        <p className="title-text">Shipping Info:</p>
        <div className="pt-4">
          <p className="m-0">
            All shipments will ship same business day when placed before 12PM
            PST.
          </p>
          <p>(Shipments after 12PM PST will ship next business day.)</p>
          <p>
            <span className="font-bold">Transit Times</span> are averages and
            not guaranteed.
          </p>
          <p>
            <span className="font-bold">International Orders:</span> Duties and
            Taxes are not collected. You will need to pay DHL your duties and
            taxes before they deliver. Shipping Cost will not be refunded for
            orders refusing delivery due to failure to pay duties and taxes.
          </p>
          <p>
            <span className="font-bold">Reserve Items:</span> If your contains
            any reserve items or out of stock items it will not ship until all
            items in the order are available.
          </p>
        </div>
      </div>
      {/* {shipping_tables} */}
      {/* {int_shipping_notice} */}
      <div class="support-bottom-banner">
        <p>Still can't find what you're looking for?</p>

        <span>Let us Know!</span>
        <a href="/support/contact-us">Contact Us</a>
      </div>
    </div>
  )
}

export default Shipping
