/* eslint-disable radix */
// Originally from:
// https://codepen.io/luke__duncan/pen/dpZVOr/
export const calculateTotalValue = length => {
  let minutesInt = Math.floor(length / 60)
  const secondsInt = Math.floor(length - minutesInt * 60)
  const secondsStr = secondsInt.toString()
  let seconds
  let minutes
  let hours

  if (minutesInt >= 60) {
    hours = Math.floor(minutesInt / 60)
    minutesInt -= hours * 60
  }
  if (minutesInt < 10) {
    minutes = `0${minutesInt}`
  } else {
    minutes = minutesInt.toString()
  }
  if (secondsInt < 10) {
    seconds = `0${secondsStr}`
  } else {
    seconds = secondsStr
  }

  let time = `${minutes}:${seconds}`
  if (hours) {
    time = `${hours}:${time}`
  }

  return time
}

export const calculateCurrentValue = currentTime => {
  const currentHour = parseInt(currentTime / 3600) % 24
  const currentMinutes = parseInt(currentTime / 60) % 60
  const currentSecondsLong = currentTime % 60
  const currentSeconds = currentSecondsLong.toFixed()
  const currentTimeStr = `${
    (currentHour > 0 ? `${currentHour}:` : "") +
    (currentMinutes < 10 ? `0${currentMinutes}` : currentMinutes)
  }:${currentSeconds < 10 ? `0${currentSeconds}` : currentSeconds}`

  return currentTimeStr
}
