// extracted by mini-css-extract-plugin
export var backToTop = "style-module--back-to-top--c5d97";
export var borderRadius14 = "style-module--border-radius-14--fdbeb";
export var borderRadius4 = "style-module--border-radius-4--ddd06";
export var item = "style-module--item--5402d";
export var lockScroll = "style-module--lock-scroll--ccbdb";
export var mobileNewLine = "style-module--mobile-new-line--e2cab";
export var paddingBtm50 = "style-module--padding-btm-50--50a4e";
export var polarproDividend = "style-module--polarpro-dividend--3d692";
export var polarproMemberPriority = "style-module--polarpro-member-priority--d3cb2";
export var polarproReferals = "style-module--polarpro-referals--5b0ec";
export var ppSliderLayered = "style-module--pp-slider-layered--59dba";
export var profileSliderContainer = "style-module--profile-slider-container--75bb1";
export var slideContent = "style-module--slide-content--5f4af";