/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"

const AutoPlayVideoBanner = ({ blok }) => {
  const { background_image, desktop_video, mobile_video } = blok

  return (
    <div className="" style={{ backgroundImage: `url("${background_image}")` }}>
      <video
        autoPlay={true}
        muted={true}
        loop={true}
        playsInline={true}
        className="hidden md:block"
      >
        <source src={desktop_video} />
      </video>
      <video
        autoPlay={true}
        muted={true}
        loop={true}
        playsInline={true}
        className="block md:hidden"
      >
        <source src={mobile_video} />
      </video>
    </div>
  )
}

export default AutoPlayVideoBanner

AutoPlayVideoBanner.propTypes = {}

AutoPlayVideoBanner.defaultProps = {}
