import React from "react"
import PropTypes from "prop-types"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import DynamicComponents from "../DynamicComponents" // eslint-disable-line
import * as style from "./style.module.scss"

const ModularFilterInfoTileContainer = ({ blok }) => {
  const { title, body } = blok

  const widthDivider = body.length <= 5 ? body.length : 5

  return (
    <section className="my-20">
      <p className="display-font font-black text-obsidian text-center mb-2 text-[18.75px]">
        {title}
      </p>
      <hr className="text-center w-[100px] h-[2px] bg-paloverde mx-auto mb-10 mt-2" />
      <div
        className={`relative block mt-4 ${style.content} ${style.activeContent}`}
      >
        <span className="hidden xl-1600:w-[19%] xl-1600:w-[24%] xl-1600:w-[32%]" />
        {body.map(
          (
            blok,
            index, // eslint-disable-line
          ) => (
            <div
              {...sbEditable(blok)} // eslint-disable-line
              className={`relative inline-block w-full md:w-[49%] md:mr-[1%] xl-1600:w-[${
                100 / widthDivider - 1
              }%] xl-1600:mr-2`}
            >
              <DynamicComponents
                blok={blok}
                key={blok._uid} // eslint-disable-line
                lastChildIndex={body.length - 1}
                index={index}
              />
            </div>
          ),
        )}
      </div>
    </section>
  )
}

export default ModularFilterInfoTileContainer

ModularFilterInfoTileContainer.propTypes = {
  blok: PropTypes.shape({
    _uid: PropTypes.string.isRequired,
    title: PropTypes.string,
    body: PropTypes.arrayOf(
      PropTypes.shape({
        blok: PropTypes.shape({
          _uid: PropTypes.string,
        }),
      }),
    ),
  }),
}

ModularFilterInfoTileContainer.defaultProps = {
  blok: {
    title: "TITLE IS MISSING",
    body: [],
  },
}
