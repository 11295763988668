/* eslint-disable */
import React, { useEffect, useRef, useState, useMemo } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"

import Carousel from "react-multi-carousel"
import FeaturedProductImagesArrow from "../FeaturedProductImagesArrow"
import FeaturedProductImagesDot from "../FeaturedProductImagesDot"
import "react-multi-carousel/lib/styles.css"
import "./style.scss"
import responsiveData from "./settings.json"
import { generateThumbnail } from "../../utils/video"

import { BsFillPlayFill } from "react-icons/bs"

const { mainResponsive } = responsiveData

const CustomDot = ({ onClick, active }) => {
  const dotStyle = {
    width: "25px", // Adjust the width as needed
    height: "6px", // Adjust the height as needed
    borderRadius: "0",
    margin: "0 4px", // Adjust the margin as needed
    backgroundColor: active ? "#000" : "#ccc", // Adjust the active and inactive dot colors as needed
  }

  return <button onClick={onClick} style={dotStyle} className="lg:hidden " />
}

const FeaturedProductImagesCarousel = props => {
  const { images, title, imageIndex, pageContext, runVariantFilter } = props

  const subResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 2500 },
      items: images?.length > 5 ? 5 : images?.length || 1,
    },
    desktop: {
      breakpoint: { max: 2500, min: 1600 },
      items: images?.length > 5 ? 5 : images?.length || 1,
    },
    tablet: {
      breakpoint: { max: 1600, min: 640 },
      items: images?.length > 5 ? 5 : images?.length || 1,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: images?.length > 3 ? 3 : images?.length || 1,
    },
  }

  const hasImages = images?.length > 0
  const hasMultipleImages = true || images?.length > 1

  const mainCarousel = useRef(null)
  const [renderLegacyDynamicTemplate, setRenderLegacyDynamicTemplate] =
    useState(true)
  const [productBottomHtml, setProductBottomHtml] = useState("")
  const [carouselImage, setCarouselImages] = useState([...images])

  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const [labelIndex, setLabelIndex] = useState(5)
  const [thumbnailUrl, setThumbnailUrl] = useState("")

  const subCarousel = useRef(null)

  const moveMainSlide = index => {
    mainCarousel.current.goToSlide(index + 2, true)
  }

  useEffect(() => {
    if (runVariantFilter) {
      mainCarousel.current.goToSlide(imageIndex + 2, true)
      setActiveItemIndex(imageIndex)
      // subCarousel.current?.goToSlide(imageIndex, true)
    }
  }, [imageIndex, runVariantFilter])

  // useEffect(() => {
  // Iterate thru metafields to grab data
  /* eslint-disable-next-line no-restricted-syntax */
  //   for (const metaData of metafields) {
  //     if (metaData.key === "one-whole") {
  //       setProductBottomHtml(metaData.value)
  //       break
  //     }
  //   }
  // })
  useEffect(() => {
    carouselImage?.map(async (img, index) => {
      if (img?.embeddedUrl) {
        const updatedArr = await generateThumbnail(
          carouselImage,
          img?.embeddedUrl,
          index,
          setCarouselImages,
        )

        setCarouselImages(updatedArr)
      }
    })
  }, [])

  useEffect(() => {
    if (productBottomHtml) {
      if (story.content && story.content.hideDynamicTemplateContent) {
        setRenderLegacyDynamicTemplate(false)
      }
    }
  }, [productBottomHtml])

  const isVideo = useMemo(
    () => !!images?.filter(img => !!img.sources?.length).length,
    [images],
  )
  const [activeSlide, setActiveSlide] = useState(2)
  const currentVideo =
    images &&
    useMemo(
      () => !!images[activeSlide - 2]?.sources?.length,
      [mainCarousel, images, activeSlide],
    )

  const handleActiveItemChange = index => {
    setActiveItemIndex(index)
  }

  // get youtube url id
  const getYoutubeVideoId = url => {
    const videoIdMatch = url && url.match(/youtu\.be\/([a-zA-Z0-9_-]+)/)
    if (videoIdMatch && videoIdMatch.length >= 2) {
      return videoIdMatch[1]
    }
  }

  return (
    <>
      {hasImages && (
        <div
          className={`w-full overflow-hidden`}
          id="featuredProductSlideWrapper"
        >
          <div className={`${"carouselVideoUi"}`}>
            <Carousel
              arrows
              renderButtonGroupOutside
              responsive={mainResponsive}
              focusOnSelect
              swipeable={false}
              draggable={false}
              centerMode={false}
              infinite
              ref={mainCarousel}
              showDots
              customDot={<CustomDot />}
              beforeChange={(nextSlide, current) => {
                setActiveItemIndex(nextSlide - 2)
                //  setLabelIndex(nextSlide - 2 + 5)
              }}
              afterChange={() => {
                if (mainCarousel.current.state.currentSlide === 1) {
                  setActiveSlide(images.length + 1)
                } else {
                  setActiveSlide(mainCarousel.current.state.currentSlide)
                }
              }}
              customRightArrow={
                <FeaturedProductImagesArrow
                  isVideo={isVideo}
                  currentVideo={currentVideo}
                  right
                  imagesLength={images?.length}
                  // subCarousel={subCarousel}
                />
              }
              customLeftArrow={
                <FeaturedProductImagesArrow
                  isVideo={isVideo}
                  currentVideo={currentVideo}
                  right={false}
                  imagesLength={carouselImage?.length}
                  // subCarousel={subCarousel}
                />
              }
            >
              {carouselImage?.map((img, index) => {
                return (
                  <div
                    className={` w-full
                     slide active ${
                       img.image?.src?.length ? "" : "video-slide"
                     }`}
                    key={index}
                    // data-index={index}
                  >
                    {img.image?.src?.length ? (
                      <Zoom>
                        <div className={`carouselContainer rounded-xl`}>
                          <div className="imageContainer">
                            <img
                              alt={
                                img.altText
                                  ? img.altText
                                  : `Product Image of ${title} #${index + 1}`
                              }
                              className="productImage rounded-xl"
                              src={img.image.originalSrc}
                            />
                          </div>
                        </div>
                      </Zoom>
                    ) : (
                      <Zoom>
                        <div className={`carouselContainer rounded-xl`}>
                          <div className="videoContainer ">
                            {img?.embeddedUrl ? (
                              <iframe
                                title={`video`}
                                src={`https://www.youtube.com/embed/${getYoutubeVideoId(
                                  img?.embeddedUrl,
                                )}`}
                                className="youtubeVideo"
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullScreen
                              />
                            ) : (
                              <video controls className="video">
                                <source
                                  src={
                                    img?.sources?.find(
                                      ({ format, height }) =>
                                        format === "mp4" && height == "720",
                                    )?.url
                                  }
                                  type="video/mp4"
                                />
                              </video>
                            )}
                          </div>
                        </div>
                      </Zoom>
                    )}
                  </div>
                )
              })}
            </Carousel>
          </div>

          <div className="w-[75%] m-auto">
            <Carousel
              arrows={false}
              responsive={subResponsive}
              swipeable={false}
              draggable={false}
              centerMode={false}
              infinite={true}
              slidesToSlide={1}
              ref={subCarousel}
              keyBoardControl
              className="custom-carousel"
              // showDots
              // customDot={<CustomDot />}
              renderDotsOutside
            >
              {carouselImage &&
                carouselImage.map((img, index) => {
                  if (index >= 0 && index <= 4) {
                    return (
                      <>
                        <button
                          className={`hidden slide active-carousel ${
                            index === activeItemIndex ? "active" : ""
                          } mr-3 mb-3 flex cursor-pointer hidden lg:block  rounded-xl overflow-hidden
                        justify-center items-center w-full
                        `}
                          type="button"
                          // tabIndex={index === 0 ? '0' : '-1'}
                          tabIndex={index}
                          style={{
                            maxWidth: "800px",
                            paddingBottom:
                              "70.25%" /* 9/16 aspect ratio (height/width) */,
                            position: "relative",
                          }}
                          // data-index={index}
                          onClick={e => {
                            // setLabelIndex(index + 5)
                            e.preventDefault()
                            setActiveSlide(index + 2)
                            moveMainSlide(index)

                            handleActiveItemChange(index)
                          }}
                          onKeyPress={e => {
                            e.preventDefault()
                            setActiveSlide(index + 2)
                            moveMainSlide(index)
                          }}
                        >
                          {img.image?.src?.length >= 1 ? (
                            <div
                              className="hidden 
                          absolute top-0 left-0 bottom-0 right-0 justify-center items-center grow	
                          lg:flex aspect-w-4 aspect-h-3 sm:aspect-w-16 sm:aspect-h-9 md:aspect-w-3 md:aspect-h-2 lg:aspect-w-5 lg:aspect-h-4"
                            >
                              <GatsbyImage
                                loading="lazy"
                                imgStyle={{
                                  objectFit: "cover",
                                }}
                                alt={
                                  img.altText
                                    ? img.altText
                                    : `Product Image of ${title} #${index + 1}`
                                }
                                image={img.image?.gatsbyImageData}
                              />
                              {labelIndex - 1 === index && (
                                <div className="absolute top-5 left-6 right-10 bottom-0 flex justify-center items-center	">
                                  <span className="text-white font-medium md:text-2xl lg:text-4xl sm:text-xs">
                                    +{images.length - labelIndex}
                                  </span>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div
                              className="hidden 
                          absolute top-0 left-0 bottom-0 right-0 justify-center items-center grow	
                          lg:flex aspect-w-4 aspect-h-3 sm:aspect-w-16 sm:aspect-h-9 md:aspect-w-3 md:aspect-h-2 lg:aspect-w-5 lg:aspect-h-4"
                            >
                              <div className="videoContainer ">
                                {
                                  // here
                                  img?.embeddedUrl ? (
                                    <div className="thumbnail-container">
                                      <img
                                        alt={
                                          img.altText
                                            ? img.altText
                                            : `Thumbnail Image of ${title} #${
                                                index + 1
                                              }`
                                        }
                                        className="w-full"
                                        src={img?.videoThumbnail}
                                      />
                                      <BsFillPlayFill className="play-icon" />
                                    </div>
                                  ) : (
                                    <video controls className="video">
                                      <source
                                        src={
                                          img?.sources?.find(
                                            ({ format, height }) =>
                                              format === "mp4" &&
                                              height == "720",
                                          )?.url
                                        }
                                        type="video/mp4"
                                      />
                                    </video>
                                  )
                                }
                              </div>
                            </div>
                          )}
                        </button>
                      </>
                    )
                  }
                })}
            </Carousel>
          </div>
          {hasMultipleImages && (
            <div
              className="text-center mt-4 hidden text-lg left-1/2 absolute transform-gpu"
              id="instructions"
            >
              <span aria-hidden="true">←</span> scroll for more{" "}
              <span aria-hidden="true">→</span>
            </div>
          )}
        </div>
      )}
      {!hasImages && (
        <span className="flex items-center justify-center text-lg top-[-640px]">
          No Preview image
        </span>
      )}
      <div>
        {/* {renderLegacyDynamicTemplate ? (
          <div className="below-content pt-60px">
            <div className="product-overview">
              <div
                className="large--one-whole"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: productBottomHtml }}
              />
            </div>
          </div>
        ) : null} */}
      </div>
    </>
  )
}

export default FeaturedProductImagesCarousel

FeaturedProductImagesCarousel.defaultProps = {
  storyblokEntry: [],
}

FeaturedProductImagesCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  title: PropTypes.string.isRequired,
  imageIndex: PropTypes.number,
}

FeaturedProductImagesCarousel.defaultProps = {
  imageIndex: 0,
}
