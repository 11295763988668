/* eslint-disable */
// @ts-nocheck

import { Link } from "@reach/router"
import React, { useEffect, useMemo, useState } from "react"
import { useLocation } from "@reach/router"
import axios from "axios"
import { functionsEndpoint } from "../../../config"
import { dateFormateHandler } from "../../../utils/dateFormatHandler"

const OrderHistoryCard = ({
  processedAt,
  id,
  lineItems,
  name,
  orderNumber,
}: Order) => {
  const inputString = name
  const formattedOrderNumber = inputString.match(/\d+/)[0]

  const location = useLocation()
  const [shopifyOrder, setShopifyOrder] = useState(null)

  const [
    allProductsVariantsWithMetaFields,
    setAllProductsVariantsWithMetaFields,
  ] = useState([])

  const hasItems = useMemo(() => {
    return !!lineItems?.edges?.filter(item => item?.node?.variant?.image?.url)
      ?.length
  }, [lineItems])

  const getProductById = async (
    id,
    productId,
    variant_id,
    variant_product_id,
  ) => {
    return await axios
      .post(functionsEndpoint + "/get-product-by-id", {
        id,
        product_id: productId,
        variant_id,
        variant_product_id,
      })
      .then(res => {
        return res.data
      })
      .catch(err => {
        return {}
      })
  }

  const getOrderDetailsById = async () => {
    try {
      const response = await axios.get(`${functionsEndpoint}/order?id=${id}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })

      const data = response?.data || {}
      if (data === undefined) {
        return
      }

      if (data) {
        const lineItemsPromises = data?.line_items?.map(async lineItem => {
          if (lineItem?.product_id !== null) {
            const productResponse = await getProductById(
              lineItem?.product_id,
              `gid://shopify/Product/${lineItem?.product_id}`,
              lineItem?.variant_id,
              `gid://shopify/ProductVariant/${lineItem?.variant_id}`,
            )
            return { ...productResponse, lineItem }
          } else {
            return { lineItem }
          }
        })

        const allProductsVariantsWithMetaFields =
          await Promise.all(lineItemsPromises)
        const filteredResponses = allProductsVariantsWithMetaFields.filter(
          res => res !== undefined,
        )
        setAllProductsVariantsWithMetaFields(filteredResponses)
        const orderData = data
        setShopifyOrder(orderData)
      } else {
      }
    } catch (error) {}
  }
  useEffect(() => {
    getOrderDetailsById()
  }, [id])
  const formattedDate = dateFormateHandler(processedAt || new Date())
  return (
    <Link
      to={`/account/order-history/order?id=${id}/${
        location.search ? location.search : ""
      }`}
      className="lg:max-w-[350px] w-full bg-white py-6 px-5 relative"
    >
      <div>
        <p className="mb-0 font-medium text-sm">
          {formattedDate.monthNumber}-{formattedDate.dateNumber}-
          {formattedDate.fullYear}
        </p>
        <h1 className="mb-0 mt-3 font-extrabold text-base">
          Order Number:
          <span className="ml-1 font-normal text-sm">{orderNumber}</span>
        </h1>
        <div className="space-x-2 flex">
          <p className="text-12 text-[#828282] mb-0">Return</p>
          <p className="text-12 text-[#828282] mb-0">Download Invoice</p>
        </div>
        <>
          <h1 className="mb-0 mt-3 font-extrabold text-base">Items</h1>
          <div className="flex flex-col items-start">
            {allProductsVariantsWithMetaFields?.length > 0 ? (
              <div className="flex flex-col items-start space-y-2 mt-2 overflow-x-auto w-full">
                {allProductsVariantsWithMetaFields?.map((item, idx) => (
                  <div
                    key={idx}
                    className="w-full"
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      e.nativeEvent.stopImmediatePropagation()
                      window.location.href = `/products/${item?.handle}`
                    }}
                  >
                    <div className="py-2 space-x-4 flex justify-between items-center w-full overflow-x-auto">
                      <p className="whitespace-nowrap font-normal text-xs">
                        {item?.lineItem?.title || item?.title}
                      </p>
                      <p className="whitespace-nowrap font-normal text-xs">
                        $
                        {item?.lineItem?.price ||
                          item?.variants?.[0]?.price ||
                          "0.00"}
                      </p>
                      <p className="whitespace-nowrap font-normal text-xs">
                        {item?.lineItem?.quantity}
                      </p>
                    </div>
                    <div className=" text-center">
                      {item?.variants?.[0]?.metafields[0]?.value && (
                        <>
                          <div className="text-[9px]">Preordered item:</div>
                          <div className="text-[9px] leading-[2px] text-[#c99e1a]">
                            {" "}
                            {item?.variants?.[0]?.metafields[0]?.value}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <h1 className="!text-[10px]"> No items to show. </h1>
            )}
          </div>
        </>
      </div>
    </Link>
  )
}

export default OrderHistoryCard
