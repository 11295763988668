/* eslint-disable*/
import React from "react"
import PropTypes from "prop-types"

const YotpoReviewsWidget = props => {
  const { product, productShopifyId } = props
  const { title, onlineStoreUrl, featuredImage, price, priceRangeV2 } = product
  return (
    <div
      className="yotpo yotpo-main-widget"
      data-product-id={productShopifyId}
      data-price={price}
      data-currency={priceRangeV2.minVariantPrice.currencyCode}
      data-name={title}
      data-url={onlineStoreUrl}
      data-image-url={featuredImage.originalSrc}
    />
  )
}

export default YotpoReviewsWidget

YotpoReviewsWidget.propTypes = {
  product: PropTypes.objectOf("string").isRequired,
  productShopifyId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onlineStoreUrl: PropTypes.string.isRequired,
  featuredImage: PropTypes.objectOf("string").isRequired,
  price: PropTypes.string.isRequired,
  priceRangeV2: PropTypes.objectOf("string").isRequired,
}
