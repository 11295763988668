import { Link } from "gatsby"
import React from "react"
import { useLocation } from "@reach/router"

interface Props {
  image: string
  title: string
  link: string
  onClose?: () => void
}

const CartDrawerCard = ({ image, title, link, onClose }: Props) => {
  const location = useLocation()

  const linkUrl = `${link}/${location.search}`
  return (
    <Link
      to={linkUrl}
      className="w-full mb-3 block"
      onClick={() => setTimeout(() => onClose?.(), 500)}
    >
      <div className="w-full relative flex justify-center items-center">
        <img src={image} className="w-full object-cover h-[121px] m-0" />
        <div className="w-full absolute inset-0 flex justify-center items-center">
          <p className="mb-0 text-white text-xl font-extrabold">{title}</p>
        </div>
      </div>
    </Link>
  )
}

export default CartDrawerCard
