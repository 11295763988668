/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react"
import AdventureAssurance from "../../assets/AdventureAssuranceV2"
import ShippingIcon from "../../assets/ShippingIconV2"
import AddToCart from "../AddToCart/v2"
import NumericInput from "../NumericInput/v2"
import SelectOption from "./SelectOption"
import YotpoStarRatings from "../YotpoStarRatings"

type Props = {
  title?: string
  affirmVariantPrice: any
  description?: string
  sku?: string
  storefrontId?: string
  id?: string
  product: any
  productShopifyId: string
  variants: any
  hasVariants: boolean
  price: string
  lineItem: {
    variantId: string
    quantity: number
  }[]
  categoryName: string
  variant: any
  setQuantity: React.Dispatch<React.SetStateAction<number>>
  quantity: number
  optionsLibrary: any
  preorder: any
  available: boolean
  metafields: any
  handleOptionChange: (index: any, event: any) => void
  availableSubOptions: any
  notice: any
  options: any[]
  // selectedVariantPrice: string
  // countryCurrency: string
  // currencyCompareAt: any
  setLineItem: React.Dispatch<
    React.SetStateAction<
      {
        variantId: string
        quantity: number
      }[]
    >
  >
}

const MobileScrollAddCart = (props: Props) => {
  const {
    title,
    variants,
    sku,
    product,
    price,
    lineItem,
    available,
    preorder,
    variant,
    categoryName,
    notice,
    hasVariants,
    options,
    optionsLibrary,
    productShopifyId,
    availableSubOptions,
    setLineItem,
    handleOptionChange,
    setQuantity,
    quantity,
    metafields,
    storefrontId,
    id,
    // selectedVariantPrice,
    // countryCurrency,
  } = props
  const affirmConfig = {
    public_api_key: process.env.GATSBY_AFFIRM_PUBLIC_KEY,
    script: "https://cdn1.affirm.com/js/v2/affirm.js",
  }

  // Run Affirm Scriptss
  useEffect(() => {
    ;(function (l, g, m, e, a, f, b) {
      var d,
        // @ts-ignore
        c = l[m] || {},
        h = document.createElement(f),
        n = document.getElementsByTagName(f)[0],
        // @ts-ignore
        k = function (a, b, c) {
          return function () {
            a[b]._.push([c, arguments])
          }
        }
      c[e] = k(c, e, "set")
      d = c[e]
      c[a] = {}
      c[a]._ = []
      d._ = []
      c[a][b] = k(c, a, b)
      // @ts-ignore
      a = 0
      for (
        // @ts-ignore
        b =
          "set add save post open empty reset on off trigger ready setProduct".split(
            " ",
          );
        // @ts-ignore
        a < b.length;
        // @ts-ignore
        a++
      )
        // @ts-ignore
        d[b[a]] = k(c, e, b[a])
      // @ts-ignore
      a = 0
      // @ts-ignore
      for (b = ["get", "token", "url", "items"]; a < b.length; a++)
        // @ts-ignore
        d[b[a]] = function () {}
      // @ts-ignore
      h.async = !0
      // @ts-ignore
      h.src = g[f]
      // @ts-ignore
      n.parentNode.insertBefore(h, n)
      // @ts-ignore
      delete g[f]
      d(g)
      // @ts-ignore
      l[m] = c
    })(window, affirmConfig, "affirm", "checkout", "ui", "script", "ready") // eslint-disable-line
    // Use your live public API Key and https://cdn1.affirm.com/js/v2/affirm.js script to point to Affirm production environment.
  }, [])

  const [affirmVariantPrice, setaffirmVariantPrice] = useState(0)

  const affirmPriceSetter = (cost: number) => {
    // Increase variant price by the factor of 2 for Affirm
    // Set 10,000th decimal point as SigFig
    const adjustedVariantPriceFloat = (cost * 100).toFixed(4)
    // Update data type to int
    const adjustedVariantPriceInt = Number.parseInt(
      adjustedVariantPriceFloat,
      10,
    )

    setaffirmVariantPrice(adjustedVariantPriceInt)
  }

  useEffect(() => {
    if (variant?.price) {
      affirmPriceSetter(variant.price)
    }
  }, [variant])

  const includedList = useMemo(() => {
    try {
      return (
        JSON.parse(
          variant?.metafields?.filter(
            (field: any) => field.key === "included_list",
          )?.[0]?.value,
        ) || []
      )
    } catch (err) {
      return []
    }
  }, [variant])

  const singleVariantIncludedList = useMemo(() => {
    try {
      return (
        JSON.parse(
          metafields?.filter((field: any) => field.key === "included_list")?.[0]
            ?.value,
        ) || []
      )
    } catch (err) {
      return []
    }
  }, [variant])

  return (
    <div className="bg-white p-7 lg:sticky lg:top-0 w-full  lg:right-0 lg:border lg:border-[#E5E5E5]  w-full h-full">
      <div className="flex justify-between items-center">
        <h1 className="font-sans font-black text-sm mb-0">{title}</h1>

        <div>
          {price === "$NaN" ? (
            <>
              {/* <p className="text-[#1C1C1C] font-sans font-bold text-xs mb-0 mt-1.5 w-[55px]">
                {selectedVariantPrice
                  ? selectedVariantPrice
                  : `$${variants[0].price}`}
              </p> */}
              {`$${variants[0].price}`}
            </>
          ) : (
            <>
              <p className="text-[#1C1C1C] font-sans font-medium text-xs mb-0">
                Price:{" "}
              </p>
              {/* <p className="text-[#1C1C1C] font-sans font-bold text-lg mb-0 mt-1.5">
                {selectedVariantPrice ? (
                  <>
                    {countryCurrency === "USD" ? "$" : countryCurrency + " "}
                    {selectedVariantPrice}
                  </>
                ) : (
                  `$${price}`
                )}
              </p> */}
              {`$${price}`}
            </>
          )}
        </div>
      </div>

      <fieldset className="optionsWrapper grid grid-cols-1 pb-2 border-none mb-2">
        {hasVariants &&
          options.map(({ id, name, values }, index) => (
            <div className="flex items-center">
              <SelectOption
                key={index}
                selectedVariant={variant}
                handleOptionChange={handleOptionChange}
                id={id}
                index={id}
                name={name}
                values={
                  name === optionsLibrary.name
                    ? values
                    : availableSubOptions[name]
                }
              />
            </div>
          ))}
      </fieldset>
      <div className="">
        <div>
          <div className="mt-1 mb-7 sm:mb-0">
            <AddToCart
              productLists={lineItem}
              setProductLists={setLineItem}
              available={available}
              preorder={preorder.preorder}
              price={price}
              productName={product.title}
              variantName={variant.title}
              categoryName={categoryName}
              hasVariants={hasVariants}
              storefrontId={storefrontId}
              quantity={quantity}
              id={id}
              variants={variants}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileScrollAddCart
