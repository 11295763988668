import React from "react"
import SupportComponent from "../../components/Support"

const Support = () => {
  return (
    <div className="w-full ">
      <SupportComponent />
    </div>
  )
}

export default Support
