/* eslint-disable */

import React, { useState } from "react"
import { useLocation } from "@reach/router"

import { Formik, Form, Field, ErrorMessage } from "formik"
import { Link } from "gatsby"
import axios from "axios"
import { StaticImage } from "gatsby-plugin-image"
// import Eye from '../images/Password-Eye.svg';
import FormError from "../components/FormError"
import * as style from "./welcome.module.scss"
import { functionsEndpoint } from "../config"

interface resetValues {
  password?: string
  confirmPassword?: string
}

interface resetError {
  password?: string
}

const Reset = () => {
  const location = useLocation()

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [showNotice, setShowNotice] = useState(false)
  const [msg, setMsg] = useState("")
  const [success, setSuccess] = useState(false)
  const resetUrl =
    typeof window !== "undefined" ? window.location.search.slice(3) : ""

  const newURl =  typeof window !== "undefined" ? window.location.href.split("=")[2] : ""
  const Notice = (
    <div
      className={`top-2 m-auto border-[1px] py-2 px-4 text-white text-center rounded-lg ${
        success ? "bg-paloverde border-paloverde" : "bg-red-500 border-red-500"
      }`}
    >
      {msg}
    </div>
  )

  return (
    <div className="relative text-center p-4 my-12">
      <h2 className="text-lg">RESET ACCOUNT PASSWORD</h2>
      <Formik
        initialValues={{ password: "" }}
        validate={(values: resetValues) => {
          const errors: resetError = {}
          if (values.password !== values.confirmPassword) {
            errors.password = "Password does not match!"
          }
          return errors
        }}
        onSubmit={async (values, { setSubmitting }) => {
          axios
            .post(
              functionsEndpoint + "/reset",
              JSON.stringify({
                data: {
                  password: values.password,
                  resetUrl: decodeURIComponent(newURl),
                },
              }),
              {
                withCredentials: true,
                headers: { "Content-Type": "application/json" },
              },
            )
            .then(async res => {
              // setSubmitting(false);
              setMsg("Password Reset Successful!")
              setSuccess(true)
              setShowNotice(true)
            })
            .catch(err => {
              console.error(err)
              setMsg(
                `Password Reset Failed. Response from Server: ${err.message}`,
              )
              setSubmitting(false)
              setSuccess(false)
              setShowNotice(true)
            })
        }}
      >
        {({ isSubmitting }) => (
          <Form className="flex flex-col relative justify-center items-center">
            <br />
            <div className="min-w-[250px] max-w-[350px] flex flex-col w-full">
              <div className="flex items-center w-full h-[35px] px-3 border-[1px] border-obsidian">
                <Field
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  className="w-full pt-1 text-sm"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(prev => !prev)}
                >
                  <StaticImage
                    src="../images/Password-Eye.svg"
                    quality={100}
                    width={20}
                    formats={["auto", "webp", "avif"]}
                    transformOptions={{ fit: "cover" }}
                    className={style.passwordEye}
                    alt="Pasword Eye Icon"
                  />
                </button>
              </div>
              <br />
              <div className="flex items-center w-full h-[35px] px-3 border-[1px] border-obsidian">
                <Field
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  className="w-full pt-1 text-sm"
                />
                <ErrorMessage
                  name="password"
                  render={msg => <FormError msg={msg} />}
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(prev => !prev)}
                >
                  <StaticImage
                    src="../images/Password-Eye.svg"
                    quality={100}
                    width={20}
                    formats={["auto", "webp", "avif"]}
                    transformOptions={{ fit: "cover" }}
                    className={style.passwordEye}
                    alt="Pasword Eye Icon"
                  />
                </button>
              </div>
              <p className={style.signInBtnWrapper}>
                <button
                  type="submit"
                  className="w-full h-[35px] text-sm bg-obsidian hover:bg-paloverde text-white pt-1"
                  disabled={isSubmitting}
                >
                  Reset Password
                </button>
              </p>
              {showNotice ? Notice : null}
              {success ? (
                <Link
                  to={`/login/${location.search ? location.search : ""}`}
                  className="text-sm shadow-md mt-5 px-4 py-2 m-auto w-full bg-paloverde text-white rounded-lg"
                >
                  Go back to Log in
                </Link>
              ) : null}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Reset
