const collectionHandles = new Set([
  // 'shop-all',
  // 'new-products',
  "filters",
  "pro-systems",
  "mobile",
  "mavic-3-pro-filters",
  // 'tripods',
  // 'mounts',
  // 'camera-straps',
  "gear",
])

export default collectionHandles
