import React from "react"
import PropTypes from "prop-types"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import { render } from "storyblok-rich-text-react-renderer"
import { useStoryblok } from "../utils/storyblok"
// import DynamicComponents from '../components/DynamicComponents';
import Seo from "../components/seo"

/* eslint-disable camelcase */
const BlogTemplate = ({ pageContext }) => {
  // console.log(storyblokEntry);
  const { storyblokEntry } = pageContext
  const story = storyblokEntry
    ? useStoryblok(storyblokEntry)
    : { content: null }

  const { title, intro, long_text, created_at } = story.content

  return (
    <div className="max-w-[1180px] mx-auto px-[15px] md:px-16 my-20 text-grey-semi-dark text-sm font-light">
      <Seo title={title} />
      <h1 className="text-center text-3xl my-8 text-paloverde uppercase">
        {title}
      </h1>
      <h4 className="text-center text-sm font-normal">{intro}</h4>
      <div>{render(long_text)}</div>
      <p className="text-center w-full italic font-light">{created_at}</p>
    </div>
  )
}

export default BlogTemplate

BlogTemplate.propTypes = {
  storyblokEntry: PropTypes.shape({
    title: PropTypes.string.isRequired,
    intro: PropTypes.string,
    long_text: PropTypes.string,
    created_at: PropTypes.string,
  }),
}

BlogTemplate.defaultProps = {
  storyblokEntry: {
    intro: "",
    long_text: "CONTENT IS MISSING",
    created_at: "",
  },
}
