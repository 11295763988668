import React from "react"
import * as style from "../GearUp/index.module.scss"

function Adventure(props: any) {
  return (
    <div
      className={`${style.adventureAssurance}relative flex flex-col items-center justify-end md:justify-center`}
    >
      <img
        src={props?.backgroundImage?.filename}
        className="hidden sm:hidden md:block w-screen m-0"
      />
      <img
        src={props?.mobileImage?.filename}
        className="block md:hidden w-screen m-0"
        alt={props?.mobileImage?.alt || "Mobile Background"}
      />
      <div
        className={`${style.data} absolute text-white w-[100%] px-[20px] md:pl-0 md:w-[60vw] lg:w-[40vw] xl:w-[32vw] left-0 md:left-[12.5vw] pb-20 md:pb-0`}
      >
        <h3 className="font-black mb-2 text-3xl md:text-md lg:text-3xl">
          {props?.Title}
        </h3>
        <p className="text-sm">{props?.subTitle}</p>
      </div>
    </div>
  )
}

export default Adventure
