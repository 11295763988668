/* eslint-disable */
import React from "react"

// width="120.075" height="136.97"
const WishListIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    viewBox="0 0 120.075 136.97"
  >
    <g id="Icon" transform="translate(8 8)">
      <g
        id="Group_447"
        data-name="Group 447"
        transform="translate(16.463 20.609)"
      >
        <line
          id="Line_220"
          data-name="Line 220"
          x2="68.426"
          transform="translate(2.724 41.231)"
          fill="none"
          stroke="#434343"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="8"
        />
        <line
          id="Line_221"
          data-name="Line 221"
          x2="68.426"
          transform="translate(2.724 60.492)"
          fill="none"
          stroke="#434343"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="8"
        />
        <line
          id="Line_222"
          data-name="Line 222"
          x2="68.426"
          transform="translate(2.724 79.753)"
          fill="none"
          stroke="#434343"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="8"
        />
        <path
          id="Path_1426"
          data-name="Path 1426"
          d="M43.883,29.022l4.226,8.564,9.451,1.373-6.838,6.666,1.614,9.412-8.453-4.444-8.453,4.444,1.614-9.412-6.838-6.666,9.451-1.373Z"
          transform="translate(-30.206 -29.022)"
          fill="#434343"
        />
      </g>
      <g
        id="Rectangle_377"
        data-name="Rectangle 377"
        transform="translate(0 0)"
        fill="none"
        stroke="#434343"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="8"
      >
        <rect width="104.075" height="120.97" rx="3.107" stroke="none" />
        <rect
          x="-4"
          y="-4"
          width="112.075"
          height="128.97"
          rx="7.107"
          fill="none"
        />
      </g>
    </g>
  </svg>
)

export default WishListIcon
