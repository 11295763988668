/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"

const YotpoStarRatings = props => {
  const { product, productShopifyId, styleName } = props
  const { title, onlineStoreUrl, featuredImage, description } = product
  return (
    <div
      className={`yotpo bottomLine ${styleName} min-h-[23px] relative`}
      data-appkey={process.env.GATSBY_YOTPO_APP_KEY}
      // data-domain="polarpro.com"
      data-product-id={productShopifyId}
      data-product-models={productShopifyId}
      data-name={title}
      data-url={onlineStoreUrl}
      data-image-url={featuredImage?.originalSrc}
      data-description={description}
      // data-bread-crumbs="{% for tag in product.tags %}{{ tag | escape }};{% endfor %}"
    />
  )
}

export default YotpoStarRatings

YotpoStarRatings.propTypes = {
  product: PropTypes.objectOf("string").isRequired,
  productShopifyId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onlineStoreUrl: PropTypes.string.isRequired,
  featuredImage: PropTypes.objectOf("string").isRequired,
  description: PropTypes.string.isRequired,
  styleName: PropTypes.string,
}

YotpoStarRatings.defaultProps = {
  styleName: "",
}
