/* eslint-disable */
import React, { useState, createContext, useEffect } from "react"
import fetch from "isomorphic-fetch"
import Client from "shopify-buy/index.unoptimized.umd"
import PropTypes from "prop-types"

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch,
)

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  cartBannerStatus: false,
  addToCartSidebar: false,
  contextRecommendedProducts: [],
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: props => {},
  changeBannerInfo: () => {},
  closeCartBanner: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  openPopUpModal: false,
  setOpenPopUpModal: val => {},
  client,
  checkout: {
    lineItems: [],
  },
}

export const StoreContext = createContext(defaultValues)
const isBrowser = typeof window !== "undefined"
const localStorageKey = "shopify_checkout_id"

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = useState(defaultValues.checkout)
  const [loading, setLoading] = useState(false)
  const [contextRecommendedProducts, setContextRecommendedProducts] = useState(
    [],
  )

  const [openPopUpModal, setOpenPopUpModal] = useState(false)

  const [cartBannerStatus, setCartBannerStatus] = useState(false)
  const [addToCartSidebar, setAddToCartSidebar] = useState(false)
  const [addToCartSidebarType, setAddToCartSidebarType] = useState("cart")

  const [didJustAddToCart, setDidJustAddToCart] = useState(false)

  const setCheckoutItem = checkoutState => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkoutState.id)
    }

    setCheckout(checkoutState)
  }

  useEffect(() => {
    const closeSidebar = event => {
      if (addToCartSidebar && !event.target.closest(".sidebar")) {
        setAddToCartSidebar(false)
        openAddToCartSidebar(true)
      }
    }

    document.addEventListener("click", closeSidebar)

    return () => {
      document.removeEventListener("click", closeSidebar)
    }
  }, [addToCartSidebar])

  useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

      if (existingCheckoutID && existingCheckoutID !== "null") {
        try {
          const existingCheckout =
            await client.checkout.fetch(existingCheckoutID)
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout)
            const checkoutUrl = existingCheckout.webUrl.split("/")
            const token = checkoutUrl[checkoutUrl.length - 1].split("?")
            const checkout_token = token[0]
            SendTrackingToRefersion(checkout_token)
            return
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null)
        }
      }
      const newCheckout = await client.checkout
        .create()
        .then(checkout => {
          setCheckoutItem(checkout)
          const checkoutUrl = checkout.webUrl.split("/")
          const token = checkoutUrl[checkoutUrl.length - 1].split("?")
          const checkout_token = token[0]

          SendTrackingToRefersion(checkout_token)
          return checkout
        })
        .catch(error => console.log(error))
    }

    initializeCheckout()
  }, [])

  /*
   * Example lineitems to be passed into addLineItems:
   * [{
   *   variantId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8yOTEwNjAyMjc5Mg==',
   *   quantity: 1,
   *   customAttributes: [{ key: 'myKey', value: 'myValue' }]
   * }, {
   *   ...
   * }]
   */
  const addVariantToCart = lineItemsToUpdate => {
    try {
      if (lineItemsToUpdate?.[0]?.quantity) {
        const { quantity, variantId } = lineItemsToUpdate[0]
        const variantIdNumber = variantId.split("/").pop()
        try {
          TriplePixel("AddToCart", { item: variantIdNumber, q: quantity })
        } catch (err) {
          // Empty
        }
      }
    } catch (err) {
      // Empty
    }
    setLoading(true)
    const checkoutID = checkout.id
    let lineItemsToUpdates
    if (lineItemsToUpdate?.length > 1) {
      lineItemsToUpdates = lineItemsToUpdate
    } else {
      lineItemsToUpdates = [
        {
          variantId: lineItemsToUpdate[0]?.variantId,
          quantity: parseInt(lineItemsToUpdate[0]?.quantity, 10),
        },
      ]
    }
    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdates)
      .then(res => {
        localStorage.setItem("totalPrice", `${res.totalPrice}`)
        localStorage.setItem("orderId", `${res.id}`)
        if (lineItemsToUpdate[0].preorder == true) {
          let preOrderInfo
          if (typeof window !== undefined) {
            const updatedPreOrderInfo = localStorage.getItem("preOrderInfo")
            preOrderInfo = JSON.parse(updatedPreOrderInfo)
          }

          if (preOrderInfo) {
            const findingVariant = preOrderInfo.filter(
              item => item.variantId === lineItemsToUpdate[0].variantId,
            )
            if (findingVariant?.length < 1) {
              preOrderInfo.push({
                preorder: lineItemsToUpdate[0]?.preorder,
                preorderDate: lineItemsToUpdate[0]?.preorderDate,
                variantId: lineItemsToUpdate[0].variantId,
              })
              if (typeof window !== undefined) {
                localStorage.setItem(
                  "preOrderInfo",
                  JSON.stringify(preOrderInfo),
                )
              }
            }
          } else {
            if (typeof window !== undefined) {
              const data = [
                {
                  preorder: lineItemsToUpdate[0]?.preorder,
                  preorderDate: lineItemsToUpdate[0]?.preorderDate,
                  variantId: lineItemsToUpdate[0].variantId,
                },
              ]
              localStorage.setItem("preOrderInfo", JSON.stringify(data))
            }
          }
        }
        if (res?.lineItems?.length) {
          res?.lineItems?.forEach(lineItem => {
            const { variantId } = lineItemsToUpdate[0]
            if (lineItem.variant.id === variantId) {
              try {
                snaptr("track", ADD_CART, {
                  price: lineItem.variant.price.amount,
                  currency: res.currencyCode,
                  item_ids: [lineItem.variant.id],
                  item_category: lineItem.title,
                  number_items: lineItem.quantity,
                  user_email: res.email || "",
                })
              } catch (err) {}
            }
          })
        }
        setCheckout(res)
        setLoading(false)
        setDidJustAddToCart(true)
        setTimeout(() => setDidJustAddToCart(false), 3000)
      })
  }

  // populate cart banner
  const changeBannerInfo = (price, productName, variantName) => {
    if (price[0] !== "$") price = "$" + price
    var bannerTitle = document.getElementsByClassName("banner-cart-title")[0]
    var bannerPrice = document.getElementsByClassName("banner-cart-price")[0]
    var bannerVariant = document.getElementsByClassName(
      "banner-cart-variant",
    )[0]
    bannerTitle.textContent = productName
    bannerPrice.textContent = price
    if (bannerVariant && variantName !== "Default Title")
      bannerVariant.textContent = variantName

    setCartBannerStatus(true)
    // set timeout after 6 sec to make banner go away
    setTimeout(() => setCartBannerStatus(false), 8000)
  }

  // close cart banner
  const closeCartBanner = () => {
    setCartBannerStatus(false)
  }

  // close cart banner
  const setAddToCartSidebarTypeFunction = e => {
    setAddToCartSidebarType(e)
  }

  // close cart banner
  const openAddToCartSidebar = () => {
    setAddToCartSidebar(true)
  }

  const closeAddToCartSideBar = () => {
    setAddToCartSidebar(false)
  }
  const removeLineItem = (checkoutID, lineItemID) => {
    setLoading(true)

    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then(res => {
        setCheckout(res)
        setLoading(false)
      })
  }

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true)

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ]

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then(res => {
        setCheckout(res)
        setLoading(false)
      })
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        changeBannerInfo,
        cartBannerStatus,
        closeCartBanner,
        openAddToCartSidebar,
        closeAddToCartSideBar,
        addToCartSidebarType,
        setAddToCartSidebarTypeFunction,
        addToCartSidebar,
        setContextRecommendedProducts,
        contextRecommendedProducts,
        checkout,
        loading,
        didJustAddToCart,
        openPopUpModal,
        setOpenPopUpModal,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
