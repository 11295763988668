/* eslint-disable linebreak-style */
import { useState, useEffect, useMemo } from "react"

export default function useProduct({
  variant,
  client,
  product,
  newInitialVariant,
}: any) {
  const [productClient, setProductClient] = useState<any>({})
  const productAvailableQty = (handle: any) => {
    const qtyQuery = client.graphQLClient.query((root: any) => {
      root.add(
        "productByHandle",
        { args: { handle: `${handle}` } },
        (productQuery: any) => {
          productQuery.add("title")
          productQuery.add("handle")
          productQuery.add("description")
          productQuery.add("descriptionHtml")
          productQuery.add("id")
          productQuery.add(
            "metafields",
            { args: { first: 20 } },
            (metafield: any) => {
              metafield.add("key")
              metafield.add("value")
            },
          )
          productQuery.addConnection(
            "variants",
            { args: { first: 50 } },
            (variantQuery: any) => {
              variantQuery.add("id")
              variantQuery.add("sku")
              variantQuery.add("title")
              variantQuery.add("availableForSale")
              variantQuery.add("quantityAvailable")
              variantQuery.add("currentlyNotInStock")
              variantQuery.add(
                "metafields",
                { args: { first: 6 } },
                (metafield: any) => {
                  metafield.add("key")
                  metafield.add("value")
                },
              )
            },
          )
        },
      )
    })

    return client.graphQLClient
      .send(qtyQuery)
      .then((res: any) => JSON.parse(JSON.stringify(res.model.productByHandle)))
      .catch(() => null)
  }

  const getProductAvailableQty = async () => {
    const data = await productAvailableQty(product.handle)
    if (data) {
      setProductClient(data)
    }
  }

  useEffect(() => {
    getProductAvailableQty()
  }, [])

  const productVariant = useMemo(() => {
    const data =
      client.product.helpers.variantForOptions(product, variant) || variant

    const productVariantClient = productClient?.variants?.find(
      (item: any) => item.id === data.storefrontId,
    )

    if (productVariantClient) {
      return {
        ...data,
        ...productVariantClient,
        inventoryQuantity: productVariantClient.quantityAvailable,
      }
    }
    return data
  }, [product, variant, productClient])

  const available = productVariant?.availableForSale
  const preorder = useMemo(() => {
    let preorderDate
    if (
      productVariant.availableForSale &&
      productVariant.inventoryQuantity <= 0
    ) {
      preorderDate = productVariant.metafields.filter(
        (metafield: any) => metafield.key === "preorder-date",
      )[0]?.value

      return { preorder: true, preorderDate }
    }
    return { preorder: false, preorderDate: "" }
  }, [productVariant.storefrontId, product.storefrontId, productVariant])

  const title = productClient?.title || product.title
  const description = productClient?.description || product.description
  const descriptionHtml =
    productClient?.descriptionHtml || product.descriptionHtml

  return {
    productVariant,
    available,
    preorder,
    title,
    description,
    descriptionHtml,
  }
}
