/* eslint-disable */
import { useContext, useDebugValue } from "react"
import { AuthContext } from "../context/AuthContext"

const useAuth = () => {
  const { auth } = useContext(AuthContext)
  useDebugValue(auth, authData => (authData?.user ? "Logged In" : "Logged Out")) // eslint-disable-line
  return useContext(AuthContext)
}

export default useAuth
