/* eslint-disable */
import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import "../support/support.scss"
import Seo from "../../components/seo"
import { FaUsers, FaPlayCircle } from "react-icons/fa"
import { useLocation } from "@reach/router"
import { FormspreeProvider } from "@formspree/react"

const ResellerApplication = () => {
  const location = useLocation()

  const profilePictureRef = useRef(null)
  const [isSuccess, setIsSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [selectedItems, setSelectedItems] = useState([])
  const [showError, setshowError] = useState(false)
  const [isError, setIsError] = useState("")

  const handleCheckboxChange = itemName => {
    const updatedItems = [...fields.camera_owned]
    const index = updatedItems.indexOf(itemName)

    if (index === -1) {
      updatedItems.push(itemName)
    } else {
      updatedItems.splice(index, 1)
    }

    setFields({ ...fields, camera_owned: updatedItems })
  }

  const [fields, setFields] = useState({
    first_name: "",
    last_name: "",
    email: "",
    birth_date: "",
    primary_discipline: "Photography",
    type_of_photography: "Professional",
    camera_owned: [],
    primary_address: "",
    city: "",
    country: "",
    zip_code: "",
    instagram: "",
    twitter: "",
    youtube: "",
    facebook: "",
    vimeo: "",
    other: "",
    brand_ambassador_program: "Expanding Brand Awarness",
    insta_handlers: "",
    travel: "Seldomly (Every Few Months)",
    additional_info: "",
    attachemnt: "",
  })

  useEffect(() => {
    setSelectedItems(fields.camera_owned)
  }, [fields.camera_owned])

  const handleInputChange = e => {
    setFields({ ...fields, primary_discipline: e.target.value })
  }

  const handlePhotographyType = e => {
    setFields({ ...fields, type_of_photography: e.target.value })
  }

  const handleAmbassadorProgram = e => {
    setFields({ ...fields, brand_ambassador_program: e.target.value })
  }

  const handleTravelChange = e => {
    setFields({ ...fields, travel: e.target.value })
  }

  const saveFileForPurchase = async (file, type) => {
    setFields({ ...fields, attachemnt: file })
  }

  const submitHandler = async event => {
    event.preventDefault()

    try {
      const formData = {
        first_name: fields.first_name,
        last_name: fields.last_name,
        email: fields.email,
        birth_date: fields.birth_date,
        primary_discipline: fields.primary_discipline,
        type_of_photography: fields.type_of_photography,
        camera_owned: fields.camera_owned,
        primary_address: fields.primary_address,
        city: fields.city,
        country: fields.country,
        zip_code: fields.zip_code,
        instagram: fields.instagram,
        twitter: fields.twitter,
        youtube: fields.youtube,
        facebook: fields.facebbok,
        vimeo: fields.vimeo,
        other: fields.other,
        brand_ambassador_program: fields.brand_ambassador_program,
        insta_handlers: fields.insta_handlers,
        travel: fields.travel,
        additional_info: fields.additional_info,
        attachemnt: fields.attachemnt.name,
      }

      const response = await fetch(
        process.env.GATSBY_FORMSPREE_GET_INVOVLED_ID,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        },
      )

      if (response.ok) {
        setIsSuccess(true)
        setSuccessMessage(
          "Thank you very much for applying. We will respond to your application within 1 business day.",
        )
      } else {
        setshowError(true)
        setIsError("Failed to submit form data")
      }
    } catch (error) {
      setshowError(true)
      setIsError("Error submitting form data:")
    }
  }

  return (
    <div id="get-involved">
      <Seo title="Get Involved" />
      <div className="support-wrapper">
        <h2 className="uppercase text-paloverde text-2xl font-black text-center">
          Get Involved
        </h2>
        <p className="subtitle-text">
          We're excited about your interest in collaborating with us. From the
          drop down menu, select how you would like to collaborate with us. Once
          submitted, we will review your application and contact you within 3-4
          business days. We're excited to hear from you!
        </p>
        <div className="flex flex-col md:flex-row text-paloverde justify-center w-full mt-8 mb-8">
          <div className="flex flex-col items-center md:mr-16">
            <FaUsers className="text-[50px] mb-4" />
            <p className="font-black">Brand Ambassador</p>
          </div>
          <div className="flex flex-col items-center">
            <FaPlayCircle className="text-[50px] mb-4" />
            <p className="font-black">Media Reviews</p>
          </div>
        </div>
        <p className="title-text text-paloverde">BRAND AMBASSADOR</p>
        <p className="subtitle-text">
          The Brand Ambassador is a team of adventure seekers, outdoor
          explorers, cinematographers and photographers who are highly engaged
          in the filming industry. A candidate for the position of Brand
          Ambassador should be an enthusiastic, outgoing individual with a
          considerable social media presence and a medium to large sized
          network. Each applicant presumably should already be familiar with
          PolarPro and endorses our products.
        </p>
      </div>

      <div>
        <FormspreeProvider
          project={process.env.GATSBY_FORMSPREE_GET_INVOVLED_ID}
        >
          <div className="w-full">
            <div className="bg-white py-12 w-full">
              <div className="w-full flex flex-col justify-center">
                <form
                  onSubmit={submitHandler}
                  action={process.env.GATSBY_FORMSPREE_GET_INVOVLED_ID}
                  method="POST"
                  className="flex flex-col gap-4 w-[40%] mx-auto"
                >
                  <div className="relative  flex justify-center  mb-1 pt-6">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs text-yellow-500 font-semibold">
                        First Name:*
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        name="first_name"
                        required
                        value={fields.first_name}
                        onChange={e =>
                          setFields({ ...fields, first_name: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="relative  flex justify-center  mb-1 pt-3">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs text-yellow-500 font-semibold">
                        Last Name:*
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        name="last_name"
                        required
                        value={fields.last_name}
                        onChange={e =>
                          setFields({ ...fields, last_name: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="relative mb-1  flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs text-yellow-500 font-semibold">
                        Email*
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="email"
                        required
                        name="email"
                        value={fields.email}
                        onChange={e =>
                          setFields({ ...fields, email: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="relative mb-1  flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs text-yellow-500 font-semibold">
                        Birth Date:*
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        required
                        placeholder="MM / DD / YYYY"
                        name="birth_date"
                        value={fields.birth_date}
                        onChange={e =>
                          setFields({ ...fields, birth_date: e.target.value })
                        }
                      />
                      <p className="text-gray-400">format: (MM / DD / YYYY)</p>
                    </div>
                  </div>

                  <div className="relative mb-1 flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs text-yellow-500 font-semibold">
                        Primary Discipline?*
                      </label>
                      <select
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        name="primary_discipline"
                        value={fields.primary_discipline}
                        onChange={handleInputChange}
                      >
                        <option value="Photography">Photography</option>
                        <option value="Videography">Videography</option>
                        <option value="Both">Both</option>
                      </select>
                    </div>
                  </div>

                  <div className="relative mb-1 flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs text-yellow-500 font-semibold">
                        Type of Photographer/Videographer:*
                      </label>
                      <select
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        name="type_of_photography"
                        value={fields.type_of_photography}
                        onChange={handlePhotographyType}
                      >
                        <option value="Professional">Professional</option>
                        <option value="Part-Time">Part-Time</option>
                        <option value="Recreational">Recreational</option>
                      </select>
                    </div>
                  </div>

                  <div className="w-full border-t-[1px] py-4 border-b-[1px]  border-gray-900/10">
                    <label className="text-xl text-yellow-500 font-semibold">
                      Cameras Owned (Check All That Apply)
                    </label>
                    <div className="flex flex-col gap-3">
                      {[
                        "Spark Series",
                        "Mavic Series",
                        "Phantom Series",
                        "Inspire Series",
                        "GoPro / RX0 Series",
                        "DSLR / Mirrorless",
                      ]?.map(item => (
                        <div key={item} className="flex items-center gap-3">
                          <input
                            type="checkbox"
                            className=""
                            id={item}
                            checked={selectedItems.includes(item)}
                            onChange={() => handleCheckboxChange(item)}
                          />
                          <label
                            className="relative text-sm top-[4px]"
                            htmlFor={item}
                          >
                            {item}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="relative mb-1  flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs text-yellow-500 font-semibold">
                        Primary Address:*
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        required
                        name="primary_address"
                        value={fields.primary_address}
                        onChange={e =>
                          setFields({
                            ...fields,
                            primary_address: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="relative mb-1  flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs text-yellow-500 font-semibold">
                        City:*
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        required
                        name="city"
                        value={fields.city}
                        onChange={e =>
                          setFields({ ...fields, city: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="relative mb-1  flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs text-yellow-500 font-semibold">
                        Country:*
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        required
                        name="country"
                        value={fields.country}
                        onChange={e =>
                          setFields({ ...fields, country: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="relative !m-0  flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs text-yellow-500 font-semibold">
                        Zip code:*
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        required
                        name="zip_code"
                        value={fields.zip_code}
                        onChange={e =>
                          setFields({ ...fields, zip_code: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <hr className="border border-b-[1px]" />
                  </div>
                  <div className="flex w-full">
                    <p className="text-yellow-500 font-bold">
                      Social Media Sites Active On (Provide Username)
                    </p>
                  </div>

                  <div className="relative !m-0  flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs text-yellow-500 font-semibold">
                        Instagram
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        required
                        placeholder="Instagram (i.e. instagram.com/polarpro)"
                        name="instagram"
                        value={fields.instagram}
                        onChange={e =>
                          setFields({ ...fields, instagram: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="relative !m-0  flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs text-yellow-500 font-semibold">
                        Twitter
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        required
                        placeholder="Twitter (i.e. twitter.com/_polarpro)"
                        name="twitter"
                        value={fields.twitter}
                        onChange={e =>
                          setFields({ ...fields, twitter: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="relative !m-0  flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs text-yellow-500 font-semibold">
                        YouTube
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        required
                        placeholder="YouTube (i.e. youtube.com/polarprofilters)"
                        name="youtube"
                        value={fields.youtube}
                        onChange={e =>
                          setFields({ ...fields, youtube: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="relative !m-0  flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs text-yellow-500 font-semibold">
                        Facebook
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        required
                        placeholder="Facebook (i.e. facebook.com/polarpro)"
                        name="facebook"
                        value={fields.facebook}
                        onChange={e =>
                          setFields({ ...fields, facebook: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="relative !m-0  flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs text-yellow-500 font-semibold">
                        Vimeo
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        required
                        placeholder="Vimeo (i.e. Vimeo.com/polarpro)"
                        name="vimeo"
                        value={fields.vimeo}
                        onChange={e =>
                          setFields({ ...fields, vimeo: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="relative !m-0  flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs text-yellow-500 font-semibold">
                        Other
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        required
                        placeholder="Other (i.e. polarpro.com)"
                        name="other"
                        value={fields.other}
                        onChange={e =>
                          setFields({ ...fields, other: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <hr className="border border-t-[1px]" />
                  </div>

                  <div className="relative mb-1 flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs text-yellow-500 font-semibold">
                        Which aspect of the Brand Ambassador program is most
                        appealing to you?*
                      </label>
                      <select
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        name="brand_ambassador_program"
                        value={fields.brand_ambassador_program}
                        onChange={handleAmbassadorProgram}
                      >
                        <option value="Expanding Brand Awareness">
                          Expanding Brand Awareness
                        </option>
                        <option value="Social-media / Blogging">
                          Social-media / Blogging
                        </option>
                        <option value="Create Content">Create Content</option>
                        <option value="Providing Feedback on Products">
                          Providing Feedback on Products
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="relative !m-0  flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs text-yellow-500 font-semibold">
                        Name 2-3 Instagram Handles you get inspiration from*
                      </label>
                      <textarea
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        required
                        name="insta_handlers"
                        value={fields.insta_handlers}
                        onChange={e =>
                          setFields({
                            ...fields,
                            insta_handlers: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <p className="text-gray-400">
                    (i.e. @chrisburkard, @petermckinnon Limit 30 Characters)
                  </p>

                  <div className="relative mb-1 flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs text-yellow-500 font-semibold">
                        How often do you travel?*
                      </label>
                      <select
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        name="travel"
                        value={fields.travel}
                        onChange={handleTravelChange}
                      >
                        <option value="Seldomly (Every Few Months)">
                          Seldomly (Every Few Months)
                        </option>
                        <option value="Occasionally (Once a Month)">
                          Occasionally (Once a Month)
                        </option>
                        <option value="Frequently (2-3 Times a Month)">
                          Frequently (2-3 Times a Month)
                        </option>
                        <option value="Regularly (3+ Tripes a Month)">
                          Regularly (3+ Tripes a Month)
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="relative !m-0  flex justify-center">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs text-yellow-500 font-semibold">
                        Additional Information
                      </label>
                      <textarea
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        required
                        name="additional_info"
                        value={fields.additional_info}
                        onChange={e =>
                          setFields({
                            ...fields,
                            additional_info: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="flex justify-center mt-1 ">
                    <div className="flex flex-col gap-1 w-full">
                      <label className="text-xs font-semibold text-yellow-500">
                        Attachment
                      </label>
                      <input
                        type="file"
                        className="hidden"
                        id="fileInput"
                        name="attachment"
                        accept="*"
                        ref={profilePictureRef}
                        max={10000000}
                        onChange={e => {
                          if (e.target.files?.length) {
                            const selectedFileType = e.target.files[0].type
                            const acceptedTypes = [
                              "image/jpeg",
                              "image/png",
                              "video/mp4",
                              "video/quicktime",
                            ]
                            if (acceptedTypes.includes(selectedFileType)) {
                              saveFileForPurchase(e.target.files[0])
                            }
                          }
                        }}
                      />
                      <div className="flex items-center">
                        <label
                          htmlFor="fileInput"
                          className=" border-[1px] text-xs border-gray-900/20 text-black font-normal py-2 px-4 rounded cursor-pointer"
                        >
                          Browse...
                        </label>
                        {!fields?.attachemnt?.name ? (
                          <span className="ml-3 text-yellow-500 text-bold text-sm">
                            No file is selected
                          </span>
                        ) : (
                          <span className="ml-3 text-yellow-500 text-bold text-sm">
                            {fields?.attachemnt?.name}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full justify-center items-center">
                    <p className="text-yellow-500">
                      By clicking submit you agree to the{" "}
                      <span className="text-blue-600 cursor-pointer">
                        terms and conditions
                      </span>
                      and confirm that the information provided is accurate.
                    </p>
                  </div>

                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className=" text-white px-[150px] opacity-90 hover:opacity-100 transition-opacity py-4 font-sans  rounded-md mt-10 bg-paloverde font-bold"
                    >
                      Submit
                    </button>
                  </div>
                  {isSuccess && (
                    <p className="bg-green-400 rounded p-5 text-green-900">
                      {successMessage}
                    </p>
                  )}
                  {showError && (
                    <p className="bg-red-400 rounded p-5 text-red-900">
                      {isError}
                    </p>
                  )}
                </form>
              </div>
            </div>
          </div>
        </FormspreeProvider>
      </div>

      <div className="support-bottom-banner">
        <p>Still can't find what you're looking for?</p>

        <span>Let us Know!</span>
        <Link
          to={`/support/contact-us/${location.search ? location.search : ""}`}
        >
          Contact Us
        </Link>
      </div>
    </div>
  )
}

export default ResellerApplication
