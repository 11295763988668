import React, { useEffect, useState } from "react"
import axois from "axios"
import { functionsEndpoint } from "../../config"
import { Link } from "gatsby"

function DefaultSearch(props: any) {
  const { story, setSearchMenu, searchMenu } = props
  const [searchResults, setSearchResults] = useState([])
  const [searchLoading, setSearchLoading] = useState(false)
  const handleSearch = async (q = story?.content?.defaultSearchString) => {
    setSearchLoading(true)
    try {
      const response = await axois.post(
        functionsEndpoint + "/search",
        { q, limit: 5 },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )

      if (response.status !== 200) {
        setSearchResults([])
        return
      }

      const suggestions = response.data
      setSearchResults(suggestions)
    } catch (err: any) {
      console.error("Error fetching search suggestions:", err.message)
    } finally {
      setSearchLoading(false)
    }
  }

  useEffect(() => {
    handleSearch()
  }, [story?.content?.defaultSearchString])

  return (
    <div className="w-full">
      {searchLoading && !searchResults?.length && (
        <h1 className="text-sm text-center mt-4">Loading...</h1>
      )}
      {searchResults.map((product: any, index: number) => (
        <div
          className="flex w-full items-center justify-center mt-5"
          key={index}
        >
          <Link
            to={`/products/${product.url}`}
            className="flex items-center w-full"
            onClick={() => setSearchMenu(false)}
            style={{ height: "60px" }}
          >
            <img
              src={product.image}
              alt={product.title}
              className="w-16 h-16 object-cover"
            />
            <div className="flex flex-col items-start self-center justify-center ml-2 !p-0">
              <h1 className="text-sm font-normal m-1">{product.title}</h1>
              <div className="flex items-center">
                <p className="text-xs font-bold ml-1">${product.price_min}</p>
                <p className="line-through pl-3 italic text-xs text-[#ababab] font-bold">
                  {product?.compare_at_price_max > 0
                    ? `$${product?.compare_at_price_max}`
                    : null}
                </p>
              </div>
            </div>
          </Link>
        </div>
      ))}
      {searchResults?.length ? (
        <Link
          to={`/search?q=${story?.content?.defaultSearchString}`}
          onClick={() => setSearchMenu(false)}
          className="cursor-pointer"
        >
          <h1 className="underline underline-offset-4 text-sm text-center mt-5 mb-0">
            See all results
          </h1>
        </Link>
      ) : null}
    </div>
  )
}

export default DefaultSearch
