/* eslint-disable */
import React from "react"
import { render } from "storyblok-rich-text-react-renderer"
import Accordion from "../Accordion"

const LinkItem = ({ blok }) => {
  const { Title, Data } = blok

  return (
    <div>
      <Accordion title={Title} content={render(Data)} />
    </div>
  )
}

export default LinkItem
