// extracted by mini-css-extract-plugin
export var abstractWire = "whoWeAre-module--abstract-wire--8bcf0";
export var abstractWireCollaborative = "whoWeAre-module--abstract-wire-collaborative--494df";
export var abstractWireOurTeam = "whoWeAre-module--abstract-wire-our-team--f682e";
export var abstractWireSustainability = "whoWeAre-module--abstract-wire-sustainability--0d2e4";
export var abstractWireWhoWeAre = "whoWeAre-module--abstract-wire-who-we-are--3ad94";
export var bg1 = "whoWeAre-module--bg-1--08aad";
export var bg2 = "whoWeAre-module--bg-2--89f42";
export var bg3 = "whoWeAre-module--bg-3--be3dc";
export var bg4 = "whoWeAre-module--bg-4--ca950";
export var bottom = "whoWeAre-module--bottom--b4039";
export var content = "whoWeAre-module--content--5ee3c";
export var data = "whoWeAre-module--data--64560";
export var fullWidth = "whoWeAre-module--full-width--5836a";
export var icon = "whoWeAre-module--icon--8b1b9";
export var innovations = "whoWeAre-module--innovations--755b9";
export var item = "whoWeAre-module--item--f5903";
export var itemFeatures = "whoWeAre-module--item-features--6b3a0";
export var itemName = "whoWeAre-module--item-name--b493c";
export var lazyloadAni = "whoWeAre-module--lazyload-ani--e804a";
export var learnMore = "whoWeAre-module--learn-more--f5242";
export var leftSection = "whoWeAre-module--left-section--87f27";
export var leftSide = "whoWeAre-module--left-side--d0cc1";
export var memberImageContainer = "whoWeAre-module--member-image-container--f8ffd";
export var name = "whoWeAre-module--name--99482";
export var ourProductsContainer = "whoWeAre-module--our-products-container--44e5c";
export var ourTeamContainer = "whoWeAre-module--our-team-container--4fe2d";
export var productImageContainer = "whoWeAre-module--product-image-container--2f3f4";
export var productListingSection = "whoWeAre-module--product-listing-section--cdc51";
export var productListingSectionContainer = "whoWeAre-module--product-listing-section-container--ef94b";
export var reducingOurImpactContainer = "whoWeAre-module--reducing-our-impact-container--f0e68";
export var rightSection = "whoWeAre-module--right-section--01fad";
export var rightSide = "whoWeAre-module--right-side--29a6b";
export var rightSideContent = "whoWeAre-module--right-side-content--85ee2";
export var sectionFeature = "whoWeAre-module--section-feature--0e3fc";
export var sectionTitle = "whoWeAre-module--section-title--b111f";
export var sidewayTag = "whoWeAre-module--sideway-tag--5816f";
export var teamContainer = "whoWeAre-module--team-container--aa93f";
export var teamSection = "whoWeAre-module--team-section--db4de";
export var top = "whoWeAre-module--top--b3c75";
export var whoWeAre = "whoWeAre-module--who-we-are--40ae9";
export var whoWeAreContainer = "whoWeAre-module--who-we-are-container--58829";
export var whoWeAreFeature = "whoWeAre-module--who-we-are-feature--a8c63";
export var whoWeAreListingSectionContainer = "whoWeAre-module--who-we-are-listing-section-container--22f37";
export var whoWeAreTitle = "whoWeAre-module--who-we-are-title--3fac4";