/* eslint-disable */

/* eslint-disable camelcase */
import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

const FlagshipNavBuyNow = ({ logo_path, show_buy }) => {
  const location = useLocation()

  return (
    <Link
      to={`${logo_path}/${location.search ? location.search : ""}`}
      className={`relative w-[120px] h-[45px] bg-paloverde text-sm text-white text-center px-2 py-[14px] mx-5 font-bold z-4 ${
        show_buy ? "" : "hidden"
      }`}
    >
      Buy Now
    </Link>
  )
}

export default FlagshipNavBuyNow

FlagshipNavBuyNow.propTypes = {
  logo_path: PropTypes.string,
  show_buy: PropTypes.bool,
}

FlagshipNavBuyNow.defaultProps = {
  logo_path: "#",
  show_buy: false,
}
