/* eslint-disable */
import React, { useEffect, useRef, useState } from "react"
import { Link, navigate } from "gatsby"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import useAuth from "../../hooks/useAuth"
import { useLocation } from "@reach/router"

import "../../styles/profile.scss"
import LockedIcon from "../../assets/LockedIcon"
import productShopifyIdDecoder from "../../utils/shopifyProductIdDecoder"
import { FaRegTimesCircle } from "react-icons/fa"
import { HiUpload } from "react-icons/hi"
import { functionsEndpoint } from "../../config"
import axiosLib from "axios"

import FormError from "../FormError"

const isBrowser = typeof window !== "undefined"

const CustomerAccountInfo = () => {
  const [loading, setLoading] = useState(false)
  const { auth, setAuth } = useAuth()
  const location = useLocation()
  const privateAxios = useAxiosPrivate()
  const [msg, setMsg] = useState("")
  const profilePictureRef = useRef(null)
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    birthday: "",
    phone: "",
    profile: "",
  })

  const fetchData = async () => {
    try {
      const result = await axiosLib.get(functionsEndpoint + "/account", {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
      const {
        id,
        email,
        firstName,
        lastName,
        defaultAddress,
        phone,
        metafields,
      } = result.data?.customer
      const customerShopifyId = productShopifyIdDecoder(id)
      const birthday = metafields?.edges?.filter?.(
        metafield => metafield?.key === "birthDate",
      )
      const birthdayStr =
        birthday?.length > 0
          ? birthday?.[0]?.value
              ?.replace?.(/-/g, "/")
              ?.toDateString?.()
              ?.substr?.(4)
          : "---"
      setAuth({
        ...auth,
        id: customerShopifyId,
        email: email,
        firstName: firstName,
        lastName: lastName,
        defaultAddress: defaultAddress,
        phone: phone,
        birthday: birthdayStr,
      })
    } catch (err) {
      console.error("error retrieving data", err.message)
      if (!err?.response) {
        console.error("No Server Response")
      } else if (err.response?.status === 400) {
        console.error("Bad Request")
      } else if (err.response?.status === 401) {
        console.error("Unauthorized")
      } else if (err.reponse?.status === 403) {
        console.error("Forbidden")
      } else if (err.response.status === 500) {
        console.error(
          `Internal server error occured. Message from server: ${err.message}`,
        )
      } else {
        console.error(
          `Unknown error occured. Message from server: ${err.message}`,
        )
      }
      if (isBrowser) {
        localStorage.setItem("loggedIn", "false")
        navigate(`/login/${location.search ? location.search : ""}`)
      }
    }
  }

  useEffect(() => {
    if (!auth.accessToken || !auth.email) {
      fetchData()
    }
  }, [auth?.accessToken, auth?.email])

  useEffect(() => {
    if (auth?.firstName) {
      setProfile({
        firstName: auth.firstName,
        lastName: auth.lastName,
        email: auth.email,
        birthday: auth.birthday,
        phone: auth.phone,
        profile: auth.profile,
      })
    }
  }, [auth])

  const formatPhoneNumber = input => {
    const phoneNumber = input.replace(/\D/g, "")

    let formattedPhoneNumber = ""
    if (phoneNumber.length >= 1) {
      formattedPhoneNumber += "+" + phoneNumber.slice(0, 1)
    }
    if (phoneNumber.length >= 2) {
      formattedPhoneNumber += " " + phoneNumber.slice(1, 4)
    }
    if (phoneNumber.length >= 5) {
      formattedPhoneNumber += " " + phoneNumber.slice(4, 7)
    }
    if (phoneNumber.length >= 8) {
      formattedPhoneNumber += " " + phoneNumber.slice(7, 11)
    }
    return formattedPhoneNumber.trim()
  }

  const handleChangePhone = e => {
    const formattedPhone = formatPhoneNumber(e.target.value)
    setProfile(prevProfile => ({
      ...prevProfile,
      phone: formattedPhone,
    }))
  }

  const submitHandler = async event => {
    event.preventDefault()
    setLoading(true)
    if (profile?.profile?.name) {
      const file = profile?.profile
      var reader = new FileReader()
      reader.onloadend = async function () {
        const payload = {
          uploaderType: "profile",
          customerId: auth.id,
          rawImageData: reader.result,
          photoName: profile?.profile?.name,
        }

        try {
          await axiosLib.post(functionsEndpoint + "/update-photo", payload, {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          })
        } catch (error) {
          console.error("error", error)
        }
      }
      reader.readAsDataURL(file)
    }
    const tempProfile = { ...profile }
    delete tempProfile.profile
    await axiosLib
      .post(functionsEndpoint + "/edit-account", tempProfile, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async result => {
        if (result.data) {
          await axiosLib
            .get(functionsEndpoint + "/account", {
              withCredentials: true,
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then(result => {
              const {
                id,
                email,
                firstName,
                lastName,
                defaultAddress,
                phone,
                metafields,
              } = result.data?.customer
              const customerShopifyId = productShopifyIdDecoder(id)
              setAuth?.({
                ...auth,
                id: customerShopifyId,
                email: email,
                firstName: firstName,
                lastName: lastName,
                defaultAddress: defaultAddress,
                phone: phone,
              })
            })

            .catch(err => {
              console.error("error retrieving data", err.message)
              if (!err?.response) {
                console.error("No Server Response")
              } else if (err.response?.status === 400) {
                console.error("Bad Request")
              } else if (err.response?.status === 401) {
                console.error("Unauthorized")
              } else if (err.reponse?.status === 403) {
                console.error("Forbidden")
              } else if (err.response.status === 500) {
                console.error(
                  `Internal server error occured. Message from server: ${err.message}`,
                )
              } else {
                console.error(
                  `Unknown error occured. Message from server: ${err.message}`,
                )
              }
            })
          setLoading(false)
          setTimeout(() => {
            navigate(-1)
          }, 3000)
        }
      })
      .catch(err => {
        const isPhoneError =
          err?.response?.data?.message ===
          "update failed: Enter a valid phone number"
        if (isPhoneError) {
          setMsg("Enter a valid phone number")
        } else {
          setMsg(err.message || "An error occoured")
        }
        if (!err?.response) {
          console.error("No Server Response")
        } else if (err.response?.status === 400) {
          console.error("Bad Request")
        } else if (err.response?.status === 401) {
          console.error("Unauthorized")
        } else if (err.reponse?.status === 403) {
          console.error("Forbidden")
        } else if (err.response.status === 500) {
          console.error(
            `Internal server error occured. Message from server: ${err.message}`,
          )
        } else {
          console.error(
            `Unknown error occured. Message from server: ${err.message}`,
          )
        }
      })
  }

  const handleInputClick = event => {
    profilePictureRef.current.click()
  }
  return (
    <div className="flex flex-col min-h-screen w-full lg:justify-center items-center">
      <div className="flex justify-between items-center px-6 2xl:px-0 w-full pb-1 border-b-4 border-black max-w-4xl">
        <div className="flex items-center">
          <p className="text-lg text-obsidian font-extrabold mb-0">
            EDIT ACCOUNT INFO
          </p>
        </div>
        <Link to={`/account/${location.search}`}>
          <FaRegTimesCircle className="w-6 h-6 text-obsidian hover:text-paloverde" />
        </Link>
      </div>
      {msg?.length ? <FormError msg={msg} /> : null}
      <form
        onSubmit={submitHandler}
        className="flex flex-col items-center w-full px-6 2xl:px-0 lg:max-w-4xl"
      >
        <div className="mt-4 flex flex-col lg:flex-row justify-between items-center w-full space-y-4 lg:space-y-0 lg:space-x-4">
          <div className="border-2 border-black px-3 pt-2 w-full">
            <p className="mb-0">First Name</p>
            <input
              placeholder="John"
              required
              value={profile?.firstName}
              onChange={e =>
                setProfile({ ...profile, firstName: e.target.value })
              }
              className="w-full pb-2 bg-transparent"
            />
          </div>
          <div className="border-2 border-black px-3 pt-2 w-full">
            <p className="mb-0">Last Name</p>
            <input
              placeholder="Doe"
              required
              value={profile?.lastName}
              onChange={e =>
                setProfile({ ...profile, lastName: e.target.value })
              }
              className="w-full pb-2 bg-transparent"
            />
          </div>
        </div>
        <div className="mt-4 flex justify-between items-center w-full space-x-4">
          <div className="border-2 border-black pl-3 w-full cursor-not-allowed">
            <div className="w-full flex justify-between items-start">
              <div className="w-full pt-2">
                <p className="mb-0">Email</p>
                <input
                  placeholder="johndoe@mail.com"
                  type="email"
                  value={profile?.email}
                  disabled
                  required
                  className="w-full pb-2 bg-transparent"
                />
              </div>
              <div className="border-l-2 border-[#1C1C1C] w-14 h-[70.5px] flex justify-center items-center">
                <LockedIcon width={20} />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 flex justify-between items-center w-full space-x-4">
          <div className="border-2 border-black px-3 pt-2 w-full">
            <p className="mb-0">Birthday (dd/mm/yyyy)</p>
            <input
              placeholder="00/00/0000"
              type="date"
              className="w-full pb-2 bg-transparent"
              value={profile?.birthday}
              onChange={e =>
                setProfile({ ...profile, birthday: e.target.value })
              }
            />
          </div>
        </div>
        <div className="mt-4 flex justify-between items-center w-full space-x-4">
          <div className="border-2 border-black px-3 pt-2 w-full">
            <p className="mb-0">Phone Number (+0 000 000 0000)</p>
            <input
              placeholder="+0 000 000 0000"
              type="text"
              name="phone"
              required
              className="w-full pb-2 bg-transparent"
              value={formatPhoneNumber(profile?.phone || "")}
              onChange={handleChangePhone}
            />
          </div>
        </div>
        <div className="mt-4 flex justify-between items-center w-full space-x-4">
          <div
            onClick={handleInputClick}
            className="border-2 border-black pr-3 w-full"
          >
            <div className="w-full flex justify-between items-start">
              <div className="border-r-2 border-[#1C1C1C] w-14 h-[92px] sm:h-[76.5px] flex justify-center items-center">
                <HiUpload width={20} />
              </div>
              <div className="w-full pt-2 pl-3">
                <p className="mb-0">
                  Profile Image (Supports JPG, JPEG, PNG | Max Size 2MB)
                </p>
                <input
                  type="file"
                  ref={profilePictureRef}
                  className="w-full pb-2 bg-transparent"
                  accept="image/*"
                  size={20000000}
                  style={{ display: "none" }}
                  onChange={e =>
                    setProfile({ ...profile, profile: e.target.files[0] })
                  }
                />
                <p className="mb-0 mt-1">
                  {profile?.profile?.name || "Upload Profile Image"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <button className="flex justify-center items-center text-11 mt-6 tracking-widest min-w-200px w-full bg-obsidian text-white p-2 font-bold font-expanded uppercase hover:bg-paloverde mb-4">
          {loading ? (
            <div role="status">
              <svg
                aria-hidden="true"
                class="w-8 h-8 mr-2 text-white animate-spin dark:text-gray-600 fill-white"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            "SAVE"
          )}
        </button>
      </form>
    </div>
  )
}

export default CustomerAccountInfo
