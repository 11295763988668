/* eslint-disable  */

import React, { FC } from "react"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"

const isBrowser = typeof window !== "undefined"
interface Props {
  children: JSX.Element
}

const RequireAuth: FC<RoutedHTMLAttr> = ({
  component: Component,
  location,
  ...rest
}) => {
  if (isBrowser && !JSON.parse(localStorage.getItem("loggedIn") ?? "false")) {
    navigate(`/login/${location.search ? location.search : ""}`)
    return null
  }

  return <Component {...rest} />
}

export default RequireAuth
