/* eslint-disable */
import React, { useState } from "react"
import { render } from "storyblok-rich-text-react-renderer"
import { FaRegTimesCircle } from "react-icons/fa"

const DynamicPromoBanner = ({ blok }) => {
  const [active, setActive] = useState(true)

  return (
    <section
      className={`w-full h-[41px] t-[48px] text-center bg-paloverde flex flex-col justify-center items-center ${
        active ? "block" : "hidden"
      }`}
    >
      <p className="uppercase text-xxs text-white font-light mb-1 tracking-widest">
        {/* {blok.exclusive} */}
        <FaRegTimesCircle
          className="absolute right-[15px] md:right-[6vw] xl:right-[132px] top-2.5 w-4 h-4 hover:text-obsidian cursor-pointer"
          onClick={() => setActive(false)}
        />
      </p>
      <p className="uppercase bold text-white inline-flex text-xs md:text-sm mb-0 font-condensed font-semibold">
        {blok.exclusive}&nbsp;
        <span className="font-normal">{render(blok.content)}</span>
      </p>
      {/* <p className="text-white text-xxs mb-0 tracking-widest font-light">{render(blok.conditional)}</p> */}
    </section>
  )
}

export default DynamicPromoBanner
