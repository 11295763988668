/* eslint-disable */
import React, { useEffect, useRef, useState } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Seo from "../../components/seo"
import "../../styles/focus.scss"
import EpisodeListS2 from "../../components/focus/episodeListS2"
import EpisodeListS1 from "../../components/focus/episodeListS1"
import EpisodeListPPOriginal from "../../components/focus/episodeListPPOriginal"
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"
import loadable from "@loadable/component"
import PlayIcon from "../../assets/PlayIcon"
import SecondaryButton from "../../components/SecondaryButton"

const ModalVideo = loadable(() => import("react-modal-video"))

const FocusPage = () => {
  // Query focus data

  const carouselRefOne = useRef(null)
  const carouselRefTwo = useRef(null)
  const carouselRefThree = useRef(null)

  const [currentSlideOne, setCurrentSlideOne] = useState(0)
  const [currentSlideTwo, setCurrentSlideTwo] = useState(0)
  const [currentSlideThree, setCurrentSlideThree] = useState(0)

  const [openModal, setOpenModal] = useState()

  const slideButton = (ref, hideLeft) => {
    return (
      <div className="slide-container">
        <button
          style={{ opacity: hideLeft ? 0 : 1 }}
          onClick={() => ref.current.previous()}
          className="left"
        >
          <FaArrowLeft fontSize={20} color="white" />
        </button>
        <button onClick={() => ref.current.next()} className="right">
          <FaArrowRight fontSize={20} color="white" />
        </button>
      </div>
    )
  }

  return (
    <>
      <Seo title="Shot With PolarPro" />
      <div>
        <div className="feature-container">
          <div className="header-container">
            <h2 className="font-black">
              Shot With <span>PolarPro</span>
            </h2>
            <p>
              Captured and produced with the precision and clarity of PolarPro
              optics.
            </p>
          </div>

          <div className="feature">
            <div className="latest">
              <div className="feature-video">
                <div
                  className="play-container hover-container"
                  onClick={() => setOpenModal("438301171")}
                >
                  <a
                    href="#data"
                    id="inline"
                    className="play-button x-y-center"
                  />
                </div>
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/articles/Episode-06-Ted-Hesser_1024x1024.jpg"
                  alt=""
                />
              </div>

              <div className="feature-data">
                <p className="episode">EP.07 | S.2</p>
                <h3 className="font-black">The Climb</h3>
                <p className="article-blurp">
                  After building a name for himself for his ability to capture
                  extraordinary imagery and stories, Ted Hesser continues to
                  wrestle with the challenge of remaining on top of his creative
                  mindset when in extremely high-risk environments.
                </p>

                <SecondaryButton
                  text="View All Episodes"
                  link="#episodes-listing"
                  buttonStyle="white"
                />
                <a
                  href="#data"
                  onClick={() => setOpenModal("438301171")}
                  className="view-all-ep font-black md:hidden"
                >
                  <PlayIcon color="white" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div id="episodes-listing">
          <div className="content-container">
            <div className="focus-s2 episode-section">
              <div className="desc">
                <h2 className="font-black">
                  Focus: Season <span className="orange">Two</span>
                </h2>
                <p>
                  A portrait of industry professionals who go above and beyond
                  in their craft examining the lengths creators go to achieve
                  their standards — the obsession, the dedication, and the
                  sacrifice.
                </p>
                {slideButton(carouselRefOne, currentSlideOne === 0)}
              </div>

              <div className="slide">
                <EpisodeListS2
                  carouselRef={carouselRefOne}
                  afterChange={(previousSlide, { currentSlide }) => {
                    setCurrentSlideOne(currentSlide)
                  }}
                  onClickPlay={value => setOpenModal(value)}
                />
              </div>

              <div className="swipe-for-more">
                [ SWIPE TO VIEW MORE EPISODES ]
              </div>
            </div>
          </div>

          <div className="content-container focus-background">
            <div className="focus-s1 episode-section">
              <div className="desc">
                <h2 className="font-black white">
                  Focus: Season <span className="orange">One</span>
                </h2>
                <p className="white">
                  PolarPro’s original 12-part documentary series follows
                  renowned content creators around the globe as they journey to
                  pursue the perfect image. Told from the eye of the
                  storyteller, follow these extraordinary artists as they travel
                  the world, uniting exploration, photography and nature.
                </p>
                {slideButton(carouselRefTwo, currentSlideTwo === 0)}
              </div>
              <div className="slide">
                <EpisodeListS1
                  carouselRef={carouselRefTwo}
                  afterChange={(previousSlide, { currentSlide }) => {
                    setCurrentSlideTwo(currentSlide)
                  }}
                  onClickPlay={value => setOpenModal(value)}
                />
              </div>

              <div className="swipe-for-more">
                [ SWIPE TO VIEW MORE EPISODES ]
              </div>
            </div>
          </div>

          <div className="content-container">
            <div className="pp-original episode-section">
              <div className="desc">
                <h2 className="font-black">
                  PolarPro <span className="orange">Originals</span>
                </h2>
                <p>
                  Explore from our curated catalog of videos created from the
                  top visual storytellers of our generation who share their
                  creativity through inspiration, education and empowerment.
                </p>
                {slideButton(carouselRefThree, currentSlideThree === 0)}
              </div>
              <div className="slide">
                <EpisodeListPPOriginal
                  carouselRef={carouselRefThree}
                  afterChange={(previousSlide, { currentSlide }) => {
                    setCurrentSlideThree(currentSlide)
                  }}
                  onClickPlay={value => setOpenModal(value)}
                />
              </div>

              <div className="swipe-for-more">
                [ SWIPE TO VIEW MORE EPISODES ]
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalVideo
        channel="vimeo"
        videoId={openModal}
        isOpen={Boolean(openModal)}
        onClose={() => setOpenModal(null)}
      />
    </>
  )
}

export default FocusPage
