import React from "react"
import PropTypes from "prop-types"

const QuartzlineFXTitle = ({ blok }) => (
  <div className="relative w-full text-center mt-20">
    <h2 className="w-[45%] uppercase display-font m-auto">
      <strong className="display-font-logo">{blok.strong_title} </strong>
      {blok.normal_title}
    </h2>
  </div>
)

export default QuartzlineFXTitle

QuartzlineFXTitle.propTypes = {
  blok: PropTypes.shape({
    strong_title: PropTypes.string,
    normal_title: PropTypes.string,
  }),
}

QuartzlineFXTitle.defaultProps = {
  blok: PropTypes.shape({
    strong_title: "",
    normal_title: "",
  }),
}
