import React, { useState } from "react"
import { RiArrowDownSFill } from "react-icons/ri"
type Props = {
  values: string[]
  name: string
  id: string
  index: number
  selectedVariant: any
  handleOptionChange: (index: any, event: any) => void
}
const SelectOption = (props: Props) => {
  const { handleOptionChange, id, index, name, values, selectedVariant } = props
  const selected = selectedVariant?.selectedOptions?.filter(
    (item: any) => item.name === name,
  )?.[0]?.value
  if (typeof location === "undefined") return null
  return (
    <div className="flex flex-col items-start w-full">
      <p className="font-medium text-[#1C1C1C] text-sm w-2/5 m-0 mt-4 mr-0">
        {name}
      </p>
      <div
        className="selectVariant font-normal  relative border-[1px] border-[#1C1C1C] rounded-[53px] m-0 mt-1 w-full pointer"
        key={id}
      >
        <select
          aria-label="Variants"
          className="relative font-green-900 font-normal appearance-none pt-2 bg-transparent border-none text-obsidian pointer text-sm font-light m-0 pl-2 py-1 w-full"
          onChange={event => handleOptionChange(index, event)}
          value={location.href.includes("?variant") ? selected : ""}
          defaultValue=""
        >
          {values
            ?.filter((value, index, self) => self.indexOf(value) === index)
            .map((value: any) => (
              <option value={value} className="p-2" key={`${name}-${value}`}>
                {value}
              </option>
            ))}
        </select>
        <span className="block bg-no-repeat absolute top-1/2 right-2 -translate-y-1/2">
          <RiArrowDownSFill size={"28px"} />
        </span>
      </div>
    </div>
  )
}
export default SelectOption
