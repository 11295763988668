/* eslint-disable */
import React, { useState } from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

// import {
//   active,
// } from './style.module.scss';

const FlagshipNavMenuItem = ({ blok }) => {
  const location = useLocation()

  const { link, label, gold_background } = blok

  const [isBuyBtn] = useState(label.toLowerCase().includes("buy"))

  return (
    <Link
      to={`${link}/${location.search ? location.search : ""}`}
      className={`${gold_background ? "md:hover:bg-paloverde-light" : ""} ${
        isBuyBtn ? "hidden md:block" : ""
      } h-[50px] md:h-full w-full`}
    >
      <li
        className={`marker:hidden marker:text-[0px] md:inline-blockh-[50px] w-full md:h-full pt-[15px] px-4 m-0 text-center text-base md:text-sm md:font-condensed font-bold ${
          gold_background
            ? "md:bg-paloverde md:text-white md:hover:bg-paloverde-light"
            : "md:hover:text-paloverde"
        }`}
      >
        {label}
      </li>
    </Link>
  )
}

export default FlagshipNavMenuItem
