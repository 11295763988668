/* eslint-disable */
// @ts-nocheck

import React, { useEffect, useState, useContext } from "react"

import { FaRegTimesCircle, FaCaretDown } from "react-icons/fa"

import CartDrawerCard from "../CartDrawerCard"
import CartDrawerProductCard from "../CartDrawerProductCard"
import NewArrivals from "../../images/Cart-NewArrivals.jpg"
import ProSystems from "../../images/Cart-HelixMaglock.jpg"
import Filters from "../../images/Cart-Filters.jpg"
import Gear from "../../images/Cart-Gear.jpg"
import Mobile from "../../images/Cart-Mobile.jpg"
import "../../styles/cart.scss"
import useCheckout from "../../hooks/useCheckout"
import { Link, useLocation } from "@reach/router"
import { StoreContext } from "../../context/StoreContext"
import SuggestedProductCard from "../SuggestedProductCard"

export interface Props {
  isMenuActive?: boolean
  onClose?: () => void
}

const fromRange = 0
const toRange = 2

export const CartSections = [
  {
    title: "New Arrivals",
    description: "Explore our latest innovations.",
    image: NewArrivals,
    link: "/collections/new-arrivals",
  },
  {
    title: "Helix Maglock",
    description: "Helix Maglock Filters.",
    image: ProSystems,
    link: "/collections/helix-filters",
  },
  {
    title: "Filters",
    description: "Explore our full line of filters.",
    image: Filters,
    link: "/collections/filters",
  },
  {
    title: "Mobile",
    description: "Maximize your mobile creative experience.",
    image: Mobile,
    link: "/collections/mobile",
  },
  {
    title: "Gear",
    description: "Industry leading tools + accessories.",
    image: Gear,
    link: "/collections/gear",
  },
]

const CartDrawer = ({ isMenuActive, onClose, cartRef }: Props) => {
  const { addToCartSidebarType, contextRecommendedProducts } =
    useContext(StoreContext)

  const location = useLocation()
  const [showItems, setShowItems] = useState(true)
  const [menuActive, setMenuActive] = useState(false)
  const [updatedCurrencyExchangedPrice, setUpdatedCurrencyExchangedPrice] =
    useState(null)
  const [userCurrency, setUserCurrency] = useState(null)

  useEffect(() => {
    setMenuActive(!!isMenuActive)
  }, [isMenuActive])

  const queryString = location.search

  const { checkout, handleCheckout, emptyCart } = useCheckout()

  const onCheckout = async () => {
    const checkoutUrl = await handleCheckout(queryString)
    const tag = document.createElement("a")
    tag.href = checkoutUrl
    tag.target = "_self"
    document.body.appendChild(tag)
    tag.click()
    document.body.removeChild(tag)
  }

  const ItemToShow = () => {
    setShowItems(!showItems)
  }

  // const handleUpdateCart = price => {
  //   const priceAsNumber = parseFloat(price)
  //   const roundedToTwoDecimalPlaces = priceAsNumber.toFixed(2)
  //   const backToNumber = parseFloat(roundedToTwoDecimalPlaces)
  //   setUpdatedCurrencyExchangedPrice(backToNumber)
  // }

  // const Currency = currency => {
  //   setUserCurrency(currency)
  // }

  return (
    <div
      ref={cartRef}
      className={`cart-close fixed top-0 bottom-0 min-h-screen w-full md:w-auto md:max-w-[500px] z-[9999999999] flex flex-col ${menuActive ? "right-0" : "right-[-120vw]"
        } duration-200 bg-white overflow-y-scroll md:overflow-y-auto md:overflow-visible 
  shadow-md lg:shadow-none rounded-l-lg`} // menuActive ? "lg:min-w-[44.7%]" : ""
    >
      {addToCartSidebarType === "basket" ? (
        <div className="bg-[#F6F6F6] px-5 py-7 flex justify-between items-center">
          <div className="flex items-center">
            <p className="text-lg text-obsidian font-extrabold mb-0 ml-3">
              Shopping Cart
            </p>
          </div>
          <button onClick={() => onClose?.()}>
            <FaRegTimesCircle className="w-6 h-6 text-obsidian hover:text-paloverde" />
          </button>
        </div>
      ) : (
        <div className="bg-[#F6F6F6] px-5 py-7">
          <div className="flex justify-end">
            <button onClick={() => onClose?.()}>
              <FaRegTimesCircle className="w-4 h-4 text-obsidian hover:text-paloverde" />
            </button>
          </div>
          <div className="flex items-center gap-2  justify-center">
            <p className="text-lg text-obsidian font-extrabold mb-0 ml-3">
              Items added to cart
            </p>
            <div className="relative mb-[8px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.436"
                height="13.002"
                viewBox="0 0 17.436 13.002"
              >
                <path
                  id="checkmark"
                  d="M5.922,17.324.255,11.657a.872.872,0,0,1,0-1.233L1.488,9.191a.872.872,0,0,1,1.233,0l3.817,3.817,8.176-8.176a.872.872,0,0,1,1.233,0L17.18,6.065a.872.872,0,0,1,0,1.233L7.155,17.324A.872.872,0,0,1,5.922,17.324Z"
                  transform="translate(0 -4.577)"
                  fill="#1c1c1c"
                />
              </svg>
            </div>
          </div>
        </div>
      )}

      <div>
        {emptyCart ? (
          <div className="mt-12 flex items-center flex-col text-center w-full">
            <h1 className="text-3xl font-extrabold mb-1">
              Your cart is empty.
            </h1>
            <p className="text-sm">
              Lets get you pointed back in the right direction:
            </p>
            <div className="w-full">
              {CartSections.map((section, idx) => (
                <CartDrawerCard {...section} key={idx} onClose={onClose} />
              ))}
            </div>
          </div>
        ) : (
          <div className="min-h-[calc(100vh-320px)]">
            <div>
              {checkout.lineItems.map(item => (
                <CartDrawerProductCard
                  item={item}
                  key={item.id}
                  onClose={onClose}
                // onUpdateCart={handleUpdateCart}
                // Currency={Currency}
                />
              ))}
            </div>
            <div className="text-[10px] italic !leading-[10px] text-center text-[#8a8a8a] py-4 px-9 mb-2 ">
              *Please note, reserve items will prevent in-stock items in your
              cart from shipping. All orders that include reserve items will
              ship when the item(s) are in stock.*
            </div>
            <div className="border-t-[1px] border-gray-200 pt-2">
              <div className="flex items-center justify-center flex-col pt-4 px-5">
                <div className="flex items-center justify-between w-full">
                  <p className="text-md mb-0 font-bold">Cart Subtotal</p>
                  <p className="text-md mb-0 font-bold">
                    {/* {userCurrency &&
                    updatedCurrencyExchangedPrice &&
                    userCurrency !== "USD"
                      ? userCurrency + " "
                      : "$"}
                    {updatedCurrencyExchangedPrice
                      ? updatedCurrencyExchangedPrice
                      : checkout?.subtotalPriceV2?.amount} */}
                    {`$${checkout?.subtotalPriceV2?.amount}`}
                  </p>
                </div>
              </div>
              <div className="mt-6 flex justify-center gap-2 px-10">
                <button
                  onClick={() => onClose()}
                  style={{ cursor: emptyCart ? "not-allowed" : "pointer" }}
                  className="flex justify-center items-center text-11 tracking-widest  w-full bg-white rounded-md text-obsidian border-[1px] border-black p-2 font-bold font-expanded uppercase  mb-4"
                >
                  Continue Shopping
                </button>
                <button
                  onClick={onCheckout}
                  style={{ cursor: emptyCart ? "not-allowed" : "pointer" }}
                  className="text-11 tracking-widest  w-full bg-obsidian rounded-md text-white p-2 font-bold font-expanded uppercase hover:bg-paloverde mb-4"
                >
                  Checkout ({checkout?.lineItems?.length})
                </button>
              </div>
            </div>
            {contextRecommendedProducts?.length > 0 && (
              <div className="py-4 mt-4 bg-[#F6F6F6]">
                <div className=" flex justify-center  gap-2 my-3">
                  <div className="flex item-center" onClick={ItemToShow}>
                    <FaCaretDown
                      className={`${showItems ? "rotate-180" : ""
                        } w-4 h-4 mt-1`}
                    />
                  </div>
                  <p className="text-lg text-center font-extrabold text-obsidian font-bold">
                    {" "}
                    Did you forget anything?
                  </p>
                </div>
                {showItems && (
                  <div>
                    {contextRecommendedProducts &&
                      contextRecommendedProducts
                        ?.slice(fromRange, toRange)
                        ?.map((item, index) => {
                          return (
                            <SuggestedProductCard item={item} index={index} />
                          )
                        })}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default CartDrawer
