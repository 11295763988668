/* eslint-disable */
import React, { useState, useEffect } from "react"
// import { Link } from "gatsby";
import loadable from "@loadable/component"
import * as style from "./style.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import { useStoryblok } from "../../utils/storyblok"
import DynamicComponents from "../../components/DynamicComponents"
import Lc14Visualizer from "../Dopple/Lc14Visualizer"
import useScrollBlock from "../../hooks/useScrollBlock"
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css"

const ModalVideo = loadable(() => import("react-modal-video"))

const BelowLcp14Overview = () => {
  const [showVideo, setShowVideo] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  }

  const scrollUp = e => {
    e.preventDefault()
    window.scrollTo({
      top: 0,
      behaviour: "smooth",
    })
  }

  useEffect(() => {
    if (showVideo) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [showVideo])

  const pageData = useStaticQuery(graphql`
    query {
      storyblokEntry(
        full_slug: { eq: "/products/litechaser-pro-iphone-14-pro-pro-max" }
      ) {
        id
        name
        content
        slug
        full_slug
        uuid
        created_at
      }
      shopifyProduct(handle: { eq: "litechaser-pro-iphone-14-pro-pro-max" }) {
        handle
        title
      }
    }
  `)

  const product = pageData.shopifyProduct

  const story = pageData.storyblokEntry
    ? useStoryblok(pageData.storyblokEntry)
    : { content: null }

  const stickyNavComponent =
    story.content && story.content["sticky_nav"]
      ? story.content["sticky_nav"].map(blok => (
          <>
            {blok ? (
              <div {...sbEditable(blok)} className="sticky top-0 z-50">
                <DynamicComponents
                  blok={blok}
                  product={product}
                  key={blok._uid}
                />
              </div>
            ) : null}
          </>
        ))
      : null

  return (
    <div className={style.lcp14Overview}>
      <p
        className={`${style.sectionTitle} display-font-logo font-bold section-title text-obsidian mt-20`}
      >
        <span className="text-obsidian">Explore LiteChaser Pro 14</span>
      </p>
      <div className={style.productHero}>
        <div class="intro-data justify-center flex-col flex left-0 lg:left-24 relative w-full lg:w-[28vw] text-center lg:text-left">
          <h2 className="display-font-logo font-bold text-white text-4xl md:text-5xl m-0">
            LiteChaser Pro 14
          </h2>
          <p className="text-white text-xs md:text-sm font-expanded">
            The ultimate camera rig for iPhone<sup>®</sup> 14 Pro/Pro Max.
          </p>
        </div>
      </div>
      <div className={style.videoContainer}>
        <div className={style.thumb}>
          <a
            href="#data"
            id="inline"
            className="cta-round play-button x-y-center"
            onClick={e => {
              e.preventDefault()
              setShowVideo(true)
            }}
          />
          {/* modal */}
          <ModalVideo
            channel="youtube"
            videoId="AuMrfpan0ys"
            isOpen={showVideo}
            onClose={() => setShowVideo(false)}
          />
        </div>
      </div>

      <div className="bg-black pt-10">
        <p
          className={`${style.sectionTitleOffset} display-font-logo font-bold section-title relative z-10 m-0 pt-14`}
        >
          Two Core System Kits
        </p>

        <div className={`${style.cageCase}`}>
          <div className={`${style.cage} flex flex-col-reverse md:flex-row`}>
            <div
              className={`${style.text} w-full md:w-1/2 flex flex-col items-center justify-center py-12 md:py-0`}
            >
              <p className="font-black text-white text-3xl md:text-2xl lg:text-4xl m-0">
                New Aluminum Cage
              </p>
              <p className="text-white text-sm mt-2">
                Built to support various grip and accessory options.
              </p>
              <a
                onClick={scrollUp}
                href="#"
                class="hover:bg-[#E0B01D] bc-build-btn font-extrabold bg-paloverde text-white text-center text-md md:text-sm px-16 pt-3 pb-3 md:px-10 md:pt-2 md:pb-1"
              >
                Shop Now
              </a>
            </div>
            <div className={`${style.img} w-full md:w-1/2`}>
              <img
                className="hidden md:block m-0 w-full"
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP14-NEW-ALUMINUM-CAGE-DESKTOP.webp?v=1665161329"
              />
              <img
                className={`${style.mobileImg} block md:hidden m-0 w-full`}
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/BulletProof-Cage-m.webp?v=1664918783"
              />
            </div>
          </div>
          <div className={`${style.case} flex flex-col md:flex-row`}>
            <div className={`${style.img} w-full md:w-1/2`}>
              <img
                className="hidden md:block m-0 w-full"
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP14-MINIMALIST-CASE-DESKTOP.webp?v=1665161329"
              />
              <img
                className={`${style.mobileImg} block md:hidden m-0 w-full`}
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/Minimalist-Case-m.webp?v=1664918856"
              />
            </div>
            <div
              className={`${style.text} w-full md:w-1/2 flex flex-col items-center justify-center py-12 md:py-0`}
            >
              <p className="font-black text-white text-3xl md:text-2xl lg:text-4xl m-0">
                Minimalist Case
              </p>
              <p className="text-white text-sm mt-2">
                Built to creatively inspire mobile content creators.
              </p>
              <a
                onClick={scrollUp}
                href="#"
                class="hover:bg-[#E0B01D] bc-build-btn font-extrabold bg-paloverde text-white text-center text-md md:text-sm px-16 pt-3 pb-3 md:px-10 md:pt-2 md:pb-1"
              >
                Shop Now
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black pt-10 pb-[40px] md:pb-0">
        <p
          className={`${style.sectionTitleOffset} display-font-logo font-bold section-title relative z-10 m-0 pt-14`}
        >
          3D Configuration Visualizer
        </p>
        <Lc14Visualizer />
      </div>

      <div className={`${style.features}`}>
        <div className={`${style.lineUp}`}>
          <div
            className={`${style.data} absolute bottom-[15vw] md:bottom-[5vw] left-[5vw]`}
          >
            <p className={`${style.title} font-black text-xl md:text-4xl`}>
              Full Suite of <br />
              Creative Filters
            </p>
            <div className="list flex">
              <ul className="list-disc m-0 ml-3 text-sm">
                <li className="m-0">VND 3-5</li>
                <li className="m-0">VND 6-7</li>
                <li className="m-0">VND 3-5 Mist</li>
                <li className="m-0">VND 6-7 Mist</li>
              </ul>

              <ul className="list-disc m-0 ml-8 md:ml-12 text-sm">
                <li className="m-0">CP</li>
                <li className="m-0">Mist</li>
                <li className="m-0">BlueMorphic</li>
                <li className="m-0">GoldMorphic</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="block lg:flex flex-row md:flex-row-reverse items-center px-0">
          <div className="hidden lg:block sm:w-full w-[100vw] flex lg:w-1/3 flex-col items-left justify-center bg-cover h-[92vw] lg:h-auto lg:bg-none">
            <p className="font-black text-4xl lg:text-2xl 2xl:text-4xl px-[4vw] text-left text-white mb-2">
              BlueMorphic
            </p>
            <p className="text-left px-[4vw] text-white text-sm">
              Anamorphic-style blue streak effects.
            </p>
          </div>
          <div class="hidden lg:block lg:w-2/3">
            <widgetic
              id="634486d9ecb2a10c198b456b"
              resize="fill-width"
              width="2374"
              height="925"
              autoscale="on"
              adaptive="414"
            ></widgetic>
          </div>
          <div class="block lg:hidden sm:w-full">
            <widgetic-embed
              id="63448a0becb2a1b3198b4569"
              resize="fill-width"
              width="1284"
              height="1788"
              autoscale="on"
              adaptive="414"
            ></widgetic-embed>
          </div>
          <div className="block lg:hidden sm:w-full w-[100vw] items-left justify-center bg-cover lg:bg-none pt-8">
            <p className="font-black text-4xl lg:text-2xl 2xl:text-4xl px-[4vw] text-center text-white mb-2">
              BlueMorphic
            </p>
            <p className="text-center px-[4vw] text-white text-sm">
              Anamorphic-style blue streak effects.
            </p>
          </div>
        </div>

        <div className="block lg:flex my-10 items-center">
          <div className="hidden lg:block sm:w-full w-[100vw] flex lg:w-1/3 flex-col items-left justify-center bg-cover h-[92vw] lg:h-auto lg:bg-none">
            <p className="font-black text-4xl lg:text-2xl 2xl:text-4xl px-[4vw] text-left text-white mb-2">
              GoldMorphic
            </p>
            <p className="text-left px-[4vw] text-white text-sm">
              Anamorphic-style gold streak effects.
            </p>
          </div>
          <div class="hidden lg:block lg:w-2/3">
            <widgetic-embed
              id="633de400ecb2a130078b4567"
              resize="fill-width"
              width="2374"
              height="925"
              autoscale="on"
              adaptive="414"
            ></widgetic-embed>
          </div>
          <div class="block lg:hidden sm:w-full">
            <widgetic-embed
              id="633de944ecb2a18e098b4567"
              resize="fill-width"
              width="1284"
              height="1788"
              autoscale="on"
              adaptive="414"
            ></widgetic-embed>
          </div>
          <div className="block lg:hidden sm:w-full w-[100vw] items-left justify-center bg-cover lg:bg-none pt-8">
            <p className="font-black text-4xl lg:text-2xl 2xl:text-4xl px-[4vw] text-center text-white mb-2">
              GoldMorphic
            </p>
            <p className="text-center px-[4vw] text-white text-sm">
              Anamorphic-style gold streak effects.
            </p>
          </div>
        </div>

        <div className="block lg:flex flex-row-reverse pb-[80px] items-center">
          <div className="hidden lg:block sm:w-full w-[100vw] flex lg:w-1/3 flex-col items-left justify-center bg-cover h-[92vw] lg:h-auto lg:bg-none">
            <p className="font-black text-4xl lg:text-2xl 2xl:text-4xl px-[4vw] text-left text-white mb-2">
              Mist
            </p>
            <p className="text-left px-[4vw] text-white text-sm">
              1/4 strength diffusion.
            </p>
          </div>
          <div class="hidden lg:block lg:w-2/3">
            <widgetic-embed
              id="633de43aecb2a13d078b4567"
              resize="fill-width"
              width="2374"
              height="925"
              autoscale="on"
              adaptive="414"
            ></widgetic-embed>
          </div>
          <div class="block lg:hidden sm:w-full">
            <widgetic-embed
              id="633de98aecb2a18a098b4567"
              resize="fill-width"
              width="1284"
              height="1788"
              autoscale="on"
              adaptive="414"
            ></widgetic-embed>
          </div>
          <div className="block lg:hidden sm:w-full w-[100vw] items-left justify-center bg-cover lg:bg-none pt-8">
            <p className="font-black text-4xl lg:text-2xl 2xl:text-4xl px-[4vw] text-center text-white mb-2">
              Mist
            </p>
            <p className="text-center px-[4vw] text-white text-sm">
              1/4 strength diffusion.
            </p>
          </div>
        </div>
      </div>

      <div class={style.systemOverview}>
        <div className="newAlignment text-white justify-center items-center flex flex-col mb-20 md:mb-0">
          <p className="font-black text-3xl md:text-2xl lg:text-3xl text-center mb-0 md:mb-12">
            New Alignment System
          </p>
          <p className="block md:hidden mt-2">
            New Alignment System for quick-filter installations.
          </p>
          <img
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP14-ALIGNMENT-SYSTEM-BLOCK-DESKTOP.webp?v=1665161329"
            className="w-[85%] ml-[-15%]"
          />
          <p className="hidden md:block text-center mt-6">
            New Alignment System for quick-filter installations.
          </p>
        </div>
        <div className="momentLens text-white justify-center items-center flex flex-col">
          <p className="font-black text-3xl md:text-2xl lg:text-3xl text-center mb-0 md:mb-12">
            Moment<sup>®</sup> Lens Adaptable
          </p>
          <p className="block md:hidden text-center mt-2">
            New adapters now allow for mounting <br />
            LCP Filters to Moment® Lenses.
          </p>
          <img
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP14-MOMENT-ADAPTERS-BLOCK-DESKTOP.webp?v=1665161329"
            className="w-[85%] ml-[-15%]"
          />
          <p className="hidden md:block text-center md:mt-6">
            New adapters now allow for mounting <br />
            LCP Filters to Moment<sup>®</sup> Lenses.
          </p>
        </div>
      </div>

      <div className="cage bg-black px-[0px] md:px-[100px] mt-[-8vw] flex flex-col items-center">
        <img
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/cage-blowup.png?v=1664320631"
          className="m-0 relative z-10 hidden md:block"
        />
        <img
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/cage-blowup-m.png?v=1664929674"
          className="m-0 relative z-10 block md:hidden w-full"
        />
        <div class="image-set flex flex-col md:flex-row w-full items-center justify-center px-10 md:px-0 mt-10 md:mt-0">
          <div>
            <img
              className="m-0 pr-0 md:pr-3"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP14-CAGE-GALLERY-1-DESKTOP.webp?v=1665161329"
            />
          </div>
          <div>
            <img
              className="m-0 my-10 md:my-0 px-0 md:px-3"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP14-CAGE-GALLERY-2-DESKTOP.webp?v=1665161329"
            />
          </div>
          <div>
            <img
              className="m-0 pl-0 md:pl-3"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP14-CAGE-GALLERY-3-DESKTOP.webp?v=1665161329"
            />
          </div>
        </div>
      </div>
      <div className="case bg-black px-[0px] md:px-[100px] flex flex-col items-center">
        <img
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/case-blowup.png?v=1664320630"
          className="m-0 my-20 hidden md:block"
        />
        <img
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/case-blowup-m.png?v=1664929674"
          className="m-0 my-20 block md:hidden w-full"
        />
        <div class="image-set flex flex-col md:flex-row w-full items-center justify-center px-10 md:px-0">
          <div>
            <img
              className="m-0 pr-0 md:pr-3"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP14-CASE-GALLERY-1-DESKTOP.webp?v=1665161330"
            />
          </div>
          <div>
            <img
              className="m-0 my-10 md:my-0 px-0 md:px-3"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP14-CASE-GALLERY-2-DESKTOP.webp?v=1665161329"
            />
          </div>
          <div>
            <img
              className="m-0 pl-0 md:pl-3"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP14-CASE-GALLERY-3-DESKTOP.webp?v=1665161329"
            />
          </div>
        </div>
      </div>

      <div className="bg-black pt-20 md:pt-6">
        <p
          className={`${style.sectionTitleOffset} display-font-logo font-bold section-title relative z-10 m-0 pt-14`}
        >
          Compatibility & Usage
        </p>
        <img
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP14-COMPATIBILITY-CHART-DESKTOP.webp?v=1665161330"
          className="bw-img hidden md:block w-[65%] m-auto"
        />
        <img
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP14-COMPATIBILITY-CHART-MOBILE.webp?v=1665185521"
          className="bw-img block md:hidden w-[90%] m-auto"
        />
      </div>

      <div class="build-kit-buy bg-black flex flex-col justify-center items-center pb-32">
        <img
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/BUILD-YOUR-KIT-MARK-DESKTOP.webp?v=1665161329"
          alt=""
          class="pt-32 pb-10 m-0 w-[80%] md:w-[35%]"
        />
        <a
          onClick={scrollUp}
          href="#"
          class="hover:bg-[#E0B01D] bc-build-btn font-extrabold bg-paloverde text-white text-center text-sm px-10 pt-2 pb-1"
        >
          Shop Now
        </a>
      </div>
    </div>
  )
}

export default BelowLcp14Overview
