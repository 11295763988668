/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
/* eslint-disable */
import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../header/header"
import Footer from "../footer/footer"
import "./layout.scss"
import "../../styles/app.scss"
import { useStoryblok } from "../../utils/storyblok"
import BackToTopButton from "../BackToTopButton/BackToTopButton.js"
import FirstVisitorForm from "../FirstVisitorForm/firstVisitorForm"
import { getCookie, setCookie } from "../../utils/cookie"
import { NewUserContext } from "../../context/NewUserContext"
import { useLocation } from "@reach/router"

const Layout = ({ children }) => {
  const { isNewUser, setIsNewUser } = useContext(NewUserContext)
  const location = useLocation()
  const menuData = useStaticQuery(graphql`
    query menuAndSiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      storyblokEntry(slug: { eq: "nav-v4" }) {
        id
        name
        content
        slug
        full_slug
        uuid
        created_at
      }
      utilityBar: storyblokEntry(slug: { eq: "utility-bar" }) {
        id
        name
        content
        slug
        full_slug
        uuid
        created_at
      }
    }
  `)
  function checkSession() {
    const visited = getCookie("visited")
    const interacted = getCookie("interacted")
    const visits = parseInt(getCookie("visits") || "1", 10)
    if ((visited === "yes" && interacted === "yes") || visits > 2) {
      setIsNewUser(false)
    } else {
      setCookie("visits", parseInt(visits || 1, 10) + 1, null)
      setIsNewUser(true)
    }
    setCookie("visited", "yes", null)
  }

  useEffect(() => {
    checkSession()
  }, [])

  const story = menuData.storyblokEntry
    ? useStoryblok(menuData.storyblokEntry)
    : { content: null }
  const utilityBar = menuData.utilityBar
    ? useStoryblok(menuData.utilityBar)
    : { content: null }

  const isFilmVault = location.pathname.includes("/filmvault")

  return (
    <>
      {isNewUser ? <FirstVisitorForm /> : null}
      <Header
        siteTitle={
          menuData.site.siteMetadata
            ? menuData.site.siteMetadata.title
            : "Innovative Gear for Content Creators"
        }
        story={story}
        utilityBar={isFilmVault ? null : utilityBar}
      />
      <main
        className={`font-sans ${
          /* mobileMenuActive ? 'overflow-hidden' : */ ""
        }`}
      >
        {children}
      </main>
      <BackToTopButton />
      {!isFilmVault ? <Footer story={story} /> : null}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
