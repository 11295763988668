/* eslint-disable */
import { sbEditable } from "@storyblok/storyblok-editable"
import React from "react"
import LCSecondarySection from "../LCSecondarySection"
import LCPNotice from "../LCPNotice"

const Components = {
  LCPNotice,
  LCSecondarySection: LCSecondarySection,
}

const DynamicPartsSectionComponent = ({ blok, products = null }) => {
  const blokComponent = blok ? blok.component : null
  if (!blokComponent) return null

  if (typeof Components[blokComponent] !== "undefined") {
    const Component = Components[blokComponent]
    return (
      <div {...sbEditable(blok)}>
        <Component blok={blok} products={products} />
      </div>
    )
  }
  return (
    <p>
      The component <strong>{blokComponent}</strong> has not been created yet.
    </p>
  )
}

export default DynamicPartsSectionComponent
