import React from "react"
import BG from "../../../images/internation_resellers_hero.png"

const Hero = () => {
  return (
    <div
      style={{ backgroundImage: `url("${BG}")` }}
      className="w-full h-[250px] hidden sm:flex justify-center items-center bg-cover"
    >
      <div className="flex flex-row justify-between items-center px-[15px] md:px-28 w-full mx-auto py-4 md:py-0">
        <div className="mt-4">
          <p className="mb-0 text-base text-grey">
            Home / International Resellers
          </p>
          <h1 className="mb-0 mt-2 text-5xl text-white">
            International Resellers
          </h1>
        </div>
      </div>
    </div>
  )
}

export default Hero
