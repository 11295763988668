/* eslint-disable */
import axios from "../api/axios"
import useAuth from "./useAuth"

const useRefreshToken = () => {
  const { setAuth } = useAuth()

  const refresh = async () => {
    const response = await axios.get("/.netlify/functions/refresh", {
      withCredentials: true,
    })
    // console.log('INSIDE REFRESH', response)
    setAuth?.((prev: any) => {
      // console.log(JSON.stringify(prev));
      // console.log(response.data)
      // console.log('Retrieved data',response.data.accessToken);
      return { ...prev, accessToken: response.data.accessToken }
    })
    // console.log('AccessToken retrieved', response.data.accessToken)
    return response.data.accessToken
  }
  return refresh
}

export default useRefreshToken
