import React from "react"
import { StoryblokRichtext } from "storyblok-rich-text-react-renderer"
import Modal from "../Modal"
import { render } from "storyblok-rich-text-react-renderer"

interface Media {
  filename: string
}

interface Props {
  item: {
    chart?: Media
    image?: Media
    title?: string
    video?: Media
    simple_text?: StoryblokRichtext
    youtube_link?: {
      url: string
    }
  }
  hide: () => void
  show: boolean
}

const DynamicPopUp = ({ item, hide, show }: Props) => {
  return (
    <Modal active={show} setActive={hide}>
      <div id="clothing-size-and-fit-modal" className="!px-3">
        <div className="!max-h-[calc(100vh-200px)] overflow-y-auto !items-start my-0 mx-auto py-2 px-[15px]">
          {!!item?.chart?.filename?.length && (
            <img
              src={item.chart.filename}
              alt={item.title}
              className="w-full h-auto object-cover"
            />
          )}
          {!!item?.video?.filename?.length && (
            <video
              src={item.video.filename}
              controls
              className="w-full h-auto object-cover"
            />
          )}
          {!!item?.image?.filename?.length && (
            <img
              src={item.image.filename}
              alt={item.title}
              className="w-full h-auto object-cover"
            />
          )}
          {!!item?.youtube_link?.url?.length && (
            <iframe
              width="100%"
              height="315"
              src={item.youtube_link.url}
              title={item.title}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder={0}
            />
          )}
          {!!item?.simple_text?.content?.length
            ? render(item.simple_text, {
                blokResolvers: {
                  Table: (props: any) => {
                    const {
                      table: { thead = [], tbody = [] },
                    } = props
                    return (
                      <table className="cm">
                        <thead>
                          <tr>
                            {thead.map((item: any, index: number) => (
                              <th key={index}>{item.value}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {tbody.map((item: any, index: number) => (
                            <tr key={index}>
                              {item.body.map((item: any, itemIndex: number) => (
                                <th key={itemIndex + "" + index}>
                                  {item.value}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )
                  },
                },
              })
            : null}
        </div>
      </div>
    </Modal>
  )
}

export default DynamicPopUp
