/* eslint-disable */
import React from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

import Seo from "../components/seo"
import LineItem from "../components/LineItem"
import { formatPrice } from "../utils/formatPrice"
import "../styles/cart.css"
import useCheckout from "../hooks/useCheckout"

const CartPage = () => {
  const {
    checkout,
    handleCheckout,
    loading,
    loadingSubmit,
    emptyCart,
    authNotice,
  } = useCheckout()
  const location = useLocation()

  const urlParam = location.search

  const checkoutInit = async () => {
    const checkoutUrl = handleCheckout(urlParam)
    const tag = document.createElement("a")
    tag.href = checkoutUrl
    tag.target = "_blank"
    document.body.appendChild(tag)
    tag.click()
    document.body.removeChild(tag)
  }
  return (
    <>
      <Seo title="PolarPro® | My Cart" />
      <div className="w-full px-[25px]">
        {emptyCart ? (
          <div className="flex flex-col justify-center w-full max-w-screen-xl min-h-half-screen mx-auto">
            <h1 className="text-paloverde mb-8">Your cart is empty.</h1>
            <p>Your cart is empty. Check out the pro gears here:</p>
            <Link
              to={`/collections/shop-all/${
                location.search ? location.search : ""
              }`}
              className="inline-block text-paloverde"
            >
              Shop Now
            </Link>
          </div>
        ) : (
          <div className="p-8">
            <h2 className="w-full max-w-4xl m-auto">Your Cart</h2>
            <table className="w-full max-w-4xl m-auto mt-8 table-auto">
              <thead className="">
                <tr>
                  <th className="overflow-hidden hidden md:table-cell">
                    Image
                  </th>
                  <th className="hidden md:table-cell">Product</th>
                  <th className="hidden md:table-cell">Price</th>
                  <th className="hidden md:table-cell">Qty.</th>
                  <th className="text-right hidden sm:table-cell">Total</th>
                </tr>
              </thead>
              <tbody>
                {checkout.lineItems.map(item => (
                  <LineItem item={item} key={item.id} />
                ))}
                <tr className="font-bold text-lg">
                  <td className="hidden sm:table-cell" />
                  <td className="hidden sm:table-cell" />
                  <td className="hidden sm:table-cell" />
                  <td className="text-right">Subtotal</td>
                  <td className="text-right">
                    {formatPrice(
                      checkout.subtotalPriceV2.currencyCode,
                      checkout.subtotalPriceV2.amount,
                    )}
                  </td>
                </tr>
                <tr className="font-bold text-lg">
                  <td className="hidden sm:table-cell" />
                  <td className="hidden sm:table-cell" />
                  <td className="hidden sm:table-cell" />
                  <td className="text-right">Taxes</td>
                  <td className="text-right">
                    {formatPrice(
                      checkout.totalTaxV2.currencyCode,
                      checkout.totalTaxV2.amount,
                    )}
                  </td>
                </tr>
                <tr className="font-bold text-lg">
                  <td className="hidden sm:table-cell" />
                  <td className="hidden sm:table-cell" />
                  <td className="hidden sm:table-cell" />
                  <td className="text-right">Shipping</td>
                  <td className="text-right">Free in U.S.</td>
                </tr>
                <tr className="font-bold text-lg">
                  <td className="hidden sm:table-cell" />
                  <td className="hidden sm:table-cell" />
                  <td className="hidden sm:table-cell" />
                  <td className="text-right">Total Price</td>
                  <td className="text-right">
                    {formatPrice(
                      checkout.totalPriceV2.currencyCode,
                      checkout.totalPriceV2.amount,
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="w-full max-w-4xl m-auto text-center mt-8">
              {authNotice ? (
                <p className="text-xs text-paloverde">
                  Note: You may be asked to log in again at checkout.
                </p>
              ) : null}
              <button
                type="button"
                onClick={() => checkoutInit()}
                disabled={loading || loadingSubmit}
                className="min-w-200px bg-obsidian text-white hover:bg-paloverde p-3 rounded-br-3xl rounded-tl-3xl font-bold font-expanded uppercase"
                style={{ fontSize: "0.75rem" }}
              >
                Checkout
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default CartPage
