/* eslint-disable react/no-array-index-key */
import React from "react"
import { render } from "storyblok-rich-text-react-renderer"

const ProductBottomSection = props => {
  const { sections, BottomSectionImage1, BottomSectionImage2 } = props
  return (
    <div className="w-full">
      {BottomSectionImage1?.filename ? (
        <div className="w-full mt-16">
          <img
            src={BottomSectionImage1.filename}
            className="w-full h-auto m-0 object-cover"
            alt="Top"
          />
        </div>
      ) : null}
      {!!sections?.length && (
        <div className="w-full bg-[#1C1C1C] px-[15px] xl:px-28 pt-10">
          {sections.map((section, i) => {
            const isEven = i % 2 === 0
            const description = section?.description
              ? render(section.description)
              : ""
            return (
              <div
                className={`w-full py-12 2xl:max-w-[1200px] m-auto flex justify-between text-center md:text-left flex-col md:items-center ${
                  !isEven ? "md:flex-row-reverse" : "md:flex-row"
                }`}
                key={i}
              >
                <div className="max-w-sm mx-5">
                  <h1 className="font-sans font-black text-white text-3xl md:text-36">
                    {section.title}
                  </h1>
                  <p className="font-sans font-regular text-lg md:text-xl text-white">
                    {description}
                  </p>
                </div>
                <div className="w-full max-w-md">
                  <img
                    src={section?.image?.filename}
                    className="w-full object-cover h-auto m-0"
                    alt={section.title}
                  />
                </div>
              </div>
            )
          })}
        </div>
      )}
      {BottomSectionImage2?.filename ? (
        <div className="w-full">
          <img
            src={BottomSectionImage2.filename}
            className="w-full h-auto m-0 object-cover"
            alt="Bottom"
          />
        </div>
      ) : null}
    </div>
  )
}

export default ProductBottomSection
