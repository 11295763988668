/* eslint-disable */
import React from "react"

const FeaturedProductImagesDot = ({
  children,
  mainCarousel,
  onClick,
  ...rest
}) => {
  const {
    onMove,
    index,
    active,
    carouselState: { currentSlide, deviceType },
  } = rest

  return (
    <li
      data-index={index}
      className={`react-multi-carousel-dot ${active ? "active" : "inactive"}`}
    >
      <button
        className={`${active ? "active bg-paloverde" : "inactive"}`}
        onClick={() => {
          // mainCarousel.current.goToSlide(index + 2, true);
          onClick()
        }}
      >
        {children}
      </button>
    </li>
  )
}

export default FeaturedProductImagesDot
