/* eslint-disable */
import React, { useEffect } from "react"
import { Link } from "gatsby"
import { Formik, Form, Field, ErrorMessage } from "formik"
import FormError from "../FormError"
import useAuth from "../../hooks/useAuth"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import axios from "axios"
import productShopifyIdDecoder from "../../utils/shopifyProductIdDecoder"
import "../../styles/profile.scss"
import * as style from "../../pages/welcome.module.scss"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import { functionsEndpoint } from "../../config"

const isBrowser = typeof window !== "undefined"

const AccountReset = () => {
  const { auth, setAuth } = useAuth()
  const privateAxios = useAxiosPrivate()

  useEffect(() => {
    if (!auth.accessToken || !auth.email) {
      axios
        .get(functionsEndpoint + "/account", {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(result => {
          const {
            id,
            email,
            firstName,
            lastName,
            defaultAddress,
            phone,
            metafields,
          } = result.data?.customer
          const customerShopifyId = productShopifyIdDecoder(id)
          const birthday = metafields?.edges?.filter?.(
            metafield => metafield?.key === "birthDate",
          )
          const birthdayStr =
            birthday?.length > 0
              ? birthday?.[0]?.value
                  ?.replace?.(/-/g, "/")
                  .toDateString?.()
                  .substr?.(4)
              : "---"
          setAuth({
            ...auth,
            id: customerShopifyId,
            email: email,
            firstName: firstName,
            lastName: lastName,
            defaultAddress: defaultAddress,
            phone: phone,
            birthday: birthdayStr,
          })
        })
        .catch(err => {
          console.error("error retrieving data", err.message)
          if (!err?.response) {
            console.error("No Server Response")
          } else if (err.response?.status === 400) {
            console.error("Bad Request")
          } else if (err.response?.status === 401) {
            console.error("Unauthorized")
          } else if (err.reponse?.status === 403) {
            console.error("Forbidden")
          } else if (err.response.status === 500) {
            console.error(
              `Internal server error occured. Message from server: ${err.message}`,
            )
          } else {
            console.error(
              `Unknown error occured. Message from server: ${err.message}`,
            )
          }

          if (isBrowser) {
            localStorage.setItem("loggedIn", "false")
            navigate(`/login/${location.search ? location.search : ""}`)
          }
        })
    }
  }, [])
  const location = useLocation()

  return (
    <div className="profile-content-wrapper">
      <div className="profile-content-width">
        <div className="profile-content-card-wrapper">
          <div className="profile-content-card-header">
            <div className="profile-content-card-title-wrapper">
              <h2 className="profile-content-card-title content-title-font-and-size">
                Reset Password
              </h2>
              {/*
                <!-- <div className="header-close-ellipse">
                  <div className="header-close-bar"></div>
                </div> -->
              */}
            </div>
            <div className="profile-content-card-back-btn">
              <Link to="/account">Back To Account</Link>
            </div>
          </div>
          <div className="profile-content-card-body-wrapper profile-content-background-color">
            <div className="profile-content-card-body">
              <div id="RecoverPasswordForm" className="m-auto w-2/3 my-4 py-8">
                <h3 className={style.title}>RESET YOUR PASSWORD</h3>
                <p className="text-sm">
                  We will send you an email to reset your password.
                </p>

                <Formik
                  initialValues={{ email: auth.email }}
                  validate={values => {
                    const errors = {}
                    if (!auth.email) {
                      errors.email =
                        "We could not acquire your email. Please try again later"
                    }
                    return errors
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    axios
                      .post(
                        functionsEndpoint + "/recover",
                        JSON.stringify({ data: { email: auth.email } }),
                        {
                          withCredentials: true,
                          headers: {
                            "Content-Type": "application/json",
                          },
                        },
                      )
                      .then(async res => {
                        setSubmitting(false)
                        navigate(
                          `/account/${location.search ? location.search : ""}`,
                        )
                      })
                      .catch(err => {
                        console.error(err.message)
                        if (!err?.response) {
                          console.error("No Server Response")
                        } else if (err.response?.status === 400) {
                          console.error("Missing Username or Password")
                          localStorage.setItem("loggedIn", "false")
                        } else if (err?.response?.status === 401) {
                          console.error("Unauthorized")
                          localStorage.setItem("loggedIn", "false")
                        } else if (err.reponse?.status === 403) {
                          console.error("Forbidden")
                          localStorage.setItem("loggedIn", "false")
                        }
                        setSubmitting(false)
                      })
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <label
                        htmlFor="email"
                        className="mb-4 text-xs text-paloverde"
                      >
                        Your email
                      </label>
                      <Field
                        type="email"
                        name="email"
                        value={auth.email}
                        placeholder="Email"
                        className="my-profile-info-field w-full text-base h-[30px] px-4"
                      />
                      <ErrorMessage
                        name="email"
                        render={msg => <FormError msg={msg} />}
                      />
                      {/* <p className="mt-4">
                        <input
                          type="submit"
                          className={`${style.btn} w-full`}
                          disabled={isSubmitting}
                        />
                      </p> */}
                      <div className="profile-input-label-wrapper text-center mx-auto mt-4">
                        <Field
                          type="submit"
                          id="infoSubmit"
                          className="h-[35px] text-white cursor-pointer"
                          value="Reset"
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountReset
