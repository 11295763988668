/* eslint-disable */
import { sbEditable } from "@storyblok/storyblok-editable"
import React from "react"
import BelowQuartzlineCollection from "../BelowQuartzlineCollection"
import BelowReconVndOverview from "../BelowReconVndOverview"
import BelowSlate2Overview from "../BelowSlate2Overview"
import BelowHelixMaglockMckinnonEdOverview from "../BelowHelixMaglockMckinnonEdOverview"
import BelowHelixMaglockBurkardEdOverview from "../BelowHelixMaglockBurkardEdOverview"
import BelowBasecampOverview from "../BelowBasecampOverview"
import Below4by5MchOverview from "../Below4by5MchOverview"
import BelowShortstacheOverview from "../BelowShortstacheOverview"
import BelowChromaOverview from "../BelowChromaOverview"
import BelowLcp14Overview from "../BelowLcp14Overview"
import BelowLCP15Overview from "../BelowLCP15Overview"
import BelowBoreal50lBackpackOverview from "../BelowBoreal50lBackpackOverview"
import GenericTemplateBottom from "../GenericTemplateBottom"
import DynamicPromoBanner from "../DynamicPromoBanner"
import QuartzlineFXTitle from "../QuartzlineFXTitle"
import VideoBanner from "../VideoBanner"
import ThreeLayerFeatureBanner from "../ThreeLayerFeatureBanner"
import LegacyThreeImagesCarousel from "../LegacyThreeImagesCarousel"
import DefenderPromoSection from "../DefenderPromoSection"
import WidgeticWithTitle from "../WidgeticWithTitle"
import FXFiltersSpecsChart from "../FXFiltersSpecsChart"
import QLSustainabilitySection from "../QLSustainabilitySection"
import ProductSupport from "../ProductSupport"
import Teaser from "../Teaser"
import LCPNotice from "../LCPNotice"
import Herocarousel from "../HeroCarousel"
import MenuItem from "../MenuItem"
import FooterSection from "../FooterSection"
import FooterMenuItem from "../FooterMenuItem"
import FlagshipNav from "../FlagshipNav"
import FlagshipNavMenuItem from "../FlagshipNavMenuItem"
import LinkItem from "../LinkItem"
import AccordionDescItem from "../AccordionDescItem"
import SpecItem from "../SpecItem"
import Spec from "../Spec"
import FAQItem from "../FAQItem"
import FAQVideo from "../FAQVideo"
import BelowPmvndCollection from "../BelowPmvndCollection"
import QuartzlineTitle from "../QuartzlineTitle"
import AutoPlayVideoBanner from "../AutoPlayVideoBanner"
import BuildQuality from "../BuildQuality"
import ZigZagBannersContainer from "../ZigZagBannersContainer"
import ZigZagBanner from "../ZigZagBanner"
import QuartzlineFilterSpecs from "../QuartzlineFilterSpecs"
import QuartzlineRenderAnimation from "../QuartzlineRenderAnimation"
import ImageBanner from "../ImageBanner"
import ImageComparison from "../ImageComparison"
import ModularFilterInfoTileContainer from "../ModularFilterInfoTileContainer"
import ModularFilterInfoTile from "../ModularFilterInfoTile"
import ListItem from "../ListItem"
import LegacyOverview from "../LegacyOverview"
import LegacyMavic2 from "../LegacyMavic2"
import LargeMenuItem from "../LargeMenuItem"
import ProductHighlightsBanner from "../ProductHighlightsBanner"
import ProductHighlightsBannerBelow from "../ProductHighlightsBannerBelow"
import FeaturedCollectionItem from "../FeaturedCollectionItem"
import GearUpSliderItem from "../GearUpSliderItem"
import ExploreCollectionCard from "../ExploreCollectionCard"
import TileContents from "../TileContents"
import { WidgetScript } from "../../scripts/widget"
import SpecData from "../SpecItem/spec_data"
import FAQData from "../faqdata"

const Components = {
  GenericTemplateBottom: GenericTemplateBottom,
  teaser: Teaser,
  StickyPromoBanner: DynamicPromoBanner,
  BelowQuartzlineCollection: BelowQuartzlineCollection,
  QuartzlineFXTitle: QuartzlineFXTitle,
  VideoBanner: VideoBanner,
  ThreeLayerFeatureBanner: ThreeLayerFeatureBanner,
  LegacyThreeImagesCarousel: LegacyThreeImagesCarousel,
  DefenderPromoSection: DefenderPromoSection,
  WidgeticWithTitle: WidgeticWithTitle,
  FXFiltersSpecsChart: FXFiltersSpecsChart,
  QLSustainabilitySection: QLSustainabilitySection,
  ProductSupport: ProductSupport,
  HeroCarousel: Herocarousel,
  LCPNotice,
  BelowPmvndCollection,
  BelowReconVndOverview,
  BelowSlate2Overview,
  BelowHelixMaglockMckinnonEdOverview,
  BelowHelixMaglockBurkardEdOverview,
  BelowBasecampOverview,
  Below4by5MchOverview,
  BelowShortstacheOverview,
  BelowChromaOverview,
  BelowLcp14Overview,
  BelowLCP15Overview,
  BelowBoreal50lBackpackOverview,
  FooterSection,
  MenuItem,
  FooterMenuItem,
  FlagshipNav,
  FlagshipNavMenuItem,
  LinkItem,
  AccordionDescItem,
  SpecItem,
  spec_item: SpecItem,
  Spec,
  FAQItem,
  FAQVideo,
  QuartzlineTitle,
  AutoPlayVideoBanner,
  BuildQuality,
  ZigZagBannersContainer,
  ZigZagBanner,
  QuartzlineFilterSpecs,
  QuartzlineRenderAnimation,
  ImageBanner,
  ImageComparison,
  ModularFilterInfoTileContainer,
  ModularFilterInfoTile,
  ListItem,
  LegacyOverview,
  LegacyMavic2,
  LargeMenuItem,
  ProductHighlightsBanner,
  ProductHighlightsBannerBelow,
  FeaturedCollectionItem,
  GearUpSliderItem,
  ExploreCollectionCard,
  TileContents,
  spec_data: SpecData,
  faq_data: FAQData,
}

const DynamicComponents = ({
  blok,
  product = null,
  index = 0,
  lastChildIndex = 0,
  dynamicATCProducts = [],
  propClass = ""
}) => {
  const blokComponent = blok ? blok.component : null
  if (!blokComponent) return null

  // using the <ProductSupport/> from the product metafield 'user-guide' instead of storyblok
  if (blokComponent === "ProductSupport") {
    return <div></div>
  } else if (typeof Components[blokComponent] !== "undefined") {
    const Component = Components[blokComponent]
    return (
      <div {...sbEditable(blok)} className="">
        <WidgetScript />
        <Component
          dynamicATCProducts={dynamicATCProducts}
          blok={blok}
          product={product}
          index={index}
          lastChildIndex={lastChildIndex}
          propClass={propClass}
        />
      </div>
    )
  }
  return (
    <p>
      The component <strong>{blokComponent}</strong> has not been created yet.
    </p>
  )
}

export default DynamicComponents
