import React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"

const ElementsCareGuide = props => {
  const { guide } = props

  return (
    <div id="clothing-care-modal">
      <div className="wrapper">
        <h1>Care</h1>
        <hr />
        <StaticImage
          src="../../images/washer-icon-clothing.png"
          quality={95}
          width={40}
          formats={["AUTO", "WEBP", "AVIF"]}
          transFormOptions={{ fit: "contain" }}
          alt="Washer Icon"
        />
        <p>{guide}</p>
      </div>
    </div>
  )
}

export default ElementsCareGuide

ElementsCareGuide.propTypes = {
  guide: PropTypes.string.isRequired,
}
