/* eslint-disable */

import React, { useState, useEffect } from "react"
import Seo from "../../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import { useStoryblok } from "../../utils/storyblok"
import DynamicComponents from "../../components/DynamicComponents"
import "./quartzlineFxOverview.scss"
import loadable from "@loadable/component"
import useScrollBlock from "../../hooks/useScrollBlock"

const ModalVideo = loadable(() => import("react-modal-video"))

const QuartzlinefxOverview = () => {
  const [showVideo, setShowVideo] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    if (showVideo) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [showVideo])

  const pageData = useStaticQuery(graphql`
    query {
      storyblokEntry(full_slug: { eq: "pages/quartzline" }) {
        id
        name
        content
        slug
        full_slug
        uuid
        created_at
      }
      shopifyProduct(handle: { eq: "cp-filters" }) {
        handle
        title
      }
    }
  `)

  const product = pageData.shopifyProduct

  const story = pageData.storyblokEntry
    ? useStoryblok(pageData.storyblokEntry)
    : { content: null }

  const stickyNavComponent =
    story.content && story.content["sticky_nav"]
      ? story.content["sticky_nav"].map(blok => (
          <>
            {blok ? (
              <div {...sbEditable(blok)} className="sticky top-0 z-50">
                <DynamicComponents
                  blok={blok}
                  product={product}
                  key={blok._uid}
                />
              </div>
            ) : null}
          </>
        ))
      : null

  return (
    <>
      <Seo title="Quartzline Fx Overview" />
      <div className="sticky top-0 z-50">{stickyNavComponent}</div>
      <div className="quartzlineFx">
        <div className="intro">
          <div className="data">
            <img
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Set-The-Mood-Title-Desktop.png?v=1632853501"
              alt=""
            />
            <p>
              Introducing a new collection of QuartzLine filters, perfect for
              creating incredible imagery straight out of camera; no post work
              necessary. From our industry-leading Neutral Density filters to
              new FX additions such as BlueMorphic and Mist, the QuartzLine
              lineup gives you all the creative tools needed for setting the
              mood.
            </p>
          </div>
        </div>
        <div className="featureVideo">
          <a
            id="inline"
            href="#data"
            className="x-y-center play-button"
            onClick={e => {
              e.preventDefault()
              setShowVideo(true)
            }}
          />

          {/* <!-- Modal --> */}
          <ModalVideo
            channel="vimeo"
            videoId="462728373"
            ratio="2.3:1"
            isOpen={showVideo}
            onClose={() => setShowVideo(false)}
          />
        </div>

        <div className="bluemorphic">
          <div className="compareContainer">
            <h3 className="display-font-logo font-bold"></h3>
            <div className="sideBySideBg blue">
              <div className="sideBySide pushTop offset colReverse">
                <div className="oneThird">
                  <p className="featureTitle display-font-logo font-bold">
                    BlueMorphic
                  </p>
                  <span>
                    Achieve cinematic, stylized blue flares without the need for
                  </span>
                  <span>expensive anamorphic lenses.</span>
                </div>
                <div className="twoThird offsetTop">
                  <img
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/BlueMorphic-Image-Block-1-Desktop.jpg?v=1632856971"
                    alt=""
                    className="desktop-only"
                  />
                  <img
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/BlueMorphic-Mobile-Block-1.jpg?v=1632868458"
                    alt=""
                    className="mobile-only"
                  />
                </div>
              </div>

              <div className="sideBySide">
                <div className="twoThird offsetBottom">
                  <img
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/BlueMorphic-Image-Block-2-Desktop.jpg?v=1632856971"
                    alt=""
                    className="desktop-only"
                  />
                  <img
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/BlueMorphic-Mobile-Block-2.jpg?v=1632868458"
                    alt=""
                    className="mobile-only"
                  />
                </div>
                <div className="oneThird">
                  <p className="featureTitle display-font-logo font-bold">
                    Suspenseful, Electrifying, Edgy
                  </p>
                  <span>
                    Add visual interest to any scene's bright light sources with
                  </span>
                  <span>unlimited creative potential, all in-camera.</span>
                </div>
              </div>
            </div>
            <a
              href="/products/bluemorphic-filter-quartzline"
              className="shopLink font-bold"
            >
              Shop Bluemorphic
            </a>
          </div>
        </div>

        <div className="goldmorphic">
          <div className="compareContainer">
            <h3 className="display-font-logo font-bold">GoldMorphic</h3>
            <div className="sideBySideBg gold">
              <div className="sideBySide pushTop offset colReverse">
                <div className="oneThird">
                  <p className="featureTitle display-font-logo font-bold">
                    GoldMorphic
                  </p>
                  <span>
                    Create cinematic, stylized warm flares without the need for
                  </span>
                  <span>expensive anamorphic lenses.</span>
                </div>
                <div className="twoThird offsetTop">
                  <img
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/GoldMorphic-Image-Block-1-Desktop.jpg?v=1632857979"
                    alt=""
                    className="desktop-only"
                  />
                  <img
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/GoldMorphic-Mobile-Block-1.jpg?v=1632868458"
                    alt=""
                    className="mobile-only"
                  />
                </div>
              </div>

              <div className="sideBySide">
                <div className="twoThird offsetBottom">
                  <img
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/GoldMorphic-Image-Block-2-Desktop.jpg?v=1632857980"
                    alt=""
                    className="desktop-only"
                  />
                  <img
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/GoldMorphic-Mobile-Block-2.jpg?v=1632868458"
                    alt=""
                    className="mobile-only"
                  />
                </div>
                <div className="oneThird">
                  <p className="featureTitle display-font-logo font-bold">
                    Calming, Serene, Tranquil
                  </p>
                  <span>
                    Perfect for setting a calm, relaxing vibe and feel, all
                    in-camera.
                  </span>
                </div>
              </div>
            </div>
            <a
              href="/products/goldmorphic-filter-quartzline"
              className="shopLink font-bold"
            >
              Shop GoldMorphic
            </a>
          </div>
        </div>

        <div className="mist">
          <div className="compareContainer">
            <h3 className="display-font-logo font-bold">Mist / Mist Heavy</h3>
            <div className="sideBySideBg mist">
              <div className="sideBySide pushTop offset colReverse">
                <div className="oneThird">
                  <p className="featureTitle display-font-logo font-bold">
                    Mist / Mist Heavy
                  </p>
                  <span>
                    Set the mood of your scenes with a beautiful diffused glow
                  </span>
                  <span>
                    around light sources and lit subjects. Available in two
                    strengths.
                  </span>
                </div>
                <div className="twoThird offsetTop">
                  <img
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/Mist-Image-Block-1-Desktop.jpg?v=1632859680"
                    alt=""
                    className="desktop-only"
                  />
                  <img
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/Mist-Mobile-Block-1.jpg?v=1632868458"
                    alt=""
                    className="mobile-only"
                  />
                </div>
              </div>

              <div className="sideBySide">
                <div className="twoThird offsetBottom">
                  <img
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/Mist-Image-Block-2-Desktop.jpg?v=1632859681"
                    alt=""
                    className="desktop-only"
                  />
                  <img
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/Mist-Mobile-Block-2.jpg?v=1632868458"
                    alt=""
                    className="mobile-only"
                  />
                </div>
                <div className="oneThird">
                  <p className="featureTitle display-font-logo font-bold">
                    Nostalgic, Wistful, Dreamy
                  </p>
                  <span>
                    Create dramatic cinema-like effects without sacrificing
                  </span>
                  <span>contrast, color accuracy, or sharpness.</span>
                </div>
              </div>
            </div>
            <a
              href="/products/mist-filters-quartzline"
              className="shopLink font-bold"
            >
              Shop Mist
            </a>
          </div>
        </div>

        <div className="exploreCollection">
          <div className="data">
            <img
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Explore-The-Full-Collection-Desktop.png?v=1632861933"
              alt=""
              className="desktop-only"
            />
            <img
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Explore-the-Full-Collection-Mobile.png?v=1632899421"
              alt=""
              className="mobile-only"
            />
            <p>
              Featuring industry-leading Neutral Density + Polarizer combo
              filters, standard NDs, Circular Polarizers and protective UV
              options. Explore the whole line of QuartzLine filter offerings.
            </p>
            <a
              href="https://www.polarpro.com/collections/quartzline-filters"
              className="shopLink font-bold"
            >
              Shop Quartzline
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuartzlinefxOverview
