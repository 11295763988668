/* eslint-disable */

import React, { useState, useEffect } from "react"
import Seo from "../../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import { useStoryblok } from "../../utils/storyblok"
import DynamicComponents from "../../components/DynamicComponents"
import "./mavic3Overview.scss"
import loadable from "@loadable/component"
import useScrollBlock from "../../hooks/useScrollBlock"

const ModalVideo = loadable(() => import("react-modal-video"))

const Mavic3Overview = () => {
  const [showVideo, setShowVideo] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    if (showVideo) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [showVideo])

  const pageData = useStaticQuery(graphql`
    query {
      storyblokEntry(full_slug: { eq: "pages/mavic-3-filters-overview" }) {
        id
        name
        content
        slug
        full_slug
        uuid
        created_at
      }
      shopifyProduct(handle: { eq: "mavic-3-filters" }) {
        handle
        title
      }
    }
  `)

  const product = pageData.shopifyProduct

  const story = pageData.storyblokEntry
    ? useStoryblok(pageData.storyblokEntry)
    : { content: null }

  const stickyNavComponent =
    story.content && story.content["sticky_nav"]
      ? story.content["sticky_nav"].map(blok => (
          <>
            {blok ? (
              <div {...sbEditable(blok)} className="sticky top-0 z-50">
                <DynamicComponents
                  blok={blok}
                  product={product}
                  key={blok._uid}
                />
              </div>
            ) : null}
          </>
        ))
      : null

  // Load Widgetic Script
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://widgetic.com/sdk/sdk.js"
    script.async = true
    document.head.appendChild(script)

    script.onload = e => {
      window.Widgetic.UI.parse()
    }

    return () => {
      script.remove()
    }
  })

  return (
    <>
      <Seo title="Mavic 3 Overview" />
      <div className="sticky top-0 z-50">{stickyNavComponent}</div>
      <div class="mavic3-overview">
        <div class="product-hero container-wrapper">
          <div class="hero-text vertical-center">
            <h2 className="display-font-logo font-bold">
              DJI Mavic 3 Filters.
            </h2>
            <p>
              Introducing an all-new lineup of creative filters for the Mavic 3
              and Mavic 3 Cine. Featuring never-before-seen offerings in the
              aerial space; Blue/GoldMorphic, Mist Diffusion, and creative
              combos such as Mist VNDs and Morphic VNDs; these new filters will
              be sure to turn heads both on location and in your final
              production.
            </p>
          </div>
        </div>

        <div class="video-container">
          <a
            href="#data"
            id="inline"
            className="play-button x-y-center"
            onClick={e => {
              e.preventDefault()
              setShowVideo(true)
            }}
          />

          {/* modal */}
          <ModalVideo
            channel="youtube"
            videoId="7smqoE1-NsU"
            isOpen={showVideo}
            ratio="16:9"
            autoplay="1"
            onClose={() => setShowVideo(false)}
          />
          {/* <div style="display:none">
							<div id="data" style="background-color: black !important; padding: 0vw 0vw; width: 90vw;">
								<iframe src="https://www.youtube.com/embed/7smqoE1-NsU"
									width="640" height="360"
									frameborder="0"
									allow="autoplay; fullscreen; picture-in-picture"
									allowfullscreen>
								</iframe>
							</div>
						</div> */}
        </div>

        <div class="features">
          <h3 className="display-font-logo font-bold section-title">
            10 Years of Innovation and Craftsmanship
          </h3>
          <div class="item air-frame">
            <div class="text vertical-center">
              <h3 className="font-extrabold">AirFrame 4.0</h3>
              <p>Our lightest, strongest, and boldest frame design to date.</p>
            </div>
          </div>

          <div class="item vnd-offerings">
            <div class="text vertical-center">
              <h3 className="font-extrabold">New VND Offerings</h3>
              <p>
                Our glass polarization technology delivers color accuracy on par
                with single-stop NDs without the need to carry mutiple filters.
              </p>
            </div>
          </div>

          <div class="item made-in-germany">
            <div class="text vertical-center">
              <h3 className="font-extrabold">Glass Made in Germany</h3>
              <p>
                Our industry-leading CinemaSeries&trade; glass has paved the way
                for new filter technology.
              </p>
            </div>
          </div>
        </div>

        <div class="wide-banner">
          <div class="x-y-center text-center">
            <h3 className="font-extrabold">From NDPLs to Morphic VNDs</h3>
            <p>
              We've been innovating single-element aerial filters for the last
              decade, introducing industry-firsts from our ND/PL combos to brand
              new morphic streak VNDs.
            </p>
          </div>
        </div>

        <div class="filter-offerings">
          <h3 className="display-font-logo font-bold section-title">
            New FX Filter Offerings
          </h3>
          <div class="filter-listing container-wrapper">
            <div class="filter">
              <div class="image listing-one"></div>
              <div class="tag">
                <p className="font-extrabold">Mist VND2-5</p>
                <span className="sub-text">1/4 stregth diffusion.</span>
              </div>
            </div>

            <div class="filter">
              <div class="image listing-two"></div>
              <div class="tag">
                <p className="font-extrabold">BlueMorphic VND2-5</p>
                <span class="sub-text">
                  Anamorphic-style blue streak effects.
                </span>
              </div>
            </div>

            <div class="filter">
              <div class="image listing-three"></div>
              <div class="tag">
                <p className="font-extrabold">GoldMorphic VND2-5</p>
                <span class="sub-text">
                  Anamorphic-style gold streak effects.
                </span>
              </div>
            </div>

            <div class="filter wide">
              <div class="image combo"></div>
              <div class="tag">
                <p className="font-extrabold">
                  BlueMorphic | GoldMorphic | Mist
                </p>
                <span class="sub-text">
                  New low-light aerial FX filters compliment the Hasselblad 4/3
                  CMOS sensor.
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="compare-container">
          <h3 className="display-font-logo font-bold section-title">
            See Them in Action
          </h3>
          <widgetic
            class="desktop-only"
            id="61e1ffedecb2a16c438b4567"
            resize="fill-width"
            width="3000"
            height="824"
            autoscale="on"
            adaptive="414"
          ></widgetic>
          <widgetic
            class="mobile-only"
            id="61e4a207ecb2a17e048b4569"
            resize="fill-width"
            width="1284"
            height="1788"
            autoscale="on"
            adaptive="414"
          ></widgetic>

          <widgetic
            class="desktop-only"
            id="61e357d9ecb2a112608b4567"
            resize="fill-width"
            width="3000"
            height="824"
            autoscale="on"
            adaptive="414"
          ></widgetic>
          <widgetic
            class="mobile-only"
            id="61e4a1d1ecb2a175048b4569"
            resize="fill-width"
            width="1284"
            height="1788"
            autoscale="on"
            adaptive="414"
          ></widgetic>
        </div>

        <div class="storage">
          <h3 className="display-font-logo font-bold section-title">
            Premium Filter Storage
          </h3>
          <img
            class="desktop-only"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/M3-Prem-Filter-Storage-Desktop.jpg?v=1642643358"
            alt=""
          />
          <img
            class="desktop-only"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/M3-Prem-Filter-Storage-2-Desktop.jpg?v=1642643358"
            alt=""
          />
          <img
            class="mobile-only"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/M3-Premium-Filter-Storage-Mobile-1.jpg?v=1642643358"
            alt=""
          />
          <img
            class="mobile-only"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/M3-Premium-Filter-Storage-Mobile-2.jpg?v=1642643358"
            alt=""
          />
          <img
            class="mobile-only"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/M3-Premium-Filter-Storage-Mobile-3.jpg?v=1642643358"
            alt=""
          />
        </div>

        <div class="build-kit">
          <img
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Build-Your-Kit-Mark_72f50798-d363-4207-821a-4e3997a3fc4d.png?v=1642644431"
            alt=""
            class="be-prepare"
          />
          <a
            href="/products/mavic-3-filters"
            class="bc-build-btn font-extrabold"
          >
            select your filter sets here
          </a>
        </div>
      </div>
    </>
  )
}

export default Mavic3Overview
