/* eslint-disable */

import React from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

import Seo from "../components/seo"

const UnderConstruction = () => {
  const location = useLocation()

  return (
    <>
      <Seo title="Under Construction" />
      <div className="flex flex-col bg-grey-tini-light min-h-[70vh] text-obsidian">
        <div className="w-full py-12 px-10 max-w-[800px] mx-auto mt-4 md:mt-20">
          <h1 className="text-paloverde mt-4">Page Under Construction 🧑‍💻</h1>
          <h3>
            We are currently upgrading our site
            <br className="hidden md:block" />
            <span className="md:hidden"> </span>
            to improve your shopping experience.
          </h3>
          <p>
            We are always striving to provide you with the best shopping
            experience. Some of our customer authentication and profile editing
            features are currently under construction. We are sorry for your
            inconvenience.
          </p>
          <h3 className="text-charcoal">Important Notice:</h3>
          <p>
            {" "}
            <strong>
              <em>You may log in at the checkout.</em>
            </strong>{" "}
            You can use your Reward points at checkout.
          </p>
          <h3 className="text-paloverde">We appreciate your patience.</h3>
          <p className="mb-8">
            Planning for the next adventure? Check out our{" "}
            <Link
              to={`/collections/filters/${
                location.search ? location.search : ""
              }`}
              className="text-paloverde underline cursor-pointer hover:text-paloverde-light"
            >
              innovative filters here!
            </Link>
          </p>
          <Link
            to={`/${location.search ? location.search : ""}`}
            className="mt-8 p-4 bg-paloverde text-white hover:bg-paloverde-light rounded-lg bold"
          >
            Go Back Home
          </Link>
        </div>
      </div>
    </>
  )
}

export default UnderConstruction
