// extracted by mini-css-extract-plugin
export var alignContainer = "style-module--alignContainer--2f91f";
export var animation = "style-module--animation--920dc";
export var availableInTwo = "style-module--availableInTwo--b5093";
export var bottom = "style-module--bottom--fb484";
export var bottomHalf = "style-module--bottomHalf--845fd";
export var chartComparison = "style-module--chartComparison--ff974";
export var compare = "style-module--compare--417d2";
export var compareVnd = "style-module--compareVnd--8d6ee";
export var container = "style-module--container--998cc";
export var darkHighlight = "style-module--dark-highlight--99a9e";
export var defender360 = "style-module--defender360--7e7d8";
export var desc = "style-module--desc--5025f";
export var desktopOnly = "style-module--desktop-only--a9fe1";
export var featureItem = "style-module--featureItem--7763a";
export var grey = "style-module--grey--6bdf0";
export var hapticFeedback = "style-module--hapticFeedback--bfdbb";
export var highlight = "style-module--highlight--ecd96";
export var itemVnd = "style-module--itemVnd--43c82";
export var leftTh = "style-module--leftTh--0fdb0";
export var mainTitle = "style-module--mainTitle--19e2d";
export var mist = "style-module--mist--cb6fb";
export var mobileOnly = "style-module--mobile-only--f7931";
export var multiStorage = "style-module--multiStorage--248d1";
export var noStacking = "style-module--no-stacking--69ae5";
export var oneHalf = "style-module--one-half--837d1";
export var oneThirdBox = "style-module--oneThirdBox--95c26";
export var playBtn = "style-module--playBtn--59c4d";
export var polarYellow = "style-module--polarYellow--3ad2a";
export var presetStopRange = "style-module--presetStopRange--a276e";
export var reducingImpact = "style-module--reducing-impact--0570a";
export var rightVnd = "style-module--rightVnd--2da21";
export var secondStack = "style-module--secondStack--629d3";
export var sideBySide = "style-module--sideBySide--4f23d";
export var size = "style-module--size--a1179";
export var stop = "style-module--stop--82ecc";
export var stopSub = "style-module--stopSub--2ecbe";
export var titleSub = "style-module--title-sub--22308";
export var titleWithSub = "style-module--title-with-sub--87e65";
export var top = "style-module--top--e8164";
export var twoThirdBox = "style-module--twoThirdBox--1c607";
export var variation = "style-module--variation--f1ad9";
export var version = "style-module--version--f9463";
export var videoContainer = "style-module--videoContainer--a7110";
export var videoWrapper = "style-module--videoWrapper--b3954";
export var vnd2PmFeatures = "style-module--vnd2PmFeatures--07848";
export var vndDiffusionFilter = "style-module--vndDiffusionFilter--24147";
export var vndRender = "style-module--vnd-render--d35b9";
export var vndRenderImg = "style-module--vndRenderImg--2d14d";
export var whatsIncluded = "style-module--whatsIncluded--818e8";
export var whichIsRight = "style-module--whichIsRight--0bfcf";