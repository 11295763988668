/* eslint-disable */

/* eslint-disable camelcase */
import React from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

const GearUpSliderItem = ({ blok }) => {
  const location = useLocation()

  const { title, sub_title, link_to, media } = blok

  return (
    <Link
      className="flex flex-col justify-center items-center bg-no-repeat bg-cover text-white h-[93vw] md:h-[43vw] lg:h-[23vw] mx-[20px] lg:mx-0 lg:ml-6 xl:ml-10"
      to={`${link_to}/${location.search ? location.search : ""}`}
      style={{ backgroundImage: `url("${media?.filename}")` }}
    >
      {/* <img src={background} alt="" className="relative" /> */}
      <span className="font-black text-2xl lg:text-[1.7rem] text-center">
        {title}
      </span>
      <span className="font-expanded text-center text-xxs lg:text-[0.7rem]">
        {sub_title}
      </span>
    </Link>
  )
}
export default GearUpSliderItem
