import React from "react"
import { IReseller } from "../Body"

const Card = ({ locations, name }: IReseller) => {
  return (
    <div className="bg-white w-full p-10 mt-4 flex flex-col items-center justify-center">
      <div className="w-full">
        <h1 className="mb-0 pb-4 border-b border-b-[#707070] uppercase text-[#1c1c1c] tracking-[1px] font-extrabold text-[28px]">
          {name}
        </h1>
        <div className="mt-5 flex flex-col">
          {locations.map(location => (
            <div className="mt-4 flex" key={location.title}>
              <div className="w-4 h-4 flex justify-center items-center border border-black rounded-full mr-2">
                <div className="bg-black w-2.5 h-2.5 rounded-full"></div>
              </div>
              <p className="mb-0 font-bold">{location.title} -</p>
              <a
                href={location.link}
                target="_blank"
                className="text-[#C99E1A] ml-1 mb-0 hover:underline"
              >
                view site
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Card
