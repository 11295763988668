/* eslint-disable */
import React, { useRef } from "react"
import PropTypes from "prop-types"
import { mainResponsive } from "./settings.json"

import Carousel from "react-multi-carousel"
import ImageCarouselArrow from "../ImageCarouselArrow"
import "react-multi-carousel/lib/styles.css"

const LegacyThreeImagesCarousel = ({ blok }) => {
  const mainCarousel = useRef(null)

  return (
    <section className="relative w-full h-full mt-20 mb-20">
      <Carousel
        arrows
        autoPlay
        autoPlaySpeed={3500}
        centerMode={false}
        draggable
        focusOnSelect
        infinite
        ref={mainCarousel}
        renderButtonGroupOutside={false}
        responsive={mainResponsive}
        pauseOnHover={false}
        showDots
        swipeable
        customRightArrow={<ImageCarouselArrow right imagesLength="100%" />}
        customLeftArrow={
          <ImageCarouselArrow right={false} imagesLength="100%" />
        }
      >
        <div
          className="slide active"
          data-index="1"
          style={{ backgroundSize: "cover" }}
        >
          <img
            className="desktop-only pointer-events-none"
            src={blok.first_slide_desktop}
          />
          <img
            className="mobile-only pointer-events-none"
            src={blok.first_slide_mobile}
          />
        </div>
        <div
          className="slide active"
          data-index="2"
          style={{ backgroundSize: "cover" }}
        >
          <img
            className="desktop-only pointer-events-none"
            src={blok.second_slide_desktop}
          />
          <img
            className="mobile-only pointer-events-none"
            src={blok.second_slide_mobile}
          />
        </div>
        <div
          className="slide active"
          data-index="3"
          style={{ backgroundSize: "cover" }}
        >
          <img
            className="desktop-only pointer-events-none"
            src={blok.third_slide_desktop}
          />
          <img
            className="mobile-only pointer-events-none"
            src={blok.third_slide_mobile}
          />
        </div>
      </Carousel>
    </section>
  )
}

export default LegacyThreeImagesCarousel
