/* eslint-disable */
import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import Carousel from "react-multi-carousel"
import ImageCarouselArrow from "../ImageCarouselArrow"
import "react-multi-carousel/lib/styles.css"

const HeroCarousel = ({ blok }) => (
  <div className="heroCarousel">
    <p>{blok.heading}</p>
  </div>
)

export default HeroCarousel
