import React, { useEffect, useMemo, useState } from "react"
import { BiSearchAlt2 } from "react-icons/bi"
import { FaRegStar, FaStar } from "react-icons/fa"
import YotpoStarRatings from "../../YotpoStarRatings"
import UserReviews from "../../UserReviews"
import axios from "../../../api/axios"
import UserQuestions from "../../UserQuestions"

const RatingsAndComments = props => {
  const { productShopifyId, product, reviewData, product_id, questionsData } =
    props
  const [ratingStar, setRatingStar] = useState(0)
  const [reviewTitle, setReviewTitle] = useState("")
  const [reviewDescription, setReviewDescription] = useState("")
  const [email, setEmail] = useState("")
  const [displayName, setDisplayName] = useState("")
  const [question, setQuestion] = useState("")
  const [totalItemsPerPage, setTotalItemsPerPage] = useState(0)

  const [allData, setAllData] = useState()
  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")
  const [toggle, setToggle] = useState(null)

  const handleDivClick = divId => {
    if (divId === toggle) {
      setToggle(null)
    } else {
      setToggle(divId)
    }
  }

  useEffect(() => {
    let filteredData
    if (searchQuery.length > 0) {
      filteredData = allData.filter(item =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    } else {
      filteredData = reviewData?.reviews
    }
    setAllData(filteredData)
  }, [searchQuery])

  const handleSearchInputChange = event => {
    setSearchQuery(event.target.value)
  }

  useEffect(() => {
    setAllData(reviewData?.reviews)
    setTotalItemsPerPage(reviewData?.pagination?.total)
  }, [reviewData])

  const { count, rating } = useMemo(() => {
    const count =
      product.metafields?.filter(item => item.key === "rating _count")?.[0]
        ?.value || "5"
    const rating =
      product.metafields?.filter(item => item.key === "rating")?.[0]?.value ||
      "{}"
    let ratingScaleAndValue = {
      scale_min: "1.0",
      scale_max: "5.0",
      value: "5",
    }
    if (typeof rating === "string") {
      try {
        ratingScaleAndValue = JSON.parse(rating)
      } catch (err) {
        console.error(err)
      }
    }
    return {
      count: parseInt(count, 10),
      rating: ratingScaleAndValue,
    }
  }, [product])
  const [ratings, setRatings] = useState([5, 4, 3, 2, 1])
  const [currentRating, setCurrentRating] = useState("5.0")
  useEffect(() => {
    if (rating?.scale_min && rating?.scale_max && count) {
      const min = parseInt(rating.scale_min, 10)
      const max = parseInt(rating.scale_max, 10)
      const tempRatings = []
      for (let i = min; i <= max; i += 1) {
        tempRatings.push(i)
      }
      tempRatings.reverse()
      setRatings(tempRatings)
      const tempRating = parseFloat(
        reviewData?.bottomline?.average_score,
      ).toFixed(1)
      setCurrentRating(tempRating)
    }
  }, [reviewData])
  const [selectedReviews, setSelectedReviews] = useState("Reviews")

  const [currentPage, setCurrentPage] = useState(1)
  const pageNumbers = []
  const itemsPerPage = 5
  if (reviewData?.pagination?.total) {
    for (
      let i = 1;
      i <= Math.ceil(reviewData.pagination.total / itemsPerPage);
      i += 1
    ) {
      pageNumbers.push(i)
    }
  }

  const getReviews = async pageNumber => {
    setLoading(true)
    await axios
      .post("/.netlify/functions/get-reviews", {
        product_id,
        count: itemsPerPage,
        page: pageNumber,
      })
      .then(async res => {
        setLoading(false)
        setAllData(res?.data?.response?.reviews)
      })
      .catch(err => {
        console.error(err, "error")
      })
  }

  const handlePagination = pageNumber => {
    setCurrentPage(pageNumber)
    getReviews(pageNumber)
  }

  const createReview = async () => {
    const url = `https://www.polarpro.com${window.location.pathname}`
    await axios
      .post("/.netlify/functions/create-review", {
        sku: product_id,
        review_title: reviewTitle,
        review_content: reviewDescription,
        display_name: displayName,
        email,
        review_score: ratingStar,
        product_title: reviewData?.products[0]?.name,
        product_url: url,
        product_image_url: product.featuredImage.originalSrc,
      })
      .then(async res => {
        setToggle(null)
        setRatingStar(0)
        setEmail("")
        setDisplayName("")
        setReviewTitle("")
        setReviewDescription("")
      })
      .catch(err => {
        console.error(err, "error")
        setToggle(null)
        setRatingStar(0)
        setEmail("")
        setDisplayName("")
        setReviewTitle("")
        setReviewDescription("")
      })
  }
  const createQuestion = async () => {
    const url = `https://www.polarpro.com${window.location.pathname}`
    await axios
      .post("/.netlify/functions/create-question", {
        appkey: "qNmEg7HN0QiYfqn6n5DHRUGCtTUGNWa1GfSVsHRS",
        sku: product_id,
        review_content: question,
        display_name: displayName,
        email,
        product_title: reviewData?.products[0]?.name,
        product_url: url,
        product_image_url: product.featuredImage.originalSrc,
      })
      .then(async res => {
        setToggle(null)
        setEmail("")
        setDisplayName("")
        setQuestion("")
      })
      .catch(err => {
        console.error(err, "error")
        setToggle(null)
        setEmail("")
        setDisplayName("")
        setQuestion("")
      })
  }
  const handleCreateReview = () => {
    if (reviewTitle && reviewDescription && displayName && email && rating) {
      createReview()
    }
  }

  const handleCreateQuestion = () => {
    createQuestion()
  }

  const renderPageNumbers = () => {
    const totalPages = Math.ceil(totalItemsPerPage / itemsPerPage)
    const maxPageNumbers = 5
    const pageNumbers = []

    if (currentPage > 1) {
      pageNumbers.push(
        <div
          key="prev"
          className="cursor-pointer text-black"
          onClick={() => handlePagination(currentPage - 1)}
        >
          Prev
        </div>,
      )
    }

    for (
      let i = currentPage;
      i <= Math.min(currentPage + maxPageNumbers - 1, totalPages);
      i += 1
    ) {
      pageNumbers.push(
        <div
          key={i}
          className={`cursor-pointer ${
            i === currentPage ? "text-yellow-600" : "text-black"
          }`}
          onClick={() => handlePagination(i)}
        >
          {i}
        </div>,
      )
    }

    if (currentPage + maxPageNumbers <= totalPages) {
      pageNumbers.push(
        <div
          key="next"
          className="cursor-pointer text-black"
          onClick={() => handlePagination(currentPage + 1)}
        >
          Next
        </div>,
      )
    }

    return (
      <div className="pagination  gap-6 mt-4 flex flex-row">{pageNumbers}</div>
    )
  }
  return (
    <div className="w-full">
      {/* Ratings Section */}
      <div className=" w-full">
        <div className="flex  flex-col gap-6 md:gap-0 lg:flex-row md:justify-between items-center my-10">
          <div className=" w-full lg:w-[80%] justify-between">
            <div className="w-full">
              <div className="w-full flex lg:justify-between md:items-center flex-row ">
                <div className="set-up-rings flex items-center md:w-[60%] w-full gap-2 pr-2">
                  <p className="font-sans font-black lg:text-3xl text-lg">
                    {Number.isNaN(currentRating) ? 5 : currentRating}
                  </p>
                  <div id="ratingSection" className="scroll-mt-10">
                    <YotpoStarRatings
                      productShopifyId={productShopifyId}
                      product={product}
                    />
                    <p className="text-[12px]">
                      {reviewData?.bottomline?.total_review} Reviews,{" "}
                      {questionsData?.total_questions !== undefined &&
                      questionsData?.total_answers !== undefined
                        ? questionsData.total_answers +
                          questionsData.total_questions
                        : 0}{" "}
                      Q&As
                    </p>
                  </div>
                </div>
                <div className="block flex-1">
                  {reviewData?.bottomline?.star_distribution &&
                    Object.keys(reviewData?.bottomline?.star_distribution)
                      .reverse() // Reverse the array
                      .map(key => (
                        <div
                          key={key}
                          className="flex flex-col text-[12px] md:text-[14px] "
                        >
                          <div className="flex gap-1 items-baseline leading-none">
                            <p className="mb-2 w-[50px]">{key} Star</p>
                            <div className="relative bg-gray-400 w-[50px] sm:w-[150px] md:w-[200px] h-2">
                              <div
                                className="absolute bg-black"
                                style={{
                                  width: `${
                                    ((reviewData?.bottomline?.star_distribution[
                                      key
                                    ] || 0) /
                                      (reviewData?.bottomline?.total_review ||
                                        1)) *
                                    100
                                  }%`,
                                  height: "100%",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
          <div className=" w-full lg:w-[20%] ml-2 mt-3">
            <p
              onClick={() => handleDivClick("Review")}
              className="px-2 lg:w-[132px] py-[6px] cursor-pointer text-sm md:text-[12px] border-2 border-black rounded-md text-center font-sans font-bold"
            >
              WRITE A REVIEW
            </p>
            <p
              onClick={() => handleDivClick("Question")}
              className="px-2 lg:w-[132px] py-[6px] cursor-pointer  text-sm md:text-[12px] border-2 border-black rounded-md text-center font-sans font-bold"
            >
              ASK A QUESTION
            </p>
          </div>
        </div>
        {/* create review */}
        {toggle === "Review" && (
          <div className="shadow-lg py-8 px-4 flex flex-col  justify-around">
            <div>
              <h1 className="text-xl text-center">Create Review</h1>
              <div className="flex items-center gap-2 mt-2">
                {[1, 2, 3, 4, 5]?.map(rating => (
                  <div
                    className="text-yellow-500"
                    onClick={() => setRatingStar(rating)}
                  >
                    {ratingStar >= rating ? (
                      <FaStar className="cursor-pointer" />
                    ) : (
                      <FaRegStar className="cursor-pointer" />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:justify-between gap-4 lg:items-center mt-4 lg:mt-6">
              <div className="flex flex-col lg:items-center lg:flex-row lg:gap-2">
                <label className="mb-[5px] ">Email:</label>
                <input
                  id="text"
                  name="text"
                  onChange={e => setEmail(e.target.value)}
                  className="bg-white border text-md border-gray-400 rounded-lg py-2 px-4 resize-none focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
                  placeholder="Please write here..."
                />
              </div>
              <div className="flex flex-col lg:items-center lg:flex-row lg:gap-2">
                <label className="mb-[5px]">Display Name:</label>
                <input
                  id="text"
                  name="text"
                  onChange={e => setDisplayName(e.target.value)}
                  className="bg-white text-md border border-gray-400 rounded-lg py-2 px-4 resize-none focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
                  placeholder="Please write here..."
                />
              </div>
            </div>
            <div className="flex flex-col mt-6 ">
              <label className="mb-[5px]">Title</label>
              <textarea
                id="text"
                name="text"
                onChange={e => setReviewTitle(e.target.value)}
                className="bg-white border border-gray-400 rounded-lg py-2 px-4 resize-none focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
                placeholder="Please write here..."
              />
            </div>
            <div className="flex flex-col mt-4 ">
              <label className="mb-[5px]">Description</label>

              <textarea
                id="text"
                name="text"
                onChange={e => setReviewDescription(e.target.value)}
                className="bg-white min-h-[130px] border border-gray-400 rounded-lg py-2 px-4 resize-none focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
                placeholder="Please write here..."
              />
            </div>
            <div>
              <button
                type="submit"
                onClick={() => handleCreateReview()}
                className="mt-4 border-[2px] px-4 py-2 rounded-lg bg-yellow-500 text-white hover:text-yello-400 cursor-pointer"
              >
                Submit
              </button>
            </div>
          </div>
        )}

        {/*  Question */}
        {toggle === "Question" && (
          <div className="shadow-lg py-8 px-4 flex flex-col  justify-around">
            <div>
              <h1 className="text-xl text-center">Create Question</h1>
            </div>
            <div className="flex flex-col gap-4 lg:flex-row lg:justify-between lg:items-center mt-4 lg:mt-6">
              <div className="flex flex-col lg:flex-row lg:items-center lg:gap-2">
                <label className="mb-[5px] ">Email:</label>
                <input
                  id="text"
                  name="text"
                  onChange={e => setEmail(e.target.value)}
                  className="bg-white border text-md border-gray-400 rounded-lg py-2 px-4 resize-none focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
                  placeholder="Please write here..."
                />
              </div>
              <div className="flex flex-col lg:flex-row lg:items-center lg:gap-2">
                <label className="mb-[5px]">Display Name:</label>
                <input
                  id="text"
                  name="text"
                  onChange={e => setDisplayName(e.target.value)}
                  className="bg-white text-md border border-gray-400 rounded-lg py-2 px-4 resize-none focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
                  placeholder="Please write here..."
                />
              </div>
            </div>

            <div className="flex flex-col mt-4 ">
              <label htmlFor="text" className="mb-[5px]">
                Your Question:
              </label>
              <textarea
                id="text"
                name="text"
                onChange={e => setQuestion(e.target.value)}
                className="bg-white text-md min-h-[130px] border border-gray-400 rounded-lg py-2 px-4 resize-none focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
                placeholder="Please write here..."
              />
            </div>
            <div>
              <button
                type="submit"
                onClick={() => handleCreateQuestion()}
                className="mt-4 border-[2px] px-4 py-2 rounded-lg bg-yellow-500 text-white hover:text-yello-400 cursor-pointer"
              >
                Submit
              </button>
            </div>
          </div>
        )}

        <div></div>
      </div>
      {/* Reviews and Questions */}
      <div className="py-1 my-10 border-b flex gap-4 ">
        <button
          type="button"
          className={`${
            selectedReviews === "Reviews"
              ? "font-bold underline-offset-4 underline decoration-2"
              : ""
          } font-sans`}
          onClick={() => setSelectedReviews("Reviews")}
        >
          Reviews
        </button>
        <button
          type="button"
          className={`${
            selectedReviews === "Questions"
              ? "font-bold underline-offset-4 underline decoration-2"
              : ""
          } font-sans`}
          onClick={() => setSelectedReviews("Questions")}
        >
          Questions
        </button>
      </div>

      {/* Reviews Section */}
      {selectedReviews === "Reviews" && (
        <div className="py-1 my-10 border-b">
          <div className=" w-full">
            <p className="font-bold font-sans">Reviews</p>
            <div className="flex justify-center items-center gap-1 px-2 border rounded-md border-grey-400 w-[300px] sm:w-[500px]">
              {" "}
              <div>
                <BiSearchAlt2 />
              </div>
              <input
                className="pl-2 py-2 w-[250px] sm:w-[400px]    "
                placeholder="Search Reviews"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </div>
            <div className="flex w-[300px] sm:w-[550px] mt-5 flex-wrap gap-2">
              {reviewData?.product_tags &&
                reviewData?.product_tags?.map(tag => (
                  <p className="px-2 py-1 mb-0 border border-grey-400 text-grey-400 rounded-2xl">
                    {tag}
                  </p>
                ))}
            </div>
          </div>

          <UserReviews allReviews={allData} />
          <div className="flex flex-wrap items-center gap-4 text-[15px]">
            {loading ? (
              <div className="relative w-4 h-4 mb-4">
                <div className="absolute top-0 left-0 w-full h-full rounded-full border-2 border-t-0 border-gray-700 animate-spin"></div>
                <div className="absolute top-0 left-0 w-full h-full rounded-full border-2 border-t-0 border-gray-900 animate-ping"></div>
              </div>
            ) : (
              <div>{renderPageNumbers()}</div>
            )}
          </div>
        </div>
      )}
      {/* Questions Section */}
      {selectedReviews === "Questions" && (
        <div className="py-1 my-10 border-b">
          <div className=" w-full">
            <p className="font-bold font-sans">Questions</p>
            <div className="flex justify-center items-center gap-1 px-2 border rounded-md border-grey-400 w-[300px] sm:w-[500px]">
              {" "}
              <div>
                <BiSearchAlt2 />
              </div>
              <input
                className="pl-2 py-2 w-[250px] sm:w-[400px]    "
                placeholder="Search Reviews"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </div>
            <div className="flex w-[300px] sm:w-[550px] mt-5 flex-wrap gap-2">
              <UserQuestions allQuestions={questionsData?.questions} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default RatingsAndComments
