/* eslint-disable */

import React, { useState, useEffect } from "react"
import Seo from "../../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import { useStoryblok } from "../../utils/storyblok"
import DynamicComponents from "../../components/DynamicComponents"
import loadable from "@loadable/component"
import useScrollBlock from "../../hooks/useScrollBlock"

import "./pivotOverview.scss"

const ModalVideo = loadable(() => import("react-modal-video"))

const PivotOverview = () => {
  const [showVideo, setShowVideo] = useState(false)
  const [showVideo2, setShowVideo2] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    if (showVideo || showVideo2) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [showVideo, showVideo2])

  const pageData = useStaticQuery(graphql`
    query {
      storyblokEntry(full_slug: { eq: "pages/pivot" }) {
        id
        name
        content
        slug
        full_slug
        uuid
        created_at
      }
      shopifyProduct(handle: { eq: "pivot-shoulder-rig" }) {
        handle
        title
      }
    }
  `)

  const product = pageData.shopifyProduct

  const story = pageData.storyblokEntry
    ? useStoryblok(pageData.storyblokEntry)
    : { content: null }

  const stickyNavComponent =
    story.content && story.content["sticky_nav"]
      ? story.content["sticky_nav"].map(blok => (
          <>
            {blok ? (
              <div {...sbEditable(blok)} className="sticky top-0 z-50">
                <DynamicComponents
                  blok={blok}
                  product={product}
                  key={blok._uid}
                />
              </div>
            ) : null}
          </>
        ))
      : null
  return (
    <>
      <Seo title="Pivot Overview" />
      <div className="sticky top-0 z-50">{stickyNavComponent}</div>
      <div class="pivot-overview">
        <div class="product-hero container-wrapper">
          <div class="intro-data">
            <h2 className="display-font-logo font-bold">
              The Most Compact & Versatile Shoulder Rig.
            </h2>
            <p>
              The Pivot Shoulder Rig was specifically designed for run-n-gun
              filmmakers looking for an ultra-lightweight, minimal, and rugged
              shoulder rig solution. Weighing in at under 4lbs, Pivot can be
              setup in over 95 configurations all while being completely
              tool-free for setup and breakdown.
            </p>
          </div>
        </div>

        <div class="video-container">
          <a
            href="#data"
            id="inline"
            className="cta-round play-button x-y-center"
            onClick={e => {
              e.preventDefault()
              setShowVideo(true)
            }}
          />
          {/* modal */}
          <ModalVideo
            channel="youtube"
            videoId="LA62Zx7tZIY"
            isOpen={showVideo}
            onClose={() => setShowVideo(false)}
          />
        </div>

        <div class="features">
          <h3 className="display-font-logo font-bold section-title">
            197 Custom-Engineered Parts
          </h3>
          <video
            width="100%"
            height="100%"
            autoPlay
            muted
            loop
            playsInline
            className="mb-10"
          >
            <source
              src="https://cdn.shopify.com/videos/c/o/v/2cf6e78a651e4654bbc1743b92a1b205.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>

          <div className="flex flex-row-reverse">
            <div className="sm:w-full w-[100vw] flex lg:w-1/3 flex-col items-center justify-center bg-cover h-[92vw] lg:h-auto bg-[url('https://cdn.shopify.com/s/files/1/1050/9944/files/From_Storage_to_Setup_In_Just_3_Clicks_Banner_Mobile.webp?v=1658342020')] lg:bg-none">
              <h3 className="font-black text-4xl lg:text-2xl 2xl:text-4xl px-[4vw] text-center">
                From Storage to Setup In Just 3 Clicks
              </h3>
              <p className="text-center px-[4vw]">
                Pivot was designed for instant setup times without tools.
              </p>
            </div>
            <div class="hidden lg:block lg:w-2/3">
              <img
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/From_Storage_to_Setup_In_Just_3_Clicks_Banner_Desktop.webp?v=1658340516"
                className="hidden lg:block m-0"
              />
            </div>
          </div>

          <div className="flex">
            <div className="sm:w-full w-[100vw] flex lg:w-1/3 flex-col items-center justify-center bg-cover h-[92vw] lg:h-auto bg-[url('https://cdn.shopify.com/s/files/1/1050/9944/files/Built_In_ARRI_Rosettes_Banner_Mobile.webp?v=1658342020')] lg:bg-none">
              <h3 className="font-black text-4xl lg:text-2xl 2xl:text-4xl px-[4vw] text-center">
                Built-In ARRI Rosettes
              </h3>
              <p className="text-center px-[4vw]">
                Seamlessly mount a follow focus, controller grip, or any other
                compatible handle for unlimited control.
              </p>
            </div>
            <div class="hidden lg:block lg:w-2/3">
              <img
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/Built-In_ARRI_Rosettes_Banner_Desktop.webp?v=1658340516"
                className="hidden lg:block m-0"
              />
            </div>
          </div>

          <div className="flex flex-row-reverse">
            <div className="sm:w-full w-[100vw] flex lg:w-1/3 flex-col items-center justify-center bg-cover h-[92vw] lg:h-auto bg-[url('https://cdn.shopify.com/s/files/1/1050/9944/files/Forward_Sliding_Base_Plate_Banner_Mobile.webp?v=1658342020')] lg:bg-none">
              <h3 className="font-black text-4xl lg:text-2xl 2xl:text-4xl px-[4vw] text-center">
                Forward-Sliding Base Plate
              </h3>
              <p className="text-center px-[4vw]">
                This allows for a perfect viewing angle when using your camera's
                built-in monitor.
              </p>
            </div>
            <div class="hidden lg:block lg:w-2/3">
              <img
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/Forward_Sliding_Base_Plate_Banner_Desktop.webp?v=1658340516"
                className="hidden lg:block m-0"
              />
            </div>
          </div>
        </div>

        <div class="features-banner">
          <h3 className="display-font-logo font-bold section-title mt-20">
            Engineered For Run-N-Gun Filmmakers
          </h3>
          <div class="highlight-container container-wrapper">
            <img
              class="desktop-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Extendable_Rods_Block_Desktop.webp?v=1658349601"
              alt=""
            />
            <img
              class="mobile-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Extendable_Rods_Block_Mobile.webp?v=1658349580"
              alt=""
            />

            <img
              class="desktop-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/ARRI_Standard_Rosettes_Block_Desktop.webp?v=1658349601"
              alt=""
            />
            <img
              class="mobile-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/ARRI_Standard_Rosettes_Block_Mobile.webp?v=1658349580"
              alt=""
            />

            <img
              class="desktop-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Grip_Adjustments_Block_Desktop.webp?v=1658349601"
              alt=""
            />
            <img
              class="mobile-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Grip_Adjustments_Block_Mobile.webp?v=1658349580"
              alt=""
            />
          </div>
          <h3 className="display-font-logo font-bold section-title mt-20">
            Video Plate Compatibility
          </h3>

          <img
            className="desktop-only w-[90vw] m-auto mb-20"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Compatibility_Chart_Desktop-Tablet.webp?v=1658351380"
            alt=""
          />
          <img
            className="mobile-only p-8"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Compatibility_Chart_Mobile.webp?v=1658351405"
            alt=""
          />
        </div>

        <div class="system-overview">
          <img
            class="mobile-only"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Pivot-Glamour-Block-1-Mobile.webp?v=1647909392"
            alt=""
          />
          <div class="overview-1">
            <div class="data">
              <a
                id="system-overview-play-btn"
                href="#data"
                className="play-button small"
                onClick={e => {
                  e.preventDefault()
                  setShowVideo2(true)
                }}
              />
              {/* modal */}
              <ModalVideo
                channel="youtube"
                videoId="rYFWGqjrqbI"
                isOpen={showVideo2}
                onClose={() => setShowVideo2(false)}
              />
            </div>
          </div>
          <div class="overview-2">
            <img
              class="desktop-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Glamour_Shots_Block_2_Desktop.webp?v=1658432251"
              alt=""
            />
            <img
              class="mobile-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Glamour_Shots_Block_2_Mobile.webp?v=1658432607"
              alt=""
            />
          </div>
          <div class="overview-3">
            <img
              class="desktop-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Pivot-Glamour-Shots-Block-3-Desktop.webp?v=1647908606"
              alt=""
            />
            <img
              class="mobile-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Pivot-Glamour-Block-4-Mobile.webp?v=1647909392"
              alt=""
            />
          </div>
        </div>

        <div class="build-kit">
          <img
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Build-Your-Kit-Mark_72f50798-d363-4207-821a-4e3997a3fc4d.png?v=1642644431"
            alt=""
            class="be-prepare"
          />
          <a
            href="/products/pivot-shoulder-rig"
            class="bc-build-btn font-extrabold"
          >
            Shop Pivot System here
          </a>
        </div>
      </div>
    </>
  )
}

export default PivotOverview
