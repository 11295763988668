/* eslint-disable */
import React from "react"
import { Link } from "gatsby"

const Spec = ({ spec }) => {
  const { Title, Data } = spec

  return (
    <div className="spec">
      <p className="specTitle font-bold uppercase">{Title}</p>
      <p className="specData text-base">{Data}</p>
    </div>
  )
}

export default Spec
