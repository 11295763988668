/* eslint-disable */

import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery, Link } from "gatsby"
import FocusEpisodeS2 from "./focusEpisodeS2"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2500 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 2500, min: 1600 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1600, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
}

const EpisodeListS2 = ({ carouselRef, afterChange, onClickPlay }) => {
  const data = useStaticQuery(graphql`
    query {
      storefront {
        blog(handle: "Focus-S2") {
          title
          handle
          articles(first: 250) {
            edges {
              node {
                handle
                title
                excerpt
                contentHtml
                image {
                  src
                }
                videoUrl: metafield(
                  namespace: "global"
                  key: "Focus-s2-video-url"
                ) {
                  key
                  value
                }
                episode: metafield(
                  namespace: "global"
                  key: "Focus-s2-episode"
                ) {
                  key
                  value
                }
                duration: metafield(
                  namespace: "global"
                  key: "Focus-s2-duration"
                ) {
                  key
                  value
                }
                by: metafield(namespace: "global", key: "Focus-s2-by") {
                  key
                  value
                }
                byImg: metafield(namespace: "global", key: "Focus-s2-by-img") {
                  key
                  value
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div>
      <Carousel
        responsive={responsive}
        focusOnSelect={true}
        draggable={true}
        arrows={false}
        ref={carouselRef}
        afterChange={afterChange}
      >
        {data.storefront.blog.articles.edges.map((edge, index) => {
          return (
            <div key={index}>
              <FocusEpisodeS2 article={edge} onClickPlay={onClickPlay} />
            </div>
          )
        })}
      </Carousel>
    </div>
  )
}

export default EpisodeListS2
