/* eslint-disable */
import React, { useMemo } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { useNavigate } from "@reach/router"
import YotpoStarRatings from "../YotpoStarRatings"
import "./style.css"

const VariantCard = ({ variant, description, descriptionhtml }) => {
    function sanitizeString(input) {
        let sanitized = ""
        for (let i = 0; i < input.length; i++) {
            const char = input[i]
            if (
                (char >= "a" && char <= "z") ||
                (char >= "A" && char <= "Z") ||
                (char >= "0" && char <= "9") ||
                char === "-"
            ) {
                sanitized += char
            }
        }
        return sanitized
    }

    const cleanNameOfVariant = useMemo(() => {
        const resp = decodeURIComponent(variant?.title || "")
        const sanitized = sanitizeString(resp)
        return sanitized
    }, [variant])

    const navigate = useNavigate()
    const handleNavigate = () => {
        const nameValue = variant.displayName
        navigate(`/products/${variant.product.handle}`, {
            state: { name: nameValue },
        })
    }

    const shopifyIdExtractor = id => id?.slice(id.lastIndexOf("/") + 1)
    const variantShopifyId = shopifyIdExtractor(variant?.product?.shopifyId)

    const displayName = useMemo(() => {
        const productTitle = variant?.product?.title ?? "";
        const optionValue = variant?.selectedOptions?.[0]?.value ?? "";
        return {
            productTitle,
            optionValue
        };
    }, [variant]);

    return (
        <Link
            to={`/products/${variant?.product?.handle}${cleanNameOfVariant?.length ? `?variant=${cleanNameOfVariant}` : ""
                }
            `}
            className="variant-card"
            onClick={handleNavigate}
        >
            <div className="variant-card">
                {variant?.image ? (
                    <div className="VariantImage bg-white rounded-[20px] flex justify-center items-center min-h-[24.5vw] md:min-h-[17vw] lg:min-h-[12vw] xl:min-h-[8vw]">
                        <img
                            src={variant?.image?.src}
                            alt={variant.title}
                            className="2xl:max-h-[130.83px] xl:max-h-[97px] lg:max-h-[128px] md:max-h-[142px] sm:max-h-[116px] max-h-[94px] mb-0"
                        />
                    </div>
                ) : (
                    <div className="VariantImage bg-white px-[15%] rounded-[20px]">
                        <img src={variant?.image?.src} alt={variant.title} />
                    </div>
                )}

                <div className="hoverDataContainer">
                    <span className="text-xs text-[#1C1C1C] font-black font-expanded block uppercase mb-2 mt-[20px] tracking-wider">
                        {displayName?.productTitle ? (
                            <>
                                {displayName.productTitle}<br /><span className={`${variant?.selectedOptions?.length ? "!font-semibold" : null}`}>{`(${displayName.optionValue})`}</span>
                            </>
                        ) : null}
                    </span>
                </div>
                <YotpoStarRatings
                    product={variant}
                    productShopifyId={variantShopifyId}
                />
                {description ? (
                    <p className="quickview text-xs text-[#949494] font-condensed !mb-0">
                        {description}
                    </p>
                ) : (
                    <div>
                        {descriptionhtml?.map(metafield => {
                            let quickviewDesc = ""
                            if (metafield.key === "quickview-content") {
                                quickviewDesc = metafield.value
                            } else {
                                return
                            }
                            return (
                                <div
                                    className="quickview-Description text-xs text-[#949494] font-condensed "
                                    dangerouslySetInnerHTML={{ __html: quickviewDesc }}
                                ></div>
                            )
                        })}
                    </div>
                )}
                <div>
                    <p className="text-[.8rem] text-[#1C1C1C] font-bold ml-1 font-condensed mt-2 ">
                        ${variant ? variant?.price : null}
                    </p>
                </div>
            </div>
        </Link>
    )
}

export default VariantCard
