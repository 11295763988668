import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import "./legacyBlog.scss"

const LegacyBlogTemplate = ({ pageContext }) => {
  const { article } = pageContext
  const {
    title,
    tags,
    // handle,
    contentHtml,
  } = article

  const commaSepTags = tags.join(",")

  return (
    <div>
      <Seo title={title} />
      <Helmet>
        <meta name="keywords" content={commaSepTags} />
      </Helmet>
      <div
        id="blog-wrapper"
        dangerouslySetInnerHTML={{ __html: contentHtml }}
      />
    </div>
  )
}

export default LegacyBlogTemplate

LegacyBlogTemplate.propTypes = {
  article: PropTypes.shape({
    title: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
    handle: PropTypes.string.isRequired,
    contentHtml: PropTypes.string.isRequired,
  }),
}

LegacyBlogTemplate.defaultProps = {
  article: {
    tags: ["polarpro", "adventure", "camera", "filters"],
  },
}
