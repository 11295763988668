import React, { useState, useEffect, useMemo, useRef } from "react"
import { sbEditable } from "@storyblok/storyblok-editable"
import { useStoryblok } from "../utils/storyblok"
import DynamicComponents from "../components/DynamicComponents"

import CollectionHero from "../components/collectionHero/collectionHero"
import ProductCard from "../components/ProductCard/index.js"
import Spinner from "../components/Icons/Progress/index.js"
import "./collections.scss"
import { progressStyle } from "../pages/searchPage.module.css"
import CollectionPagination from "../components/CollectionPagination"
import Seo from "../components/seo"
import { CheckFilter } from "../components/CheckFilter"
import VariantCard from "../components/VariantCard"

const DEFAULT_PRODUCTS_PER_PAGE = 24

const Collection = ({ pageContext }) => {
  const {
    collection,
    product,
    storyblokEntry,
    responseOfAllVariantsOnCollection,
  } = pageContext
  const story = storyblokEntry
    ? useStoryblok(storyblokEntry.node)
    : { content: null }
  const queryParams = {
    maxPrice: undefined,
    minPrice: undefined,
    productTypes: [],
    sortKey: undefined,
    tags: [],
    term: undefined,
    vendors: [],
  }

  const [isLoading, setIsLoading] = useState(false)
  const [filters, setFilters] = useState(queryParams)
  const [showModal, setShowModal] = useState(false)
  const [filterTagLibraryState, setFilterTagLibraryState] = useState({})
  const [tagArrayState, setTagArrayState] = useState([])
  const [showFilters, setShowFilters] = useState(false)
  const [currentPageAllProducts, setCurrentPageAllProducts] = useState(
    collection.products,
  )

  const [currentPageProducts, setCurrentPageProducts] = useState([])
  const [currentPageVariants, setCurrentPageVariants] = useState([])

  const [currentPageNumber, setCurrentPageNumber] = useState(1)

  const initialCollectionPagination = Math.ceil(
    collection.products.length / DEFAULT_PRODUCTS_PER_PAGE,
  )
  const [totalCollectionPageNumber, setTotalCollectionPageNumber] = useState(
    initialCollectionPagination,
  )
  const [paginationArray, setPaginationArray] = useState([])
  const [selectedFilters, setSelectedFilters] = useState({})
  const [isStickyNavVisible, setIsStickyNavVisible] = useState(false)
  const [filterData, setFilterData] = useState([])

  const toggleFilters = () => {
    setShowFilters(prev => !prev)
  }

  useEffect(() => {
    if (!showModal) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    }
  }, [currentPageNumber, showModal])

  const createPaginationArray = totalPages => {
    const paginationArray = []
    for (let i = 0; i < totalPages; i += 1) {
      paginationArray.push(i + 1)
    }

    setPaginationArray(paginationArray)
  }
  useEffect(() => {
    createPaginationArray(totalCollectionPageNumber)
  }, [totalCollectionPageNumber, currentPageAllProducts])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    // TODO: Set a state that tracks current products length
    const PRODUCT_LISTINGS_ON_PAGE =
      DEFAULT_PRODUCTS_PER_PAGE * currentPageNumber
    const totalProducts = currentPageAllProducts.length
    const initialCollectionPagination = Math.ceil(
      totalProducts / DEFAULT_PRODUCTS_PER_PAGE,
    )
    setTotalCollectionPageNumber(initialCollectionPagination)

    setCurrentPageProducts(
      PRODUCT_LISTINGS_ON_PAGE > totalProducts
        ? currentPageAllProducts.slice(
          PRODUCT_LISTINGS_ON_PAGE - 24,
          totalProducts,
        )
        : currentPageAllProducts.slice(
          PRODUCT_LISTINGS_ON_PAGE - 24,
          PRODUCT_LISTINGS_ON_PAGE,
        ),
    )
    scrollToTop()
  }, [currentPageNumber, currentPageAllProducts])

  /*
   * Use filteredProducts to load in products that are filtered by tags
   * Update currentPageProducts state with the filteredProducts when filtration is done.
   * use checkFilters for setting previous page and next page
   */

  const tagSet = new Set()
  const tagTitleSet = new Set()
  const filterTagLibrary = {}

  useEffect(() => {
    const totalProducts = collection.products.length

    collection.products.forEach(product => {
      const productTagsCollection = new Set()

      product.tags.forEach(tag => {
        if (tag.includes(":")) {
          tagSet.add(tag)
          productTagsCollection.add(tag)
        }
      })

      product.clientTags = productTagsCollection
    })

    tagSet.forEach(tag => {
      const colonPosition = tag.indexOf(":")
      const productType = tag.slice(colonPosition + 1)

      tagTitleSet.add(tag.slice(0, colonPosition))

      if (filterTagLibrary[tag.slice(0, colonPosition)]) {
        filterTagLibrary[tag.slice(0, colonPosition)].push(productType)
      } else {
        filterTagLibrary[tag.slice(0, colonPosition)] = [productType]
      }
    })

    setCurrentPageProducts(
      collection.products.slice(
        0,
        DEFAULT_PRODUCTS_PER_PAGE < totalProducts
          ? DEFAULT_PRODUCTS_PER_PAGE
          : totalProducts,
      ),
    )

    const tagArray = Array.from(tagTitleSet)

    setTagArrayState(tagArray)
    setFilterTagLibraryState({ ...filterTagLibrary })
  }, [])

  useEffect(() => {
    if (filters.tags.length === 0) {
      setCurrentPageAllProducts(collection.products)
    } else {
      const filteredProducts = []
      const selectedTagsSet = new Set(filters.tags)
      for (const product of collection.products) {
        for (const element of selectedTagsSet) {
          if (product.clientTags.has(element)) {
            filteredProducts.push(product)
            break
          }
        }
      }
      setCurrentPageAllProducts(filteredProducts)
    }
  }, [filters])

  useEffect(() => {
    setIsStickyNavVisible(
      story.content &&
      story.content.sticky_nav &&
      story.content.sticky_nav.length > 0,
    )
  }, [story.content])

  const stickyNavComponent =
    story.content && story.content.sticky_nav
      ? story.content.sticky_nav.map(blok => (
        <>
          {blok ? (
            <div {...sbEditable(blok)} className="sticky top-0 z-50">
              <DynamicComponents
                blok={blok}
                product={product}
                key={blok._uid}
              />
            </div>
          ) : null}
        </>
      ))
      : null
  useEffect(() => {
    const script = document.createElement("script")
    script.src =
      "https://staticw2.yotpo.com/qNmEg7HN0QiYfqn6n5DHRUGCtTUGNWa1GfSVsHRS/widget.js"
    script.async = true
    document.head.appendChild(script)

    script.onload = e => {
      window.yotpo.refreshWidgets()
    }

    return () => {
      script.remove()
    }
  })

  useEffect(() => {
    const tagMap = {}

    collection.products.forEach(product => {
      product.clientTags.forEach(tag => {
        const [prefix, value] = tag.split(":")
        if (!tagMap[prefix]) {
          tagMap[prefix] = new Set()
        }
        tagMap[prefix].add(value)
      })
    })
    const variantTagMap = {}
    responseOfAllVariantsOnCollection?.forEach(variant => {
      variant?.storyblokVariant?.tags?.map(tags => {
        if (tags?.tags?.includes(":")) {
          const [prefix, value] = tags?.tags?.split?.(":") || []
          if (!variantTagMap[prefix]) {
            variantTagMap[prefix] = new Set()
          }
          variantTagMap[prefix].add(value)
          return tags
        } return null
      })
    })

    Object.keys(variantTagMap).forEach(key => {
      if (tagMap[key]) {
        tagMap[key] = new Set([...tagMap[key], ...variantTagMap[key]])
      } else {
        tagMap[key] = variantTagMap[key]
      }
    })

    const dynamicFilterData = Object.keys(tagMap).map(key => ({
      name: key,
      options: Array.from(tagMap[key]),
    }))

    setFilterData(dynamicFilterData)
  }, [collection.products])

  const setSelectedItems = (name, items) => {
    setSelectedFilters(pre => ({
      ...pre,
      [name]: items,
    }))
  }

  useEffect(() => {
    const filteredProducts = collection.products.filter(product => {
      const tags = Object.values(product.tags)
        .flat()
        .map(item => item.split(":")[1])
        .filter(tag => tag !== undefined)

      const newTags = Object.values(selectedFilters)
        .flat()
        .map(item => item.split(":")[1])

      const allIncluded = newTags.some(value => tags.includes(value))

      if (newTags.length === 0) return true
      return allIncluded
    })

    try {
      const filteredVariants = responseOfAllVariantsOnCollection?.filter(
        variant => {
          const tags = variant?.storyblokVariant?.tags
            ?.map(tag => tag.tags?.split(":")?.[1] || null)
            .filter(tag => tag)

          const newTags = Object.values(selectedFilters)
            .flat()
            .map(item => item.split(":")[1])

          const allIncluded = newTags.some(value => tags.includes(value))

          if (newTags.length === 0) return true
          return allIncluded
        },
      )

      setCurrentPageProducts(filteredProducts)
      setCurrentPageVariants(filteredVariants)
    } catch (err) {
      console.error(err)
    }
  }, [selectedFilters, collection.products, responseOfAllVariantsOnCollection])
  useEffect(() => {
    if (
      currentPageNumber >
      Math.ceil(currentPageAllProducts.length / DEFAULT_PRODUCTS_PER_PAGE)
    ) {
      setCurrentPageNumber(1)
    }
  }, [currentPageAllProducts])

  const classStyle = {
    overflowY: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    WebkitOverflowScrolling: "touch",
  }

  return (
    <>
      <Seo title={collection.title} description={collection.description} />
      <div className="bg-gray-100 ">
        <CollectionHero image={collection.image} />
      </div>
      {stickyNavComponent}

      <div
        className={`w-full flex items-start ${selectedFilters ? "bg-gray-100" : ""
          }`}
      >
        {filterData?.length > 0 &&
          filterData.some(item => item.options.length > 0) && (
            <>
              <div
                className={`scrollBarStyle bg-white w-full md:w-1/3 lg:w-1/6 fixed md:sticky ${isStickyNavVisible ? "top-10" : "top-0"
                  } h-screen overflow-hidden z-[99999] lg:z-0  ${showFilters ? "block" : "hidden md:block "
                  }`}
                style={classStyle}
              >
                <div className="w-full flex justify-between block md:hidden items-center mt-4 px-3">
                  <div className="font-bold">Filters</div>
                  <button
                    type="button"
                    className="block md:hidden"
                    onClick={toggleFilters}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        d="M6.343 6.571l11.314 11.314M6.343 17.885L17.657 6.571"
                      ></path>
                    </svg>
                  </button>
                </div>
                <>
                  {filterData.map((item, index) => (
                    <CheckFilter
                      items={item.options}
                      name={item.name}
                      selectedItems={selectedFilters[item.name] || []}
                      index={index}
                      setSelectedItems={items => {
                        setSelectedItems(item.name, items)
                      }}
                    />
                  ))}
                </>
              </div>
            </>
          )}

        <div className="w-full relative collections-wrapper">
          <CollectionHero
            title={collection.title}
            toggleFilters={toggleFilters}
          />
          <div className="w-full mx-auto px-[15px] xl:px-[7rem] max-w-max">
            <div className="flex flex-wrap w-full mx-auto collections-below-wrapper px-1 pt-10 pb-10 md:px-0 mb-0">
              <div
                className={`w-full ${showModal ? "hidden" : "grid"} md:grid`}
              >
                <div className="grid collectionsGrid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-x-5 md:gap-x-10 gap-y-5">
                  {currentPageVariants ? (
                    isLoading ? (
                      <p className={progressStyle}>
                        <Spinner aria-valuetext="Searching" /> Loading...
                      </p>
                    ) : (
                      <>
                        {currentPageProducts.map(product => (
                          <ProductCard key={product?.id} product={product} />
                        ))}
                        {currentPageVariants?.map(variant => (
                          <VariantCard
                            key={variant.id}
                            variant={variant}
                            product={variant.product}
                            description={variant.storyblokVariant?.description}
                            descriptionhtml={variant.product?.metafields}
                          />
                        ))}
                      </>
                    )
                  ) : isLoading ? (
                    <p className={progressStyle}>
                      <Spinner aria-valuetext="Searching" /> Loading...
                    </p>
                  ) : (
                    [
                      ...currentPageProducts,
                      ...((currentPageVariants?.length
                        ? currentPageVariants
                        : responseOfAllVariantsOnCollection) || []),
                    ]?.map(cProduct => {
                      const isVariant = cProduct?.shopifyId?.includes(
                        "gid://shopify/ProductVariant",
                      )
                      if (isVariant) {
                        return (
                          <VariantCard
                            key={cProduct.id}
                            variant={cProduct}
                            product={cProduct.product}
                            description={
                              cProduct?.storyblokVariant?.description
                            }
                            descriptionhtml={cProduct?.product?.metafields}
                          />
                        )
                      }
                      return (
                        <ProductCard product={cProduct} key={cProduct.handle} />
                      )
                    })
                  )}
                </div>

                {totalCollectionPageNumber > 1 ? (
                  <CollectionPagination
                    paginationArray={paginationArray}
                    totalCollectionPageNumber={totalCollectionPageNumber}
                    currentPageNumber={currentPageNumber}
                    setCurrentPageNumber={setCurrentPageNumber}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Collection
