import React from "react"
import {
  specContainer,
  chartComparison,
  version,
  leftTh,
  subTitle,
  bar,
  qlSectionTitle,
} from "./style.module.scss"

const FXFiltersSpecsChart = () => (
  <div className={`${specContainer} mb-20`}>
    <h3 className={`${qlSectionTitle} text-center mb-4`}>FX Filter Specs</h3>
    <p className={subTitle}>An in-depth look at materials and construction.</p>
    <div className={bar} />

    <div className={chartComparison}>
      <div className={version}>
        <table style={{ width: "100%" }}>
          <tr>
            <th />
            {/* eslint-disable-line */}
            <th>Mist/Mist H</th>
            <th>Blue/GoldMorphic</th>
          </tr>
          {/* <!-- glass spec --> */}
          <tr>
            <td className={`${leftTh} uppercase font-bold`}>Glass Material:</td>
            <td>Cinema Series&trade;</td>
            <td>Cinema Series&trade;</td>
          </tr>
          <tr>
            <td className={`${leftTh} uppercase font-bold`}>Frame Material:</td>
            <td>Aluminium</td>
            <td>Aluminium</td>
          </tr>
          <tr>
            <td className={`${leftTh} uppercase font-bold`}>
              Available Thread Sizes:
            </td>
            <td>67mm, 77mm, 82mm</td>
            <td>67mm, 77mm, 82mm</td>
          </tr>
          {/* <!-- build spec --> */}
          <tr>
            <td className={`${leftTh} uppercase font-bold`}>Diameter:</td>
            <td>
              <p>67 = 70mm</p>
              <p>77 = 80mm</p>
              <p>82 = 85mm</p>
            </td>
            <td>
              <p>67 = 70.5mm</p>
              <p>77 = 80.5mm</p>
              <p>82 = 85.5mm</p>
            </td>
          </tr>
          <tr>
            <td className={`${leftTh} uppercase font-bold`}>Depth:</td>
            <td>
              <p>67 = 6.5mm</p>
              <p>77 = 6.5mm</p>
              <p>82 = 6.5mm</p>
            </td>
            <td>
              <p>67 = 7.5mm</p>
              <p>77 = 7.5mm</p>
              <p>82 = 7.5mm</p>
            </td>
          </tr>
          <tr>
            <td className={`${leftTh} uppercase font-bold`}>Weight:</td>
            <td>
              <p>67 = 29g</p>
              <p>77 = 37g</p>
              <p>82 = 37.5g</p>
            </td>
            <td>
              <p>67 = 32.9g</p>
              <p>77 = 38.8g</p>
              <p>82 = 43.5g</p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
)

export default FXFiltersSpecsChart
