import React from "react"
import PropTypes from "prop-types"

const LCPNotice = ({ blok }) => (
  <div className="bg-grey-tini-light h-[30px] p-[2px] m-auto mt-[20px] mb-[30px] w-[98%] text-center">
    <p className="text-center text-xs md:text-sm font-medium mb-0 pt-1">
      {blok.notice}
    </p>
  </div>
)

export default LCPNotice

LCPNotice.propTypes = {
  blok: PropTypes.shape({
    notice: PropTypes.string.isRequired,
  }).isRequired,
}
