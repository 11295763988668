import React, { memo, useEffect, useMemo, useRef, useState } from "react"
import { IoIosArrowDown } from "react-icons/io"
import "./index.css"
import { Link } from "@reach/router"

if (typeof window !== "undefined") {
  var L = require("leaflet")
  var MapContainer = require("react-leaflet").MapContainer
  var TileLayer = require("react-leaflet").TileLayer
  var Marker = require("react-leaflet").Marker
  var Popup = require("react-leaflet").Popup
  var useMap = require("react-leaflet").useMap
}
const InnerComponent = memo(
  ({
    center,
    filteredLocations,
    myIcon,
    userLocation,
    userIcon,
    isClicked,
    setIsClicked,
  }: {
    center: any
    filteredLocations: any
    myIcon: any
    userLocation: any
    userIcon: any
    isClicked: any
    setIsClicked: any
  }) => {
    const calculateBounds = (locations: any) => {
      if (!locations || locations.length === 0) return
      const bounds = new L.LatLngBounds(
        locations.map((loc: any) => L.latLng(loc.position)),
      )
      return bounds
    }

    const l = useMap()
    useEffect(() => {
      if (l && filteredLocations && filteredLocations.length > 0) {
        const bounds = calculateBounds(filteredLocations)
        if (bounds) {
          l.fitBounds(bounds)
        }
      }
    }, [filteredLocations, l])

    useEffect(() => {
      if (isClicked) {
        l.setView(center, 40)
        setIsClicked(false)
      }
    }, [isClicked])

    return (
      <>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {filteredLocations.map((place: any, index: any) => {
          return (
            <Marker
              key={index}
              position={place.position}
              icon={
                userLocation && place.name === "User Location"
                  ? userIcon
                  : myIcon
              }
            >
              <Popup className="map-popup">
                {place?.store_url ? (
                  <>
                    <a href={place?.store_url} target="_blank">
                      <p className="font-bold text-md map-popup-title">
                        {place.name}
                      </p>
                      <p className="font-bold text-md map-popup-description">
                        {place.placeName}
                      </p>
                      <p className="link">{place.store_url}</p>
                    </a>
                  </>
                ) : (
                  <>
                    <a href={"https://www.polarpro.com"} target="_blank">
                      <p className="font-bold text-md map-popup-title">
                        {place.name}
                      </p>
                      <p className="font-bold text-md map-popup-description">
                        {place.placeName}
                      </p>
                      <p className="link">www.polarpro.com</p>
                    </a>
                  </>
                )}
              </Popup>
            </Marker>
          )
        })}
      </>
    )
  },
)
const StoreLocatorComp = ({ allStoryblokEntry }: any) => {
  const countryDropdown = useRef<HTMLDivElement | null>(null)
  const [isClicked, setIsClicked] = useState(false)
  const locationsData = allStoryblokEntry.edges[0].node.content
  const content = JSON.parse(locationsData)
  const locations = useMemo(() => {
    return content.locations.map((location: any) => ({
      ...location,
      name: location.name,
      position: [parseFloat(location.latitude), parseFloat(location.longitude)],
      placeName: location.description,
    }))
  }, [content])
  const [selectedCountry, setSelectedCountry] = useState<any>(null)

  const [selectcountry, setSelectCountry] = useState(false)

  const { countries } = useMemo(() => {
    const countriesMap = new Map()

    content.locations.forEach((location: any) => {
      if (location?.country?.length) {
        countriesMap.set(location.country, {
          name: location.country,
        })
      }
    })

    const uniqueCountries = Array.from(countriesMap.values())

    return {
      countries: uniqueCountries,
    }
  }, [locationsData])

  const [userLocation, setUserLocation] = useState<number[] | null>(null)
  const [initialCenter, setInitialCenter] = useState([0, 0])
  const [zoom, setZoom] = useState(13)
  const myIcon = L.icon({
    iconUrl: "/images/LOCATION-ICON.svg",
    iconSize: [32, 32],
    iconAnchor: [32, 64],
  })
  const userIcon = L.icon({
    iconUrl: "/images/location.svg",
    iconSize: [32, 32],
    iconAnchor: [16, 32],
  })

  const handleToggleCountry = () => {
    setSelectCountry(!selectcountry)
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        countryDropdown.current &&
        !countryDropdown.current.contains(event.target)
      ) {
        setSelectCountry(false)
      }
    }

    document.addEventListener("click", handleClickOutside)

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [selectcountry])

  const [isLocationAllowed, setIsLocationAllowed] = useState(false)
  const [filteredLocations, setFilteredLocations] = useState<any>([])
  useEffect(() => {
    const countryName = locations.filter((location: any) => {
      return location.country === "USA"
    })
    if (countryName?.length && !filteredLocations?.length) {
      setSelectedCountry({ name: countryName?.[0]?.country })
      setFilteredLocations(countryName)
    }
  }, [locations])

  const handleFilterClick = (option: any) => {
    setSelectedCountry(option)
    setSelectCountry(false)

    let filteredCountries: any[] = []
    const queryC = option?.name.toLowerCase()
    filteredCountries = locations.filter((location: any) =>
      location?.country?.toLowerCase?.().includes?.(queryC) ? true : false,
    )
    const filteredSet = new Set([...filteredCountries])
    const filtered = Array.from(filteredSet)?.length
      ? Array.from(filteredSet)
      : locations
    setFilteredLocations(filtered)
    if (filtered.length) {
      setInitialCenter(filtered[0].position)
    }
  }
  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords
          setUserLocation([latitude, longitude])
          setIsLocationAllowed(true)
        },
        error => {
          setIsLocationAllowed(false)
          const USALocation = (locations || [])?.find?.(
            (location: any) =>
              location.country === "United States" ||
              location.country === "USA",
          )
          const initialLocation = USALocation?.position ||
            locations[0].position || [0, 0]
          setInitialCenter(initialLocation)
        },
      )
    }
  }
  useEffect(() => {
    getUserLocation()
  }, [])
  useEffect(() => {
    if (isLocationAllowed && userLocation) {
      setInitialCenter(userLocation)
    } else {
      setInitialCenter(locations[0].position)
    }
  }, [isLocationAllowed, userLocation])
  useEffect(() => {
    if (userLocation) {
      setInitialCenter(userLocation)
      if (
        !filteredLocations.some(
          (location: any) =>
            location.position[0] === userLocation[0] &&
            location.position[1] === userLocation[1],
        )
      ) {
        setFilteredLocations([
          ...filteredLocations,
          {
            position: userLocation,
            name: "User Location",
            placeName: "current Location",
          },
        ])
      }
    }
  }, [userLocation])

  useEffect(() => {
    setZoom(2)
  }, [filteredLocations])

  return (
    <div
      className="flex flex-col h-full justify-center items-center md:justify-start md:items-start md:flex-row w-full bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(/images/BACKGROUND.webp)` }}
    >
      <div className="flex flex-col items-center p-6 md:px-0 md:py-20 w-full md:w-2/5">
        <div className={`w-full md:w-11/12 flex flex-col gap-5 py-4`}>
          <p className="text-white text-[30px] font-bold  font-helvetica-neue m-0 font-black font-expanded">
            Dealer Locator
          </p>

          {!!countries.length && (
            <div className="w-full">
              <div className="flex flex-col">
                <div
                  className="flex justify-between items-center selected-option p-2 bg-white border border-gray-300 w-full h-[60px] cursor-pointer"
                  onClick={handleToggleCountry}
                  ref={countryDropdown}
                >
                  <span className="md:text-md py-1 text-sm ">
                    {selectedCountry?.name || "Select Country"}
                  </span>
                  <span
                    className={`left-10 transform ${
                      selectcountry ? "rotate-180" : "rotate-0"
                    }`}
                  >
                    <IoIosArrowDown />
                  </span>
                </div>
                {selectcountry && (
                  <div className="relative w-full mt-4">
                    <div className="border -mt-3 z-[999] pb-2 w-full  border-t-0 border-gray-300 bg-white max-h-40 overflow-y-auto absolute">
                      <div
                        className="py-2 cursor-pointer hover:bg-gray-100 mx-auto rounded-lg text-md px-4"
                        onClick={() => handleFilterClick(null)}
                      >
                        <span className="md:text-md text-xs">
                          Select Country
                        </span>
                      </div>
                      {countries
                        .slice()
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(option => (
                          <div
                            key={option?.name}
                            className="py-2 cursor-pointer hover:bg-gray-100 mx-auto rounded-lg text-md px-4"
                            onClick={() => handleFilterClick(option)}
                          >
                            <span className="md:text-md text-xs">
                              {option?.name}
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {filteredLocations?.length > 0 && (
            <div className="w-full whiteScroll hidden md:flex overflow-y-auto mt-20">
              <div className="max-h-[650px] flex flex-col gap-0 md:gap-6">
                {filteredLocations?.length > 0 &&
                  filteredLocations.map((location: any, index: any) => (
                    <div
                      key={index}
                      onClick={() => {
                        setInitialCenter(location.position)
                        setIsClicked(true)
                      }}
                      className="flex justify-start items-start cursor-pointer"
                    >
                      <img
                        src="/images/LOCATION-ICON-White.png"
                        className="w-8 h-8 object-cover"
                      />
                      <div className="marker-list">
                        <h1 className="font-expanded">{location.name}</h1>
                        <p className="font-expanded">{location.placeName}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex px-5 md:pl-0 w-full md:w-3/5 py-3 md:py-20 md:pr-10 h-full">
        <MapContainer
          center={initialCenter}
          className="w-full min-h-[90vh] z-[0] rounded-[25px]"
        >
          <InnerComponent
            userIcon={userIcon}
            userLocation={userLocation}
            center={initialCenter}
            filteredLocations={filteredLocations}
            myIcon={myIcon}
            isClicked={isClicked}
            setIsClicked={setIsClicked}
          />
        </MapContainer>
      </div>
      {filteredLocations?.length > 0 &&
        filteredLocations.map((location: any, index: any) => {
          return (
            <div
              key={index}
              className={`flex md:hidden justify-start items-start mt-8 ${
                index === filteredLocations.length - 1 ? "pb-8" : ""
              } w-full px-5 cursor-pointer`}
              onClick={() => {
                setInitialCenter(location.position)
                setIsClicked(true)
              }}
            >
              <img
                src="/images/LOCATION-ICON-White.png"
                className="w-8 h-8 object-cover"
              />
              <div className="marker-list">
                <h1 className="!text-xl font-expanded">{location.name}</h1>
                <p className="!text-base font-expanded">{location.placeName}</p>
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default StoreLocatorComp
