// extracted by mini-css-extract-plugin
export var TechPouch = "style-module--TechPouch--c68a4";
export var base1Slider = "style-module--base1Slider--84d93";
export var base2Slider = "style-module--base2Slider--c3b16";
export var boreal50lOverview = "style-module--boreal50lOverview--d0e36";
export var connorV = "style-module--connorV--7e05d";
export var hannahH = "style-module--hannahH--dd70d";
export var highlightContainer = "style-module--highlightContainer--e22b8";
export var introData = "style-module--introData--bf743";
export var nextConfigBtn = "style-module--nextConfigBtn--398c9";
export var productHero = "style-module--productHero--a77b7";
export var reactMultiCarouselTrack = "style-module--react-multi-carousel-track--2a47d";
export var reconCustomDotSlider = "style-module--reconCustomDotSlider--39cb8";
export var sectionTitleRecon = "style-module--sectionTitleRecon--1ce96";
export var sixConfig = "style-module--sixConfig--22d72";
export var tenlcube = "style-module--tenlcube--e312e";
export var twentylCube = "style-module--twentylCube--8ec9c";
export var videoContainer = "style-module--video-container--db69e";