import React, { useEffect, useState } from "react"
import * as style from "../../templates/index.module.scss"
import SecondaryButton from "../SecondaryButton"
import PlayIcon from "../Icons/PlayIcon"
import loadable from "@loadable/component"
import useScrollBlock from "../../hooks/useScrollBlock"
const ModalVideo = loadable(() => import("react-modal-video"))

function HomeHighlights(props: any) {
  const [playVideo, setPlayVideo] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()

  const getYoutubeVideoId = (url: string) => {
    const videoIdMatch = url && url.match(/youtu\.be\/([a-zA-Z0-9_-]+)/)
    if (videoIdMatch && videoIdMatch.length >= 2) {
      return videoIdMatch[1]
    }
  }

  useEffect(() => {
    if (playVideo) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [playVideo])
  const checkReverse = props?.Reverse
    ? style?.RightHighlightBanner
    : style?.highlightBanner
  return (
    <div className={`${checkReverse} relative`}>
      <img
        src={props?.MobileBackground?.filename}
        alt="mobileBackgroundImage"
        className="relative sm:block md:hidden w-screen mb-0"
      />
      <img
        src={props?.Media?.filename}
        alt="BackgroundImage"
        className="relative hidden sm:hidden md:block w-screen mb-0"
      />
      <div className={`${style.data} absolute`}>
        <div
          className={`tag ${
            props.color ? "bg-paloverde" : "bg-grey-dark"
          } text-white font-bold text-sm inline-block px-4 pb-[2px] pt-[7px] rounded-tl-lg rounded-br-lg`}
        >
          {props?.Tag}
        </div>
        <div className="title font-black text-white text-4xl md:text-4xl lg:text-5xl mt-4">
          {props?.Title}
        </div>
        <div className="sub-title font-expanded text-white text-sm lg:text-md">
          {props?.Subtitle}
        </div>
        <div className="mt-3">
          <SecondaryButton
            text={props?.LearnMore}
            link={props?.CtaLink}
            buttonStyle="white"
          />
        </div>
      </div>
      {props?.videoUrl?.url?.length ? (
        <div
          className={`absolute ${
            props?.Reverse
              ? "left-[33%] md:left-[15%] lg:left-[18%] top-1/5 lg:top-2/5"
              : " right-2/5 md:right-[15%] lg:right-1/5 top-1/5 lg:top-2/5"
          }`}
        >
          <button
            className={`${style.PlayBtn} cta-round opacity-90 hover:opacity-100`}
            onClick={e => {
              e.preventDefault()
              setPlayVideo(true)
            }}
          >
            <PlayIcon />
          </button>
        </div>
      ) : null}
      {playVideo ? (
        //@ts-ignore
        <ModalVideo
          channel="youtube"
          videoId={getYoutubeVideoId(props.videoUrl?.url) as any}
          isOpen={playVideo}
          onClose={() => setPlayVideo(false)}
        />
      ) : null}
    </div>
  )
}

export default HomeHighlights
