import React from "react"
import SupportThank from "../../components/SupportThank"

type Props = {}

const Thanks = (props: Props) => {
  return (
    <div>
      <SupportThank />
    </div>
  )
}

export default Thanks
