/* eslint-disable */
import React from "react"

const LockedIcon = props => {
  const { width } = props
  //  xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      viewBox="0 0 416.208 416.209"
      // style="enable-background:new 0 0 416.208 416.209;"
    >
      <g>
        <path
          d="M344.757,166.9h-20.543v-50.792C324.214,52.086,272.128,0,208.107,0C144.084,0,91.996,52.086,91.996,116.108V166.9H71.453
          c-6.635,0-12.012,5.377-12.012,12.011v225.286c0,6.635,5.377,12.012,12.012,12.012h273.305c6.633,0,12.01-5.377,12.01-12.012
          V178.911C356.767,172.277,351.39,166.9,344.757,166.9z M226.833,304.012v47.961c0,4.189-3.396,7.586-7.586,7.586h-22.286
          c-4.189,0-7.586-3.396-7.586-7.586v-47.961c-8.287-5.875-13.699-15.535-13.699-26.466c0-17.907,14.518-32.427,32.428-32.427
          c17.908,0,32.426,14.52,32.426,32.427C240.531,288.477,235.119,298.137,226.833,304.012z M268.779,166.9H147.431v-50.792
          c0-33.456,27.219-60.673,60.676-60.673c33.455,0,60.672,27.217,60.672,60.673V166.9z"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  )
}

export default LockedIcon
