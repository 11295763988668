import React from 'react'

function SliderTitle(props: any) {
  return (
    <div className='flex w-full bg-black justify-center items-center'>
      <div className="flex w-full justify-center items-center py-20">
        <div className="flex w-10/12 md:w-7/12 justify-center items-center">
          <p className="text-white text-md leading-6 font-normal text-center font-helvetica-neue m-0">
            {props.title}
          </p>
        </div>
      </div>
    </div>
  )
}

export default SliderTitle