/* eslint-disable */

import React, { useState } from "react"
import Seo from "../components/seo"

import { useStaticQuery, graphql } from "gatsby"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import { useStoryblok } from "../utils/storyblok"
import DynamicComponents from "../components/DynamicComponents"

import "react-multi-carousel/lib/styles.css"

const RidgeLockOverview = () => {
  // const pageData = useStaticQuery(graphql`
  //   query {
  //     storyblokEntry(full_slug: {eq: "pages/flare-app"}) {
  //       id
  //       name
  //       content
  //       slug
  //       full_slug
  //       uuid
  //       created_at
  //     }
  //   }
  // `);

  // const story = pageData.storyblokEntry ? useStoryblok(pageData.storyblokEntry) : { content: null };

  // const stickyNavComponent = story.content && story.content['sticky_nav'] ? story.content['sticky_nav'].map(blok => (
  //   <>
  //     {blok ? (
  //       <div {...sbEditable(blok)} className="sticky top-0 z-50">
  //         <DynamicComponents blok={blok} product={{handle:'flare', title: 'flare'}} key={blok._uid} />
  //       </div>
  //     ) : null
  //     }
  //   </>
  // )) : null;

  return (
    <>
      <Seo title="RidgeLock Overview" />

      {/* sticky nav */}
      {/* <div className="sticky top-0 z-50">
        {stickyNavComponent}
      </div> */}

      <div></div>
    </>
  )
}

export default RidgeLockOverview
