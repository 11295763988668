// @ts-nocheck
import { getShopifyImage } from "gatsby-source-shopify"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { StoreContext } from "../../context/StoreContext"
import { GatsbyImage } from "gatsby-plugin-image"
import moment from "moment"
import { RiArrowDownSFill } from "react-icons/ri"
import { isEqual } from "lodash"
import ExchangePrice from "./ExchangePrice"

type Props = {
  item: any
  index: number
}

const SuggestedProductCard = ({ item }: Props) => {
  const { variants } = item || {}
  const [_availableSubOptions, setAvailableSubOptions] = useState([])
  const [optionsLibrary, setOptionsLibrary] = useState({})

  const [variant, setVariant] = useState({ ...item?.variants?.[0] })
  const { addVariantToCart, changeBannerInfo } = useContext(StoreContext)
  useEffect(() => {
    if (item?.variants?.[0]) {
      setVariant({ ...(item?.variants?.[0] || {}) })
    }
  }, [item])
  const variantPreorderDate = useMemo(() => {
    let preorderDate
    if (variant?.availableForSale === true && variant?.inventoryQuantity <= 0) {
      preorderDate = variant?.metafields.filter(
        (metafield: any) => metafield.key === "preorder-date",
      )[0]?.value
      let dateObject = moment(preorderDate)
      const formattedDate = dateObject.format("MMMM, YYYY")
      return { preorder: true, preorderDate: preorderDate }
    }
  }, [item])
  const addToCart = () => {
    let preOrderStatus = false
    let preOrderDate
    if (variant?.availableForSale === true && variant?.inventoryQuantity <= 0) {
      preOrderDate = variant?.metafields?.filter(
        item => item.key === "preorder-date",
      )

      if (preOrderDate?.length > 0) {
        preOrderStatus = true
      }
    }

    addVariantToCart([
      {
        variantId: variant?.storefrontId,
        quantity: 1 as number,
        preorder: preOrderDate?.[0]?.value ? true : false,
        preorderDate: preOrderDate?.[0]?.value,
      },
    ])
    let price = item.priceRangeV2.maxVariantPrice.amount
    let title = item.title
    let variantName = item.title
    // @ts-ignore
    changeBannerInfo(price, title, variantName)
  }
  const { variantImage } = useMemo(() => {
    return {
      variantImage: {
        originalSrc: item?.featuredImage?.originalSrc,
      },
    }
  }, [item])

  const image = React.useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: "constrained",
        crop: "cover",
        width: 83,
        height: 83,
      }),
    [variantImage.originalSrc],
  )

  useEffect(() => {
    // Primary option object keeps track of the first options selector name
    const primaryOption = {}
    // Reorganize data will live in optionLibrary
    const optionLibrary = {}
    const { name, values } = item?.options?.[0]

    // Set primary option data with the first option data
    primaryOption.name = name
    primaryOption.values = values

    // Keep track of the primary option data in optionLibrary object
    optionLibrary.name = name
    optionLibrary.root = values

    // Use primary options values as the key for storing subsequent options
    values.forEach(value => {
      optionLibrary[value] = {}
    })

    item?.variants.forEach(({ selectedOptions }) => {
      let primaryOptionName = ""
      selectedOptions.forEach(selectedOption => {
        // Check if the vairant has primary option. If it does, use it's value as key
        if (selectedOption.name === optionLibrary.name) {
          primaryOptionName = selectedOption.value
        } else if (optionLibrary[primaryOptionName][selectedOption.name]) {
          // Add available sub options for each key
          // eslint-disable-next-line max-len
          optionLibrary[primaryOptionName][selectedOption.name] = [
            ...optionLibrary[primaryOptionName][selectedOption.name],
            selectedOption.value,
          ]
        } else {
          // If the key does not exist, create new list of sub-options array
          optionLibrary[primaryOptionName][selectedOption.name] = [
            selectedOption.value,
          ]
        }
      })
    })

    // Set optionsLibrary state
    setOptionsLibrary(optionLibrary)

    // Set up the first available sub-options
    variant?.selectedOptions?.forEach(selectedOption => {
      if (selectedOption.name === optionLibrary.name) {
        setAvailableSubOptions(optionLibrary[selectedOption.value])
      }
    })

    const script = document.createElement("script")
    script.src =
      "https://staticw2.yotpo.com/qNmEg7HN0QiYfqn6n5DHRUGCtTUGNWa1GfSVsHRS/widget.js"
    script.async = true
    document.head.appendChild(script)

    script.onload = e => {
      // @ts-ignore
      window.yotpo.refreshWidgets()
    }
    return () => {
      script.remove()
    }
  }, [])

  const handleOptionChange = (index, event) => {
    const { value } = event.target
    if (value === "") {
      return
    }

    // If primary option is selected, show the appropriately available sub-options
    if (optionsLibrary.root.includes(value)) {
      setAvailableSubOptions(optionsLibrary[value])
    }

    const currentOptions = [...variant?.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    /* eslint-disable-next-line max-len */
    let selectedVariant = item?.variants.find(variantItem =>
      isEqual(currentOptions, variantItem?.selectedOptions),
    )

    if (!selectedVariant) {
      // eslint-disable-next-line no-shadow
      currentOptions.forEach((currentOption, index) => {
        // If no variant exists with the selected primary option,
        // set variant to first available variant with selected primary option
        if (currentOption.name !== optionsLibrary.name) {
          // eslint-disable-next-line max-len, prefer-destructuring
          currentOptions[index].value =
            optionsLibrary[currentOptions[0].value][currentOption.name][0]
        }
      })

      // eslint-disable-next-line max-len
      selectedVariant = item?.variants.find(variantItem =>
        isEqual(currentOptions, variantItem.selectedOptions),
      )
    }

    setVariant({ ...selectedVariant })
    // setNewInitialVariant({ ...newSelectedVariant })
  }

  const checkVariants = item?.options?.filter(item =>
    item.values.includes("Default Title"),
  )
  const productsToRender = useMemo(() => {
    return item?.options?.map((item, index) => {
      let cleanValues = item?.values?.filter(value => {
        const currentVariant = variants?.filter(variant => {
          return variant.title.includes(value)
        })?.[0]
        return currentVariant?.availableForSale || false
      })
      cleanValues = cleanValues?.length ? cleanValues : []
      return cleanValues // Return the filtered values for each option
    })
  }, [item])

  return productsToRender?.[0]?.length ? (
    <div className="">
      <div className="relative first:border-none border-t pt-2 px-5">
        <div className="flex mt-2 ">
          <div className="w-[100px] relative bottom-2">
            {image && (
              <GatsbyImage key={variantImage.originalSrc} image={image} />
            )}
          </div>
          <div className="w-[80%] relative top-4 relative -top-0">
            {variantPreorderDate !== undefined &&
              variantPreorderDate.preorderDate !== "Invalid date" ? (
              <div className="flex gap-1">
                <p className="mb-0 text-[10px]  text-paloverde font-semibold">
                  Preordered item.
                  <span className="text-black">Estimated to ship</span>
                </p>
                {variantPreorderDate?.preorderDate !== "" && (
                  <p className="mb-0 text-[10px]  font-semibold">
                    {variantPreorderDate.preorderDate}
                  </p>
                )}
              </div>
            ) : (
              ""
            )}
            <ExchangePrice item={item} />
            <div className="flex flex-col gap-1 w-[80%]">
              {checkVariants?.length < 1 &&
                item?.options?.map((item, index) => {
                  return (
                    <div className="mt-2" key={index}>
                      <div className="text-xs">{item?.name}</div>
                      <div className="selectVariant font-normal  relative border-[1px] border-[#1C1C1C] rounded-[53px] m-0 mt-1 w-full pointer">
                        <select
                          aria-label="Variants"
                          className="relative cursor-pointer font-green-900 w-full font-normal appearance-none outline-none bg-transparent border-none text-obsidian pointer text-[11px] font-light m-0 pl-2 pr-[30px]"
                          onChange={event => handleOptionChange(index, event)}
                        >
                          {item.values?.map((value: any) => (
                            <option
                              value={value}
                              className="w-full cursor-pointer"
                              key={`${item?.name}-${value}`}
                            >
                              {value}
                            </option>
                          ))}
                        </select>
                        <span className="block bg-no-repeat absolute top-1/2 right-2 -translate-y-1/2">
                          <RiArrowDownSFill size={"17px"} />
                        </span>
                      </div>
                    </div>
                  )
                })}
              <div className="flex" onClick={() => addToCart()}>
                <p className="text-[15px] lg:text-[12px] font-medium cursor-pointer mt-2 mb-2">
                  <span className="mr-2 h-[3px] px-[2px] !w-[12px] border-[1px] border-black rounded-full cursor-pointer relative bottom-[1px]">
                    +
                  </span>
                  Add to cart
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default SuggestedProductCard
