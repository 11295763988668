import React, { useState, useEffect, useContext, useMemo } from "react"
import { Link } from "gatsby"
import { StoreContext } from "../../context/StoreContext"
import useProduct from "../../hooks/useProduct"
import collectionHandles from "../../utils/collectionHandles"
import BelowBaseCamp from "./BelowBaseCamp"
import BelowBaseCampCurrency from "./BelowBaseCampCurrency"

const HelixVariantCart = ({ variant, product, variantProduct }) => {
  let buttonStyle
  const single = variant[0]?.availableForSale
  const { addVariantToCart, changeBannerInfo, client } =
    useContext(StoreContext)
  const { title: variantName, collections, variants } = product
  const [isAdding, setIsAdding] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [showError, setShowError] = useState(false)
  const [cartProductId, setCartProductId] = useState("")
  let buttonText = "Add to cart"
  const {
    available,
    preorder: { preorder },
  } = useProduct({
    variant,
    client,
    product: variant.isProduct ? variant : product,
  })
  const [collectionIndex, setCollectionIndex] = useState(0)

  useEffect(() => {
    if (collections?.length > 1) {
      for (let i = 0; i < collections?.length; i += 1) {
        // eslint-disable-line no-plusplus
        if (collectionHandles.has(collections?.[i]?.handle)) {
          setCollectionIndex(i)
          break
        }
      }
    }
  }, [])

  const categoryName = useMemo(() => {
    if (collections?.length) {
      return collections?.[collectionIndex]?.title
    }
    return ""
  }, [collections])

  useEffect(() => {
    if (variantName) {
      setErrorMessage("")
    }
  }, [variantName])

  const addToCart = (variant, value) => {
    const preOrderDate = variant.metafields?.filter(
      item => item.key === "pre_order_date",
    )
    const productList = {
      variantId: variant?.variants?.[0]?.shopifyId,
      quantity: 1,
      preorder: variant?.totalInventory <= 0,
      preorderDate: preOrderDate[0]?.value,
    }

    if (product.variants.length > 1 && !variantName) {
      setErrorMessage("Please select a variant.")
    } else {
      setErrorMessage("")
    }

    setShowError(false)
    // Notify customer of updated cart
    setIsAdding(true)
    // @ts-ignore
    addVariantToCart([productList])

    // @ts-ignore
    // changeBannerInfo(variant.priceRangeV2?.maxVariantPrice?.amount, product.title, variantName)
    if (productList.length) {
      // @ts-ignore
      fbq("track", "AddToCart", {
        value: variant.price,
        currency: "USD",
        num_items: productList.length,
        content_type: "product_group",
        content_name: product.title,
        content_category: categoryName,
      })
    } else {
      // @ts-ignore
      fbq("track", "AddToCart", {
        value: variant.price,
        currency: "USD",
        num_items: productList.quantity,
        content_type: "product_group",
        content_name: product.title,
        content_category: categoryName,
      })
    }
    setTimeout(() => setIsAdding(false), 1500)
  }
  if (preorder) {
    buttonText = "RESERVE NOW"
    buttonStyle = `${buttonStyle} hover:bg-paloverde`
  }
  if (single === false) {
    /* Empty */
  } else {
    buttonStyle = `${buttonStyle} hover:bg-paloverde`
  }

  return (
    <div className="flex-1">
      <div className="h-full">
        {variant?.addToCart === false ? (
          <div className="h-full">
            <a
              href={`/products/${variant.handle}`}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#1c1c1c] w-full h-full inline-block text-white p-5 rounded-lg border-transparent transition-all ease-in-out duration-300 border hover:border hover:border-paloverde"
            >
              <BelowBaseCamp variant={variant} />
              {errorMessage?.length > 0 && (
                <p className="font-sans italic text-[#1c1c1c] text-center text-sm mt-1 transition-all duration-[250ms]">
                  {errorMessage}
                </p>
              )}
            </a>
          </div>
        ) : (
          <div className="h-full">
            <div
              onClick={() => (
                addToCart(variant, variant.title),
                setCartProductId(variant.shopifyId)
              )}
              className="bg-[#1c1c1c] w-full h-full inline-block text-white p-5 cursor-pointer rounded-lg border-transparent transition-all ease-in-out duration-300 border hover:border hover:border-paloverde"
            >
              <BelowBaseCampCurrency
                variantProduct={variantProduct}
                variant={variant}
              />
              <button type="button" className="cursor-pointer mt-4 text-sm">
                {cartProductId === variant.shopifyId
                  ? variantName
                    ? isAdding
                      ? "Item Added!"
                      : buttonText
                    : "Add To Cart"
                  : "Add To Cart"}
              </button>

              {errorMessage?.length > 0 && (
                <p className="font-sans italic text-[#1c1c1c] text-center text-sm mt-1 transition-all duration-[250ms]">
                  {errorMessage}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default HelixVariantCart
