import axios from "axios"

export const downloadPdf = async pdfUrl => {
  try {
    const response = await axios.get(pdfUrl, { responseType: "blob" })
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", "user-guide.pdf")
    document.body.appendChild(link)
    link.click()
  } catch (error) {
    console.error("Error downloading PDF:", error)
  }
}
