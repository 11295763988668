/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable */
import React, { useContext, useEffect, useState } from "react"
import { AiOutlineCloseCircle } from "react-icons/ai"
import { setCookie } from "../../utils/cookie"
import VisitorImage from "../../images/first-visitor.png"
import { NewUserContext } from "../../context/NewUserContext.tsx"

const FirstVisitorForm = () => {
  const { setIsNewUser, footerSubmitted, loaded, setLoaded } =
    useContext(NewUserContext)
  const [isLoaded, setIsLoaded] = useState(false)
  const [hideModal, setHideModel] = useState(true)
  useEffect(() => {
    window.addEventListener("klaviyoForms", e => {
      if (e?.detail?.type === "submit") {
        setCookie("interacted", "yes", null)
      }
    })
    if (!loaded) {
      setTimeout(() => {
        setLoaded(true)
      }, 1000)
    }
  }, [])

  const closeBar = () => {
    setLoaded(false)
    setCookie("visited", "yes", null)
    setCookie("interacted", "yes", null)
    setCookie("visits", 10, null)
    setIsNewUser?.(false)
  }
  if (footerSubmitted) {
    return null
  }
  if (hideModal) {
    return <div></div>
  } else {
    return (
      <div
        style={{ transform: isLoaded ? "translateX(0)" : "" }}
        className="fixed w-full md:w-auto bottom-0 right-0 z-50 transition-all duration-500 translate-x-full md:translate-x-[450px]"
      >
        <div className="flex w-full h-[300px] sm:h-[200px]">
          <img
            src={VisitorImage}
            alt="First Visitor"
            className="m-0 w-1/3 sm:w-[140px] h-auto object-cover"
          />
          <div className="bg-black overflow-x-scroll flex-1 px-5 py-3 h-full relative">
            <button
              type="button"
              onClick={closeBar}
              className="absolute right-2.5 top-2.5"
            >
              <AiOutlineCloseCircle color="white" />
            </button>
            <div className="sm:min-w-[260px] w-full h-full flex flex-col items-center justify-center">
              <h1 className="text-white font-expanded text-24 font-bold mb-2">
                Enjoy 15% Off
              </h1>
              <p className="text-white font-expanded text-11 font-bold">
                When you subscribe to our newsletter.
              </p>
              <div className="klaviyo-form-VSTE4w" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FirstVisitorForm
