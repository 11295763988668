/* eslint-disable */
import React, { useState } from "react"
import { useEffect } from "react"
import YotpoStarRatings from "../YotpoStarRatings"
import { FaStar, FaDownload, FaThumbsUp, FaThumbsDown } from "react-icons/fa"
const UserReviews = ({ allReviews }) => {
  const [reviews, setReviews] = useState()
  useEffect(() => {
    setReviews(allReviews)
  }, [allReviews])
  return (
    <div className="mt-14 flex flex-col gap-2">
      {reviews?.map((item, index) => {
        const stars = []
        for (let i = 0; i < item.score; i++) {
          stars.push(<FaStar key={i} className="text-yellow-500" />)
        }
        return (
          <div className="flex flex-col-reverse lg:flex-row">
            {/* left section  */}
            <div className="w-full relative bottom-6 lg:bottom-0 lg:w-[30%] flex flex-col gap-4">
              <div className="flex items-center gap-[8px]">
                {stars?.map(item => {
                  return <FaStar className=" text-yellow-500" />
                })}
              </div>

              <div className="flex flex-col leading-[1px]">
                <p className="text-[15px]"> {item?.user?.display_name}</p>
                <p className="text-[12px]">
                  {!item?.verified_buyer
                    ? "Non verified buyer"
                    : "Verified buyer"}
                </p>
                <p className="text-[12px]">
                  {item?.created_at
                    ? new Date(item.created_at).toLocaleDateString()
                    : ""}
                </p>
              </div>
            </div>
            {/* Right section  */}
            <div className="w-full">
              <div>
                <h1 className="text-[19px]">
                  {item?.title ? item?.title : ""}
                </h1>
                <h1 className="text-[13px] text-gray-400 relative bottom-2  ">
                  {item?.content ? item?.content : ""}
                </h1>
              </div>
              <div className="hidden justify-between text-[10px] relative bottom-4">
                <div className="flex gap-2  ">
                  <div className="mt-[7px]">
                    <FaDownload />
                  </div>
                  <p>Share</p>
                </div>
                <div className="flex items-center gap-2">
                  <p>Was this Review helpful</p>
                  {/* Icons */}
                  <div className="flex gap-2">
                    <div className=" flex gap-[3px]">
                      <div className="mt-[5px]">
                        <FaThumbsUp />
                      </div>
                      <p>{item?.votes_up}</p>
                    </div>
                    <div className=" flex gap-[3px]">
                      <div className="mt-[5px]">
                        <FaThumbsDown />
                      </div>
                      <p>{item?.votes_down}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default UserReviews
