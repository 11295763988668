/* eslint-disable */
/* eslint-disable import/prefer-default-export */
export const capitalizeFirstLetter = word => {
  try {
    if (word && typeof word === "string") {
      const wordArr = word.trim().toLowerCase().split(" ")
      const formattedWords = []

      wordArr.forEach(item =>
        formattedWords.push(item.replace(/^./, item[0].toUpperCase())),
      )

      const formattedSentence = formattedWords
        .join(" ")
        .replaceAll("Iphone", "iPhone")

      return formattedSentence
    }
  } catch (error) {
    console.error(error)
    throw error
  }

  const errorMsg = "Given input is not a string. Please check the parameter"
  console.error(errorMsg)
  return errorMsg
}
