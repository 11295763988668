import React from "react"
import { Router } from "@reach/router"
import "./account.scss"
import RequireAuth from "../../components/RequireAuth"
import ProfileHub from "../../components/ProfileHub"
import CustomerAccountInfo from "../../components/CustomerAccountInfo"
import AccountReset from "../../components/AccountReset"
import AccountSidebar from "../../components/AccountSidebar"
import Rewards from "../../components/Rewards"
import OrderHistory from "../../components/OrderHistory"
import ContactSupport from "../../components/ContactSupport"
import ShippingAddresses from "../../components/ShippingAddresses"
import AddNewShippingAddress from "../../components/AddNewShippingAddress"
import UpdateShippingAddress from "../../components/UpdateShippingAddress"
import SingleOrder from "../../components/SingleOrder"
import ResetPassword from "../../components/ResetPassword"

const Account = () => (
  <>
    <div className="w-full flex items-start justify-between">
      <AccountSidebar />
      <div className="w-full py-16 bg-[#F6F6F6] overflow-hidden">
        <Router basepath="/account">
          <RequireAuth path="/edit" component={CustomerAccountInfo} />
          <ResetPassword path="/reset/:user/:id" />
          <RequireAuth path="/reset" component={AccountReset} />
          <RequireAuth path="/polarpro-rewards" component={Rewards} />
          <RequireAuth path="/order-history" component={OrderHistory} />
          <RequireAuth path="/contact-support" component={ContactSupport} />
          <RequireAuth path="/shipping-address" component={ShippingAddresses} />
          <RequireAuth
            path="/shipping-address/new"
            component={AddNewShippingAddress}
          />
          <RequireAuth path="/order-history/order" component={SingleOrder} />

          <RequireAuth
            path="/shipping-address/update"
            component={UpdateShippingAddress}
          />
          <RequireAuth path="*" component={ProfileHub} />
        </Router>
      </div>
    </div>
  </>
)

export default Account
