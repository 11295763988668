/* eslint-disable */
import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { useLocation } from "@reach/router"

type Props = any

const SelectedDropDownItem = (props: Props) => {
  const location = useLocation()

  const { selectedContent, setSelectedItem, shopAll, columns, bannerFileName } =
    props
  const elementRef = useRef<HTMLDivElement>(null)
  const timerRef = useRef<any>(0)

  const [showItem, setShowItem] = useState(false)

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    if (selectedContent?.length && elementRef.current) {
      setShowItem(false)
      timerRef.current = setTimeout(() => {
        setShowItem(true)
      }, 200)
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [selectedContent])
  return (
    <div
      style={{
        opacity: selectedContent?.length ? 1 : 0,
        // height:
        //   selectedContent?.length < 10 && selectedContent?.length > 5
        //     ? `${bannerFileName
        //       ? selectedContent?.length * 85
        //       : selectedContent?.length * 70
        //     }px`
        //     : selectedContent?.length === 1
        //       ? `${selectedContent?.length * 280}px`
        //       : selectedContent?.length === 2
        //         ? `${selectedContent?.length * 170}px`
        //         : selectedContent?.length === 3
        //           ? `${selectedContent?.length * 120}px `
        //           : selectedContent?.length === 5
        //             ? `${selectedContent?.length * 90}px `
        //             : `${selectedContent?.length * 100}px`,
        transitionDuration: selectedContent?.length && "800ms",
        zIndex: selectedContent?.length ? 999999 : -99999999,
        position: selectedContent?.length ? "absolute" : "fixed",
        visibility: selectedContent?.length ? "visible" : "hidden",
      }}
      onClick={() => setSelectedItem(-1)}
      onMouseLeave={() => {
        setSelectedItem(-1)
      }}
      className="w-full bg-white absolute  top-[99px] md:top-[79px] lg:top-[79px] z-[999999] left-0 px-[15px] lg:px-[80px] xl:px-[180px] 3xl:px-[400px] xl:px-28 hidden md:flex flex-col items-center"
    >
      {bannerFileName && (
        <div className="flex w-full  justify-center items-center bg-[#ededed]  mt-3 rounded">
          <div className="flex justify-center items-center">
            <img
              src={bannerFileName}
              alt="bannerFilename"
              className="h-[70px] w-full pt-6 object-contain"
            />
          </div>
        </div>
      )}
      <div
        ref={elementRef}
        style={{
          opacity: showItem ? 1 : 0,
        }}
      >
        <div
          style={{
            opacity: showItem ? 1 : 0,
            transform: showItem ? "none" : "translateY(-14px) translateZ(0px)",
            transitionDuration: "500ms",
            gridTemplateColumns:
              selectedContent?.length < columns
                ? `repeat(${selectedContent.length}, minmax(0, 1fr))`
                : `repeat(${columns}, minmax(0, 1fr))`,
          }}
          className="grid grid-cols-3 pt-10 lg:pt-14 pb-8 z-50 flex"
        >
          {selectedContent?.map((content: any, index: number) => {
            if (content.label === "Shop All") return null
            const isLastInColumn =
              selectedContent.length < columns
                ? index === selectedContent.length - 1
                : (index + 1) % columns === 0
            const isFirstInColumn = !index || index % columns === 0
            const isFirstRow = index < columns
            const locationSearch = new URLSearchParams(location.search)
            locationSearch.delete("variant")
            const hasQueryParams =
              content.link.includes("?") &&
              content.link.split("?")[1].length > 0
            return (
              <Link
                to={`${content.link}${hasQueryParams
                  ? (content.link.includes("?") ? "" : "?") + locationSearch
                  : ""
                  }`}
                key={index}
              >
                <div
                  style={{
                    borderRight: isLastInColumn ? "none" : "",
                    paddingRight: isLastInColumn ? 0 : "",
                    paddingLeft: isFirstInColumn ? 0 : "",
                    paddingTop: isFirstRow ? 0 : "",
                  }}
                  key={index}
                  className="flex items-start border-r border-[#EDEDED] pr-3 pl-3 2xl:pr-8 2xl:pl-8 pt-7 max-w-[380px] h-full"
                >
                  <img
                    src={
                      content?.image?.filename || "/images/exampleImageNav.png"
                    }
                    className={`mb-0 h-auto object-cover w-[50px] lg:w-20 ${bannerFileName ? "w-[40px] lg:w-20" : "w-[40]"
                      }`}
                  />
                  <div className="flex flex-col items-start ml-3 lg:ml-5">
                    <p className="mb-0 font-black leading-[17px] lg:leading-[20px] font-expanded font-sans text-[13px] lg:text-[14px]">
                      {content.label}
                    </p>
                    <p className="mb-0 font-sans font-condensed text-[11px] text-[#949494] leading-3 mt-1 ">
                      {content.description}
                    </p>
                    {content?.list?.length > 0 && (
                      <div className="w-full h-[1px] bg-[#f1f1f1] mt-3" />
                    )}
                    <div>
                      {content?.list?.length > 0 && (
                        <div className="flex flex-col leading-[25px] pt-1 font-bold ">
                          {content?.list.map((item: any) => {
                            return (
                              <Link to={`${item?.link?.url || item?.link}`}>
                                <div className="text-[12px] cursor-pointer hover:text-gray-500">
                                  {item?.title}
                                </div>
                              </Link>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
        {/* shop all link stroyblock entry go here */}
        {shopAll?.url?.length ? (
          <Link
            className="text-center mt-4 mx-0 block font-extrabold font-condensed uppercase underline text-sm cursor-pointer"
            to={shopAll.url}
          >
            Shop All
          </Link>
        ) : null}
      </div>
      <div className="pb-6 xl:pb-12">
        {shopAll?.label && (
          <Link
            to={`${shopAll.link}/${location.search ? location.search : ""}`}
          >
            <p className="mb-0 text-[#1C1C1C] hover:text-[#C99E1A] font-sans font-[15px] font-bold">
              {shopAll?.label}
            </p>
          </Link>
        )}
      </div>
    </div>
  )
}

export default SelectedDropDownItem
