/* eslint-disable  */

import React, { useEffect, useState } from "react"
import { Link, navigate, useParams } from "@reach/router"
import { FaRegTimesCircle } from "react-icons/fa"
import useAuth from "../../hooks/useAuth"
import { getDate } from "../../utils/formatDate"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import productShopifyIdDecoder from "../../utils/shopifyProductIdDecoder"
import { useLocation } from "@reach/router"
import { functionsEndpoint } from "../../config"
import axiosLib from "axios"

const SingleOrder = () => {
  const isBrowser = typeof window !== "undefined"
  const location = useLocation()

  const [order, setOrder] = useState<any>()
  const { auth, setAuth } = useAuth()
  const [found, setFound] = useState(true)
  const privateAxios = useAxiosPrivate()
  const [loading, setLoading] = useState(true)

  const getOrder = async () => {
    const query = new URLSearchParams(window.location.search).get("id")
    await axiosLib
      .post(
        functionsEndpoint + `/order?id=${query}`,
        {},
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then(result => {
        const isSuccess = !!Object.keys(result.data)?.length
        if (isSuccess) {
          setOrder(result.data)
          setFound(true)
        } else {
          setFound(false)
        }
      })
      .catch(err => {
        console.error(err)
      })
    setLoading(false)
  }

  useEffect(() => {
    if (!auth?.accessToken || !auth?.email) {
      axiosLib
        .get(functionsEndpoint + "/account", {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(result => {
          const {
            id,
            email,
            firstName,
            lastName,
            defaultAddress,
            phone,
            metafields,
          } = result.data?.customer
          // Properly format retrieved data
          const customerShopifyId = productShopifyIdDecoder(id)
          const birthday = metafields?.edges?.filter?.(
            (metafield: any) => metafield?.key === "birthDate",
          )
          const birthdayStr =
            birthday?.length > 0
              ? birthday?.[0]?.value
                  ?.replace?.(/-/g, "/")
                  ?.toDateString?.()
                  ?.substr?.(4)
              : "---"

          // Update auth info
          setAuth?.({
            ...auth,
            id: customerShopifyId,
            email: email,
            firstName: firstName,
            lastName: lastName,
            defaultAddress: defaultAddress,
            phone: phone,
            birthday: birthdayStr,
          })
        })
        .catch(err => {
          console.error("error retrieving data", err.message)
          if (!err?.response) {
            console.error("No Server Response")
          } else if (err.response?.status === 400) {
            console.error("Bad Request")
          } else if (err.response?.status === 401) {
            console.error("Unauthorized")
          } else if (err.reponse?.status === 403) {
            console.error("Forbidden")
          } else if (err.response.status === 500) {
            console.error(
              `Internal server error occured. Message from server: ${err.message}`,
            )
          } else {
            console.error(
              `Unknown error occured. Message from server: ${err.message}`,
            )
          }

          if (isBrowser) {
            localStorage.setItem("loggedIn", "false")
            navigate(`/login/${location.search ? location.search : ""}`)
          }
        })
    }
  }, [])

  useEffect(() => {
    if (auth?.id) {
      getOrder()
    }
  }, [auth])

  useEffect(() => {
    if (!found) {
      navigate(-1)
    }
  }, [found])
  const hasOrder = !!Object.keys(order || {})?.length
  if (!found || !hasOrder || loading)
    return (
      <div className="w-full min-h-screen flex justify-center items-center">
        <div role="status">
          <svg
            aria-hidden="true"
            className="w-24 h-24 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  const shippingAddress = order?.shipping_address
  return (
    <div className="flex flex-col min-h-screen w-full px-6 2xl:px-0 lg:justify-center items-center">
      <div className="flex justify-between items-center w-full pb-1 border-b-4 border-black max-w-4xl">
        <div className="flex items-center">
          <p className="uppercase text-lg text-obsidian font-extrabold mb-0">
            Order {order?.name}
          </p>
        </div>
        <Link to={`/account/${location.search ? location.search : ""}`}>
          <FaRegTimesCircle className="w-6 h-6 text-obsidian hover:text-paloverde" />
        </Link>
      </div>
      <div className="flex flex-col items-start w-full lg:max-w-4xl">
        <div className="mt-4 flex flex-col 2xl:flex-row justify-between 2xl:items-center w-full">
          <div className="flex items-start">
            <div className="bg-white flex items-start px-6 lg:px-10 py-4 border-r-2 border-[#CFCFCF]">
              <p className="text-xs lg:text-lg mb-0">
                Payment Status:{" "}
                <span className="text-paloverde italic font-bold">
                  {order?.financial_status?.toUpperCase() || "Paid"}
                </span>
              </p>
            </div>
            <div className="bg-white flex items-start px-6 lg:px-10 py-4">
              <p className="text-xs lg:text-lg mb-0">
                Fulfillment Status:{" "}
                <span className="text-paloverde italic font-bold">
                  {order?.fulfillment_status?.toUpperCase() || "Fulfilled"}
                </span>
              </p>
            </div>
          </div>
          <div className="mt-6 2xl:mt-0">
            <h1 className="font-bold text-lg mb-0">Need to make a return?</h1>
            <div className="flex items-start mt-4">
              <a
                className="mb-0 border-r-2 pr-4 mr-4 border-gray-400"
                href="https://polarpro.loopreturns.com/#/"
              >
                Return
              </a>
              <Link
                to={`/account/contact-support/${
                  location.search ? location.search : ""
                }`}
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
        <div className="mt-6 w-full">
          <div className="overflow-x-scroll 2xl:overflow-x-hidden w-full">
            <table className="w-full min-w-[1024px] 2xl:min-w-[auto]">
              <thead className="bg-[#1C1C1C] text-white">
                <th>
                  <td className="!px-4">Product</td>
                </th>
                <th>
                  <td className="!px-4">SKU</td>
                </th>
                <th>
                  <td className="!px-4">Price</td>
                </th>
                <th>
                  <td className="!px-4">Quantity</td>
                </th>
                <th>
                  <td className="!px-4">Total</td>
                </th>
              </thead>
              <tbody>
                {order?.line_items?.map((item: any, idx: number) => (
                  <tr key={idx} className="border-2 border-[#1c1c1c]">
                    <td className="!pl-4">
                      <div className="flex items-center">
                        <div>
                          <img
                            src={item?.variant?.image?.url}
                            className="max-w-[60px] mr-4"
                          />
                        </div>
                        <div>
                          <p className="mb-0">{item?.title}</p>
                          <a
                            href="https://polarpro.loopreturns.com/#/"
                            className="underline text-sm"
                          >
                            Return
                          </a>
                          <p className="mb-0 text-paloverde text-xs">
                            Fulfilled {getDate(order?.processed_at || "")}
                            {order?.fulfillments?.length ? " - " : null}
                            {order?.fulfillments?.length ? (
                              <span className="text-black font-bold">
                                {order?.fulfillments?.[0]?.tracking_company} #
                                {order?.fulfillments?.[0]?.tracking_number}
                              </span>
                            ) : null}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="text-[#1C1C1C] whitespace-nowrap">
                      {item?.sku || "-"}
                    </td>
                    <td className="text-[#1C1C1C] whitespace-nowrap">
                      {item?.price_set.shop_money.currency_code}{" "}
                      {item?.price_set.shop_money.amount}
                    </td>
                    <td className="text-[#1C1C1C] text-center">
                      {item?.quantity}
                    </td>
                    <td className="text-[#1C1C1C] !pr-[0.96667rem] whitespace-nowrap">
                      {item?.price_set.shop_money.currency_code}{" "}
                      {item?.price_set.shop_money.amount}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="w-full flex justify-end items-center border-b-2 border-gray-300 pb-12">
            <div className="max-w-md flex flex-col w-full items-start space-y-2 mt-8">
              <div className="flex justify-between items-center w-full">
                <p className="mb-0 text-[#1C1C1CB3] text-md font-semibold">
                  Subtotal
                </p>
                <p className="mb-0 text-right font-bold">
                  {order?.total_price_set.shop_money.currency_code}{" "}
                  {order?.total_price_set.shop_money.amount}
                </p>
              </div>
              {order?.tax_lines?.map((line: any, idx: number) => (
                <div
                  key={idx}
                  className="flex justify-between items-center w-full"
                >
                  <p className="mb-0 text-[#1C1C1CB3] text-md font-semibold">
                    {line?.title}
                  </p>
                  <p className="mb-0 text-right font-bold">
                    {line.price_set.shop_money.currency_code}{" "}
                    {line.price_set.shop_money.amount}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full flex justify-end items-center mt-8">
            <div className="max-w-md flex flex-col w-full items-start space-y-2 mt-8">
              <div className="w-full flex justify-between items-center">
                <h1 className="mb-0 font-bold text-lg">Total</h1>
                <p className="mb-0 text-sm text-[#8A8A8A] font-bold">
                  {order?.subtotal_price_set.shop_money.currency_code}
                  <span className="font-bold text-black text-2xl ml-2">
                    {order?.subtotal_price_set.shop_money.amount}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 bg-white py-10 px-6 w-full">
          <div className="flex w-full justify-between items-center border-b border-gray-300 pb-4">
            <p className="text-36 font-extrabold mb-0">Customer Info</p>
          </div>
          <div className="mt-6">
            <h1 className="text-xl font-bold mb-0">Contact Information</h1>
            <p className="text-lg mb-0 mt-1">{auth?.email}</p>
          </div>
          <div className="mt-6">
            <h1 className="text-xl font-bold mb-0">Shipping Info</h1>
            <p className="text-lg mb-0 mt-1">
              {shippingAddress?.first_name || auth?.firstName}{" "}
              {shippingAddress?.last_name || auth?.lastName}
            </p>
            <p className="text-lg mb-0">
              {shippingAddress?.address1 || auth?.defaultAddress?.address1}
            </p>
            <p className="text-lg mb-0">
              {shippingAddress?.city || auth?.defaultAddress?.city},{" "}
              {shippingAddress?.country || auth?.defaultAddress?.country}{" "}
              {shippingAddress?.zip || auth?.defaultAddress?.zip}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleOrder
