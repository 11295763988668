/* eslint-disable */

import React, { useState } from "react"
import Seo from "../../components/seo"

import { useStaticQuery, graphql } from "gatsby"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import { useStoryblok } from "../../utils/storyblok"
import DynamicComponents from "../../components/DynamicComponents"

import * as style from "./ReconVndSetup.module.scss"
// import 'react-multi-carousel/lib/styles.css';
import { FaPlus, FaMinus } from "react-icons/fa"

const ReconVndSetup = () => {
  const [firstIsOpen, setFirstIsOpen] = useState("false")
  const [secondIsOpen, setSecondIsOpen] = useState("false")
  const [thirdIsOpen, setThirdIsOpen] = useState("false")
  const [fourthIsOpen, setFourthIsOpen] = useState("false")
  const [fifthIsOpen, setFifthIsOpen] = useState("false")
  // const handleToggle = () => {
  //   setOpen(!isOpen);
  // };

  // const pageData = useStaticQuery(graphql`
  //   query {
  //     storyblokEntry(full_slug: {eq: "pages/flare-app"}) {
  //       id
  //       name
  //       content
  //       slug
  //       full_slug
  //       uuid
  //       created_at
  //     }
  //   }
  // `);

  // const story = pageData.storyblokEntry ? useStoryblok(pageData.storyblokEntry) : { content: null };

  // const stickyNavComponent = story.content && story.content['sticky_nav'] ? story.content['sticky_nav'].map(blok => (
  //   <>
  //     {blok ? (
  //       <div {...sbEditable(blok)} className="sticky top-0 z-50">
  //         <DynamicComponents blok={blok} product={{handle:'flare', title: 'flare'}} key={blok._uid} />
  //       </div>
  //     ) : null
  //     }
  //   </>
  // )) : null;

  return (
    <>
      <Seo title="Recon VND" />

      {/* sticky nav */}
      {/* <div className="sticky top-0 z-50">
        {stickyNavComponent}
      </div> */}

      <div className="bg-[#F5F5F5]">
        <div
          className={`${style.banner} text-white text-center justify-center flex-col flex`}
        >
          <p className="m-0 text-lg font-bold font-condensed">
            Recon VND | Hybrid Matte box System
          </p>
          <p className="text-2xl font-black">System Overview</p>
        </div>
        <div className="items px-[15px]">
          {/* choose your base */}
          <div className="setItems pt-14">
            <p className="text-xl font-bold font-condensed">
              Choose your Base:
            </p>
            <div className="flex flex-row">
              <div className="item pr-2">
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-VND-3-6.webp?v=1669916703"
                  className="m-0"
                />
                <p className="mt-2">VND 3-6</p>
              </div>
              <div className="item">
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-Thread-Base.webp?v=1669916703"
                  className="m-0"
                />
                <p className="mt-2">82mm Thread Base</p>
              </div>
            </div>
          </div>
          {/* add your stage 2 filters */}
          <div className="setItems pt-14">
            <p className="text-xl font-bold font-condensed">
              Add your Stage 2 Filters:
            </p>
            <div className="flex flex-row">
              <div className="item pr-2">
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/ND16.webp?v=1669916703"
                  className="m-0"
                />
                <p className="mt-2">ND16</p>
              </div>
              <div className="item">
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-BlueMorphic.webp?v=1669916703"
                  className="m-0"
                />
                <p className="mt-2">BlueMorphic</p>
              </div>
            </div>
            <div className="flex flex-row">
              <div className="item  pr-2">
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-GoldMorphic.webp?v=1669916703"
                  className="m-0"
                />
                <p className="mt-2">GoldMorphic</p>
              </div>
              <div className="item">
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-Mist.webp?v=1669916703"
                  className="m-0"
                />
                <p className="mt-2">Mist</p>
              </div>
            </div>
            <div className="flex flex-row">
              <div className="item max-w-[50%] pr-2">
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-Mist-H.webp?v=1669916703"
                  className="m-0"
                />
                <p className="mt-2">Mist-H</p>
              </div>
            </div>
          </div>
          {/* add matte box */}
          <div className="setItems pt-14">
            <p className="text-xl font-bold font-condensed">Add Matte Box:</p>
            <div>
              <div className="item max-w-[50%] pr-2">
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-Hood.webp?v=1669916703"
                  className="m-0"
                />
                <p className="mt-2">Recon Hood</p>
              </div>
            </div>
          </div>
        </div>

        {/* USER GUIDE SECTION */}
        <div className="userGuide px-[15px] mt-20">
          <h3>User Guides:</h3>

          {/* user guides items */}
          <div className="userGuideItem bg-obsidian text-white mb-2 p-[30px]">
            <a
              className="m-0 font-black block cursor-pointer"
              onClick={() => setFirstIsOpen(!firstIsOpen)}
            >
              Recon VND
              <FaPlus
                className={`${firstIsOpen ? "block" : "hidden"} float-right`}
              />
              <FaMinus
                className={`${firstIsOpen ? "hidden" : "block"} float-right`}
              />
            </a>
            {/* data container showing and hiding */}
            <div
              className={`${firstIsOpen ? "max-h-0 overflow-hidden" : "max-h-[2000px]"
                } ${style.data}`}
            >
              <img
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-User-Guide-VND.webp?v=1669921512"
                className="pt-6"
              />
              <div className="step">
                <p className="text-paloverde font-black mb-2">STEP 1:</p>
                <p>Unthread Defender back plate from Recon VND.</p>
              </div>
              <div className="step">
                <p className="text-paloverde font-black mb-2">STEP 2:</p>
                <p>
                  Thread Recon VND onto camera lens. Use{" "}
                  <a href="/products/step-up-rings" className="text-paloverde">
                    Step-Up Rings
                  </a>{" "}
                  to adapt to lenses other than 82mm.
                </p>
              </div>
              <div className="step">
                <p className="text-paloverde font-black mb-2">STEP 3:</p>
                <p>Remove Defender from front of Recon VND.</p>
              </div>
            </div>
          </div>

          {/* user guides items */}
          <div className="userGuideItem bg-obsidian text-white mb-2 p-[30px]">
            <a
              className="m-0 font-black block cursor-pointer"
              onClick={() => setSecondIsOpen(!secondIsOpen)}
            >
              Recon Hood
              <FaPlus
                className={`${secondIsOpen ? "block" : "hidden"} float-right`}
              />
              <FaMinus
                className={`${secondIsOpen ? "hidden" : "block"} float-right`}
              />
            </a>
            {/* data container showing and hiding */}
            <div
              className={`${secondIsOpen ? "max-h-0 overflow-hidden" : "max-h-[2000px]"
                } ${style.data}`}
            >
              <img
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-User-Guide-Hood.webp?v=1669921512"
                className="pt-6"
              />
              <div className="step">
                <p className="text-paloverde font-black mb-2">STEP 1:</p>
                <p>Unlock the Bronze Hood Rotation Lock lever.</p>
              </div>
              <div className="step">
                <p className="text-paloverde font-black mb-2">STEP 2:</p>
                <p>
                  Hold down the Gold Release Button and Fit the Mattebox onto
                  the VND or the Thread Base.
                </p>
              </div>
              <div className="step">
                <p className="text-paloverde font-black mb-2">STEP 3:</p>
                <p>
                  Once Fitted onto the VND or Thread base, release the gold
                  button and the Mattbox should be securely in place.
                </p>
              </div>
              <div className="step">
                <p className="text-paloverde font-black mb-2">STEP 4:</p>
                <p>
                  Rotate the Mattebox to your desired angle and lock the bronze
                  Hood Rotation lever.
                </p>
              </div>
            </div>
          </div>

          {/* user guides items */}
          <div className="userGuideItem bg-obsidian text-white mb-2 p-[30px]">
            <a
              className="m-0 font-black block cursor-pointer"
              onClick={() => setThirdIsOpen(!thirdIsOpen)}
            >
              Recon Flag
              <FaPlus
                className={`${thirdIsOpen ? "block" : "hidden"} float-right`}
              />
              <FaMinus
                className={`${thirdIsOpen ? "hidden" : "block"} float-right`}
              />
            </a>
            {/* data container showing and hiding */}
            <div
              className={`${thirdIsOpen ? "max-h-0 overflow-hidden" : "max-h-[2000px]"
                } ${style.data}`}
            >
              <img
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-User-Guide-Flag.webp?v=1669921512"
                className="pt-6"
              />
              <div className="step">
                <p className="text-paloverde font-black mb-2">STEP 1:</p>
                <p>Loosen Flag-Lock Knob.</p>
              </div>
              <div className="step">
                <p className="text-paloverde font-black mb-2">STEP 2:</p>
                <p>Insert Flag into hinge at 45 degree angle until flush.</p>
              </div>
              <div className="step">
                <p className="text-paloverde font-black mb-2">STEP 3:</p>
                <p>Adjust angle of flag.</p>
              </div>
              <div className="step">
                <p className="text-paloverde font-black mb-2">STEP 4:</p>
                <p>Tighten Flag-Lock Knob.</p>
              </div>
            </div>
          </div>

          {/* user guides items */}
          <div className="userGuideItem bg-obsidian text-white mb-2 p-[30px]">
            <a
              className="m-0 font-black block cursor-pointer"
              onClick={() => setFourthIsOpen(!fourthIsOpen)}
            >
              Recon Stage 2 Filters
              <FaPlus
                className={`${fourthIsOpen ? "block" : "hidden"} float-right`}
              />
              <FaMinus
                className={`${fourthIsOpen ? "hidden" : "block"} float-right`}
              />
            </a>
            {/* data container showing and hiding */}
            <div
              className={`${fourthIsOpen ? "max-h-0 overflow-hidden" : "max-h-[2000px]"
                } ${style.data}`}
            >
              <img
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-User-Guide-Stage-2-Filters.jpg?v=1669921512"
                className="pt-6"
              />
              <div className="step">
                <p className="text-paloverde font-black mb-2">STEP 1:</p>
                <p>Pinch attachment clips with thumb and pointer finger.</p>
              </div>
              <div className="step">
                <p className="text-paloverde font-black mb-2">STEP 2:</p>
                <p>Insert filter into Recon VND or Thread Base.</p>
              </div>
              <div className="step">
                <p className="text-paloverde font-black mb-2">STEP 3:</p>
                <p>Release Attachment Clips.</p>
              </div>
            </div>
          </div>

          {/* user guides items */}
          <div className="userGuideItem bg-obsidian text-white mb-2 p-[30px]">
            <a
              className="m-0 font-black block cursor-pointer"
              onClick={() => setFifthIsOpen(!fifthIsOpen)}
            >
              Recon Thread Base
              <FaPlus
                className={`${fifthIsOpen ? "block" : "hidden"} float-right`}
              />
              <FaMinus
                className={`${fifthIsOpen ? "hidden" : "block"} float-right`}
              />
            </a>
            {/* data container showing and hiding */}
            <div
              className={`${fifthIsOpen ? "max-h-0 overflow-hidden" : "max-h-[2000px]"
                } ${style.data}`}
            >
              <img
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-User-Guide-Thread-Base.webp?v=1669921512"
                className="pt-6"
              />
              <div className="step">
                <p className="text-paloverde font-black mb-2">STEP 1:</p>
                <p>Pinch attachment clips with thumb and pointer finger.</p>
              </div>
              <div className="step">
                <p className="text-paloverde font-black mb-2">STEP 2:</p>
                <p>Insert filter into Recon VND or Thread Base.</p>
              </div>
              <div className="step">
                <p className="text-paloverde font-black mb-2">STEP 3:</p>
                <p>Release Attachment Clips.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReconVndSetup
