/* eslint-disable */

import React, { useState } from "react"
import Seo from "../../components/seo"
import * as style from "./flareAppOverview.module.scss"

import { useStaticQuery, graphql } from "gatsby"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import { useStoryblok } from "../../utils/storyblok"
import DynamicComponents from "../../components/DynamicComponents"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
}

const FlareAppOverview = () => {
  const pageData = useStaticQuery(graphql`
    query {
      storyblokEntry(full_slug: { eq: "pages/flare-app" }) {
        id
        name
        content
        slug
        full_slug
        uuid
        created_at
      }
    }
  `)

  const story = pageData.storyblokEntry
    ? useStoryblok(pageData.storyblokEntry)
    : { content: null }

  const stickyNavComponent =
    story.content && story.content["sticky_nav"]
      ? story.content["sticky_nav"].map(blok => (
          <>
            {blok ? (
              <div {...sbEditable(blok)} className="sticky top-0 z-50">
                <DynamicComponents
                  blok={blok}
                  product={{ handle: "flare", title: "flare" }}
                  key={blok._uid}
                />
              </div>
            ) : null}
          </>
        ))
      : null

  return (
    <>
      <Seo title="Flare App Overview" />

      {/* sticky nav */}
      <div className="sticky top-0 z-50">{stickyNavComponent}</div>

      <div className={style.flareOverview}>
        <div className={style.hero}>
          <h2 className="font-bold display-font-logo">
            The app built for helping you <br />
            capture the perfect image<span>.</span>
          </h2>
          <a
            className={`${style.seeFlare} font-bold font-condensed`}
            href="#flare-slide"
          >
            See everything Flare has to offer.
            <span className={style.bottom}></span>
          </a>
        </div>
        <div className={style.slides} id="flare-slide">
          <div id={style.flareSlider}>
            <Carousel
              responsive={responsive}
              swipeable
              draggable
              showDots={true}
              infinite={true}
            >
              <div>
                <div className={style.item}>
                  <div className={style.ppSliderLayered}>
                    <div className={style.slideContent}>
                      <div
                        className={`${style.slideNumber} font-bold display-font-logo`}
                      >
                        01
                      </div>
                      <div className={style.text}>
                        <h3 className="font-bold display-font-logo">
                          Sun Path and
                          <br />
                          Sun Angle Graphs
                        </h3>
                        <p className="text-xs">
                          Know exactly where to setup your compositions in
                          conjunction with our golden hour / blue hour times
                          finder.
                        </p>
                      </div>
                      <div>
                        <img
                          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Sun-Path-Sun-Angle-Graphs.webp?v=1654634027"
                          class="desktop-only"
                        />
                        <img
                          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Sun-Path-Sun-Angle-Graphs-Mobile.webp?v=1654812954"
                          class="mobile-only"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={style.item}>
                  <div className={style.ppSliderLayered}>
                    <div className={style.slideContent}>
                      <div
                        className={`${style.slideNumber} font-bold display-font-logo`}
                      >
                        02
                      </div>
                      <div className={style.text}>
                        <h3 className="font-bold display-font-logo">
                          Detailed Forecasts
                        </h3>
                        <p className="text-xs">
                          No need to jump back and forth between apps. Flare
                          provides 7-day forecasts for your area along with
                          predicted wind speed and tide charts for coastal
                          shoots.
                        </p>
                      </div>
                      <div>
                        <img
                          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Detailed-Forecasts.webp?v=1654634027"
                          class="desktop-only"
                        />
                        <img
                          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Detailed-Forecasts-Mobile.webp?v=1654812954"
                          class="mobile-only"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
        <div className={style.download} id="download-section">
          <p className={`${style.subText} font-bold font-condensed`}>
            Download
            <br />
            on iOS or Android.
          </p>
          <div className={style.textBlurp}>
            <h2 className="font-bold display-font-logo">
              Download Flare Today
            </h2>
            <p className="font-bold">Get out and capture perfection.</p>
          </div>
          <div className={style.getApp}>
            <a
              className={style.appleStore}
              target="_blank"
              href="https://apps.apple.com/na/app/polarpro-flare/id1623568622"
            >
              <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/CTA_Download_Apple.svg?v=1654643546" />
            </a>
            <a
              className={style.googlePlay}
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.snd_flare_app"
            >
              <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/CTA_Download_Google_Play.svg?v=1654643546" />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default FlareAppOverview
