import React, { useState, useEffect } from "react"
// import { useCurrencyExchange } from "../../context/CurrencyExchange"
import axios from "axios"
import { productEndPointUrl } from "../../config"

function BelowBaseCampCurrency({ variantProduct, variant }: any) {
  // const { userCountry } = useCurrencyExchange()
  // const [currencyExchangedPrice, setCurrencyExchangedPrice] = useState(null)
  // const [countryCurrency, setCountryCurrency] = useState("")
  // const getproductCurrency = async () => {
  //   try {
  //     const response = await axios.post(
  //       productEndPointUrl,

  //       {
  //         country: userCountry?.cca2,
  //         productId: variantProduct?.shopifyId,
  //       },
  //     )

  //     if (response?.data?.product) {
  //       const selected =
  //         response.data?.product?.data?.product?.variants?.edges?.filter(
  //           (item: any) => {
  //             return item.node.id === variant?.shopifyId
  //           },
  //         )

  //       if (selected) {
  //         setCurrencyExchangedPrice(selected[0]?.node?.price?.amount)
  //         setCountryCurrency(selected[0]?.node?.price?.currencyCode)
  //       } else {
  //         setCountryCurrency(
  //           response.data?.product?.data?.product?.variants?.edges?.[0]?.node
  //             ?.price?.currencyCode,
  //         )
  //         setCurrencyExchangedPrice(
  //           response.data?.product?.data?.product?.variants?.edges?.[0]?.node
  //             ?.price?.amount,
  //         )
  //       }
  //     }
  //   } catch (err: any) {
  //     return {
  //       statusCode: 500,
  //       body: JSON.stringify({
  //         failed: true,
  //         message: err.message,
  //       }),
  //     }
  //   }
  // }

  // useEffect(() => {
  //   if (userCountry?.cca2) {
  //     getproductCurrency()
  //   }
  // }, [userCountry])
  return (
    <div>
      <p className="title uppercase font-black text-md lg:text-2xl m-0">
        {variant.title}
      </p>
      {/* {currencyExchangedPrice ? (
        <>
          {countryCurrency === "USD" ? "$" : countryCurrency + " "}
          {currencyExchangedPrice}
        </>
      ) : (
        `$${variant?.priceRangeV2?.maxVariantPrice?.amount}`
      )} */}
      {`$${variant?.priceRangeV2?.maxVariantPrice?.amount}`}
      <img
        className="image m-auto w-[65%]"
        src={
          variant?.featuredImage?.originalSrc ||
          variant?.image?.originalSrc ||
          "https://cdn.shopify.com/s/files/1/1050/9944/files/VND2-5.png?v=1688156538"
        }
        alt="Product"
      />
    </div>
  )
}

export default BelowBaseCampCurrency
