/* eslint-disable */

import { Link } from "gatsby"
import React, { useMemo, useState } from "react"
import { AiOutlineClose, AiOutlineRight, AiOutlineLeft } from "react-icons/ai"
import { useLocation } from "@reach/router"

import {
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaTwitter,
  FaVimeoV,
  FaYoutube,
} from "react-icons/fa"

const SideBar = ({ onMenuClick, menuActive, setMenuActive, story }: any) => {
  const location = useLocation()

  const sub_nav_bar_shop = story?.content?.sub_nav_bar_shop
  const [selectedSubItem, setSelectedSubItem] = useState<
    null | "explore" | "support" | string
  >(null)
  const storyContent = story?.content
  const {
    explore_title,
    support_title,
    sub_nav_bar_explore,
    sub_nav_bar_support,
  } = storyContent
  const selectedItemContent = useMemo(() => {
    return selectedSubItem === "explore"
      ? { nav: sub_nav_bar_explore, title: explore_title }
      : { nav: sub_nav_bar_support, title: support_title }
  }, [selectedSubItem])
  const [facebook, instagram, youtube, vimeo, twitter, tiktok] =
    storyContent?.social_links || []
  const [selectedItem, setSelectedItem] = useState(-1)
  const content = useMemo(() => {
    if (selectedItem === -1) return null
    const shopAll = sub_nav_bar_shop[selectedItem].shopAll
    return {
      selectedContent: sub_nav_bar_shop[selectedItem].menuItems,
      columns: sub_nav_bar_shop[selectedItem].columns,
      shopAll,
      bannerFileName: sub_nav_bar_shop[selectedItem]?.banner?.filename,
    }
  }, [selectedItem, sub_nav_bar_shop])
  let selectedContent: any = null
  let shopAll: any = null
  let columns = 3
  if (content?.selectedContent) {
    selectedContent = content.selectedContent
    shopAll = content.shopAll
    columns = parseInt(content.columns || "0") || 3
  }
  return (
    <div
      style={{ left: menuActive ? 0 : "-100%" }}
      className="xl:hidden !font-primary  w-screen h-screen top-0 left-0 fixed z-[999999999]"
    >
      {menuActive && (
        <div
          style={{
            backdropFilter: "blur(25px) opacity(1)",
            backgroundColor: "rgba(17, 17, 17, .7)",
          }}
          onClick={() => {
            onMenuClick()
            setSelectedSubItem(null)
            setSelectedItem(-1)
          }}
          className={`w-full h-full absolute inset-0 z-[0]`}
        />
      )}
      <div
        style={{
          left: menuActive ? 0 : "-100%",
        }}
        className="transition-all flex duration-700 overflow-y-auto fixed top-0 left-[-100%] w-[90%] overflow-hidden h-full bg-white p-5"
      >
        <div className="w-full h-full relative">
          <button
            onClick={() => {
              onMenuClick()
              setSelectedSubItem(null)
              setSelectedItem(-1)
            }}
            className="absolute right-0 top-0"
          >
            <AiOutlineClose color="#1C1C1C" fontSize={20} />
          </button>
          <div className="mt-14 space-y-8">
            {sub_nav_bar_shop?.map((item: any, index: number) => {
              return (
                <div
                  onClick={() => setSelectedItem(index)}
                  key={index}
                  className="flex cursor-pointer justify-between w-full items-center"
                >
                  <p className="mb-0 text-[#1C1C1C] font-sans font-extrabold text-[15pt] uppercase">
                    {item.title}
                  </p>
                  <AiOutlineRight color="#1C1C1C" />
                </div>
              )
            })}
          </div>
          <div className="my-8 py-8 border-t border-b border-[#E6E6E6] space-y-4">
            <button
              onClick={() => setSelectedSubItem("explore")}
              className="w-full flex justify-between items-center"
            >
              <p className="m-0 font-sans font-normal text-[#8A8A8A] text-lg">
                {explore_title}
              </p>
              <AiOutlineRight color="#8A8A8A" />
            </button>
            <button
              onClick={() => (window.location.href = "/support/")}
              className="w-full flex justify-between items-center"
            >
              <p className="m-0 font-sans font-normal text-[#8A8A8A] text-lg">
                {support_title}
              </p>
            </button>
            <div
              onClick={() => {
                setSelectedSubItem(null)
                setSelectedItem(-1)
                onMenuClick()
              }}
              className="w-full flex justify-between items-center"
            >
              <Link
                className="flex items-center space-x-2"
                to={`${
                  (
                    typeof window !== "undefined"
                      ? JSON.parse(localStorage.getItem("loggedIn") || "{}")
                      : false
                  )
                    ? "/account"
                    : "/welcome"
                }`}
              >
                <p className="mb-0 text-lg mt-1.5 font-sans font-normal text-[#8A8A8A]">
                  Account
                </p>
              </Link>
            </div>
          </div>
          <div>
            <ul className="flex justify-between m-0 list-none">
              <li>
                <a
                  href={facebook?.link}
                  title={facebook?.display_name}
                  target="_blank"
                >
                  <FaFacebookF color="#8A8A8A" />
                </a>
              </li>
              <li>
                <a
                  href={instagram?.link}
                  title={instagram?.display_name}
                  target="_blank"
                >
                  <FaInstagram color="#8A8A8A" />
                </a>
              </li>
              <li>
                <a
                  href={youtube?.link}
                  title={youtube?.display_name}
                  target="_blank"
                >
                  <FaYoutube color="#8A8A8A" />
                </a>
              </li>
              <li>
                <a
                  href={vimeo?.link}
                  title={vimeo?.display_name}
                  target="_blank"
                >
                  <FaVimeoV color="#8A8A8A" />
                </a>
              </li>
              <li>
                <a
                  href={twitter?.link}
                  title={twitter?.display_name}
                  target="_blank"
                >
                  <FaTwitter color="#8A8A8A" />
                </a>
              </li>
              <li>
                <a
                  href={tiktok?.link}
                  title={tiktok?.display_name}
                  target="_blank"
                >
                  <FaTiktok color="#8A8A8A" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        style={{
          left: selectedSubItem?.length ? 0 : "-100%",
          zIndex: "200",
        }}
        className="transition-all flex duration-700 overflow-y-auto fixed top-0 left-[-100%] w-[90%] overflow-hidden h-full bg-white p-5"
      >
        <div className="w-full h-full relative">
          <button
            onClick={() => {
              onMenuClick()
              setSelectedSubItem(null)
              setSelectedItem(-1)
            }}
            className="absolute right-0 top-0"
          >
            <AiOutlineClose color="#1C1C1C" fontSize={20} />
          </button>
          <button
            onClick={() => setSelectedSubItem(null)}
            className="absolute left-0 top-0"
          >
            <AiOutlineLeft color="#1C1C1C" fontSize={20} />
          </button>
          <div className="flex justify-center items-center">
            <button
              onClick={() => setSelectedSubItem(null)}
              className="font-sans font-bold text-sm text-[#1C1C1C]"
            >
              {selectedItemContent?.title}
            </button>
          </div>
          <div
            className="my-16"
            onClick={() => {
              setSelectedSubItem(null)
              setSelectedItem(-1)
              onMenuClick()
            }}
          >
            {selectedItemContent.title === "Support" ? (
              <Link to="/support/">
                <p className="m-0 mt-3 font-sans text-sm text-[#1C1C1C]">
                  Support
                </p>
              </Link>
            ) : null}
            {selectedItemContent.nav?.map((item: any, index: number) => {
              return (
                <Link
                  to={`${item.link}/${location.search ? location.search : ""}`}
                  key={index}
                >
                  <p className="m-0 mt-3 font-sans text-sm text-[#1C1C1C]">
                    {item.label}
                  </p>
                </Link>
              )
            })}
          </div>
        </div>
      </div>
      <div
        style={{
          left: selectedContent?.length ? 0 : "-100%",
          zIndex: "300",
        }}
        className="transition-all flex duration-700 overflow-y-auto fixed top-0 left-[-100%] w-[90%] overflow-hidden h-full bg-white p-5"
      >
        <div className="w-full h-full relative">
          <button
            onClick={() => {
              onMenuClick()
              setSelectedSubItem(null)
              setSelectedItem(-1)
            }}
            className="absolute right-0 top-0"
          >
            <AiOutlineClose color="#1C1C1C" fontSize={20} />
          </button>
          <button
            onClick={() => setSelectedItem(-1)}
            className="absolute left-0 top-0"
          >
            <AiOutlineLeft color="#1C1C1C" fontSize={20} />
          </button>
          <div className="flex justify-center items-center">
            <button
              onClick={() => setSelectedItem(-1)}
              className="font-sans font-bold text-sm text-[#1C1C1C]"
            >
              {sub_nav_bar_shop?.[selectedItem]?.title}
            </button>
          </div>
          {content?.bannerFileName && (
            <div className="flex w-full  justify-center items-center bg-[#ededed]  mt-7 rounded">
              <div className="w-full flex justify-center items-center">
                <img
                  src={content?.bannerFileName}
                  alt="bannerFilename"
                  className="h-16 w-1/2 pt-5  object-contain"
                />
              </div>
            </div>
          )}
          <div
            style={{
              gridTemplateColumns:
                selectedContent?.length < 2
                  ? `repeat(${selectedContent.length}, minmax(0, 1fr))`
                  : "",
            }}
            onClick={() => {
              setSelectedSubItem(null)
              setSelectedItem(-1)
              onMenuClick()
            }}
            className="grid grid-cols-2 px-0 sm:px-2.5 pb-[50px] items-start"
          >
            {selectedContent?.map((item: any, index: number) => {
              if (item.label === "Shop All") return null
              const locationSearch = new URLSearchParams(location.search)
              locationSearch.delete("variant")
              return (
                <Link
                  to={`${item.link}${
                    locationSearch
                      ? (item.link.includes("?") ? "&" : "?") + locationSearch
                      : ""
                  }`}
                  key={index}
                  className="flex flex-col justify-center items-center mt-10"
                >
                  <img
                    src={item?.image?.filename || "/images/exampleImageNav.png"}
                    className="mb-0 h-auto object-cover"
                    width={80}
                  />
                  <p className="m-0 font-[750] font-sans font-expanded text-sm text-[#1C1C1C] uppercase mt-2.5 text-center">
                    {item.label}
                  </p>

                  <p className="mb-0 font-sans px-2.5 font-condensed text-[11px] text-[#949494] leading-3 mt-1 text-center">
                    {item.description}
                  </p>
                  {item?.list?.length > 0 && (
                    <div className="w-11/12 h-[1px] bg-[#f1f1f1] mt-3" />
                  )}

                  {item?.list?.length > 0 && (
                    <div className="flex flex-col leading-[20px] pt-2 font-bold text-center ">
                      {item?.list.map((item: any) => (
                        <Link to={`${item?.link?.url || item?.link}`}>
                          <div className="text-[16px] leading-[25px] cursor-pointer hover:text-gray-500">
                            {item?.title}
                          </div>
                        </Link>
                      ))}
                    </div>
                  )}
                </Link>
              )
            })}
          </div>
          {/* shop all link stroyblock entry go here mobile*/}
          {shopAll?.url?.length ? (
            <Link
              className="text-center mt-4 pb-8 mx-0 block font-extrabold font-condensed uppercase underline text-sm"
              to={shopAll?.url}
              onClick={() => {
                setSelectedSubItem(null)
                setSelectedItem(-1)
                onMenuClick()
              }}
            >
              Shop All
            </Link>
          ) : null}
          {shopAll?.label && (
            <div
              onClick={() => {
                setSelectedSubItem(null)
                setSelectedItem(-1)
                onMenuClick()
              }}
            >
              <Link
                to={`${shopAll.link}/${location.search ? location.search : ""}`}
                className="flex justify-center items-center w-full mt-10"
              >
                <p className="mb-0 text-[#1C1C1C] hover:text-[#C99E1A] font-sans font-[15px] font-bold">
                  {shopAll?.label}
                </p>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SideBar
