import React, { useState, useEffect } from "react"
// import { useCurrencyExchange } from "../../context/CurrencyExchange"
import axios from "axios"
import { productEndPointUrl } from "../../config"

function ExchangePrice({ item }: any) {
  // const { userCountry } = useCurrencyExchange()
  // const [currencies, setCurrencies] = useState<any>(null)
  // const [countryCurrency, setCountryCurrency] = useState(null)

  // const getproductCurrency = async () => {
  //   if (userCountry?.cca2) {
  //     try {
  //       const response = await axios.post(productEndPointUrl, {
  //         country: userCountry?.cca2,
  //         productId: item?.shopifyId,
  //       })
  //       if (response?.data?.product) {
  //         setCurrencies(
  //           response.data?.product?.data?.product?.variants?.edges?.[0]?.node
  //             ?.price?.amount,
  //         )
  //         setCountryCurrency(
  //           response.data?.product?.data?.product?.variants?.edges?.[0]?.node
  //             ?.price?.currencyCode,
  //         )
  //       }
  //     } catch (err: any) {
  //       return {
  //         statusCode: 500,
  //         body: JSON.stringify({
  //           failed: true,
  //           message: err.message,
  //         }),
  //       }
  //     }
  //   }
  // }

  // useEffect(() => {
  //   if (userCountry?.cca2) {
  //     getproductCurrency()
  //   }
  // }, [userCountry])

  return (
    <>
      <p className="mb-0 text-xs font-semibold">{item.title}</p>
      <div className="variant">
        {item.variants[0].title !== "Default Title" ? (
          <>
            <p className="font-medium text-[13px] pr-1 mb-0 inline">Variant:</p>
            <p className="mb-0 text-[13px] font-medium font-normal inline">
              {item?.variants[0]?.title}
            </p>
          </>
        ) : null}
      </div>
      <div>
        <span className="text-paloverde text-[13px]">
          {/* {countryCurrency && countryCurrency === "USD"
            ? "$"
            : countryCurrency && countryCurrency + " "}
          {currencies ? currencies : `$${item?.variants[0]?.price}`} */}
          {`$${item?.variants[0]?.price}`}
        </span>
      </div>
    </>
  )
}

export default ExchangePrice
