import React from "react"
import PropTypes from "prop-types"
import * as style from "./style.module.scss"

const ImageComparison = ({ blok }) => {
  const {
    title,
    subTitle,
    polarproImage,
    polarproImageAlt,
    otherImage,
    otherImageAlt,
  } = blok

  return (
    <div className={`mt-20 ${style.colorAccuracy}`}>
      <p className="display-font font-black text-obsidian text-center mb-2 text-[18.75px]">
        {title}
      </p>
      <p className="text-center font-medium text-sm text-dim-gray mb-2">
        {subTitle}
      </p>
      <hr className="text-center w-[100px] h-[2px] bg-paloverde mx-auto mb-20 mt-2" />
      <div className={style.compareContainer}>
        <img src={polarproImage} alt={polarproImageAlt} />
        <img src={otherImage} alt={otherImageAlt} />
      </div>
    </div>
  )
}

export default ImageComparison

ImageComparison.propTypes = {
  blok: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    polarproImage: PropTypes.string.isRequired,
    polarproImageAlt: PropTypes.string.isRequired,
    otherImage: PropTypes.string.isRequired,
    otherImageAlt: PropTypes.string.isRequired,
  }).isRequired,
}
