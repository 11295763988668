import React, { useState, useEffect } from "react"
// import { useCurrencyExchange } from "../../context/CurrencyExchange"
import axios from "axios"
import { productEndPointUrl } from "../../config"

function HelixVariantCurrency({ product, variant }: any) {
  // const { userCountry } = useCurrencyExchange()
  // const [currencyExchangedPrice, setCurrencyExchangedPrice] = useState(null)
  // const [countryCurrency, setCountryCurrency] = useState("")
  // const getproductCurrency = async () => {
  //   try {
  //     const response = await axios.post(productEndPointUrl, {
  //       country: userCountry?.cca2,
  //       productId: product?.shopifyId,
  //     })

  //     if (response?.data?.product) {
  //       const selected =
  //         response.data?.product?.data?.product?.variants?.edges?.filter(
  //           (item: any) => {
  //             return item.node.id === variant?.shopifyId
  //           },
  //         )
  //       if (selected) {
  //         setCurrencyExchangedPrice(selected[0]?.node?.price?.amount)
  //         setCountryCurrency(selected[0]?.node?.price?.currencyCode)
  //       }
  //     }
  //   } catch (err: any) {
  //     return {
  //       statusCode: 500,
  //       body: JSON.stringify({
  //         failed: true,
  //         message: err.message,
  //       }),
  //     }
  //   }
  // }

  // useEffect(() => {
  //   if (userCountry?.cca2) {
  //     getproductCurrency()
  //   }
  // }, [userCountry])
  return (
    <div>
      <p className="title uppercase font-black text-md lg:text-2xl m-0">
        {variant.title}
      </p>
      <p className="price text-sm">
        {/* {currencyExchangedPrice ? (
          <>
            {countryCurrency === "USD" ? "$" : countryCurrency + " "}
            {currencyExchangedPrice}
          </>
        ) : (
          `$${variant.price}`
        )} */}
        {`$${variant.price}`}
      </p>
    </div>
  )
}

export default HelixVariantCurrency
