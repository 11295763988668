import { navigate } from "gatsby"
import React from "react"

const SupportThank = () => {
  const handleContinue = () => {
    navigate("/")
  }
  return (
    <div
      style={{ backgroundImage: "url(/images/BACKGROUND.webp)" }}
      className="bg-white py-16 w-full"
    >
      <div className="w-full flex justify-center items-center flex-col pb-6">
        <div className="">
          <img
            className="lg:w-32 lg:h-32 w-24 h-24"
            src="/images/checkIcon.png"
            alt=""
          />
        </div>
        <div className="flex justify-center flex-col items-center w-[80%]">
          <p className="lg:text-[50px] text-[30px]  text-[#FFFFFF] font-sans font-bold">
            Thank You!
          </p>
          <p className="text-[#FFFFFF] lg:text-[31px] font-sans text-center text-[18px] mb-0">
            Your support ticket has been received, we’ll be in touch during
            office hours:
          </p>
          <span className="font-semibold font-sans text-center text-[#FFFFFF]  lg:text-[31px] md:mt-4 mt-2 text-[18px]">
            {" "}
            M-F 9am-6pm PST
          </span>
          <p className="text-[#FFFFFF] sm:w-[70%] w-full pt-6 text-center font-sans text-[13px] lg:text-[19px]">
            You will receive an automated confirmation of your support request
            within 5 minutes. If you do not receive it, please confirm your
            email was entered correctly or check your spam.
          </p>
          <button
            className=" text-white px-[60px] py-4 font-sans  rounded-sm mt-4 bg-paloverde font-bold"
            onClick={handleContinue}
          >
            CONTINUE SHOPPING
          </button>
        </div>
      </div>
    </div>
  )
}

export default SupportThank
