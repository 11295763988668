/* eslint-disable */
import React from "react"
import { CgChevronLeft, CgChevronRight } from "react-icons/cg"
import { pagination, paginationButton } from "./style.module.css"

const CollectionPagination = ({
  paginationArray,
  currentPageNumber,
  setCurrentPageNumber,
  totalCollectionPageNumber,
}) => {
  return (
    <nav className={`${pagination} mb-10`}>
      <button
        className={paginationButton}
        disabled={currentPageNumber === 1}
        onClick={() => setCurrentPageNumber(currentPageNumber - 1)}
        aria-label="Previous page"
      >
        <CgChevronLeft className="mb-1" />
      </button>
      {paginationArray.map(i => (
        <button
          className="mx-2"
          onClick={() => setCurrentPageNumber(i)}
          disabled={i === currentPageNumber}
        >
          {i}
        </button>
      ))}
      <button
        className={`${paginationButton}`}
        disabled={currentPageNumber === totalCollectionPageNumber}
        onClick={() => setCurrentPageNumber(currentPageNumber + 1)}
        aria-label="Next page"
      >
        <CgChevronRight className="mb-1" />
      </button>
    </nav>
  )
}

export default CollectionPagination
