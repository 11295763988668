// import React, { useState } from "react"
// import * as style from "./style.module.scss"
// import { IoClose } from "react-icons/io5"

// function ProductHero(props: any) {
//   const [playVideo, setPlayVideo] = React.useState(false)
//   const [mobilePlayButton, setMobilePlayButton] = useState(false)

//   const getYoutubeVideoId = (url: string) => {
//     const videoIdMatch = url && url.match(/youtu\.be\/([a-zA-Z0-9_-]+)/)
//     if (videoIdMatch && videoIdMatch.length >= 2) {
//       return videoIdMatch[1]
//     }
//   }

//   return (
//     <div className={`${style.productHeroStyles} overflow-hidden w-full`}>
//       <div
//         className={`${style.productHeroBanner} relative text-white hidden lg:block w-full`}
//         style={{
//           background: `url(${props?.backgroundImage?.filename})`,
//           backgroundSize: "cover",
//           backgroundRepeat: "no-repeat",
//         }}
//       >
//         {!!props?.video_url?.url?.length ? (
//           <div>
//             <img
//               src="/images/playButton.png"
//               alt="playButton"
//               className={` ${style.playButton} absolute w-28 h-28 top-1/2 left-[12%] left:right-1/5 cursor-pointer`}
//               onClick={() => {
//                 setPlayVideo(true)
//               }}
//               id="playButtonId"
//             />

//             {playVideo ? (
//               <div className="fixed bg-transparent w-full flex justify-center items-center left-0 top-0 right-0 bottom-0 z-[99999] pointer-events-none">
//                 <div className="fixed w-screen h-screen bg-black opacity-50 z-[9999] left-0 top-0 right-0 bottom-0 backdrop-blur-[2px]"></div>
//                 <div
//                   className=" bg-gray-500 rounded-full flex justify-center items-center p-2 fixed right-[22%] top-[23%] pointer-events-auto z-[999999] cursor-pointer"
//                   onClick={() => setPlayVideo(false)}
//                 >
//                   <IoClose className="!cursor-pointer" />
//                 </div>

//                 {props.video_url.url ? (
//                   <iframe
//                     title={`video`}
//                     src={`https://www.youtube.com/embed/${getYoutubeVideoId(
//                       props.video_url.url,
//                     )}`}
//                     className="h-1/2 w-1/2 !cursor-pointer pointer-events-auto z-[9999]"
//                     frameBorder="0"
//                     allow="autoplay; fullscreen; picture-in-picture"
//                     allowFullScreen
//                   />
//                 ) : (
//                   <video
//                     width="100%"
//                     height="100%"
//                     autoPlay
//                     playsInline
//                     muted
//                     className={`${style.video} w-4/5 h-auto z-[99999]`}
//                     poster="video-poster.jpg"
//                     controls={false}
//                   >
//                     <source src={props.video_url.url} type="video/mp4" />
//                   </video>
//                 )}
//               </div>
//             ) : null}
//           </div>
//         ) : null}
//         <div className={`${style.introDataRight} pl-0 md:pl-30 right-0 !left-[53%]`}>
//           <p className="text-4xl font-black">{props.title}</p>
//           <div className="w-9/12">
//             <p className="hidden lg:block">{props.description}</p>
//             <p className="block lg:hidden">{props.description}</p>
//           </div>
//         </div>
//       </div>
//       <div
//         className={`${style.productHeroBanner} relative text-white block lg:hidden w-full`}
//         style={{
//           background: `url(${props?.mobileBackgroundImage?.filename})`,
//           backgroundSize: "cover",
//           backgroundRepeat: "no-repeat",
//         }}
//       >
//         {!!props?.video_url?.url?.length ? (
//           <div>
//             <img
//               src="/images/playButton.png"
//               alt="playButton"
//               className="absolute w-40 h-40 top-1/5 right-[36%] cursor-pointer"
//               onClick={() => {
//                 setMobilePlayButton(true)
//               }}
//               id="playButton"
//             />
//             {mobilePlayButton ? (
//               <div className="fixed bg-transparent w-full flex justify-center items-center left-0 top-0 right-0 bottom-0 z-[99999] pointer-events-none">
//                 <div className="fixed w-screen h-screen bg-black opacity-50 z-[9999] left-0 top-0 right-0 bottom-0 backdrop-blur-[2px]"></div>
//                 <div
//                   className=" bg-gray-500 rounded-full flex justify-center items-center p-2 fixed right-[14%] top-[20%] pointer-events-auto z-[999999] cursor-pointer"
//                   onClick={() => setMobilePlayButton(false)}
//                 >
//                   <IoClose className="!cursor-pointer" />
//                 </div>
//                 {props.video_url.url ? (
//                   <iframe
//                     title={`video`}
//                     src={`https://www.youtube.com/embed/${getYoutubeVideoId(
//                       props.video_url.url,
//                     )}`}
//                     className="h-1/2 w-3/5 !cursor-pointer pointer-events-auto z-[9999]"
//                     frameBorder="0"
//                     allow="autoplay; fullscreen; picture-in-picture"
//                     allowFullScreen
//                   />
//                 ) : (
//                   <video
//                     width="100%"
//                     height="100%"
//                     autoPlay
//                     playsInline
//                     muted
//                     className={`${style.video} w-4/5 h-auto z-[99999]`}
//                     poster="video-poster.jpg"
//                     controls={false}
//                   >
//                     <source src={props.video_url.url} type="video/mp4" />
//                   </video>
//                 )}
//               </div>
//             ) : null}
//           </div>
//         ) : null}
//         <div className={`${style.introDataRight} pl-0 md:pl-30 relative`}>
//           <p className="text-4xl md:text-5xl font-black font-expanded">
//             {props.title}
//           </p>
//           <div className="w-9/12 font-bold text-xl absolute top-[130vw]">
//             <p className="hidden lg:block">{props.description}</p>
//             <p className="block lg:hidden">{props.description}</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default ProductHero

import React, { useState } from "react"
import * as style from "./style.module.scss"
import { TfiClose } from "react-icons/tfi"
import PlayIcon from "../Icons/PlayIcon"

// @ts-ignore
import loadable from "@loadable/component"
// @ts-ignore
const ModalVideo = loadable(() => import("react-modal-video"))

function ProductHero(props: any) {
  const [playVideo, setPlayVideo] = React.useState(false)
  const [mobilePlayButton, setMobilePlayButton] = useState(false)

  const getYoutubeVideoId = (url: string) => {
    const videoIdMatch = url && url.match(/youtu\.be\/([a-zA-Z0-9_-]+)/)
    if (videoIdMatch && videoIdMatch.length >= 2) {
      return videoIdMatch[1]
    }
  }

  return (
    <>
      <div
        className={`${style.productHeroBannerMobile} relative text-white flex-col block lg:hidden w-full`}
        style={{
          background: `url(${props?.mobileBackgroundImage?.filename})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        {props?.logo?.filename ? (
          <div className={`${style.introDataTop} h-[140vw] block relative`}>
            <div className={`${style.title} pl-12 relative w-10/12 md:w-9/12`}>
              <img src={props?.logo?.filename} alt="logo" />
            </div>

            <div className="w-10/12 md:w-9/12 text-xl pl-12 xl:pl-0 subtext">
              <p className="text-[16px] sm:text-[20px] leading-[25px]">{props.description}</p>
            </div>
          </div>
        ) : (
          <div className={`${style.introDataTop}`}>
            <p
              className={`${style.title} text-4xl md:text-5xl pl-12 xl:pl-0 font-black`}
            >
              {props.title}
            </p>

            <div className="w-10/12 md:w-9/12 text-xl pl-12 xl:pl-0">
              <p className="text-[16px] sm:text-[20px] leading-[25px]">{props.description}</p>
            </div>
          </div>
        )}
        {!!props?.video_url?.url?.length ? (
          <div className={`${style.introDataBottom}`}>
            <button
              className={`${style.PlayBtn} cta-round opacity-90 hover:opacity-100`}
              onClick={e => {
                e.preventDefault()
                setPlayVideo(true)
              }}
            >
              <PlayIcon />
            </button>

            <div className="absolute"></div>
          </div>
        ) : null}

      </div>

      <div
        className={`${style.productHeroDefault} h-auto text-white lg:flex flex-col lg:flex-row hidden lg:block`}
        style={{
          background: `url(${props?.backgroundImage?.filename})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "35% 0",
        }}
      >
        <div className={`${style.introDataLeft}`}>
          {!!props?.video_url?.url?.length ? (
            <div className="video-container">
              <button
                className={`${style.PlayBtn} cta-round opacity-90 hover:opacity-100`}
                onClick={e => {
                  e.preventDefault()
                  setPlayVideo(true)
                }}
              >
                <PlayIcon />
              </button>
            </div>
          ) : null}
        </div>
        <div className={`${style.introDataRight} lg:block`}>
          <div className={`${style.title} w-11/12 pl-12 lg:pt-0 2xl:pt-52`}>
            {props?.logo?.filename ? (
              <img src={props?.logo?.filename} alt="logo" className="w-1/2 lg:w-3/4 xl:w-1/2" />
            ) : (
              <p className="text-4xl lg:text-2xl xl:text-3xl 2xl:text-4xl font-black">
                {props.title}
              </p>
            )}
            <p className="text-[16px] lg:text-[14px] 2xl:text-[16px] w-auto 2xl:w-2/3">
              {props.description}
            </p>
          </div>
        </div>
      </div>

      {playVideo ? (
        <ModalVideo
          channel="youtube"
          videoId={getYoutubeVideoId(props.video_url.url)}
          isOpen={playVideo}
          onClose={() => setPlayVideo(false)}
        />
      ) : null}
    </>
  )
}

export default ProductHero
