import { useEffect } from "react"

export const Suppression = () => {
  useEffect(() => {
    var cartScript = document.createElement("script")
    cartScript.type = "text/javascript"
    cartScript.innerHTML = `
 !function(){var geq=window.geq=window.geq||[];if(geq.initialize) return;if (geq.invoked){if (window.console && console.error) {console.error("GE snippet included twice.");}return;}geq.invoked = true;geq.methods = ["page", "suppress", "trackOrder", "identify", "addToCart", "callBack", "event"];geq.factory = function(method){return function(){var args = Array.prototype.slice.call(arguments);args.unshift(method);geq.push(args);return geq;};};for (var i = 0; i < geq.methods.length; i++) {var key = geq.methods[i];geq[key] = geq.factory(key);}geq.load = function(key){var script = document.createElement("script");script.type = "text/javascript";script.async = true;if (location.href.includes("vge=true")) {script.src = "https://s3-us-west-2.amazonaws.com/jsstore/a/" + key + "/ge.js?v=" + Math.random();} else {script.src = "https://s3-us-west-2.amazonaws.com/jsstore/a/" + key + "/ge.js";}var first = document.getElementsByTagName("script")[0];first.parentNode.insertBefore(script, first);};geq.SNIPPET_VERSION = "1.6.1";
geq.load("E82H7M9");}();
`

    document.head.appendChild(cartScript)

    // Create and append the second script tag
    var geqScriptCart = document.createElement("script")
    geqScriptCart.type = "text/javascript"
    geqScriptCart.innerHTML = "geq.suppress();"

    document.head.appendChild(geqScriptCart)
  }, [])

  return null
}

// Collection script
export const CollectionScript = () => {
  useEffect(() => {
    var cartScript = document.createElement("script")
    cartScript.type = "text/javascript"
    cartScript.innerHTML = `
 !function(){var geq=window.geq=window.geq||[];if(geq.initialize) return;if (geq.invoked){if (window.console && console.error) {console.error("GE snippet included twice.");}return;}geq.invoked = true;geq.methods = ["page", "suppress", "trackOrder", "identify", "addToCart", "callBack", "event"];geq.factory = function(method){return function(){var args = Array.prototype.slice.call(arguments);args.unshift(method);geq.push(args);return geq;};};for (var i = 0; i < geq.methods.length; i++) {var key = geq.methods[i];geq[key] = geq.factory(key);}geq.load = function(key){var script = document.createElement("script");script.type = "text/javascript";script.async = true;if (location.href.includes("vge=true")) {script.src = "https://s3-us-west-2.amazonaws.com/jsstore/a/" + key + "/ge.js?v=" + Math.random();} else {script.src = "https://s3-us-west-2.amazonaws.com/jsstore/a/" + key + "/ge.js";}var first = document.getElementsByTagName("script")[0];first.parentNode.insertBefore(script, first);};geq.SNIPPET_VERSION = "1.6.1";
geq.load("E82H7M9");}();
`

    document.head.appendChild(cartScript)

    // Create and append the second script tag
    var geqScriptCart = document.createElement("script")
    geqScriptCart.type = "text/javascript"
    geqScriptCart.innerHTML = "geq.page();"

    document.head.appendChild(geqScriptCart)
  }, [])

  return null
}
