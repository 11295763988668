/* eslint-disable */

import React, { useState, useEffect, FC } from "react"
import { useLocation } from "@reach/router"

import { Link, navigate } from "gatsby"
import axios from "axios"
import { Formik, Form, Field, ErrorMessage } from "formik"
import useAuth from "../hooks/useAuth"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import LoginBanner from "../components/LoginBanner"
// import BackIcon from '../images/Profile-login-Back.svg';
// import Eye from '../images/Password-Eye.svg';
import * as style from "./welcome.module.scss"
import { StaticImage } from "gatsby-plugin-image"
import { Suppression } from "../scripts/retention"
import { functionsEndpoint } from "../config"
import axiosLib from "axios"

const Signup: FC<RoutedHTMLAttr> = () => {
  const location = useLocation()

  const isBrowser = typeof window !== "undefined"
  const { auth, setAuth } = useAuth()
  const [showPassword, setShowPassword] = useState(false)
  const [showNotice, setShowNotice] = useState(false)
  const [msg, setMsg] = useState("")
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errMessage, setErrMessage] = useState("")

  const privateAxios = useAxiosPrivate()

  useEffect(() => {
    if (
      isBrowser &&
      localStorage.getItem("loggedIn") === "true" &&
      // @ts-ignore
      !auth.accessToken
    ) {
      setLoading(true)
      axiosLib
        .get(functionsEndpoint + "/refresh", {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(res => {
          // console.log(res)
          // @ts-ignore
          setAuth({
            accessToken: res.data.accesToken,
          })
          navigate(`/account/${location.search ? location.search : ""}`)
        })
        .catch(err => {
          // console.log('No tokens found. Please log in');
          setLoading(false)
          localStorage.setItem("loggedIn", "false")
        })
    } else if (auth?.accessToken) {
      navigate(`/account/${location.search ? location.search : ""}`)
    }
  }, [])

  const showPasswordHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    setShowPassword(prev => !prev)
  }

  const Notice = (
    <div
      className={`top-2 m-auto border-[1px] py-2 px-4 text-white text-center rounded-lg ${success ? "bg-paloverde border-paloverde" : "bg-red-500 border-red-500"
        }`}
    >
      {msg}
    </div>
  )
  return (
    <div className={style.profileUserCredWrapper}>
      <Suppression />
      <LoginBanner />
      <div className="relative w-full md:w-2/5 py-0 md:py-6">
        <div className="relative w-full">
          <div className={style.profileCredWrapper}>
            <div className={style.profileCredFormOuterWrapper}>
              <h1 className={style.profileCredFormTitle}>
                New
                <br />
                Account.
              </h1>
              {errMessage.length ? (
                <span className="m-auto border-[1px] py-2 px-4 text-white text-center rounded-lg bg-red-500 border-red-500">
                  {errMessage}
                </span>
              ) : (
                ""
              )}
              <div className={`${style.profileCredFormWrapper} rounded-lg`}>
                <div id="CustomerRegistryForm">
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      email: "",
                      password: "",
                    }}
                    validate={values => {
                      const errors: any = {}
                      if (!values.email) {
                        errors.email = "Required"
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email,
                        )
                      ) {
                        errors.email = "Invalid email address"
                      }

                      if (!values.firstName) {
                        errors.firstName = "Required"
                      }

                      if (!values.lastName) {
                        errors.lastName = "Required"
                      }

                      if (!values.password) {
                        errors.password = "Required"
                      }
                      return errors
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setShowNotice(false)
                      setMsg("")
                      axios
                        .post(functionsEndpoint + "/signup", values, {
                          headers: {
                            "Content-Type": "application/json",
                          },
                          withCredentials: true,
                        })
                        .then(async res => {
                          await axios
                            .post(
                              functionsEndpoint + "/login",
                              {
                                data: {
                                  email: values.email,
                                  password: values.password,
                                },
                              },
                              {
                                headers: { "Content-Type": "application/json" },
                                withCredentials: true,
                              },
                            )
                            .then(async res => {
                              const { data } = res.data
                              if (data?.accessToken) {
                                // @ts-ignore
                                setAuth({
                                  user: values.email,
                                  pwd: values.password,
                                  accessToken: data.accessToken,
                                  loggedIn: true,
                                })
                                localStorage.setItem("loggedIn", "true")
                                localStorage.setItem("email", `${values.email}`)
                                setSubmitting(false)
                                await navigate(
                                  `/account/${location.search ? location.search : ""
                                  }`,
                                )
                              } else {
                                setErrMessage("Failed to login.")
                              }
                            })
                            .catch(err => {
                              if (!err?.response) {
                                // console.log('No Server Response');
                                setErrMessage("No Server Response")
                              } else if (err.response?.status === 400) {
                                // console.error('Missing Username or Password');
                                setErrMessage("Missing Email or Password")
                                localStorage.setItem("loggedIn", "false")
                              } else if (err.response?.status === 401) {
                                // console.error('Unauthorized');
                                setErrMessage("Email or Password is incorrect")
                                localStorage.setItem("loggedIn", "false")
                              } else if (err.reponse?.status === 403) {
                                // console.error('Forbidden');
                                setErrMessage("User not found")
                                localStorage.setItem("loggedIn", "false")
                              }
                              setSubmitting(false)
                            })
                          // navigate(`/login/${location.search ? location.search : ""}`)
                        })
                        .catch(err => {
                          console.error(err.message)
                          setShowNotice(true)
                          setMsg(
                            err?.response?.data?.[0]?.message ||
                            "Could not complete signup. Please try again later!",
                          )
                        })
                      // setTimeout(() => {
                      //   alert(JSON.stringify(values, null, 2));
                      //   setSubmitting(false);
                      // }, 400);
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <label
                          htmlFor="firstName"
                          className={style.profileCredFormLabel}
                        >
                          First Name
                        </label>
                        <br />
                        <Field
                          type="firstName"
                          name="firstName"
                          placeholder="First Name"
                          className="text-xs w-full"
                        />
                        <br />

                        <label
                          htmlFor="lastName"
                          className={style.profileCredFormLabel}
                        >
                          Last Name
                        </label>
                        <br />
                        <Field
                          type="lastName"
                          name="lastName"
                          placeholder="Last Name"
                          className="text-xs w-full"
                        />
                        <br />

                        <label
                          htmlFor="email"
                          className={style.profileCredFormLabel}
                        >
                          Email
                        </label>
                        <br />
                        <Field
                          type="email"
                          name="email"
                          placeholder="Email"
                          className="text-xs w-full"
                        />
                        <ErrorMessage name="email" component="div" />
                        <br />

                        <div className={style.passwordWrapper}>
                          <label
                            htmlFor="password"
                            className={style.profileCredFormLabel}
                          >
                            Password
                          </label>
                          <br />
                          <Field
                            type={showPassword ? "text" : "password"}
                            name="password"
                            className="mb-0 text-xs w-full"
                            placeholder="Password"
                          />
                          <button type="button" onClick={showPasswordHandler}>
                            <StaticImage
                              src="../images/Password-Eye.svg"
                              quality={100}
                              width={20}
                              // @ts-ignore
                              formats={["AUTO", "WEBP", "AVIF"]}
                              transFormOptions={{ fit: "cover" }}
                              className={style.passwordEye}
                              alt="Pasword Eye Icon"
                            />
                            {/* <Eye
                              className={style.passwordEye}
                            /> */}
                          </button>
                        </div>
                        <ErrorMessage name="password" component="div" />
                        <br />

                        <div className={style.credUtilWrapper}>
                          <p>Password must be at least 8 characters.</p>
                        </div>
                        <p className={style.signInBtnWrapper}>
                          <input
                            type="submit"
                            placeholder="CREATE ACCOUNT"
                            className={`${style.btn} w-full rounded`}
                          />
                        </p>
                        {/* <button type="submit" disabled={isSubmitting}>
                          Create Account
                        </button> */}
                        {showNotice ? Notice : null}
                        <div className="font-medium text-[12px] text-center">
                          Already have an account ?&nbsp;
                          <Link
                            className="underline font-medium text-[12px]"
                            to="/login"
                          >Login</Link>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signup
