/* eslint-disable */
/* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label */
import React, {
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { Link } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import loadable from "@loadable/component"
import isEqual from "lodash.isequal"
import { CgChevronRight as ChevronIcon } from "react-icons/cg"
import { StoreContext } from "../context/StoreContext"
import FeaturedProductImagesCarousel from "../components/FeaturedProductImagesCarousel"
import AddToCart from "../components/AddToCart"
import { NewUserContext } from "../context/NewUserContext"
import RatingsAndComments from "../components/ProductRecommendationv2/RatingsAndComments"
import AddToWishlist from "../components/AddToWishlist"
import NumericInput from "../components/NumericInput"
import { formatPrice } from "../utils/formatPrice"
import collectionHandles from "../utils/collectionHandles"
import Seo from "../components/seo"
import "./products.scss"
import YotpoStarRatings from "../components/YotpoStarRatings"
import shipmentNoticeSetter from "../utils/shipmentNoticeHelper"
import Modal from "../components/Modal"
import ElementsSizeChart from "../components/ElementsSizeChart"
import ElementsExchangeNotice from "../components/ElementsExchangeNotice"
import ProductSupport from "../components/ProductSupport"
import { useStaticQuery, graphql } from "gatsby"

import Tab from "../components/Tab"
import "./clothing.scss"
import belowContent from "../data/elementsCollection.json"
import useScrollBlock from "../hooks/useScrollBlock"
import { useLocation } from "@reach/router"

import Carousel from "react-multi-carousel"
import "./style.scss"
import * as style from "./style.module.scss"
import "react-multi-carousel/lib/styles.css"
import AffirmIcon from "../assets/Affirm"

import ElementsCareGuide from "../components/ElementsCareGuide"
import { useStoryblok } from "../utils/storyblok"
import DynamicComponents from "../components/DynamicComponents"
import { render } from "storyblok-rich-text-react-renderer"
import ProductBottomSection from "../components/DefaultGenericProductv2/ProductBottomSection"
import { RiArrowDownSFill } from "react-icons/ri"
import AdventureAssurance from "../assets/AdventureAssuranceV2"
// import { useCurrencyExchange } from "../context/CurrencyExchange"
import { productEndPointUrl } from "../config"

const ModalVideo = loadable(() => import("react-modal-video"))

const ElementsCollection = props => {
  const location = useLocation()
  const completeUrl = location.href
  const { pageContext } = props

  const { productPageLinks, shipmentnoticemessagedata } = pageContext
  const story = pageContext.storyblokEntry[0]?.node
    ? useStoryblok(pageContext.storyblokEntry[0]?.node)
    : { content: null }

  const productPageLinksData = productPageLinks?.data
    ? useStoryblok(productPageLinks?.data?.storyblokEntry)
    : { content: null }
  const [reviewsData, setReviewsData] = useState()
  const myDivRef = useRef(null)

  const [questionsData, setQuestionsData] = useState()
  const [imageEnumeration, setImageEnumeration] = useState({})

  const { product, recommendedProducts } = pageContext
  const carouselRef = React.useRef()

  // const { userCountry } = useCurrencyExchange()
  // const [countryCurrency, setCountryCurrency] = useState("")
  // const [lowestComparePrice, setLowestComparePrice] = useState(null)
  // const [productPrice, setProductPrice] = useState(null)
  // const getproductCurrency = async () => {
  //   try {
  //     const response = await axios.post(productEndPointUrl, {
  //       country: userCountry?.cca2,
  //       productId: product?.shopifyId,
  //     })

  //     if (response?.data?.product) {
  //       const firstProduct =
  //         response.data?.product?.data?.product?.variants?.edges?.filter(
  //           item => item?.node?.price?.amount > 0,
  //         )?.[0]?.node?.price?.amount
  //       if (firstProduct) {
  //         const lowestPrice =
  //           response.data?.product?.data?.product?.variants?.edges?.reduce(
  //             (acc, item) => {
  //               if (acc > item?.node?.price?.amount) {
  //                 return item?.node?.price?.amount
  //               }
  //               return acc
  //             },
  //             firstProduct,
  //           )
  //         if (lowestPrice) {
  //           setProductPrice(lowestPrice)
  //         } else {
  //           setProductPrice(
  //             response.data?.product?.data?.product?.variants?.edges?.[0]?.node
  //               ?.price?.amount,
  //           )
  //         }
  //       } else {
  //         setProductPrice(
  //           response.data?.product?.data?.product?.variants?.edges?.[0]?.node
  //             ?.price?.amount,
  //         )
  //       }
  //       setCountryCurrency(
  //         response.data?.product?.data?.product?.variants?.edges?.[0]?.node
  //           ?.price?.currencyCode,
  //       )
  //       const firstComparePrice =
  //         response.data?.product?.data?.product?.variants?.edges?.filter(
  //           item => item?.node?.compareAtPrice?.amount > 0,
  //         )?.[0]?.node?.compareAtPrice?.amount
  //       if (firstComparePrice) {
  //         const lowestComparePrice =
  //           response.data?.product?.data?.product?.variants?.edges?.reduce(
  //             (acc, item) => {
  //               if (acc > item?.node?.compareAtPrice?.amount) {
  //                 return item?.node?.compareAtPrice?.amount
  //               }
  //               return acc
  //             },
  //             firstComparePrice,
  //           )
  //         if (lowestComparePrice) {
  //           setLowestComparePrice(lowestComparePrice)
  //         } else {
  //           setLowestComparePrice(
  //             response.data?.product?.data?.product?.variants?.edges?.[0]?.node
  //               ?.compareAtPrice?.amount,
  //           )
  //         }
  //       } else {
  //         setLowestComparePrice(
  //           response.data?.product?.data?.product?.variants?.edges?.[0]?.node
  //             ?.compareAtPrice?.amount,
  //         )
  //       }
  //     }
  //   } catch (err) {}
  // }

  // useEffect(() => {
  //   if (userCountry?.cca2) {
  //     getproductCurrency()
  //   }
  // }, [userCountry])

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1025 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 769 },
      items: 2,
    },
  }
  const {
    // metafields,
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,
    shopifyId,
    description,
    descriptionHtml,
    media: images,
    collections,
  } = product
  const { client } = useContext(StoreContext)
  // Configure public API key and script location for Affirm
  const affirmConfig = {
    public_api_key: process.env.GATSBY_AFFIRM_PUBLIC_KEY,
    script: "https://cdn1.affirm.com/js/v2/affirm.js",
  }
  const handleClick = () => {
    myDivRef.current.scrollIntoView({ behavior: "smooth" })
  }

  const getReviews = async () => {
    await axios
      .post("/.netlify/functions/get-reviews", {
        product_id: productId,
      })
      .then(async res => {
        setReviewsData(res?.data?.response)
      })
      .catch(err => {
        console.error(err, "error")
      })
  }

  const getQuestions = async () => {
    await axios
      .post("/.netlify/functions/get-questions", {
        product_id: productId,
      })
      .then(async res => {
        setQuestionsData(res?.data?.response)
      })
      .catch(err => {
        console.error(err, "error")
      })
  }

  // Run Affirm Script
  useEffect(() => {
    ; (function (l, g, m, e, a, f, b) {
      var d,
        c = l[m] || {},
        h = document.createElement(f),
        n = document.getElementsByTagName(f)[0],
        k = function (a, b, c) {
          return function () {
            a[b]._.push([c, arguments])
          }
        }
      c[e] = k(c, e, "set")
      d = c[e]
      c[a] = {}
      c[a]._ = []
      d._ = []
      c[a][b] = k(c, a, b)
      a = 0
      for (
        b =
        "set add save post open empty reset on off trigger ready setProduct".split(
          " ",
        );
        a < b.length;
        a++
      )
        d[b[a]] = k(c, e, b[a])
      a = 0
      for (b = ["get", "token", "url", "items"]; a < b.length; a++)
        d[b[a]] = function () { }
      h.async = !0
      h.src = g[f]
      n.parentNode.insertBefore(h, n)
      delete g[f]
      d(g)
      l[m] = c
    })(window, affirmConfig, "affirm", "checkout", "ui", "script", "ready") // eslint-disable-line
    // Use your live public API Key and https://cdn1.affirm.com/js/v2/affirm.js script to point to Affirm production environment.
  }, [])

  const [newInitialVariant, setNewInitialVariant] = useState()
  const [newAllVariants, setNewAllVariants] = useState()

  const data = useStaticQuery(graphql`
    query {
      allShopifyProduct {
        edges {
          node {
            id
            storefrontId
            shopifyId
            totalInventory
            productType
            variants {
              metafields {
                key
                value
              }
              shopifyId
              storefrontId
              availableForSale
              inventoryQuantity
              selectedOptions {
                name
                value
              }
            }
            handle
            metafields {
              key
              value
            }
            options {
              name
              values
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    const newData = data?.allShopifyProduct?.edges?.filter(item => {
      return location.pathname.includes(item?.node?.handle)
    })
    setNewInitialVariant(newData[0]?.node?.variants[0])
    setNewAllVariants(newData[0]?.node?.variants)
  }, [data])

  let productGuideUrl = "" // eslint-disable-line

  // Extract Product Shopify Id
  const shopifyIdExtractor = id => id?.slice(id.lastIndexOf("/") + 1)
  const productShopifyId = useMemo(() => {
    return shopifyIdExtractor(shopifyId)
  }, [shopifyId])

  const productId = shopifyIdExtractor(shopifyId)
  const { cartOverlay } = useContext(NewUserContext)

  const productDetail = belowContent[productId]

  const [imageIndex, setImageIndex] = useState(0)

  useEffect(() => {
    images.forEach((image, index) => {
      const imageShopifyId = shopifyIdExtractor(image.shopifyId)
      imageEnumeration[imageShopifyId] = index
    })
  }, [images])

  const [variant, setVariant] = useState({ ...initialVariant })
  const [quantity, setQuantity] = useState(1)
  const [shippingHours, setShippingHours] = useState("Now")
  const [variantSKU, setVariantSKU] = useState(variants[0].sku)
  const [affirmVariantPrice, setaffirmVariantPrice] = useState(0)
  const [lineItem, setLineItem] = useState([
    {
      variantId: "",
      quantity: 1,
    },
  ])

  const [showVideo, setShowVideo] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()
  const [showSizeFitGuide, setShowSizeFitGuide] = useState(false)
  const [showExchanges, setShowExchanges] = useState(false)
  const [showCare, setShowCare] = useState(false)
  const hasNdlFilter = location.pathname.includes("nd-filters")
  useEffect(() => {
    if (showVideo) {
      blockScroll()
    } else {
      // stopAllVideos();
      allowScroll()
    }
  }, [showVideo])

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const [available, setAvailable] = useState(initialVariant.availableForSale)

  const affirmPriceSetter = cost => {
    // Increase variant price by the factor of 2 for Affirm
    // Set 10,000th decimal point as SigFig
    const adjustedVariantPriceFloat = (cost * 100).toFixed(4)
    // Update data type to int
    const adjustedVariantPriceInt = Number.parseInt(
      adjustedVariantPriceFloat,
      10,
    )
    setaffirmVariantPrice(adjustedVariantPriceInt)
  }

  const checkAvailablity = useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        const result = fetchedProduct
          ? fetchedProduct.variants.filter(
            variantItem => variantItem.id === productVariant.storefrontId,
          )
          : []

        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.storefrontId, client.product],
  )

  const handleOptionChange = (index, event, name) => {
    const value = event.target.value

    if (value === "") {
      return
    }

    const currentOptions = [...variant?.selectedOptions]
    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const newCurrentOptions = [...variant?.selectedOptions]
    newCurrentOptions[index] = {
      ...newCurrentOptions[index],
      value,
    }

    const selectedVariant = variants.find(
      variant => isEqual(currentOptions, variant.selectedOptions), // eslint-disable-line
    )

    const newSelectedVariant = newAllVariants.find(
      variant => isEqual(currentOptions, variant.selectedOptions), // eslint-disable-line
    )

    // Set carousel image index to selected variant
    const selectedShopifyImageId = shopifyIdExtractor(selectedVariant?.image.id)
    setImageIndex(imageEnumeration[selectedShopifyImageId])
    setVariant(selectedVariant)
    setNewInitialVariant(newSelectedVariant)
    setAvailable(newSelectedVariant?.availableForSale)
    setVariantSKU(selectedVariant?.sku)
  }

  // Update lineitem to be added
  useEffect(() => {
    setLineItem({
      variantId: productVariant.storefrontId,
      quantity: parseInt(quantity, 10),
      customAttributes: [{ key: "gatsby-site", value: "test" }],
    })
  }, [productVariant.storefrontId, quantity])

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price,
  )

  let compareAtPrice = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.compareAtPrice,
  )

  useEffect(() => {
    affirmPriceSetter(variant.price)
  }, [variant])

  const hasVariants = variants.length > 1

  const shipmentMessageStory = shipmentnoticemessagedata?.data?.storyblokEntry
    ? useStoryblok(shipmentnoticemessagedata?.data?.storyblokEntry)
    : { content: null }
  const offsetDays = shipmentMessageStory?.content?.offset_days || 2

  // Check time to notify user for shipping in a day
  useEffect(() => {
    getReviews()
    getQuestions()
    shipmentNoticeSetter(setShippingHours, offsetDays)
    const shipmentTimer = setInterval(() => {
      shipmentNoticeSetter(setShippingHours, offsetDays)
    }, 5000)

    return () => clearTimeout(shipmentTimer)
  }, [])

  /* Breadcrumb collection set setup */
  // Default to the first collection
  let collectionIndex = 0

  if (collections?.length > 1) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < collections?.length; i++) {
      if (collectionHandles.has(collections?.[i]?.handle)) {
        collectionIndex = i
        break
      }
    }
  }

  const [
    BottomSectionImage1,
    BottomSectionImage2,
    sections,
    features,
    overview,
    belowContentComponent,
  ] = useMemo(() => {
    return [
      story?.content?.BottomSectionImage1,
      story?.content?.BottomSectionImage2,
      story?.content?.sections,
      render(story?.content?.features),
      render(story?.content?.overview),
      story.content && story.content["below_content"] ? (
        story.content["below_content"].map(blok => (
          <>
            {blok ? (
              <div {...sbEditable(blok)}>
                <DynamicComponents
                  product={product}
                  blok={blok}
                  key={blok._uid}
                />
              </div>
            ) : null}
          </>
        ))
      ) : (
        <div
          className="text-center"
          dangerouslySetInnerHTML={{ __html: oneWholeMetaField[0]?.value }}
        ></div>
      ),
    ]
  }, [story])
  // Load Yotpo Reviews Script
  useEffect(() => {
    // eslint-disable-next-line
    ; (function e() {
      var e = document.createElement("script")
        ; (e.type = "text/javascript"),
          (e.async = true),
          (e.src =
            "//staticw2.yotpo.com/qNmEg7HN0QiYfqn6n5DHRUGCtTUGNWa1GfSVsHRS/widget.js")
      var t = document.getElementsByTagName("script")[0]
      t.parentNode.insertBefore(e, t)
    })()
  }, [])

  const stickyNavComponent =
    story.content && story.content["sticky_nav"]
      ? story.content["sticky_nav"].map(blok => (
        <>
          {blok ? (
            <div {...sbEditable(blok)} className="relative">
              <DynamicComponents
                blok={blok}
                product={product}
                key={blok._uid}
              />
            </div>
          ) : null}
        </>
      ))
      : null
  const [showDiv, setShowDiv] = useState(false)
  useEffect(() => {
    const handleScroll = () => {
      const isNearBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 500
      if (isNearBottom) {
        setShowDiv(false)
      } else if (window.scrollY > 2000 && !showDiv) {
        // setShowDiv(true)
      } else if (window.scrollY <= 2000 && showDiv) {
        setShowDiv(false)
      }
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [showDiv])
  const categoryName = useMemo(() => {
    if (collections?.length) {
      return collections?.[collectionIndex]?.title
    }
    return ""
  }, [collections])

  const KlaviyoScript = () => {
    useEffect(() => {
      // Load Klaviyo script
      const klaviyoScript = document.createElement("script")
      klaviyoScript.src =
        "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=LFDYCZ"
      klaviyoScript.async = true
      document.head.appendChild(klaviyoScript)
      // Execute inline script
      const inlineScript = document.createElement("script")
      inlineScript.innerHTML = `
    try {
    var item = {
    "ProductName": "${title}",
    "ProductID": "${productShopifyId}",
    "SKU": "${variantSKU}",
    "Categories": "${categoryName}",
    "ImageURL": "${variant.image.gatsbyImageData.images.fallback.src}",
    "URL": "${completeUrl}",
    "Brand": "PolarPro",
    "Price": ${variant.price},
    "CompareAtPrice": ${variant.compareAtPrice}
    };
    _learnq.push(["track", "Viewed Product", item]);
    } catch(error) {
    console.error("Error:", error);
    }
    `
      document.head.appendChild(inlineScript)
    }, [])

    return null
  }

  const { setOpenPopUpModal } = useContext(StoreContext)

  return (
    <>
      <KlaviyoScript />
      {images.length ? (
        <Seo
          title={title}
          description={description}
          image={getSrc(images[0].image.gatsbyImageData)}
        />
      ) : (
        <Seo title={title} description={description} />
      )}

      <div className="w-full min-h-screen flex justify-between items-start">
        <div className="w-full overflow-hidden">
          <div
            className={`w-full ${showVideo ? "overflow-y-hidden" : "overflow-y-auto"
              }`}
          >
            <div className="relative m-auto pl-[20px] pr-[20px] md:pl-30px md:pr-30px py-4 lg:py-7">
              {/** ************************************* */}
              {/** *** Left Section of Product Page **** */}
              {/** ************************************* */}

              {/* Breadcrumb */}
              <div className="flex ml-[15px] text-xs md:text-sm items-center flex-row lg:mr-0">
                <Link
                  to={`/${location.search ? location.search : ""}`}
                  className="hover:underline hover:pointer mr-2 mt-1 text-grey-dark text-xs font-medium"
                >
                  Home
                </Link>
                {collections?.length ? (
                  <ChevronIcon className="mr-2 text-grey-dark" size={12} />
                ) : (
                  ""
                )}
                {collections?.length ? (
                  <Link
                    to={`/collections/${collections?.[collectionIndex]?.handle}/${location.search ? location.search : ""
                      }`}
                    className="hover:underline hover:pointer mr-2 mt-1 text-grey-dark text-xs font-medium"
                  >
                    {collections?.[collectionIndex]?.title}
                  </Link>
                ) : (
                  ""
                )}
                <ChevronIcon className="mr-2 text-grey-dark" size={12} />
                <p className="mr-2 mt-1 mb-0 text-grey-dark text-xs font-medium">
                  {product.title}
                </p>
              </div>
              <div className="">
                {/* Product Image Carousel Starts */}
                <div className="px-4 lg:px-10">
                  <FeaturedProductImagesCarousel
                    images={images}
                    title={title}
                    imageIndex={imageIndex}
                  />
                </div>
                {/* Product Image Carousel Ends */}

                {/** ************************************** */}
                {/** *** Right Section of Product Page **** */}
                {/** ************************************** */}
              </div>
              <div className="mt-10 lg:hidden px-[20px]">
                <div className="">
                  {/* Product Title, description, configuration, options, etc... */}
                  <h1 className="header mb-2 text-2xl">{title}</h1>
                  <p className="mb-0 text-xxs text-grey">
                    SKU:
                    {variantSKU}
                  </p>
                  {/* Yotpo Star Ratings */}
                  <YotpoStarRatings product={product} productId={productId} />
                  <div className="flex items-center">
                    <h2 className="priceValue">
                      <span>{price}</span>
                    </h2>
                    <h2 className="comparePriceValue inline line-through italic ml-[15px]">
                      <span>{compareAtPrice}</span>
                    </h2>
                  </div>
                  {/* compare price goes here */}
                  {/* In stock message */}
                  <p className="text-xs font-bold font-condensed">
                    {available ? (
                      <span className="text-paloverde">IN STOCK!</span>
                    ) : (
                      <span className="text-red">OUT OF STOCK.</span>
                    )}{" "}
                    {available && (
                      <span>
                        Order
                        {shippingHours}
                      </span>
                    )}
                  </p>
                  {/* Affirm */}
                  {/* {variantPrice > 75.00 ? */}
                  {/* Product Title, description, etc... Ends */}
                  {/* Product Option Selector and custom product configuration */}
                  {/* {hasVariants &&
                    options.map(({ id, name, values }, index) => {
                      const optionName = name.toLowerCase() === "size"

                      return (
                        <div className="flex items-center mt-4">
                          <p className="font-bold w-[45px] text-xs m-0">{name}</p>
                          <div
                            className="selectVariant flex flex-wrap gap-2 relative rounded-md ml-2 w-full"
                            key={id}
                          >
                            {values.map(value => {
                              let bgColor = ""
                              if (!optionName) {
                                bgColor = value.toLowerCase().replace(/\s/g, "")
                              }

                              return (
                                <div className="relative">
                                  <button
                                    type="button"
                                    className={`ElementsOptionSelector px-2 mr-2
                  ${optionName
                                        ? `flex justify-center items-center  rounded-md border-2 border-obsidian font-bold ${name}-option`
                                        : `rounded-md bg-${bgColor} ${name}-option`
                                      }`}
                                    data-value={value}
                                    data-type={name}
                                    onClick={event =>
                                      handleOptionChange(index, event)
                                    }
                                  >
                                    {optionName ? value : ""}
                                  </button>
                                  {optionName ? (
                                    ""
                                  ) : (
                                    <span
                                      className={`hidden color-option-name mr-2 color-${bgColor}`}
                                    >
                                      {value}
                                    </span>
                                  )}
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )
                    })} */}
                  {hasVariants &&
                    options.map(({ id, name, values }, index) => {
                      const optionName = name.toLowerCase() === "size"
                      return (
                        <div className="flex items-center mt-4">
                          <p className="font-bold w-[45px] text-xs m-0">
                            {name}
                          </p>

                          <div
                            className="selectVariant relative border-[1px] border-[#1C1C1C] rounded-[53px] m-0 mt-1 w-full pointer"
                            key={id}
                          >
                            <select
                              // value={selected || selectedVariant?.title}
                              aria-label="Variants"
                              className="relative appearance-none bg-transparent border-none text-obsidian pointer text-sm font-light m-0 pl-2 py-1 w-full"
                              onChange={event =>
                                handleOptionChange(index, event, name)
                              }
                            >
                              <option value="">{`${name}`}</option>
                              {values?.map(value => (
                                <option value={value} key={`${name}-${value}`}>
                                  {value}
                                </option>
                              ))}
                            </select>
                            <span className="block bg-no-repeat absolute top-1/2 right-2 -translate-y-1/2">
                              <RiArrowDownSFill size={"28px"} />
                            </span>
                          </div>
                        </div>
                      )
                    })}
                  {/* Ghost color swatches to workaround tailwind color compilation */}
                  <div className="hidden">
                    <span className="bg-namib" />
                    <span className="bg-tundra" />
                    <span className="bg-sagebrush" />
                    <span className="bg-slate" />
                    <span className="bg-bristlecone" />
                    <span className="bg-midnightglacier" />
                    <span className="bg-flare" />
                    <span className="bg-ember" />
                    <span className="bg-ignite" />
                  </div>
                  {/* Product Option Selector ends */}
                  {/* TODO: Add clothing tool tips */}
                  <div className="clothing-pop-up-links-wrapper">
                    <a
                      id="fancybox-clothing-size-and-fit"
                      href="#clothing-size-and-fit-modal"
                      onClick={e => {
                        e.preventDefault()
                        setShowSizeFitGuide(true)
                      }}
                    >
                      <p className="clothing-pop-up-link size-and-fit">
                        Size & Fit Guide
                      </p>
                    </a>
                    <span> | </span>
                    <a
                      id="fancybox-clothing-exchanges"
                      href="#clothing-exchanges-modal"
                      onClick={e => {
                        e.preventDefault()
                        setShowExchanges(true)
                        setOpenPopUpModal(true)
                      }}
                    >
                      <p className="clothing-pop-up-link">Exchanges</p>
                    </a>
                    <span> | </span>
                    <a
                      id="fancybox-clothing-care"
                      href="#clothing-care-modal"
                      onClick={e => {
                        e.preventDefault()
                        setShowCare(true)
                      }}
                    >
                      <p className="clothing-pop-up-link">Care</p>
                    </a>
                  </div>
                  <Modal
                    active={showSizeFitGuide}
                    setActive={newValue => {
                      setShowSizeFitGuide(newValue)
                      setOpenPopUpModal(false)
                    }}
                  >
                    <ElementsSizeChart />
                  </Modal>
                  <Modal
                    active={showExchanges}
                    setActive={newValue => {
                      setShowExchanges(newValue)
                      setOpenPopUpModal(false)
                    }}
                  >
                    <ElementsExchangeNotice />
                  </Modal>
                  <Modal
                    active={showCare}
                    setActive={newValue => {
                      setShowCare(newValue)
                      setOpenPopUpModal(false)
                    }}
                  >
                    <ElementsCareGuide guide={productDetail.toolTips.care} />
                  </Modal>
                  <div>
                    <div className="flex justify-between w-full">
                      <div className="flex mr-6 xl:m-0">
                        <NumericInput
                          aria-label="Quantity"
                          onIncrement={() =>
                            setQuantity(q => Math.min(q + 1, 20))
                          }
                          onDecrement={() =>
                            setQuantity(q => Math.max(1, q - 1))
                          }
                          onChange={event =>
                            setQuantity(event.currentTarget.value)
                          }
                          value={quantity}
                          min="1"
                          max="20"
                        />
                      </div>
                      <div className="flex self-end items-center w-4/5 flex-col justify-between xl:flex-row xl:w-auto">
                        <div className="flex justify-end xl:justify-center xl:mr-6 w-full mb-2 xl:w-auto xl:mb-0">
                          <AddToCart
                            productLists={lineItem}
                            available={available}
                            price={price}
                            productName={product.title}
                            variantName={product.title}
                          />
                        </div>
                        <div className="flex justify-end xl:justify-center w-full xl:w-auto">
                          <AddToWishlist />
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex items-start mt-5">
                      <div className="mr-2.5">
                        <AdventureAssurance />
                      </div>
                      <div className="text-xs font-condensed font-bold text-[#1c1c1c]">
                        <h1 className="mb-0 text-xs text-[#1c1c1c]">
                          AdventureAssurance™ Backed
                        </h1>
                        <p className="mb-0 text-[11.5px] mt-1.5 text-[#1c1c1c] font-normal font-sans">
                          Be satisfied with your gear throughout your adventures
                          or your money back.{" "}
                          <a className="underline italic" href="/">
                            Learn More.
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="metaSection mt-8 hidden">
                    <span className="labelFont">Type</span>
                    <span className="tagList">
                      <Link
                        to={`${product.productTypeSlug}/${location.search ? location.search : ""
                          }`}
                      >
                        {product.productType}
                      </Link>
                    </span>
                    <span className="labelFont">Tags</span>
                    <span className="tagList">
                      {product.tags.map(tag => (
                        <Link
                          to={`/search?t=${tag}/${location.search ? location.search : ""
                            }`}
                        >
                          {tag}
                        </Link>
                      ))}
                    </span>
                  </div>
                  <br />
                </div>
              </div>
              {features && overview ? (
                <div className="mx-auto pt-[13px] md:pt-[20px] relative px-[20px] md:px-0 flex flex-col md:flex-row justify-center gap-[30px] md:gap-[112px] caurosalSpace">
                  <div className="w-full md:w-[40%] lg:w-[35%] feature-overview-divider">
                    <p className="font-sans font-black text-[30px]">Overview</p>
                    <div className="text-base text-[15px] leading-[22px]">
                      {overview}
                    </div>
                  </div>
                  <div className="w-full md:w-[40%] lg:w-[35%]">
                    <p className="font-sans font-black text-[30px]">Features</p>

                    <div className=" list_styling text-base text-[15px] leading-[18px]">
                      {features}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="pt-[13px] md:pt-[80px] px-[20px] sm:px-[60px] lg:px-[110px]">
                  {/* eslint-disable-next-line */}
                  <div
                    className="product-description rte main-desc "
                    dangerouslySetInnerHTML={{ __html: descriptionHtml }}
                  />
                </div>
              )}
              {story?.content?.cases_and_components?.length &&
                story?.content?.user_guide?.length ? (
                <div className="border-y-[#eseses] border-t border-solid border-b flex flex-col h-auto sm:h-[120px] gap-6 sm:flex-row mt-8 justify-center text-center px:4 sm:px-0 bg-gray-100 items-center gap-0 sm:gap-[80px] md:gap-[100px] lg:gap-[150px] text-gray-500">
                  <Link
                    to={story?.content?.cases_and_components[0]?.link?.url}
                    className="border-b sm:border-b-0 hover:border-b w-full sm:w-fit"
                  >
                    <p className="m-0 my-10 sm:my-0 text-md md:text-md lg:text-xl cursor-pointer hover:text-black hover:border-b-[1px] hover:border-black font-black font-sans inline-block xs:block">
                      {story?.content?.cases_and_components[0]?.title}
                    </p>
                  </Link>
                  <Link to={story?.content?.user_guide[0]?.link?.url}>
                    <p className="m-0 my-10 sm:my-0 text-md md:text-md lg:text-xl cursor-pointer hover:text-black hover:border-b-[1px] hover:border-black font-black font-sans">
                      {story?.content?.user_guide[0]?.title}
                    </p>
                  </Link>
                </div>
              ) : (
                ""
              )}

              <div className="below-content video-bg-custom-apex">
                {sections?.length && hasNdlFilter == false ? (
                  <ProductBottomSection
                    sections={sections}
                    BottomSectionImage1={BottomSectionImage1}
                    BottomSectionImage2={BottomSectionImage2}
                  />
                ) : (
                  belowContentComponent
                )}
              </div>
            </div>
          </div>
          {/* Mobile end of scrolll */}
          {showDiv && (
            <div
              style={{ zIndex: cartOverlay ? "999999" : "50" }}
              className="lg:hidden bottom-0 h-[200px] shadow-xl overflow-scroll bg-white px-6 fixed duration-300 ease-in-out transform w-full z-50"
            >
              <h1 className="header mb-2 !text-[17px] py-4  font-extrabold font-condensed">
                {title}
              </h1>
              <h2 className="priceValue">
                <span>{price}</span>
              </h2>

              {hasVariants &&
                options.map(({ id, name, values }, index) => {
                  const optionName = name.toLowerCase() === "size"

                  return (
                    <div className="flex items-center mt-4">
                      <p className="font-bold w-[45px] text-xs m-0">{name}</p>
                      <div
                        className="selectVariant flex flex-wrap gap-2 relative rounded-md ml-2 w-full"
                        key={id}
                      >
                        {values.map(value => {
                          let bgColor = ""
                          if (!optionName) {
                            bgColor = value.toLowerCase().replace(/\s/g, "")
                          }

                          return (
                            <div className="relative">
                              <button
                                type="button"
                                className={`ElementsOptionSelector px-2 mr-2
                  ${optionName
                                    ? `flex justify-center items-center  rounded-md border-2 border-obsidian font-bold ${name}-option`
                                    : `rounded-md bg-${bgColor} ${name}-option`
                                  }`}
                                data-value={value}
                                data-type={name}
                                onClick={event =>
                                  handleOptionChange(index, event)
                                }
                              >
                                {optionName ? value : ""}
                              </button>
                              {optionName ? (
                                ""
                              ) : (
                                <span
                                  className={`hidden color-option-name mr-2 color-${bgColor}`}
                                >
                                  {value}
                                </span>
                              )}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              <div>
                <div className="flex justify-between w-full mt-6">
                  <div className="flex mr-6 xl:m-0">
                    <NumericInput
                      aria-label="Quantity"
                      onIncrement={() => setQuantity(q => Math.min(q + 1, 20))}
                      onDecrement={() => setQuantity(q => Math.max(1, q - 1))}
                      onChange={event => setQuantity(event.currentTarget.value)}
                      value={quantity}
                      min="1"
                      max="20"
                    />
                  </div>
                  <div className="flex self-end items-center w-4/5 flex-col justify-between xl:flex-row xl:w-auto">
                    <div className="flex justify-end xl:justify-center xl:mr-6 w-full mb-2 xl:w-auto xl:mb-0">
                      <AddToCart
                        productLists={lineItem}
                        available={available}
                        price={price}
                        productName={product.title}
                        variantName={product.title}
                      />
                    </div>
                    <div className="flex justify-end xl:justify-center w-full xl:w-auto">
                      <AddToWishlist />
                    </div>
                  </div>
                </div>
                <div className="w-full flex items-start mt-5">
                  <div className="mr-2.5">
                    <AdventureAssurance />
                  </div>
                  <div className="text-xs font-condensed font-bold text-[#1c1c1c]">
                    <h1 className="mb-0 text-xs text-[#1c1c1c]">
                      AdventureAssurance™ Backed
                    </h1>
                    <p className="mb-0 text-[11.5px] mt-1.5 text-[#1c1c1c] font-normal font-sans">
                      Be satisfied with your gear throughout your adventures or
                      your money back.{" "}
                      <a className="underline italic" href="/">
                        Learn More.
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Product Bottom Section */}
          <div className="below-content elements-col mt-6">
            <div className="features-icons">
              <img
                alt={`${title} featured icons desktop`}
                src={productDetail.featuredIcons.desktop}
                className="desktop-only"
              />
              <img
                alt={`${title} featured icons mobile`}
                src={productDetail.featuredIcons.mobile}
                className="mobile-only"
              />
            </div>

            <div className="video-feature-banner">
              <div
                className={`video-container elements-video ${productDetail.handle}`}
              >
                <a
                  href="#data"
                  id="inline"
                  className="cta-round play-button x-y-center"
                  onClick={e => {
                    e.preventDefault()
                    setShowVideo(true)
                  }}
                />
                {/* <!-- Modal --> */}
                <ModalVideo
                  channel="vimeo"
                  videoId={productDetail.videoAsset.id}
                  isOpen={showVideo}
                  onClose={() => setShowVideo(false)}
                />
              </div>
            </div>

            <div className="feature-list">
              <h3 className="section-title">
                {productDetail.productInAction.title}
              </h3>
              <div className="hero-img">
                <img
                  alt={`${title} in action desktop`}
                  src={productDetail.productInAction.desktop}
                  className="desktop-only"
                />
                <img
                  alt={`${title} in action mobile`}
                  src={productDetail.productInAction.mobile}
                  className="mobile-only"
                />
              </div>
              <div className=" !px-0 details">
                <img
                  alt={`${title} details desktop`}
                  src={productDetail.productDetails.desktop}
                  className="desktop-only"
                />
                <img
                  alt={`${title} details mobile`}
                  src={productDetail.productDetails.mobile}
                  className="mobile-only"
                />
              </div>
              <div className={`color-palette ${productDetail.handle}`}>
                <div className="content relative !bottom-[120px] sm:!bottom-[250px] lg:!bottom-[180px] xl:!bottom-[200px]">
                  <div className="relative left-2 lg:top-[20px] xl:top-[50px]">
                    <img
                      alt={`${title} color palette`}
                      src={productDetail.colorPaletteBanner.colorSwatch}
                      className="swatches four-set !w-[12px] xl:!w-[20px]"
                    />
                  </div>

                  <p className="sub-title !text-[12px] sm:!text-[22px] relative  lg:!text-[9px] xl:!text-[12px] !top-4 xl:!top-10">
                    Color Palette
                  </p>

                  <h3 className=" text-[30px] lg:!text-[15px] xl:!text-[20px] relative  lg:bottom-2 xl:bottom-0 top-4">
                    {productDetail.colorPaletteBanner.title}
                  </h3>

                  <p className=" lg:w-[50%] xl:w-[60%] lg:!leading-[15px] !text-[15px] sm:!text-[20px] !leading-[25px] sm:!leading-[30px] top-2 xl:!leading-[20px] lg:!text-[9px] xl:!text-[14px] relative lg:bottom-4">
                    {productDetail.colorPaletteBanner.content}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {productGuideUrl ? (
            <ProductSupport productGuideUrl={productGuideUrl} />
          ) : (
            ""
          )}
          {recommendedProducts.length < 4 ? (
            <div className="relative">
              <Tab
                product={product}
                productId={productId}
                recommendedProducts={recommendedProducts}
              />
            </div>
          ) : (
            <div className="recommendedProdutsContainer w-full md:px-[75px] block relative">
              <div className="absolute leftArrow">
                <button
                  onClick={() => carouselRef.current.previous()}
                  className="w-[42px] h-[42px] bg-[gray] hover:bg-[#C99E1A] rounded-full relative"
                ></button>
              </div>
              <div className="absolute rightArrow">
                <button
                  onClick={() => carouselRef.current.next()}
                  className="w-[42px] h-[42px] bg-[gray] hover:bg-[#C99E1A] rounded-full relative"
                ></button>
              </div>
              <Carousel
                responsive={responsive}
                swipeable
                ref={carouselRef}
                arrows={false}
                draggable
                showDots
                infinite
                renderDotsOutside
                containerClass={`${style.sixConfig} sixConfigArrow reconCustomSlider productRecommendationCustomSlider`}
                dotListClass={`${style.reconCustomDotSlider} ${style.sixConfig} reconCustomDotSlider`}
              >
                {recommendedProducts.map(recommendedProduct => {
                  if (recommendedProduct) {
                    return (
                      <a
                        href={`/products/${recommendedProduct.handle}`}
                        className="pointer-events-none"
                      >
                        <div className="recommendedProductCard w-full">
                          <img
                            src={recommendedProduct.featuredImage.originalSrc}
                            alt={recommendedProduct.title}
                          />
                          <p className="font-bold text-[14px] pointer-events-none">
                            {recommendedProduct.title}
                          </p>
                          <div className="recommendedProductCardData !static">
                            {recommendedProduct.metafields
                              .filter(
                                metafield =>
                                  metafield.key === "quickview-content",
                              )
                              .map(metafield => (
                                <p
                                  className="text-[12px] leading-normal recProductDesc pointer-events-none"
                                  dangerouslySetInnerHTML={{
                                    __html: metafield.value,
                                  }}
                                />
                              ))}
                            <p className="font-bold text-[14px] pointer-events-none">
                              From $
                              {parseFloat(
                                recommendedProduct.priceRangeV2.minVariantPrice
                                  .amount,
                              ).toFixed(2)}
                            </p>
                          </div>
                        </div>
                      </a>
                    )
                  }
                  return null
                })}
              </Carousel>
            </div>
          )}

          {/* Ratings and Commets */}
          <div ref={myDivRef} className="mx-7 mt-10">
            <div className="max-w-screen-xl m-auto mt-2 pt-10 border-t">
              <RatingsAndComments
                product={product}
                productId={productId}
                reviewData={reviewsData}
                product_id={productId}
                questionsData={questionsData}
              />
            </div>
          </div>
        </div>

        <div className="sticky top-0 overflow-auto right-0 hidden lg:block bg-white pt-[40px] p-7 lg:sticky lg:top-0 pb-16 lg:right-0 lg:border lg:border-[#E5E5E5] lg:min-h-[calc(100vh-140px)] overflow-x-hidden lg:max-w-sm lg:min-w-[384px] w-full h-[100vh]">
          <div className="">
            {/* Product Title, description, configuration, options, etc... */}
            <h1 className="header mb-2 text-2xl">{title}</h1>
            <p className="mb-0 text-xxs text-grey">
              SKU:
              {variantSKU}
            </p>
            <div
              onClick={() => handleClick()}
              tabIndex={0}
              onKeyDown={() => handleClick()}
            >
              {/* Yotpo Star Ratings */}
              <YotpoStarRatings product={product} productId={productId} />
            </div>

            <div className="flex items-center">
              <h2 className="priceValue">
                {/* {!!countryCurrency &&
                !!productPrice &&
                productPrice !== "NaN" ? (
                  <span>
                    {countryCurrency === "USD" ? "$" : countryCurrency + " "}
                    {String(price)?.split("$")?.join("") || price}
                  </span>
                ) : (
                  <span>{price}</span>
                )} */}
                <span>{price}</span>
              </h2>
              <h2 className="comparePriceValue inline line-through italic ml-[15px]">
                {/* {!!countryCurrency &&
                !!lowestComparePrice &&
                lowestComparePrice !== "NaN" ? (
                  <span>
                    {countryCurrency === "USD" ? "$" : countryCurrency + " "}
                    {lowestComparePrice}
                  </span>
                ) : (
                  <span>{compareAtPrice}</span>
                )} */}
                <span>{compareAtPrice}</span>
              </h2>
            </div>
            {/* compare price goes here */}
            {/* In stock message */}
            <p className="text-xs font-bold font-condensed">
              {available ? (
                <span className="text-paloverde">IN STOCK!</span>
              ) : (
                <span className="text-red">OUT OF STOCK.</span>
              )}{" "}
              {available && (
                <span>
                  Order
                  {shippingHours}
                </span>
              )}
            </p>
            {/* Affirm */}
            {/* {variantPrice > 75.00 ? */}
            {/* Product Title, description, etc... Ends */}
            {/* Product Option Selector and custom product configuration */}
            {hasVariants &&
              options.map(({ id, name, values }, index) => {
                const optionName = name.toLowerCase() === "size"

                return (
                  <div className="flex items-center mt-4">
                    <p className="font-bold w-[45px] text-xs m-0">{name}</p>

                    <div
                      className="selectVariant relative border-[1px] border-[#1C1C1C] rounded-[53px] m-0 mt-1 w-full pointer"
                      key={id}
                    >
                      <select
                        // value={selected || selectedVariant?.title}
                        aria-label="Variants"
                        className="relative appearance-none bg-transparent border-none text-obsidian pointer text-sm font-light m-0 pl-2 py-1 w-full"
                        onChange={event =>
                          handleOptionChange(index, event, name)
                        }
                      >
                        <option value="">{`${name}`}</option>
                        {values?.map(value => (
                          <option value={value} key={`${name}-${value}`}>
                            {value}
                          </option>
                        ))}
                      </select>
                      <span className="block bg-no-repeat absolute top-1/2 right-2 -translate-y-1/2">
                        <RiArrowDownSFill size={"28px"} />
                      </span>
                    </div>
                  </div>
                )
              })}
            {/* Ghost color swatches to workaround tailwind color compilation */}
            <div className="hidden">
              <span className="bg-namib" />
              <span className="bg-tundra" />
              <span className="bg-sagebrush" />
              <span className="bg-slate" />
              <span className="bg-bristlecone" />
              <span className="bg-midnightglacier" />
              <span className="bg-flare" />
              <span className="bg-ember" />
              <span className="bg-ignite" />
            </div>
            {/* Product Option Selector ends */}
            {/* TODO: Add clothing tool tips */}
            <div className="clothing-pop-up-links-wrapper">
              <a
                id="fancybox-clothing-size-and-fit"
                href="#clothing-size-and-fit-modal"
                onClick={e => {
                  e.preventDefault()
                  setShowSizeFitGuide(true)
                  setOpenPopUpModal(true)
                }}
              >
                <p className="clothing-pop-up-link size-and-fit">
                  Size & Fit Guide
                </p>
              </a>
              {/* <span> | </span>
              <a
                id="fancybox-clothing-exchanges"
                href="#clothing-exchanges-modal"
                onClick={e => {
                  e.preventDefault()
                  setShowExchanges(true)
                }}
              >
                <p className="clothing-pop-up-link">Exchanges</p>
              </a> */}
              <span> | </span>
              <a
                id="fancybox-clothing-care"
                href="#clothing-care-modal"
                onClick={e => {
                  e.preventDefault()
                  setShowCare(true)
                  setOpenPopUpModal(true)
                }}
              >
                <p className="clothing-pop-up-link">Care</p>
              </a>
            </div>
            <Modal
              active={showSizeFitGuide}
              setActive={newValue => {
                setShowSizeFitGuide(newValue)
                setOpenPopUpModal(false)
              }}
            >
              <ElementsSizeChart />
            </Modal>
            <Modal
              active={showExchanges}
              setActive={newValue => {
                setShowExchanges(newValue)
                setOpenPopUpModal(false)
              }}
            >
              <ElementsExchangeNotice />
            </Modal>
            <Modal
              active={showCare}
              setActive={newValue => {
                setShowCare(newValue)
                setOpenPopUpModal(false)
              }}
            >
              <ElementsCareGuide guide={productDetail.toolTips.care} />
            </Modal>
            <div>
              <div className="flex justify-between w-full">
                <div className="flex mr-6 xl:m-0">
                  <NumericInput
                    aria-label="Quantity"
                    onIncrement={() => setQuantity(q => Math.min(q + 1, 20))}
                    onDecrement={() => setQuantity(q => Math.max(1, q - 1))}
                    onChange={event => setQuantity(event.currentTarget.value)}
                    value={quantity}
                    min="1"
                    max="20"
                  />
                </div>
                <div className="flex self-end items-center w-4/5 flex-col justify-between xl:flex-row xl:w-auto">
                  <div className="flex justify-end xl:justify-center xl:mr-6 w-full mb-2 xl:w-auto xl:mb-0">
                    <AddToCart
                      productLists={lineItem}
                      available={available}
                      price={price}
                      productName={product.title}
                      variantName={product.title}
                    />
                  </div>
                  <div className="flex justify-end xl:justify-center w-full xl:w-auto">
                    <AddToWishlist />
                  </div>
                </div>
              </div>
              <div className="w-full flex items-start mt-5">
                <div className="mr-2.5">
                  <AdventureAssurance />
                </div>
                <div className="text-xs font-condensed font-bold text-[#1c1c1c]">
                  <h1 className="mb-0 text-xs text-[#1c1c1c]">
                    AdventureAssurance™ Backed
                  </h1>
                  <p className="mb-0 text-[11.5px] mt-1.5 text-[#1c1c1c] font-normal font-sans">
                    Be satisfied with your gear throughout your adventures or
                    your money back.{" "}
                    <a className="underline italic" href="/">
                      Learn More.
                    </a>
                  </p>
                </div>
              </div>
            </div>
            {/* {(!countryCurrency?.length || countryCurrency === "USD") && ( */}
            {/* <div className="w-full flex items-start mt-3">
              <div className="mr-2.5">
                <AffirmIcon />
              </div>

              <div
                className="affirm-as-low-as text-xs font-condensed font-bold text-[#1c1c1c]"
                data-page-type="product"
                data-amount={affirmVariantPrice}
                data-sku={variantSKU}
              >
                <span
                  className="affirm-as-low-as mb-0 text-[12px] text-[#1c1c1c] affirm-as-low-as mb-1 h-[21px]"
                  data-amount={affirmVariantPrice}
                />
              </div>
            </div> */}
            {/* )} */}
            <div className="metaSection mt-8 hidden">
              <span className="labelFont">Type</span>
              <span className="tagList">
                <Link
                  to={`${product.productTypeSlug}/${location.search ? location.search : ""
                    }`}
                >
                  {product.productType}
                </Link>
              </span>
              <span className="labelFont">Tags</span>
              <span className="tagList">
                {product.tags.map(tag => (
                  <Link
                    to={`/search?t=${tag}/${location.search ? location.search : ""
                      }`}
                  >
                    {tag}
                  </Link>
                ))}
              </span>
            </div>
            <br />
          </div>
        </div>
      </div>
    </>
  )
}

export default ElementsCollection

ElementsCollection.propTypes = {
  pageContext: PropTypes.shape({
    product: PropTypes.shape({
      title: PropTypes.string.isRequired,
      metafields: PropTypes.objectOf(PropTypes.string),
      options: PropTypes.arrayOf(
        PropTypes.objectOf({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          values: PropTypes.arrayOf(PropTypes.string).isRequired,
        }),
      ),
      variants: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          selectedOptions: PropTypes.objectOf(PropTypes.string).isRequired,
          sku: PropTypes.string.isRequired,
        }),
      ),
      shopifyId: PropTypes.string.isRequired,
      storefrontId: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      descriptionHtml: PropTypes.string.isRequired,
      images: PropTypes.objectOf(PropTypes.stiring).isRequired,
      collections: PropTypes.arrayOf(PropTypes.string).isRequired,
      priceRangeV2: PropTypes.shape(PropTypes.objectOf(PropTypes.string))
        .isRequired,
      productTypeSlug: PropTypes.string.isRequired,
      productType: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
  }).isRequired,
}
