/* eslint-disable */
import React, { useEffect, useRef, useState, useMemo } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"

import Carousel from "react-multi-carousel"
import FeaturedProductImagesArrow from "../FeaturedProductImagesArrow"
import FeaturedProductImagesDot from "../FeaturedProductImagesDot"
import "react-multi-carousel/lib/styles.css"
import "./style.scss"
import responsiveData from "./settings.json"

const FeaturedProductImagesCarousel = props => {
  const { images, title, imageIndex } = props

  const CustomDot = ({ onClick, active }) => {
    const dotStyle = {
      width: "25px", // Adjust the width as needed
      height: "6px", // Adjust the height as needed
      borderRadius: "0",
      margin: "30px 4px 0px 4px", // Adjust the margin as needed
      backgroundColor: active ? "#000" : "#ccc", // Adjust the active and inactive dot colors as needed
    }

    return (
      <button className="block lg:hidden" onClick={onClick} style={dotStyle} />
    )
  }

  const mainResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 2500 },
      items: 1,
      slideToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 2500, min: 1600 },
      items: 1,
      slideToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1600, min: 768 },
      items: 1,
      slideToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slideToSlide: 1,
    },
  }

  const subResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 2500 },
      items: images?.length > 5 ? 5 : images?.length || 1,
    },
    desktop: {
      breakpoint: { max: 2500, min: 1600 },
      items: images?.length > 5 ? 5 : images?.length || 1,
    },
    tablet: {
      breakpoint: { max: 1600, min: 640 },
      items: images?.length > 5 ? 5 : images?.length || 1,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: images?.length > 3 ? 3 : images?.length || 1,
    },
  }

  const hasImages = images?.length > 0
  const hasMultipleImages = true || images?.length > 1

  const mainCarousel = useRef(null)
  const subCarousel = useRef(null)

  const moveMainSlide = index => {
    mainCarousel.current.goToSlide(index + 2, true)
  }

  useEffect(() => {
    mainCarousel.current.goToSlide(imageIndex + 2, true)
    subCarousel.current?.goToSlide(imageIndex + 2, true)
  }, [imageIndex])
  const isVideo = useMemo(
    () => !!images?.filter(img => !!img.sources?.length).length,
    [images],
  )
  const [activeSlide, setActiveSlide] = useState(2)
  const currentVideo =
    images &&
    useMemo(
      () => !!images[activeSlide - 2]?.sources?.length,
      [mainCarousel, images, activeSlide],
    )
  return (
    <>
      {hasImages && (
        <div
          className={` relative w-full pb-8 top-0 overflow-hidden`}
          id="featuredProductSlideWrapper"
        >
          <div
            className={`${
              isVideo && !currentVideo ? " carouselUi " : "carouselVideoUi"
            }`}
          >
            <Carousel
              arrows
              // showDots={true}
              renderButtonGroupOutside
              // renderDotsOutside={renderButtonGroupOutside}
              responsive={mainResponsive}
              focusOnSelect
              swipeable
              draggable
              centerMode={false}
              infinite
              ref={mainCarousel}
              afterChange={() => {
                if (mainCarousel.current.state.currentSlide === 1) {
                  setActiveSlide(images.length + 1)
                } else {
                  setActiveSlide(mainCarousel.current.state.currentSlide)
                }
              }}
              customRightArrow={
                <FeaturedProductImagesArrow
                  isVideo={isVideo}
                  currentVideo={currentVideo}
                  right
                  imagesLength={images?.length}
                  subCarousel={subCarousel}
                />
              }
              customLeftArrow={
                <FeaturedProductImagesArrow
                  isVideo={isVideo}
                  currentVideo={currentVideo}
                  right={false}
                  imagesLength={images?.length}
                  subCarousel={subCarousel}
                />
              }
              keyBoardControl
              showDots
              customDot={<CustomDot />}
            >
              {images?.map((img, index) => (
                <div
                  className={`slide active ${
                    img.image?.src?.length ? "" : "video-slide"
                  }`}
                  key={index}
                  data-index={index}
                  onClick={e => {
                    e.preventDefault()

                    moveMainSlide(index)
                  }}
                  onKeyPress={e => {
                    e.preventDefault()

                    moveMainSlide(index)
                  }}
                >
                  {img.image?.src?.length ? (
                    <Zoom>
                      <GatsbyImage
                        objectFit="contain"
                        loading={index === 0 ? "eager" : "lazy"}
                        alt={
                          img.altText
                            ? img.altText
                            : `Product Image of ${title} #${index + 1}`
                        }
                        image={img.image?.gatsbyImageData}
                      />
                    </Zoom>
                  ) : (
                    <div className="video-container h-[640px]">
                      <Zoom>
                        <video controls className="h-[640px]">
                          <source src={img?.sources[0]?.url} type="video/mp4" />
                        </video>
                      </Zoom>
                    </div>
                  )}
                </div>
              ))}
            </Carousel>
          </div>

          <div className="w-[75%] m-auto hidden lg:block">
            <Carousel
              arrows={false}
              responsive={subResponsive}
              focusOnSelect
              swipeable
              draggable
              centerMode={false}
              infinite
              ref={subCarousel}
              keyBoardControl
              renderDotsOutside
            >
              {images.map((img, index) => (
                <button
                  className={`slide active mr-2 mb-3 cursor-pointer`}
                  type="button"
                  // role="button"
                  // tabIndex={index === 0 ? '0' : '-1'}
                  tabIndex={index}
                  data-index={index}
                  style={{ maxWidth: "140px" }}
                  onClick={e => {
                    e.preventDefault()
                    setActiveSlide(index + 2)
                    moveMainSlide(index)
                  }}
                  onKeyPress={e => {
                    e.preventDefault()
                    setActiveSlide(index + 2)
                    moveMainSlide(index)
                  }}
                >
                  {img.image?.src?.length >= 1 ? (
                    <div className="relative max-w-full">
                      <GatsbyImage
                        objectFit="contain"
                        // loading={index === 0 ? 'eager' : 'lazy'}
                        loading="lazy"
                        alt={
                          img.altText
                            ? img.altText
                            : `Product Image of ${title} #${index + 1}`
                        }
                        image={img.image?.gatsbyImageData}
                      />
                    </div>
                  ) : (
                    <video>
                      <source src={img?.sources[0]?.url} type="video/mp4" />
                    </video>
                  )}
                </button>
              ))}
            </Carousel>
          </div>
          {hasMultipleImages && (
            <div
              className="text-center mt-4 hidden text-lg left-1/2 absolute transform-gpu"
              id="instructions"
            >
              <span aria-hidden="true">←</span> scroll for more{" "}
              <span aria-hidden="true">→</span>
            </div>
          )}
        </div>
      )}
      {!hasImages && (
        <span className="flex items-center justify-center text-lg top-[-640px]">
          No Preview image
        </span>
      )}
      <div>
        {/* {renderLegacyDynamicTemplate ? (
            <div className="below-content pt-60px">
              <div className="product-overview">
                <div
                  className="large--one-whole"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: productBottomHtml }}
                />
              </div>
            </div>
          ) : null} */}
      </div>
    </>
  )
}

export default FeaturedProductImagesCarousel

FeaturedProductImagesCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  imageIndex: PropTypes.number,
}

FeaturedProductImagesCarousel.defaultProps = {
  imageIndex: 0,
}
