/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useState } from "react"
import { NewUserContext } from "../../context/NewUserContext.tsx"
import { setCookie } from "../../utils/cookie"

const KlaviyoNewsletter = () => {
  const { isNewUser, setFooterSubmitted, popUpSubmitted, setPopupSubmitted } =
    useContext(NewUserContext)
  const [isFooter, setIsFooter] = useState(false)

  const klaviyoEvent = e => {
    if (e?.detail?.type === "submit" && isNewUser) {
      setCookie("interacted", "yes", null)
      if (isFooter) {
        setFooterSubmitted(true)
      } else {
        setPopupSubmitted(true)
      }
    }
  }
  useEffect(() => {
    window.addEventListener("click", () => setIsFooter(false))
    return () => {
      window.removeEventListener("click", () => setIsFooter(false))
    }
  }, [])

  useEffect(() => {
    window.addEventListener("klaviyoForms", klaviyoEvent)
    return () => {
      window.removeEventListener("klaviyoForms", klaviyoEvent)
    }
  }, [isNewUser, isFooter])

  if (isNewUser && !popUpSubmitted) {
    return (
      <div
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setIsFooter(true)
        }}
      >
        <div className="klaviyo-form-VSTE4w" />
      </div>
    )
  }
  return <div className="klaviyo-form-JiTk4r" />
}

export default KlaviyoNewsletter
