/* eslint-disable */
import React from "react"
import * as tooltipStyles from "./tooltip.module.scss"

export default function Tooltip({ tooltipText, ...props }) {
  return (
    <a className={tooltipStyles.tooltips} href="javascript:void(0);">
      <div className={tooltipStyles.iIcon}>
        <span>{tooltipText}</span>
      </div>
    </a>
  )
}
