/* eslint-disable */
import React, { useState, useEffect } from "react"
// import { Link } from "gatsby";
import loadable from "@loadable/component"
import * as style from "./style.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import { useStoryblok } from "../../utils/storyblok"
import DynamicComponents from "../DynamicComponents"

import useScrollBlock from "../../hooks/useScrollBlock"

const ModalVideo = loadable(() => import("react-modal-video"))

const BelowSlate2Overview = () => {
  const [showVideo, setShowVideo] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()

  const scrollUp = e => {
    e.preventDefault()
    window.scrollTo({
      top: 0,
      behaviour: "smooth",
    })
  }

  useEffect(() => {
    if (showVideo) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [showVideo])

  const pageData = useStaticQuery(graphql`
    query {
      storyblokEntry(full_slug: { eq: "/products/recon-vnd-matte-box" }) {
        id
        name
        content
        slug
        full_slug
        uuid
        created_at
      }
      shopifyProduct(handle: { eq: "recon-vnd-matte-box" }) {
        handle
        title
      }
    }
  `)

  const product = pageData.shopifyProduct

  const story = pageData.storyblokEntry
    ? useStoryblok(pageData.storyblokEntry)
    : { content: null }

  const stickyNavComponent =
    story.content && story.content["sticky_nav"]
      ? story.content["sticky_nav"].map(blok => (
          <>
            {blok ? (
              <div {...sbEditable(blok)} className="sticky top-0 z-50">
                <DynamicComponents
                  blok={blok}
                  product={product}
                  key={blok._uid}
                />
              </div>
            ) : null}
          </>
        ))
      : null

  return (
    <div className={`${style.slate2Overview} overflow-hidden`}>
      <h3
        className={`${style.sectionTitleSlate} display-font-logo font-bold section-title text-obsidian mt-20`}
      >
        <span className="text-obsidian">Explore Slate II</span>
      </h3>
      <div class={`${style.productHero} text-white container-wrapper`}>
        <div class={`${style.introData}`}>
          <h2 className="display-font-logo font-bold">Slate - Edition II</h2>
          <p className="hidden 2xl:hidden 3xl:block">
            Slate II has been completely reformatted and reimagined to keep your
            precious <br />
            content safe in the harshest shooting environments.
          </p>
          <p className="hidden lg:block 2xl:block 3xl:hidden">
            Slate II has been completely reformatted and reimagined to keep your
            precious content safe in the harshest shooting environments.
          </p>
          <p className="block lg:hidden">
            Slate II has been completely reformatted and reimagined to <br />
            keep your precious content safe in the harshest <br />
            shooting environments.
          </p>
        </div>
      </div>

      <div class={style.videoContainer}>
        <div className="block lg:flex lg:flex-row m-auto justify-center w-[45%] lg:w-full absolute left-0 right-0 bottom-[15%] lg:bottom-[5vw] xl:bottom-[5vw] gap-0 md:gap-8">
          <a
            href="#data"
            onClick={e => {
              e.preventDefault()
              setShowVideo(true)
            }}
            className="border-solid border-2 text-white block lg:inline-block px-8 py-2 font-bold text-center font-expanded hover:bg-[#ffffff1a] mt-10 uppercase w-full lg:w-[230px] text-[.6rem] pb-[7px]"
          >
            WATCH VIDEO
          </a>
          <a
            href="#"
            onClick={scrollUp}
            className="border-solid border-2 text-white border-paloverde hover:bg-paloverde text-center block lg:inline-block px-8 py-2 font-bold font-expanded mt-4 lg:mt-10 uppercase w-full lg:w-[230px] text-[.6rem] pb-[7px]"
          >
            BUY NOW
          </a>
        </div>

        {/* modal */}
        <ModalVideo
          channel="youtube"
          videoId="TSxL5AO5GFw"
          isOpen={showVideo}
          onClose={() => setShowVideo(false)}
        />
      </div>

      <div className="fast-versatility max-w-full lg:max-w-[64vw] m-auto">
        <h3 className="display-font-logo font-bold section-title text-obsidian mt-[100px] lg:mt-20">
          <span className="text-obsidian">Formatted For Adventure</span>
        </h3>

        <div className="flex items-center flex-col-reverse lg:flex-row-reverse">
          <div className="py-14 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black font-expanded uppercase text-2xl lg:text-base lg:text-sm xl:text-md 2xl:text-xl px-[2vw] text-center lg:text-left text-obsidian mb-2">
              WEATHERPROOF /<br />
              SHOCKPROOF /<br />
              CRUSHPROOF
            </p>
            <p className="hidden 4xl:block text-center lg:text-md lg:text-left font-condensed px-[2vw] text-obsidian">
              Featuring a weatherproof silicone gasket seal, shockproof <br />
              thermoplastic elastomer storage tray, and crushproof <br />
              aluminum enclosure.
            </p>
            <p className="hidden xl:block 4xl:hidden text-center lg:text-sm lg:text-left font-condensed px-[2vw] text-obsidian">
              Featuring a weatherproof silicone gasket seal, shockproof
              thermoplastic elastomer storage tray, and crushproof aluminum
              enclosure.
            </p>
            <p className="hidden lg:block xl:hidden text-center lg:text-sm lg:text-left font-condensed px-[2vw] text-obsidian">
              Featuring a weatherproof silicone gasket seal, shockproof <br />
              thermoplastic elastomer storage tray, and crushproof <br />
              aluminum enclosure.
            </p>
            <p className="block lg:hidden text-center lg:text-sm lg:text-left font-condensed px-[2vw] text-obsidian">
              Featuring a weatherproof silicone gasket seal, shockproof
              thermoplastic elastomer storage tray, and crushproof aluminum
              enclosure.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/SLTII-Feature-Block-1-Desktop.webp?v=1686612385"
              alt=""
            />
            <img
              class="block lg:hidden w-full m-0"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/SLTII-Feature-Block-1-Mobile.webp?v=1686612630"
              alt=""
            />
          </div>
        </div>

        <div className="flex items-center flex-col-reverse lg:flex-row">
          <div className="py-14 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black font-expanded uppercase text-3xl lg:text-base xl:text-xl 2xl:text-2xl px-[2vw] text-center lg:text-left text-obsidian mb-2">
              SWAPPABLE
              <br />
              INSERTS
            </p>
            <p className="hidden 3xl:block text-center lg:text-md lg:text-left font-condensed px-[2vw] text-obsidian">
              SD, CFExpress A, and CFExpress B storage insert <br />
              trays can be quickly interchanged for <br />
              custom layouts.
            </p>
            <p className="hidden xl:block 3xl:hidden text-center lg:text-sm lg:text-left font-condensed px-[2vw] text-obsidian">
              SD, CFExpress A, and CFExpress B storage insert trays can be
              quickly interchanged for custom layouts.
            </p>
            <p className="block xl:hidden text-center lg:text-sm lg:text-left font-condensed px-[2vw] text-obsidian">
              SD, CFExpress A, and CFExpress B storage insert <br />
              trays can be quickly interchanged <br />
              for custom layouts.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/SLTII-Feature-Block-2-Desktop.webp?v=1686612385"
              alt=""
            />
            <img
              class="block lg:hidden w-full m-0"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/SLTII-Feature-Block-2-Mobile.webp?v=1686612630"
              alt=""
            />
          </div>
        </div>

        <div className="flex items-center flex-col-reverse lg:flex-row-reverse">
          <div className="py-14 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black font-expanded uppercase text-3xl lg:text-base xl:text-xl 2xl:text-2xl px-[2vw] text-center lg:text-left text-obsidian mb-2">
              3 ANODIZED COLORWAYS
            </p>
            <p className="hidden 4xl:block text-center lg:text-md lg:text-left font-condensed px-[2vw] text-obsidian">
              Available in three new adventure-inspired colors <br />
              (forest, mountain, and desert) that look and feel so <br />
              good you won't want to put the case down!
            </p>
            <p className="hidden xl:block 4xl:hidden text-center lg:text-sm lg:text-left font-condensed px-[2vw] text-obsidian">
              Available in three new adventure-inspired colors (forest,
              mountain, and desert) that look and feel so good you won't want to
              put the case down!
            </p>
            <p className="block xl:hidden text-center lg:text-sm lg:text-left font-condensed px-[2vw] text-obsidian">
              Available in three new adventure-inspired colors <br />
              (forest, mountain, and desert) that look and feel so <br />
              good you won't want to put the case down!
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/SLTII-Feature-Block-3-Desktop.webp?v=1686612385"
              alt="3 ANODIZED COLORWAYS"
            />
            <img
              class="block lg:hidden w-full m-0"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/SLTII-Feature-Block-3-Mobile.webp?v=1686612630"
              alt="3 ANODIZED COLORWAYS"
            />
          </div>
        </div>

        <div class="block mb-8">
          <video
            autoPlay={true}
            muted={true}
            loop={true}
            playsInline={true}
            className="hidden lg:block"
          >
            <source src="https://cdn.shopify.com/videos/c/o/v/7ab9ec88805f4d6488d64df0a81ee0bf.mp4" />
          </video>

          <video
            autoPlay={true}
            muted={true}
            loop={true}
            playsInline={true}
            className="block lg:hidden w-full"
          >
            <source src="https://cdn.shopify.com/videos/c/o/v/7997a6f51ec14aa5992e24b5def22393.mp4" />
          </video>
        </div>
      </div>

      <div className="max-w-full m-auto">
        <h3 className="display-font-logo font-bold section-title text-obsidian mt-[100px] lg:mt-20">
          <span className="text-obsidian">Storage Configurations</span>
        </h3>
        <div>
          <img
            className="m-auto hidden lg:block"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/SLTII-Storage-Configurations-Block-Desktop.webp?v=1686941022"
            alt="Storage Configuration"
          />
          <img
            className="m-auto block lg:hidden w-full"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/SLTII-Storage-Configurations-Block-Mobile.webp?v=1686941022"
            alt="Storage Configuration sd cf"
          />
        </div>
      </div>

      <div className="launchCommercial">
        <div className="flex flex-col lg:flex-col">
          <div>
            <img
              className="m-auto hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/SLTII-Large-Image-Block-1-Desktop.webp?v=1686612385"
              alt=""
            />
            <img
              className="m-auto block lg:hidden w-full"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/SLTII-Large-Image-Block-1-Mobile.webp?v=1686612630"
              alt=""
            />
          </div>
          <div>
            <img
              className="m-auto hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/SLTII-Large-Image-Block-2-Desktop.webp?v=1686612385"
              alt=""
            />
            <img
              className="m-auto block lg:hidden w-full"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/SLTII-Large-Image-Block-2-Mobile.webp?v=1686612630"
              alt=""
            />
          </div>
          <div>
            <img
              className="m-auto hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/SLTII-Large-Image-Block-3-Desktop.webp?v=1686612386"
              alt=""
            />
            <img
              className="m-auto block lg:hidden w-full"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/SLTII-Large-Image-Block-3-Mobile.webp?v=1686612630"
              alt=""
            />
          </div>
        </div>
        <a
          href="#"
          onClick={scrollUp}
          className="bg-paloverde hover:bg-[#e2b220] min-w-200px w-[368px] m-auto block text-center mt-16 px-30 pb-[10px] pt-3 font-expanded uppercase text-white font-bold text-xs"
        >
          Buy Now
        </a>
      </div>
    </div>
  )
}

export default BelowSlate2Overview
