import React from "react"
import "./style.scss"
import RecommndedProduct from "./RecommndedProduct"

const ProductRecommendationv2 = props => {
  const { recommendedProducts } = props

  let recommendedProductsShow

  if (recommendedProducts) {
    recommendedProductsShow = recommendedProducts.length >= 1
  } else {
    recommendedProductsShow = null
  }

  return !recommendedProductsShow ? (
    <div className="grid grid-cols-1 gap-20 text-center mt-[30px] mb-[-55px] text-obsidian text-xs">
      Sorry no recommendations
    </div>
  ) : (
    <div className="flex flex-col lg:flex-row lg:gap-5 w-full lg:justify-center">
      {recommendedProducts.map(recommendedProduct => {
        if (!recommendedProduct) {
          return null
        }
        return (
          <RecommndedProduct
            key={recommendedProduct.id}
            recommendedProduct={recommendedProduct}
          />
        )
      })}
    </div>
  )
}

export default ProductRecommendationv2
