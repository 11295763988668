/* eslint-disable */
import React from "react"
import { Link } from "gatsby"
import { render } from "storyblok-rich-text-react-renderer"
import Spec from "../Spec"

const SpecItem = ({ blok, propClass }) => {
  const { Image, Title, Specs, image, title, specs } = blok
  return (
    <div className={"item" + " " + propClass || ""}>
      <img className="specImg" src={Image || image} />
      <div className="spec-container">
        <p className="display-font-logo font-bold text-lg titleField">
          {render(Title || title)}
        </p>
        {(Specs || specs)?.map((spec, index) => (
          <Spec key={index} spec={spec} />
        ))}
      </div>
    </div>
  )
}

export default SpecItem
