import React from "react"

const ShippingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="20"
      viewBox="0 0 25 20"
    >
      <path
        id="shipping-icon"
        d="M24.375,13.75H23.75V9.527A1.875,1.875,0,0,0,23.2,8.2L19.3,4.3a1.875,1.875,0,0,0-1.324-.551H16.25V1.875A1.875,1.875,0,0,0,14.375,0h-10A1.875,1.875,0,0,0,2.5,1.875V3.75H.313A.313.313,0,0,0,0,4.063v.625A.313.313,0,0,0,.313,5H10.938a.313.313,0,0,1,.313.313v.625a.313.313,0,0,1-.312.313H1.563a.313.313,0,0,0-.313.313v.625a.313.313,0,0,0,.313.313H9.688A.313.313,0,0,1,10,7.813v.625a.313.313,0,0,1-.312.313H.313A.313.313,0,0,0,0,9.063v.625A.313.313,0,0,0,.313,10H8.438a.313.313,0,0,1,.313.313v.625a.313.313,0,0,1-.312.313H2.5v5a3.75,3.75,0,1,0,7.5,0h5a3.75,3.75,0,0,0,7.5,0h1.875A.627.627,0,0,0,25,15.625v-1.25A.627.627,0,0,0,24.375,13.75ZM6.25,18.125A1.875,1.875,0,1,1,8.125,16.25,1.875,1.875,0,0,1,6.25,18.125Zm12.5,0a1.875,1.875,0,1,1,1.875-1.875A1.875,1.875,0,0,1,18.75,18.125ZM21.875,10H16.25V5.625h1.723l3.9,3.9Z"
        fill="#1c1c1c"
      />
    </svg>
  )
}

export default ShippingIcon
