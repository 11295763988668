import React, { useEffect, useState } from "react"
// import { useCurrencyExchange } from "../../context/CurrencyExchange"
import axios from "axios"
import { productEndPointUrl } from "../../config"

function ProductRecomended({ recommendedProduct }: any) {
  // const { userCountry } = useCurrencyExchange()
  // const [currencyExchangedPrice, setCurrencyExchangedPrice] = useState(null)
  // const [countryCurrency, setCountryCurrency] = useState("")

  // const getproductCurrency = async () => {
  //   try {
  //     const response = await axios.post(
  //       productEndPointUrl,

  //       {
  //         country: userCountry?.cca2,
  //         productId: recommendedProduct?.shopifyId,
  //       },
  //     )

  //     if (response?.data?.product) {
  //       setCountryCurrency(
  //         response.data?.product?.data?.product?.variants?.edges?.[0]?.node
  //           ?.price?.currencyCode,
  //       )
  //       setCurrencyExchangedPrice(
  //         response.data?.product?.data?.product?.variants?.edges?.[0]?.node
  //           ?.price?.amount,
  //       )
  //     }
  //   } catch (err: any) {
  //     return {
  //       statusCode: 500,
  //       body: JSON.stringify({
  //         failed: true,
  //         message: err.message,
  //       }),
  //     }
  //   }
  // }

  // useEffect(() => {
  //   if (userCountry?.cca2) {
  //     getproductCurrency()
  //   }
  // }, [userCountry])

  return (
    <div>
      <p className="text-[14px]">
        From{" "}
        {/* {currencyExchangedPrice ? (
          <>
            {countryCurrency === "USD" ? "$" : countryCurrency + " "}
            {currencyExchangedPrice}
          </>
        ) : (
          <>{`$${recommendedProduct?.priceRangeV2?.minVariantPrice?.amount}`}</>
        )} */}
        {`$${recommendedProduct?.priceRangeV2?.minVariantPrice?.amount}`}
      </p>
    </div>
  )
}

export default ProductRecomended
