/* eslint-disable */
/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, lang, meta, title, ogImage }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          ogImage
        }
      }
      # markdownRemark (frontmatter: {title: {eq: "Site-Wide-License-Notice"}}) {
      #   html
      # }
    }
  `)

  const metaDescription = description || site.siteMetadata.description
  const metaImage = ogImage || site.siteMetadata.ogImage
  const defaultTitle = site.siteMetadata ? site.siteMetadata.title : ""

  // console.log(markdownRemark.html);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: "description",
          content: metaDescription,
        },
        {
          property: "og:title",
          content: title,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:creator",
          content: site.siteMetadata ? site.siteMetadata.author : "",
        },
        {
          name: "twitter:title",
          content: title,
        },
        {
          name: "twitter:description",
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script
        async
        type="text/javascript"
        src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=LFDYCZ"
      />
      {/* widgetic before and after script */}
      <script
        async={false}
        type="text/javascript"
        src="https://widgetic.com/sdk/sdk.js"
      />
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: "en",
  meta: [],
  description: "",
  ogImage: "",
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
