import React, { useState, useEffect } from "react"
// import { useCurrencyExchange } from "../../context/CurrencyExchange"
import axios from "axios"
import { productEndPointUrl } from "../../config"

function ProductCardSearchCard(product: any) {
  // const { userCountry } = useCurrencyExchange()
  // const [countryCurrency, setCountryCurrency] = useState("")
  // const [lowestComparePrice, setLowestComparePrice] = useState(null)
  // const [productPrice, setProductPrice] = useState("")

  // const getproductCurrency = async () => {
  //   try {
  //     const response = await axios.post(productEndPointUrl, {
  //       country: userCountry?.cca2,
  //       productId: product?.product?.shopifyId,
  //     })
  //     if (response?.data?.product) {
  //       const firstProductWithValidPrice =
  //         response.data?.product?.data?.product?.variants?.edges?.filter(
  //           (variant: any) => {
  //             return variant.node.price.amount
  //           },
  //         )?.[0]?.node?.price?.amount
  //       if (firstProductWithValidPrice) {
  //         const tempLowestPrice =
  //           response.data?.product?.data?.product?.variants?.edges?.reduce(
  //             (acc: any, variant: any) => {
  //               if (parseFloat(variant.node.price?.amount || acc + 1) < acc) {
  //                 return variant.node.price?.amount
  //               }
  //               return acc
  //             },
  //             parseFloat(firstProductWithValidPrice),
  //           )
  //         if (tempLowestPrice) {
  //           setProductPrice(tempLowestPrice)
  //         } else {
  //           setProductPrice(
  //             response.data?.product?.data?.product?.variants?.edges?.[0]?.node
  //               ?.price?.amount,
  //           )
  //         }
  //       } else {
  //         setProductPrice(
  //           response.data?.product?.data?.product?.variants?.edges?.[0]?.node
  //             ?.price?.amount,
  //         )
  //       }
  //       setCountryCurrency(
  //         response.data?.product?.data?.product?.variants?.edges?.[0]?.node
  //           ?.price?.currencyCode,
  //       )
  //       const firstComparePrice =
  //         response.data?.product?.data?.product?.variants?.edges?.filter(
  //           (variant: any) => {
  //             return variant?.node?.compareAtPrice?.amount
  //           },
  //         )?.[0]?.node?.compareAtPrice?.amount
  //       if (firstComparePrice) {
  //         const tempLowestComparePrice =
  //           response.data?.product?.data?.product?.variants?.edges?.reduce(
  //             (acc: any, variant: any) => {
  //               if (
  //                 parseFloat(variant.node.compareAtPrice?.amount || acc + 1) <
  //                 acc
  //               ) {
  //                 return variant.node.compareAtPrice?.amount
  //               }
  //               return acc
  //             },
  //             parseFloat(firstComparePrice),
  //           )
  //         setLowestComparePrice(tempLowestComparePrice)
  //       } else {
  //         setLowestComparePrice(
  //           response.data?.product?.data?.product?.variants?.edges?.[0]?.node
  //             ?.compareAtPrice?.amount,
  //         )
  //       }
  //     }
  //   } catch (err: any) {}
  // }

  // useEffect(() => {
  //   if (userCountry?.cca2) {
  //     getproductCurrency()
  //   }
  // }, [userCountry])

  let lowestCompareAmount = ""

  if (product.product.variants) {
    let lowestPricedVariant = product.product.variants[0]

    product.product.variants.forEach((variant: any) => {
      if (!lowestPricedVariant.compareAtPrice) {
        lowestPricedVariant = variant
      } else if (variant.compareAtPrice) {
        if (parseFloat(variant.price) < parseFloat(lowestPricedVariant.price)) {
          lowestPricedVariant = variant
        }
      }
    })

    if (lowestPricedVariant.compareAtPrice)
      lowestCompareAmount = `$${lowestPricedVariant.compareAtPrice}`
  }

  return (
    <div>
      {/* {!!countryCurrency ? (
        <p className="text-[.8rem] text-[#1C1C1C] font-bold font-condensed mb-0 pr-1">
          {!!productPrice ? (
            <>
              {countryCurrency === "USD" ? "$" : countryCurrency + " "}
              {productPrice}
            </>
          ) : (
            <>
              $&nbsp;
              {product?.product.priceRangeV2?.minVariantPrice?.amount}
            </>
          )}
          {!!lowestComparePrice ? (
            <span
              className={`${
                lowestComparePrice ? "line-through" : ""
              } pl-2 italic text-[#ababab]`}
            >
              {countryCurrency === "USD" ? "$" : countryCurrency + " "}
              {lowestComparePrice}
            </span>
          ) : product?.product?.variants?.[0]?.compareAtPrice ? (
            <span className="line-through pl-2 italic text-[#ababab]">
              {lowestCompareAmount}
            </span>
          ) : null}
        </p>
      ) : ( */}
      <p className="text-base text-[#1C1C1C] font-bold font-condensed mb-0 pr-1">
        {`$${product?.product.priceRangeV2?.minVariantPrice?.amount}`}
        {product?.product?.variants?.[0]?.compareAtPrice ? (
          <span className="line-through pl-2 italic text-[#ababab]">
            {lowestCompareAmount}
          </span>
        ) : null}
      </p>
      {/* )} */}
    </div>
  )
}

export default React.memo(ProductCardSearchCard)
