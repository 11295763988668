/*
 * This script is a JS implementation of JAVA's String.hashCode() method
 * It is NOT SECURE, and thus, only use it for creating unique hash for
 * something that does not need to be secured like product key value
 * mapping.
 *
 * From Stackoverflow:
 *     https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript
 */
/* eslint-disable */
export const hashCode = str => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash &= hash // Convert to 32bit integer
  }
  return hash
}

export default hashCode
