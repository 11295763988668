/* eslint-disable */

import { useContext, useEffect, useState } from "react"
import { StoreContext } from "../context/StoreContext"
import useAxiosPrivate from "./useAxiosPrivate"

function useCheckout() {
  const isBrowser = typeof window !== "undefined"
  const axios = useAxiosPrivate()
  const { checkout, loading } = useContext(StoreContext)
  const emptyCart = checkout.lineItems.length === 0
  const [authNotice, setAuthNotice] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  useEffect(() => {
    if (isBrowser && localStorage.getItem("loggedIn") === "true") {
      setAuthNotice(true)
    }
  })

  const handleCheckout = async urlParams => {
    if (checkout?.webUrl) {
      const checkoutUrl = checkout?.webUrl?.split?.("/")
      if (checkoutUrl?.length) {
        const token = checkoutUrl?.[checkoutUrl.length - 1]?.split?.("?")
        if (token) {
          const checkout_token = token[0]
          if (SendTrackingToRefersion) {
            await SendTrackingToRefersion(checkout_token)
          }
        }
      }
    }
    let urlToOpen = ""
    if (isBrowser && localStorage.getItem("loggedIn") === "true") {
      setLoadingSubmit(true)
      await axios
        .post("/.netlify/functions/multipass", {
          data: { return_to: checkout.webUrl },
        })
        .then(res => {
          setLoadingSubmit(false)
          urlToOpen = `${res.data.url}/${urlParams}`
        })
        .catch(err => {
          setLoadingSubmit(false)
          urlToOpen = `${checkout.webUrl}/${urlParams}`
        })
    } else {
      urlToOpen = `${checkout.webUrl}/${urlParams}`
    }
    return urlToOpen
  }

  return {
    handleCheckout,
    loadingSubmit,
    loading,
    authNotice,
    emptyCart,
    checkout,
  }
}

export default useCheckout
