// @ts-nocheck
import { getShopifyImage } from "gatsby-source-shopify"
import React, { useEffect, useMemo, useState } from "react"
import { debounce } from "lodash"
import { StoreContext } from "../../context/StoreContext"
// import { useCurrencyExchange } from "../../context/CurrencyExchange"
import { GatsbyImage } from "gatsby-plugin-image"
import DeleteIcon from "../Icons/DeleteIcon"
import HorizontalNumericInput from "../NumericInput/horizontal"
import { FaShoppingBasket } from "react-icons/fa"
import axios from "axios"
import { productVariantEndPointUrl } from "../../config"
import useCheckout from "../../hooks/useCheckout"
import { navigate } from "gatsby"

type Props = {
  item: any
  onClose: any
  // onUpdateCart: (price: number) => void
  // Currency: (currency: string) => void
}

const CartDrawerProductCard = ({ item, onClose }: Props) => {
  const [inventoryQuantity, setInventoryQuantity] = useState(0)
  const [preOrderDate, setPreorderDate] = useState(null)
  // const { userCountry } = useCurrencyExchange()
  // const [currencyExchangedPrice, setCurrencyExchangedPrice] = useState(null)
  // const [countryCurrency, setCountryCurrency] = useState("")
  const [totalPrice, setTotalPrice] = useState(0)
  const [basePlateUrl, setBasePlateUrl] = useState(null)
  const [basePlate, setBasePlate] = useState(null)

  // const getproductCurrency = async () => {
  //   try {
  //     const response = await axios.post(productVariantEndPointUrl, {
  //       country: userCountry?.cca2,
  //       productId: item?.title,
  //     })

  //     if (response?.data?.product) {
  //       const selected = response.data?.product?.data?.products?.edges?.filter(
  //         it => it?.node.title === item?.title,
  //       )
  //       const selectedVariant = selected[0].node.variants.edges.filter(
  //         it => it.node.id === item.variant.id,
  //       )
  //       if (
  //         selected.length > 0 &&
  //         selectedVariant?.[0]?.node?.title === "Default Title"
  //       ) {
  //         setCountryCurrency(
  //           selected[0]?.node?.variants?.edges?.[0]?.node?.price?.currencyCode,
  //         )
  //         setCurrencyExchangedPrice(
  //           selected[0]?.node?.variants?.edges?.[0]?.node?.price?.amount,
  //         )
  //       } else if (selected && selectedVariant?.length > 0) {
  //         setCountryCurrency(selectedVariant?.[0]?.node?.price?.currencyCode)
  //         setCurrencyExchangedPrice(
  //           parseFloat(selectedVariant?.[0]?.node?.price?.amount),
  //         )
  //       }
  //     }
  //   } catch (err: any) {
  //     return {
  //       statusCode: 500,
  //       body: JSON.stringify({
  //         failed: true,
  //         message: err.message,
  //       }),
  //     }
  //   }
  // }

  // useEffect(() => {
  //   if (userCountry?.cca2) {
  //     getproductCurrency()
  //   }
  // }, [userCountry])

  useEffect(() => {
    let preOrderInfo
    if (typeof window !== undefined) {
      if (inventoryQuantity <= 0 && item.variant.available === true) {
        const updatedPreOrderInfo = localStorage.getItem("preOrderInfo")
        preOrderInfo = JSON.parse(updatedPreOrderInfo)
        const newValue =
          preOrderInfo &&
          preOrderInfo?.filter(value => value?.variantId === item?.variant?.id)
        if (newValue?.length > 0) {
          setPreorderDate(newValue[0]?.preorderDate)
        }
      }
    }
    axios
      .post(
        "/.netlify/functions/get-product-by-title",
        JSON.stringify({ title: item.title, sku: item.variant.sku }),
      )
      .then(res => {
        setInventoryQuantity(res.data.product[0]?.inventory_quantity)
      })
      .catch(err => {
        console.log("error: ", err)
      })
  }, [item])
  const { removeLineItem, checkout, updateLineItem, loading } =
    React.useContext(StoreContext)

  const { checkoutPopup } = useCheckout()
  const [shouldAnimate, setShouldAnimate] = useState(false)
  const [quantity, setQuantity] = React.useState(item.quantity)

  // const getTotalPrice = async () => {
  //   const productList = checkout?.lineItems?.map(async productItem => {
  //     try {
  //       const response = await axios.post(productVariantEndPointUrl, {
  //         country: userCountry?.cca2,
  //         productId: productItem?.title,
  //       })
  //       const selected = response.data?.product?.data?.products?.edges?.filter(
  //         it => it?.node.title === productItem?.title,
  //       )
  //       const variantTitle = selected?.[0]?.node?.variants
  //       const filterVariantTitle = variantTitle.edges.filter(
  //         item => item?.node?.title === productItem?.variant.title,
  //       )

  //       if (
  //         selected.length > 0 &&
  //         filterVariantTitle?.[0]?.node?.title === "Default Title"
  //       ) {
  //         return {
  //           productTitle: productItem?.title,
  //           quantity: productItem?.quantity,
  //           price: selected[0]?.node?.variants?.edges?.[0]?.node?.price?.amount,
  //           currency:
  //             selected[0]?.node?.variants?.edges?.[0]?.node?.price
  //               ?.currencyCode,
  //         }
  //       } else if (selected && filterVariantTitle.length > 0) {
  //         return {
  //           price: filterVariantTitle?.[0]?.node?.price?.amount,
  //           currency: filterVariantTitle?.[0]?.node?.price?.currencyCode,
  //           quantity: productItem?.quantity,
  //         }
  //       } else {
  //         return {
  //           productTitle: productItem?.title,
  //           quantity: productItem?.quantity,
  //           price:
  //             response.data?.product?.data?.products?.edges?.[0]?.node?.variants
  //               ?.edges?.[0]?.node?.price?.amount,
  //           currency:
  //             response.data?.product?.data?.products?.edges?.[0]?.node?.variants
  //               ?.edges?.[0]?.node?.price?.currencyCode,
  //         }
  //       }
  //     } catch (error) {
  //       return {
  //         statusCode: 500,
  //         body: JSON.stringify({
  //           failed: true,
  //           message: error.message,
  //         }),
  //       }
  //     }
  //   })

  //   try {
  //     const allProductList = await Promise.all(productList)
  //     const cartSum = allProductList?.reduce((acc, curr) => {
  //       return acc + curr.price * curr.quantity
  //     }, 0)
  //     setTotalPrice(parseFloat(cartSum))
  //   } catch (err) {
  //     return {
  //       statusCode: 500,
  //       body: JSON.stringify({
  //         failed: true,
  //         message: err.message,
  //       }),
  //     }
  //   }
  // }

  // useEffect(() => {
  //   getTotalPrice()
  // }, [checkout])

  // useEffect(() => {
  //   onUpdateCart(parseFloat(totalPrice))
  //   Currency(countryCurrency)
  // }, [currencyExchangedPrice, totalPrice, quantity])

  let discountTotalAmount = 0
  const {
    price,
    discountedPrice,
    variantImage,
    discount,
    subTotal,
    discountAmount,
    discTitle,
    priceAmount,
  } = useMemo(() => {
    let discounts = item.discountAllocations
    let discTitle = ""
    if (discounts.length > 0) {
      let discountObj = discounts[0]
      let discountAmount = Number(discountObj.allocatedAmount.amount)
      discountTotalAmount += discountAmount
      discTitle = discountObj.discountApplication.title
    }
    return {
      price:
        parseFloat(
          // currencyExchangedPrice
          //   ? currencyExchangedPrice
          //   :
          Number(item.variant.priceV2.amount),
        ) * quantity,
      discountedPrice:
        parseFloat(
          // currencyExchangedPrice
          //   ? currencyExchangedPrice
          //   :
          Number(item.variant.priceV2.amount),
        ) -
        discountTotalAmount / quantity,
      variantImage: {
        ...item.variant.image,
        originalSrc: item.variant.image.src,
      },
      discount: !!discounts.length,
      subTotal: parseFloat(
        (
          parseFloat(
            // currencyExchangedPrice
            //   ? currencyExchangedPrice
            //   :
            Number(item.variant.priceV2.amount),
          ) *
          quantity -
          discountTotalAmount
        ).toFixed(2),
      ),
      discountAmount: discountTotalAmount,
      discTitle,
      priceAmount: parseFloat(
        // currencyExchangedPrice
        //   ? currencyExchangedPrice
        //   :
        Number(item.variant.priceV2.amount),
      ),
    }
    // }, [item, quantity, currencyExchangedPrice])
  }, [item, quantity])
  const priceString =
    parseFloat(
      // currencyExchangedPrice
      //   ? currencyExchangedPrice
      //   :
      Number(item.variant.priceV2.amount),
    ) *
    quantity -
    discountTotalAmount / quantity
  const result = priceString - discountAmount
  const formattedResult = result.toFixed(2)

  const handleRemove = () => {
    let preorderInfo
    if (typeof window !== undefined) {
      preorderInfo = localStorage.getItem("preOrderInfo")
    }
    const filtering = JSON.parse(preorderInfo)?.filter(
      items => items?.variantId !== item?.variant?.id,
    )
    const updatedFiltering = JSON.stringify(filtering)

    if (typeof window !== undefined && updatedFiltering?.length > 0) {
      localStorage.setItem("preOrderInfo", updatedFiltering)
    }
    if (typeof window !== undefined && updatedFiltering?.length < 1) {
      localStorage.removeItem("preOrderInfo")
    }

    setShouldAnimate(true)
    setTimeout(() => {
      removeLineItem(checkout.id, item.id)
      setShouldAnimate(false)
    }, 2700)
  }

  const uli = debounce(
    value => updateLineItem(checkout.id, item.id, value),
    300,
  )

  useEffect(() => {
    setQuantity(item.quantity)
  }, [item])

  const debouncedUli = React.useCallback(value => uli(value), [])

  const handleQuantityChange = value => {
    if (value !== "" && Number(value) < 1) {
      return
    }
    setQuantity(value)
    if (Number(value) >= 1) {
      debouncedUli(value)
    }
  }

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1)
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1)
  }
  const image = React.useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: "constrained",
        crop: "cover",
        width: 100,
        height: 100,
      }),
    [variantImage.src],
  )

  const productsTitle = checkout?.lineItems?.map(item => {
    return item?.title?.toLowerCase()?.includes("base plates")
  })

  const variantTitle = checkout?.lineItems?.map(item => {
    return item?.variant?.title?.toLowerCase()?.includes("base plate")
  })

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const basePlatesProducts = !!checkout.lineItems.filter(cartItem => {
          return cartItem?.title?.toLowerCase()?.includes("baseplate")
        })?.length

        if (!basePlatesProducts) {
          const response = await axios.post(
            "/.netlify/functions/get-product-by-title",
            JSON.stringify({
              title: item.title,
              sku: item.variant.sku,
              variantId: item.variant.id,
            }),
          )

          const variant =
            response?.data?.filteredVariant?.[0]?.node?.variants?.edges
          const filteredVariant = variant?.map(item => item?.node)
          const flattenedVariant = filteredVariant?.flat()
          const result = flattenedVariant?.filter(item => {
            return (
              typeof item?.value === "string" &&
              item?.value?.toLowerCase()?.includes("base plate")
            )
          })

          if (result?.length) {
            setBasePlate(true)
          } else {
            setBasePlate(null)
          }

          const basepaltes =
            response?.data?.temptest?.data?.products?.edges?.[0]?.node?.variants?.edges?.filter(
              item => {
                return item?.node?.metafields
              },
            )

          const finalResult = basepaltes.filter(_item => {
            return (
              _item?.node?.metafields?.[1]?.key === "baseplates_url" &&
              _item?.node?.id === item.variant?.id &&
              !productsTitle?.includes(true) &&
              !variantTitle?.includes(true)
            )
          })

          setBasePlateUrl(finalResult[0]?.node?.metafields[1]?.value)
          setBasePlate(true)
        }
      } catch (error) {
        console.log("error:", error)
      }
    }

    fetchProductData()
  }, [checkout])

  return (
    <div className="relative ">
      <div
        className={`w-full ${shouldAnimate ? "slide-in-out" : "right-[100%]"
          } h-full absolute bg-paloverde z-50`}
      >
        <div className="flex flex-col justify-center items-center h-full w-full">
          <FaShoppingBasket className="w-6 h-6 text-obsidian" />
          <p className="text-lg font-bold mb-0">ITEM REMOVED</p>
        </div>
      </div>
      <div className="relative first:border-none pt-2 px-5">
        <div className="flex items-center mt-6">
          <div className="w-[100px] mr-2 relative">
            {image && (
              <GatsbyImage
                key={variantImage.src}
                image={image}
                alt={
                  variantImage.altText !== undefined &&
                    variantImage.altText !== null
                    ? variantImage.altText
                    : item.variant.title
                }
              />
            )}
          </div>
          <div className="w-[80%] relative top-4">
            {preOrderDate ? (
              <p className="mb-0 text-[10px] text-paloverde font-semibold">
                *Reserve Item.
                <span className="text-black">
                  {"  "}
                  Estimated to ship {preOrderDate}
                </span>
              </p>
            ) : (
              ""
            )}
            <p className="mb-0 text-sm font-semibold">{item.title}</p>
            <div className="flex flex-col gap-1">
              <div className="flex">
                <p className="mb-0 text-[13px] flex font-medium font-normal">
                  {item?.variant?.title === "Default Title" ? (
                    ""
                  ) : (
                    <div className="flex">
                      <p className="font-medium text-[13px] pr-1">
                        Variant:
                        <span className="pl-1">{item?.variant?.title}</span>
                      </p>
                    </div>
                  )}{" "}
                </p>
              </div>
              <div className="flex gap-1">
                {discount ? (
                  <>
                    <span className="text-paloverde line-through">
                      {/* {countryCurrency && countryCurrency !== "USD"
                        ? countryCurrency + " "
                        : 
                        item?.variant?.priceV2?.currencyCode} */}
                      {item?.variant?.priceV2?.currencyCode}
                      &nbsp;{price ? price : item?.variant?.priceV2?.amount}
                    </span>{" "}
                    <span className="text-paloverde pl-1">
                      ${formattedResult}
                    </span>
                  </>
                ) : (
                  <span className="text-paloverde">
                    {/* {countryCurrency && countryCurrency !== "USD"
                      ? countryCurrency + " "
                      : "$"} */}
                    {subTotal
                      ? `$${subTotal}`
                      : `$${item?.variant?.price?.amount}`}
                  </span>
                )}
                {discount && (
                  <div className="flex ">
                    <p className="font-medium text-[13px] mb-0"> {discTitle}</p>
                    <p className="text-paloverde font-medium text-[13px] pl-1 mb-0">
                      (-${discountAmount})
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="flex gap-3 mt-3"></div>
            <div className="flex gap-2">
              <p className="text-[13px]">Qty:</p>
              <div className="flex items-center justify-between mb-6 ">
                {discount ? (
                  <HorizontalNumericInput
                    disabled={loading}
                    value={quantity}
                    aria-label="Qua ntity"
                    onIncrement={doIncrement}
                    onDecrement={doDecrement}
                    onChange={e => handleQuantityChange(e.currentTarget.value)}
                  />
                ) : (
                  <HorizontalNumericInput
                    disabled={loading}
                    value={quantity}
                    aria-label="Quantity"
                    onIncrement={doIncrement}
                    onDecrement={doDecrement}
                    onChange={e => handleQuantityChange(e.currentTarget.value)}
                  />
                )}
              </div>
            </div>
          </div>
          <button onClick={handleRemove} className="relative bottom-3">
            <DeleteIcon className="inline" />
          </button>
        </div>
      </div>
      {basePlateUrl ? (
        <div class="w-full flex justify-center items-center font-condensed ">
          <div class="w-[87%] px-2 sm:px-7 cut-shape shadowed-element py-8 bg-[#D4AE05]">
            <p class="text-sm font-extrabold text-center">
              WE SEE YOU ADDED A HELIX MAGLOCK FILTER TO CART!
            </p>
            <p class="text-xs font-semibold -mt-3 px-3 flex w-full justify-center items-center text-center">
              Please note that MagLock filters require a threaded baseplate for
              lenses that you wish to use with it. We recommend getting a
              baseplate for each lens so you can easily swap MagLock filters
              between them. They will not attach to lenses without baseplates.
            </p>
            <div class="w-full flex justify-between px-5 gap-3">
              <div class="w-1/2">
                <button
                  class="bg-obsidian text-base sm:text-xs px-2 font-extrabold text-white w-full py-2 rounded-sm"
                  onClick={() => {
                    onClose()
                    navigate(basePlateUrl)
                  }}
                >
                  SHOP BASEPLATES
                </button>
              </div>
              <div class="w-1/2">
                <button
                  class="bg-obsidian text-base sm:text-xs px-2 font-semibold text-white w-full py-2 rounded-sm"
                  onClick={() => {
                    setBasePlateUrl(null)
                  }}
                >
                  I'M ALL SET
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default CartDrawerProductCard
