/* eslint-disable */
// @ts-nocheck
import React, { useState, useEffect, FC } from "react"
import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Formik, Form, Field, ErrorMessage } from "formik"
import LoginBanner from "../components/LoginBanner"
import useAuth from "../hooks/useAuth"
import axios from "../api/axios"
import axiosLib from "axios"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import { useLocation } from "@reach/router"
import { functionsEndpoint } from "../config"
import { FaRegEye } from "react-icons/fa"

// import axios from 'axios';
// import Cookies from 'universal-cookie';
// import LoginBanner from '../LoginBanner';

import * as style from "./welcome.module.scss"
// import BackIcon from '../images/Profile-login-Back.svg';
// import Eye from '../images/Password-Eye.svg';
import FormError from "../components/FormError"

const Login: FC<RoutedHTMLAttr> = () => {
  const location = useLocation()

  const isBrowser = typeof window !== "undefined"
  const { auth, setAuth } = useAuth()
  const [showPassword, setShowPassword] = useState(false)
  // const [persist, setPersist] = useState(isBrowser && localStorage.getItem('persist') === 'true');
  const [errMessage, setErrMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  const [showRecover, setShowRecover] = useState(false)
  const [loading, setLoading] = useState(false)

  const privateAxios = useAxiosPrivate()

  useEffect(() => {
    if (
      isBrowser &&
      localStorage.getItem("loggedIn") === "true" &&
      !auth?.accessToken
    ) {
      setLoading(true)
      axiosLib
        .get(functionsEndpoint + "/refresh", {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(res => {
          setAuth?.({
            accessToken: res.data.accesToken,
          })
          navigate(`/account/${location.search ? location.search : ""}`)
        })
        .catch(err => {
          setLoading(false)
          localStorage.setItem("loggedIn", "false")
        })
    } else if (auth?.accessToken) {
      navigate(`/account/${location.search ? location.search : ""}`)
    }
  }, [])

  // const togglePersist = () => {
  //   // setPersist((prev: Boolean) => !prev);
  //   isBrowser && localStorage.setItem('persist', 'true')
  // }

  // useEffect(() => {
  //   isBrowser && localStorage.setItem("persist", persist.toString());
  // }, [persist])

  const handleShowPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setShowPassword(!showPassword)
  }
  return (
    <div className={style.profileUserCredWrapper}>
      <LoginBanner />
      <div className="relative w-full md:w-2/5 py-0 md:py-6">
        <div className="relative w-full">
          <div className={style.profileCredWrapper}>
            <div className={style.profileCredFormOuterWrapper}>
              <h1 className={style.profileCredFormTitle}>
                Welcome
                <br />
                Back
              </h1>
              {errMessage.length ? (
                <span className="m-auto border-[1px] py-2 px-4 text-white text-center rounded-lg bg-red-500 border-red-500">
                  {errMessage}
                </span>
              ) : (
                ""
              )}
              {successMessage.length ? (
                <span className="m-auto border-[1px] py-2 px-4 text-white text-center rounded-lg bg-green-500 border-green-500">
                  {successMessage}
                </span>
              ) : (
                ""
              )}
              <div
                className={`${style.profileCredFormWrapper} rounded-lg mt-4`}
              >
                <div
                  id="CustomerLoginForm"
                  className={!showRecover ? "" : "hidden"}
                >
                  <Formik
                    initialValues={{ email: "", password: "" }}
                    validate={values => {
                      const errors: any = {}
                      if (!values.email) {
                        errors.email = "Required"
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email,
                        )
                      ) {
                        errors.email = "Invalid email address"
                      }
                      return errors
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      setErrMessage("")
                      setSuccessMessage("")
                      await axiosLib
                        .post(
                          functionsEndpoint + "/login",
                          JSON.stringify({ data: values }),
                          {
                            headers: { "Content-Type": "application/json" },
                            withCredentials: true,
                          },
                        )
                        .then(async res => {
                          const { data } = res.data
                          if (data?.accessToken) {
                            setAuth?.({
                              user: values.email,
                              pwd: values.password,
                              accessToken: data.accessToken,
                              loggedIn: true,
                            })
                            localStorage.setItem("loggedIn", "true")
                            localStorage.setItem("email", `${values.email}`)
                            setSubmitting(false)
                            await navigate(
                              `/account/${
                                location.search ? location.search : ""
                              }`,
                            )
                          } else {
                            setErrMessage("Failed to login.")
                          }
                        })
                        .catch(err => {
                          if (!err?.response) {
                            setErrMessage("No Server Response")
                          } else if (err.response?.status === 400) {
                            setErrMessage("Missing Email or Password")
                            localStorage.setItem("loggedIn", "false")
                          } else if (err.response?.status === 401) {
                            setErrMessage("Email or Password is incorrect")
                            localStorage.setItem("loggedIn", "false")
                          } else if (err.reponse?.status === 403) {
                            setErrMessage("User not found")
                            localStorage.setItem("loggedIn", "false")
                          }
                          setSubmitting(false)
                        })
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <label
                          htmlFor="email"
                          className={style.profileCredFormLabel}
                        >
                          Email
                        </label>
                        <br />
                        <Field
                          type="email"
                          name="email"
                          placeholder="Email"
                          className="w-full text-sm"
                        />
                        <ErrorMessage
                          name="email"
                          render={msg => <FormError msg={msg} />}
                        />
                        <div className={style.passwordWrapper}>
                          <label
                            htmlFor="password"
                            className={style.profileCredFormLabel}
                          >
                            Password
                          </label>
                          <br />
                          <Field
                            type={showPassword ? "text" : "password"}
                            name="password"
                            className="mb-0 w-full text-sm"
                            placeholder="Password"
                          />
                          <button
                            type="button"
                            onClick={handleShowPassword}
                            className="relative"
                          >
                            <FaRegEye className="absolute bottom-0 right-2" />
                            {/* <Eye className={style.passwordEye} /> */}
                          </button>
                        </div>
                        <ErrorMessage
                          name="password"
                          render={msg => <FormError msg={msg} />}
                        />

                        {/*
                        <div className="flex flex-row items-center mt-2">
                          <Field
                            type="checkbox"
                            name="checked"
                            id="persist"
                            className={`mb-0 mr-1`}
                            onChange={togglePersist}
                            checked={persist}
                          />
                          <label htmlFor="persist" className={`${style.profileCredFormLabel} pt-[2px]`}>
                            Trust This Device
                          </label>
                        </div>
                        */}

                        <div className={style.credUtilWrapper}>
                          <button
                            id="RecoverPassword"
                            type="button"
                            className="underline"
                            onClick={() => setShowRecover(true)}
                          >
                            Forgot Password
                          </button>
                        </div>
                        <p className={style.signInBtnWrapper}>
                          <input
                            type="submit"
                            className={`${style.btn} w-full rounded`}
                            disabled={isSubmitting || loading}
                          />
                        </p>
                        <p className={style.createProfileP}>
                          <span>
                            Don&apos;t Have an Account?{" "}
                            <Link
                              to={`/signup/${
                                location.search ? location.search : ""
                              }`}
                              className="underline"
                            >
                              Create Profile
                            </Link>
                          </span>
                        </p>
                      </Form>
                    )}
                  </Formik>
                </div>
                <div
                  id="RecoverPasswordForm"
                  className={showRecover ? "" : "hidden"}
                >
                  <h3 className={style.title}>RESET YOUR PASSWORD</h3>
                  <p className="text-sm">
                    We will send you an email to reset your password.
                  </p>

                  <Formik
                    initialValues={{ email: "" }}
                    validate={values => {
                      const errors: any = {}
                      if (!values.email) {
                        errors.email = "Required"
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email,
                        )
                      ) {
                        errors.email = "Invalid email address"
                      }
                      return errors
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setSuccessMessage("")
                      setErrMessage("")
                      axiosLib
                        .post(
                          functionsEndpoint + "/recover",
                          JSON.stringify({ data: { email: values.email } }),
                          {
                            withCredentials: true,
                            headers: {
                              "Content-Type": "application/json",
                            },
                          },
                        )
                        .then(async res => {
                          const message =
                            res?.data?.response?.message?.customerRecover
                              ?.customerUserErrors[0]?.message
                          if (message) {
                            setErrMessage(message)
                          } else {
                            setSuccessMessage("Password reset email was sent.")
                          }
                          setSubmitting(false)
                          setShowRecover(false)
                          setErrMessage("")
                        })
                        .catch(err => {
                          if (err.response?.status === 402) {
                            console.error("Email doesn't exist.")
                            setErrMessage("Email doesn't exist")
                            localStorage.setItem("loggedIn", "false")
                          } else if (!err?.response) {
                            console.error("No Server Response")
                          } else if (err.response?.status === 400) {
                            console.error("Missing Username or Password")
                            setErrMessage("Missing Username or Password")
                            localStorage.setItem("loggedIn", "false")
                          } else if (err.response?.status === 401) {
                            console.error("Unauthorized")
                            setErrMessage("Password incorrect")
                            localStorage.setItem("loggedIn", "false")
                          } else if (err.reponse?.status === 403) {
                            console.error("Forbidden")
                            setErrMessage("User not found")
                            localStorage.setItem("loggedIn", "false")
                          }
                          setSubmitting(false)
                        })
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <label htmlFor="email" className="hidden">
                          Email
                        </label>
                        <Field
                          type="email"
                          name="email"
                          placeholder="Email"
                          className="text-sm w-full"
                        />
                        <ErrorMessage
                          name="email"
                          render={msg => <FormError msg={msg} />}
                        />
                        <p className="mt-4">
                          <input
                            type="submit"
                            className={`${style.btn} w-full`}
                            disabled={isSubmitting}
                          />
                        </p>
                        <button
                          type="button"
                          id="HideRecoverPasswordLink"
                          className="text-link text-sm underline"
                          onClick={() => setShowRecover(prev => !prev)}
                        >
                          Cancel
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>

                {/* If accounts are set as optional, the following will be shown as an option
            during checkout, not on the default /login page. */}
                {/* {% if shop.checkout.guest_login %} */}
                {/* <hr /> */}

                {/* <h3 className={style.title}>{{ 'customer.login.guest_title' | t }}</h3> */}

                {/* {% form 'guest_login' %} */}
                {/* <input type="submit" className={style.btn} value="{{ 'customer.login.guest_continue' | t }}" /> */}
                {/* {% endform %} */}
                {/* {% endif %} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
