/* eslint-disable */

import React, { useState, useEffect } from "react"
import Seo from "../../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import { useStoryblok } from "../../utils/storyblok"
import DynamicComponents from "../../components/DynamicComponents"
import loadable from "@loadable/component"
import useScrollBlock from "../../hooks/useScrollBlock"

import "./summitOverview.scss"

const ModalVideo = loadable(() => import("react-modal-video"))

const SummitOverview = () => {
  const [showVideo, setShowVideo] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    if (showVideo) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [showVideo])

  const pageData = useStaticQuery(graphql`
    query {
      storyblokEntry(full_slug: { eq: "pages/summit-filter-system" }) {
        id
        name
        content
        slug
        full_slug
        uuid
        created_at
      }
      shopifyProduct(handle: { eq: "summit-landscape-filter-system" }) {
        handle
        title
      }
    }
  `)

  const product = pageData.shopifyProduct

  const story = pageData.storyblokEntry
    ? useStoryblok(pageData.storyblokEntry)
    : { content: null }

  const stickyNavComponent =
    story.content && story.content["sticky_nav"]
      ? story.content["sticky_nav"].map(blok => (
          <>
            {blok ? (
              <div {...sbEditable(blok)} className="sticky top-0 z-50">
                <DynamicComponents
                  blok={blok}
                  product={product}
                  key={blok._uid}
                />
              </div>
            ) : null}
          </>
        ))
      : null

  // Load Widgetic Script
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://widgetic.com/sdk/sdk.js"
    script.async = true
    document.head.appendChild(script)

    script.onload = e => {
      window.Widgetic.UI.parse()
    }

    return () => {
      script.remove()
    }
  })

  return (
    <>
      <Seo title="Summit Overview" />
      <div className="sticky top-0 z-50">{stickyNavComponent}</div>
      <div class="summit-overview">
        {/* hero banner */}
        <div class="summit-hero">
          <div class="hero-text">
            <span class="industry font-black">The Industry's Most</span>
            <span class="streamlined italic">&nbsp;Refined</span>
            <br />
            <span class="filter-system font-black">
              Landscape Filter System
            </span>
          </div>
        </div>
        {/* hero banner end */}

        {/* summit video */}
        <div id="bc-st-video" class="summit-video">
          <div class="intro-text">
            <div class="intro">
              <div class="vl"></div>
              <span className="font-extrabold">Introducing</span>
            </div>
            <div class="title">
              <img
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/Summit-Logomark.svg?v=1649286536"
                alt="summit-logomark-image"
              />
            </div>
            <div class="content-text">
              <p>
                Alex Strohl has experienced a meteoric rise to become one of our
                community’s most sought-after landscape photographers.
                Throughout that journey, he has faced some of the harshest
                scenarios imaginable, each of which putting his creativity,
                mental strength and camera gear to the test. In Reaching the
                Summit, we join Alex as he progresses into the professional he
                is today.
              </p>
            </div>
          </div>
          <div class="featuring">
            <div class="title">
              <span class="dash">-</span>
              <span class="text font-extrabold uppercase">Featuring</span>
            </div>
            <div class="vl"></div>
            <div class="names">Alex Strohl</div>
            <img
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Alex-Strohl-Headshot.webp?v=1649286470"
              alt="alex-strohl-creator-headshot-image"
            />
          </div>

          <a
            href="#data"
            id="inline"
            className="cta-round play-button x-y-center"
            onClick={e => {
              e.preventDefault()
              setShowVideo(true)
            }}
          />

          <div class="video-bg-img">
            <img
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Summit-Thumbnail.webp?v=1649286470"
              class="summit-thumbnail desktop"
            />
          </div>

          {/* modal */}
          {/* <div>
							<div id="data">
								<iframe src="https://player.vimeo.com/video/372453150" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
							</div>
						</div> */}
          {/* modal end */}
          <ModalVideo
            channel="vimeo"
            videoId="372453150"
            isOpen={showVideo}
            ratio="2.3:1"
            onClose={() => setShowVideo(false)}
          />
        </div>
        {/* summit video end */}

        {/* summit-highlight */}
        <div class="summit-highlight">
          <div class="core-yellow-bar"></div>
          <div class="highlight-top highlight">
            <img
              class="desktop-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Summit-Finger-Print-Free-Install.jpg?v=1591638407"
            />
            <img
              class="mobile-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Summit-Mobile-Fingerprint-Free.jpg?v=1591638447"
            />
          </div>
          <div class="highlight">
            <img
              class="desktop-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Summit-AI-Construction.jpg?v=1591638346"
            />
            <img
              class="mobile-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Summit-Mobile-Ai-Construction.jpg?v=1591638530"
            />
          </div>
          <div class="highlight">
            <img
              class="desktop-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Summit-Integrated-CP.jpg?v=1591640761"
            />
            <img
              class="mobile-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Summit-Mobile-Integrated-CP.jpg?v=1591640817"
            />
          </div>
          <div class="highlight-bottom highlight">
            <img
              class="desktop-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Summit-Key-Feature-Banners-Optimized-UX.jpg?v=1592004902"
            />
            <img
              class="mobile-only"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Summit-Mobile-Optimized-UX-1.jpg?v=1591641260"
            />
          </div>
        </div>
        {/* summit-highlight end */}

        {/* summit quote */}
        <div class="summit-testimonial">
          <div class="st-testimonial-ctn">
            <h3 class="st-test-title desktop-only font-extrabold">
              Innovation
              <br />
              Where It Was Needed Most
            </h3>
            <h3 class="st-test-title mobile-only font-extrabold">
              All of Your Lenses,
              <br />
              One Filter System
            </h3>
            <p class="st-quote font-bold">
              <i>"</i> Having a metal edge around the filters is a game changer!
              <br />
              I have broken so many loose square glass filters in the past,
              <br />
              so the aluminum frames are an extremely well thought-out
              <br />
              design element.<i>"</i>
            </p>
            <div class="st-michael desktop-only font-bold">
              -<span class="st-michael-cap">M</span>ichael{" "}
              <span class="st-michael-cap">S</span>HAINBLUM
            </div>
            <div class="st-michael mobile-only font-bold">
              - Michael Shainblum
            </div>
          </div>
        </div>

        <div class="summit-testimonial-image mobile-only">
          <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/Summit-Mobile-Michael-Shainblum.webp?v=1649357283" />
        </div>

        {/* summit quote end */}

        {/* summit compare */}
        <div class="summit-compare-ctn">
          <div class="summit-compare-title-ctn">
            <h3 class="section-title">IN THE FIELD</h3>
          </div>
          <div class="summit-comp-top-itm">
            <widgetic
              id="5dc0556eecb2a139018b4567"
              resize="fill-width"
              width="3000"
              height="919"
              autoscale="on"
              adaptive="414"
            ></widgetic>
          </div>
          <div class="summit-compare-itm">
            <img
              class="summit-quality"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Summit-Mono-Lake.webp?v=1649288325"
              alt="See the difference in image quality with Summit"
            />
          </div>
          <div class="summit-compare-itm">
            <widgetic
              id="5dc05896ecb2a178028b4567"
              resize="fill-width"
              width="3000"
              height="919"
              autoscale="on"
              adaptive="414"
            ></widgetic>
          </div>
        </div>
        {/* summit compare end */}

        {/* summit features banner */}
        <div class="summit-features">
          <div class="summit-core">
            <div class="summit-core-title">
              <h5 class="core-title">Summit</h5>
              <h4 class="flag-title">Components</h4>
            </div>
            <div class="summit-core-image mobile">
              <img
                class="sm-mobile-only"
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/Summit-Mobile-Core.png?v=1591745137"
                alt="summit-mobile-core"
              />
            </div>
            <div class="core-details-container">
              <h4 class="flag-title">Core</h4>
              <ul class="core-bullet">
                <li class="core-detail">
                  <span class="dash desktop">-</span> Integrated CP Slot with
                  Easily Accessible Control
                </li>
                <li class="core-detail">
                  <span class="dash desktop">-</span> Tool free mounting options
                </li>
                <li class="core-detail">
                  <span class="dash desktop">-</span> Unibody ultralight design
                </li>
                <li class="core-detail">
                  <span class="dash desktop">-</span> Snap-On hood attachment
                </li>
                <li class="core-detail">
                  <span class="dash desktop">-</span> Toolless Rail Mount
                </li>
                <li class="core-detail">
                  <span class="dash desktop">-</span> Filter lock
                </li>
              </ul>
            </div>
          </div>

          <div class="bc-comp-feat">
            <div class="bc-feat-item bc-item-top thread-plate-bg">
              <div class="summit-feat-image mobile">
                <img
                  class="sm-mobile-only"
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Summit-Mobile-Thread-Plates.png?v=1591745442"
                  alt="summit-mobile-thread-plates"
                />
              </div>
              <div class="feature-details-container bc-right">
                <h4 class="flag-title">Thread Plates</h4>
                <div class="bc-feature-content">
                  <div class="bc-feature-blurb desktop-only">
                    Machined Channel allows for 360 rotation while staying{" "}
                    <br />
                    fully locked onto the system.
                  </div>
                  <div class="bc-feature-blurb sm-mobile-only">
                    Machined Channel allows for 360 rotation while <br />
                    staying fully locked onto the system.
                  </div>
                  <div class="bc-feature-choice-ctn">
                    <div class="bc-choice-title">CHOOSE FROM</div>
                    <div class="bc-feature-choice">
                      67<span class="bc-feature-choice-mm">mm</span> | 72
                      <span class="bc-feature-choice-mm">mm</span> | 77
                      <span class="bc-feature-choice-mm">mm</span> | 82
                      <span class="bc-feature-choice-mm">mm</span> | 95
                      <span class="bc-feature-choice-mm">mm</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bc-feat-item hood-bg">
              <div class="summit-feat-image mobile">
                <img
                  class="sm-mobile-only"
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Summit-Mobile-Hood.png?v=1591745594"
                  alt="summit-mobile-hood"
                />
              </div>
              <div class="feature-details-container st-left">
                <h4 class="flag-title">Hood</h4>
                <div class="bc-feature-content">
                  <div class="bc-feature-blurb">
                    Custom hood reduces stray-light and ghosting <br />
                    to retain more details in your composition.
                  </div>
                </div>
              </div>
            </div>
            <div class="bc-feat-item circular-polarizer-bg">
              <div class="summit-feat-image mobile">
                <img
                  class="sm-mobile-only"
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Summit-Mobile-CP.png?v=1591745625"
                  alt="summit-mobile-cp"
                />
              </div>
              <div class="feature-details-container st-right">
                <h4 class="flag-title">Circular Polarizer</h4>
                <div class="bc-feature-content">
                  <ul class="bc-feature-bullet">
                    <li class="bc-feature-bullet-detail">
                      <span class="dash desktop">-</span> Fused Quartz Glass
                    </li>
                    <li class="bc-feature-bullet-detail">
                      <span class="dash desktop">-</span> 16-layers of coatings
                    </li>
                    <li class="bc-feature-bullet-detail desktop-only">
                      <span class="dash desktop">-</span> Aluminum frames
                      prevent fingerprints <br />
                      <span class="bc-indent-bullet">
                        &amp; protects from impact
                      </span>
                    </li>
                    <li
                      class="bc-feature-bullet-detail sm-mobile-only"
                      id="ai-line"
                    >
                      Aluminum frames prevent fingerprints <br />
                      <span class="bc-indent-bullet">
                        &amp; protects from impact
                      </span>
                    </li>
                    <li class="bc-feature-bullet-detail desktop-only">
                      <span class="dash desktop">-</span> Reduces Glare to
                      increase color fidelity and clarity
                    </li>
                    <li class="bc-feature-bullet-detail sm-mobile-only">
                      Highly-visible filter type indicators <br />
                      <span class="bc-indent-bullet">
                        Reduces Glare to increase color fidelity and clarity
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="bc-feat-item nd-filters-bg">
              <div class="summit-feat-image mobile">
                <img
                  class="sm-mobile-only"
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Summit-Mobile-ND-Filters.png?v=1591745655"
                  alt="summit-mobile-nd-filters"
                />
              </div>
              <div class="feature-details-container st-left bc-outlier">
                <h4 class="flag-title">ND Filters</h4>
                <div class="bc-feature-content">
                  <ul class="bc-feature-bullet">
                    <li class="bc-feature-bullet-detail">
                      <span class="dash desktop">-</span> Fused Quartz Glass
                    </li>
                    <li class="bc-feature-bullet-detail">
                      <span class="dash desktop">-</span> 16-layers of coatings
                    </li>
                    <li class="bc-feature-bullet-detail desktop-only">
                      <span class="dash desktop">-</span> Aluminum frames
                      prevent fingerprints &amp;
                      <br />
                      <span class="bc-indent-bullet">protects from impact</span>
                    </li>
                    <li
                      class="bc-feature-bullet-detail sm-mobile-only"
                      id="nd-line"
                    >
                      {" "}
                      Aluminum frames prevent fingerprints <br />
                      <span class="bc-indent-bullet">
                        &amp; protects from impact
                      </span>
                    </li>
                    <li class="bc-feature-bullet-detail desktop-only">
                      <span class="dash desktop">-</span> Hyper-visible filter
                      type indicators
                    </li>
                    <li
                      class="bc-feature-bullet-detail sm-mobile-only"
                      id="nd-line"
                    >
                      {" "}
                      Hyper-visible filter type indicators <br />
                      <span class="bc-indent-bullet">
                        Reduces Glare to increase color fidelity and clarity
                      </span>
                    </li>
                    <li class="bc-feature-bullet-detail desktop-only">
                      <span class="dash desktop">-</span> Hyper-Neutral Light
                      reduction for long <br />
                      <span class="bc-indent-bullet">exposure composition</span>
                    </li>
                    <li
                      class="bc-feature-bullet-detail sm-mobile-only"
                      id="long-text"
                    >
                      <span>
                        Hyper-Neutral Light reduction for long exposure
                        composition
                      </span>
                    </li>
                  </ul>
                  <div class="bc-feature-choice-ctn">
                    <div class="bc-choice-title">CHOOSE FROM</div>
                    <div class="bc-feature-choice">
                      ND8 | ND64 | ND1000 | ND100k
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bc-feat-item soft-gradient-bg">
              <div class="summit-feat-image mobile">
                <img
                  class="sm-mobile-only"
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Summit-Mobile-GR.png?v=1591745693"
                  alt="summit-mobile-gr"
                />
              </div>
              <div class="feature-details-container st-right bc-outlier">
                <h4 class="flag-title">Soft Gradient</h4>
                <div class="bc-feature-content">
                  <ul class="bc-feature-bullet">
                    <li class="bc-feature-bullet-detail" id="long-text">
                      <span class="dash desktop">-</span> Balances exposure in
                      harsh lighting to enhance dynamic range{" "}
                    </li>
                    <li class="bc-feature-bullet-detail">
                      <span class="dash desktop">-</span> Fused Quartz Glass
                    </li>
                    <li class="bc-feature-bullet-detail">
                      <span class="dash desktop">-</span> 16-layers of coatings
                    </li>
                    <li class="bc-feature-bullet-detail desktop-only">
                      <span class="dash desktop">-</span> Aluminum frames
                      prevent fingerprints & protects from impact
                    </li>
                    <li class="bc-feature-bullet-detail sm-mobile-only">
                      Aluminum frames prevent fingerprints <br />
                      <span class="bc-indent-bullet">
                        & protects from impact
                      </span>
                    </li>
                    <li class="bc-feature-bullet-detail">
                      <span class="dash desktop">-</span> Highly-visible filter
                      type indicator
                    </li>
                  </ul>
                  <div class="bc-feature-choice-ctn">
                    <div class="bc-choice-title">CHOOSE FROM</div>
                    <div class="bc-feature-choice">ND4-GR | ND8-GR</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bc-feat-item ultra-compact-bg">
              <div class="summit-feat-image mobile">
                <img
                  class="sm-mobile-only"
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Summit-Mobile-Case.jpg?v=1591745724"
                  alt="summit-mobile-case"
                />
              </div>
              <div class="feature-details-container st-left">
                <h4 class="flag-title feat-title-outlier desktop">
                  Ultra-Compact Case
                </h4>
                <div class="bc-feature-content">
                  <div class="bc-feature-blurb desktop">
                    No breakdown required, store your fully rigged <br />
                    Summit system and be ready to shoot in seconds.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* summit features banner end */}

        {/* summit choose kit */}
        <div class="summit-build-kit">
          <a
            href="https://www.polarpro.com/products/Filter-holder-system"
            class="bc-build-btn-overlay bc-build-btn-ctn vertical-center"
          ></a>
        </div>
        {/* summit choose kit end*/}
      </div>
    </>
  )
}

export default SummitOverview
