/* eslint-disable */
import React, { useState, useEffect } from "react"
// import { Link } from "gatsby";
import loadable from "@loadable/component"
import * as style from "./style.module.scss"
import "./style.scss"
import { useStaticQuery, graphql } from "gatsby"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import { useStoryblok } from "../../utils/storyblok"
import DynamicComponents from "../DynamicComponents"

import useScrollBlock from "../../hooks/useScrollBlock"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

const ModalVideo = loadable(() => import("react-modal-video"))

const BelowReconVndOverview = () => {
  const [showVideo, setShowVideo] = useState(false)
  const [showVideo2, setShowVideo2] = useState(false)
  const [showVideo3, setShowVideo3] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  }

  const scrollUp = e => {
    e.preventDefault()
    window.scrollTo({
      top: 0,
      behaviour: "smooth",
    })
  }

  useEffect(() => {
    if (showVideo || showVideo2 || showVideo3) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [showVideo, showVideo2, showVideo3])

  const pageData = useStaticQuery(graphql`
    query {
      storyblokEntry(full_slug: { eq: "/products/recon-vnd-matte-box" }) {
        id
        name
        content
        slug
        full_slug
        uuid
        created_at
      }
      shopifyProduct(handle: { eq: "recon-vnd-matte-box" }) {
        handle
        title
      }
    }
  `)

  const product = pageData.shopifyProduct

  const story = pageData.storyblokEntry
    ? useStoryblok(pageData.storyblokEntry)
    : { content: null }

  const stickyNavComponent =
    story.content && story.content["sticky_nav"]
      ? story.content["sticky_nav"].map(blok => (
        <>
          {blok ? (
            <div {...sbEditable(blok)} className="sticky top-0 z-50">
              <DynamicComponents
                blok={blok}
                product={product}
                key={blok._uid}
              />
            </div>
          ) : null}
        </>
      ))
      : null

  // add event listener for next config cta btn
  useEffect(() => {
    document
      .getElementsByClassName("nextConfigBtn")[0]
      .addEventListener("click", function (e) {
        e.preventDefault()
        let elem = document.querySelector(
          "div.six-config .react-multiple-carousel__arrow--right",
        )
        elem.click()
      })
  })

  return (
    <div className={`${style.boreal50lOverview} overflow-hidden`}>
      <p
        className={`${style.sectionTitleRecon} display-font-logo font-bold section-title text-obsidian mt-20`}
      >
        <span className="text-obsidian">Explore Boreal</span>
      </p>
      <div class={`${style.productHero} text-white container-wrapper`}>
        <div class={`${style.introData}`}>
          <h2 className="display-font-logo font-bold">Explore Boreal</h2>
          <p className="hidden 4xl:block">
            Overbuilt to take on your toughest productions. Featuring a 600D
            Poly exterior and <br />
            extra-deep main compartment, Boreal will be your do-it-all
            workhorse.
          </p>
          <p className="block 4xl:hidden">
            Overbuilt to take on your toughest productions. Featuring a 600D
            Poly exterior and extra-deep main compartment, Boreal will be your
            do-it-all workhorse.
          </p>
        </div>
      </div>

      <div class={style.videoContainer}>
        <div className="block lg:flex lg:flex-row m-auto justify-center w-[45%] lg:w-full absolute left-0 right-0 bottom-[15%] lg:bottom-[3vw] xl:bottom-[3vw] gap-0 md:gap-8">
          <a
            href="#data"
            onClick={e => {
              e.preventDefault()
              setShowVideo(true)
            }}
            className="border-solid border-2 text-white block lg:inline-block px-8 py-2 font-bold text-center font-expanded hover:bg-[#ffffff1a] mt-10 uppercase w-full lg:w-[230px] text-[.6rem] pb-[7px]"
          >
            WATCH VIDEO
          </a>
          <a
            href="#"
            onClick={scrollUp}
            className="border-solid border-2 text-white border-paloverde hover:bg-paloverde text-center block lg:inline-block px-8 py-2 font-bold font-expanded mt-4 lg:mt-10 uppercase w-full lg:w-[230px] text-[.6rem] pb-[7px]"
          >
            BUY NOW
          </a>
        </div>

        {/* modal */}
        <ModalVideo
          channel="youtube"
          videoId="laek8rNSHQ8"
          isOpen={showVideo}
          onClose={() => setShowVideo(false)}
        />
      </div>

      <div className="fast-versatility max-w-full lg:max-w-[64vw] m-auto">
        <p className="display-font-logo font-bold section-title text-obsidian mt-[100px] lg:mt-20">
          <span className="text-obsidian">
            Built For Your Toughest Productions
          </span>
        </p>

        <div className="flex items-center flex-col-reverse lg:flex-row-reverse">
          <div className="py-14 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black font-expanded uppercase text-3xl lg:text-base xl:text-xl 3xl:text-3xl px-[2vw] text-center lg:text-left text-obsidian mb-2">
              600D POLY EXTERIOR
            </p>
            <p className="hidden 2xl:block text-center lg:text-sm lg:text-left font-condensed px-[2vw] text-obsidian">
              Boreal's 600D Polyester exterior combined with <br />a DWR finish
              make it nearly 50% thicker than <br />
              traditional camera packs with just 6oz. of <br />
              additional weight gain.
            </p>
            <p className="block 2xl:hidden text-center lg:text-sm lg:text-left font-condensed px-[2vw] text-obsidian">
              Boreal's 600D Polyester exterior combined with a DWR finish make
              it nearly 50% thicker than traditional camera packs with just 6oz.
              of additional weight gain.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/boreal-600d-poly-block-1-desktop.webp?v=1673391574"
              alt=""
            />
            <img
              class="block lg:hidden w-full m-0"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/boreal-600d-poly-block-1-mobile.webp?v=1673391603"
              alt=""
            />
          </div>
        </div>

        <div className="flex items-center flex-col-reverse lg:flex-row">
          <div className="py-14 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black font-expanded uppercase text-3xl lg:text-base xl:text-xl 3xl:text-3xl px-[2vw] text-center lg:text-left text-obsidian mb-2">
              PRODUCTION <br /> TO TRAVEL
            </p>
            <p className="hidden 3xl:block text-center lg:text-sm lg:text-left font-condensed px-[2vw] text-obsidian">
              The Boreal 50L's packing cube system and carry-on <br />
              size allow you to use the bag for light camera + <br />
              travel gear pack-outs, perfect for weekend <br />
              photography/videography trips.
            </p>
            <p className="block 3xl:hidden text-center lg:text-sm lg:text-left font-condensed px-[2vw] text-obsidian">
              The Boreal 50L's packing cube system and carry-on size allow you
              to use the bag for light camera + travel gear pack-outs, perfect
              for weekend photography/videography trips.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/boreal-production-to-travel-block-2-desktop.webp?v=1673391574"
              alt=""
            />
            <img
              class="block lg:hidden w-full m-0"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/boreal-production-to-travel-block-2-mobile.webp?v=1673391603"
              alt=""
            />
          </div>
        </div>

        <div className="flex items-center flex-col-reverse lg:flex-row-reverse">
          <div className="py-14 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black font-expanded uppercase text-3xl lg:text-base xl:text-xl 3xl:text-3xl px-[2vw] text-center lg:text-left text-obsidian mb-2">
              LOAD TESTED <br />
              AT 65+ LBS
            </p>
            <p className="hidden 3xl:block text-center lg:text-sm lg:text-left font-condensed px-[2vw] text-obsidian">
              Boreal's stitching and straps are stress-tested at <br />
              100lbs, giving you full confidence to load up <br />
              the pack with the heaviest camera bodies, <br />
              lenses, and exterior gear/equipment.
            </p>
            <p className="block 3xl:hidden text-center lg:text-sm lg:text-left font-condensed px-[2vw] text-obsidian">
              Boreal's stitching and straps are stress-tested at 100lbs, giving
              you full confidence to load up the pack with the heaviest camera
              bodies, lenses, and exterior gear/equipment.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/boreal-load-tested-65lbs-block-3-desktop.webp?v=1673391574"
              alt=""
            />
            <img
              class="block lg:hidden w-full m-0"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/boreal-load-tested-65lbs-block-3-mobile.webp?v=1673391603"
              alt=""
            />
          </div>
        </div>

        <div class="block mb-8">
          <video
            autoPlay={true}
            muted={true}
            loop={true}
            playsInline={true}
            className="hidden lg:block"
          >
            <source src="https://cdn.shopify.com/videos/c/o/v/48f890a177a4414f884da094c1f0698e.mp4" />
          </video>

          <video
            autoPlay={true}
            muted={true}
            loop={true}
            playsInline={true}
            className="block lg:hidden w-full"
          >
            <source src="https://cdn.shopify.com/videos/c/o/v/32b794fc7c5e4668b2bbf22cf2670c1f.mp4" />
          </video>
        </div>
      </div>

      <div className="six-config bg-black pb-0 lg:pb-20 pt-1">
        <p className="display-font-logo font-bold section-title text-white mt-20">
          <span className="text-white">Explore Features</span>
        </p>
        <div className="max-w-full lg:max-w-[64vw] m-auto">
          <div class="block relative">
            <div id={style.sixPowerfulConfig}>
              <Carousel
                responsive={responsive}
                swipeable={false}
                draggable={false}
                showDots={true}
                infinite={true}
                renderDotsOutside={true}
                containerClass={`${style.sixConfig} sixConfigArrow reconCustomSlider`}
                dotListClass={`${style.reconCustomDotSlider} ${style.sixConfig} reconCustomDotSlider`}
              >
                <div className={`${style.item} flex flex-col lg:flex-row`}>
                  <div className="text-white w-2/5 p-0 flex flex-col justify-center">
                    <p className="text-paloverde font-black font-condensed text-5xl border-[#272727] border-bottom border-b-2 relative lg:absolute top-0 w-[65vw] lg:w-[20vw] mx-10 lg:mx-0">
                      01
                    </p>
                    <p className="hidden lg:block font-black font-expanded text-3xl lg:text-lg xl:text-2xl 2xl:text-3xl m-0">
                      16" MACBOOK <br />
                      PRO STORAGE
                    </p>
                    <p className="hidden lg:block text-xs mt-2">
                      The front outer compartment safely stores laptops <br />
                      up to a 16-inch MacBook Pro.
                    </p>
                  </div>
                  <div className="w-full lg:w-3/5">
                    <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/boreal-16in-macbook-pro-storage-desktop.webp?v=1676938809" />
                  </div>
                  <div className="text-white px-10 block lg:hidden py-32">
                    <p className="font-black font-expanded text-3xl m-0">
                      16" MACBOOK <br />
                      PRO STORAGE
                    </p>
                    <p className="text-xs mt-2">
                      The front outer compartment safely stores laptops up to a
                      16-inch MacBook Pro.
                    </p>
                  </div>
                </div>

                <div className={style.item}>
                  <div className={`${style.item} flex flex-col lg:flex-row`}>
                    <div className="text-white w-2/5 p-0 flex flex-col justify-center">
                      <p className="text-paloverde font-black font-condensed text-5xl border-[#272727] border-bottom border-b-2 relative lg:absolute top-0 w-[65vw] lg:w-[20vw] mx-10 lg:mx-0">
                        02
                      </p>
                      <p className="hidden lg:block font-black font-expanded text-3xl lg:text-lg xl:text-2xl 2xl:text-3xl m-0">
                        YKK EASY-CLIP BUCKLES
                      </p>
                      <p className="hidden lg:block text-xs mt-2">
                        All of Boreal's buckles feature YKK's quick-release,{" "}
                        <br />
                        no-hassle style clips which are super durable <br />
                        and fast to secure/release.
                      </p>
                    </div>
                    <div className="w-full lg:w-3/5">
                      <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/boreal-features-slider-2.webp?v=1673463505" />
                    </div>
                    <div className="text-white px-10 block lg:hidden py-32">
                      <p className="font-black font-expanded text-3xl m-0">
                        YKK EASY-CLIP BUCKLES
                      </p>
                      <p className="text-xs mt-2">
                        All of Boreal's buckles feature YKK's quick-release,
                        no-hassle style clips which are super durable and fast
                        to secure/release.
                      </p>
                    </div>
                  </div>
                </div>

                <div className={style.item}>
                  <div className={`${style.item} flex flex-col lg:flex-row`}>
                    <div className="text-white w-2/5 p-0 flex flex-col justify-center">
                      <p className="text-paloverde font-black font-condensed text-5xl border-[#272727] border-bottom border-b-2 relative lg:absolute top-0 w-[65vw] lg:w-[20vw] mx-10 lg:mx-0">
                        03
                      </p>
                      <p className="hidden lg:block font-black font-expanded text-3xl lg:text-lg xl:text-2xl 2xl:text-3xl m-0">
                        15L ROLL-TOP DESIGN
                      </p>
                      <p className="hidden lg:block text-xs mt-2">
                        Adds 15L of additional, quick-access storage. <br />
                        Perfect for packing adventure essentials like <br />
                        jackets, gloves, layers, etc.
                      </p>
                    </div>
                    <div className="w-full lg:w-3/5">
                      <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/boreal-features-slider-3.webp?v=1673463505" />
                    </div>
                    <div className="text-white px-10 block lg:hidden py-32">
                      <p className="font-black font-expanded text-3xl m-0">
                        15L ROLL-TOP DESIGN
                      </p>
                      <p className="text-xs mt-2">
                        Adds 15L of additional, quick-access storage. Perfect
                        for packing adventure essentials like jackets, gloves,
                        layers, etc.
                      </p>
                    </div>
                  </div>
                </div>

                <div className={style.item}>
                  <div className={`${style.item} flex flex-col lg:flex-row`}>
                    <div className="text-white w-2/5 p-0 flex flex-col justify-center">
                      <p className="text-paloverde font-black font-condensed text-5xl border-[#272727] border-bottom border-b-2 relative lg:absolute top-0 w-[65vw] lg:w-[20vw] mx-10 lg:mx-0">
                        04
                      </p>
                      <p className="hidden lg:block font-black font-expanded text-3xl lg:text-lg xl:text-2xl 2xl:text-3xl m-0">
                        QUICK-GRAB HANDLES
                      </p>
                      <p className="hidden lg:block text-xs mt-2">
                        Located on the left, right, top, and bottom; these{" "}
                        <br />
                        quick-grab handles allow you to grab Boreal from <br />
                        any angle or orientation.
                      </p>
                    </div>
                    <div className="w-full lg:w-3/5">
                      <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/boreal-features-slider-4.webp?v=1673463505" />
                    </div>
                    <div className="text-white px-10 block lg:hidden py-32">
                      <p className="font-black font-expanded text-3xl m-0">
                        QUICK-GRAB HANDLES
                      </p>
                      <p className="text-xs mt-2">
                        Located on the left, right, top, and bottom; these
                        quick-grab handles allow you to grab Boreal from any
                        angle or orientation.
                      </p>
                    </div>
                  </div>
                </div>

                <div className={style.item}>
                  <div className={`${style.item} flex flex-col lg:flex-row`}>
                    <div className="text-white w-2/5 p-0 flex flex-col justify-center">
                      <p className="text-paloverde font-black font-condensed text-5xl border-[#272727] border-bottom border-b-2 relative lg:absolute top-0 w-[65vw] lg:w-[20vw] mx-10 lg:mx-0">
                        05
                      </p>
                      <p className="hidden lg:block font-black font-expanded text-3xl lg:text-lg xl:text-2xl 2xl:text-3xl m-0">
                        KEYDROP <br />
                        POCKET
                      </p>
                      <p className="hidden lg:block text-xs mt-2">
                        Safely secure smaller essential items such as <br />
                        your passport, keys, phone, or field notes. <br />
                        Featuring a waterproof zipper for safe keeping.
                      </p>
                    </div>
                    <div className="w-full lg:w-3/5">
                      <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/boreal-features-slider-5.webp?v=1673463505" />
                    </div>
                    <div className="text-white px-10 block lg:hidden py-32">
                      <p className="font-black font-expanded text-3xl m-0">
                        KEYDROP POCKET
                      </p>
                      <p className="text-xs mt-2">
                        Safely secure smaller essential items such as your
                        passport, keys, phone, or field notes. Featuring a
                        waterproof zipper for safe keeping.
                      </p>
                    </div>
                  </div>
                </div>

                <div className={style.item}>
                  <div className={`${style.item} flex flex-col lg:flex-row`}>
                    <div className="text-white w-2/5 p-0 flex flex-col justify-center">
                      <p className="text-paloverde font-black font-condensed text-5xl border-[#272727] border-bottom border-b-2 relative lg:absolute top-0 w-[65vw] lg:w-[20vw] mx-10 lg:mx-0">
                        06
                      </p>
                      <p className="hidden lg:block font-black font-expanded text-3xl lg:text-lg xl:text-2xl 2xl:text-3xl m-0">
                        11MM THICK DIVIDERS
                      </p>
                      <p className="hidden lg:block text-xs mt-2">
                        We wanted to make sure that standard, flimsy <br />
                        internal dividers were a thing of the past.
                      </p>
                    </div>
                    <div className="w-full lg:w-3/5">
                      <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/boreal-features-slider-6.webp?v=1673463505" />
                    </div>
                    <div className="text-white px-10 block lg:hidden py-32">
                      <p className="font-black font-expanded text-3xl m-0">
                        11MM THICK DIVIDERS
                      </p>
                      <p className="text-xs mt-2">
                        We wanted to make sure that standard, flimsy internal
                        dividers were a thing of the past.
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel>
              {/* custom next cta btn */}
              <a
                className={`${style.nextConfigBtn} nextConfigBtn text-white hover:text-paloverde absolute left-0 bottom-0 font-bold font-condensed`}
                href="#"
              >
                NEXT FEATURE{" "}
              </a>
            </div>
            {/* six powerful config img carousel end*/}
          </div>
        </div>
      </div>

      <div className="two-base-platform px-[15px] lg:px-0 max-w-full lg:max-w-[64vw] m-auto mb-[200px]">
        <p className="display-font-logo font-bold section-title text-obsidian mt-[100px] lg:mt-20">
          <span className="text-obsidian">Interior Layouts</span>
        </p>

        <div className="block lg:flex flex-row-reverse items-center border-bottom border-b-2 pb-20 pr-0 lg:pr-[2vw]">
          <div className="block sm:w-full flex lg:w-1/2 flex-col items-left justify-center pl-0 lg:pl-[3vw]">
            <p className="uppercase bg-paloverde inline-block text-white font-condensed font-bold px-4 pt-2 pb-[4px] text-sm rounded-3xl w-fit">
              CINEMATOGRAPHER
            </p>
            <p className="font-black font-expanded uppercase text-4xl lg:text-2xl 2xl:text-3xl text-left text-obsidian mb-2">
              PRO-VIDEO
            </p>
            <p className="hidden 4xl:block text-left text-obsidian text-sm font-condensed">
              Boreal's main compartment sports a depth of 6.25-inches, ensuring
              that you no longer need to cram large cine camera bodies and
              lenses into a pack that was designed for small mirrorless cameras.
            </p>
            <p className="block 4xl:hidden text-left text-obsidian text-sm font-condensed">
              Boreal's main compartment sports a depth of 6.25-inches, ensuring
              that you no longer need to cram large cine camera bodies and
              lenses into a pack that was designed for small mirrorless cameras.
            </p>
            <p className="border-bottom border-b-2 inline-block font-black mt-4 mb-4">
              DETAILS / FEATURES
            </p>
            <ul className="font-condensed text-sm font-bold m-0 list-none">
              <li className="m-0">
                ◉ Storage for multiple cinema camera bodies
              </li>
              <li className="m-0">◉ Cine lens storage/support</li>
              <li className="m-0">◉ Custom-molded, 11mm thick dividers</li>
            </ul>
          </div>
          <div class="block lg:w-1/2 mt-10 lg:mt-0 relative">
            <div id={style.base1Slider}>
              <Carousel
                responsive={responsive}
                swipeable
                draggable
                showDots={true}
                infinite={true}
                renderDotsOutside={true}
                containerClass={`${style.base1Slider} reconCustomSlider`}
                dotListClass={`${style.reconCustomDotSlider} ${style.base1Slider} reconCustomDotSlider`}
              >
                <div className={style.item}>
                  <img
                    class=""
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/boreal-interior-layouts-provideo-slide-1.webp?v=1673464412"
                    alt=""
                  />
                </div>
                <div className={style.item}>
                  <img
                    class=""
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/boreal-interior-layouts-provideo-slide-2.webp?v=1673464412"
                    alt=""
                  />
                </div>
              </Carousel>
            </div>
          </div>
        </div>

        <div className="block lg:flex items-center pt-24">
          <div className="block sm:w-full flex lg:w-1/2 flex-col items-left justify-center pr-0 lg:pr-[3vw] pl-0 lg:pl-[2vw]">
            <p className="uppercase bg-paloverde inline-block text-white font-condensed font-bold px-4 pt-2 pb-[4px] text-sm rounded-3xl w-fit">
              PHOTOGRAPHER
            </p>
            <p className="font-black font-expanded uppercase text-4xl lg:text-2xl 2xl:text-3xl text-left text-obsidian mb-2">
              PRO-PHOTO
            </p>
            <p className="hidden 4xl:block text-left text-obsidian text-sm font-condensed">
              Our custom-engineered dividers provide ideal organization and
              durability when loading up your pack with multiple large camera
              bodies and lenses needed when tackling a professional photo shoot.
            </p>
            <p className="block 4xl:hidden text-left text-obsidian text-sm font-condensed">
              Our custom-engineered dividers provide ideal organization and
              durability when loading up your pack with multiple large camera
              bodies and lenses needed when tackling a professional photo shoot.
            </p>
            <p className="border-bottom border-b-2 inline-block font-black mt-4 mb-4">
              DETAILS / FEATURES
            </p>
            <ul className="font-condensed text-sm font-bold m-0 list-none">
              <li className="m-0">
                ◉ Storage for multiple medium-format camera bodies
              </li>
              <li className="m-0">◉ Large zoom lens storage/support</li>
              <li className="m-0">◉ Custom-molded, 11mm thick dividers</li>
            </ul>
          </div>
          <div class="block lg:w-1/2 pl-0 mt-10 lg:mt-0 relative">
            <div id={style.base2Slider}>
              <Carousel
                responsive={responsive}
                swipeable
                draggable
                showDots={true}
                infinite={true}
                renderDotsOutside={true}
                containerClass={`${style.base2Slider} reconCustomSlider`}
                dotListClass={`${style.reconCustomDotSlider} ${style.base2Slider} reconCustomDotSlider`}
              >
                <div className={style.item}>
                  <img
                    class=""
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/boreal-interior-layouts-prophoto-slide-1.webp?v=1673464412"
                    alt=""
                  />
                </div>

                <div className={style.item}>
                  <img
                    class=""
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/boreal-interior-layouts-prophoto-slide-2.webp?v=1673464412"
                    alt=""
                  />
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>

      <div className="explore-prebuilt px-[15px] lg:px-0 max-w-full lg:max-w-[64vw] m-auto text-white">
        <p className="display-font-logo font-bold section-title text-obsidian mt-[100px] lg:mt-20">
          <span className="text-obsidian">Packing Cubes & Organization</span>
        </p>
        <div className="baseAndDirector block lg:flex flex-row text-center gap-0 lg:gap-10">
          <div
            className={`${style.TechPouch} w-full lg:w-1/2 text-center rounded-2xl p-10 relative pb-[240px] mb-8 lg:mb-0`}
          >
            <p className="title font-black text-3xl md:text-2xl xl:text-3xl mb-2">
              7L Tech Pouch
            </p>
            <p className="subTitle text-sm px-0 xl:px-10 hidden lg:block">
              Outer Dims: 9.75 in. W x 6.0 in. H x 3.2 in. D
            </p>
            <p className="subTitle text-sm px-0 xl:px-10 block lg:hidden">
              Outer Dims: 9.75 in. W x 6.0 in. H x 3.2 in. D
            </p>
            <img
              className="m-auto mt-10 mb-6 max-h-[270px] w-full object-contain"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/boreal-tech-pouch.png?v=1673467595"
              alt=""
            />
            <a
              className="border-solid border-2 inline-block px-6 py-2 font-bold font-expanded text-[.6rem] pb-[7px] hover:bg-[#ffffff1a] mt-10 uppercase w-[230px]"
              href="/products/tech-pouch-boreal"
              target="_blank"
            >
              Buy Now
            </a>
            <div className="included bg-obsidian absolute bottom-0 left-0 right-0 pt-4 pb-8 rounded-b-2xl px-12">
              <p className="font-bold text-lg border-[#272727] border-bottom border-b-2">
                Details
              </p>
              <p className="hidden 3xl:block text-sm font-medium font-condensed">
                The Tech Pouch can be used in conjunction with multiple 10L
                Cubes <br />
                or with both the 10L and 20L Cubes simultaneously.
              </p>
              <p className="block 3xl:hidden text-sm font-medium font-condensed">
                The Tech Pouch can be used in conjunction with multiple 10L
                Cubes or with both the 10L and 20L Cubes simultaneously.
              </p>
            </div>
          </div>
          <div
            className={`${style.tenlcube} w-full lg:w-1/2 text-center rounded-2xl p-10 relative pb-[240px]`}
          >
            <p className="title font-black text-3xl md:text-2xl xl:text-3xl mb-2">
              10L Cube
            </p>
            <p className="hidden lg:block subTitle text-sm px-0 xl:px-10">
              Outer Dims: 12.0 in. W x 5.75 in. H x 6.25 in. D
            </p>
            <p className="block lg:hidden subTitle text-sm px-0 xl:px-10">
              Outer Dims: 12.0 in. W x 5.75 in. H x 6.25 in. D
            </p>
            <img
              className="m-auto mt-10 mb-6 max-h-[220px] w-full object-contain"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/boreal-10L-cube.png?v=1673467596"
              alt=""
            />
            <a
              className="border-solid border-2 inline-block px-4 py-2 font-bold font-expanded text-[.6rem] pb-[7px] hover:bg-[#ffffff1a] mt-10 uppercase w-[230px]"
              href="/products/10l-cube-boreal"
              target="_blank"
            >
              Buy Now
            </a>
            <div className="included bg-obsidian absolute bottom-0 left-0 right-0 pt-4 pb-8 rounded-b-2xl px-12">
              <p className="font-bold text-lg border-[#272727] border-bottom border-b-2">
                Details
              </p>
              <p className="hidden 3xl:block text-sm font-medium font-condensed ">
                The Boreal 50L supports up to three (3) 10L Cubes in total:{" "}
                <br />
                two (2) in the main compartment and one (1) in the top
                compartment.
              </p>
              <p className="block 3xl:hidden text-sm font-medium font-condensed ">
                The Boreal 50L supports up to three (3) 10L Cubes in total: two
                (2) in the main compartment and one (1) in the top compartment.
              </p>
            </div>
          </div>
        </div>

        <div
          className={`${style.twentylCube} w-full items-center justify-center text-center rounded-2xl p-10 mt-10 relative pb-[240px]`}
        >
          <p className="title font-black text-3xl md:text-2xl xl:text-3xl mb-2">
            20L Cube
          </p>
          <p className="subTitle text-sm px-0 xl:px-10">
            Outer Dims: 12.0 in. W x 15.5 in. H x 6.25 in. D
          </p>
          <img
            className="m-auto mt-10 mb-6 max-h-[300px] w-full object-contain"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/boreal-20L-cube.png?v=1673467595"
            alt=""
          />
          <a
            className="border-solid border-2 inline-block px-8 py-2 font-bold font-expanded text-[.6rem] pb-[7px] hover:bg-[#ffffff1a] mt-10 uppercase w-[230px]"
            href="/products/20l-cube-boreal"
            target="_blank"
          >
            Buy Now
          </a>
          <div className="included bg-obsidian absolute bottom-0 left-0 right-0 pt-4 pb-8 px-12 rounded-b-2xl">
            <p className="font-bold text-lg border-[#272727] border-bottom border-b-2">
              Details
            </p>
            <p className="text-sm font-medium font-condensed">
              The Boreal 50L supports one (1) 20L Cube in the main compartment,
              and can be <br />
              used in conjunction with the 10L Cube and/or Tech Pouch for
              refined organization.
            </p>
          </div>
        </div>
      </div>
      {/* explore prebuilt end */}
      <div className="launchCommercial my-20">
        <div className="flex flex-col lg:flex-row">
          <div
            className={`${style.hannahH} relative items-center justify-center flex w-full lg:w-[50vw]`}
          >
            <a
              href="#data"
              id="system-overview-play-btn"
              className="play-button small"
              onClick={e => {
                e.preventDefault()
                setShowVideo2(true)
              }}
            />
            {/* modal */}
            <ModalVideo
              channel="youtube"
              videoId="FhbAfUbV54M"
              isOpen={showVideo2}
              onClose={() => setShowVideo2(false)}
            />
          </div>
          <div
            className={`${style.connorV} relative items-center justify-center flex w-full lg:w-[50vw]`}
          >
            <a
              href="#data"
              id="system-overview-play-btn"
              className="play-button small"
              onClick={e => {
                e.preventDefault()
                setShowVideo3(true)
              }}
            />
            {/* modal */}
            <ModalVideo
              channel="youtube"
              videoId="ytGTERiUMOI"
              isOpen={showVideo3}
              onClose={() => setShowVideo3(false)}
            />
          </div>
        </div>

        <div className="flex flex-col lg:flex-row">
          <div>
            <img
              className="m-auto"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/boreal-media-image-block-1-desktop.webp?v=1673475907"
              alt=""
            />
          </div>
          <div>
            <img
              className="m-auto"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/boreal-media-image-block-2-desktop.webp?v=1673475908"
              alt=""
            />
          </div>
          <div>
            <img
              className="m-auto"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/boreal-media-image-block-3-desktop.webp?v=1673475908"
              alt=""
            />
          </div>
        </div>
        <a
          href="#"
          onClick={scrollUp}
          className="bg-paloverde hover:bg-[#e2b220] min-w-200px w-[368px] m-auto block text-center mt-16 px-30 pb-[10px] pt-3 font-expanded uppercase text-white font-bold text-xs"
        >
          Buy Now
        </a>
      </div>
    </div>
  )
}

export default BelowReconVndOverview
