/* eslint-disable */
import * as React from "react"
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md"
const NumericInput = ({
  onIncrement,
  onDecrement,
  className,
  disabled,
  ...props
}) => {
  return (
    <div className="w-20 h-[44px] grid grid-rows-2 grid-cols-3 border-grey-dark border-[1px] overflow-hidden">
      <input
        disabled={disabled}
        type="numeric"
        className={`w-12 grid col-start-1 col-span-2 row-span-2 border-none border-solid border-black border-r-2 font-md text-obsidian text-center bg-transparent ${className}`}
        {...props}
      />
      <button
        disabled={disabled}
        className="w-7 border-solid bg-none border-b-[1px] border-l-[1px] border-grey-dark grid col-span-1 row-span-1 text-obsidian place-items-center hover:bg-grey"
        aria-label="Increment"
        onClick={onIncrement}
      >
        <span className="hidden">+</span>
        <MdArrowDropUp />
      </button>
      <button
        disabled={disabled}
        className="w-7 border-solid bg-none border-l-[1px] border-grey-dark grid col-span-1 row-span-1 text-obsidian place-items-center hover:bg-grey"
        aria-label="Decrement"
        onClick={onDecrement}
      >
        <span className="hidden">-</span>
        <MdArrowDropDown />
      </button>
    </div>
  )
}

export default NumericInput
