/* eslint-disable */
import React from "react"

// width="126.134" height="107.385"
const ShoppingCartIcon = (w, h = "auto") => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    viewBox="0 0 126.134 107.385"
    style={{ width: `${w}` }}
  >
    <g id="Icon" transform="translate(4 4)">
      <g
        id="Ellipse_95"
        data-name="Ellipse 95"
        transform="translate(82.002 74.917)"
        fill="none"
        stroke="#434343"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="8"
      >
        <circle cx="10.234" cy="10.234" r="10.234" stroke="none" />
        <circle cx="10.234" cy="10.234" r="14.234" fill="none" />
      </g>
      <g
        id="Ellipse_96"
        data-name="Ellipse 96"
        transform="translate(35.171 74.917)"
        fill="none"
        stroke="#434343"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="8"
      >
        <circle cx="10.234" cy="10.234" r="10.234" stroke="none" />
        <circle cx="10.234" cy="10.234" r="14.234" fill="none" />
      </g>
      <path
        id="Path_1427"
        data-name="Path 1427"
        d="M40.215,37.022h84.259a6.516,6.516,0,0,1,6.15,8.67l-10.7,30.523a6.515,6.515,0,0,1-6.148,4.362H52.222A6.516,6.516,0,0,1,45.9,75.632L33.089,23.99a6.513,6.513,0,0,0-6.322-4.949H12.919"
        transform="translate(-12.919 -19.041)"
        fill="none"
        stroke="#434343"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="8"
      />
      <line
        id="Line_223"
        data-name="Line 223"
        x1="53.43"
        transform="translate(45.405 46.506)"
        fill="none"
        stroke="#434343"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="8"
      />
      <line
        id="Line_224"
        data-name="Line 224"
        x1="61.752"
        transform="translate(41.244 32.811)"
        fill="none"
        stroke="#434343"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="8"
      />
    </g>
  </svg>
)

export default ShoppingCartIcon
