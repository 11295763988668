import React from "react"
import PropTypes from "prop-types"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import DynamicComponents from "../DynamicComponents" // eslint-disable-line

const ZigZagBannersContainer = ({ blok }) => {
  const { title, body } = blok

  return (
    <section className="my-20">
      <p className="display-font font-black text-obsidian text-center mb-2 text-[18.75px]">
        {title}
      </p>
      <hr className="text-center w-[100px] h-[2px] bg-paloverde mx-auto mb-10 mt-2" />
      <div className="mt-[5rem] md:mb-[10rem]">
        {body.map(
          (
            blok,
            index, // eslint-disable-line
          ) => (
            <div
              {...sbEditable(blok)} // eslint-disable-line
              className="md:bg-grey-tini-light"
            >
              <DynamicComponents
                blok={blok}
                key={blok._uid} // eslint-disable-line
                lastChildIndex={body.length - 1}
                index={index}
              />
            </div>
          ),
        )}
      </div>
    </section>
  )
}

export default ZigZagBannersContainer

ZigZagBannersContainer.propTypes = {
  blok: PropTypes.shape({
    _uid: PropTypes.string.isRequired,
    title: PropTypes.string,
    body: PropTypes.arrayOf(
      PropTypes.shape({
        blok: PropTypes.shape({
          _uid: PropTypes.string,
        }),
      }),
    ),
  }),
}

ZigZagBannersContainer.defaultProps = {
  blok: {
    title: "TITLE IS MISSING",
    body: [],
  },
}
