// extracted by mini-css-extract-plugin
export var breakBanner = "style-module--breakBanner--7b398";
export var dataLeft = "style-module--dataLeft--561f7";
export var dataRight = "style-module--dataRight--d9809";
export var helixMobilePlayBtn = "style-module--helixMobilePlayBtn--a2cf3";
export var introData = "style-module--introData--f6952";
export var introDataRight = "style-module--introDataRight--640e8";
export var lcp15Overview = "style-module--lcp15Overview--2cb0b";
export var leftStrike = "style-module--leftStrike--36645";
export var ofset = "style-module--ofset--70480";
export var productHero = "style-module--productHero--4cc00";
export var sandBackg = "style-module--sandBackg--c6ef6";