/* eslint-disable */
import React, { useState } from "react"
import { useEffect } from "react"
import YotpoStarRatings from "../YotpoStarRatings"
import { FaStar, FaDownload, FaThumbsUp, FaThumbsDown } from "react-icons/fa"
const UserQuestions = ({ allQuestions }) => {
  const [questions, setQuestions] = useState()
  useEffect(() => {
    setQuestions(allQuestions)
  }, [allQuestions])
  return (
    <div className="mt-14 flex flex-col gap-2">
      {questions?.length === 0 ? (
        <h1 className="text-[19px]">No Questions</h1>
      ) : (
        questions?.map(item => {
          return (
            <div className="flex flex-col-reverse lg:flex-row">
              {/* left section  */}
              <div className="w-full relative bottom-6 lg:bottom-0 lg:w-[30%] flex flex-col gap-4">
                <div className="flex flex-col leading-[1px]">
                  <p className="text-[15px]"> {item?.asker?.display_name}</p>
                  <p className="text-[12px]">
                    {item?.created_at
                      ? new Date(item.created_at).toLocaleDateString()
                      : ""}
                  </p>
                </div>
              </div>
              {/* Right section  */}
              <div className="w-full">
                <div>
                  <h1 className="text-[13px] text-gray-400 relative bottom-2  ">
                    {item?.content ? item?.content : ""}
                  </h1>
                  <h1 className="text-[19px]">Answers:</h1>
                  {item?.sorted_public_answers.length ? (
                    <div>
                      {item?.sorted_public_answers?.map(answer => (
                        <h1 className="text-[13px] text-gray-400 relative bottom-2 ">
                          {answer.content}
                        </h1>
                      ))}
                    </div>
                  ) : (
                    <h1 className="text-[13px] text-gray-400 relative bottom-2 ">
                      No Answers Yet
                    </h1>
                  )}
                </div>
                <div className="flex justify-between text-[10px] relative bottom-4">
                  <div className="gap-2 hidden ">
                    <div className="mt-[7px]">
                      <FaDownload />
                    </div>
                    <p>Share</p>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      )}
    </div>
  )
}

export default UserQuestions
