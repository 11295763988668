/* eslint-disable */
import React, {
  // useContext,
  useState,
  useEffect,
} from "react"
import "./reconvnd-visualizer.scss"

// <av-product id="my-product" namespace="polarpro-assets" name="pp-product-configurator">
// <av-binding src="https://builds.dopple.io/atlatl-visual-component/releases/current/bindings/shopify/index.js"/>

const ReconVndVisualizer = () => {
  // Builder Rules
  // 1. If no camera is selected, VND (3-6) should be displayed
  // 2. Front defender cannot be added when ultralight hood has been selected
  // 3. Rear defender cannot be added when the camera is selected

  // Options are true and false
  const [showCamera, setShowCamera] = useState(true)

  // Options are 'vnd36Filter' and 'vnd36Filter'
  const [filterSelection, setFilterSelection] = useState("vnd36Filter")

  // Options are 'none', 'closed', and 'open'
  const [hoodMode, setHoodMode] = useState("none")

  // Options are 'none', 'blueMorphic', 'mist', and 'nd16'
  const [stage2Filter, setStage2Filter] = useState("none")

  // Options are true and false
  const [showFrontDef, setShowFrontDef] = useState(false)

  // Options are true and false
  const [showRearDef, setShowRearDef] = useState(false)

  // These functions will handle updating the current selection, and can do anything else you want when a new selection is made too
  function changeShowCamera(value) {
    // console.log('Camera system changed')
    setShowCamera(!showCamera)
  }
  function changeFilterSelection(value) {
    // console.log('Filter setup changed')
    setFilterSelection(value)
  }
  function changeHoodMode(value) {
    // console.log('Hood Mode changed')
    setHoodMode(value)
  }
  function changeStage2Filter(value) {
    // console.log('Filter 2 setup changed')
    setStage2Filter(value)
  }
  function changeShowFrontDef(value) {
    // console.log('Front Defender changed')
    setShowFrontDef(!showFrontDef)
  }
  function changeShowRearDef(value) {
    // console.log('Rear Defender changed')
    setShowRearDef(!showRearDef)
  }

  // tracking all states here
  // useEffect(() => {
  //   console.log('showCamera', showCamera);
  //   console.log('filterSelection', filterSelection);
  //   console.log('hoodMode', hoodMode);
  //   console.log('stage2Filter', stage2Filter);
  //   console.log('showFrontDef', showFrontDef);
  //   console.log('showRearDef', showRearDef);
  // });

  const uiSections = [
    {
      heading: "Camera:",
      subheading: null,
      property: "showCamera",
      mobile: "mobile-hide",
      changeHandler: value => changeShowCamera(value),
      type: "checkbox",
      lockedCondition: null,
      options: [
        {
          name: "Sony A7III",
          mobileName: "",
          thumbnail:
            "https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-A7III.png?v=1671493904",
          isDefault: true, // Since this is a single option and therefore a checkbox, this will set whether or not it's checked by default
        },
        // {
        //   name: 'No Camera',
        //   mobileName: '',
        //   thumbnail: 'https://cdn.shopify.com/s/files/1/1050/9944/files/None-Tab-Image.png?v=1667343818',
        //   value: false
        // }
      ],
    },
    {
      heading: "Select Base:",
      subheading: null,
      property: "filterSelection",
      mobile: "mobile-hide",
      changeHandler: value => changeFilterSelection(value),
      type: "radio",
      options: [
        {
          name: "VND 3-6+ Base",
          mobileName: "VND 3-6+ Base",
          value: "vnd36Filter",
          thumbnail:
            "https://cdn.shopify.com/s/files/1/1050/9944/files/vnd3-6.png?v=1671230874",
          isDefault: true,
        },
        {
          name: "Thread Base",
          mobileName: "Thread Base",
          value: "filterDeleteAdaptor",
          thumbnail:
            "https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-Thread-Base.png?v=1671493902",
        },
      ],
    },
    {
      heading: "Aero-Hood & Flag:",
      subheading: null,
      property: "hoodMode",
      mobile: "mobile-hide",
      changeHandler: value => changeHoodMode(value),
      type: "radio",
      options: [
        {
          name: "None",
          mobileName: "None",
          value: "none",
          thumbnail:
            "https://cdn.shopify.com/s/files/1/1050/9944/files/None-Tab-Image.png?v=1667343818",
          isDefault: true,
        },
        {
          name: "Aero-Hood + Flag Open",
          mobileName: "Flag Open",
          value: "open",
          thumbnail:
            "https://cdn.shopify.com/s/files/1/1050/9944/files/Flag_Open.png?v=1671230900",
        },
        {
          name: "Aero-Hood + Flag Closed",
          mobileName: "Flag Closed",
          value: "closed",
          thumbnail:
            "https://cdn.shopify.com/s/files/1/1050/9944/files/Flag_Closed.png?v=1671230898",
        },
      ],
    },
    {
      heading: "Stage-2 Filters:",
      subheading: "",
      property: "stage2Filter",
      mobile: "mobile-hide",
      changeHandler: value => changeStage2Filter(value),
      type: "radio",
      // lockedCondition: lcpSystem !== 'cinema', // This will "lock" and disable this section's options from being selected if this condition is true
      options: [
        {
          name: "None",
          mobileName: "",
          value: "none",
          thumbnail:
            "https://cdn.shopify.com/s/files/1/1050/9944/files/None-Tab-Image.png?v=1667343818",
          isDefault: true,
        },
        {
          name: "BlueMorphic",
          mobileName: "",
          value: "blueMorphic",
          thumbnail:
            "https://cdn.shopify.com/s/files/1/1050/9944/files/Blue_Morphic.png?v=1671230894",
        },
        // {
        //   name: 'GoldMorphic',
        //   mobileName: '',
        //   value: 'goldMorphic',
        //   thumbnail: 'https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-GoldMorphic.png?v=1671493897',
        // },
        {
          name: "Mist",
          mobileName: "",
          value: "mist",
          thumbnail:
            "https://cdn.shopify.com/s/files/1/1050/9944/files/Mist.png?v=1671230889",
        },
        {
          name: "ND16",
          mobileName: "",
          value: "nd16",
          thumbnail:
            "https://cdn.shopify.com/s/files/1/1050/9944/files/ND16.png?v=1671230884",
        },
      ],
    },
    {
      heading: "Front Protection:",
      subheading: null,
      property: "showFrontDef",
      mobile: "mobile-hide",
      changeHandler: value => changeShowFrontDef(value),
      type: "checkbox",
      lockedCondition: hoodMode === "open" || hoodMode === "closed",
      options: [
        {
          name: "Front Defender",
          mobileName: "",
          thumbnail:
            "https://cdn.shopify.com/s/files/1/1050/9944/files/Front_Defender.png?v=1671230880",
          isDefault: false, // Since this is a single option and therefore a checkbox, this will set whether or not it's checked by default
        },
      ],
    },
    {
      heading: "Rear Protection:",
      subheading: null,
      property: "showRearDef",
      mobile: "mobile-hide",
      changeHandler: value => changeShowRearDef(value),
      type: "checkbox",
      lockedCondition: showCamera === true,
      options: [
        {
          name: "Rear Defender",
          mobileName: "",
          thumbnail:
            "https://cdn.shopify.com/s/files/1/1050/9944/files/Rear_Defender.png?v=1671231064",
          isDefault: false, // Since this is a single option and therefore a checkbox, this will set whether or not it's checked by default
        },
      ],
    },
  ]

  return (
    <div className="hidden md:flex w-10/12 m-auto md:max-h-[1000px] mainDoppleWrapper bg-transparent">
      <atlatl-visual
        id="visual-component"
        client-id="4b823bf2-0204-466c-a1ba-6a79428b1cfa"
        loading-screen-type="spinningCube"
      >
        <av-product namespace="polarpro-assets" name="pp-recon-builder">
          <av-binding src="https://builds.dopple.io/atlatl-visual-component/releases/current/bindings/dom/index.js"></av-binding>
        </av-product>
        <av-native-ui>
          <av-ar native-button></av-ar>
        </av-native-ui>
      </atlatl-visual>

      <div
        className="dopple-selectors-wrapper text-white bg-visualizer-platter-2 overflow-x-scroll overscroll-auto"
        id="dopple-configurator"
      >
        {/* Main heading */}
        <p className="bg-visualizer-platter-2 text-black py-2 px-7 font-black text-lg mb-0 dopple-main-heading">
          Configuration
        </p>
        <div style={{}}>
          {/* Create each section (i.e. each group of radios/checkboxes) with heading above */}
          {uiSections.map((section, sectionIndex) => (
            <div key={sectionIndex} className={`${section.mobile}`}>
              {/* Section heading */}
              <p className="bg-visualizer-grey-2 py-2 text-[13px] text-white mb-0 px-7 font-condensed font-bold mobile-hide">
                {section.heading}
              </p>

              {/* Container for the radio/checkbox options */}
              <div
                className={`visualizer-palette-section p-5`}
                data-av-property={
                  section.type === "radio" ? section.property : null
                }
              >
                {/* Create each radio/checkbox option */}
                {section.options.map((option, optionIndex) => (
                  // This label will serve as the "button" that the user selects but do not style it with CSS
                  <label key={optionIndex}>
                    {/*
                      This input should be visually hidden with the following CSS to keep it accessible:

                      border: 0 !important;
                      clip: rect(0, 0, 0, 0) !important;
                      height: 1px !important;
                      margin: -1px !important;
                      overflow: hidden !important;
                      padding: 0 !important;
                      position: absolute !important;
                      white-space: nowrap !important;
                      width: 1px !important;
                    */}
                    <input
                      type={section.type}
                      name={section.property}
                      data-av-property={
                        section.type === "checkbox" ? section.property : null
                      }
                      data-av-value={
                        section.type === "radio" ? option.value : null
                      }
                      defaultChecked={option.isDefault}
                      onChange={() => section.changeHandler(option.value)}
                      disabled={section.lockedCondition} // Input will be disabled if this section's "locked condition" is true
                    />

                    {/*
                      This span serves as the visible button, and its active/highlighted state can be style like this:

                      input:checked + .visualizer-label {
                        background-color: #F00;
                        color: #FFF;
                      }
                    */}
                    <span className="btn btn-default visualizer-label">
                      {/* This thumbnail image for the option can be positioned however you like */}
                      <img
                        src={option.thumbnail}
                        alt={"Thumbnail for " + option.name}
                        className="selector-icon"
                      />
                      <span className="mobile-hide">{option.name}</span>
                      <span className="mobile-show desktop-hide">
                        {option.mobileName}
                      </span>
                      {/* This lock icon will be displayed only if this section's "locked condition" is true */}
                      <img
                        style={{
                          display: section.lockedCondition ? "initial" : "none",
                        }}
                        src="https://cdn.shopify.com/s/files/1/1050/9944/files/Icon_Lock_2x_ffb5e6cf-d016-4c39-aa59-dc33e4d5784a.png?v=1667422474"
                        alt="Lock icon"
                        className="lock-icon"
                      />
                    </span>
                  </label>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ReconVndVisualizer
