/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { render } from "storyblok-rich-text-react-renderer"
import DynamicComponents from "../components/DynamicComponents"
import { sbEditable } from "@storyblok/storyblok-editable"
import { useStoryblok } from "../utils/storyblok"
import "./techspecs.scss"
import Seo from "../components/seo"
import SpecData from "../components/SpecItem/spec_data"

const TechSpecs = ({ pageContext }) => {
  const { storyblokEntry, product } = pageContext
  const story = storyblokEntry
    ? useStoryblok(storyblokEntry.node)
    : { content: null }

  const components =
    story.content && story.content.components
      ? story.content.components.map(blok => {
        return (
          <SpecData blok={blok} key={blok._uid} />
        )
      })
      : null;
  const sideBarComponents =
    story.content && story.content.SidebarMenuItem
      ? story.content.SidebarMenuItem.map(blok => (
        <>
          {blok ? (
            <div {...sbEditable(blok)}>
              <DynamicComponents blok={blok} key={blok._uid} />
            </div>
          ) : null}
        </>
      ))
      : null

  const specComponents =
    story.content && story.content.SpecData
      ? story.content.SpecData.map(blok => {
        return (
          <>
            {blok ? (
              <div {...sbEditable(blok)}>
                <DynamicComponents blok={blok} key={blok._uid} />
              </div>
            ) : null}
          </>
        )
      })
      : null

  const FAQComponents =
    story.content && story.content.FAQData
      ? story.content.FAQData.map(blok => (
        <>
          {blok ? (
            <div {...sbEditable(blok)}>
              <DynamicComponents blok={blok} key={blok._uid} />
            </div>
          ) : null}
        </>
      ))
      : null

  const { Banner, SidebarLogo } = story.content

  const stickyNavComponent =
    story.content && story.content["sticky_nav"]
      ? story.content["sticky_nav"].map(blok => (
        <>
          {blok ? (
            <div {...sbEditable(blok)} className="sticky top-0 z-50">
              <DynamicComponents
                blok={blok}
                product={product}
                key={blok._uid}
              />
            </div>
          ) : null}
        </>
      ))
      : null

  if (story.content.components?.length) {
    return (
      <>
        <Seo title={story.content?.title} />
        <div className="sticky top-0 z-50">{stickyNavComponent}</div>
        <div className="tech-specs">
          <img src={Banner} className="wideBanner w-full" />
          <div className="techSpecsContent">
            <div className="dimension-container">
              <div className="sideBar">
                <img
                  src={SidebarLogo}
                  className="max-w-[100%] xl:max-w-[300px]"
                />
                <p className="uppercase font-bold">Support Hub</p>
                <ul>
                  {
                    story?.content?.components.map((item, idx) => (
                      <li key={idx} className="font-bold text-sm"  >
                        <a className="whitespace-nowrap" href={`#${item.title}`}>{item.title}</a>
                      </li>
                    ))
                  }
                </ul>
              </div>
              <div>
                {
                  story?.content?.components?.length ? (
                    <div className={story?.content?.components[0]?.component === 'spec_data' ? `specs` : "faq simple_plain  mb-24"} id={story?.content?.components[0].title}>
                      <ul>
                        <li className="sectionHeading list-square font-bold text-2xl ">
                          {story?.content?.components[0].title}
                        </li>
                      </ul>
                      <DynamicComponents blok={story?.content?.components[0]} propClass="border-b-2 border-gray-300 border-opacity-50" />
                    </div>
                  ) : null
                }
              </div>
            </div>

            {
              story?.content?.components?.length ? story?.content?.components?.slice(1)
                ?.map((item, idx) =>
                  <div className={item?.component === 'spec_data' ? `specs !w-full ` : "faq !p-0 !m-0 !mb-0 !pb-0"} id={item.title} key={idx} style={{ background: '#E5E7EB' }}>
                    <div className="flex w-full !mb-0 !pb-20 px-4 md:px-0">
                      <div className="w-auto md:w-[28.8%] h-full">

                      </div>
                      <div className="w-full">
                        <ul>
                          <li className="sectionHeading  mt-[50px] list-square font-bold text-2xl">
                            {item.title}
                          </li>
                        </ul>
                        <DynamicComponents blok={item} propClass="border-b-2  border-gray-300 border-opacity-50" />
                      </div>
                    </div>
                  </div>) : null
            }
          </div>
        </div >
      </>
    )
  }

  return (
    <>
      <Seo title={story.content?.title} />
      <div className="sticky top-0 z-50">{stickyNavComponent}</div>
      <div className="tech-specs">
        <img src={Banner} className="wideBanner w-full" />
        <div className="techSpecsContent">
          <div className="dimension-container">
            <div className="sideBar">
              <img
                src={SidebarLogo}
                className="max-w-[100%] xl:max-w-[300px]"
              />
              <p className="uppercase font-bold">Support Hub</p>
              <ul>{sideBarComponents}</ul>
            </div>
            <div className="specs" id="specs">
              <ul>
                <li className="sectionHeading list-square font-bold text-2xl">
                  Dimension / Specs
                </li>
              </ul>
              {specComponents}
            </div>
          </div>
          <div className="faq" id="faq">
            <ul>
              <li className="sectionHeading list-square font-bold  text-2xl">
                FAQ
              </li>
            </ul>
            {/* <div className='item'>FAQ item goes here</div> */}
            {FAQComponents}
          </div>
        </div>
      </div>
    </>
  )
}

export default TechSpecs
