/* eslint-disable */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import "./style.scss"

import loadable from "@loadable/component"
import useScrollBlock from "../../hooks/useScrollBlock"

const ModalVideo = loadable(() => import("react-modal-video"))

const LegacyOverview = ({ blok, product }) => {
  const [showVideo, setShowVideo] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()

  const [heroIcon1, setHeroIcon1] = useState("")
  const [heroIcon1Text, setHeroIcon1Text] = useState("")
  const [heroIcon2, setHeroIcon2] = useState("")
  const [heroIcon2Text, setHeroIcon2Text] = useState("")
  const [heroIcon3, setHeroIcon3] = useState("")
  const [heroIcon3Text, setHeroIcon3Text] = useState("")

  const [heroImage1, setHeroImage1] = useState("")
  const [heroImage1Position, setHeroImage1Position] = useState("")
  const [heroVideoImage, setHeroVideoImage] = useState("")
  const [heroVideoUrl, setHeroVideoUrl] = useState("")

  const [heroImage2, setHeroImage2] = useState("")
  const [heroImage2Position, setHeroImage2Position] = useState("")
  const [heroImage3, setHeroImage3] = useState("")
  const [heroImage3Position, setHeroImage3Position] = useState("")
  const [heroMobileImage1, setHeroMobileImage1] = useState("")

  const [heroVideoImageMobile, setHeroVideoImageMobile] = useState("")
  const [heroMobileImage2, setHeroMobileImage2] = useState("")
  const [heroMobileImage3, setHeroMobileImage3] = useState("")

  const [heroBlock1Title, setHeroBlock1Title] = useState("")
  const [heroBlock1Text, setHeroBlock1Text] = useState("")
  const [heroBlock2Title, setHeroBlock2Title] = useState("")
  const [heroBlock2Text, setHeroBlock2Text] = useState("")
  const [heroBlock3Title, setHeroBlock3Title] = useState("")
  const [heroBlock3Text, setHeroBlock3Text] = useState("")
  const [heroSectionSliderTitle, setHeroSectionSliderTitle] = useState("")
  const [heroSliderEmbed, setHeroSliderEmbed] = useState("")
  const [whatsIncluded, setWhatsIncluded] = useState("")
  const [whatsIncludedMobile, setWhatsIncludedMobile] = useState("")

  const [heroSectionTitle2, setHeroSectionTitle2] = useState("")

  const {
    title,
    // badges,
  } = blok

  const { metafields } = product

  useEffect(() => {
    if (showVideo) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [showVideo])

  useEffect(() => {
    metafields.forEach(metafield => {
      const { key, value } = metafield
      if (key === "hero-icon-1") setHeroIcon1(value)
      else if (key === "hero-icon-1-text") setHeroIcon1Text(value)
      else if (key === "hero-icon-2") setHeroIcon2(value)
      else if (key === "hero-icon-2-text") setHeroIcon2Text(value)
      else if (key === "hero-icon-3") setHeroIcon3(value)
      else if (key === "hero-icon-3-text") setHeroIcon3Text(value)
      else if (key === "hero-image-1") setHeroImage1(value)
      else if (key === "hero-image-2") setHeroImage2(value)
      else if (key === "hero-image-3") setHeroImage3(value)
      else if (key === "hero-image-1-position") setHeroImage1Position(value)
      else if (key === "hero-image-2-position") setHeroImage2Position(value)
      else if (key === "hero-image-3-position") setHeroImage3Position(value)
      else if (key === "hero-video-image") setHeroVideoImage(value)
      else if (key === "hero-video-url") setHeroVideoUrl(value)
      else if (key === "hero-mobile-image-1") setHeroMobileImage1(value)
      else if (key === "hero-video-image-mobile") setHeroVideoImageMobile(value)
      else if (key === "hero-mobile-image-2") setHeroMobileImage2(value)
      else if (key === "hero-mobile-image-3") setHeroMobileImage3(value)
      else if (key === "hero-block-1-title") setHeroBlock1Title(value)
      else if (key === "hero-block-1-text") setHeroBlock1Text(value)
      else if (key === "hero-block-2-title") setHeroBlock2Title(value)
      else if (key === "hero-block-2-text") setHeroBlock2Text(value)
      else if (key === "hero-block-3-title") setHeroBlock3Title(value)
      else if (key === "hero-block-3-text") setHeroBlock3Text(value)
      else if (key === "hero-sections-slider-title")
        setHeroSectionSliderTitle(value)
      else if (key === "hero-slider-embed") setHeroSliderEmbed(value)
      else if (key === "whats-included") setWhatsIncluded(value)
      else if (key === "whats-included-mobile") setWhatsIncludedMobile(value)
      else if (key === "hero-section-title-2") setHeroSectionTitle2(value)
    })
  }, [])

  return (
    <section className="product-overview-hero-9">
      <div class="large--one-whole">
        <p className="display-font text-[18px] display-font-logo font-bold text-obsidian text-center underline underline-offset-8 decoration-[#c99e1a] decoration-2 mb-20">
          {title}
        </p>
        {heroIcon1.length > 0 ? (
          <div class="hero-features-banner">
            <div class="grid-uniform" style={{ margin: "0 auto" }}>
              <div class="grid__item large--one-third">
                <img
                  src={heroIcon1}
                  alt={heroIcon1Text}
                  width="150"
                  height="150"
                />
                {heroIcon1Text}
              </div>
              <div class="grid__item large--one-third">
                <img
                  src={heroIcon2}
                  alt={heroIcon2Text}
                  width="150"
                  height="150"
                />
                {heroIcon2Text}
              </div>
              <div class="grid__item large--one-third">
                <img
                  src={heroIcon3}
                  alt={heroIcon3Text}
                  width="150"
                  height="150"
                />
                {heroIcon3Text}
              </div>
            </div>
          </div>
        ) : null}
        <div class="banners-container">
          <div
            class="banner-overview banner-1"
            style={{
              backgroundImage: `url("${heroImage1}")`,
              backgroundPosition: heroImage1Position,
            }}
          ></div>
          {heroVideoUrl.length > 0 ? (
            <div class="video-feature">
              <div
                class="video-container elements-video"
                style={{ backgroundImage: `url("${heroVideoImage}")` }}
              >
                <a
                  id="inline"
                  href="#data"
                  class="cta-round play-button x-y-center"
                  onClick={e => {
                    e.preventDefault()
                    setShowVideo(true)
                  }}
                />

                {/* modal */}
                <ModalVideo
                  channel="custom"
                  // videoId="LA62Zx7tZIY"
                  url={heroVideoUrl}
                  isOpen={showVideo}
                  onClose={() => setShowVideo(false)}
                />
                {/* <div style={{ display: "none" }}>
                  <div id="data" style={{ backgroundColor: 'black !important', padding: '0vw 0vw', width: '90vw' }}>
                    <iframe src={heroVideoUrl}
                      width="640" height="360"
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowFullscreen>
                    </iframe>
                  </div>
                </div> */}
              </div>
            </div>
          ) : (
            <div
              class="banner-overview banner-2"
              style={{
                backgroundImage: `url("${heroImage2}")`,
                backgroundPosition: heroImage2Position,
              }}
            ></div>
          )}
          <div
            class="banner-overview banner-3"
            style={{
              backgroundImage: `url("${heroImage3}")`,
              backgroundPosition: heroImage3Position,
            }}
          ></div>
        </div>
        <div class="banners-container-mobile">
          <div
            class="banner-overview banner-1"
            style={{ backgroundImage: `url("${heroMobileImage1}")` }}
          >
            <div class="block-copy">
              <h4>{heroBlock1Title}</h4>
              <p>{heroBlock1Text}</p>
            </div>
          </div>
          {heroVideoUrl.length > 0 ? (
            <div class="video-feature">
              <div
                class="video-container elements-video"
                style={{ backgroundImage: `url('${heroVideoImageMobile}')` }}
              >
                <a
                  id="inline"
                  href="#data"
                  class="cta-round play-button x-y-center"
                  onClick={e => {
                    e.preventDefault()
                    setShowVideo(true)
                  }}
                />
                {/* modal */}
                <ModalVideo
                  channel="custom"
                  // videoId="LA62Zx7tZIY"
                  url={heroVideoUrl}
                  isOpen={showVideo}
                  onClose={() => setShowVideo(false)}
                />
                {/* <!-- Modal --> */}
                {/* <div style={{ display: "none" }}>
                  <div id="data" style={{ backgroundColor: 'black !important', padding: '0vw 0vw', width: '90vw' }}>
                    <iframe src={heroVideoUrl}
                      width="640" height="360"
                      frameborder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowfullscreen>
                    </iframe>
                  </div>
                </div> */}
              </div>
            </div>
          ) : (
            <div
              class="banner-overview banner-2"
              style={{ backgroundImage: `url('${heroMobileImage2}')` }}
            >
              <div class="block-copy">
                <h4>{heroBlock2Title}</h4>
                <p>{heroBlock2Text}</p>
              </div>
            </div>
          )}
          <div
            class="banner-overview"
            style={{ backgroundImage: `url('${heroMobileImage3}')` }}
          >
            <div class="block-copy">
              <h4>{heroBlock3Title}</h4>
              <p>{heroBlock3Text}</p>
            </div>
          </div>
        </div>
        {heroSectionSliderTitle && heroSliderEmbed && (
          <>
            <h3>{heroSectionSliderTitle}</h3>
            <div class="slider-wrapper">{heroSliderEmbed}</div>
          </>
        )}
        {whatsIncluded && whatsIncludedMobile && (
          <>
            <h3 class="hero-mt">{heroSectionTitle2}</h3>
            <div class="banner-overview hero-included desktop-view">
              <img src={whatsIncluded} alt="" width="2240" height="540" />
            </div>
            <div class="banner-overview hero-included mobile-view">
              <img src={whatsIncludedMobile} alt="" width="2240" height="540" />
            </div>
          </>
        )}
      </div>
    </section>
  )
}

export default LegacyOverview
