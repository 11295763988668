/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"
import { sbEditable } from "@storyblok/storyblok-editable"
import DynamicComponents from "../DynamicComponents"
import PolarProGoldenLogo from "../../assets/PolarProGoldenLogo"
import * as footerStyles from "./footer.module.scss"

const Footer = ({ story }) => {
  /* eslint-disable-next-line */
  const SBFooterSection =
    story.content && story.content.footer_section
      ? story.content.footer_section.map(blok => (
        <div>
          {blok ? (
            <div {...sbEditable(blok)}>
              {" "}
              {/* eslint-disable-line */}
              <DynamicComponents blok={blok} key={blok._uid} />{" "}
              {/* eslint-disable-line */}
            </div>
          ) : null}
        </div>
      ))
      : null

  return (
    <footer className="relative px-4 flex flex-col">
      <div className="hidden md:block border-b-[1px] border-[#686868] w-3/4 mx-auto mb-16">
        <div className="flex justify-center mb-16">
          <PolarProGoldenLogo width={80} />
        </div>
      </div>
      <div
        className={`${footerStyles.footerWrapper} relative flex flex-col md:flex-row justify-center md:flex-wrap`}
      >
        {SBFooterSection}
      </div>
      <p className="text-white font-bold text-xs mx-auto mt-16 bottom-2 right-0 left-0">
        &copy; 2024 PolarPro. All Rights Reserved.
      </p>
    </footer>
  )
}

export default Footer

Footer.propTypes = {
  story: PropTypes.shape({
    content: PropTypes.shape({
      footer_section: PropTypes.arrayOf(
        PropTypes.shape({
          blok: PropTypes.string,
        }),
      ),
    }),
  }),
}

Footer.defaultProps = {
  story: {
    content: "",
  },
}
