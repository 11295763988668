/* eslint-disable */

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from "react"

import { Helmet } from "react-helmet"
import { AuthProvider } from "./src/context/AuthContext" // eslint-disable-line
import { NewUserContextProvider } from "./src/context/NewUserContext" // eslint-disable-line
import { StoreProvider } from "./src/context/StoreContext"
import { LiteChaserProvider } from "./src/context/LiteChaserContext"
// import { CurrencyExchange } from "./src/context/CurrencyExchange"
import "./src/styles/style.css"
import "./src/styles/variables.css"
import Layout from "./src/components/layout/layout"
import "leaflet/dist/leaflet.css"
export const wrapRootElement = ({ element }) => (
  // <CurrencyExchange>
  <AuthProvider>
    <StoreProvider>
      <NewUserContextProvider>
        <LiteChaserProvider>{element}</LiteChaserProvider>
      </NewUserContextProvider>
    </StoreProvider>
  </AuthProvider>
  // </CurrencyExchange>
)
/* eslint-disable */
export const wrapPageElement = ({ element, props }) => {
  return (
    <Layout {...props}>
      <Helmet>
        <link
          rel="preconnect dns-prefetch"
          href="https://api.config-security.com/"
          crossorigin
        />
        <link
          rel="preconnect dns-prefetch"
          href="https://conf.config-security.com/"
          crossorigin
        />
        <link
          rel="preconnect dns-prefetch"
          href="https://whale.camera/"
          crossorigin
        />
        {/* <!-- Refersion Tracking --> */}
        <script>
          {`

! function(e, n, t, i, o, c, s, a) {
  e.TrackingSystemObject = "r", (s = n.createElement(t)).async = 1, s.src = "https://cdn.refersion.com/refersion.js", s.onload = function() {

      // Replace with your Refersion Public API Key
      r.pubKey = "pub_d084fac14148c7471dbf";

      // Uncomment next line if you need to debug during testing
      // r.settings.dbg_mode = true;

      r.settings.fp_off = true;

      r.initializeXDLS().then(() => {
          r.launchDefault().then(() => {

              // Send a custom  event that can be listened to later
              const rfsnTrackingEvent = new Event("refersion-loaded");
              document.dispatchEvent(rfsnTrackingEvent);
          })
      })
  }, (a = n.getElementsByTagName(t)[0]).parentNode.insertBefore(s, a)
}(window, document, "script");

    `}
        </script>
        <script>
          {`
          function SendTrackingToRefersion(checkout_token) {
            const rfsn = {
                cart: checkout_token,
                id: localStorage.getItem("rfsn_v4_id"),
                url: window.location.href,
                aid: localStorage.getItem("rfsn_v4_aid"),
                cs: localStorage.getItem("rfsn_v4_cs")
            };
            r.sendCheckoutEvent(rfsn.cart, rfsn.id, rfsn.url, rfsn.aid, rfsn.cs);
        }
          `}
        </script>
        {/* <!-- End Refersion Tracking --> */}
        {/* one trust */}
        {/* <!-- OneTrust Cookies Consent Notice start for polarpro.com --> */}
        <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charset="UTF-8"
          data-domain-script="f8244501-cf9f-4b5a-b93c-7ef05562995b"
        ></script>
        <script type="text/javascript">
          {`
            function OptanonWrapper() { }
          `}
        </script>
        {/* <!-- OneTrust Cookies Consent Notice end for polarpro.com --> */}
        {/* one trust end */}

        {/* Reddit Pixel updated 7/6 */}
        <script>
          {`
            !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','t2_506ju8lz', {"optOut":false,"useDecimalCurrencyValues":true,"aaid":"<AAID-HERE>","email":"<EMAIL-HERE>","externalId":"<EXTERNAL-ID-HERE>"});rdt('track', 'PageVisit');
          `}
        </script>
        {/* End Reddit Pixel */}

        {/* triple pixel */}
        <script>
          {`
            TripleHeadless = 'polarpro.myshopify.com'
            ~function(W,H,A,L,E,_){function O(T,R){void 0===R&&(R=!1),H=new XMLHttpRequest,H.open("GET","//triplewhale-pixel.web.app/triplepx.txt?"+~(Date.now()/9e7),!0),H.send(null),H.onreadystatechange=function(){4===H.readyState&&200===H.status?setTimeout(function(){return eval(H.responseText)},50):(299<H.status||H.status<200)&&T&&!R&&(R=!0,O(T-1))}}if(L=window,!L[H+"sn"]){L[H+"sn"]=1;try{A.setItem(H,1+(0|A.getItem(H)||0)),W.includes("⇈")&&A.setItem(H+"Stg",1+(0|A.getItem(H+"Stg")||0)),(E=JSON.parse(A.getItem(H+"U")||"[]")).push(location.href),A.setItem(H+"U",JSON.stringify(E))}catch(e){}A.getItem('"!nC\`')||(A=L,A[H]||(L=function(){return Date.now().toString(36)+"_"+Math.random().toString(36)},E=A[H]=function(t,e){return W=L(),(E._q=E._q||[]).push([W,t,e]),E._headless=!0,W},E.ch=W,O(5)))}}("","TriplePixel",localStorage);
          `}
        </script>
        {/* End triple pixel */}

        {/* triple pixel new */}
        <script>
          {`window.TriplePixelData={TripleName:"polarpro.myshopify.com",ver:"2.12",plat:"SHOPIFY",isHeadless:true},function(W,H,A,L,E,_,B,N){function O(U,T,P,H,R){void 0===R&&(R=!1),H=new XMLHttpRequest,P?(H.open("POST",U,!0),H.setRequestHeader("Content-Type","text/plain")):H.open("GET",U,!0),H.send(JSON.stringify(P||{})),H.onreadystatechange=function(){4===H.readyState&&200===H.status?(R=H.responseText,U.includes(".txt")?eval(R):P||(N[B]=R)):(299<H.status||H.status<200)&&T&&!R&&(R=!0,O(U,T-1,P))}}if(N=window,!N[H+"sn"]){N[H+"sn"]=1,L=function(){return Date.now().toString(36)+"_"+Math.random().toString(36)};try{A.setItem(H,1+(0|A.getItem(H)||0)),(E=JSON.parse(A.getItem(H+"U")||"[]")).push({u:location.href,r:document.referrer,t:Date.now(),id:L()}),A.setItem(H+"U",JSON.stringify(E))}catch(e){}var i,m,p;A.getItem('"!nC\`')||(_=A,A=N,A[H]||(E=A[H]=function(t,e,a){return void 0===a&&(a=[]),"State"==t?E.s:(W=L(),(E._q=E._q||[]).push([W,t,e].concat(a)),W)},E.s="Installed",E._q=[],E.ch=W,B="configSecurityConfModel",N[B]=1,O("https://conf.config-security.com/model",5),i=L(),m=A[atob("c2NyZWVu")],_.setItem("di_pmt_wt",i),p={id:i,action:"profile",avatar:_.getItem("auth-security_rand_salt_"),time:m[atob("d2lkdGg=")]+":"+m[atob("aGVpZ2h0")],host:A.TriplePixelData.TripleName,plat:A.TriplePixelData.plat,url:window.location.href,ref:document.referrer,ver:A.TriplePixelData.ver},O("https://api.config-security.com/event",5,p),O("https://whale.camera/live/dot.txt",5)))}}("","TriplePixel",localStorage);`}
        </script>
        {/* triple pixel new end */}
        {/* Google and FB Domain Verification */}
        <meta
          name="google-site-verification"
          content="5O5sV7n7nRWq-CFdjVntz9r-AYwxAX51Im7USdICkK8"
        />
        {/* polarpro.com */}
        <meta
          name="facebook-domain-verification"
          content="bre62s4gyknt5b5o8983m27296mb3m"
        />
        {/* End Domain Verification */}

        {/* Facebook Meta Pixel Code */}
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1668433226782895');
            fbq('track', 'PageView');
          `}
          {/* fbq('track', 'ViewContent', {value: 'placeholder', currency: 'USD', content_name: 'placeholder', content_type: 'placeholder', content_category: 'placeholder'}); */}
        </script>
        <noscript>
          {'<img height="1" width="1" style="display:none"/>'}
        </noscript>
        {/* End Facebook Meta Pixel Code */}

        {/* TikTok Pixel Code */}
        <script>
          {`
            !function (w, d, t) {
              w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
              ttq.load('CF47NRRC77U7HEM9A3E0');
              ttq.page();
            }(window, document, 'ttq');
          `}
        </script>
        {/* End TikTok Pixel Code */}

        {/* UpPromote Affiliate Marketing Tracking Code */}
        <script
          type="text/javascript"
          src="https://af.uppromote.com/tracking_third_party.js?shop=polarpro.myshopify.com"
        ></script>
        {/* End UpPromote Affiliate Marketing Tracking Code */}

        {/* dopple.io Visual Component CDN Script */}
        <script src="https://builds.dopple.io/atlatl-visual-component/releases/current/index.js"></script>
        {/* End dopple.io Visual Component CDN Script */}

        {/* script for mntn */}
        {/* <!--MNTN Tracking Pixel--> */}
        {/* <!-- INSTALL ON ALL PAGES OF SITE--> */}
        <script type="text/javascript">
          {`
          (function(){"use strict";var e=null,b="4.0.0",
          n="34422",
          additional="term=value",
          t,r,i;try{t=top.document.referer!==""?encodeURIComponent(top.document.referrer.substring(0,2048)):""}catch(o){t=document.referrer!==null?document.referrer.toString().substring(0,2048):""}try{r=window&&window.top&&document.location&&window.top.location===document.location?document.location:window&&window.top&&window.top.location&&""!==window.top.location?window.top.location:document.location}catch(u){r=document.location}try{i=parent.location.href!==""?encodeURIComponent(parent.location.href.toString().substring(0,2048)):""}catch(a){try{i=r!==null?encodeURIComponent(r.toString().substring(0,2048)):""}catch(f){i=""}}var l,c=document.createElement("script"),h=null,p=document.getElementsByTagName("script"),d=Number(p.length)-1,v=document.getElementsByTagName("script")[d];if(typeof l==="undefined"){l=Math.floor(Math.random()*1e17)}h="dx.mountain.com/spx?"+"dxver="+b+"&shaid="+n+"&tdr="+t+"&plh="+i+"&cb="+l+additional;c.type="text/javascript";c.src=("https:"===document.location.protocol?"https://":"http://")+h;v.parentNode.insertBefore(c,v)})()
          `}
        </script>
        <script
          async
          type="text/javascript"
          src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=LFDYCZ"
        ></script>
        {/* END script for mntn */}
        <script type="text/javascript">
          {`
            (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
            {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
            a.queue=[];var s=script;r=t.createElement(s);r.async=!0;
            r.src=n;var u=t.getElementsByTagName(s)[0];
            u.parentNode.insertBefore(r,u);})(window,document,
            https://sc-static.net/scevent.min.js);
            snaptr(init, "ea13bd56-e55a-49a9-9013-c285553c31dd", {
            user_email: __INSERT_USER_EMAIL__
            });
            snaptr(track, PAGE_VIEW)
         `}
        </script>
      </Helmet>
      {/* start */}
      <Helmet>
        <script type="text/javascript">
          {`
    _linkedin_partner_id = "1092876"; 
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    `}
        </script>

        <script type="text/javascript">
          {`
    (function(l) {
      if (!l) {
        window.lintrk = function(a, b) {
          window.lintrk.q.push([a, b]);
        };
        window.lintrk.q = [];
      }
      var s = document.getElementsByTagName("script")[0];
      var b = document.createElement("script");
      b.type = "text/javascript";
      b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode.insertBefore(b, s);
    })(window.lintrk);
    `}
        </script>
        <noscript>
          {`
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            alt=""
            src="https://px.ads.linkedin.com/collect/?pid=1092876&fmt=gif"
          />
          `}
        </noscript>
      </Helmet>
      {element}
    </Layout>
  )
}
