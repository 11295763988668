import React, { useRef } from "react"
import Carousel from "react-multi-carousel"
// import { ButtonGroupProps, ArrowProps, DotProps } from 'react-multi-carousel/lib/types';
import * as style from "./style.module.scss"
import { responsive } from "./settings.json"

import FeaturedProductImagesDot from "../FeaturedProductImagesDot"

const LoginBanner = () => {
  const carousel = useRef(null)

  return (
    <div className={style.profileSliderContainer}>
      <Carousel
        arrows
        autoPlay
        autoPlaySpeed={2500}
        responsive={responsive}
        showDots
        customDot={<FeaturedProductImagesDot mainCarousel={carousel} />}
        focusOnSelect
        swipeable
        draggable
        centerMode={false}
        infinite
        ref={carousel}
        className="h-full"
      >
        {/* <div id="profile-slider" className={style.owlCarousel}> */}
        <div className={style.item}>
          <div className={style.ppSliderLayered}>
            <div
              className={`${style.slideContent} ${style.polarproDividend}`}
              style={{ backgroundSize: "cover" }}
            />
            {/* </div> */}
          </div>
        </div>
        <div className={style.item}>
          <div className={style.ppSliderLayered}>
            <div
              className={`${style.slideContent} ${style.polarproReferals}`}
              style={{ backgroundPosition: "center", backgroundSize: "cover" }}
            />
            {/* </div> */}
          </div>
        </div>
        <div className={style.item}>
          <div className={style.ppSliderLayered}>
            <div
              className={`${style.slideContent} ${style.polarproMemberPriority}`}
              style={{ backgroundPosition: "center", backgroundSize: "cover" }}
            />
            {/* </div> */}
          </div>
        </div>
        {/* </div> */}
      </Carousel>
    </div>
  )
}

export default LoginBanner
