import React from 'react';
import FAQItem from '../FAQItem';
import FAQVideo from "../FAQVideo"
import './index.scss'

const FAQData = ({ blok }) => {
  const { faq_item } = blok;
  if (faq_item?.length) {
    return (
        <>
        {
            faq_item.map((blok) => {
            if (blok?.component === "FAQVideo") {
              return <FAQVideo blok={blok} key={blok.uid} />
            }
            return (<FAQItem blok={blok} key={blok.uid} />)
            })
        }
        </>
    )
  }
    return null;
}

export default FAQData;
