/* eslint-disable max-len */
import React from "react"

const ElementsExchangeNotice = () => (
  <div id="clothing-exchanges-modal">
    <div className="wrapper">
      <h1>Returns & Exchanges</h1>
      <hr />
      <p>
        All PolarPro Elements Collection apparel can be returned or exchanged
        for a different size. For <u>U.S. customers</u>, exchanges are free.
        Returns have a $5 flat-rate return postage fee. <u>International</u>{" "}
        customers are responsible for return shipping costs. Note, this may be
        very expensive from some countries. Please send your gear within
        60-days. All items must be kept in new/unused condition with the tags
        still attached in order to be processed for a return or exchange.
      </p>
    </div>
    <div className="wrapper">
      <h1>AdventureAssurance&trade;</h1>
      <hr />
      <p>
        Be confident in your PolarPro gear throughout all your adventures.
        AdventureAssurance&trade; is our commitment that our products are built
        to last and we will stand behind them for any manufacture defects in
        materials or workmanship.
      </p>
    </div>
  </div>
)

export default ElementsExchangeNotice
