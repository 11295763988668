/* eslint-disable */

/* eslint-disable react/prop-types */
import React from "react"
import { Link, graphql } from "gatsby"
import { useLocation } from "@reach/router"

const ProductsPage = ({ data }) => {
  const location = useLocation()

  return (
    <>
      <h1>Products</h1>
      <ul>
        {data.allShopifyProduct.edges.map(({ node }) => (
          <li key={node.shopifyId}>
            <h3>
              <Link
                to={`/products/${node.handle}/${
                  location.search ? location.search : ""
                }`}
              >
                {node.title}
              </Link>
              {" - "}${node.priceRangeV2.minVariantPrice.amount}
            </h3>
            <p>{node.description}</p>
          </li>
        ))}
      </ul>
    </>
  )
}
export default ProductsPage
export const query = graphql`
  {
    allShopifyProduct {
      edges {
        node {
          handle
          description
          priceRangeV2 {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
          productType
          title
          tags
        }
      }
    }
  }
`
