import React, { useState, useEffect } from "react"
// import { useCurrencyExchange } from "../../context/CurrencyExchange"
import axios from "axios"
import { productEndPointUrl } from "../../config"

function ProductCardPrice(product: any) {
  // const { userCountry } = useCurrencyExchange()
  // const [countryCurrency, setCountryCurrency] = useState("")
  // const [lowestComparePrice, setLowestComparePrice] = useState(null)
  // const [productPrice, setProductPrice] = useState(null)
  // const [productLength, setproductLength] = useState(0)

  // const currencyProductsSetter = async () => {
  //   try {
  //     const response = await axios.post(productEndPointUrl, {
  //       country: userCountry?.cca2,
  //       productId: product?.product?.shopifyId,
  //     })
  //     if (response?.data?.product) {
  //       const firstPrice =
  //         response.data?.product?.data?.product?.variants?.edges?.filter(
  //           (variant: any) => {
  //             return variant.node.price.amount
  //           },
  //         )?.[0]?.node?.price?.amount
  //       if (firstPrice) {
  //         const tempLowestPrice =
  //           response.data?.product?.data?.product?.variants?.edges?.reduce(
  //             (acc: any, variant: any) => {
  //               if (parseFloat(variant.node.price?.amount || acc + 1) < acc) {
  //                 return variant.node.price?.amount
  //               }
  //               return acc
  //             },
  //             parseFloat(firstPrice),
  //           )
  //         setProductPrice(tempLowestPrice)
  //       } else {
  //         setProductPrice(
  //           response.data?.product?.data?.product?.variants?.edges?.[0]?.node
  //             ?.price?.amount,
  //         )
  //       }
  //       setCountryCurrency(
  //         response.data?.product?.data?.product?.variants?.edges?.[0]?.node
  //           ?.price?.currencyCode,
  //       )
  //       const firstComparePrice =
  //         response.data?.product?.data?.product?.variants?.edges?.filter(
  //           (variant: any) => {
  //             return variant?.node?.compareAtPrice?.amount
  //           },
  //         )?.[0]?.node?.compareAtPrice?.amount
  //       if (firstComparePrice) {
  //         const tempLowestComparePrice =
  //           response.data?.product?.data?.product?.variants?.edges?.reduce(
  //             (acc: any, variant: any) => {
  //               if (
  //                 parseFloat(variant.node.compareAtPrice?.amount || acc + 1) <
  //                 acc
  //               ) {
  //                 return variant.node.compareAtPrice?.amount
  //               }
  //               return acc
  //             },
  //             parseFloat(firstComparePrice),
  //           )
  //         setLowestComparePrice(tempLowestComparePrice)
  //       } else {
  //         setLowestComparePrice(
  //           response.data?.product?.data?.product?.variants?.edges?.[0]?.node
  //             ?.compareAtPrice?.amount,
  //         )
  //       }

  //       setproductLength(
  //         response.data?.product?.data?.product?.variants?.edges?.length,
  //       )
  //     }
  //   } catch (err: any) {}
  // }

  // useEffect(() => {
  //   if (userCountry?.cca2) {
  //     currencyProductsSetter()
  //   }
  // }, [userCountry])

  let lowestCompareAmount = ""

  if (product.product.variants) {
    let lowestPricedVariant = product.product.variants[0]

    product.product.variants.forEach((variant: any) => {
      if (!lowestPricedVariant.compareAtPrice) {
        lowestPricedVariant = variant
      } else if (variant.compareAtPrice) {
        if (parseFloat(variant.price) < parseFloat(lowestPricedVariant.price)) {
          lowestPricedVariant = variant
        }
      }
    })

    if (lowestPricedVariant.compareAtPrice)
      lowestCompareAmount = `$${lowestPricedVariant.compareAtPrice}`
  }

  return (
    <div>
      <p className="text-[.8rem] text-[#1C1C1C] font-bold font-condensed mb-0">
        {/* {`${productLength > 1 ? "Starting at" : ""} `}
        {productPrice ? (
          <>
            {countryCurrency === "USD" ? "$" : countryCurrency + " "}
            {productPrice}
          </>
        ) : (
          <>
            {product?.variants?.length > 1 ? "Starting at" : ""} &nbsp; $
            {product?.product.priceRangeV2?.minVariantPrice?.amount}
          </>
        )} */}
        {product?.variants?.length > 1 ? "Starting at" : ""} &nbsp; $
        {product?.product.priceRangeV2?.minVariantPrice?.amount}
        <span className="line-through pl-2 italic text-[#ababab]">
          {/* {!!lowestComparePrice ? (
            <>
              {countryCurrency === "USD" ? "$" : countryCurrency + " "}
              {lowestComparePrice}
            </>
          ) : (
            <>{lowestCompareAmount}</>
          )} */}
          {lowestCompareAmount}
        </span>
      </p>
    </div>
  )
}

export default ProductCardPrice
