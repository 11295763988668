/* eslint-disable */
// @ts-nocheck

import { Link } from "gatsby"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { FaSearch, FaShoppingBasket, FaUserCircle } from "react-icons/fa"
import { AiOutlineClose } from "react-icons/ai"
import { StoreContext } from "../../context/StoreContext"
import WrittenLogoMark from "../Icons/WrittenLogoMark"
import SelectedDropDownItem from "./selectedDropDownItem"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"
import useCheckout from "../../hooks/useCheckout"
import axois from "axios"
import debounce from "lodash.debounce"
import { functionsEndpoint } from "../../config"
import "./index.css"
import DefaultSearch from "./defaultSearch"

const NavBarV2 = ({
  isCartOpen,
  setIsCartOpen,
  story,
  onMenuClick,
  selectedItem,
  setSelectedItem,
}: any) => {
  const [isSearchExpanded, setIsSearchExpanded] = useState(false)
  const location = useLocation()

  const { setAddToCartSidebarTypeFunction, openAddToCartSidebar } =
    useContext(StoreContext)
  const [searchValue, setSearchValue] = useState("")
  const [mobileSearchValue, setMobileSearchValue] = useState("")
  const [searchResults, setSearchResults] = useState([])
  const [searchLoading, setSearchLoading] = useState(false)
  const [searchCompleted, setSearchCompleted] = useState(false)
  const [noResults, setNoResults] = useState(false)
  const [error, setError] = useState(null)
  const [searchMenu, setSearchMenu] = useState(false)
  const [mobileResults, setMobileResults] = useState([])
  const [showDefaultSearch, setShowDefaultSearch] = useState(false)
  const sub_nav_bar_shop = story?.content?.sub_nav_bar_shop
  const timerRef = useRef(null)
  const loadingRef = useRef(null)
  const content = useMemo(() => {
    if (selectedItem === -1) return null
    const shopAll = sub_nav_bar_shop[selectedItem]?.shopAll
    return {
      selectedContent: sub_nav_bar_shop[selectedItem].menuItems,
      columns: sub_nav_bar_shop[selectedItem].columns,
      shopAll,
      bannerFileName: sub_nav_bar_shop[selectedItem]?.banner?.filename,
    }
  }, [selectedItem, sub_nav_bar_shop])

  useEffect(() => {
    setShowDefaultSearch(
      story?.content?.defaultSearchString?.length && !mobileSearchValue?.length,
    )
  }, [story?.content?.defaultSearchString, mobileSearchValue])

  let selectedContent: any = null
  let shopAll: any = null
  let columns = 3
  if (content?.selectedContent) {
    selectedContent = content.selectedContent
    shopAll = content.shopAll
    columns = parseInt(content.columns || "0") || 3
  }
  const { checkout } = useCheckout()

  const handleDebouncedSearch = async (q = "") => {
    setSearchCompleted(false)
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    if (loadingRef.current) {
      clearTimeout(loadingRef.current)
    }
    try {
      const response = await axois.post(
        functionsEndpoint + "/search",
        { q, limit: 5 },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )

      if (response.status !== 200) {
        setSearchResults([])
        return
      }

      const suggestions = response.data
      setSearchResults(suggestions)
      setMobileResults(suggestions)
    } catch (err) {
      setError(err)
      console.error("Error fetching search suggestions:", err.message)
    } finally {
      setSearchCompleted(true)
    }
  }

  const debouncedSearch = debounce(handleDebouncedSearch, 200)
  const moblieDebounce = debounce(handleDebouncedSearch, 200)

  const searchHandler = async e => {
    e.preventDefault()
    if (location.search.endsWith("true")) {
      const refParam = location.search.includes("ref")
        ? location.search.substring(location.search.indexOf("ref"))
        : ""
      navigate(`/search?q=${encodeURIComponent(searchValue)}&${refParam}`)
    } else {
      navigate(`/search?q=${encodeURIComponent(searchValue)}`)
    }
  }

  const MobileSearchHandler = async e => {
    e.preventDefault()
    setSearchMenu(false)
    if (location.search.endsWith("true")) {
      const refParam = location.search.includes("ref")
        ? location.search.substring(location.search.indexOf("ref"))
        : ""
      navigate(`/search?q=${encodeURIComponent(mobileSearchValue)}&${refParam}`)
    } else {
      navigate(`/search?q=${encodeURIComponent(mobileSearchValue)}`)
    }
  }

  useEffect(() => {
    setSearchResults([])
    setSearchValue("")
    setMobileSearchValue("")
    setIsSearchExpanded(false)
  }, [location])

  const handleSearchClick = () => {
    setSearchMenu(!searchMenu)
    setSelectedItem(-1)
  }

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      setSearchLoading(true)
    }, 1)
    loadingRef.current = setTimeout(() => {
      setSearchLoading(false)
    }, 500)

    return () => {
      clearTimeout(timerRef.current)
      clearTimeout(loadingRef.current)
    }
  }, [searchResults])

  return (
    <div className="relative w-full">
      <div className="flex flex-col relative w-full">
        <div
          style={{ zIndex: selectedItem > -1 ? 9999999999 : 0 }}
          className="z-[9999999999] relative"
        >
          <div
            className={`flex relative h-[80px] md:h-[90px] flex-row justify-between items-center bg-white w-full py-3 px-[15px] xl:px-28`}
          >
            <div
              className={`flex justify-center md:justify-start relative items-center w-[205px] md:w-[115px] lg:w-[160px] mb-[7px]`}
            >
              <button
                onClick={onMenuClick}
                className="space-y-1.5 mr-5 block md:hidden"
              >
                <div className="w-[32px] h-[3px] bg-[#1C1C1C] rounded-sm"></div>
                <div className="w-[32px] h-[3px] bg-[#1C1C1C] rounded-sm"></div>
                <div className="w-[32px] h-[3px] bg-[#1C1C1C] rounded-sm"></div>
              </button>
              <Link
                to={`/${location.search ? location.search : ""}`}
                className="block"
              >
                <WrittenLogoMark />
              </Link>
            </div>
            {!isSearchExpanded ? (
              <div className="hidden md:flex  items-center space-x-[11px] lg:space-x-6">
                {sub_nav_bar_shop?.map((blok: any, index: number) => {
                  return (
                    <div
                      onMouseEnter={() => setSelectedItem(index)}
                      className="border-b-4 bg-white border-white hover:border-b-paloverde-light py-4 cursor-pointer"
                    >
                      <p className="mb-0 font-extrabold font-condensed text-xs md:text-[.69rem] lg:text-[.83rem] xl:text-[.9rem] uppercase">
                        {blok.title}
                      </p>
                    </div>
                  )
                })}
              </div>
            ) : (
              <form
                onSubmit={searchHandler}
                className="w-[63%] ml-2 mr-6 my-0 lg:mt-[8px]  hidden md:flex items-center border border-gray-300 rounded-lg"
              >
                <input
                  autoFocus
                  className="w-full text-base md:text-[17px] px-4 py-1 md:pt-3 placeholder:!absolute placeholder:!top-[19px] md:pb-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 focus-visible:ring-2 focus-visible:ring-yellow-500 focus-visible:border-yellow-500"
                  placeholder="Search..."
                  value={searchValue}
                  onChange={e => {
                    setSearchValue(e.target.value)
                    debouncedSearch(e.target.value)
                  }}
                  required
                />
              </form>
            )}
            <SelectedDropDownItem
              selectedContent={selectedContent}
              setSelectedItem={setSelectedItem}
              shopAll={shopAll}
              columns={columns}
              bannerFileName={content?.bannerFileName}
            />
            <div className="flex flex-row justify-end items-center space-x-4 mb-[7px]">
              {!isSearchExpanded && (
                <Link
                  className="flex items-center pr-[5px]"
                  to={`${
                    (
                      typeof window !== "undefined"
                        ? JSON.parse(localStorage.getItem("loggedIn") || "{}")
                        : false
                    )
                      ? "/account"
                      : "/welcome"
                  }`}
                >
                  <FaUserCircle className="w-5 h-5 text-obsidian hover:text-paloverde z-[9999999999999]" />
                </Link>
              )}
              <div className="sm:block w-5">
                <button
                  className="flex"
                  onClick={() => {
                    setIsSearchExpanded(!isSearchExpanded)
                  }}
                >
                  {!isSearchExpanded ? (
                    <FaSearch
                      className="w-5 h-5 text-obsidian hover:text-paloverde"
                      onClick={handleSearchClick}
                    />
                  ) : (
                    <AiOutlineClose className="w-5 h-5 text-obsidian hover:text-paloverde hidden md:block" />
                  )}
                </button>
              </div>
              {!isSearchExpanded && (
                <button
                  onClick={() => {
                    setAddToCartSidebarTypeFunction("basket")
                    openAddToCartSidebar()
                  }}
                >
                  <div
                    className="flex flex-row items-center justify-center"
                    onClick={() => setSelectedItem(-1)}
                  >
                    <div className="mr-4 md:mr-1">
                      <FaShoppingBasket className="w-6 h-6 text-obsidian hover:text-paloverde" />
                    </div>
                    <div className="w-5 h-5 ml-[-1rem] md:flex-row items-center justify-center pt-[4px] mb-5 text-center hidden font-extrabold text-xs rounded-xl bg-paloverde text-white">
                      {checkout.lineItems.length}
                    </div>
                  </div>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`md:flex hidden md:flex-row search-bar  absolute z-[9999999] bg-white custom-search-container ${
          searchResults?.length > 0 && searchValue && isSearchExpanded ? "" : ""
        }`}
      >
        {!searchLoading &&
        searchResults?.length > 0 &&
        searchCompleted &&
        isSearchExpanded &&
        searchValue ? (
          <div className="absolute top-0 w-full bg-white">
            <div className="flex flex-col items-center w-full py-2">
              {searchResults.map((product: any, index: number) => (
                <div
                  className="flex w-full items-center justify-center px-4"
                  key={index}
                >
                  <Link
                    to={`/products/${product.url}`}
                    className="flex items-center w-full"
                    style={{ height: "80px" }}
                  >
                    <img
                      src={product.image}
                      alt={product.title}
                      className="w-16 h-16 object-cover"
                    />
                    <div className="flex flex-col items-start self-center justify-center ml-2 !p-0">
                      <h1 className="text-sm font-normal m-1">
                        {product.title}
                      </h1>
                      <div className="flex items-center">
                        <p className="text-xs font-bold ml-1">
                          ${product.price_min}
                        </p>
                        <p className="line-through pl-3 italic text-xs text-[#ababab] font-bold">
                          {product?.compare_at_price_max > 0
                            ? `$${product?.compare_at_price_max}`
                            : null}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
              <Link
                to={`/search?q=${searchValue}`}
                onClick={() => setSearchMenu(false)}
                className="cursor-pointer"
              >
                <h1 className="underline underline-offset-4 text-sm text-center mb-0">
                  See all results
                </h1>
              </Link>
            </div>
          </div>
        ) : null}

        {searchLoading && isSearchExpanded && searchValue && (
          <div className="hidden md:flex w-11/12 justify-center items-center h-20">
            <p className="font-bold text-base">Loading...</p>
          </div>
        )}

        {!searchLoading &&
          searchCompleted &&
          searchResults.length === 0 &&
          searchValue && (
            <div className="hidden md:flex w-11/12 justify-center items-center h-20">
              <p className="font-bold text-base">No results found</p>
            </div>
          )}
      </div>
      <div
        style={{
          transform: isSearchExpanded ? "translateX(0)" : "translateX(-100%)",
        }}
        className="overflow-y-auto fixed top-0 w-full z-[999999] overflow-hidden h-full bg-white p-5 md:opacity-0 md:hidden transition-transform duration-[700ms]"
      >
        <div className="flex flex-col py-5 items-center w-full">
          <div className="flex gap-4 w-full justify-center items-center">
            <form
              onSubmit={MobileSearchHandler}
              className="w-[63%] ml-2 mr-6 my-0 lg:mt-[8px] flex items-center border border-gray-300 rounded-lg"
            >
              <input
                autoFocus
                className="w-full text-base md:text-[17px] px-4 py-1 md:pt-3 md:pb-2 placeholder:!absolute placeholder:!top-[10px] rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 focus-visible:ring-2 focus-visible:ring-yellow-500 focus-visible:border-yellow-500"
                placeholder="Search..."
                value={mobileSearchValue}
                onChange={e => {
                  setMobileSearchValue(e.target.value)
                  moblieDebounce(e.target.value)
                }}
                required
              />
            </form>
            <AiOutlineClose
              fontSize={20}
              onClick={() => {
                setSearchMenu(false), setIsSearchExpanded(false)
              }}
              className="cursor-pointer hover:!text-paloverde !text-[#1C1C1C]"
            />
          </div>

          {!showDefaultSearch ? (
            <>
              {mobileResults.map((product: any, index: number) => (
                <div
                  className="flex w-full items-center justify-center mt-5"
                  key={index}
                >
                  <Link
                    to={`/products/${product.url}`}
                    className="flex items-center w-full"
                    onClick={() => setSearchMenu(false)}
                    style={{ height: "60px" }}
                  >
                    <img
                      src={product.image}
                      alt={product.title}
                      className="w-16 h-16 object-cover"
                    />
                    <div className="flex flex-col items-start self-center justify-center ml-2 !p-0">
                      <h1 className="text-sm font-normal m-1">
                        {product.title}
                      </h1>
                      <div className="flex items-center">
                        <p className="text-xs font-bold ml-1">
                          ${product.price_min}
                        </p>
                        <p className="line-through pl-3 italic text-xs text-[#ababab] font-bold">
                          {product?.compare_at_price_max > 0
                            ? `$${product?.compare_at_price_max}`
                            : null}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
              {!searchLoading && mobileResults?.length ? (
                <Link
                  to={`/search?q=${mobileSearchValue}`}
                  onClick={() => setSearchMenu(false)}
                  className="cursor-pointer"
                >
                  <h1 className="underline underline-offset-4 text-sm mt-5">
                    See all results
                  </h1>
                </Link>
              ) : null}
              {!searchLoading && !mobileResults?.length && searchCompleted && (
                <div className="flex w-11/12 justify-center items-center h-40">
                  <p className="font-bold text-base">No results found</p>
                </div>
              )}
              {searchLoading && (
                <div className="flex w-11/12 justify-center items-center h-40">
                  <p className="font-bold text-base">Loading...</p>
                </div>
              )}
            </>
          ) : (
            <DefaultSearch
              story={story}
              setSearchMenu={setSearchMenu}
              searchMenu={searchMenu}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default NavBarV2
