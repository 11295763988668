/* eslint-disable */

import { useLocation } from "@reach/router"

import * as React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const PrimaryButton = props => {
  const location = useLocation()

  const { text, link, buttonStyle } = props
  let buttonClasses =
    "min-w-200px bg-obsidian text-white hover:bg-paloverde p-5 rounded-br-3xl rounded-tl-3xl font-bold font-expanded uppercase"

  if (buttonStyle === "whiteBorder") {
    buttonClasses =
      "min-w-200px bg-transparent text-white hover:bg-paloverde border-white border-2 hover:border-paloverde p-5 rounded-br-3xl rounded-tl-3xl font-bold font-expanded uppercase"
  } else if (buttonStyle === "goldBorder") {
    buttonClasses =
      "min-w-200px bg-transparent hover:bg-paloverde text-paloverde hover:text-white border-paloverde border-2 hover:border-paloverde p-5 rounded-br-3xl rounded-tl-3xl font-bold font-expanded uppercase"
  }

  return (
    <button type="button" className={buttonClasses}>
      <Link to={`${link}/${location.search ? location.search : ""}`}>
        {text}
      </Link>
    </button>
  )
}

export default PrimaryButton

// Validate prop types
PrimaryButton.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  buttonStyle: PropTypes.string,
}

PrimaryButton.defaultProps = {
  text: "SHOP NOW",
  link: "none",
  buttonStyle: "default",
}
