/* eslint-disable */
import React, { useState, useEffect } from "react"
import loadable from "@loadable/component"
import useScrollBlock from "../../hooks/useScrollBlock"

const ModalVideo = loadable(() => import("react-modal-video"))

const VideoBanner = ({ blok }) => {
  const [showVideo, setShowVideo] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    if (showVideo) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [showVideo])

  return (
    <section
      className={`relative block h-[28vw] mt-20 bg-cover bg-no-repeat bg-[url('${blok.background_image}']`}
      style={{ backgroundImage: `url('${blok.background_image}')` }}
    >
      <a
        href="#data"
        id="inline"
        className="cta-round play-button x-y-center"
        onClick={e => {
          e.preventDefault()
          setShowVideo(true)
        }}
      />

      {/* <!-- Modal --> */}
      <ModalVideo
        channel={blok.platform}
        videoId={blok.video_id}
        isOpen={showVideo}
        onClose={() => setShowVideo(false)}
      />
    </section>
  )
}

export default VideoBanner
