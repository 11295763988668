import React from "react"
function ComponentBannner(props: any) {
  return (
    <div className="overflow-hidden w-full">
      <div className="launchCommercial">
        <div className="flex flex-col lg:flex-col">
          <div>
            <img
              className="m-auto hidden lg:block w-full"
              src={props.desktopAsset.filename}
              alt="ComponentBannerImage"
            />
            <img
              className="m-auto block lg:hidden w-full"
              src={props.mobileAsset.filename}
              alt="ComponentBannerImage"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComponentBannner
