/* eslint-disable */
import * as React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import ProductCard from "../ProductCard"
import "./style.scss"
import YotpoStarRatings from "../YotpoStarRatings"
import ProductRecomended from "./ProductRecomended"

const ProductRecommendation = props => {
  const { recommendedProducts } = props

  let recommendedProductsShow

  if (recommendedProducts) {
    recommendedProductsShow = recommendedProducts.length >= 1
  } else {
    recommendedProductsShow = null
  }

  const shopifyIdExtractor = id => id.slice(id.lastIndexOf("/") + 1)

  return !recommendedProductsShow ? (
    <div className="grid grid-cols-1 gap-20 text-center mt-[30px] mb-[-55px] text-obsidian text-xs">
      Sorry no recommendations
    </div>
  ) : (
    <div className="flex flex-col lg:flex-row lg:gap-5 w-full lg:justify-center ">
      {recommendedProducts.map(recommendedProduct => {
        if (recommendedProduct) {
          const productShopifyId = shopifyIdExtractor(
            recommendedProduct.shopifyId,
          )

          return (
            <div className="w-full">
              <a href={`/products/${recommendedProduct.handle}`}>
                <img src={recommendedProduct.featuredImage.originalSrc} />
                <YotpoStarRatings
                  product={recommendedProduct}
                  productShopifyId={productShopifyId}
                />
                <p className="font-black text-[14px] mb-0 mt-2">
                  {recommendedProduct.title}
                </p>

                {/* {recommendedProduct.metafields
                    .filter(metafield => metafield.key === "quickview-content")
                    .map(metafield => (
                      <p
                        className="text-[12px] leading-normal h-[60px]"
                        dangerouslySetInnerHTML={{ __html: metafield.value }}
                      ></p>
                    ))} */}

                {!recommendedProduct ? null : (
                  <ProductRecomended
                    key={recommendedProduct.id}
                    recommendedProduct={recommendedProduct}
                  />
                )}
              </a>
            </div>
          )
        }
      })}
    </div>
  )
}

export default ProductRecommendation
