/* eslint-disable */
import axios from "axios"

const { GATSBY_BASE_URL } = process.env

export default axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
  baseURL: GATSBY_BASE_URL,
  withCredentials: true,
  credentials: "include",
})

export const axiosPrivate = axios.create({
  baseURL: GATSBY_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  withCredentials: true,
  credentials: "include",
})
