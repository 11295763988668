/* eslint-disable */
import React, { useState, useEffect } from "react"
import {
  filter,
  summary,
  filterOptions,
  clearButton,
  selectedLabel,
  checkbox,
} from "./style.module.css"

import ChevronUpIcon from "../Icons/ChevronUpIcon"
import ChevronDownIcon from "../Icons/ChevronDownIcon"

export function CheckFilter({
  items,
  name,
  selectedItems = [],
  setSelectedItems,
  index,
  // open = true,
}) {
  let mediaDesktop = true
  // if (window.innerWidth < 769) mediaDesktop = false
  // const [open, setOpen] = useState(mediaDesktop);

  const [localCheckbox, setLocalCheckbox] = useState([])
  const [open, setOpen] = useState(mediaDesktop)
  const toggleItem = ({ currentTarget: input }) => {
    const shopifyTag = `${name}:${input.value}`

    if (input.checked) {
      setSelectedItems([...selectedItems, shopifyTag])
      setLocalCheckbox([...localCheckbox, input.value])
    } else {
      const idx = selectedItems.indexOf(shopifyTag)
      const localIdx = localCheckbox.indexOf(input.value)

      if (idx === -1) {
        return
      }

      const localNewItems = [
        ...localCheckbox.slice(0, localIdx),
        ...localCheckbox.slice(localIdx + 1),
      ]
      const newItems = [
        ...selectedItems.slice(0, idx),
        ...selectedItems.slice(idx + 1),
      ]
      setSelectedItems(newItems)
      setLocalCheckbox(localNewItems)
    }
  }

  const clearItems = () => {
    let clearedItems = [...selectedItems]
    for (let checked of localCheckbox) {
      const idx = clearedItems.indexOf(`${name}:${checked}`)

      clearedItems = [
        ...clearedItems.slice(0, idx),
        ...clearedItems.slice(idx + 1),
      ]
    }

    setLocalCheckbox([])
    setSelectedItems(clearedItems)
  }

  const handleClick = e => {
    e.preventDefault()
    setOpen((prev) => !prev);
  }

  useEffect(() => {
    let up = document.getElementsByClassName(`up-icon-index-${index}`)[0]
    let down = document.getElementsByClassName(`down-icon-index-${index}`)[0]

    if (open && up && down) {
      up.classList.add("icon-show")
      down.classList.remove("icon-show")
    } else if (!open && up && down) {
      up.classList.remove("icon-show")
      down.classList.add("icon-show")
    }
  }, [open])
  return (
    <details open={open} className={filter} onToggle={(e) => {
      setOpen(e.target.open || false)
    }}>
      {name && (
        <summary className="pb-0">
          <button className="drop-icon" onClick={e => handleClick(e)}>
            Test
          </button>
          <div className="drop-icons">
            {!localCheckbox.length && (
              <ChevronUpIcon
                width={10}
                index={index}
                onClick={(e) => handleClick(e)}
              />
            )}
            {!localCheckbox.length && (
              <ChevronDownIcon
                width={10}
                index={index}
                onClick={(e) => handleClick(e)}
              />
            )}
          </div>
          <div
            className={`${summary} fond-medium tracking-wider text-sm font-normal`}
          >
            <p className="filter-header font-extrabold font-condensed mb-0">
              {name}
            </p>{" "}
            {localCheckbox.length ? (
              <button className={clearButton} onClick={clearItems}>
                Clear
              </button>
            ) : undefined}
          </div>
        </summary>
      )}
      <div className={`${filterOptions} min-h-[25px] mt-2`}>
        {items.map(item => (
          <label
            className={selectedItems.includes(item) ? selectedLabel : undefined}
            key={item}
          >
            <input
              type="checkbox"
              className={checkbox}
              onChange={toggleItem}
              value={item}
              checked={selectedItems.includes(`${name}:${item}`)}
            />{" "}
            <span className="top-0 bottom-0 m-auto ml-2 text-[13px] font-condensed font-light">
              {item || "None"}
            </span>
          </label>
        ))}
      </div>
    </details>
  )
}
