const getVideoIdFromUrl = url => {
  const match = url.match(/(?:v=|youtu.be\/)([a-zA-Z0-9_-]+)/)
  if (match) {
    return match[1]
  }
  throw new Error("Invalid YouTube URL")
}
const updatedArray = (carouselImages, url, ind) => {
  carouselImages[ind] = { ...carouselImages[ind], videoThumbnail: url }

  return carouselImages
}

const generateThumbnail = (carouselImages, videoUrl, index) => {
  try {
    // Extract the video ID from the YouTube URL
    const videoId = getVideoIdFromUrl(videoUrl)

    // Construct the thumbnail URL
    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/default.jpg`

    return updatedArray(carouselImages, thumbnailUrl, index)
  } catch (error) {
    console.log("Error generating thumbnail:", error)
  }
}

export { getVideoIdFromUrl, generateThumbnail }
