import { Link } from "gatsby"
import React from "react"
import { FaUserCircle } from "react-icons/fa"
import DropDown from "./dropDown"

const SubNavBar = ({ story, setSelectedItem }: any) => {
  const content = story?.content
  if (!content) return null
  const {
    explore_title,
    support_title,
    sub_nav_bar_explore,
    sub_nav_bar_support,
  } = content
  return (
    <div
      onMouseEnter={() => setSelectedItem(-1)}
      className="hidden justify-end z-[9999999999] items-center w-full h-[40px] bg-[#CFCFCF] space-x-6 px-[15px] xl:px-28"
    >
      <DropDown title={explore_title} items={sub_nav_bar_explore}></DropDown>
      <DropDown title={support_title} items={sub_nav_bar_support}></DropDown>
      <Link
        className="flex items-center space-x-2"
        to={`${
          (
            typeof window !== "undefined"
              ? JSON.parse(localStorage.getItem("loggedIn") || "{}")
              : false
          )
            ? "/account"
            : "/welcome"
        }`}
      >
        <FaUserCircle className="w-4 h-4 text-obsidian hover:text-paloverde" />
        <p className="mb-0 text-sm mt-1 font-sans text-[#1C1C1C] font-bold hover:text-[#686868]">
          Account
        </p>
      </Link>
    </div>
  )
}

export default SubNavBar
