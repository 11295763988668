/* eslint-disable */
import React, { useState, useEffect } from "react"
// import { Link } from "gatsby";
import loadable from "@loadable/component"
import * as style from "./style.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import { useStoryblok } from "../../utils/storyblok"
import DynamicComponents from "../DynamicComponents"
import image0 from "../../images/basecampImages/image0.webp"
import image1 from "../../images/basecampImages/image1.webp"
import image2 from "../../images/basecampImages/image2.webp"
import image3 from "../../images/basecampImages/image3.webp"
import useScrollBlock from "../../hooks/useScrollBlock"
import HelixVariantCart from "./helixVariantCart"

import PlayIcon from "../Icons/PlayIcon"

const ModalVideo = loadable(() => import("react-modal-video"))

const BelowBasecampOverview = ({
  product: variantProduct,
  dynamicATCProducts,
}) => {
  const [showVideo, setShowVideo] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()
  const [selectedTitle, setSelectedTitle] = useState(null)

  const basecampDescription = [
    {
      title: "BASECAMP - STAGE 1 FILTERS",
      description:
        "Available CP, BlueMorphic Streak, and GoldMorphic Streak filters are offered for the BaseCamp Stage 1 slot. Compatible with the BaseCamp Core.",
    },
    {
      title: "BASECAMP - STAGE 2 & 3 FILTERS",
      description:
        "The BaseCamp ecosystem offers a huge array of second and third stage filters, ranging from VNDs, Mist Diffusion, Morphic Streak, NDs, and Graduated NDs.",
    },
    {
      title: "POLARPRO 4x5.65 FILTERS",
      description:
        "New to the BaseCamp lineup, featuring NDs, Mist Diffusion, and Morphic Streak filters with built-in aluminum frames and fitting the 4x5.65 industry standard. Compatible with the BaseCamp 4x5.65 Adapter as well as standard matte boxes.",
    },
    {
      title: "INDUSTRY STANDARD 4x5.65 FILTERS",
      description:
        'The BaseCamp 4x5.65 Adapter can accept both "standard" 4x5.65 glass as well as our new 4x5.65 filters with built-in aluminum frames.',
    },
  ]
  const scrollUp = e => {
    e.preventDefault()
    window.scrollTo({
      top: 0,
      behaviour: "smooth",
    })
  }

  useEffect(() => {
    if (showVideo) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [showVideo])

  const pageData = useStaticQuery(graphql`
    query {
      storyblokEntry(
        full_slug: { eq: "/products/basecamp-ultralight-matte-box-system" }
      ) {
        id
        name
        content
        slug
        full_slug
        uuid
        created_at
      }
      shopifyProduct(handle: { eq: "basecamp-ultralight-matte-box-system" }) {
        handle
        title
      }
    }
  `)

  const product = pageData.shopifyProduct

  const story = pageData.storyblokEntry
    ? useStoryblok(pageData.storyblokEntry)
    : { content: null }

  const stickyNavComponent =
    story.content && story.content["sticky_nav"]
      ? story.content["sticky_nav"].map(blok => (
          <>
            {blok ? (
              <div {...sbEditable(blok)} className="sticky top-0 z-50">
                <DynamicComponents
                  blok={blok}
                  product={product}
                  key={blok._uid}
                />
              </div>
            ) : null}
          </>
        ))
      : null

  return (
    <div className={`${style.basecampOverview} overflow-hidden`}>
      <div class={`${style.productHero} text-white flex flex-col lg:flex-row`}>
        <div class={`${style.introData}`}>
          <div class="video-container">
            <a
              href="#data"
              id="inline"
              className={`${style.MobilePlayBtn} cta-round opacity-90 hover:opacity-100`}
              onClick={e => {
                e.preventDefault()
                setShowVideo(true)
              }}
            >
              <PlayIcon />
            </a>
          </div>
        </div>
        <div class={`${style.introDataRight} pl-0 lg:pl-20`}>
          <img
            className="w-full pt-5"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/BASECAMP.png?v=1693433115"
            alt="Basecamp logo"
          />
          {/* <h2 className="display-font-logo font-bold font-expanded uppercase">
            Helix
          </h2> */}
          <p className="hidden lg:block">
            Renan Ozturk and Taylor Rees have built a career challenging the
            elements to capture epic footage of rarely seen places. Several of
            those excursions have involved a BaseCamp, the point in their
            journey which exemplifies taking things to the next level.
          </p>
          <p className="block lg:hidden">
            Renan Ozturk and Taylor Rees have built a career challenging the
            elements to capture epic footage of rarely seen places. Several of
            those excursions have involved a BaseCamp, the point in their
            journey which exemplifies taking things to the next level.
          </p>
        </div>
        {/* modal */}
        <ModalVideo
          channel="vimeo"
          videoId="372459516"
          isOpen={showVideo}
          onClose={() => setShowVideo(false)}
        />
      </div>
      <div className={`${style.breakBanner} bg-black py-28`}>
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/3-STAGES-OF-LIGHT-CONTROL.png?v=1693521942"
          alt=""
        />
        <div className={`${style.dataRight} pl-5 hidden lg:block`}>
          <img
            class="w-4/5 m-0"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/3_STAGES_OF_LIGHT_CONTROLUNLIMITED_CREATIVE_POTENTIAL..png?v=1693521890"
            alt=""
          />
        </div>
      </div>

      <div className="tech-callout bg-black pb-24">
        <img
          className="m-auto hidden lg:block"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/BASECAMP-TECH-CALLOUT-BANNER-1.webp?v=1693433691"
          alt="Tech Callout"
        />
        <img
          className="m-auto block lg:hidden w-full"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/BC-SYSTEM-DETAILS-BANNERS-MOBILE_1.webp?v=1693433704"
          alt="Tech Callout"
        />
      </div>

      <div className={`${style.sandBackg} feature-block max-w-full m-auto`}>
        <div className="flex items-center flex-col lg:flex-row">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black lg:leading-[1.1rem] text-2xl lg:text-sm xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-white mb-2 lg:md-8">
              Universal Compatibility — No Vignetting Down To 16mm Focal Lengths
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-white">
              BaseCamp features multiple mounting options allowing it to
              seamlessly work with any lens/filming setup from threaded lenses
              to 114mm cine lenses.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-white m-0">
              BaseCamp features multiple mounting options allowing it to
              seamlessly work with any lens/filming setup from threaded lenses
              to 114mm cine lenses.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <video
              autoPlay={true}
              muted={true}
              loop={true}
              playsInline={true}
              className="block w-full"
            >
              <source src="https://cdn.shopify.com/videos/c/o/v/5c84219a8e4f41619ec6736f9265faf8.mp4" />
            </video>
          </div>
        </div>

        <div className="flex items-center flex-col lg:flex-row-reverse">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black text-3xl lg:leading-[1.1rem] lg:text-base xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-white mb-2 lg:md-8">
              Industry-Leading Glass Across The Entire Filter Lineup
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-white">
              All BaseCamp filters feature CinemaSeries™ glass that resolves
              the largest video sensors with perfect color accuracy and pristine
              optics.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-white m-0">
              All BaseCamp filters feature CinemaSeries™ glass that resolves
              the largest video sensors with perfect color accuracy and pristine
              optics.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="w-full m-0 block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/BLOCK-2-BC-DESKTOP.webp?v=1693434727"
              alt=""
            />
          </div>
        </div>
      </div>

      <div
        className={`${style.breakBanner} ${style.leftStrike} bg-black py-28`}
      >
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/FULL_METAL_CONSTRUCTION_BOMBPROOF_PROTECTION._1b6320db-95a7-4acc-9361-04a43f69d627.png?v=1693521942"
          alt=""
        />
        <div className={`${style.dataLeft} pr-5 hidden lg:block`}>
          <img
            class="w-4/5 m-0 inline-block"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/METICULOUSLY_ENGINEERED_FOR_GIMBAL_AERIAL_APPLICATIONS..png?v=1693521890"
            alt=""
          />
        </div>
      </div>

      <div className="system-details bg-black pb-24">
        <img
          className="m-auto hidden lg:block"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/MATTE-BOX-SYSTEM-DESKTOP.webp?v=1693436748"
          alt="System Details"
        />
        <img
          className="m-auto block lg:hidden w-full"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/BC-SYSTEM-DETAILS-STYLE-BANNERS-MOBILE_1.webp?v=1693436724"
          alt="System Details"
        />
      </div>

      <div className={`${style.sandBackg} feature-block2 max-w-full m-auto`}>
        <div className="flex items-center flex-col lg:flex-row">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black lg:leading-[1.1rem] text-3xl lg:text-base xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-white mb-2 lg:md-8">
              New 4x5.65 Filter Compatibility
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-white">
              New to the BaseCamp line, the 4x5.65 Stage 3 Adapter now allows
              for the use of any industry-standard 4x5 glass filters, making
              BaseCamp the most capable production matte box on the market.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-white m-0">
              New to the BaseCamp line, the 4x5.65 Stage 3 Adapter now allows
              for the use of any industry-standard 4x5 glass filters, making
              BaseCamp the most capable production matte box on the market.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="w-full m-0 block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/NEW4X565-FILTER-BLOCK-DESKTOP.webp?v=1693437523"
              alt=""
            />
          </div>
        </div>

        <div className="flex items-center flex-col lg:flex-row-reverse">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black lg:leading-[1.1rem] text-3xl lg:text-base xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-white mb-2 lg:md-8">
              The Only Filters With Built-In Aluminum Frames
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-white">
              Both standard BaseCamp filters and our new 4x5.65 filters feature
              built-in aluminum frames, adding strength and rigidity while also
              providing a secure way to install and remove without touching the
              glass.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-white m-0">
              Both standard BaseCamp filters and our new 4x5.65 filters feature
              built-in aluminum frames, adding strength and rigidity while also
              providing a secure way to install and remove without touching the
              glass.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="w-full m-0 block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/BUILT-IN-ALUMINUM-BLOCK-DESKTOP.webp?v=16934375234"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="bg-black w-full flex flex-col lg:flex-row pt-[100px]">
        <div className=" w-full px-[10px] sm:px-[50px] lg:w-1/2">
          <p className="hilex-title mb-0">EXPLORE -</p>
          <p className="hilex-main-title text-[24px] lg:[20px] xl:text-[24px] 2xl:text-[30px]">
            FILTER COMPATIBILITY
          </p>
          {basecampDescription.map((content, index) => (
            <div key={content.title}>
              <div className="flex justify-between border-t border-[#DDDDD7] pt-4">
                <p className="font-medium text-[20px] lg:[16px] xl:text-[20px] 2xl:text-[24px]  text-[#DDDDD7] w-[90%]">
                  {content.title}
                </p>
                {selectedTitle !== index ? (
                  <p
                    className="font-light text-[#DDDDD7] cursor-pointer text-3xl"
                    onClick={() => setSelectedTitle(index)}
                  >
                    +
                  </p>
                ) : (
                  <p
                    className="font-light text-white cursor-pointer text-3xl pr-2"
                    onClick={() => setSelectedTitle(null)}
                  >
                    -
                  </p>
                )}
              </div>
              {selectedTitle === index ? (
                <p className="text-[#DDDDD7] text-[13px] lg:text-[11px] xl:text-[12px] leading-4 2xl:text-[16px]">
                  {content.description}
                </p>
              ) : null}
            </div>
          ))}
        </div>
        <div className="w-full lg:w-1/2">
          {(selectedTitle === null) | 0 ? (
            <img
              className="w-full h-auto object-cover"
              src={image0}
              alt={"basecampImg"}
            />
          ) : null}
          {selectedTitle === 0 ? (
            <img
              className="w-full h-auto object-cover"
              src={image0}
              alt={"basecampImg"}
            />
          ) : null}
          {selectedTitle === 1 ? (
            <img
              className="w-full h-auto object-cover"
              src={image1}
              alt={"basecampImg"}
            />
          ) : null}
          {selectedTitle === 2 ? (
            <img
              className="w-full h-auto object-cover"
              src={image2}
              alt={"basecampImg"}
            />
          ) : null}
          {selectedTitle === 3 ? (
            <img
              className="w-full h-auto object-cover"
              src={image3}
              alt={"basecampImg"}
            />
          ) : null}
        </div>
      </div>

      <div className={`${style.breakBanner} bg-black py-28`}>
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/FILTERS-COMPONENTS.png?v=1693521942"
          alt=""
        />
        <div className={`${style.dataRight} pl-5 hidden lg:block`}>
          <img
            class="w-2/3 m-0"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/BASECAMP_FILTERS_COMPONENTS..png?v=1693521890"
            alt=""
          />
        </div>
      </div>

      <div className="grid grid-cols-2 auto-cols-fr sm:grid-cols-2 md:grid-cols-3 xl-1600:grid-cols-4 gap-6 md:gap-6 xl:gap-10 bg-black text-center px-[2vw] pb-20">
        {[...dynamicATCProducts].map((variant, ind) => {
          return (
            <HelixVariantCart
              variant={variant}
              product={variantProduct}
              sliderImages={variant?.image?.originalSrc}
              variantProduct={variantProduct}
            />
          )
        })}
      </div>

      <div
        className={`${style.breakBanner} ${style.leftStrike} bg-black py-28`}
      >
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/3-STRENGTH-OPTIONS.png?v=1693521942"
          alt=""
        />
        <div className={`${style.dataLeft} pr-5 hidden lg:block`}>
          <img
            class="w-1/2 m-0 inline-block"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/PROFESSIONAL_DIFFUSION_3_STRENGTH_OPTIONS..png?v=1693521890"
            alt=""
          />
        </div>
      </div>

      <div className="before-after">
        <widgetic
          class="desktop-only"
          id="62015913ecb2a1ff298b4567"
          resize="fill-width"
          width="2374"
          height="925"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <widgetic
          class="mobile-only"
          id="62015aaaecb2a1ba2a8b4567"
          resize="fill-width"
          width="1284"
          height="1788"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <div className="data bg-black m-0 text-white text-center pt-5 pb-5">
          <h2 className="title font-black mb-2">Mist-L [1/8]</h2>
          <p className="text-[12px]">
            Our lightest diffusion option at 1/8 strength. Can be used in most
            applications to help soften the sharpness of digital sensors.
          </p>
        </div>
      </div>

      <div className="before-after">
        <widgetic
          class="desktop-only"
          id="6201594fecb2a11b2a8b456b"
          resize="fill-width"
          width="2374"
          height="925"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <widgetic
          class="mobile-only"
          id="62015ae2ecb2a1d82a8b4567"
          resize="fill-width"
          width="1284"
          height="1788"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <div className="data bg-black m-0 text-white text-center pt-5 pb-5">
          <h2 className="title font-black mb-2">Mist [1/4]</h2>
          <p className="text-[12px]">
            Smooths highlights and gives a subtle bloom to light sources. 1/4
            diffusion strength.
          </p>
        </div>
      </div>

      <div className="before-after">
        <widgetic
          class="desktop-only"
          id="6201597decb2a1402a8b4569"
          resize="fill-width"
          width="2374"
          height="925"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <widgetic
          class="mobile-only"
          id="62015b0becb2a1f82a8b4567"
          resize="fill-width"
          width="1284"
          height="1788"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <div className="data bg-black m-0 text-white text-center pt-5 pb-5">
          <h2 className="title font-black mb-2">Mist-H [1/2]</h2>
          <p className="text-[12px]">
            This heavier 1/2 diffusion strength has a strong diffused look.
            Ideal for creative applications such as music videos or dream
            sequences.
          </p>
        </div>
      </div>

      <div className={`${style.breakBanner} bg-black py-28`}>
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/MORPHIC-FX.png?v=1693521942"
          alt=""
        />
        <div className={`${style.dataRight} pl-5 hidden lg:block`}>
          <img
            class="w-2/3 m-0"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/STAGE_1_2_MORPHIC_STREAK_FX..png?v=1693521890"
            alt=""
          />
        </div>
      </div>

      <div className="before-after">
        <widgetic
          class="desktop-only"
          id="62015816ecb2a17e298b456c"
          resize="fill-width"
          width="2374"
          height="925"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <widgetic
          class="mobile-only"
          id="62015a22ecb2a1572a8b4571"
          resize="fill-width"
          width="1284"
          height="1788"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <div className="data bg-black m-0 text-white text-center pt-5 pb-5">
          <h2 className="title font-black mb-2">BlueMorphic</h2>
          <p className="text-[12px]">
            BlueMorphic creates anamorphic-style blue streaks, available in
            Stage 1 Circular and Stage 2/3 Rectangular options.
          </p>
        </div>
      </div>

      <div className="before-after">
        <widgetic
          class="desktop-only"
          id="6201588decb2a17e298b4578"
          resize="fill-width"
          width="2374"
          height="925"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <widgetic
          class="mobile-only"
          id="62015a7cecb2a1572a8b4576"
          resize="fill-width"
          width="1284"
          height="1788"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <div className="data bg-black m-0 text-white text-center pt-5 pb-5">
          <h2 className="title font-black mb-2">GoldMorphic</h2>
          <p className="text-[12px]">
            GoldMorphic produces warm stylized streaks, available in both Stage
            1 Circular and Stage 2/3 Rectangular options.
          </p>
        </div>
      </div>

      <div
        className={`${style.breakBanner} ${style.leftStrike} bg-black py-28`}
      >
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/ND-FILTERS.png?v=1693521942"
          alt=""
        />
        <div className={`${style.dataLeft} pr-5 hidden lg:block`}>
          <img
            class="w-1/4 m-0 inline-block"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/GRADUATED_ND_FILTERS..png?v=1693521890"
            alt=""
          />
        </div>
      </div>

      <div className="before-after">
        <widgetic
          class="desktop-only"
          id="620159baecb2a1442a8b4567"
          resize="fill-width"
          width="2374"
          height="925"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <widgetic
          class="mobile-only"
          id="62015b38ecb2a1f22a8b4567"
          resize="fill-width"
          width="1284"
          height="1788"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <div className="data bg-black m-0 text-white text-center pt-5 pb-5">
          <h2 className="title font-black mb-2">ND4/GR</h2>
          <p className="text-[12px]">
            Featuring a stop reduction from 2 down to 0, ideal for balancing
            exposure of the sky and foreground in most scenarios.
          </p>
        </div>
      </div>

      <div className="before-after">
        <widgetic
          class="desktop-only"
          id="620159edecb2a1472a8b456b"
          resize="fill-width"
          width="2374"
          height="925"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <widgetic
          class="mobile-only"
          id="62015b65ecb2a1232b8b4567"
          resize="fill-width"
          width="1284"
          height="1788"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <div className="data bg-black m-0 text-white text-center pt-5 pb-5">
          <h2 className="title font-black mb-2">ND8/GR</h2>
          <p className="text-[12px]">
            Featuring a stop reduction from 3 down to 0, perfect for balancing
            exposure of the sky and foreground in harsher light.
          </p>
        </div>
      </div>

      <div className={`${style.breakBanner} bg-black py-28`}>
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/INFINITE-ARTISTIC-POSSIBLITIES.png?v=1693521942"
          alt=""
        />
        <div className={`${style.dataRight} pl-5 hidden lg:block`}>
          <img
            class="w-4/5 m-0"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/POLARPRO_BASECAMP_INFINITE_ARTISTIC_POSSIBILITIES..png?v=1693521890"
            alt=""
          />
        </div>
      </div>

      <div className="launchCommercial">
        <div className="flex flex-col lg:flex-col">
          <div>
            <img
              className="m-auto hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/COLLAGE-1-DESKTOP.webp?v=1693428354"
              alt=""
            />
            <img
              className="m-auto block lg:hidden w-full"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/COLLAGE-1-MOBILE.webp?v=1693428387"
              alt=""
            />
          </div>
          <div>
            <img
              className="m-auto hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/COLLAGE-2-DESKTOP.webp?v=1693428354"
              alt=""
            />
            <img
              className="m-auto block lg:hidden w-full"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/COLLAGE-2-MOBILE.webp?v=1693428387"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BelowBasecampOverview
