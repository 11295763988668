import React from "react"
import Card from "../Card"

interface Location {
  title: string
  link: string
}

export interface IReseller {
  name: string
  locations: Location[]
}

const ResellersList: IReseller[] = [
  {
    name: "Australia",
    locations: [
      {
        title: "DigiDirect",
        link: "https://www.digidirect.com.au/find?q=polarpro",
      },
      {
        title: "D1 Store",
        link: "https://www.d1store.com.au/search.php?s=polarpro",
      },
    ],
  },
  {
    name: "Belgium",
    locations: [
      {
        title: "Kamera Express",
        link: "https://www.kamera-express.be/polarpro",
      },
      {
        title: "CameraNU",
        link: "https://www.cameranu.be/nl/search?q=polarpro",
      },
    ],
  },
  {
    name: "Denmark",
    locations: [
      {
        title: "Elkjop/Elgiganten/Gigantti",
        link: "https://www.elgiganten.dk/search/polar%2520pro",
      },
      {
        title: "Proshop",
        link: "https://www.proshop.dk/?s=polarpro",
      },
      {
        title: "Scandinavian Photo",
        link: "https://www.scandinavianphoto.dk/sog?q=polarpro",
      },
      {
        title: "Dustin",
        link: "http://www.dustinhome.dk/sog/polarpro",
      },
      {
        title: "Kamerahuset",
        link: "https://www.kamerahuset.dk/alle-soegeresultater?q=polarpro",
      },
    ],
  },
  {
    name: "Finland",
    locations: [
      {
        title: "Elkjop/Elgiganten/Gigantti",
        link: "https://www.gigantti.fi/search/polar%2520pro",
      },
      {
        title: "Proshop",
        link: "https://www.proshop.fi/?s=polarpro",
      },
      {
        title: "Scandinavian Photo",
        link: "https://www.scandinavianphoto.fi/hae?q=polarpro",
      },
      {
        title: "Dustin",
        link: "https://www.dustinhome.fi/hae/polarpro",
      },
    ],
  },
  {
    name: "France",
    locations: [
      {
        title: "Miss Numerique",
        link: "https://www.missnumerique.com/polar-pro-m-321.html",
      },
      {
        title: "Photo Specialist",
        link: "https://www.photospecialist.fr/resultats-recherche?tn_q=polarpro",
      },
      {
        title: "Digit Photo",
        link: "https://www.digit-photo.com/Recherche/",
      },
    ],
  },
  {
    name: "Germany",
    locations: [
      {
        title: "Foto Gregor",
        link: "https://www.foto-gregor.de/suchergebnisse?tn_q=polarpro",
      },
      {
        title: "Foto Leistenschneider",
        link: "https://www.foto-leistenschneider.de/search?search=polarpro",
      },
    ],
  },
  {
    name: "Japan",
    locations: [
      {
        title: "WorldLink & Company Co.,Ltd.",
        link: "https://www.uavoom.com/collections/polarpro",
      },
    ],
  },
  {
    name: "Korea",
    locations: [
      {
        title: "Smartgeeks Co., LTD.",
        link: "https://www.smartgeeksmall.com/",
      },
      {
        title: "Clmedia",
        link: "https://www.clmedia.co.kr/goods/search?keyword_log_flag=Y&search_text=polarpro&x=0&y=0",
      },
      {
        title: "Weedeal",
        link: "https://smartstore.naver.com/wiidiil/category/9083f1b78bd441d290940416cc760b0e?cp=1",
      },
      {
        title: "KPP",
        link: "https://koreaphotoproducts.com/shop/brand.php?brand=POLARPRO",
      },
    ],
  },
  {
    name: "Netherlands",
    locations: [
      {
        title: "Kamera Express",
        link: "https://www.kamera-express.nl/zoekresultaten?tn_q=polarpro",
      },
      {
        title: "CameraNU",
        link: "https://www.cameranu.nl/nl/search?q=polarpro",
      },
      {
        title: "Ring Foto",
        link: "https://ringfotomeppel.nl/?s=polarpro&post_type=product",
      },
    ],
  },
  {
    name: "Norway",
    locations: [
      {
        title: "Elkjop/Elgiganten/Gigantti",
        link: "https://www.elkjop.no/search/polar%2520pro",
      },
      {
        title: "Proshop",
        link: "https://www.proshop.no/?s=polarpro",
      },
      {
        title: "Scandinavian Photo",
        link: "https://www.scandinavianphoto.no/sok?q=polarpro",
      },
      {
        title: "Dustin",
        link: "https://www.dustinhome.no/sok/polarpro",
      },
    ],
  },
  {
    name: "Poland",
    locations: [
      {
        title: "FLYELECTRONICS",
        link: "https://flystore.pl/pl/searchquery/polarpro/1/phot/5?url=polarpro",
      },
      {
        title: "CAMONBOARD",
        link: "https://www.gohero.pl/pl/searchquery/polarpro/1/phot/5?url=polarpro",
      },
      {
        title: "SKY SCAFF",
        link: "https://megadron.pl/pl/search.html?text=polarpro",
      },
      {
        title: "Photo4B",
        link: "https://www.photo4b.pl/",
      },
      {
        title: "GOCAM",
        link: "https://gocam.pl/",
      },
      {
        title: "Cyfrowe.pl",
        link: "https://www.cyfrowe.pl/",
      },
    ],
  },
  {
    name: "Sweden",
    locations: [
      {
        title: "Elkjop/Elgiganten/Gigantti",
        link: "https://www.elgiganten.se/search/polar%2520pro",
      },
      {
        title: "Proshop",
        link: "https://www.proshop.se/?s=polarpro",
      },
      {
        title: "Scandinavian Photo",
        link: "https://www.scandinavianphoto.se/sok?q=polarpro",
      },
      {
        title: "Dustin",
        link: "https://www.dustinhome.no/sok/polarpro",
      },
    ],
  },
  {
    name: "Switzerland",
    locations: [
      {
        title: "Foto Video Zumstein",
        link: "https://foto-zumstein.ch/de/foto-video?manufacturer=279",
      },
      {
        title: "Interdiscount",
        link: "https://www.interdiscount.ch/de/search?search=polar+pro",
      },
      {
        title: "Digitec Galaxus AG",
        link: "https://www.digitec.ch/de/search?q=polarpro&is=polarpro",
      },
      {
        title: "BRACK.CH",
        link: "https://www.brack.ch/search?active_search=true&query=polarpro",
      },
    ],
  },
  {
    name: "UK",
    locations: [
      {
        title: "Scan Computers",
        link: "https://www.scan.co.uk/shop-by-brand/polar-pro",
      },
      {
        title: "Wex",
        link: "https://www.wexphotovideo.com/search/?q=Polar+pro&search_type=All#q=Polar%20pro&facet.multiselect=true&filter=flag:%22Product%22&variants=false&variants.count=5&fields=&spellcheck=true&rows=12&start=0&version=V2&viewType=LIST&facet.version=V2",
      },
      {
        title: "CVP",
        link: "https://cvp.com/catalogue/search/polar+pro",
      },
    ],
  },
]

const Body = () => {
  return (
    <div className="flex flex-col justify-between items-center px-[15px] md:px-28 w-full mx-auto py-4 max-w-[1088px] md:py-12">
      <h1 className="mb-0 mt-2 text-2xl w-full text-left font-extrabold">
        International Resellers
      </h1>
      {ResellersList.map((reseller, index) => (
        <Card key={index} {...reseller} />
      ))}
    </div>
  )
}

export default Body
