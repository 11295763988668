/* eslint-disable */
export const getTodayDate = () => {
  const today = new Date()
  const dd = today.getDate()
  const mm = today.getMonth() + 1 //January is 0!
  const yyyy = today.getFullYear()
  if (dd < 10) {
    dd = "0" + dd
  }
  if (mm < 10) {
    mm = "0" + mm
  }

  today = yyyy + "-" + mm + "-" + dd
  return today
}

export const getDate = date => {
  const today = new Date(date)
  let dd = today.getDate()
  let mm = today.getMonth() + 1 //January is 0!
  let yyyy = today.getFullYear()
  if (dd < 10) {
    dd = "0" + dd
  }
  if (mm < 10) {
    mm = "0" + mm
  }

  const result = yyyy + "-" + mm + "-" + dd
  return result
}
