/* eslint-disable */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

import { sbEditable } from "@storyblok/storyblok-editable"
import { FaChevronDown, FaChevronUp } from "react-icons/fa"
import DynamicComponents from "../DynamicComponents" // eslint-disable-line
import FlagshipNavBuyNow from "../FlagshipNavBuyNow"

import "./style.scss"
// import DynamicLogos from '../DynamicLogos';

/* eslint-disable */
/* eslint-disable camelcase */
const FlagshipNav = ({ blok, product }) => {
  const { handle, title } = product || { title: "", handle: "" }
  const location = useLocation()

  const {
    dark_mode,
    dark_grey_mode,
    logo,
    customLogoClass,
    hover_logo,
    mobile_logo,
    logo_path = `/products/${handle}`,
    nav_menu_items,
    show_buy,
  } = blok

  const [mobileActive, setMobileActive] = useState(false)

  return (
    <div
      className={`bg-off-white ${dark_mode ? "md:bg-obsidian" : ""} ${dark_grey_mode ? "bg-grey-polardark md:bg-grey-polardark" : ""
        } flex flex-col top-0 md:flex-row items-center justify-between h-[45px] md:h-[50px] px-[15px] md:px-[6vw] xl:px-[7rem]`}
    >
      <span className="hidden max-w-[80px] max-h-[30px]" />
      <div className="relative flex flex-row items-center justify-between md:block w-full md:w-[140px] h-full m-0">
        <Link
          to={`${logo_path}/${location.search ? location.search : ""}`}
          className="block relative top-0 left-0 w-1/3 md:w-full h-full ml-[15px] md:ml-0"
        >
          <div className="logo-container">
            <img
              src={logo.filename}
              alt={`${title} logo`}
              className={`hidden md:block absolute my-auto top-0 bottom-0 base-logo ${customLogoClass ? customLogoClass : "max-w-[140px] max-h-[30px]"
                }`}
            />
            <img
              src={hover_logo.filename}
              alt={`${title} hover logo`}
              className={`opacity-0 hover:opacity-100 absolute my-auto top-0 bottom-0 ${customLogoClass ? customLogoClass : "max-w-[140px] max-h-[30px]"
                }`}
            />
            <img
              src={mobile_logo.filename}
              alt={`${title} logo`}
              className={`block md:hidden absolute my-auto left-[15px] md top-0 bottom-0 ${customLogoClass ? customLogoClass : "max-w-[140px] max-h-[30px]"
                }`}
            />
          </div>
        </Link>
        <div
          className={`inline-block md:hidden w-2/5 my-auto ${show_buy ? "" : "w-[13%]"
            }`}
          onClick={() => setMobileActive(!mobileActive)}
        >
          <div className="flex flex-row items-center md:hidden">
            <FaChevronDown
              className={`${mobileActive ? "hidden" : "inline-block"} w-[15px]`}
            />
            <FaChevronUp
              className={`${mobileActive ? "inline-block" : "hidden"}`}
            />
            <FlagshipNavBuyNow logo_path={logo_path} show_buy={show_buy} />
          </div>
        </div>
      </div>
      {/* <div className="relative flex flex-col md:flex-row"> */}
      <ul
        className={`${dark_mode ? "md:text-white" : "md:text-obsidian"} ${mobileActive
          ? "absolute md:relative flex top-[45px] md:top-0 left-0 w-full text-obsidian bg-off-white md:bg-obsidian"
          : "h-0 hidden"
          } md:flex flex-col md:flex-row md:relative md:top-0 md:h-[50px] md:justify-end items-center m-0 transition-all ease-in-out`}
      >
        {nav_menu_items.map((blok, index) => (
          <div
            {...sbEditable(blok)}
            className={`${index + 1 === nav_menu_items.length ? "md:flex" : "h-[80px]"
              } py-[15px] md:p-0 md:h-[50px] text-center`}
          >
            <DynamicComponents blok={blok} product={product} key={blok._uid} />
          </div>
        ))}
      </ul>
      {/* </div> */}
    </div>
  )
}

export default FlagshipNav

FlagshipNav.propTypes = {
  blok: PropTypes.shape({
    dark_mode: PropTypes.bool,
    dark_grey_mode: PropTypes.bool,
    logo: PropTypes.string,
    logo_path: PropTypes.string,
    show_buy: PropTypes.bool,
    customLogoClass: PropTypes.string,
    // logo: PropTypes.string,
    // logo: PropTypes.string,
  }),
  product: PropTypes.shape({
    handle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}

FlagshipNav.defaultProps = {
  blok: {
    dark_mode: false,
    dark_grey_mode: false,
    show_buy: false,
    logo: "",
    customLogoClass: "",
  },
}
