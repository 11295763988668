import React, { useState, createContext } from "react"
import PropTypes from "prop-types"

export const NewUserContext = createContext<{
  loaded?: boolean
  setLoaded?: React.Dispatch<React.SetStateAction<boolean>>
  isNewUser?: boolean
  setIsNewUser?: React.Dispatch<React.SetStateAction<boolean>>
  popUpSubmitted?: boolean
  setPopUpSubmitted?: React.Dispatch<React.SetStateAction<boolean>>
  footerSubmitted?: boolean
  setFooterSubmitted?: React.Dispatch<React.SetStateAction<boolean>>
  cartOverlay: boolean
  setCartOverlay?: React.Dispatch<React.SetStateAction<boolean>>
}>({
  isNewUser: false,
  setIsNewUser: () => null,
  popUpSubmitted: false,
  setPopUpSubmitted: () => null,
  setFooterSubmitted: () => null,
  footerSubmitted: false,
  loaded: false,
  setLoaded: () => null,
  cartOverlay: false,
  setCartOverlay: () => null,
})

export const NewUserContextProvider = ({ children }: any) => {
  const [isNewUser, setIsNewUser] = useState(false)
  const [footerSubmitted, setFooterSubmitted] = useState(false)
  const [popUpSubmitted, setPopUpSubmitted] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [cartOverlay, setCartOverlay] = useState(false)

  return (
    <NewUserContext.Provider
      value={{
        isNewUser,
        setIsNewUser,
        popUpSubmitted,
        setPopUpSubmitted,
        footerSubmitted,
        setFooterSubmitted,
        loaded,
        setLoaded,
        cartOverlay,
        setCartOverlay,
      }}
    >
      {children}
    </NewUserContext.Provider>
  )
}

NewUserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
