/* eslint-disable */
import { useEffect } from "react"
import { axiosPrivate } from "../api/axios"
import useRefreshToken from "./useRefreshToken"
import useAuth from "./useAuth"

const useAxiosPrivate = () => {
  const refresh = useRefreshToken()
  const { auth } = useAuth()

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      config => {
        if (!config.headers.Authorization) {
          config.headers.Authorization = `Bearer ${auth?.accessToken}` // eslint-disable-line no-param-reassign
        }
        return config
      },
      error => Promise.reject(error),
    )

    const responseIntercept = axiosPrivate.interceptors.response.use(
      response => response,
      async error => {
        // console.log('INSIDE response Interceptor', error.response)
        const prevRequest = error?.config
        // console.log('previous req', prevRequest)
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true
          const newAccessToken = await refresh()
          // console.log('Setting up a new access token', newAccessToken);
          prevRequest.headers.Authorization = `Bearer ${newAccessToken}`
          return axiosPrivate(prevRequest)
        }
        return Promise.reject(error)
      },
    )

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept)
      axiosPrivate.interceptors.response.eject(responseIntercept)
    }
  }, [auth, refresh])

  return axiosPrivate
}

export default useAxiosPrivate
