/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from "react"
import { StoreContext } from "../../context/StoreContext"

import UtilityBar from "../utilityBar/utilityBar"
import AddToCartBanner from "../AddToCartBanner/AddToCartBanner"
import NavBarV2 from "../NavBarV2"
import SideBarV2 from "../NavBarV2/sideBar"
import CartDrawer from "../CartDrawer"
import SubNavBar from "../NavBarV2/subNavBar"
import { CollectionScript } from "../../scripts/retention"

const isBrowser = typeof window !== "undefined"

const Header = ({ story, utilityBar }) => {
  const [menuActive, setMenuActive] = useState(false)
  const [currLoc, setCurrLoc] = useState("")
  const [isCartOpen, setIsCartOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(-1)

  const {
    addToCartSidebar,
    closeAddToCartSideBar,
    openPopUpModal,
    setOpenPopUpModal,
  } = useContext(StoreContext)

  const onMenuClick = () => {
    setMenuActive(prev => !prev)
  }

  const handleCloseMenu = () => {
    onMenuClick()
  }

  useEffect(() => {
    const geq = window.geq || []
    if (geq.initialize) return
    if (geq.invoked) {
      if (window.console && console.error) {
        console.error("GE snippet included twice.")
      }
      return
    }
    geq.invoked = true
    geq.methods = [
      "page",
      "suppress",
      "trackOrder",
      "identify",
      "addToCart",
      "callBack",
      "event",
    ]
    geq.factory = function (method) {
      return function () {
        var args = Array.prototype.slice.call(arguments)
        args.unshift(method)
        geq.push(args)
        return geq
      }
    }
    for (var i = 0; i < geq.methods.length; i++) {
      var key = geq.methods[i]
      geq[key] = geq.factory(key)
    }

    const loadScript = () => {
      var script = document.createElement("script")
      console.log("SCRIPT-WORKING")
      script.type = "text/javascript"
      script.async = true
      if (location.href.includes("vge=true")) {
        script.src =
          "https://s3-us-west-2.amazonaws.com/jsstore/a/" +
          "E82H7M9" +
          "/ge.js?v=" +
          Math.random()
      } else {
        script.src =
          "https://s3-us-west-2.amazonaws.com/jsstore/a/" + "E82H7M9" + "/ge.js"
      }
      var first = document.getElementsByTagName("script")[0]
      first.parentNode.insertBefore(script, first)
    }

    // loadScript()

    if (isBrowser && currLoc === "") {
      setCurrLoc(window.location.pathname)
    } else if (isBrowser && currLoc !== window.location.pathname) {
      setMenuActive(false)
      setCurrLoc(window.location.pathname)
    }
  })
  const cartRef = useRef(null)
  useEffect(() => {
    const handleClickOutside = event => {
      if (cartRef.current) {
        const clickElement = event.target
        if (
          !cartRef.current.contains(clickElement) &&
          !clickElement.classList.contains("cart-close")
        ) {
          closeAddToCartSideBar()
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <>
      <div className="sidebar">
        <CollectionScript />
        {selectedItem > -1 && (
          <div
            style={{
              backdropFilter: "blur(25px) opacity(1)",
              backgroundColor: "rgba(17, 17, 17, .7)",
            }}
            className="w-full hidden md:block h-full fixed inset-0 z-[99999999]"
          />
        )}
        <SideBarV2
          onMenuClick={menuActive ? handleCloseMenu : onMenuClick}
          menuActive={menuActive}
          story={story}
          setMenuActive={setMenuActive}
        />
        <div
          className={`${openPopUpModal ? "z-0" : "z-[999999999]"
            } relative left-0 w-full top-0 `}
        >
          <SubNavBar
            story={story}
            setSelectedItem={newValue => {
              setSelectedItem(newValue)
              setOpenPopUpModal(false)
            }}
          />
        </div>
        <NavBarV2
          onMenuClick={menuActive ? handleCloseMenu : onMenuClick}
          menuActive={menuActive}
          isCartOpen={isCartOpen}
          setIsCartOpen={setIsCartOpen}
          story={story}
          selectedItem={selectedItem}
          setSelectedItem={newValue => {
            setSelectedItem(newValue)
            setOpenPopUpModal(false)
          }}
        />
        {utilityBar?.content?.show === true && (
          <header className="h-[auto]">
            <UtilityBar data={utilityBar} />
          </header>
        )}

        <CartDrawer
          cartRef={cartRef}
          isMenuActive={addToCartSidebar}
          onClose={() => closeAddToCartSideBar()}
        />
        <div className="hidden">
          <AddToCartBanner setIsCartOpen={setIsCartOpen} />
        </div>
      </div>
    </>
  )
}

export default Header
