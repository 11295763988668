/* eslint-disable */
import React, { useCallback, useContext, useState, useEffect } from "react"
import { StoreContext } from "../../context/StoreContext"
import { LiteChaserContext } from "../../context/LiteChaserContext"

const LiteChaserGripSelector = ({ product }) => {
  const { variants } = product
  const { client } = useContext(StoreContext)
  const variantStorefrontId = variants[0].storefrontId
  const [disable, setDisable] = useState(false)
  const [mainName, setMainName] = useState("")
  const [subName, setSubName] = useState("")
  const [gripCompatibleModel] = useState(product.customMetafield.model.trim())

  // const [bgColor, setBgColor] = useState('bg-paloverde');
  const [toggle, setToggle] = useState(false)
  const [includedInKit, setIncludedInKit] = useState(false)
  const [available, setAvailable] = useState(false)
  const [priceValue, setPriceValue] = useState("0.00")

  const {
    kitIncludesSet,
    selectedPhoneModel,
    selectedKit,
    lineItemsSet,
    totalPrice,
    setTotalPrice,
    setKitIncludesSet,
    updateLineItemsSet,
  } = useContext(LiteChaserContext)

  useEffect(() => {
    const tempMain = product?.customMetafield?.selector_name?.main
    const tempSub = product?.customMetafield?.selector_name?.sub

    setMainName(tempMain)
    setSubName(tempSub)
  })

  const checkAvailablity = useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        const result = fetchedProduct
          ? fetchedProduct.variants.filter(
              variantProduct => variantProduct.id === variantStorefrontId,
            )
          : []

        if (result.length > 0) {
          setAvailable(result[0].available)
          setDisable(!result[0].available)

          if (result[0].available) {
            setPriceValue("$" + product.priceRangeV2.maxVariantPrice.amount)
          } else {
            setPriceValue("OUT OF STOCK")
          }
        } else {
          setPriceValue("OUT OF STOCK")
        }
      })
    },
    [variantStorefrontId, client.product],
  )

  useEffect(() => {
    checkAvailablity(product.storefrontId, variantStorefrontId)
  }, [variantStorefrontId, checkAvailablity, product.storefrontId])

  useEffect(() => {
    let newLineItemsSet = lineItemsSet
    if (gripCompatibleModel !== selectedPhoneModel.trim()) {
      setIncludedInKit(false)
      setToggle(false)
      setDisable(true)
    } else if (
      gripCompatibleModel === selectedPhoneModel.trim() &&
      !selectedKit.toLowerCase().includes("custom")
    ) {
      setIncludedInKit(true)
      setToggle(true)
      setDisable(true)
    } else if (selectedKit.toLowerCase().includes("custom")) {
      setIncludedInKit(false)
      setToggle(false)
      setDisable(false)
    } else {
      setToggle(false)
      setIncludedInKit(false)
      setDisable(false)
    }

    if (newLineItemsSet.has(variantStorefrontId)) {
      newLineItemsSet.delete(variantStorefrontId)
      updateLineItemsSet(newLineItemsSet)
    }
  }, [selectedPhoneModel, selectedKit])

  const onSelect = e => {
    e.preventDefault()
    let newLineItemsSet = lineItemsSet
    let tempTotalPrice = totalPrice
    const tempKitIncludesSet = new Set(kitIncludesSet)
    const itemName = product.title

    if (!toggle) {
      newLineItemsSet.add(variantStorefrontId)
      tempKitIncludesSet.add(itemName.slice(0, itemName.lastIndexOf("|") - 1))
      tempTotalPrice += Number.parseInt(
        Number.parseFloat(product.variants[0].price) * 100,
      )
      setTotalPrice(tempTotalPrice)
    } else {
      newLineItemsSet.delete(variantStorefrontId)
      tempKitIncludesSet.delete(
        itemName.slice(0, itemName.lastIndexOf("|") - 1),
      )
      tempTotalPrice -= Number.parseInt(
        Number.parseFloat(product.variants[0].price) * 100,
      )
      setTotalPrice(tempTotalPrice)
    }

    setKitIncludesSet(tempKitIncludesSet)
    updateLineItemsSet(newLineItemsSet)
    setToggle(!toggle)
  }

  return (
    <button
      className={`w-[100px] h-[100px] p-2 m-2 ${
        toggle
          ? "bg-paloverde text-white border-paloverde border-[1px]"
          : "border-obsidian border-[1px]"
      } ${(disable && !includedInKit) || !available ? "opacity-50" : ""}`}
      disabled={disable}
      onClick={onSelect}
    >
      <span className="font-bold text-xs font-condensed">{mainName}</span>
      <br />
      <span className="font-bold text-xxs">{subName}</span>
      <span className="font-bold text-xs font-condensed">{priceValue}</span>
    </button>
  )
}

export default LiteChaserGripSelector
