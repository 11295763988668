import React, { useRef, useState } from "react"
import MobileAccountSidebar from "../AccountSidebar/mobile"
import { navigate } from "gatsby"
import FormError from "../FormError"
import { FormspreeProvider, useForm, ValidationError } from "@formspree/react"

const ContactSupport = () => {
  const [msg, setMsg] = useState<string>("")
  const [success, setSuccess] = useState<any>("")
  const [fileFormatMessage, setFileFormatMessage] = useState("")
  const [state, handleSubmit] = useForm(process.env.GATSBY_FORMSPREE_ID ?? "")
  const [fields, setFields] = useState<{
    name: string
    email: string
    order_number: string
    sku: string
    subject: string
    description: string
    image: File | null
  }>({
    name: "",
    email: "",
    order_number: "",
    sku: "",
    subject: "",
    description: "",
    image: null,
  })

  const profilePictureRef = useRef<HTMLInputElement>(null)
  const handleInputClick = () => {
    if (profilePictureRef.current) {
      profilePictureRef.current.click()
    }
  }
  const saveFile = async (file: File) => {
    setFields({ ...fields, image: file })
  }

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!state.submitting) {
      try {
        await handleSubmit(event)
        setSuccess("Thank you, we will process your submission shortly")
        navigate("/support/support-thanks")
      } catch (error) {
        setMsg("Failed to submit form.")
      }
    }
  }

  return (
    <FormspreeProvider project={process.env.GATSBY_FORMSPREE_ID}>
      <div className="w-full">
        <MobileAccountSidebar />
        <div
          style={{ backgroundImage: "url(/images/BACKGROUND.webp)" }}
          className="bg-white py-12 w-full"
        >
          <h1 className="text-center text-[50px] text-[#ffffff] font-helvetica-neue pt-10">
            Contact Us
          </h1>
          <div className="w-full flex flex-col justify-center md-w-[90%]">
            <form
              onSubmit={submitHandler}
              action={process.env.GATSBY_FORMSPREE_ID}
              method="POST"
            >
              <div className="relative  flex justify-center  mb-1 pt-6">
                <input
                  placeholder="Name*"
                  type="text"
                  name="name"
                  required
                  value={fields.name}
                  onChange={e => setFields({ ...fields, name: e.target.value })}
                  className="lg:w-[719px] w-[90%] py-2 px-1  rounded-sm pl-2 lg:placeholder-[#000000] placeholder-[#888888]  placeholder:font-helvetica-neue"
                />
              </div>
              <div className="relative mb-1  flex justify-center">
                <input
                  type="email"
                  required
                  name="email"
                  value={fields.email}
                  onChange={e =>
                    setFields({ ...fields, email: e.target.value })
                  }
                  placeholder="Email*"
                  className="lg:w-[719px] w-[90%] py-2 px-1 rounded-sm pl-2 lg:placeholder-[#000000] placeholder-[#888888]  placeholder:font-helvetica-neue"
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </div>
              <div className="relative mb-1  flex justify-center">
                <input
                  placeholder="Order Number (if Applicable)"
                  type="text"
                  name="orderNumber"
                  value={fields.order_number}
                  onChange={e =>
                    setFields({ ...fields, order_number: e.target.value })
                  }
                  className="lg:w-[719px] w-[90%] py-2 px-1 rounded-sm pl-2 lg:placeholder-[#000000] placeholder-[#888888]  placeholder:font-helvetica-neue"
                />
              </div>
              <div className="relative mb-1  flex justify-center">
                <input
                  type="text"
                  placeholder="Product Name / SKU"
                  name="productName"
                  required
                  value={fields.sku}
                  onChange={e => setFields({ ...fields, sku: e.target.value })}
                  className="lg:w-[719px] w-[90%] py-2 px-1 rounded-sm pl-2 lg:placeholder-[#000000] placeholder-[#888888]  placeholder:font-helvetica-neue"
                />
              </div>
              <div className="relative mb-1  flex justify-center">
                <input
                  type="text"
                  placeholder="Subject*"
                  name="subject"
                  required
                  value={fields.subject}
                  onChange={e =>
                    setFields({ ...fields, subject: e.target.value })
                  }
                  className="lg:w-[719px] w-[90%] py-2 px-1 rounded-sm pl-2 lg:placeholder-[#000000] placeholder-[#888888]  placeholder:font-helvetica-neue"
                />
              </div>
              <div className="flex justify-center mt-1 ">
                <textarea
                  required
                  name="help"
                  value={fields.description}
                  onChange={e =>
                    setFields({ ...fields, description: e.target.value })
                  }
                  placeholder="What can we help you with*"
                  className="lg:w-[719px] w-[90%] h-[150px] px-2 pt-2 rounded-sm lg:placeholder-[#000000] placeholder-[#888888] font-helvetica-neue"
                />
              </div>
              <div className="w-full flex justify-center">
                <div className="flex flex-col ">
                  <div className="flex md:gap-8 gap-4 justify-start lg:w-[719px] w-full mt-6">
                    <span className="text-white font-helvetica-neue text-[25px] font-bold">
                      Attachments
                    </span>
                    <button
                      type="button"
                      onClick={handleInputClick}
                      className="text-white"
                    >
                      <img
                        className="w-[24px] h-[24px]"
                        src="/images/ATTACHMENTS.png"
                        alt=""
                      />
                    </button>
                    <input
                      type="file"
                      name="attachment"
                      accept="*"
                      ref={profilePictureRef}
                      className="w-full pb-2 bg-transparent hidden"
                      max={10000000}
                      onChange={e => {
                        if (e.target.files?.length) {
                          const selectedFileType = e.target.files[0].type
                          const acceptedTypes = [
                            "image/jpeg",
                            "image/png",
                            "video/mp4",
                            "video/quicktime",
                          ]
                          if (acceptedTypes.includes(selectedFileType)) {
                            saveFile(e.target.files[0])
                            setFileFormatMessage("")
                          } else {
                            setFileFormatMessage(
                              "Please select JPG, PNG, MP4, or MOV files only.",
                            )
                            setFields(old => ({ ...old, image: null }))
                          }
                        }
                      }}
                    />
                    <div className="flex justify-center items-center">
                      {fields?.image?.name && (
                        <img
                          className="w-6 h-6 mr-[3px]"
                          src="/images/FILES.png"
                          alt="File"
                        />
                      )}
                      <p className=" bg-white block text-black rounded-lg  px-2">
                        {fields?.image?.name}
                      </p>
                    </div>
                  </div>
                  {fileFormatMessage.length && (
                    <p className="text-red-500">{fileFormatMessage}</p>
                  )}
                </div>
              </div>
              {!!msg?.length && <FormError msg={msg} />}
              <div className="flex justify-center">
                <button
                  type="submit"
                  disabled={!!success?.length && state.submitting}
                  className=" text-white px-[60px] opacity-90 hover:opacity-100 transition-opacity py-4 font-sans  rounded-md mt-10 bg-paloverde font-bold"
                >
                  {success ? (
                    <span className="text-xs  ">{success}</span>
                  ) : (
                    "SEND"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </FormspreeProvider>
  )
}

export default ContactSupport
