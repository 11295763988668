/* eslint-disable camelcase */
import React from "react"

const ExploreCollectionCard = ({ blok }) => {
  const { product_handle } = blok

  return <div>{product_handle}</div>
}

export default ExploreCollectionCard
