import { useEffect } from "react"

declare global {
  interface Window {
    Widgetic: any
  }
}

export const WidgetScript = () => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://widgetic.com/sdk/sdk.js"
    script.async = true
    document.head.appendChild(script)

    const handleScriptLoad = () => {
      window.Widgetic.UI.parse()
    }

    script.onload = handleScriptLoad

    return () => {
      script.remove()
    }
  }, [])
  return null
}
