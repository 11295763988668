/* eslint-disable */
import React from "react"
import { render } from "storyblok-rich-text-react-renderer"
import { Link } from "gatsby"

const FAQItem = ({ blok }) => {
  const { FAQVideoTitle, VideoEmbed } = blok
  return (
    <div className="item">
      <p className="font-bold">{FAQVideoTitle}</p>
      <div className="videoWrapper">
        <iframe
          width="587"
          height="330"
          src={VideoEmbed}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  )
}

export default FAQItem
