/* eslint-disable
  jsx-a11y/anchor-has-content,
  jsx-a11y/no-noninteractive-element-interactions,
  radix
*/
import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import {
  calculateTotalValue,
  calculateCurrentValue,
} from "./podcastPlayerHelper"

const PodcastPlayer = props => {
  // Get featured podcast src url from props
  const {
    featuredPodcastSrc,
    // isAnyPodcastPlaying,
    // setIsAnyPodcastPlaying
  } = props

  // Initialize states
  const [currentTime, setCurrentTime] = useState("00:00")
  const [totalTime, setTotalTime] = useState("00:00")
  const [totalSeconds, setTotalSeconds] = useState(0)
  const [currentTimeRatio, setCurrentTimeRatio] = useState(0)
  // The below state will track whether the podcast is supposed to be played or not
  const [isEpisodePlaying, setIsEpisodePlaying] = useState(false)
  // Initialize object that contains button class and labels for when podcast plays and pauses
  const playBtnPlayMetadata = {
    class: "play gh-play-button",
    ariaLabel: "play button",
    label: "play",
  }
  const playBtnPauseMetadata = {
    class: "play gh-play-button pause",
    ariaLabel: "pause button",
    label: "pause",
  }
  const [playBtnCurrentClass, setPlayBtnCurrentClass] =
    useState(playBtnPauseMetadata)

  // Create references
  const audioRef = useRef(
    typeof window !== "undefined" ? new Audio(featuredPodcastSrc) : null,
  )
  const intervalRef = useRef()

  // Add event listeners to show total time, play time, and pause button when podcast is done
  audioRef.current.addEventListener("loadedmetadata", () => {
    setTotalSeconds(audioRef.current.duration)
    setTotalTime(calculateTotalValue(audioRef.current.duration))
  })

  audioRef.current.addEventListener("loadeddata", () => {
    setCurrentTime(calculateCurrentValue(audioRef.current.currentTime))
  })

  audioRef.current.addEventListener("ended", () => {
    setIsEpisodePlaying(!isEpisodePlaying)
  })

  // If isEpisodePlaying state changes to true, play podcast and vice versa
  useEffect(() => {
    if (isEpisodePlaying) {
      audioRef.current.play()
      setPlayBtnCurrentClass(playBtnPauseMetadata)
      intervalRef.current = setInterval(() => {
        setCurrentTimeRatio(audioRef.current.currentTime / totalSeconds)
        setCurrentTime(calculateCurrentValue(audioRef.current.currentTime))
      }, 200)
    } else {
      audioRef.current.pause()
      setPlayBtnCurrentClass(playBtnPlayMetadata)
      clearInterval(intervalRef)
    }
  }, [isEpisodePlaying])

  // Compute the relative position that user clicked on progress bar to play
  // the podcast at desired time
  const computeProgressPosition = e => {
    e.preventDefault()
    const elementRect = e.currentTarget.getBoundingClientRect()
    const progressBarWidth = elementRect.right - elementRect.left
    const userPosition = (e.clientX - elementRect.left) / progressBarWidth

    e.currentTarget.value = userPosition
    audioRef.current.currentTime = Number.parseInt(
      userPosition * Number.parseInt(totalSeconds),
    )
  }

  return (
    // Golden Hour Podcast player
    <div className="audio-player">
      <div className="play-bar-wrapper">
        <button
          type="button"
          name="button"
          data-time="0"
          data-src={featuredPodcastSrc}
          onClick={() => setIsEpisodePlaying(!isEpisodePlaying)}
          className={playBtnCurrentClass.class}
          aria-label={playBtnCurrentClass.ariaLabel}
        >
          {playBtnCurrentClass.label}
        </button>
        <div className="audio-wrapper" id="player-container" />
        <small style={{ fontSize: "15px;" }} className="start-time">
          {currentTime}
        </small>
        <div className="player-controls scrubber">
          <span id="seekObjContainer">
            <progress
              className="seekObj"
              value={currentTimeRatio}
              max="1"
              onClick={computeProgressPosition}
              onKeyUp={computeProgressPosition}
            />
          </span>
          <br />
        </div>
        <small style={{ fontSize: "15px;" }} className="end-time">
          {totalTime}
        </small>
      </div>
    </div>
  )
}

export default PodcastPlayer

// Validate prop types
PodcastPlayer.propTypes = {
  featuredPodcastSrc: PropTypes.string,
}

PodcastPlayer.defaultProps = {
  featuredPodcastSrc: PropTypes.string,
}
