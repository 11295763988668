/* eslint-disable  */

import * as React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import * as CustomStyle from "./style.module.scss"
import { useLocation } from "@reach/router"

const SecondaryButton = props => {
  const location = useLocation()

  const { text, link, buttonStyle = "default", isActive } = props
  let buttonClasses = `${CustomStyle.button} font-bold font-expanded uppercase transform transition-all hover:translate-x-0 md:hover:translate-x-1 text-[.7rem] leading-[.8rem]`

  if (buttonStyle === "white") {
    buttonClasses = `${buttonClasses} text-white ${CustomStyle.white}`
  } else if (buttonStyle === "obsidian") {
    buttonClasses = `${buttonClasses} obsidian`
  } else if (buttonStyle === "gold") {
    buttonClasses = `${buttonClasses} text-paloverde ${CustomStyle.paloverde}`
  } else if (buttonStyle === "gold-hover") {
    buttonClasses = `${buttonClasses} text-white hover:text-paloverde ${CustomStyle.paloverde}`
  } else {
    buttonClasses = `${buttonClasses} text-obsidian`
  }

  if (isActive) {
    buttonClasses = `${buttonClasses} translate-x-1`
  }

  return (
    <button type="button" className={buttonClasses}>
      <div
        className={`${CustomStyle.triangle} ${
          isActive ? CustomStyle.isActive : ""
        }`}
      />
      <Link
        to={`${link}${link.endsWith("/") ? "" : "/"}${
          location.search ? location.search : ""
        }`}
      >
        {text}
      </Link>
    </button>
  )
}

export default SecondaryButton

// Validate prop types
SecondaryButton.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  buttonStyle: PropTypes.string,
  isActive: PropTypes.bool,
}

SecondaryButton.defaultProps = {
  text: PropTypes.string,
  link: PropTypes.string,
  buttonStyle: PropTypes.string,
  isActive: false,
}
