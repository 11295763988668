/* eslint-disable */
import React, { useState } from "react"
import { FaPlus, FaMinus } from "react-icons/fa"

const Accordion = ({ title, content }) => {
  const [expanded, setExpanded] = useState(false)
  const handleClick = () => {
    setExpanded(!expanded)
  }
  return (
    <div>
      <FaPlus
        className={`${
          expanded ? "hidden" : "block"
        } pointer-events-none float-right`}
      />
      <FaMinus
        className={`${
          expanded ? "block" : "hidden"
        } pointer-events-none float-right`}
      />
      <div class="" onClick={handleClick}>
        <h3>{title}</h3>
      </div>
      <div
        class={`${expanded ? "visible" : "hidden"} mb-10`}
        style={{ display: expanded ? "block" : "none" }}
      >
        {content}
      </div>
    </div>
  )
}

export default Accordion
