/* eslint-disable */
import React from "react"
import PlayIcon from "../../assets/PlayIcon"

const FocusPPOriginal = ({ article, onClickPlay }) => {
  // Initialize focus articles metadata variables
  const pporiginalSrc = article.node.videoUrl.value
  const pporiginalEpisode = article.node.seriesType.value
  const pporiginalBy = article.node.by.value
  const pporiginalByImage = article.node.byImg.value

  const handleClickPlay = () => {
    onClickPlay(pporiginalSrc.replace("https://vimeo.com/", ""))
  }

  return (
    <div className="episode-item" onClick={handleClickPlay}>
      <div className="image-container">
        <img src={article.node.image.src} />
        <div className="play-container">
          <div id="inline" className="play-button x-y-center" />
        </div>
      </div>
      <div className="data">
        <div className="episode teal">{pporiginalEpisode}</div>
        <h3 className="font-black">{article.node.title}</h3>
        <p className="article-blurp">{article.node.content}</p>
        <div onClick={handleClickPlay} className="md:hidden absolute">
          <PlayIcon />
        </div>
        <div className="episode-by hidden md:block">
          <img src={pporiginalByImage}></img>
          <p className="name-by font-bold">{pporiginalBy}</p>
        </div>
      </div>
    </div>
  )
}

export default FocusPPOriginal
