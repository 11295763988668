import React from "react"
import SecondaryButton from "../SecondaryButton"
import { useLocation } from "@reach/router"

const items = [
  {
    title: "My Account",
    link: "/account",
  },
  {
    title: "Shipping Address(s)",
    link: "/account/shipping-address/",
  },
  {
    title: "Order History",
    link: "/account/order-history",
  },
  {
    title: "Returns",
    link: "https://polarpro.loopreturns.com/#/",
  },
  {
    title: "Contact Support",
    link: "/account/contact-support/",
  },
]

const AccountSidebar = () => {
  const location = useLocation()
  return (
    <div className="sticky hidden lg:flex left-0 top-0 bottom-0 h-screen space-y-8 z-[10] bg-[#1C1C1C] flex-col w-80 p-12 pl-0 justify-center items-end justify-items-end">
      {items.map((item, idx) => (
        <div
          key={idx}
          className="text-white pb-6 border-b border-gray-500 min-w-[200px]"
        >
          <SecondaryButton
            buttonStyle={
              location.pathname === item.link ||
              location.pathname === `${item.link}/`
                ? "gold"
                : "gold-hover"
            }
            isActive={
              location.pathname === item.link ||
              location.pathname === `${item.link}/`
            }
            text={item.title}
            link={item.link}
          ></SecondaryButton>
        </div>
      ))}
    </div>
  )
}

export default AccountSidebar
