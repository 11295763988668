/* eslint-disable */
import React, { useState, useEffect } from "react"
// import { Link } from "gatsby";
import loadable from "@loadable/component"
import * as style from "./style.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import { useStoryblok } from "../../utils/storyblok"
import DynamicComponents from "../DynamicComponents"
import useScrollBlock from "../../hooks/useScrollBlock"

import PlayIcon from "../Icons/PlayIcon"

const ModalVideo = loadable(() => import("react-modal-video"))

const BelowShortstacheOverview = ({
  product: variantProduct,
  dynamicATCProducts,
}) => {
  const [showVideo, setShowVideo] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()

  const scrollUp = e => {
    e.preventDefault()
    window.scrollTo({
      top: 0,
      behaviour: "smooth",
    })
  }

  useEffect(() => {
    if (showVideo) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [showVideo])

  const pageData = useStaticQuery(graphql`
    query {
      storyblokEntry(full_slug: { eq: "/products/shortstache-black-mist-polarizer-filter" }) {
        id
        name
        content
        slug
        full_slug
        uuid
        created_at
      }
      shopifyProduct(handle: { eq: "shortstache-black-mist-polarizer-filter" }) {
        handle
        title
      }
    }
  `)

  const product = pageData.shopifyProduct

  const story = pageData.storyblokEntry
    ? useStoryblok(pageData.storyblokEntry)
    : { content: null }

  const stickyNavComponent =
    story.content && story.content["sticky_nav"]
      ? story.content["sticky_nav"].map(blok => (
        <>
          {blok ? (
            <div {...sbEditable(blok)} className="sticky top-0 z-50">
              <DynamicComponents
                blok={blok}
                product={product}
                key={blok._uid}
              />
            </div>
          ) : null}
        </>
      ))
      : null

  return (
    <div className={`${style.fourbyfiveMchOverview} overflow-hidden`}>
      <div class={`${style.productHero} text-white flex flex-col lg:flex-row`}>
        <div class={`${style.introData}`}>
          <div class="video-container">
            <a
              href="#data"
              id="inline"
              className={`${style.MobilePlayBtn} cta-round opacity-90 hover:opacity-100`}
              onClick={e => {
                e.preventDefault()
                setShowVideo(true)
              }}
            >
              <PlayIcon />
            </a>
          </div>
        </div>
        <div class={`${style.introDataRight} pl-0 lg:pl-20`}>
          <img
            className="w-full"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/SHORTSTACHE-LOGO-UX-PAGE.png?v=1708802651"
            alt="Shortstache"
          />
          {/* <h2 className="display-font-logo font-bold font-expanded uppercase">
            Helix
          </h2> */}
          <div className="w-11/12	">
            <p className="hidden lg:block">
              Garrett King’s staple filter and one that is called upon everyday, the polarizer delivers a crisp and pristine visual, which is complemented by the subtle halation and blooming that the mist diffusion provides in the highlights for filmic character.
            </p>
            <p className="block lg:hidden">
              Garrett King’s staple filter and one that is called upon everyday, the polarizer delivers a crisp and pristine visual, which is complemented by the subtle halation and blooming that the mist diffusion provides in the highlights for filmic character.
            </p>
          </div>
        </div>
        {/* modal */}
        <ModalVideo
          channel="youtube"
          videoId="91K0vbVbJVA?si=ieO7vjfBOyOL2w9R"
          isOpen={showVideo}
          onClose={() => setShowVideo(false)}
        />
      </div>
      <div className={`${style.breakBanner} bg-black py-28`}>
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Designed_To_Help_You_Tell_Your_Story_Title_11c14cf4-7696-4826-a18b-62a2ff96a203.png?v=1708988349"
          alt="Thread it and forget it"
        />
        <div className={`${style.dataRight} pl-5 hidden lg:block`}>
          <img
            class="w-5/6 m-0"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Polarizer_mist_combo_filterthread_it_and_forget_it..png?v=1708988326"
            alt="Thread it and forget it"
          />
        </div>
      </div>

      <div className={`${style.sandBackg} feature-block max-w-full m-auto`}>
        <div className="flex items-center flex-col lg:flex-row">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black  lg:leading-[1.1rem] text-2xl lg:text-sm xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-white mb-2 lg:md-8">
              Signature Matte Green Aluminum Frame
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-white">
              Shortstache’s signature colorway gives the filter a unique, low profile look on your lens.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-white m-0">
              Shortstache’s signature colorway gives the filter a unique, low profile look on your lens.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="w-full m-0 block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/V_Sbio2k.webp?v=1708803389"
              alt=""
            />
          </div>
        </div>
        <img
          class="w-full m-0 hidden lg:block"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Group_1945.webp?v=1708803428"
          alt=""
        />
        <img
          class="w-full m-0 block lg:hidden"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Group_1946.webp?v=1708803439"
          alt=""
        />
        <div className="flex items-center flex-col lg:flex-row-reverse">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black text-3xl lg:leading-[1.1rem] lg:text-base xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-white mb-2 lg:md-8">
              Choose from two diffusion strengths
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-white">
              EveryDay 1/4 Strength - Black Mist provides subtle halation and blooming of highlights while retaining depth in shadows/black tones. This gives your image additional dimension and character in camera<br /><br />
              Mist + PL 1/2 Strength - A more stylized filmic look with a stronger effect. This blooms light sources giving your image even more dimension than the Everyday version. Emulates a look similar to filmic motion pictures.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-white m-0">
              EveryDay 1/4 Strength - Black Mist provides subtle halation and blooming of highlights while retaining depth in shadows/black tones. This gives your image additional dimension and character in camera<br /><br />
              Mist + PL 1/2 Strength - A more stylized filmic look with a stronger effect. This blooms light sources giving your image even more dimension than the Everyday version. Emulates a look similar to filmic motion pictures.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="w-full m-0 block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/LdAjNOar_9ab55d1f-9143-459a-bc6e-3d2dce644796.webp?v=1708803745"
              alt=""
            />
          </div>
        </div>
      </div>

      <div
        className={`${style.breakBanner} ${style.leftStrike} bg-black py-28`}
      >
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/SUBTLE_BLACK_MIST_DIFFUSION_Emulates_GarrettKing_s_Photo_Look.png?v=1709660987"
          alt="Black Mist Diffusion"
        />
        <div className={`${style.dataLeft} pr-5 hidden lg:block`}>
          <img
            class="w-5/6 m-0 inline-block"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/SUBTLE_BLACK_MIST_DIFFUSION_Emulates_Garrett_King_s_Photo_Look.png?v=1708988326"
            alt="Black Mist Diffusion"
          />
        </div>
      </div>

      <div className={`${style.sandBackg} feature-block2 max-w-full m-auto`}>
        <div className="flex items-center flex-col lg:flex-row">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black lg:leading-[1.1rem] text-3xl lg:text-base xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-white mb-2 lg:md-8">
              From Film to Run N Gun
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-white">
              The Combination of a polarizer for powerful reflection reduction and black mist for softening highlights create a subtle look from film photography to commercial video shoots.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-white m-0">
              The Combination of a polarizer for powerful reflection reduction and black mist for softening highlights create a subtle look from film photography to commercial video shoots.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="w-full m-0 block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/cHqMUQfK.webp?v=1708803857"
              alt=""
            />
          </div>
        </div>

        <img
          class="w-full m-0 hidden lg:block"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Lifestyle_Image_Block_2_c21bb099-291b-482a-bbe7-567fa331749f.webp?v=1708803862"
          alt=""
        />
        <img
          class="w-full m-0 block lg:hidden"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Group_1944.webp?v=1708804047"
          alt=""
        />


        <div className="flex items-center flex-col lg:flex-row-reverse">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black lg:leading-[1.1rem] text-3xl lg:text-base xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-white mb-2 lg:md-8">
              New Chroma Polarizer Glass
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-white">
              All new polarizer tech enabled us to create a polarizer filter which provides perfect color neutrality giving you the perfect base to edit from.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-white m-0">
              All new polarizer tech enabled us to create a polarizer filter which provides perfect color neutrality giving you the perfect base to edit from.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="w-full m-0 block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/84yF_yOk.webp?v=1708803874"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className={`${style.breakBanner} bg-black py-28`}>
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Combines_these_two_effects_For_Zero_Vignettingand_Ultra_Slim_Profile.png?v=1708988349"
          alt=""
        />
        <div className={`${style.dataRight} pl-5 hidden lg:block`}>
          <img
            class="w-full m-0"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Combines_these_two_effects_For_Zero_Vignetting_and_Ultra_Slim_Profile.png?v=1708988326"
            alt=""
          />
        </div>
      </div>

      <div className="launchCommercial">
        <div className="flex flex-col lg:flex-col">
          <div>
            <img
              className="m-auto hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Group_1936.webp?v=1708985521"
              alt=""
            />
            <img
              className="m-auto block lg:hidden w-full"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Group_1943.webp?v=1708985991"
              alt=""
            />
          </div>
          <div>
            <img
              className="m-auto hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/0pfoRcsE.webp?v=1708985521"
              alt=""
            />
            <img
              className="m-auto block lg:hidden w-full"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/0pfoRcsE_f02ac509-7729-4d54-951c-7986e36b021c.webp?v=1708985990"
              alt=""
            />
          </div>
        </div>
      </div>

      <div
        className={`${style.breakBanner} ${style.leftStrike} bg-black py-28`}
      >
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Professional_Diffusion_3_Strength_Options_Title.png?v=1708988349"
          alt="PROFESSIONAL DIFFUSION 2 STRENGTH OPTIONS m"
        />
        <div className={`${style.dataLeft} pr-5 hidden lg:block`}>
          <img
            class="w-1/2 m-0 inline-block"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/PROFESSIONAL_DIFFUSION_2_STRENGTH_OPTIONS..png?v=1708988326"
            alt="PROFESSIONAL DIFFUSION 2 STRENGTH OPTIONS"
          />
        </div>
      </div>

      <div className="before-after">
        <widgetic
          class="desktop-only"
          id="65cfc9a5ecb2a1f94f8b4567"
          resize="fill-width"
          width="2374"
          height="925"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <widgetic
          class="mobile-only"
          id="65cfc53fecb2a11f4d8b4567"
          resize="fill-width"
          width="1284"
          height="1788"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <div className="data bg-black m-0 text-white text-center pt-5 pb-5">
          <h2 className="title font-black mb-2">EVERYDAY FILTER (1/4)</h2>
          <p className="text-[12px]">
            Provides subtle halation and blooming of highlights, while retaining depth in shadows/dark tones.
          </p>
        </div>
      </div>

      <div className="before-after">
        <widgetic
          class="desktop-only"
          id="65cfcd14ecb2a10d528b4567"
          resize="fill-width"
          width="2374"
          height="925"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <widgetic
          class="mobile-only"
          id="65cfccbfecb2a1cb518b4567"
          resize="fill-width"
          width="1284"
          height="1788"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <div className="data bg-black m-0 text-white text-center pt-5 pb-5">
          <h2 className="title font-black mb-2">MIST-PL (1/2 MIST DIFFUSION)</h2>
          <p className="text-[12px]">
            A more stylized, filmic strength to emulate vintage looks.
          </p>
        </div>
      </div>
      <div className="launchCommercial pt-20 bg-black">
        <div className="flex flex-col lg:flex-col">
          <div>
            <img
              className="m-auto hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Group_1897_e4b52d74-9f97-4303-8dd3-c9baa7e7ff4b.webp?v=1708986878"
              alt=""
            />
            <img
              className="m-auto block lg:hidden w-full"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Group_1942.webp?v=1708986858"
              alt=""
            />
          </div>
          <div>
            <img
              className="m-auto hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/34ZuBMVZ_ba6426d9-be9a-401b-b5b4-c256322fa669.webp?v=1708986878"
              alt=""
            />
            <img
              className="m-auto block lg:hidden w-full"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/34ZuBMVZ.webp?v=1708986858"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BelowShortstacheOverview
