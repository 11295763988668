/* eslint-disable */
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react"
import PropTypes from "prop-types"
import { StoreContext } from "./StoreContext"
import hashCode from "../utils/UNSECURE_SimpleHashCode"

const defaultValues = {
  phoneModels: [],
  phoneModelSet: new Set(),
  productsByParts: {},
  selectedPhoneModel: "",
  disableKitSelection: true,
  kits: [],
  kitIncludesSet: new Set(),
  kitProductsByHash: {},
  kitProductsById: {},
  lineItems: [],
  lineItemsSet: new Set(),
  selectedKit: "",
  selectedKitHash: 0,
  selectedCase: "",
  selectedCasesSet: new Set(),
  totalPrice: 0.0,
  totalComparePrice: 0.0,
  variantsToPartsMap: {},
  checkAvailablity: () => {},
  convertPhoneModelsList2Set: () => {},
  extractPartsProducts: () => {},
  extractKitsProducts: () => {},
  listPhoneModels: () => {},
  listKits: () => {},
  selectPhoneModel: () => {},
  selectKit: () => {},
  setSelectedCasesSet: () => {},
  setSelectedKitHash: () => {},
  // setTotalPrice: () => {},
  updateLineItemsSet: () => {},
  preorderDate: "",
}

export const LiteChaserContext = createContext(defaultValues)

// const isBrowser = typeof window !== 'undefined';
// const localStorageKey = 'litechaser_id';

export const LiteChaserProvider = ({ children }) => {
  const { client } = useContext(StoreContext)

  // Keep track of which model is selected
  const [disableKitSelection, setDisableKitSelection] = useState(true)
  const [phoneModels, setPhoneModels] = useState([])
  const [phoneModelSet, setPhoneModelsSet] = useState(new Set())
  const [selectedPhoneModel, setSelectedPhoneModel] = useState("")
  const [selectedCase, setSelectedCase] = useState("BLACK")
  const [selectedCasesSet, setSelectedCasesSet] = useState(new Set(["BLACK"]))
  const [kits, setKits] = useState([])
  const [kitIncludesSet, setKitIncludesSet] = useState(new Set())
  const [selectedKit, setSelectedKit] = useState("")
  const [selectedKitHash, setSelectedKitHash] = useState(0)
  const [productsByParts, setProductsByParts] = useState({})
  const [kitProductsByHash, setKitProductsByHash] = useState({})
  const [kitProductsById, setKitProductsById] = useState({})
  const [lineItemsSet, setLineItemsSet] = useState(new Set())
  const [totalPrice, setTotalPrice] = useState(0.0)
  const [totalComparePrice, setTotalComparePrice] = useState(0.0)
  const [variantsToPartsMap, setVariantsToPartsMap] = useState({})
  // state that keeps track of lineItem objects to be directly added to store cart
  const [lineItems, setLineItems] = useState([])
  const [preorderDate, setPreorderDate] = useState("")

  // Disable kit selection section if phone model is not selected
  useEffect(() => {
    if (disableKitSelection && selectedPhoneModel) {
      setDisableKitSelection(false)
    }
  }, [selectedPhoneModel])

  const listPhoneModels = models => setPhoneModels(models)

  const listKits = kitItems => setKits(kitItems)

  const convertPhoneModelsList2Set = list => setPhoneModelsSet(new Set(list))

  const selectPhoneModel = phone => setSelectedPhoneModel(phone.toLowerCase())

  const selectKit = kit => setSelectedKit(kit)

  const tempProductsByParts = {}
  const tempProductsByVariantId = {}

  const updateLineItemsSet = newLineItemsSet => {
    setLineItemsSet(newLineItemsSet)
    const lineItemsArr = []

    newLineItemsSet.forEach((key, val, set) => {
      const lineItem = {
        variantId: key,
        quantity: 1,
      }

      // if (kitProductsByHash[selectedKitHash] && kitProductsByHash[selectedKitHash].kitVariantId) {
      //   lineItem['customAttributes'] = "SKUS"
      // }

      if (preorderDate !== "") {
        lineItem["customAttributes"] = [
          { key: "Preorder - Shipping", value: preorderDate },
        ]
      }

      lineItemsArr.push(lineItem)
    })

    setLineItems(lineItemsArr)
  }

  const extractPartsProducts = productsData => {
    if (typeof productsData === "object") {
      productsData.forEach((product, index) => {
        const { metafields, variants } = product

        const productVariantId = variants[0].shopifyId.slice(
          variants[0].shopifyId.lastIndexOf("/") + 1,
        )

        for (const metafield of metafields) {
          if (
            metafield.key === "litechaser_components_metadata" &&
            metafield.value
          ) {
            const lcMetaData = JSON.parse(metafield.value)
            const sectionTitle = lcMetaData.type

            const productWithMeta = {
              ...product,
              customMetafield: { ...lcMetaData },
            }

            tempProductsByVariantId[productVariantId] = productWithMeta

            if (tempProductsByParts[sectionTitle]) {
              const newSectionItem = [
                ...tempProductsByParts[sectionTitle],
                productWithMeta,
              ]

              tempProductsByParts[sectionTitle] = newSectionItem
              break
            } else {
              tempProductsByParts[sectionTitle] = [productWithMeta]
              break
            }
          }
        }
      })

      const casesArr = tempProductsByParts["cases"]
      if (casesArr) {
        const orderedCases = new Array(casesArr.length)

        for (let i = 0; i < orderedCases.length; i++) {
          orderedCases[casesArr[i]?.customMetafield?.index] = casesArr[i]
        }

        tempProductsByParts["cases"] = orderedCases
      }

      setProductsByParts(tempProductsByParts)
      setVariantsToPartsMap(tempProductsByVariantId)
    }
  }

  const tempProductsByKits = {}
  const tempProductsByHash = {}

  const extractKitsProducts = kitProducts => {
    if (typeof kitProducts === "object") {
      kitProducts.forEach((product, index) => {
        const { metafields, variants } = product

        const productVariantId = variants[0].shopifyId.slice(
          variants[0].shopifyId.lastIndexOf("/") + 1,
        )

        tempProductsByKits[productVariantId] = product

        let productWithMeta
        let keyHash
        let kitSet = new Set()
        let kitSetArr = []
        let kitItemsKey = ""
        let kitItemsSetKey = ""
        let promoSet = new Set()
        let promoSetArr = []
        let promoItemsKey = ""
        let promoItemsSetKey = ""

        for (const metafield of metafields) {
          if (metafield.key === "litechaser_components_metadata") {
            const lcMetaData = JSON.parse(metafield.value)
            const kitHash = hashCode(
              lcMetaData.model.trim().toLowerCase() +
                lcMetaData.kit.trim().toLowerCase() +
                lcMetaData.color.trim().toLowerCase(),
            )

            const tempProductWithMeta = {
              ...product,
              customMetafield: { ...lcMetaData },
              kitVariantId: productVariantId,
            }

            productWithMeta = tempProductWithMeta
            keyHash = kitHash
          } else if (metafield.key === "kit_items" && metafield.value) {
            const kitItemObj = JSON.parse(metafield.value)
            kitSetArr = kitItemObj.items

            for (let kitItem of kitItemObj.items) {
              kitSet.add(kitItem.variantId)
            }

            kitItemsKey = metafield.key
            kitItemsSetKey = `${metafield.key}_set`
          } else if (metafield.key === "promo_items" && metafield.value) {
            const promoItemsObj = JSON.parse(metafield.value)

            promoSetArr = promoItemsObj.variantIds

            promoSet = new Set(promoItemsObj.variantIds)
            promoItemsKey = metafield.key
            promoItemsSetKey = `${metafield.key}_set`
          }
        }

        if (kitItemsKey) {
          productWithMeta[kitItemsKey] = kitSetArr
        }
        if (kitItemsSetKey) {
          productWithMeta[kitItemsSetKey] = kitSet
        }
        if (promoItemsKey) {
          productWithMeta[promoItemsKey] = promoSetArr
        }
        if (promoItemsSetKey) {
          productWithMeta[promoItemsSetKey] = promoSet
        }

        tempProductsByHash[keyHash] = productWithMeta
      })

      setKitProductsById(tempProductsByKits)
      setKitProductsByHash(tempProductsByHash)
    }
  }

  // Update selected Kit Hash code when phone model and kit is selected
  useEffect(() => {
    if (
      selectedPhoneModel &&
      selectedKit &&
      selectedCase &&
      !selectedKit.toLowerCase().includes("custom")
    ) {
      const kitHashKey = hashCode(
        selectedPhoneModel.trim().toLowerCase() +
          selectedKit.trim().toLowerCase() +
          selectedCase.trim().toLowerCase(),
      )
      const tempKitIncludesSet = new Set()

      for (let item of kitProductsByHash[kitHashKey]?.kit_items) {
        const itemName = variantsToPartsMap[item.variantId].title
        tempKitIncludesSet.add(itemName.slice(0, itemName.lastIndexOf("|") - 1))
      }

      setKitIncludesSet(tempKitIncludesSet)
      setSelectedKitHash(kitHashKey)

      if (
        kitProductsByHash[kitHashKey] &&
        kitProductsByHash[kitHashKey].variants
      ) {
        let tempLineItemsSet = new Set()
        let tempTotalPrice = 0
        let tempTotalComparePrice = 0
        tempLineItemsSet.add(
          kitProductsByHash[kitHashKey].variants[0].storefrontId,
        )
        tempTotalPrice += Number.parseInt(
          Number.parseFloat(kitProductsByHash[kitHashKey].variants[0].price) *
            100,
        )
        tempTotalComparePrice += Number.parseInt(
          Number.parseFloat(
            kitProductsByHash[kitHashKey].variants[0].compareAtPrice,
          ) * 100,
        )

        // if (kitProductsByHash[kitHashKey].promo_items) {
        //   for (let promo of kitProductsByHash[kitHashKey]?.promo_items) {
        //     tempLineItemsSet.add(variantsToPartsMap[promo].variants[0].storefrontId);
        //   }
        // }

        setTotalPrice(tempTotalPrice)
        setTotalComparePrice(tempTotalComparePrice)
        updateLineItemsSet(tempLineItemsSet)

        const preorderMetafieldArr = kitProductsByHash[
          kitHashKey
        ].variants[0].metafields.filter(
          metafield => metafield.key === "preorder-date",
        )

        let preorderDate = "pending"

        if (preorderMetafieldArr.length > 0) {
          preorderDate = preorderMetafieldArr[0].value
        }

        if (kitProductsByHash[kitHashKey].totalInventory <= 0) {
          setPreorderDate(preorderDate)
        }

        if (selectedCasesSet.size > 1) {
          setSelectedCasesSet(new Set([selectedCase]))
        }
      }
    } else if (
      selectedPhoneModel &&
      selectedKit &&
      selectedKit.toLowerCase().includes("custom")
    ) {
      const kitHashKey = hashCode(
        selectedPhoneModel.trim().toLowerCase() +
          selectedKit.trim().toLowerCase(),
      )

      setSelectedKitHash(kitHashKey)
    }
  }, [selectedPhoneModel, selectedKit, selectedCase, selectedKitHash])

  useEffect(() => {
    if (!selectedKit.toLowerCase().includes("custom") && !selectedCase) {
      setSelectedCase("BLACK")
      setSelectedCasesSet(new Set(["BLACK"]))
    }
  }, [selectedKit])

  return (
    <LiteChaserContext.Provider
      value={{
        kitIncludesSet,
        kits,
        kitProductsByHash,
        kitProductsById,
        lineItems,
        lineItemsSet,
        phoneModels,
        productsByParts,
        disableKitSelection,
        selectedKit,
        selectedKitHash,
        selectedPhoneModel,
        selectedCase,
        selectedCasesSet,
        totalPrice,
        totalComparePrice,
        variantsToPartsMap,
        setKitIncludesSet,
        setSelectedCasesSet,
        setSelectedKitHash,
        setTotalPrice,
        setVariantsToPartsMap,
        setSelectedCase,
        listPhoneModels,
        convertPhoneModelsList2Set,
        listKits,
        setKits,
        extractPartsProducts,
        extractKitsProducts,
        selectPhoneModel,
        selectKit,
        updateLineItemsSet,
        preorderDate,
      }}
    >
      {children}
    </LiteChaserContext.Provider>
  )
}

LiteChaserProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
