import React from "react"
import PropTypes from "prop-types"
import useScrollBlock from "../../hooks/useScrollBlock"
import CrossIcon from "../Icons/CrossIcon"

const Modal = ({ active = false, setActive, children }) => {
  const [blockScroll, allowScroll] = useScrollBlock()
  let display = "hidden"
  if (active === true) {
    display = "flex"
    blockScroll()
  } else {
    display = "hidden"
    allowScroll()
  }

  return (
    <div
      className={`${display} transition-all relative justify-center align-items-center`}
    >
      <div
        className={`flex fixed items-center justify-center inset-0  z-[999999999999999] bg-obsidian ${
          active ? "bg-opacity-90" : "bg-opacity-0"
        } w-screen h-screen overflow-y-hidden overflow-x-hidden overscroll-none transition-all duration-500`}
      >
        <button
          type="button"
          onClick={e => {
            e.preventDefault()
            setActive(false)
          }}
          className="absolute top-8 right-8"
        >
          <CrossIcon color="white" />
        </button>
        {children}
      </div>
    </div>
  )
}

export default Modal

Modal.propTypes = {
  active: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}
