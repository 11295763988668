import * as React from "react"
import PropTypes from "prop-types"

const WrittenLogoMark = props => {
  const { width = 193 } = props

  return (
    <img
      src="/images/2023-PolarPro-Wordmark-Final (3).svg"
      placeholder="tracedSVG"
      alt="PolarPro Standard Icon"
      style={{ width }}
      className="m-1 w-3/4 md:w-[95%] lg:w-[85%]"
    />
  )
}

export default WrittenLogoMark

WrittenLogoMark.propTypes = {
  width: PropTypes.number,
}

WrittenLogoMark.defaultProps = {
  width: PropTypes.number,
}
