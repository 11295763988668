/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react"
import { render } from "storyblok-rich-text-react-renderer"
import loadable from "@loadable/component"
import PlayIcon from "../Icons/PlayIcon"
import * as style from "./style.module.scss"

const ModalVideo = loadable(() => import("react-modal-video"))

const DynamicComparison = props => {
  const {
    explainerVideobanner,
    multipleSectionTitle,
    comparisonMainImage,
    allSliders,
  } = props
  const [showVideo, setShowVideo] = useState(false)
  const [testSlider, setTestSlider] = useState([])

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://widgetic.com/sdk/sdk.js"
    script.async = true
    document.head.appendChild(script)

    script.onload = e => {
      window.Widgetic.UI.parse()
    }

    return () => {
      script.remove()
    }
  })

  useEffect(() => {
    setTestSlider(allSliders)
  }, [allSliders])

  return (
    <div>
      {(explainerVideobanner?.length > 0 ||
        multipleSectionTitle?.length > 0 ||
        comparisonMainImage?.length > 0 ||
        allSliders?.length > 0) && (
        <div className="mt-6 pb-[60px] md:pb-[80px] bg-[#1C1C1C]">
          {/* Section 1 */}
          {explainerVideobanner !== undefined && explainerVideobanner?.[0] && (
            <div className=" h-[100vw] sm:h-[80vw] md:h-[50vw]  lg:h-auto w-full relative ">
              <img
                className={` object-cover lg:object-fill w-full  h-full`}
                src={
                  explainerVideobanner?.[0]?.background_image?.filename
                    ? explainerVideobanner?.[0]?.background_image?.filename
                    : ""
                }
                alt="explainerVideobanner"
              />
              {explainerVideobanner?.[0] && (
                <div
                  onKeyDown={() => setShowVideo(true)}
                  className="absolute top-0 z-[999999] inset-0 flex items-center justify-center cursor-pointer"
                  onClick={() => setShowVideo(true)}
                  role="button"
                >
                  <PlayIcon />
                </div>
              )}
              <div className="absolute top-0 right-0 text-white">
                <h1 className="text-[20px] md:text-[25px] lg:!text-[32px] uppercase tracking-wider pt-10 px-2 !font-[300] w-[90%] text-center leading-[29px] md:leading-[35px] lg:leading-[45px]">
                  {explainerVideobanner?.[0]?.title}
                </h1>
              </div>
              <ModalVideo
                channel="youtube"
                videoId={explainerVideobanner?.[0]?.video_id}
                isOpen={showVideo}
                onClose={() => setShowVideo(false)}
              />
            </div>
          )}

          {/* Section 2 */}
          {multipleSectionTitle !== undefined && (
            <div>
              <div className="w-full px-[15px] xl:px-28 pt-10 ">
                {multipleSectionTitle?.map((section, i) => {
                  const isEven = i % 2 === 0
                  const description = section?.subTitle
                    ? render(section.subTitle)
                    : ""
                  return (
                    <div
                      className={`w-full py-12 2xl:max-w-[1200px] m-auto flex justify-between text-center md:text-left flex-col items-center ${
                        !isEven ? "md:flex-row-reverse" : "md:flex-row"
                      }`}
                      key={i}
                    >
                      <div className="max-w-sm mx-5">
                        <h1 className="font-sans font-black text-white text-3xl md:text-36">
                          {section.tite}
                        </h1>
                        <p className="font-sans font-regular text-lg md:text-xl text-white">
                          {description}
                        </p>
                      </div>
                      <div className="w-full max-w-md rounded-lg">
                        <img
                          src={section?.image?.filename}
                          className="w-full object-cover h-auto m-0 rounded-lg"
                          alt={section.title}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>

              <div className=" h-[100vw] sm:h-[80vw] md:h-[50vw]  lg:h-auto w-full relative">
                <img
                  className="w-full h-full object-cover lg:object-fill"
                  src={comparisonMainImage}
                  alt="comparisonMainImage"
                />
              </div>
            </div>
          )}

          {testSlider?.map(item => (
            <div className="bg-[#1C1C1C]">
              {item?.order === "0" ? (
                <div
                  className={`${style.dynamicNewBanner} bg-[#1C1C1C] pt-12 pb-[50px]  md:py-24`}
                >
                  <div
                    className={`${style.dataRight} pl-5 hidden lg:block flex flex-col leading-[2px]`}
                  >
                    <p className="text-white leading !font-sans text-[12px] relative top-2 uppercase font-bold font-condensed">
                      {item?.SliderSubHeading ? item?.SliderSubHeading : ""}
                    </p>
                    <h1 className="uppercase !font-sans text-[32px] tracking-tighter text-[#c99e1a] font-extrabold font-condensed">
                      {item?.SliderMainHeading ? item?.SliderMainHeading : ""}
                    </h1>
                  </div>
                  <div
                    className={`  block text-center lg:hidden flex flex-col leading-[2px]`}
                  >
                    <p className="text-white !font-sans leading text-[12px] relative top-4 uppercase font-bold font-condensed">
                      {item?.SliderSubHeading ? item?.SliderSubHeading : ""}
                    </p>
                    <h1 className="uppercase !font-sans text-[32px] tracking-tighter text-[#c99e1a] font-extrabold font-condensed">
                      {item?.SliderMainHeading ? item?.SliderMainHeading : ""}
                    </h1>
                  </div>
                </div>
              ) : (
                <div
                  className={`${style.dynamicNewBannerRight} bg-[#1C1C1C] pt-12 pb-[50px]  md:py-[80px]`}
                >
                  <div
                    className={`${style.dataLeft} text-left   pl-5 hidden lg:block flex flex-col leading-[2px] uppercase`}
                  >
                    <p className="text-white leading !font-sans text-[12px] relative top-2 uppercase font-bold font-condensed">
                      {item?.SliderSubHeading ? item?.SliderSubHeading : ""}
                    </p>
                    <h1 className="uppercase !font-sans text-[32px] tracking-tighter text-[#c99e1a] font-extrabold font-condensed">
                      {item?.SliderMainHeading ? item?.SliderMainHeading : ""}
                    </h1>
                  </div>
                  <div
                    className={` text-center  block lg:hidden flex flex-col leading-[2px] uppercase`}
                  >
                    <p className="text-white leading !font-sans text-[12px] relative  uppercase font-bold font-condensed">
                      {item?.SliderSubHeading ? item?.SliderSubHeading : ""}
                    </p>
                    <h1 className="uppercase !font-sans text-[35px] md:text-[26px] text-[#c99e1a] font-extrabold font-condensed">
                      {item?.SliderMainHeading ? item?.SliderMainHeading : ""}
                    </h1>
                  </div>
                </div>
              )}
              <div className="flex flex-col gap-[60px] md:gap-[80px]">
                {item?.SubSlider1?.map(subSlider => (
                  <div>
                    <div className="flex flex-col gap-8 relative">
                      <div>
                        <div>
                          <widgetic-embed
                            id={subSlider?.desktopImageId}
                            resize="fill-width"
                            width="2374"
                            height="925"
                            autoscale="on"
                            adaptive="414"
                            class="hidden md:block"
                          />
                          <widgetic-embed
                            id={
                              subSlider?.MobileImageId ||
                              subSlider?.desktopImageId
                            }
                            width="415"
                            height="595"
                            autoscale="on"
                            class="block md:hidden"
                          />
                        </div>
                        <div className="text-white text-center px-6 text-white leading-[19px] pt-5">
                          <h1 className="text-[25px] font-extrabold">
                            {subSlider?.title ? subSlider?.title : ""}
                          </h1>
                          <p className="text-[11px] lg:text-[13px] relative bottom-2 lg:bottom-3">
                            {subSlider?.subtitle ? subSlider?.subtitle : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default DynamicComparison
