import React from "react"
import Body from "../components/InternationalResellers/Body"
import Hero from "../components/InternationalResellers/Hero"

const InternationalResellers = () => {
  return (
    <div className="w-full bg-[#F6F6F6]">
      <Hero />
      <Body />
    </div>
  )
}

export default InternationalResellers
