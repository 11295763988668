import React from "react"
import * as style from "./index.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import { useStoryblok } from "../../utils/storyblok"
import {
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaTwitter,
  FaVimeoV,
  FaYoutube,
} from "react-icons/fa"
import KlaviyoNewsletter from "../klaviyoNewsletter/klaviyoNewsletter"

function GetNotified(props: any) {
  const { storyblokEntry } = props
  const navBarQuery = useStaticQuery(graphql`
    query navBarQuery {
      storyblokEntry(slug: { eq: "nav-v4" }) {
        id
        name
        content
        slug
        full_slug
        uuid
        created_at
      }
    }
  `)
  const story = storyblokEntry
    ? useStoryblok(storyblokEntry)
    : { content: null }
  const navBarData = navBarQuery?.storyblokEntry
    ? useStoryblok(navBarQuery.storyblokEntry)
    : { content: null }
  const [facebook, instagram, youtube, vimeo, twitter, tiktok] =
    navBarData.content?.social_links || []
  return (
    <div className={style.getNotified}>
      <div className={`${style.xYcenter} ${style.wrapper}`}>
        <h3 className="font-black text-3xl md:text-4xl mb-2">{props?.title}</h3>
        <p className="font-expanded text-xs md:text-sm">{props?.description}</p>
        <div className={style.socials}>
          <ul className="list-none">
            <li>
              <a
                className="reset"
                href={facebook.link}
                title={facebook.display_name}
                target="_blank"
              >
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a
                className="reset"
                href={instagram.link}
                title={instagram.display_name}
                target="_blank"
              >
                <FaInstagram />
              </a>
            </li>
            <li>
              <a
                className="reset"
                href={youtube.link}
                title={youtube.display_name}
                target="_blank"
              >
                <FaYoutube />
              </a>
            </li>
            <li>
              <a
                className="reset"
                href={vimeo.link}
                title={vimeo.display_name}
                target="_blank"
              >
                <FaVimeoV />
              </a>
            </li>
            <li>
              <a
                className="reset"
                href={twitter.link}
                title={twitter.display_name}
                target="_blank"
              >
                <FaTwitter />
              </a>
            </li>
            <li>
              <a
                className="reset"
                href={tiktok.link}
                title={tiktok.display_name}
                target="_blank"
              >
                <FaTiktok />
              </a>
            </li>
          </ul>
        </div>
        <div className={style.klaviyoForm}>
          <KlaviyoNewsletter />
        </div>
      </div>
    </div>
  )
}

export default GetNotified
