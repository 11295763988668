/* eslint-disable */
import React from "react"
import "./style.scss"

const ThreeLayerFeatureBanner = ({ blok }) => (
  <div className="mt-20 mb-20 compare-container">
    <h3 className="fx-anomaly">{blok.title}</h3>
    <div className="side-by-side push-top col-reverse">
      <div className="one-third">
        <p className="feature-title ql-fx font-black">{blok.first_heading}</p>
        <span className="ql-fx">{blok.first_paragraph}</span>
      </div>
      <div className="two-third offset-top">
        <img className="desktop-only" src={blok.first_banner_desktop} />
        <img className="mobile-only" src={blok.first_banner_mobile} />
      </div>
    </div>

    <div className="side-by-side">
      <div className="two-third offset">
        <img className="desktop-only" src={blok.second_banner_desktop} />
        <img className="mobile-only" src={blok.second_banner_mobile} />
      </div>
      <div className="one-third">
        <p className="feature-title ql-fx font-black">{blok.second_heading}</p>
        <span className="ql-fx">{blok.second_paragraph}</span>
      </div>
    </div>

    <div className="side-by-side col-reverse">
      <div className="one-third offset-bottom">
        <p className="feature-title ql-fx font-black">{blok.third_heading}</p>
        <span className="ql-fx">{blok.third_paragraph}</span>
      </div>
      <div className="two-third offset-bottom">
        <img className="desktop-only" src={blok.third_banner_desktop} />
        <img className="mobile-only" src={blok.third_banner_mobile} />
      </div>
    </div>
  </div>
)

export default ThreeLayerFeatureBanner
