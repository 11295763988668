import React, { useState, useEffect } from "react"
// import { useCurrencyExchange } from "../../context/CurrencyExchange"
import axios from "axios"
import { productVariantEndPointUrl } from "../../config"

function Below4by5Mch({ variant }: any) {
  // const { userCountry } = useCurrencyExchange()
  // const [currencyExchangedPrice, setCurrencyExchangedPrice] = useState(null)
  // const [countryCurrency, setCountryCurrency] = useState("")
  // const getproductCurrency = async () => {
  //   try {
  //     const response = await axios.post(productVariantEndPointUrl, {
  //       country: userCountry?.cca2,
  //       productId: variant?.title,
  //     })

  //     if (response?.data) {
  //       const selected = response.data?.product?.data?.products?.edges?.filter(
  //         (item: any) => {
  //           return item.node.title === variant?.title
  //         },
  //       )
  //       if (selected) {
  //         setCurrencyExchangedPrice(
  //           selected[0]?.node?.variants?.edges[0]?.node?.price?.amount,
  //         )
  //         setCountryCurrency(
  //           selected[0]?.node?.variants?.edges[0]?.node?.price?.currencyCode,
  //         )
  //       }
  //     }
  //   } catch (err: any) {
  //     return {
  //       statusCode: 500,
  //       body: JSON.stringify({
  //         failed: true,
  //         message: err.message,
  //       }),
  //     }
  //   }
  // }

  // useEffect(() => {
  //   if (userCountry?.cca2) {
  //     getproductCurrency()
  //   }
  // }, [variant, userCountry])

  return (
    <div>
      <p className="title uppercase font-black text-md lg:text-2xl m-0">
        {variant.title}
      </p>
      <p className="price text-sm">
        {/* {currencyExchangedPrice ? (
          <>
            {countryCurrency === "USD" ? "$" : countryCurrency + " "}
            {currencyExchangedPrice}
          </>
        ) : ( */}
        {`$${variant?.priceRangeV2?.maxVariantPrice?.amount}`}
        {/* )} */}
      </p>
      <img
        className="image m-auto w-[65%]"
        src={
          variant?.featuredImage?.originalSrc ||
          variant?.image?.originalSrc || 
          "https://cdn.shopify.com/s/files/1/1050/9944/files/VND2-5.png?v=1688156538"
        }
        alt="Product"
      />
      <div>
        <div>Shop Now</div>
      </div>
    </div>
  )
}

export default Below4by5Mch
