/* eslint-disable */

import React, { useState } from "react"
import Seo from "../../components/seo"

import data from "../../../site/settings/who_we_are.json"

import * as styles from "../../styles/whoWeAre.module.scss"
import SustainabilityCard from "./sustainability-card"
import SecondaryButton from "../../components/SecondaryButton"

const slidesData = {
  0: {
    bg: "bg1",
    title: "BASECAMP",
    subtitle: "ULTRALIGHT MATTE BOX",
    bullet1: "Development Time: <strong>30-months</strong>",
    bullet2: "Research & Development with: <strong>Renan Ozturk</strong>",
    link: "/pages/basecamp-matte-box/",
  },
  1: {
    bg: "bg2",
    title: "SUMMIT",
    subtitle: "DROP-IN FILTER SYSTEM",
    bullet1: "Development Time: <strong>30-months</strong>",
    bullet2:
      "Research & Development with: <strong>Michael Shainblum / Alex Strohl</strong>",
    link: "/pages/summit-filter-system/",
  },
  2: {
    bg: "bg3",
    title: "VND",
    subtitle: "VARIABLE NEUTRAL DENSITY",
    bullet1: "Development Time: <strong>12-months</strong>",
    bullet2: "Research & Development with: <strong>Peter McKinnon</strong>",
    link: "/products/pmvnd-edii",
  },
  3: {
    bg: "bg4",
    title: "LITECHASER PRO",
    subtitle: "MOBILE IMAGING SYSTEM",
    bullet1: "Development Time: <strong>12-months</strong>",
    bullet2:
      "Research & Development with: <strong>Matti Haapoja / Sorelle Amore</strong>",
    link: "/litechaser-pro-iphone14",
  },
}

const WhoWeArePage = () => {
  const [teamImages] = useState(data.team_images)

  const [selectedTab, setSelected] = useState(3)

  return (
    <>
      <Seo title="Who We Are" />
      <div id={styles.whoWeAre}>
        <div className={styles.fullWidth}>
          <div className={styles.whoWeAreContainer}>
            <div className={styles.whoWeAre}>
              <div className={styles.item}>
                <div className={styles.whoWeAreListingSectionContainer}>
                  {teamImages.map((image, index) => (
                    <div className={styles.memberImageContainer}>
                      <img
                        className={styles.lazyloadAni}
                        src={image}
                        key={`team-members-${index}`}
                      ></img>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={styles.whoWeAreFeature}>
              {/* <img id={styles.abstractWireWhoWeAre} className={styles.abstractWire} src="//cdn.shopify.com/s/files/1/1050/9944/t/167/assets/abstract-wire-who-we-are.png?v=5636372590444082168"></img> */}
              <p
                className={`${styles.sectionTitle} font-bold font-condensed uppercase`}
              >
                Who we are
              </p>
              <ul className="font-black">
                <li>Design.</li>
                <li>Innovation.</li>
                <li>Adventure.</li>
                <li>Photography.</li>
                <li>Videography.</li>
              </ul>
              <p>
                Pushing the limitations set by traditional camera gear, we're a
                team of designers who are constantly exploring new ways to
                create, record, shoot, or in one phrase:{" "}
                <span>capture perfection.</span>
              </p>
              <div className={styles.sidewayTag}>EST.2011</div>
            </div>
          </div>

          <div className={styles.ourTeamContainer}>
            <div className={styles.leftSide}>
              <p
                className={`${styles.sectionTitle} font-bold font-condensed uppercase`}
              >
                Our Team
              </p>
              <h2 className="font-black">Professional insight.</h2>
              <p>
                From the beginning, we developed working relationships with
                industry-leading photographers and videographers. These
                relationships allow us to see first-hand what professionals need
                to create epic content while in the field.
              </p>
            </div>
            <div className={styles.rightSide}>
              <div className={styles.teamContainer}>
                <div className={styles.teamSection + " " + styles.top}>
                  <div className={styles.leftSection}>
                    <div className={styles.item}>
                      <div className={styles.name}>
                        Peter <span>McKinnon</span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.rightSection}>
                    <div className={styles.item}>
                      <div className={styles.name}>
                        Chris <span>Burkard</span>
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.name}>
                        Alex <span>Strohl</span>
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.name}>
                        Michael <span>Shainblum</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.teamSection + " " + styles.bottom}>
                  <div className={styles.item}>
                    <div className={styles.name}>
                      Renan <span>Ozturk</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.ourProductsContainer}>
            <div className={styles.sectionFeature}>
              <p
                className={`${styles.sectionTitle} font-bold font-condensed uppercase`}
              >
                Our Products
              </p>
              <h2 className="font-black">Collaborative innovation.</h2>
              <p>
                We combine professional insights with our own exploration of
                product development and the outcomes are solutions that perform
                in the most demanding of situations and environments. Together
                we create the world's most progressive imaging solutions.
              </p>
            </div>
            <div className="mt-20 flex justify-center items-center flex-col w-full">
              <div className="flex flex-row sm:justify-center flex-wrap sm:items-center w-full text-center colab-nav">
                {Object.keys(slidesData).map(key => (
                  <div
                    onClick={() => setSelected(parseInt(key))}
                    key={key}
                    className={`text-white cursor-pointer w-6/12 md:w-auto ${
                      selectedTab != key ? " opacity-40" : " active"
                    }`}
                  >
                    <p className="pb-4 sm:px-8 border-b border-gray-600 mb-0 text-base font-extrabold whitespace-nowrap">
                      {slidesData[key].title}
                    </p>
                    <p className="text-12 sm:px-8 mt-4 whitespace-nowrap">
                      {slidesData[key].subtitle}
                    </p>
                  </div>
                ))}
              </div>
              <div className="mt-20 w-full max-w-[1364px] flex flex-col lg:flex-row">
                <div className="w-full lg:w-1/2 h-[413px]">
                  <div className={styles[slidesData[selectedTab].bg]} />
                </div>
                <div className="w-full lg:w-1/2 py-6 lg:py-0 bg-black text-white flex flex-col justify-center px-6">
                  <h1 className="text-4xl font-extrabold">
                    {slidesData[selectedTab].title}
                  </h1>
                  <p className="text-lg font-bold border-b border-gray-600 pb-3">
                    {slidesData[selectedTab].subtitle}
                  </p>
                  <ul className="list-disc pl-3">
                    <li
                      className="mb-0"
                      dangerouslySetInnerHTML={{
                        __html: slidesData[selectedTab].bullet1,
                      }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: slidesData[selectedTab].bullet2,
                      }}
                    ></li>
                  </ul>
                  <div>
                    <SecondaryButton
                      text="Learn More"
                      link={slidesData[selectedTab].link}
                      buttonStyle="gold"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.reducingOurImpactContainer}>
            <div className={styles.leftSide}>
              <p
                className={`${styles.sectionTitle} font-bold font-condensed uppercase`}
              >
                reducing our impact
              </p>
              <h2 className="font-black">Sustainability.</h2>
              <p>
                To ensure we build a sustainable business for the future, we’ve
                focused our corporate responsibility activities on reducing the
                environmental impact of the products we create and overall
                company operations.
              </p>
            </div>
            <div className={styles.rightSide}>
              <div className="flex w-full space-y-8 lg:space-y-0 flex-col lg:flex-row justify-end items-center gap-6">
                <SustainabilityCard
                  background="longevity"
                  description="We are proudly reducing excess materials from our packaging while also reducing plastics from our products, using materials with a longer lifespan + better recyclability such as aluminum and glass."
                />
                <SustainabilityCard
                  background="responsibility"
                  description="PolarPro’s carbon emissions generated from our products and operations are completely offset via our partnership with 3Degrees™."
                />

                <SustainabilityCard
                  background="simplicity"
                  description="All of our product packaging has been reduced in size by nearly 75%, using 100% recycled cardboard material and plant-based inks."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WhoWeArePage
