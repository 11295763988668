/* eslint-disable */
import React from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

const MenuItem = ({ blok }) => {
  const location = useLocation()

  const { link, label } = blok

  return (
    <Link
      to={`${link}/${location.search ? location.search : ""}`}
      className="h-full"
    >
      <li
        className={`relative text-base font-extrabold md:text-sm md:h-[36px] md:pt-[9px] mb-0 md:pl-0 md:pr-[20px] md:m-auto hover:text-paloverde whitespace-nowrap`}
      >
        {label}
      </li>
    </Link>
  )
}

export default MenuItem
