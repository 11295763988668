/* eslint-disable */
import * as React from "react"
import { heading } from "./style.module.scss"
import { FaRegComments, FaRegCaretSquareDown } from "react-icons/fa"

export default function ProductSupport({ productGuideUrl, ...props }) {
  return (
    <div className="product-support mt-20">
      <h2 className={heading}>Product Support</h2>
      <div className="support-container bg-[#f6f6f6] p-10">
        <div className="max-w-screen-xl lg:flex lg:grid-cols-2 gap-4 w-full m-auto ">
          <a href={productGuideUrl} target="_blank" className="lg:w-[50%]">
            <button
              type="button"
              className="user-guide bg-obsidian text-white rounded-[15px] px-[10px] py-[75px] lg:p-20 w-[100%] text-center font-condensed font-bold hover:text-paloverde pointer"
            >
              <p className="mb-5">DOWNLOAD USER GUIDE</p>
              <FaRegCaretSquareDown className="m-auto w-6 h-6 hover:text-paloverde bottom-2" />
            </button>
          </a>
          <a
            href="https://www.polarpro.com/pages/contact-us"
            className="lg:w-[50%] lg:mt-0 mt-[20px] block"
          >
            <button className="chat-with-expert bg-obsidian text-white rounded-[15px] px-[10px] py-[75px] lg:p-20 w-[100%] text-center font-condensed font-bold hover:text-paloverde pointer">
              <p className="mb-5">CHAT WITH OUR BRAND EXPERTS</p>
              <FaRegComments className="m-auto w-7 h-7 hover:text-paloverde" />
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}
