/* eslint-disable */
import React, { useState, useEffect } from "react"
// import { Link } from "gatsby";
import loadable from "@loadable/component"
import * as style from "./style.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import { useStoryblok } from "../../utils/storyblok"
import DynamicComponents from "../DynamicComponents"
import useScrollBlock from "../../hooks/useScrollBlock"

import PlayIcon from "../Icons/PlayIcon"

const ModalVideo = loadable(() => import("react-modal-video"))

const BelowChromaOverview = ({
  product: variantProduct,
}) => {
  const [showVideo, setShowVideo] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()

  const scrollUp = e => {
    e.preventDefault()
    window.scrollTo({
      top: 0,
      behaviour: "smooth",
    })
  }

  useEffect(() => {
    if (showVideo) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [showVideo])

  const pageData = useStaticQuery(graphql`
    query {
      storyblokEntry(full_slug: { eq: "/products/chroma-vndpl-filter" }) {
        id
        name
        content
        slug
        full_slug
        uuid
        created_at
      }
      shopifyProduct(handle: { eq: "chroma-vndpl-filter" }) {
        handle
        title
      }
    }
  `)

  const product = pageData.shopifyProduct

  const story = pageData.storyblokEntry
    ? useStoryblok(pageData.storyblokEntry)
    : { content: null }

  const stickyNavComponent =
    story.content && story.content["sticky_nav"]
      ? story.content["sticky_nav"].map(blok => (
        <>
          {blok ? (
            <div {...sbEditable(blok)} className="sticky top-0 z-50">
              <DynamicComponents
                blok={blok}
                product={product}
                key={blok._uid}
              />
            </div>
          ) : null}
        </>
      ))
      : null

  return (
    <div className={`${style.chromaOverview} overflow-hidden`}>
      <div class={`${style.productHero} text-white flex flex-col lg:flex-row`}>
        <div class={`${style.introData}`}>
          <img
            className="w-1/2 lg:w-1/2 2xl:w-4/6 relative left-[-6vw] md:left-[-2.2vw] xl:left-[-3vw] 3xl:left-[-3vw] top-[0px] 3xl:top-[20px] m-0"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/CROMA-PMVND-LOGO.png?v=1715124531"
            alt="CHROMA PMVND"
          />
          <div className="w-full	">
            <p className="hidden lg:block font-bold text-xl md:text-sm 2xl:text-md 3xl:text-xl">
              McKinnon’s staple filter, the VND, gets an all new Chroma™ Glass element with a built-in polarizer. The polarizer rotates independently from the VND, allowing you to tune from no polarization to full polarization.
            </p>
            <p className="block lg:hidden font-bold">
              McKinnon’s staple filter, the VND, gets an all new Chroma™ Glass element with a built-in polarizer. The polarizer rotates independently from the VND, allowing you to tune from no polarization to full polarization.
            </p>
          </div>
        </div>
        <div class={`${style.videoElement}`}>
          <div class="video-container">
            <a
              href="#data"
              id="inline"
              className={`${style.MobilePlayBtn} cta-round opacity-90 hover:opacity-100`}
              onClick={e => {
                e.preventDefault()
                setShowVideo(true)
              }}
            >
              <PlayIcon />
            </a>
          </div>
        </div>
        {/* modal */}
        <ModalVideo
          channel="youtube"
          videoId="OGsuWBIWTpo?si=qTnmdtuil3lb6SJb"
          isOpen={showVideo}
          onClose={() => setShowVideo(false)}
        />
      </div>
      <div className={`${style.breakBanner} bg-black py-28`}>
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Group_1961.png?v=1715360740"
          alt="Exceptional color accuracy"
        />
        <div className={`hidden lg:block`}>
          <img
            class="w-5/6 m-0"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Designed_To_Help_You_Tell_Your_Story_Title_e0057be2-dc37-4a99-90e9-c604273e92f4.png?v=1715360719"
            alt="Exceptional color accuracyn"
          />
        </div>
      </div>

      <div className={`${style.sandBackg} feature-block max-w-full m-auto`}>
        <div className="flex items-center flex-col lg:flex-row">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black  lg:leading-[1.1rem] text-2xl lg:text-sm xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-white mb-2 lg:md-8">
              Independent Polarization
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-white">
              This key feature allows you to custom tune your polarization to each scene. Depending on whether you want it disengaged from the image or fully engaged for maximum glare reduction, the creative touch is in your hands.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-white m-0">
              This key feature allows you to custom tune your polarization to each scene. Depending on whether you want it disengaged from the image or fully engaged for maximum glare reduction, the creative touch is in your hands.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <video
              autoPlay={true}
              muted={true}
              loop={true}
              playsInline={true}
              className="block w-full"
            >
              <source src="https://cdn.shopify.com/videos/c/o/v/279b0812a2cb453c922aa5a9dacbc908.mp4" />
            </video>
          </div>
        </div>

        <video
          autoPlay={true}
          muted={true}
          loop={true}
          playsInline={true}
          className="hidden lg:block w-full"
          src="https://cdn.shopify.com/videos/c/o/v/35a11a10cc8242c2a419fba7d5cc0d02.mp4"
        >
          <source src="https://cdn.shopify.com/videos/c/o/v/35a11a10cc8242c2a419fba7d5cc0d02.mp4" />
        </video>

        <video
          autoPlay={true}
          muted={true}
          loop={true}
          playsInline={true}
          className="block lg:hidden w-full"
          src="https://cdn.shopify.com/videos/c/o/v/352045346c5949499dfdc546fec6df13.mp4"
        >
          <source src="https://cdn.shopify.com/videos/c/o/v/352045346c5949499dfdc546fec6df13.mp4" />
        </video>


        <div className="flex items-center flex-col lg:flex-row-reverse">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black text-3xl lg:leading-[1.1rem] lg:text-base xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-white mb-2 lg:md-8">
              Chroma Series Glass
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-white">
              This all new glass formula produces unrivaled color accuracy and enabled us to combine a Polarizer with a Variable ND into a single filter.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-white m-0">
              This all new glass formula produces unrivaled color accuracy and enabled us to combine a Polarizer with a Variable ND into a single filter.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="w-full m-0 block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/NEW-CHROMA-1-updated.webp?v=1715280630"
              alt=""
            />
          </div>
        </div>
      </div>

      <div
        className={`${style.breakBanner} ${style.leftStrike} bg-black py-28`}
      >
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Full_Metal_Construction_Bombproof_Protection_Title-m.png?v=1714488369"
          alt="Available in 3 new versions"
        />
        <div className={`text-right hidden lg:block`}>
          <img
            class="w-5/6 m-0 inline-block"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/AVAIL-3-VERSIONS.png?v=1714488313"
            alt="Available in 3 new versions"
          />
        </div>
      </div>

      <div className="before-after">
        <widgetic
          class="desktop-only"
          id="661eeabcecb2a1d8698b4567"
          resize="fill-width"
          width="2374"
          height="925"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <widgetic
          class="mobile-only"
          id="661eeac1ecb2a1e2698b4567"
          resize="fill-width"
          width="1284"
          height="1788"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <div className="data bg-black m-0 text-white text-center pt-10 pb-10">
          <h2 className="title font-black mb-2">VND/PL</h2>
          <p className="text-[12px] w-[80%] m-auto">
            Fine tune your custom level of polarization, ranging from fully off to maximum polarization.
          </p>
        </div>
      </div>

      <div className="before-after">
        <widgetic
          class="desktop-only"
          id="661ee85decb2a1b7688b4567"
          resize="fill-width"
          width="2374"
          height="925"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <widgetic
          class="mobile-only"
          id="661ee863ecb2a1bf688b4567"
          resize="fill-width"
          width="1284"
          height="1788"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <div className="data bg-black m-0 text-white text-center pt-10 pb-10">
          <h2 className="title font-black mb-2">VND/PL | BLACK MIST</h2>
          <p className="text-[12px] w-[80%] m-auto">
            This 3-in-1 setup combines a Polarizer, VND, and ¼ Black Mist into a single filter element. This rids the need for stacking multiple filters in order to craft a unique look, such as one that incorporates subtle halation around areas of highlight, while retaining deep black shadows.
          </p>
        </div>
      </div>

      <div className="before-after">
        <widgetic
          class="desktop-only"
          id="661eec2decb2a1706a8b4567"
          resize="fill-width"
          width="2374"
          height="925"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <widgetic
          class="mobile-only"
          id="661eec31ecb2a16e6a8b4567"
          resize="fill-width"
          width="1284"
          height="1788"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <div className="data bg-black m-0 text-white text-center pt-10 pb-10">
          <h2 className="title font-black mb-2">VND/PL | GOLD MIST</h2>
          <p className="text-[12px] w-[80%] m-auto">
            Another variation of the 3-in-1 filter, this combo has a Polarizer, VND, and ¼ Gold Mist packaged as one, eliminating the need to stack filters. In addition to the benefits of a Variable ND and Polarizer, the Gold Mist works to create a warming effect on highlights by blooming them slightly gold.
          </p>
        </div>
      </div>

      <div className={`${style.breakBanner} bg-black py-28`}>
        <img
          class="w-5/6 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Preset_Stop_Ranges_for_No_Cross-Polarization.png?v=1715360740"
          alt="No Cross-Polarization"
        />
        <div className={`hidden lg:block`}>
          <img
            class="w-5/6 m-0 pr-10"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Designed_To_Help_You_Tell_Your_Story_Title-1_89fbf3db-41ab-40bd-a75e-7e6c2db7941c.png?v=1715360719"
            alt="No Cross-Polarization"
          />
        </div>
      </div>


      <div className={`${style.sandBackg} feature-block2 max-w-full m-auto`}>
        <div className="flex items-center flex-col lg:flex-row">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black lg:leading-[1.1rem] text-3xl lg:text-base xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-white mb-2 lg:md-8">
              Haptic Feedback Locks In Your Stops
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-white">
              Haptic feedback allows you to feel each VND stop lock into place and makes it easier to rotate the polarizer without moving the VND.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-white m-0">
              Haptic feedback allows you to feel each VND stop lock into place and makes it easier to rotate the polarizer without moving the VND.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="w-full m-0 block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/DSC4735.webp?v=1715280630"
              alt=""
            />
          </div>
        </div>

        <img
          class="w-full m-0 hidden lg:block"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/MCKINNON.jpg?v=1715027250"
          alt=""
        />
        <img
          class="w-full m-0 block lg:hidden"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Group_1979.webp?v=1715026940"
          alt=""
        />


        <div className="flex items-center flex-col lg:flex-row-reverse">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black lg:leading-[1.1rem] text-3xl lg:text-base xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-white mb-2 lg:md-8">
              Defender360™ Provides Unmatched Protection
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-white">
              With a full 360 degree encasement, this is the absolute highest level of filter protection available, regardless of if it’s on-camera or stowed away in your bag. The innovative Defender360 brings full coverage no matter where your filter is placed.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-white m-0">
              With a full 360 degree encasement, this is the absolute highest level of filter protection available, regardless of if it’s on-camera or stowed away in your bag. The innovative Defender360 brings full coverage no matter where your filter is placed.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="w-full m-0 block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/DEFENDER.jpg?v=1715026622"
              alt="Defender 360"
            />
          </div>
        </div>
      </div>

      <div className={`${style.breakBanner} bg-black py-28`}>
        <img
          class="w-5/6 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Helix_McKinnon_Series_Filters_Components_Title_36face7d-b626-4b08-9ef6-8d975454b626.png?v=1715360740"
          alt="No Cross-Polarization"
        />
        <div className={`hidden lg:block`}>
          <img
            class="w-5/6 m-0 pr-10"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Helix_McKinnon_Series_Filters_Components_Title.png?v=1715360719"
            alt="No Cross-Polarization"
          />
        </div>
      </div>

      {/* custom atc boxes */}
      <div className="grid grid-cols-2 auto-cols-fr sm:grid-cols-2 md:grid-cols-3 xl-1600:grid-cols-4 gap-6 md:gap-6 xl:gap-10 bg-black text-center px-[2vw] pb-20">
        <div className="flex-1">
          <div className="h-full">
            <a
              className="bg-[#1c1c1c] w-full h-full inline-block text-white p-5 rounded-lg border-transparent transition-all ease-in-out duration-300 border hover:border hover:border-paloverde"
            >
              <span className="block font-black text-2xl">HELIX 2-5</span>
              <span className="block">$299.99</span>
              <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/HELIX_2-5.png?v=1715391638" alt="helix 2-5" className="m-auto" />
            </a>
          </div>
        </div>
        <div className="flex-1">
          <div className="h-full">
            <a
              className="bg-[#1c1c1c] w-full h-full inline-block text-white p-5 rounded-lg border-transparent transition-all ease-in-out duration-300 border hover:border hover:border-paloverde"
            >
              <span className="block font-black text-2xl">HELIX 6-9</span>
              <span className="block">$299.99</span>
              <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/HELIX_6-9.png?v=1715391638" alt="helix 6-9" className="m-auto" />
            </a>
          </div>
        </div>
        <div className="flex-1">
          <div className="h-full">
            <a
              className="bg-[#1c1c1c] w-full h-full inline-block text-white p-5 rounded-lg border-transparent transition-all ease-in-out duration-300 border hover:border hover:border-paloverde"
            >
              <span className="block font-black text-2xl">HELIX 2-5 Black Mist</span>
              <span className="block">$299.99</span>
              <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/HELIX_2-5_Black_Mist.png?v=1715391638" alt="HELIX 2-5 Black Mist" className="m-auto" />
            </a>
          </div>
        </div>
        <div className="flex-1">
          <div className="h-full">
            <a
              className="bg-[#1c1c1c] w-full h-full inline-block text-white p-5 rounded-lg border-transparent transition-all ease-in-out duration-300 border hover:border hover:border-paloverde"
            >
              <span className="block font-black text-2xl">HELIX 6-9 Black Mist</span>
              <span className="block">$299.99</span>
              <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/HELIX_6-9_Black_Mist.png?v=1715391638" alt="HELIX 6-9 Black Mist" className="m-auto" />
            </a>
          </div>
        </div>

        <div className="flex-1">
          <div className="h-full">
            <a
              className="bg-[#1c1c1c] w-full h-full inline-block text-white p-5 rounded-lg border-transparent transition-all ease-in-out duration-300 border hover:border hover:border-paloverde"
            >
              <span className="block font-black text-2xl">HELIX 2-5 Gold Mist</span>
              <span className="block">$299.99</span>
              <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/HELIX_2-5_Gold_Mist.png?v=1715391638" alt="HELIX 2-5 Gold Mist" className="m-auto" />
            </a>
          </div>
        </div>

        <div className="flex-1">
          <div className="h-full">
            <a
              className="bg-[#1c1c1c] w-full h-full inline-block text-white p-5 rounded-lg border-transparent transition-all ease-in-out duration-300 border hover:border hover:border-paloverde"
            >
              <span className="block font-black text-2xl">HELIX 6-9 Gold Mist</span>
              <span className="block">$299.99</span>
              <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/HELIX_6-9_Gold_Mist.png?v=1715391638" alt="HELIX 6-9 Gold Mist" className="m-auto" />
            </a>
          </div>
        </div>

        <div className="flex-1">
          <div className="h-full">
            <a
              className="bg-[#1c1c1c] w-full h-full inline-block text-white p-5 rounded-lg border-transparent transition-all ease-in-out duration-300 border hover:border hover:border-paloverde"
            >
              <span className="block font-black text-2xl">Threaded 2-5</span>
              <span className="block">Starting at $149.99</span>
              <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/Threaded_2-5.png?v=1715391638" alt="Threaded 2-5" className="m-auto" />
            </a>
          </div>
        </div>

        <div className="flex-1">
          <div className="h-full">
            <a
              className="bg-[#1c1c1c] w-full h-full inline-block text-white p-5 rounded-lg border-transparent transition-all ease-in-out duration-300 border hover:border hover:border-paloverde"
            >
              <span className="block font-black text-2xl">Threaded 6-9</span>
              <span className="block">Starting at $149.99</span>
              <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/Threaded_6-9.png?v=1715391638" alt="Threaded 6-9" className="m-auto" />
            </a>
          </div>
        </div>

        <div className="flex-1">
          <div className="h-full">
            <a
              className="bg-[#1c1c1c] w-full h-full inline-block text-white p-5 rounded-lg border-transparent transition-all ease-in-out duration-300 border hover:border hover:border-paloverde"
            >
              <span className="block font-black text-2xl">Threaded 2-5 Black Mist</span>
              <span className="block">Starting at $149.99</span>
              <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/Threaded_2-5_Black_Mist.png?v=1715391638" alt="Threaded 2-5 Black Mist" className="m-auto" />
            </a>
          </div>
        </div>

        <div className="flex-1">
          <div className="h-full">
            <a
              className="bg-[#1c1c1c] w-full h-full inline-block text-white p-5 rounded-lg border-transparent transition-all ease-in-out duration-300 border hover:border hover:border-paloverde"
            >
              <span className="block font-black text-2xl">Threaded 6-9 Black Mist</span>
              <span className="block">Starting at $149.99</span>
              <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/Threaded_6-9_Black_Mist.png?v=1715391638" alt="Threaded 6-9 Black Mist" className="m-auto" />
            </a>
          </div>
        </div>

        <div className="flex-1">
          <div className="h-full">
            <a
              className="bg-[#1c1c1c] w-full h-full inline-block text-white p-5 rounded-lg border-transparent transition-all ease-in-out duration-300 border hover:border hover:border-paloverde"
            >
              <span className="block font-black text-2xl">Threaded 2-5 Gold Mist</span>
              <span className="block">Starting at $149.99</span>
              <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/Threaded_2-5_Gold_Mist.png?v=1715391638" alt="Threaded 2-5 Gold Mist" className="m-auto" />
            </a>
          </div>
        </div>

        <div className="flex-1">
          <div className="h-full">
            <a
              className="bg-[#1c1c1c] w-full h-full inline-block text-white p-5 rounded-lg border-transparent transition-all ease-in-out duration-300 border hover:border hover:border-paloverde"
            >
              <span className="block font-black text-2xl">Threaded 6-9 Gold Mist</span>
              <span className="block">Starting at $149.99</span>
              <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/Threaded_6-9_Gold_Mist.png?v=1715391638" alt="Threaded 6-9 Gold Mist" className="m-auto" />
            </a>
          </div>
        </div>
        {/* atc ends */}
      </div>

      <div className="bg-black pt-20">
        <img
          class="w-2/4 m-auto hidden lg:block"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Group_1975_806c2a37-5ff2-424c-993d-55bade3b082e.png?v=1715360719"
          alt="Achieve cinematic styles"
        />
        <img
          class="w-2/3 m-auto block lg:hidden"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Group_1981.png?v=1715360741"
          alt="Achieve cinematic styles"
        />
        <div className="highlight-banner">
          <div className={`${style.cinematicVnd} banner px-10 xl:px-20 mt-20 flex items-left lg:items-center flex-col-reverse lg:flex-row`}>
            <div className="overtext text-white w-full md:w-2/3 xl:w-1/3">
              <p className="text-4xl lg:text-3xl font-black">VND</p>
              <p className="text-lg xl:text-sm 2xl:text-lg">Works to reduce lighting, so you can shoot at double frame rate to capture motion naturally with an added subtle blur. Also utilized for capturing long exposure photography.</p>
            </div>
          </div>
          <div className={`${style.cinematicPolarizer} banner px-10 xl:px-20 mt-7 flex items-left lg:items-center flex-col-reverse lg:flex-row-reverse text-right lg:text-right`}>
            <div className="overtext text-white w-full md:w-2/3 xl:w-1/3 ml-0 md:ml-[33%] lg:ml-0">
              <p className="text-4xl lg:text-3xl font-black">POLARIZER</p>
              <p className="text-lg xl:text-sm 2xl:text-lg">Rotates to give you full control of reflections on the ground, windows, vehicles, and water. Choose to fully engage the tunable polarizer to max reduction or leave it untouched to include reflections in your frame.</p>
            </div>
          </div>
          <div className={`${style.cinematicBlackMist} banner px-10 xl:px-20 mt-7 flex items-left lg:items-center flex-col-reverse lg:flex-row`}>
            <div className="overtext text-white w-full md:w-2/3 xl:w-1/3">
              <p className="text-4xl lg:text-3xl font-black">BLACK MIST</p>
              <p className="text-lg xl:text-sm 2xl:text-lg">This unique mist takes excessive sharpness off your digital lenses to create smooth and subtle highlight roll-off, while also blooming light sources. The Black Mist is key for retaining deep shadow detail.</p>
            </div>
          </div>
          <div className={`${style.cinematicGoldMist} banner px-10 xl:px-20 mt-7 flex items-left lg:items-center flex-col-reverse lg:flex-row-reverse text-right lg:text-right`}>
            <div className="overtext text-white w-full md:w-2/3 xl:w-1/3 ml-0 md:ml-[33%] lg:ml-0">
              <p className="text-4xl lg:text-3xl font-black">GOLD MIST</p>
              <p className="text-lg xl:text-sm 2xl:text-lg">Similar to other mists, it takes excessive sharpness off your digital lenses. The gold aspect creates smooth and subtle golden highlight roll-off and blooms the sources gold to add a hint of warmth to you image and offer a filmic look.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black py-32">
        <img
          class="w-2/3 m-auto hidden lg:block"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Group_1977_0d1d0c10-8efa-440a-9db1-67c9bac92104.png?v=1715360719"
          alt="No More Stacking with this 6 in 1 Filter"
        />
        <img
          class="w-2/3 m-auto block lg:hidden"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Group_1986.png?v=1715360740"
          alt="No More Stacking with this 6 in 1 Filter"
        />
      </div>
      <div className={`${style.sandBackg} feature-block2 max-w-full m-auto`}>
        <div className="flex items-center flex-col lg:flex-row">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black lg:leading-[1.1rem] text-3xl lg:text-base xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-white mb-2 lg:md-8">
              The Benefits Of This Filter Combination
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-white">
              1. Eliminates stacking - Zero vignetting <br />
              2. Lower profile - Leave it on your lens <br />
              3. Faster setup speed and filter swaps <br />
              4. Less Filters - Less bag weight and more carry space
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-white m-0">
              1. Eliminates stacking - Zero vignetting <br />
              2. Lower profile - Leave it on your lens <br />
              3. Faster setup speed and filter swaps <br />
              4. Less Filters - Less bag weight and more carry space
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="w-full m-0 block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/BENEFITS.jpg?v=1715026622"
              alt=""
            />
          </div>
        </div>
      </div>

      <div
        className={`${style.breakBanner} ${style.leftStrike} bg-black py-28`}
      >
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Designed_To_Help_You_Tell_Your_Story_Title-3.png?v=1715360740"
          alt="Independent polarization control"
        />
        <div className={`hidden lg:block`}>
          <img
            class="w-5/6 m-0 inline-block"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Designed_To_Help_You_Tell_Your_Story_Title-2_c5b6c59b-e24f-4799-a9b4-0d0f035b862d.png?v=1715360719"
            alt="Independent polarization control"
          />
        </div>
      </div>

      <div className="launchCommercial pt-20 bg-black">
        <div className="flex flex-col lg:flex-col">
          <div>
            <img
              className="m-auto hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/COLLAGE-1-2.jpg?v=1715026622"
              alt=""
            />
            <img
              className="m-auto block lg:hidden w-full"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Group_1987.webp?v=1715026941"
              alt=""
            />
          </div>
          <div>
            <img
              className="m-auto hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/COLLAGE-3.jpg?v=1715026622"
              alt=""
            />
            <img
              className="m-auto block lg:hidden w-full"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/ePkrBtnE.webp?v=1715026941"
              alt=""
            />
          </div>
        </div>
      </div>
    </div >
  )
}

export default BelowChromaOverview
