import React from "react"
import "./index.scss"

type Props = {
  background: string
  description: string
}

const SustainabilityCard = ({ background, description }: Props) => {
  return (
    <div className={`${background} sustainabilityCard`}>
      <p className="font-expanded">{description}</p>
    </div>
  )
}

export default SustainabilityCard
