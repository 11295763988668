// extracted by mini-css-extract-plugin
export var LCPiP12MainBanner = "style-module--LCPiP12MainBanner--f1d21";
export var active = "style-module--active--469eb";
export var activeViewing = "style-module--activeViewing--f7a46";
export var alignContainer = "style-module--alignContainer--414fe";
export var allNewCase = "style-module--allNewCase--596c1";
export var animateContainer = "style-module--animateContainer--18e97";
export var animation = "style-module--animation--170ce";
export var attentionToDetail = "style-module--attentionToDetail--8dc85";
export var availableKit = "style-module--availableKit--180ca";
export var backToTop = "style-module--back-to-top--b812e";
export var bannerImg = "style-module--banner-img--c07ca";
export var bgOuterContainer = "style-module--bg-outer-container--52393";
export var bottom = "style-module--bottom--41fab";
export var bottomHalf = "style-module--bottomHalf--2e5db";
export var breadcrumb = "style-module--breadcrumb--3442a";
export var buildYourKit = "style-module--buildYourKit--53438";
export var bulletsOverlays = "style-module--bulletsOverlays--7d880";
export var buyBtn = "style-module--buyBtn--12658";
export var col = "style-module--col--ce597";
export var compareVnd = "style-module--compareVnd--93a5b";
export var container = "style-module--container--c26fb";
export var content = "style-module--content--c8419";
export var darkHighlight = "style-module--dark-highlight--45a4c";
export var desc = "style-module--desc--fe31d";
export var desktopOnly = "style-module--desktopOnly--2a7e4";
export var directorKit = "style-module--directorKit--f4130";
export var divider = "style-module--divider--f957f";
export var feature = "style-module--feature--2ef31";
export var featureItem = "style-module--featureItem--66da3";
export var filmmakingKit = "style-module--filmmakingKit--663a7";
export var filterContainer = "style-module--filterContainer--2ea4b";
export var filterDesc = "style-module--filterDesc--11f36";
export var filterItem = "style-module--filterItem--008f6";
export var filterName = "style-module--filterName--79315";
export var firstStack = "style-module--firstStack--1972a";
export var fusedQuartzVnd = "style-module--fused-quartz-vnd--f8249";
export var highlight = "style-module--highlight--2a0ef";
export var icon = "style-module--icon--4e03e";
export var ip12Features = "style-module--ip12Features--5ba64";
export var itemName = "style-module--itemName--c1d65";
export var itemVnd = "style-module--item-vnd--47c5e";
export var lightControlVnd = "style-module--light-control-vnd--38c0d";
export var listing = "style-module--listing--b74fb";
export var listingItem = "style-module--listingItem--1cb41";
export var listingTitle = "style-module--listingTitle--f0937";
export var mainTitle = "style-module--mainTitle--2bf4c";
export var middle = "style-module--middle--df569";
export var mist = "style-module--mist--55012";
export var mobileBreak = "style-module--mobileBreak--ef8cf";
export var mobileOnly = "style-module--mobileOnly--94b2d";
export var mobileOptimizedFilters = "style-module--mobileOptimizedFilters--e9d36";
export var newForiP12 = "style-module--newForiP12--fd760";
export var newIcon = "style-module--new-icon--16049";
export var newIconMobile = "style-module--new-icon-mobile--2e171";
export var oneThirds = "style-module--oneThirds--7d3dc";
export var overlay = "style-module--overlay--4509f";
export var overlayTwo = "style-module--overlayTwo--2157c";
export var owlDot = "style-module--owl-dot--2a4a9";
export var owlDots = "style-module--owl-dots--02943";
export var picture = "style-module--picture--0c1af";
export var presetStop = "style-module--preset-stop--7b244";
export var presetStopVnd = "style-module--preset-stop-vnd--da3e8";
export var reducingImpact = "style-module--reducing-impact--a1f01";
export var reducingImpactSection = "style-module--reducing-impact-section--b2592";
export var render = "style-module--render--f6136";
export var renderVertical = "style-module--renderVertical--1856a";
export var right = "style-module--right--74ab2";
export var secondCol = "style-module--second-col--af329";
export var secondStack = "style-module--secondStack--73f3f";
export var sideBySide = "style-module--sideBySide--1937f";
export var sideBySideWithListing = "style-module--sideBySideWithListing--588cd";
export var spacer = "style-module--spacer--eb929";
export var subName = "style-module--subName--fdcda";
export var supportBar = "style-module--support-bar--cc50b";
export var supportBarTitle = "style-module--support-bar-title--12ac2";
export var supportIconChat = "style-module--support-icon-chat--9507b";
export var supportIconDownload = "style-module--support-icon-download--dcba4";
export var supportWrapper = "style-module--support-wrapper--abce2";
export var tag = "style-module--tag--a2aef";
export var text = "style-module--text--79b71";
export var textGold = "style-module--textGold--b59e3";
export var titleSub = "style-module--titleSub--6f354";
export var titleWithSub = "style-module--titleWithSub--bfd84";
export var top = "style-module--top--01407";
export var twoThirds = "style-module--twoThirds--c6de4";
export var variation = "style-module--variation--98f20";
export var vndItem = "style-module--vndItem--bce57";
export var wrapperText = "style-module--wrapper-text--0c7e3";
export var youAskedWeListened = "style-module--youAskedWeListened--96822";