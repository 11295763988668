import React from "react"
import ProductHero from "../ProductHero"
import SectionTitle from "../SectionTitle"
import LeftRightHighlights from "../LeftRightHighlights"
import ComponentBannner from "../ComponentBanner"
import ImageComponent from "../ImageComponent"
import AdditionalBlock from "../AdditionalBlock"
import MultipleBeforeAfterComparison from "../BeforeAfterComparison/MultipleBeforeAfterComparison"
import FilterAndComponent from "../FilterAndComponent"
import GearUp from "../GearUp"
import Explore from "../Explore"
import FeaturedCollection from "../FeaturedCollection"
import Adventure from "../Adventure"
import HomeHighlights from "../HomeHighlights"
import GetNotified from "../GetNotified"
import Slider from "../SilderComponent"
import SliderTitle from "../SliderTitle"
type Data = {
  component: string
  [key: string]: any
}

interface Props {
  components: Data[]
  productCardsData2?: any[]
}
const DynamicComponentsV2 = ({
  components: tmpComponents,
  productCardsData2,
}: Props) => {
  const components = tmpComponents
  const exploreComponents = tmpComponents
    .filter(item => item.component === "Explore")
    .map((item, index) => {
      return {
        ...item,
        // @ts-ignore
        productCardsData3: productCardsData2[index],
      }
    })

  const updatedComponents = tmpComponents.map((item, idx) => {
    if (item.component === "Explore") {
      const exploreComponent = exploreComponents.shift()
      return exploreComponent
    }
    return item
  })

  return (
    <div className="flex w-full flex-col">
      {updatedComponents.map((item, index) => {
        if (item?.component === "product_hero")
          return (
            <div key={index} className="w-full">
              <ProductHero {...item} />
            </div>
          )
        if (
          item?.component === "componentImage" ||
          item?.component === "Component Image"
        )
          return <ImageComponent {...item} key={index} />
        if (item?.component === "section_title")
          return <SectionTitle {...item} key={index} />
        if (item?.component === "HomeHighlights")
          return <HomeHighlights {...item} key={index} />
        if (item?.component === "left_right_highlights")
          return <LeftRightHighlights {...item} key={index} />
        if (item?.component === "lifestyle_banner")
          return <ComponentBannner {...item} key={index} />
        if (item?.component === "Additional block")
          return <AdditionalBlock {...item} key={index} />
        if (item?.component === "multiple_compare_sections") {
          return <MultipleBeforeAfterComparison {...item} key={index} />
        }
        if (item?.component === "filters_and_components") {
          return <FilterAndComponent {...item} key={index} />
        }
        if (item?.component === "Gear Up")
          return <GearUp {...item} key={index} />
        if (item?.component === "Explore")
          return (
            <Explore
              {...item}
              key={index}
              productCardsData2={productCardsData2}
              // @ts-ignore
              linkItem={item?.ShopNowLink?.url}
            />
          )
        if (item?.component === "Featured Collection")
          return <FeaturedCollection {...item} key={index} />
        if (item?.component === "Adventure")
          return <Adventure {...item} key={index} />
        if (item?.component === "GetNotified")
          return <GetNotified {...item} key={index} />
        if (item?.component === "title")
          return <SliderTitle {...item} key={index} />
        if (item?.component === "slider_component") {
          const sliderId = `slider-${index}`
          return <Slider key={sliderId} id={sliderId} {...item} />
        }
        return <p>Component not created {item?.component}</p>
      })}
    </div>
  )
}

export default DynamicComponentsV2
