/* eslint-disable */

import React, { useState } from "react"
import Seo from "../../components/seo"

import "./litechaser11Overview.scss"

const Litechaser11Overview = () => {
  return (
    <>
      <Seo title="LiteChaser Overview" />
      <div class="lightchaser11-overview">
        {/* hero  */}
        <div class="litechaser-hero-v2">
          <h2 className="display-font-logo font-bold">
            Minimalism Meets Creativity
          </h2>
          <p>
            The ultimate mobile filter system for iPhone 11 & Galaxy S20 Ultra.
          </p>
        </div>

        {/* litechaser video */}
        <div id="lc-video" class="litechaser-video">
          <div class="intro-text">
            <div class="intro">
              <div class="vl"></div>
              <span className="font-bold">Introducing</span>
            </div>
            <div class="title">
              <img
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/LiteChaser-Logo.webp?v=1650328758"
                alt="litechaser-logo"
              />
            </div>
            <div class="text font-bold">
              <p>
                The iPhone 11 Pro and Galaxy S20 Ultra systems are changing the
                game in mobile content creation. Maximize your creative
                experience and push the boundaries of what a mobile phone can
                accomplish by controlling unwanted light before it enters your
                lens with our mobile-optimized Variable ND and Circular
                Polarizer. The complete creative system is built specifically
                for mobile filmmakers and photographers to deliver shots never
                before possible on mobile devices.{" "}
              </p>
            </div>
          </div>
          <div class="featuring">
            <div class="title">
              <span class="dash">-</span>
              <span class="text font-extrabold">Featuring</span>
            </div>
            <div class="vl"></div>
            <div class="names font-bold">
              Matti Haapoja
              <br />+ Sorelle Amore
            </div>
            <img
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Matti-Headshot.jpg?v=1581723491"
              alt="matti-haapoja-image"
            />
            <img
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Sorelle-Headshot.jpg?v=1581723491"
              id="sorelle"
              alt="sorelle-amore-image"
            />
          </div>

          <a
            id="inline"
            href="#data"
            class="cta-round play-button"
            onclick="fireMutationObserver()"
          ></a>

          <div class="video-bg-img">
            <img
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Video-Thumbnail-Desktop.jpg?v=1581723545"
              alt="video-thumbnail"
            />
          </div>
        </div>

        {/* litechaser features */}

        <div class="lc-features-ctn">
          <div class="lc-features-title-ctn">
            <h3 class="lc-features-title">KEY FEATURES</h3>
            <div class="lc-features-bar"></div>
          </div>

          <div class="lc-features-itm top">
            <img
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP-Minimalism-Meets-Creativity.jpg?v=1582054203"
              className="desktop-only"
            />
            <img
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP-Minimalism-Meets-Creativity-Mobile.jpg?v=1582053585"
              className="mobile-only"
            />
          </div>

          <div class="lc-features-itm">
            <img
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP-Rock-Solid-Grip.jpg?v=1582054203"
              className="desktop-only"
            />
            <img
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP-Rock-Solid-Grip-Mobile.jpg?v=1582053585"
              className="mobile-only"
            />
          </div>
        </div>

        {/* litechaser collection */}
        <div class="lc-collection-ctn">
          <div class="lc-core-title">
            <h5 class="core-title font-bold">LiteChaser</h5>
            <h4 class="flag-title font-bold display-font-logo">
              Filter Collection
            </h4>
          </div>
          <div class="lc-collection-info-ctn">
            <div class="lc-filter-ctn" id="lc-cp-ctn">
              <div class="title font-bold">Pro Photo</div>
              <div class="filter-img">
                <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP-CP-Revised.png?v=1584723486" />
              </div>
              <div class="filter-content">
                <div class="content-top">
                  <div class="filter-title font-bold">
                    Circular Polarizer <span>(CP)</span>
                  </div>
                  <div class="filter-desc">
                    The essential filter for capturing deeper colors and
                    reducing glare, reflections, and haze from your photos.
                  </div>
                </div>
                <hr />
                <div class="content-bottom">
                  <div class="available font-bold font-condensed">
                    Available for -{" "}
                    <span class="device-type">
                      iPhone 11 & Galaxy S20 Ultra
                    </span>
                  </div>
                  <div class="works-with-title font-bold">
                    Camera Compatibility:
                  </div>
                  <div class="line-item works-with-item-1 font-bold">
                    • Ultra Wide Camera
                  </div>
                  <div class="line-item works-with-item-2 font-bold">
                    • Wide Camera
                  </div>
                  <div class="line-item works-with-item-3 font-bold">
                    • Telephoto Camera
                  </div>
                </div>
              </div>
            </div>
            <div class="lc-filter-ctn" id="lc-vnd-ctn">
              <div class="title font-bold">Run-N-Gun Video</div>
              <div class="filter-img">
                <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP-VND-3-5-Revised.png?v=1584723487" />
              </div>
              <div class="filter-content">
                <div class="content-top">
                  <div class="filter-title font-bold">VND 3-5 Stop</div>
                  <div class="filter-desc">
                    3-5 stops, built for run-n-gun filmmakers who want to create
                    smooth cinematic footage.
                  </div>
                </div>
                <hr />
                <div class="content-bottom">
                  <div class="available font-bold font-condensed">
                    Available for -{" "}
                    <span class="device-type">
                      iPhone 11 & Galaxy S20 Ultra
                    </span>
                  </div>
                  <div class="works-with-title font-bold">
                    Camera Compatibility:
                  </div>
                  <div class="line-item works-with-item-1 font-bold">
                    • Wide Camera
                  </div>
                  <div class="line-item works-with-item-2 font-bold">
                    • Telephoto Camera
                  </div>
                </div>
              </div>
            </div>
            <div class="lc-filter-ctn" id="lc-nd8-ctn">
              <div class="title font-bold">Pro Video</div>
              <div class="filter-img">
                <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP-ND8-Revised.png?v=1584723486" />
              </div>
              <div class="filter-content">
                <div class="content-top">
                  <div class="filter-title font-bold">
                    ND8 - <span>3 Stops</span>
                  </div>
                  <div class="filter-desc">
                    The essential filter for shooting cinematic footage at high
                    frame rates or during golden hour.
                  </div>
                </div>
                <hr />
                <div class="content-bottom">
                  <div class="available font-bold font-condensed">
                    Available for - <span class="device-type">iPhone 11</span>
                  </div>
                  <div class="works-with-title font-bold">
                    Camera Compatibility:
                  </div>
                  <div class="line-item works-with-item-1 font-bold">
                    • Ultra Wide Camera
                  </div>
                  <div class="line-item works-with-item-2 font-bold">
                    • Wide Camera
                  </div>
                  <div class="line-item works-with-item-3 font-bold">
                    • Telephoto Camera
                  </div>
                </div>
              </div>
            </div>
            <div class="lc-filter-ctn" id="lc-nd64-ctn">
              <div class="title font-bold">Pro Video</div>
              <div class="filter-img">
                <img src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP-ND64-Revised.png?v=1584723487" />
              </div>
              <div class="filter-content">
                <div class="content-top">
                  <div class="filter-title font-bold">
                    ND64 - <span>6 Stops</span>
                  </div>
                  <div class="filter-desc">
                    Ideal for shooting cinematic footage mid-day at normal frame
                    rates.
                  </div>
                </div>
                <hr />
                <div class="content-bottom">
                  <div class="available font-bold font-condensed">
                    Available for - <span class="device-type">iPhone 11</span>
                  </div>
                  <div class="works-with-title font-bold">
                    Camera Compatibility:
                  </div>
                  <div class="line-item works-with-item-1 font-bold">
                    • Ultra Wide Camera
                  </div>
                  <div class="line-item works-with-item-2 font-bold">
                    • Wide Camera
                  </div>
                  <div class="line-item works-with-item-3 font-bold">
                    • Telephoto Camera
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* litechaser detail listing */}
        <div class="lc-detail-ctn">
          <div class="lc-detail-inner-ctn">
            <div class="lc-detail-title-ctn">
              <h3 class="lc-detail-title">ATTENTION TO DETAIL</h3>
              <div class="lc-detail-bar"></div>
            </div>
            <div class="lc-detail-content-ctn">
              <div class="lc-detail-case-title">
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/ATD-LITECHASER-PRO-LOGO.webp?v=1650328669"
                  class="detail-img"
                  alt="lightchaser-pro-cp"
                />
                <div class="title font-bold display-font-logo uppercase">
                  Case
                </div>
              </div>
              <div class="lc-case-detail-listing">
                {/* desktop */}
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Desktop-Attention-To-Detail-W-Filter.png?v=1582134546"
                  class="lc-detail-img detail-desktop active"
                  id="w-filter"
                  alt="litechaser-case-w-filter"
                />
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Desktop-Attention-To-Detail-No-Filter.png?v=1582134547"
                  class="lc-detail-img detail-desktop"
                  id="no-filter"
                  alt="litechaser-case-no-filter"
                />
                {/* mobile */}
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Mobile-Attention-To-Detail-W-Filter.png?v=1582134737"
                  class="lc-detail-img detail-mobile m-active"
                  id="m-w-filter"
                  alt="litechaser-case-w-filter"
                />
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Mobile-Attention-To-Detail-No-Filter.png?v=1582134737"
                  class="lc-detail-img detail-mobile"
                  id="m-no-filter"
                  alt="litechaser-case-no-filter"
                />
              </div>
              <div class="toggle-btn-ctn">
                <div id="app-cover">
                  <div class="row">
                    <div class="toggle-button-cover">
                      <div class="button-cover">
                        <div class="button r" id="button-1">
                          <input type="checkbox" class="checkbox" />
                          <div class="knobs"></div>
                          <div class="layer"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="toggle-btn-content">
                  Toggle To See With / Without Filter
                </div>
              </div>
              <hr />
              <div class="lc-detail-grip-title">
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/ATD-LITECHASER-PRO-LOGO.webp?v=1650328669"
                  class="detail-img"
                  alt="lightchaser-pro-cp"
                />
                <div class="title font-bold display-font-logo uppercase">
                  Grip
                </div>
              </div>
              <div class="lc-grip-detail-listing">
                {/* desktop */}
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Revised-Attention-To-Detail-Desktop.png?v=1581731742"
                  class="lc-detail-img detail-desktop active desktop-only"
                  alt="litechaser-grip"
                />
                {/* mobile */}
                <img
                  src="https://cdn.shopify.com/s/files/1/1050/9944/files/Revised-Attention-To-Detail-Mobile.png?v=1581731742"
                  class="lc-detail-img detail-mobile active mobile-only"
                  alt="litechaser-grip"
                />
              </div>
            </div>
          </div>
        </div>

        {/* in the field */}
        <div class="ltc-in-the-field">
          <h5>IN THE FIELD</h5>
          <div class="ltc-field-item">
            <div class="ltc-item-bg">
              <img
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/In-The-Field-The-Ultimate-Tripod-Mount.jpg?v=1581632637"
                className="desktop-only"
              />
              <img
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/In-The-Field-The-Ultimate-Tripod-Mount.png?v=1581632638"
                className="mobile-only"
              />
            </div>
            <div class="ltc-details right-ltc-item">
              <h4 className="font-bold display-font-logo">
                The Ultimate <br />
                Tripod Mount
              </h4>
              <p className="font-bold">
                The LiteChaser Grip securely locks to the Case, creating the
                most secure mobile tripod mount available.
              </p>
            </div>
          </div>
          <div class="ltc-field-item">
            <div class="ltc-item-bg">
              <img
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/In-The-Field-LowProfile-Mic.jpg?v=1581632637"
                className="desktop-only"
              />
              <img
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/In-The-Field-The-Ultimate-Microphone-Mount.png?v=1581632638"
                className="mobile-only"
              />
            </div>
            <div class="ltc-details left-ltc-item">
              <h4 className="font-bold display-font-logo">
                Low-Profile <br />
                Microphone Mount
              </h4>
              <p className="font-bold">
                From shooting vlogs to run-n-gun b-roll missions, the Grip's
                ¼”-20 top mount is ready for whatever your shoot requires.
              </p>
            </div>
          </div>
        </div>
        <div class="ltc-camera-orientation">
          <h5>GRIP ORIENTATIONS</h5>
          <div class="orientation-slider">
            <img
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Grip-Orientations-Desktop.jpg?v=1581632638"
              className="desktop-only"
            />
            <img
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Grip-Orientations-Mobile.jpg?v=1581632637"
              className="mobile-only"
            />
          </div>
        </div>

        {/* kits */}
        <div class="ltc-kits">
          <h5>Explore</h5>
          <img
            alt="LiteChaser Pro Kits"
            class="ltc-type"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Litechaser-Pro-Kits.webp?v=1650408827"
          />

          <div class="ltc-kits-item kit-right-bg photography-kit">
            <div class="grab-ele ltc-details--left fade-in-element">
              <img
                alt="Essential Kit Image"
                class=""
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/IP11-PHTO-1.png?v=1581363087"
              />
              <div class="ltc-details-ctn">
                <h4>PHOTOGRAPHY KIT</h4>
                <h5>
                  Includes: <span class="includes">Case + Grip + CP</span>
                </h5>
                <p className="font-bold font-condensed m-0">
                  The Fundamental Setup
                </p>
                <p>
                  Capture deeper colors and reduce glare, reflections, and haze
                  from your photos with the Circular Polarizer. Attach the grip
                  to your LiteChaser Pro case or tripod for a secure hold.
                </p>
                <div class="lc-pre-order">
                  <a href="https://www.polarpro.com/products/litechaser-pro-builder">
                    BUY NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="ltc-kits-item kit-left-bg filmmaker-kit">
            <div class="grab-ele ltc-details fade-in-element">
              <img
                alt="Essential Kit Image"
                class=""
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/IP11-Filmmaker-Kit.png?v=1581363086"
              />
              <div class="ltc-details-ctn--right">
                <h4>FILMMAKING KIT</h4>
                <h5>
                  Includes: <span class="includes">Case + Grip + VND 3-5</span>
                </h5>
                <p className="font-bold font-condensed m-0">
                  Capture Smooth, Cinematic Video
                </p>
                <p>
                  The Filmmaking Kit is an outstanding starting point for
                  creators looking to create smooth cinematic footage with the
                  VND 3-5.
                </p>
                <div class="lc-pre-order">
                  <a href="https://www.polarpro.com/products/litechaser-pro-builder">
                    BUY NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="ltc-kits-item kit-right-bg build-your-own">
            <div class="grab-ele ltc-details--left fade-in-element">
              <img
                alt="Essential Kit Image"
                class="custom-mobile-img mobile-only"
                src="https://cdn.shopify.com/s/files/1/1050/9944/files/1x1-Build-Your-Kit_6f32711b-ddde-4c55-8590-faa28f4fbc47.jpg?v=1582048244"
              />
              <div class="ltc-details-ctn outlier-kit">
                <h4>BUILD YOUR OWN KIT</h4>
                <h5>Customized To Your Creativity</h5>
                <p>
                  Start From Scratch And Build <br />
                  The Complete Mobile Setup.
                </p>
                <div class="lc-pre-order">
                  <a href="https://www.polarpro.com/products/litechaser-pro-builder">
                    BUY NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* impact */}
        <div class="our-impact-section our-impact-bg">
          <img
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Reducing-Our-Environmental-Impact_da40ff47-50a8-4456-ba76-bf70f78eb46b.jpg?v=1582133168"
            class="desktop-only"
          />
          <img
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Reducing-Our-Environmental-Impact-Mobile_40f2eb60-4524-4425-bdc7-fa372f78f8ce.jpg?v=1582133167"
            class="mobile-only"
          />
        </div>
      </div>
    </>
  )
}

export default Litechaser11Overview
