/* eslint-disable */
import React, { useContext, useState, useEffect, useMemo } from "react"
import { FaRegTimesCircle } from "react-icons/fa"

import { StoreContext } from "../../context/StoreContext"
import "./style.scss"
import useCheckout from "../../hooks/useCheckout"
import { useLocation } from "@reach/router"

interface Props {
  setIsCartOpen: Function
}

const AddToCartBanner = ({ setIsCartOpen }: Props) => {
  const location = useLocation()
  const urlParams = location.search

  const { checkout, loading, cartBannerStatus, closeCartBanner } =
    useContext(StoreContext)
  const [headerPos, setHeaderPos] = useState("menuNonSticky")
  const { handleCheckout, loadingSubmit } = useCheckout()

  const onCheckout = async () => {
    const checkoutUrl = await handleCheckout(urlParams)
    const tag = document.createElement("a")
    tag.href = checkoutUrl
    tag.target = "_blank"
    document.body.appendChild(tag)
    tag.click()
    document.body.removeChild(tag)
  }

  const listenScrollEvent = () => {
    window.scrollY > 240
      ? setHeaderPos("menuSticky")
      : setHeaderPos("menuNonSticky")
  }
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent)
  })

  return (
    <header
      className={`z-[999999999] w-full ${headerPos} ${cartBannerStatus ? `bannerShow` : `bannerHide`
        }`}
    >
      <div className="w-full">
        <div
          className={`z-[99999] top-[0px] md:top-[41px] h-[150px] md:h-[94px] bg-[#ffffff] w-full left-0 flex justify-center flex-row shadow-[0_1px_3px_rgba(0,0,0,0.1)]`}
        >
          <div className="max-w-[1180px] px-[15px] md:px-[6vw] xl:px-[30px] m-auto flex flex-row justify-between w-full">
            <div className="data w-[50%] md:w-[65%] lg:w-[auto]">
              <h3 className="banner-cart-title m-0 inline-block text-sm font-black text-[#1c1c1c]" />{" "}
              <p className="inline-block banner-cart-variant m-0 text-xs text-[#1c1c1c]" />
              <p className="banner-cart-price m-0 text-xs font-bold text-[#8a8a8a]"></p>
            </div>
            <div className="right-0 relative flex flex-col md:flex-row items-center w-[45%] lg:w-[auto]">
              <button
                onClick={() => setIsCartOpen(true)}
                className="pt-[8px] bg-grey hover:bg-[#1c1c1c] text-white uppercase font-expanded font-bold text-xxs px-8 md:px-6 py-1 mr-0 md:mr-5 mb-2 md:mb-0 w-full md:w-auto text-center"
              >
                Go To Cart
              </button>
              <button
                type="button"
                onClick={onCheckout}
                disabled={loading || loadingSubmit}
                className="pt-[8px] bg-paloverde hover:bg-[#e5b41d] text-white uppercase font-expanded font-bold text-xxs cursor-pointer px-8 md:px-6 lg:px-10 py-1 w-full md:w-auto"
              >
                Checkout
              </button>
            </div>

            <FaRegTimesCircle
              className="absolute right-10 md:right-4 xl:right-10 top-7 w-6 h-6 hover:text-paloverde cursor-pointer hidden md:block"
              onClick={() => closeCartBanner()}
            />
          </div>
        </div>
      </div>
    </header>
  )
}

export default AddToCartBanner
