/* eslint-disable */
import React from "react"

const WidgeticWithTitle = ({ blok }) => (
  <div className="compare-container">
    <h3 className="display-font text-obsidian after:border-b-paloverde after:border-b-2 after:w-[100px] after:m-auto after:t-[5px] mb-10">
      {blok.title}
    </h3>
    <widgetic
      className="desktop-only"
      id={blok.desktop_id}
      resize="fill-width"
      width="3000"
      height="824"
      autoscale="on"
      adaptive="414"
    />
    {blok.mobile_id ? (
      <widgetic
        className="mobile-only"
        id={blok.mobile_id}
        resize="fill-width"
        width="1284"
        height="1788"
        autoscale="on"
        adaptive="414"
      />
    ) : null}
  </div>
)

export default WidgeticWithTitle
