/* eslint-disable camelcase */
import React from "react"
// import PropTypes from 'prop-types';
import * as style from "../../templates/index.module.scss"
import SecondaryButton from "../SecondaryButton"

const ProductHighlightsBannerBelow = ({ blok }) => {
  const {
    background_img,
    background_img_mobile,
    tag,
    title,
    sub_title,
    cta_link,
  } = blok

  return (
    <div className={style.highlightBanner}>
      <img
        src={background_img_mobile}
        alt=""
        className="relative sm:block md:hidden w-screen mb-0"
      />
      <img
        src={background_img}
        alt=""
        className="relative hidden sm:hidden md:block w-screen mb-0"
      />
      <div className={`${style.data} absolute`}>
        <div className="tag bg-grey-dark text-white font-bold text-sm inline-block px-4 pb-[2px] pt-[7px] rounded-tl-lg rounded-br-lg">
          {tag}
        </div>
        <div className="title font-black text-white text-4xl md:text-4xl lg:text-5xl mt-4">
          {title}
        </div>
        <div className="sub-title font-expanded text-white text-sm lg:text-md">
          {sub_title}
        </div>
        <div className="mt-3">
          <SecondaryButton
            text="LEARN MORE"
            link={cta_link}
            buttonStyle="white"
          />
        </div>
      </div>
    </div>
  )
}

export default ProductHighlightsBannerBelow

// ProductHighlightsBannerBelow.propTypes = {
//   background_img: PropTypes.string,
//   background_img_mobile: PropTypes.string,
//   tag: PropTypes.string,
//   title: PropTypes.string,
//   sub_title: PropTypes.string,
//   cta_link: PropTypes.string,
// };

// ProductHighlightsBannerBelow.defaultProps = {
//   background_img: PropTypes.string,
//   background_img_mobile: PropTypes.string,
//   tag: PropTypes.string,
//   title: PropTypes.string,
//   sub_title: PropTypes.string,
//   cta_link: PropTypes.string,
// };
