/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable
  jsx-a11y/control-has-associated-label,
  jsx-a11y/anchor-has-content
 */
import React from "react"
import PropTypes from "prop-types"
import "./style.scss"

const GenericTemplateBottom = props => {
  const { metafields, title } = props

  const oneWholeTwo = "one-whole-two"
  const oneWholeSpecs = "one-whole-specs"
  const oneWholeTips = "one-whole-tips"
  const oneWholeWhen2Use = "one-whole-when-to-use"

  const heroIcon1 = "hero-icon-1"
  const heroIcon1Text = "hero-icon-1-text"
  const heroIcon2 = "hero-icon-2"
  const heroIcon2Text = "hero-icon-2-text"
  const heroIcon3 = "hero-icon-3"
  const heroIcon3Text = "hero-icon-3-text"

  const heroImage1 = "hero-image-1"
  const heroImage1Position = "hero-image-1-position"
  const heroImage2 = "hero-image-2"
  const heroImage2Position = "hero-image-2-position"
  const heroImage3 = "hero-image-3"
  const heroImage3Position = "hero-image-3-position"

  const heroMobileImage1 = "hero-mobile-image-1"
  const heroMobileImage2 = "hero-mobile-image-2"
  const heroMobileImage3 = "hero-mobile-image-3"

  const heroSectionTitle1 = "hero-section-title-1"
  const heroSectionTitle2 = "hero-section-title-2"

  const heroVideoUrl = "hero-video-url"
  const heroVideoImage = "hero-video-image"

  const heroBlock1Title = "hero-block-1-title"
  const heroBlock1Text = "hero-block-1-text"
  const heroBlock2Title = "hero-block-2-title"
  const heroBlock2Text = "hero-block-2-text"
  const heroBlock3Title = "hero-block-3-title"
  const heroBlock3Text = "hero-block-3-text"

  const heroVideoImageMobile = "hero-video-image-mobile"
  const heroSectionSliderTitle = "hero-section-slider-title"
  const heroSliderEmbed = "hero-slider-embed"

  const whatsIncluded = "whats-included"
  const whatsIncludedMobile = "whats-included-mobile"

  const metafieldData = {}

  metafields?.forEach(metafield => {
    metafieldData[metafield.key] = metafield?.value
  })

  return (
    <>
      {metafieldData[heroSectionTitle1] ? (
        <>
          <div className="product-overview-hero-9">
            <div className="large--one-whole">
              <h3>{metafieldData[heroSectionTitle1]}</h3>
              {metafieldData[heroIcon1] &&
              metafieldData[heroIcon2] &&
              metafieldData[heroIcon3] ? (
                <div className="hero-features-banner">
                  <div className="grid-uniform">
                    <div className="grid__item large--one-third">
                      <img
                        src={metafieldData[heroIcon1]}
                        alt=""
                        width="150"
                        height="150"
                      />
                      {metafieldData[heroIcon1Text]}
                    </div>
                    <div className="grid__item large--one-third">
                      <img
                        src={metafieldData[heroIcon2]}
                        alt=""
                        width="150"
                        height="150"
                      />
                      {metafieldData[heroIcon2Text]}
                    </div>
                    <div className="grid__item large--one-third">
                      <img
                        src={metafieldData[heroIcon3]}
                        alt=""
                        width="150"
                        height="150"
                      />
                      {metafieldData[heroIcon3Text]}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="banners-container">
                <div
                  className="banner-overview banner-1"
                  style={{
                    backgroundImage: `url('${metafieldData[heroImage1]}')`,
                    backgroundPosition: `${metafieldData[heroImage1Position]}`,
                  }}
                />
                {metafieldData[heroVideoUrl] ? (
                  <div className="video-feature">
                    <div
                      className="video-container elements-video"
                      style={{
                        backgroundImage: `url('${metafieldData[heroVideoImage]}')`,
                      }}
                    >
                      <div className="float-btn">
                        <div className="play-button-outer">
                          <a id="inline" href="#data" className="cta-round" />
                          <div className="play-button" />
                        </div>
                      </div>
                      {/* <!-- Modal --> */}
                      <div style={{ display: "none" }}>
                        <div
                          id="data"
                          style={{
                            backgroundColor: "black !important",
                            padding: "0vw 0vw",
                            width: "90vw",
                          }}
                        >
                          <iframe
                            title={`${title} video`}
                            src={metafieldData[heroVideoUrl]}
                            width="640"
                            height="360"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="banner-overview banner-2"
                    style={{
                      backgroundImage: `url(${metafieldData[heroImage2]})`,
                      backgroundPosition: metafieldData[heroImage2Position],
                    }}
                  />
                )}
                <div
                  className="banner-overview banner-3"
                  style={{
                    backgroundImage: `url(${metafieldData[heroImage3]})`,
                    backgroundPosition: metafieldData[heroImage3Position],
                  }}
                />
              </div>
              <div className="banners-container-mobile">
                <div
                  className="banner-overview banner-1"
                  style={{
                    backgroundImage: `url(${metafieldData[heroMobileImage1]})`,
                  }}
                >
                  <div className="block-copy">
                    <h4>{metafieldData[heroBlock1Title]}</h4>
                    <p>{metafieldData[heroBlock1Text]}</p>
                  </div>
                </div>
                {metafieldData[heroVideoUrl] ? (
                  <div className="video-feature">
                    <div
                      className="video-container elements-video"
                      style={{
                        backgroundImage: `url(${metafieldData[heroVideoImageMobile]})`,
                      }}
                    >
                      <div className="float-btn">
                        <div className="play-button-outer">
                          <a id="inline" href="#data" className="cta-round" />
                          <div className="play-button" />
                        </div>
                      </div>
                      {/* <!-- Modal --> */}
                      <div style={{ display: "none" }}>
                        <div
                          id="data"
                          style={{
                            backgroundColor: "black !important",
                            padding: "0vw 0vw",
                            width: "90vw",
                          }}
                        >
                          <iframe
                            title={`${title} video`}
                            src={metafieldData[heroVideoUrl]}
                            width="640"
                            height="360"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="banner-overview banner-2"
                    style={{
                      backgroundImage: `url(${metafieldData[heroMobileImage2]})`,
                    }}
                  >
                    <div className="block-copy">
                      <h4>{metafieldData[heroBlock2Title]}</h4>
                      <p>{metafieldData[heroBlock2Text]}</p>
                    </div>
                  </div>
                )}
                <div
                  className="banner-overview"
                  style={{
                    backgroundImage: `url(${metafieldData[heroMobileImage3]})`,
                  }}
                >
                  <div className="block-copy">
                    <h4>{metafieldData[heroBlock3Title]}</h4>
                    <p>{metafieldData[heroBlock3Text]}</p>
                  </div>
                </div>
              </div>
              {metafieldData[heroSectionSliderTitle] &&
              metafieldData[heroSliderEmbed] ? (
                <>
                  <h3>{metafieldData[heroSectionSliderTitle]}</h3>
                  <div className="slider-wrapper">
                    {metafieldData[heroSliderEmbed]}
                  </div>
                </>
              ) : (
                ""
              )}
              {metafieldData[whatsIncluded] &&
              metafieldData[whatsIncludedMobile] ? (
                <>
                  <h3 className="hero-mt">
                    {metafieldData[heroSectionTitle2]}
                  </h3>
                  <div className="banner-overview hero-included desktop-view">
                    <img
                      src={metafieldData[whatsIncluded]}
                      alt=""
                      width="2240"
                      height="540"
                    />
                  </div>
                  <div className="banner-overview hero-included mobile-view">
                    <img
                      src={metafieldData[whatsIncludedMobile]}
                      alt=""
                      width="2240"
                      height="540"
                    />
                  </div>
                </>
              ) : (
                ""
              )}
              {/* {% include 'product-customer-support-9' %} */}
            </div>
          </div>
          {/* <!--product-overview end--> */}
          <div className="additional-overview">
            <div className="large--one-whole">{metafieldData[oneWholeTwo]}</div>
          </div>
          {/* <!--additional overview end--> */}
          <div className="tech-specs">
            <div className="large--one-whole clearfix">
              {metafieldData[oneWholeSpecs] ? (
                <>
                  <h3>Tech Specs</h3>
                  {metafieldData[oneWholeSpecs]}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* <!--tech-specs--> */}
          <div className="tips-field">
            <div className="large--one-whole clearfix">
              {metafieldData[oneWholeTips] ? (
                <>
                  <h3>Tips</h3>
                  {metafieldData[oneWholeTips]}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* <!--tips--> */}
          <div className="how-to-use">
            <div className="large--one-whole clearfix">
              {metafieldData[oneWholeWhen2Use] ? (
                <>
                  <h3>When To Use</h3>
                  {metafieldData[oneWholeWhen2Use]}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* <!--tech-specs--> */}
        </>
      ) : (
        ""
      )}
    </>
  )
}

export default GenericTemplateBottom

GenericTemplateBottom.propTypes = {
  metafields: PropTypes.objectOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
}
