/* eslint-disable */
import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { GoTriangleDown } from "react-icons/go"
import { useLocation } from "@reach/router"

const DropDown = ({ title, items }: any) => {
  const location = useLocation()

  const [isOpen, setIsOpen] = useState(false)
  const elementRef = useRef<HTMLDivElement>(null)

  const closeDropdown = (e: any) => {
    setIsOpen(false)
  }
  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.addEventListener("mouseleave", closeDropdown)
    }

    return () => {
      elementRef.current?.removeEventListener("mouseleave", closeDropdown)
    }
  }, [elementRef])
  return (
    <div ref={elementRef} className="relative">
      <button className="w-full" onClick={() => setIsOpen(!isOpen)}>
        <div className="flex items-center space-x-2">
          {title.toLowerCase() !== "support" ? (
            <p className="!m-0 !text-sm mt-1 font-sans text-[#1C1C1C] font-bold hover:text-[#686868]">
              {title}
            </p>
          ) : (
            <Link to="/support/">
              <p className="!m-0 !text-sm mt-1 font-sans text-[#1C1C1C] font-bold hover:text-[#686868]">
                {title}
              </p>
            </Link>
          )}
          {title.toLowerCase() !== "support" ? (
            <GoTriangleDown color="#1c1c1c" size={16} />
          ) : null}
        </div>
        <ul
          style={{
            opacity: isOpen ? 1 : 0,
            visibility: isOpen ? "visible" : "hidden",
            maxHeight: isOpen ? "400px" : 0,
          }}
          className={`absolute transition-[max-height] duration-500 max-h-0 overflow-y-auto opacity-0 invisible top-6 w-full bg-white ${
            title.toLowerCase() === "support" ? "" : "border border-black"
          } z-[10000] min-w-[280px] right-0 rounded-md`}
        >
          {title.toLowerCase() !== "support"
            ? items.map((item: any, index: number) => (
                <Link
                  to={`${item.link}/${location.search ? location.search : ""}`}
                  key={index}
                >
                  <li
                    style={{
                      paddingBottom: index === items.length - 1 ? "1rem" : "",
                    }}
                    className="text-left !m-0 text-md font-sans text-[#1C1C1C] hover:font-bold p-4 pb-0"
                  >
                    {item.label}
                  </li>
                </Link>
              ))
            : null}
        </ul>
      </button>
    </div>
  )
}

export default DropDown
