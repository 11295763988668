// extracted by mini-css-extract-plugin
export var breakBanner = "style-module--breakBanner--25623";
export var chartComparison = "style-module--chartComparison--68d0d";
export var chromaOverview = "style-module--chromaOverview--bbff0";
export var cinematicBlackMist = "style-module--cinematicBlackMist--038b2";
export var cinematicGoldMist = "style-module--cinematicGoldMist--d0437";
export var cinematicPolarizer = "style-module--cinematicPolarizer--a6475";
export var cinematicVnd = "style-module--cinematicVnd--b01d1";
export var dataLeft = "style-module--dataLeft--cd877";
export var dataRight = "style-module--dataRight--c8d72";
export var introData = "style-module--introData--b2000";
export var introDataRight = "style-module--introDataRight--867e6";
export var leftTh = "style-module--leftTh--eb7f0";
export var productHero = "style-module--productHero--117d9";
export var sandBackg = "style-module--sandBackg--f2a4b";
export var videoElement = "style-module--videoElement--b886b";