import React from "react"

const PreorderNotification = props => {
  const { preorderToggleAddtoCart, preOrderCartCallback } = props
  return (
    <div className="bg-[#1C1C1C] h-[220px] sm:h-[240px] w-full lg:w-[319px] py-1 px-[40px] sm:px-[100px] lg:px-4 rounded-md z-50">
      <div className="text-center text-md lg:text-sm text-white !mt-2">
        Reserve Item!
      </div>
      <div className="bg-white w-full h-[1px] mt-2" />
      <div className="mt-5 px-2">
        <p className="text-center text-sm lg:text-xs text-white">
          You have added a reserve item to your cart. Please be aware that your
          entire order will not ship until the reserve items are in stock.
        </p>
      </div>
      <div className="w-full px-12 sm:px-[100px] lg:px-10">
        <button
          type="button"
          onClick={e => preOrderCartCallback(e)}
          className="text-white pt-2 pb-1 bg-paloverde w-full text-sm hover:bg-yellow-500 rounded-md"
        >
          OK
        </button>
      </div>
    </div>
  )
}

export default PreorderNotification
