import React from "react"

function PlayIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="83.683"
      height="23.155"
      viewBox="0 0 83.683 23.155"
    >
      <g
        id="Mobile_CTA"
        data-name="Mobile CTA"
        transform="translate(-40.5 -2474)"
      >
        <path
          id="Arrow"
          d="M11.577,0,23.155,19.506H0Z"
          transform="translate(60.006 2474) rotate(90)"
          fill={color}
        />
        <text
          id="PLAY"
          transform="translate(81.183 2488.577)"
          fill={color}
          fontSize="12"
          fontFamily="HelveticaNeueLTPro-BdEx, Helvetica Neue LT Pro"
          letterSpacing="0.13em"
          fontWeight="bold"
        >
          <tspan x="0" y="0">
            PLAY
          </tspan>
        </text>
      </g>
    </svg>
  )
}

export default PlayIcon
