/* eslint-disable */
import React, { useState, useEffect } from "react"
// import { Link } from "gatsby";
import loadable from "@loadable/component"
import * as style from "./style.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import { useStoryblok } from "../../utils/storyblok"
import DynamicComponents from "../DynamicComponents"
import image0 from "../../images/fourbyfivemch/image0.png"
import image1 from "../../images/fourbyfivemch/image1.png"
import image2 from "../../images/fourbyfivemch/image2.png"
import image3 from "../../images/fourbyfivemch/image3.png"
import useScrollBlock from "../../hooks/useScrollBlock"
import HelixVariantCart from "./helixVariantCart"

import PlayIcon from "../Icons/PlayIcon"

const ModalVideo = loadable(() => import("react-modal-video"))

const Below4by5MchOverview = ({
  product: variantProduct,
  cleanHelixProducts,
  dynamicATCProducts,
}) => {
  const [showVideo, setShowVideo] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()
  const [selectedTitle, setSelectedTitle] = useState(null)

  const helixDescription = [
    {
      title: "FULLY COLOR NEUTRAL FROM 1-7 STOPS",
      description:
        "In any lighting environment, no matter the stop, all your color will match and be consistent across all your content.",
    },
    {
      title: "INDUSTRY STANDARD COMPATIBILITY",
      description:
        "These 4x5.65 filters are the industry standard size and will fit in just about every matte box out there.",
    },
    {
      title: "BASECAMP COMPATIBILTY",
      description:
        "If you are looking to add these filters to your BaseCamp kit, they will only fit in the BaseCamp 4x5.65 3rd stage adaptor (sold separately).",
    },
    {
      title: "CINEMA SERIES GLASS FULLY RESOLVES LARGEST SENSORS",
      description:
        "These ND filters hold up against the largest sensors to give you complete color accuracy and neutrality.",
    },
  ]
  const scrollUp = e => {
    e.preventDefault()
    window.scrollTo({
      top: 0,
      behaviour: "smooth",
    })
  }

  useEffect(() => {
    if (showVideo) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [showVideo])

  const pageData = useStaticQuery(graphql`
    query {
      storyblokEntry(full_slug: { eq: "/products/4x5_filters" }) {
        id
        name
        content
        slug
        full_slug
        uuid
        created_at
      }
      shopifyProduct(handle: { eq: "4x5_filters" }) {
        handle
        title
      }
    }
  `)

  const product = pageData.shopifyProduct

  const story = pageData.storyblokEntry
    ? useStoryblok(pageData.storyblokEntry)
    : { content: null }

  const stickyNavComponent =
    story.content && story.content["sticky_nav"]
      ? story.content["sticky_nav"].map(blok => (
        <>
          {blok ? (
            <div {...sbEditable(blok)} className="sticky top-0 z-50">
              <DynamicComponents
                blok={blok}
                product={product}
                key={blok._uid}
              />
            </div>
          ) : null}
        </>
      ))
      : null

  return (
    <div className={`${style.fourbyfiveMchOverview} overflow-hidden`}>
      <div class={`${style.productHero} text-white flex flex-col lg:flex-row`}>
        {/* <div class={`${style.introData}`}>
          <div class="video-container">
            <a
              href="#data"
              id="inline"
              className={`${style.MobilePlayBtn} cta-round opacity-90 hover:opacity-100`}
              onClick={e => {
                e.preventDefault()
                setShowVideo(true)
              }}
            >
              <PlayIcon />
            </a>
          </div>
        </div> */}
        <div class={`${style.introDataRight} pl-0 lg:pl-20`}>
          <img
            className="w-2/3"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/LOGO_1.png?v=1695855646"
            alt="PolarPro x Motion Clubhouse"
          />
          {/* <h2 className="display-font-logo font-bold font-expanded uppercase">
            Helix
          </h2> */}
          <div className="w-11/12	">
            <p className="hidden lg:block">
              Same industry. New standard. Industry standard 4x5 filters built
              for industry professionals, by industry professionals.
            </p>
            <p className="block lg:hidden">
              Same industry. New standard. Industry standard 4x5 filters built
              for industry professionals, by industry professionals.
            </p>
          </div>
        </div>
        {/* modal */}
        <ModalVideo
          channel="youtube"
          videoId="ITjxjyiGuKA"
          isOpen={showVideo}
          onClose={() => setShowVideo(false)}
        />
      </div>
      <div className={`${style.breakBanner} bg-black py-28`}>
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Designed_To_Help_You_Tell_Your_Story_Title_b4adcc19-e2c4-4da4-a08c-56b6deba48da.png?v=1695922912"
          alt=""
        />
        <div className={`${style.dataRight} pl-5 hidden lg:block`}>
          <img
            class="w-1/2 m-0"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/THE_ALL_NEW_INDUSTRY_STANDARD.png?v=1695922900"
            alt=""
          />
        </div>
      </div>

      <div className={`${style.sandBackg} feature-block max-w-full m-auto`}>
        <div className="flex items-center flex-col lg:flex-row">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black  lg:leading-[1.1rem] text-2xl lg:text-sm xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-white mb-2 lg:md-8">
              Aluminum Frame Provides Unmatched Glass Protection
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-white">
              The first-ever 4x5 filter encased by an aluminum frame. Protect
              your glass from matte box clamps, drops, fingerprints, and more.
              No more stressing about where to set it down when swapping your
              filters, now you can place it on any flat surface without the
              glass getting damaged.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-white m-0">
              The first-ever 4x5 filter encased by an aluminum frame. Protect
              your glass from matte box clamps, drops, fingerprints, and more.
              No more stressing about where to set it down when swapping your
              filters, now you can place it on any flat surface without the
              glass getting damaged.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="w-full m-0 block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/25A9614.webp?v=1695923224"
              alt=""
            />
          </div>
        </div>
        <img
          class="w-full m-0 hidden lg:block"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Lifestyle_Image_Block_1_a5997d5a-77f5-4cd1-81db-2893437b4bdf.webp?v=1695923401"
          alt=""
        />
        <img
          class="w-full m-0 block lg:hidden"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Lifestyle_Image_Block_1.webp?v=1695923386"
          alt=""
        />
        <div className="flex items-center flex-col lg:flex-row-reverse">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black text-3xl lg:leading-[1.1rem] lg:text-base xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-white mb-2 lg:md-8">
              100% Visible Filter Etchings
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-white">
              Identify filter strength etchings easily on the top and front of
              the frame. These etchings allow you to quickly see which filter
              you need, grab it, and start shooting.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-white m-0">
              Identify filter strength etchings easily on the top and front of
              the frame. These etchings allow you to quickly see which filter
              you need, grab it, and start shooting.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="w-full m-0 block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/25A9883.webp?v=1695923498"
              alt=""
            />
          </div>
        </div>
      </div>

      <div
        className={`${style.breakBanner} ${style.leftStrike} bg-black py-28`}
      >
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/BOMBPROOF.png?v=1695923617"
          alt=""
        />
        <div className={`${style.dataLeft} pr-5 hidden lg:block`}>
          <img
            class="w-1/2 m-0 inline-block"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/BOMBPROOF_ALUMINUM_FRAMES.png?v=1695923636"
            alt=""
          />
        </div>
      </div>

      <div className={`${style.sandBackg} feature-block2 max-w-full m-auto`}>
        <div className="flex items-center flex-col lg:flex-row">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black lg:leading-[1.1rem] text-3xl lg:text-base xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-white mb-2 lg:md-8">
              Fingerprint-Free Handling
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-white">
              The aluminum frame allows you to easily install and remove filters
              without getting fingerprints on your glass.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-white m-0">
              The aluminum frame allows you to easily install and remove filters
              without getting fingerprints on your glass.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="w-full m-0 block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/25A0055.webp?v=1695923770"
              alt="Fingerprint-Free Handling"
            />
          </div>
        </div>

        <img
          class="w-full m-0 hidden lg:block"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Lifestyle_Image_Block_2.webp?v=1695923829"
          alt=""
        />
        <img
          class="w-full m-0 block lg:hidden"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Mask_1.webp?v=1695923844"
          alt=""
        />
        <img
          class="w-full m-0 block lg:hidden"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/Mask_2.webp?v=1695923849"
          alt=""
        />

        <div className="flex items-center flex-col lg:flex-row-reverse">
          <div className="py-28 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black lg:leading-[1.1rem] text-3xl lg:text-base xl:text-xl 2xl:text-4xl px-[2vw] text-left lg:text-left text-white mb-2 lg:md-8">
              Enhanced Color Accuracy
            </p>
            <p className="hidden xl:block text-left lg:text-md lg:text-left px-[2vw] text-white">
              PolarPro x Motion Clubhouse 4x5 filters were designed to deliver
              impeccable color accuracy. Capture the world in-camera as you see
              it in real time.
            </p>
            <p className="block xl:hidden text-left lg:text-xs lg:text-left px-[2vw] text-white m-0">
              PolarPro x Motion Clubhouse 4x5 filters were designed to deliver
              impeccable color accuracy. Capture the world in-camera as you see
              it in real time.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="w-full m-0 block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/25A4410_104579db-fd54-4a64-a783-d17c07fa313c.webp?v=1695924128"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="bg-black w-full flex flex-col lg:flex-row pt-[100px]">
        <div className=" w-full px-[10px] sm:px-[50px] lg:w-1/2">
          <p className="hilex-title mb-0">EXPLORE -</p>
          <p className="hilex-main-title text-[24px] lg:[20px] xl:text-[24px] 2xl:text-[30px]">
            ADDITIONAL SYSTEM DETAILS
          </p>
          {helixDescription.map((content, index) => (
            <div key={content.title}>
              <div className="flex justify-between border-t border-[#DDDDD7] pt-4">
                <p className="font-medium text-[20px] lg:[16px] xl:text-[20px] 2xl:text-[24px]  text-[#DDDDD7] w-[90%]">
                  {content.title}
                </p>
                {selectedTitle !== index ? (
                  <p
                    className="font-light text-[#DDDDD7] cursor-pointer text-3xl"
                    onClick={() => setSelectedTitle(index)}
                  >
                    +
                  </p>
                ) : (
                  <p
                    className="font-light text-white cursor-pointer text-3xl pr-2"
                    onClick={() => setSelectedTitle(null)}
                  >
                    -
                  </p>
                )}
              </div>
              {selectedTitle === index ? (
                <p className="text-[#DDDDD7] text-[13px] lg:text-[11px] xl:text-[12px] leading-4 2xl:text-[16px]">
                  {content.description}
                </p>
              ) : null}
            </div>
          ))}
        </div>
        <div className="w-full lg:w-1/2">
          {(selectedTitle === null) | 0 ? (
            <img
              className="w-full h-auto object-cover"
              src={image0}
              alt={"fourbyfivemchImg"}
            />
          ) : null}
          {selectedTitle === 0 ? (
            <img
              className="w-full h-auto object-cover"
              src={image0}
              alt={"fourbyfivemchImg"}
            />
          ) : null}
          {selectedTitle === 1 ? (
            <img
              className="w-full h-auto object-cover"
              src={image1}
              alt={"fourbyfivemchImg"}
            />
          ) : null}
          {selectedTitle === 2 ? (
            <img
              className="w-full h-auto object-cover"
              src={image2}
              alt={"fourbyfivemchImg"}
            />
          ) : null}
          {selectedTitle === 3 ? (
            <img
              className="w-full h-auto object-cover"
              src={image3}
              alt={"fourbyfivemchImg"}
            />
          ) : null}
        </div>
      </div>

      <div className={`${style.breakBanner} bg-black py-28`}>
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/FILTERS-COMPONENTS_6a3369c6-e007-43b7-9858-54495e252e0f.png?v=1695925108"
          alt=""
        />
        <div className={`${style.dataRight} pl-5 hidden lg:block`}>
          <img
            class="w-2/3 m-0"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/4X5.65_MOTION_CLUBHOUSE_SERIES_FILTERS_COMPONENTS..png?v=1695925095"
            alt=""
          />
        </div>
      </div>

      <div className="grid grid-cols-2 auto-cols-fr sm:grid-cols-2 md:grid-cols-3 xl-1600:grid-cols-4 gap-6 md:gap-6 xl:gap-10 bg-black text-center px-[2vw] pb-20">
        {[...variantProduct.variants, ...dynamicATCProducts]
          .filter(item => !item?.handle?.includes("burkard"))
          .map((variant, ind) => {
            return (
              <HelixVariantCart
                variant={variant}
                product={variantProduct}
                sliderImages={variant?.image?.originalSrc}
                variantProduct={variantProduct}
              />
            )
          })}
      </div>

      <div className="launchCommercial">
        <div className="flex flex-col lg:flex-col">
          <div>
            <img
              className="m-auto hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Group_1897_716c4bf9-72eb-4344-88be-6d2d5aaf9154.webp?v=1695925780"
              alt=""
            />
            <img
              className="m-auto block lg:hidden w-full"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/25A0882.webp?v=1695925760"
              alt=""
            />
            <img
              className="m-auto block lg:hidden w-full"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/25A0848.webp?v=1695925760"
              alt=""
            />
          </div>
          <div>
            <img
              className="m-auto hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/25A1082_8fda1fed-3d09-4950-bcb8-d9c7b4a71bca.webp?v=1695925780"
              alt=""
            />
            <img
              className="m-auto block lg:hidden w-full"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/25A1082.webp?v=1695925760"
              alt=""
            />
          </div>
        </div>
      </div>

      <div
        className={`${style.breakBanner} ${style.leftStrike} bg-black py-28`}
      >
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/3-STRENGTH-OPTIONS.png?v=1693521942"
          alt=""
        />
        <div className={`${style.dataLeft} pr-5 hidden lg:block`}>
          <img
            class="w-1/2 m-0 inline-block"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/PROFESSIONAL_DIFFUSION_3_STRENGTH_OPTIONS..png?v=1693521890"
            alt=""
          />
        </div>
      </div>

      <div className="before-after">
        <widgetic
          class="desktop-only"
          id="62015913ecb2a1ff298b4567"
          resize="fill-width"
          width="2374"
          height="925"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <widgetic
          class="mobile-only"
          id="62015aaaecb2a1ba2a8b4567"
          resize="fill-width"
          width="1284"
          height="1788"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <div className="data bg-black m-0 text-white text-center pt-5 pb-5">
          <h2 className="title font-black mb-2">Mist-L [1/8]</h2>
          <p className="text-[12px]">
            Our lightest diffusion option at 1/8 strength. Can be used in most
            applications to help soften the sharpness of digital sensors.
          </p>
        </div>
      </div>

      <div className="before-after">
        <widgetic
          class="desktop-only"
          id="6201594fecb2a11b2a8b456b"
          resize="fill-width"
          width="2374"
          height="925"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <widgetic
          class="mobile-only"
          id="62015ae2ecb2a1d82a8b4567"
          resize="fill-width"
          width="1284"
          height="1788"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <div className="data bg-black m-0 text-white text-center pt-5 pb-5">
          <h2 className="title font-black mb-2">Mist [1/4]</h2>
          <p className="text-[12px]">
            Smooths highlights and gives a subtle bloom to light sources. 1/4
            diffusion strength.
          </p>
        </div>
      </div>

      <div className="before-after">
        <widgetic
          class="desktop-only"
          id="6201597decb2a1402a8b4569"
          resize="fill-width"
          width="2374"
          height="925"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <widgetic
          class="mobile-only"
          id="62015b0becb2a1f82a8b4567"
          resize="fill-width"
          width="1284"
          height="1788"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <div className="data bg-black m-0 text-white text-center pt-5 pb-5">
          <h2 className="title font-black mb-2">Mist-H [1/2]</h2>
          <p className="text-[12px]">
            This heavier 1/2 diffusion strength has a strong diffused look.
            Ideal for creative applications such as music videos or dream
            sequences.
          </p>
        </div>
      </div>

      <div className={`${style.breakBanner} bg-black py-28`}>
        <img
          class="w-2/3 m-0 block lg:hidden m-auto"
          src="https://cdn.shopify.com/s/files/1/1050/9944/files/MORPHIC-FX.png?v=1693521942"
          alt=""
        />
        <div className={`${style.dataRight} pl-5 hidden lg:block`}>
          <img
            class="w-2/3 m-0"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/STAGE_1_2_MORPHIC_STREAK_FX..png?v=1693521890"
            alt=""
          />
        </div>
      </div>

      <div className="before-after">
        <widgetic
          class="desktop-only"
          id="62015816ecb2a17e298b456c"
          resize="fill-width"
          width="2374"
          height="925"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <widgetic
          class="mobile-only"
          id="62015a22ecb2a1572a8b4571"
          resize="fill-width"
          width="1284"
          height="1788"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <div className="data bg-black m-0 text-white text-center pt-5 pb-5">
          <h2 className="title font-black mb-2">BlueMorphic</h2>
          <p className="text-[12px]">
            BlueMorphic creates anamorphic-style blue streaks, available in
            Stage 1 Circular and Stage 2/3 Rectangular options.
          </p>
        </div>
      </div>

      <div className="before-after">
        <widgetic
          class="desktop-only"
          id="6201588decb2a17e298b4578"
          resize="fill-width"
          width="2374"
          height="925"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <widgetic
          class="mobile-only"
          id="62015a7cecb2a1572a8b4576"
          resize="fill-width"
          width="1284"
          height="1788"
          autoscale="on"
          adaptive="414"
        ></widgetic>
        <div className="data bg-black m-0 text-white text-center pt-5 pb-5">
          <h2 className="title font-black mb-2">GoldMorphic</h2>
          <p className="text-[12px]">
            GoldMorphic produces warm stylized streaks, available in both Stage
            1 Circular and Stage 2/3 Rectangular options.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Below4by5MchOverview
