/* eslint-disable */

import { useLocation } from "@reach/router"
import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import YotpoStarRatings from "../YotpoStarRatings"
import ProductCardPrice from "../ProductCard/ProductCardPrice"
import * as styles from "./style.module.scss"

const ProductCard2 = props => {
    const { product } = props
    const location = useLocation()

    const lowestVariantPrice = parseFloat(
        product.priceRangeV2?.minVariantPrice?.amount,
    ).toFixed(2)

    const shopifyIdExtractor = id => id?.slice(id.lastIndexOf("/") + 1)

    const productShopifyId = shopifyIdExtractor(product.shopifyId)
    const [overviewPageArr, setOverviewPageArr] = useState([])

    useEffect(() => {
        setOverviewPageArr(
            product.metafields?.filter(metafield => {
                return metafield.key === "overview-page"
            }),
        )
    }, [])

    return (
        <Link
            to={
                overviewPageArr?.length > 0
                    ? overviewPageArr[0].value
                    : `/products/${product.handle}/${location.search ? location.search : ""
                    }`
            }
            className=""
        >
            <div className={`${styles.productCard} mb-[10px]`}>
                <div className={`${styles.productImage} bg-white px-[25%] rounded-md`}>
                    {product.featuredImage ? (
                        <GatsbyImage image={product.featuredImage?.gatsbyImageData} />
                    ) : (
                        <img src={product?.images?.[0].node?.originalSrc} />
                    )}
                </div>

                <div className="hoverDataContainer">
                    <span className="text-xs text-[#1C1C1C] font-black font-expanded block uppercase mb-2 mt-[20px] tracking-wider">
                        {product.title}
                    </span>
                    {/* Yotpo Star Ratings */}
                    <YotpoStarRatings
                        product={product}
                        productShopifyId={productShopifyId}
                    />
                    {product.metafields?.map(metafield => {
                        let quickviewDesc = ""
                        if (metafield.key === "quickview-content") {
                            quickviewDesc = metafield.value
                        } else {
                            return
                        }
                        return (
                            <div
                                className={`${styles.quickview} text-xs text-[#949494] font-condensed`}
                                dangerouslySetInnerHTML={{ __html: quickviewDesc }}
                            ></div>
                        )
                    })}
                    {product ? <ProductCardPrice product={product} /> : null}
                </div>
            </div>
        </Link>
    )
}

export default ProductCard2
