/* eslint-disable camelcase */
import React from "react"
import * as style from "../../templates/index.module.scss"

const FeaturedCollectionItem = ({ blok }) => {
  const { name, hover_subtitle, link_to, Media } = blok

  return (
    <a
      href={link_to}
      className={`${style.featuredCollectionItem} h-[122vw] md:h-[30vw] bg-cover w-[90vw] md:w-[22vw] mb-10 md:mb-0 flex flex-col items-center justify-center`}
      style={{ backgroundImage: `url("${Media?.filename}")` }}
    >
      <span className="relative z-10">
        <h3 className="font-black text-white text-2xl md:text-xl lg:text-2xl m-2 text-center">
          {name}
        </h3>
        <p
          className={`${style.hoverTag} font-expanded font-normal text-xs md:text-xxs lg:text-xs text-white opacity-0 text-center`}
        >
          {hover_subtitle}
        </p>
      </span>
    </a>
  )
}

export default FeaturedCollectionItem
