import React from "react"
import PropTypes from "prop-types"
import { render } from "storyblok-rich-text-react-renderer"
import * as style from "./style.module.scss"

const ListItem = ({ blok }) => {
  const { listing } = blok

  return (
    <li className={`flex flex-row items-center ${style.listing}`}>
      {render(listing)}
    </li>
  )
}

export default ListItem

ListItem.propTypes = {
  blok: PropTypes.shape({
    listing: PropTypes.element,
  }),
}

ListItem.defaultProps = {
  blok: {
    listing: "",
  },
}
