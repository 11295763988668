/* eslint-disable */
import React, { useEffect } from "react"
import PropTypes from "prop-types"

const ZigZagBanner = ({ blok, index, lastChildIndex }) => {
  const {
    title,
    subTitle,
    desktopImage,
    mobileImage,
    comparisonSlider,
    widgeticDesktopId,
    widgeticMobileId,
  } = blok

  //Widget Script
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://widgetic.com/sdk/sdk.js"
    script.async = true
    document.head.appendChild(script)

    script.onload = e => {
      window.Widgetic.UI.parse()
    }

    return () => {
      script.remove()
    }
  })

  return (
    <div
      className={`flex flex-col ${
        index % 2 === 0 ? "md:flex-row-reverse" : "md:flex-row"
      } text-obsidian`}
    >
      <div
        className={`relative w-full md:w-2/3 offset ${
          index === 0
            ? "md:top-[-60px]"
            : index === lastChildIndex
            ? "md:bottom-[-80px]"
            : ""
        }`}
      >
        {comparisonSlider ? (
          <>
            <widgetic
              id={widgeticDesktopId}
              resize="fill-width"
              width="2374"
              height="925"
              autoscale="on"
              adaptive="414"
              class="hidden md:block"
            >
              <h1>test</h1>
            </widgetic>
            {widgeticMobileId ? (
              <widgetic
                id={widgeticMobileId}
                resize="fill-width"
                width="415"
                height="461"
                autoscale="on"
                adaptive="414"
                class="md:hidden"
              ></widgetic>
            ) : null}
          </>
        ) : (
          <>
            <img className="hidden md:block" src={desktopImage} />
            <img className="md:hidden" src={mobileImage} />
          </>
        )}
      </div>
      <div className="w-full text-center md:text-left md:w-1/3 py-[40px] px-[10%] md:pt-[9%] md:pb-[20px] md:pr-[70px] pl-[6%]">
        <p className="text-3xl font-black ql-fx">{title}</p>
        <p className="text-xs font-light">{subTitle}</p>
      </div>
    </div>
  )
}

export default ZigZagBanner

ZigZagBanner.propTypes = {
  blok: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    desktopImage: PropTypes.string,
    mobileImage: PropTypes.string,
    comparisonSlider: PropTypes.bool,
    widgeticDesktopId: PropTypes.string,
    widgeticMobileId: PropTypes.string,
  }),
  index: PropTypes.number.isRequired,
  lastChildIndex: PropTypes.number.isRequired,
}

ZigZagBanner.defaultProps = {
  blok: {
    title: "CONTENT IS MISSING",
    subTitle: "CONTENT IS MISSING",
    desktopImage: "",
    mobileImage: "",
    comparisonSlider: false,
    widgeticDesktopId: "",
    widgeticMobileId: "",
  },
}
