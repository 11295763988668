/* eslint-disable */
import React from "react"

const PolarProGoldenLogo = props => {
  const { width } = props

  // width="60.002" height="29.999"
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 70 70">
      <path
        id="PolarPro_Icon"
        data-name="PolarPro Icon"
        d="M706.113,395.978a35,35,0,1,0,34.993,35.007V395.978ZM707.22,439.6a7.093,7.093,0,1,1-7.091-7.094h7.091Zm7.094-10.158H700.128a10.12,10.12,0,0,0-7.094,2.9v-9.99a7.1,7.1,0,0,1,7.094-7.094h14.186a7.093,7.093,0,0,1,0,14.186Z"
        transform="translate(-671.106 -395.978)"
        fill="#c99e1a"
      />
    </svg>
  )
}

export default PolarProGoldenLogo
