/* eslint-disable */
import React from 'react';

const MultipleBeforeAfterComparison = (props) => {
    return (
        <div>
            {props?.before_after?.map((before_after, index) => (
                <div key={index} className="before-after">
                    <div className="hidden sm:block">
                        <widgetic
                            id={before_after.desktop_id}
                            resize="fill-width"
                            width="2374"
                            height="925"
                            autoscale="on"
                            adaptive="414"
                        ></widgetic>
                    </div>
                    <div className="block sm:hidden">
                        <widgetic
                            id={before_after.mobile_id}
                            resize="fill-width"
                            width="1284"
                            height="1788"
                            autoscale="on"
                            adaptive="414"
                        ></widgetic>
                    </div>
                    <div className="data bg-black m-0 text-white text-center pt-5 pb-5">
                        <h2 className="title font-black mb-2">{before_after.title}</h2>
                        <p className="text-[12px]">{before_after.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default MultipleBeforeAfterComparison;
