/* eslint-disable */
import React from "react"
import LeftIcon from "../../assets/LeftIcon"
import RightIcon from "../../assets/RightIcon"

const FeaturedProductImagesArrow = ({
  onClick,
  right,
  subCarousel,
  imagesLength,
  index,
  isVideo,
  currentVideo,
  ...rest
}) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest

  const ariaLabelDirection = right ? "next" : "previous"

  let buttonStyle = `
    block absolute w-12 h-12
    transition hover:transition
    bg-center bg-no-repeat bg-transparent"
    
    ${right ? "right-[-15px] pr-[2px]" : "left-0"}
  `

  return (
    <button
      type="button"
      data-role="none"
      className={buttonStyle}
      aria-label={`${ariaLabelDirection}`}
      onClick={() => {
        right ? subCarousel?.current?.next() : subCarousel?.current?.previous()
        onClick()
      }}
    >
      {right ? (
        <RightIcon color="#686868" className="rightIcon" />
      ) : (
        <LeftIcon color="#686868" className="leftIcon" />
      )}
    </button>
  )
}

export default FeaturedProductImagesArrow
