/* eslint-disable */
import * as React from "react"
import debounce from "lodash.debounce"
import { StoreContext } from "../../context/StoreContext"
import { formatPrice } from "../../utils/formatPrice"
import { GatsbyImage } from "gatsby-plugin-image"
import { getShopifyImage } from "gatsby-source-shopify"
import DeleteIcon from "../Icons/DeleteIcon"
import NumericInput from "../NumericInput"

const LineItem = ({ item }) => {
  const { removeLineItem, checkout, updateLineItem, loading } =
    React.useContext(StoreContext)

  const [quantity, setQuantity] = React.useState(item.quantity)

  // Discount Variables
  let discounts = item.discountAllocations
  let discountTotalAmount = 0
  let discount = false
  let discountMessage

  // If there is a discount object inside the line item's discounts array, the line item is a promotion item
  if (discounts.length > 0) {
    discount = true
    let discountObj = discounts[0]
    let discountAmount = Number(discountObj.allocatedAmount.amount)
    discountTotalAmount += discountAmount
    if (discountObj.discountApplication.description) {
      discountMessage = discountObj.discountApplication.description
    }
  }

  const variantImage = {
    ...item.variant.image,
    originalSrc: item.variant.image.src,
  }

  const price = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount),
  )

  // creates a discountedPrice variable to display actual price in cart LineItem component
  const discountedPrice = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) - discountTotalAmount / quantity,
  )

  const estimatedPrice = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount),
  )

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    // applying the discount to the subtotal
    Number(item.variant.priceV2.amount) * quantity - discountTotalAmount,
  )

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id)
  }

  const uli = debounce(
    value => updateLineItem(checkout.id, item.id, value),
    300,
  )

  const debouncedUli = React.useCallback(value => uli(value), [])

  const handleQuantityChange = value => {
    if (value !== "" && Number(value) < 1) {
      return
    }
    setQuantity(value)
    if (Number(value) >= 1) {
      debouncedUli(value)
    }
  }

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1)
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1)
  }

  const image = React.useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: "constrained",
        crop: "contain",
        width: 160,
        height: 160,
      }),
    [variantImage.src],
  )

  return (
    <tr className="border-grey border-solid border-b">
      <td>
        {image && (
          <GatsbyImage
            key={variantImage.src}
            image={image}
            alt={
              variantImage.altText !== undefined &&
              variantImage.altText !== null
                ? variantImage.altText
                : item.variant.title
            }
          />
        )}
      </td>
      <td>
        <p className="text-base sm:text-lg font-bold mb-2 sm:mb-5">
          {item.title}
        </p>
        <div className="text-sm sm:text-base capitalize">
          {item.variant.title === "Default Title" ? "" : item.variant.title}
        </div>
        <div className="text-sm flex items-center">
          <button onClick={handleRemove} className="mt-2 mb-2">
            <DeleteIcon className="inline" />{" "}
            <span className="inline">Remove</span>
          </button>
        </div>
        <div className="flex justify-between items-center sm:hidden">
          <p className="m-0">
            {discount ? (
              <div>
                <span className="line-through">{price}</span>
                <br></br>
                <span>{discountedPrice}</span>
              </div>
            ) : (
              price
            )}
          </p>
          <div>
            {discount ? (
              <NumericInput
                disabled={true}
                value={quantity}
                aria-label="Quantity"
                onIncrement={doIncrement}
                onDecrement={doDecrement}
                onChange={e => handleQuantityChange(e.currentTarget.value)}
              />
            ) : (
              <NumericInput
                disabled={loading}
                value={quantity}
                aria-label="Quantity"
                onIncrement={doIncrement}
                onDecrement={doDecrement}
                onChange={e => handleQuantityChange(e.currentTarget.value)}
              />
            )}
          </div>
        </div>
      </td>
      <td className="hidden sm:table-cell">
        {discount ? (
          <div>
            <span className="line-through">{price}</span>
            <br></br>
            <span>{discountedPrice}</span>
          </div>
        ) : (
          price
        )}
      </td>
      <td className="hidden sm:table-cell">
        {discount ? (
          <NumericInput
            disabled={true}
            value={quantity}
            aria-label="Quantity"
            onIncrement={doIncrement}
            onDecrement={doDecrement}
            onChange={e => handleQuantityChange(e.currentTarget.value)}
          />
        ) : (
          <NumericInput
            disabled={loading}
            value={quantity}
            aria-label="Quantity"
            onIncrement={doIncrement}
            onDecrement={doDecrement}
            onChange={e => handleQuantityChange(e.currentTarget.value)}
          />
        )}
      </td>
      <td className="hidden sm:table-cell text-right">
        {subtotal}
        <br></br>
        {discountMessage}
      </td>
    </tr>
  )
}

export default LineItem
