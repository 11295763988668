/* eslint-disable */
import React from "react"
import "./style.scss"

const DefenderPromoSection = ({ blok }) => (
  <div className="feature-item defender360 mb-20">
    <div className="title-with-sub">
      <h3>
        Defender360<sup>TM</sup>
      </h3>
      <p className="title-sub">
        360° of filter protection, featuring a fully waterproof seal.
      </p>
    </div>

    <div className="two-image-blocks">
      <img className="image-block desktop-only" src={blok.left_image_desktop} />
      <img
        className="image-block desktop-only"
        src={blok.right_image_desktop}
      />

      <img className="image-block mobile-only" src={blok.left_image_mobile} />
      <img className="image-block mobile-only" src={blok.right_image_mobile} />
    </div>
  </div>
)

export default DefenderPromoSection
