import React, { useContext, useEffect, useState } from "react"
// import PropTypes from 'prop-types';
import { LiteChaserContext } from "../../context/LiteChaserContext"

const KitIncludesBox = () => {
  const { kitIncludesSet } = useContext(LiteChaserContext)

  const [included, setIncluded] = useState(Array.from(kitIncludesSet))

  useEffect(() => {
    const kitSetArr = Array.from(kitIncludesSet)

    setIncluded(kitSetArr)
  }, [kitIncludesSet])

  return (
    <>
      <hr className="m-0" />
      <div className="bg-grey-tini-light pt-[20px] pl-[15px] pb-4">
        <h4 className="font-condensed text-sm font-bold mb-4">Kit Includes:</h4>
        <ul className="list-disc m-0 ml-3">
          {included.map(item => (
            <li className="mb-1 text-xs">{item}</li>
          ))}
        </ul>
      </div>
      <hr className="m-0" />
    </>
  )
}

export default KitIncludesBox

// KitIncludesBox.propTypes = {

// }
