import React from "react"

function Affirm() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="20"
      viewBox="0 0 20 25"
    >
      <g data-name="Affirm Logo">
        <path
          fill="#4a4af4"
          d="M4.088 18.006a10.052 10.052 0 1116.817 0h2.815a12.5 12.5 0 10-22.442 0z"
          data-name="Path 36"
        ></path>
        <path
          fill="#060809"
          d="M42.943 34.445a9.07 9.07 0 00-5.081 1.775l1.043 2.2a6.012 6.012 0 013.737-1.556c1.272 0 1.972.423 1.972 1.281 0 .572-.465.9-1.344.98-3.3.3-5.859 1.335-5.859 3.865 0 2.008 1.447 3.22 3.829 3.22a4.031 4.031 0 003.518-2.054v1.726h2.971v-7.235c0-2.988-2.078-4.195-4.784-4.195zm-.868 9.444c-.953 0-1.489-.412-1.489-1.087 0-1.411 1.716-1.75 3.966-1.75a2.651 2.651 0 01-2.479 2.837z"
          data-name="Path 37"
          transform="translate(-30.277 -27.877)"
        ></path>
      </g>
    </svg>
  )
}

export default Affirm
