import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

const ChevronDownIcon = props => {
  const { width = 10, onClick, index } = props

  return (
    <StaticImage
      src="../../../images/chevron-down-solid.svg"
      width={50}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Down Icon"
      style={{ width }}
      className={`down-icon down-icon-index-${index}`}
      onClick={onClick}
    />
  )
}

export default ChevronDownIcon

ChevronDownIcon.propTypes = {
  width: PropTypes.number,
}

ChevronDownIcon.defaultProps = {
  width: PropTypes.number,
}
