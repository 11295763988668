import React from 'react';
import './spec_data.scss';
import SpecItem from '.';

const SpecData = ({ blok: { spec_item }, propClass }) => {
    if (spec_item?.length) {
        const specComponents =
            spec_item.map((blok, index) => (
                <SpecItem blok={blok} key={blok._uid} propClass={index === spec_item.length - 1 ? undefined : propClass} />
            ))
        return specComponents;
    }
    return null;
}

export default SpecData;
