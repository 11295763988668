export const setCookie = (name, value, exdays) => {
  const exdate = new Date()
  exdate.setDate(exdate.getDate() + exdays)
  const val =
    escape(value) + (exdays == null ? "" : `; expires=${exdate.toUTCString()}`)
  document.cookie = `${name}=${val}`
}

export const getCookie = name => {
  let value = document.cookie
  let start = value.indexOf(` ${name}=`)
  if (start === -1) {
    start = value.indexOf(`${name}=`)
  }
  if (start === -1) {
    value = null
  } else {
    start = value.indexOf("=", start) + 1
    let end = value.indexOf(";", start)
    if (end === -1) {
      end = value.length
    }
    value = unescape(value.substring(start, end))
  }
  return value
}
