/* eslint-disable */

import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { AiOutlineArrowRight } from "react-icons/ai"
import { RiArrowDownSFill } from "react-icons/ri"
import useAuth from "../../hooks/useAuth"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import SecondaryButton from "../SecondaryButton"
import { useLocation } from "@reach/router"
import { functionsEndpoint } from "../../config"
import axiosLib from "axios"

const items = [
  {
    title: "My Account",
    link: "/account",
  },
  {
    title: "Shipping Address(s)",
    link: "/account/shipping-address/",
  },
  {
    title: "Order History",
    link: "/account/order-history",
  },
  {
    title: "Returns",
    link: "https://polarpro.loopreturns.com/#/",
  },
  {
    title: "Contact Support",
    link: "/account/contact-support/",
  },
]

const MobileAccountSidebar = () => {
  const location = useLocation()
  const axios = useAxiosPrivate()
  const [selected, setSelected] = useState(items[0])
  const { setAuth } = useAuth()
  const [openDrawer, setOpenDrawer] = useState(false)

  useEffect(() => {
    const filtered = items.filter(
      item =>
        location.pathname === item.link ||
        location.pathname === `${item.link}/`,
    )?.[0]
    setSelected(filtered || items[0])
  }, [location])

  const handleLogout = (e: any) => {
    e.preventDefault()

    axiosLib
      .get(functionsEndpoint + "/logout", {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res: any) => {
        setAuth?.({})
        typeof window !== "undefined"
          ? localStorage.setItem("loggedIn", "false")
          : null
        navigate(`/${location.search ? location.search : ""}`)
      })
      .catch((err: any) => {
        console.error(
          err.message,
          "But, your session has been closed, and logged out.",
        )
        setAuth?.({})
        typeof window !== "undefined"
          ? localStorage.setItem("loggedIn", "false")
          : null
        navigate(`/${location.search ? location.search : ""}`)
      })
  }

  if (location.pathname.includes("/support/contact-us")) {
    return null
  }

  return (
    <div className="w-full bg-gray-500 flex lg:hidden justify-center items-center px-4 py-6 relative">
      <div
        onClick={() => setOpenDrawer(!openDrawer)}
        className="bg-white cursor-pointer w-full border-2 flex border-black"
      >
        <div className="border-r-2 border-black w-full flex justify-center items-center py-3">
          <p className="mb-0 uppercase text-md">{selected?.title}</p>
        </div>
        <div className="flex items-center px-1 ">
          <RiArrowDownSFill fontSize={36} />
        </div>
      </div>
      {openDrawer && (
        <div className="absolute top-full z-[99999] w-full bg-black px-4 py-6">
          <h1 className="text-2xl font-extrabold text-white">Account Menu</h1>
          {items.map((item, idx) => (
            <div
              key={idx}
              className="text-white pb-6 mt-6 border-b border-gray-500 min-w-[200px]"
            >
              <SecondaryButton
                buttonStyle={
                  location.pathname === item.link ||
                    location.pathname === `${item.link}/`
                    ? "gold"
                    : "gold-hover"
                }
                isActive={
                  location.pathname === item.link ||
                  location.pathname === `${item.link}/`
                }
                text={item.title}
                link={item.link}
              ></SecondaryButton>
            </div>
          ))}
          <button
            onClick={handleLogout}
            className="mb-0 flex items-start h-full border-2 text-center border-gray-800 mt-4 lg:mt-0 bg-white w-full justify-between"
          >
            <div className="w-full flex justify-center">
              <p className="mb-0 m-0 px-4 text-center lg:px-10 py-2 text-lg leading-8 font-bold">
                Sign Out
              </p>
            </div>
            <div className="px-2 border-l-2 py-4 border-gray-800 mb-0 h-full flex justify-center items-center">
              <AiOutlineArrowRight fontWeight={800} fontSize={18} />
            </div>
          </button>
        </div>
      )}
    </div>
  )
}

export default MobileAccountSidebar
