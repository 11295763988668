import React from "react"

const AdventureAssurance = () => {
  return (
    <svg
      id="AdventureAssurance_Badge"
      data-name="AdventureAssurance Badge"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="20"
      viewBox="0 0 20 25"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Your_Icons" data-name="Your Icons">
          <path
            id="Path_124"
            data-name="Path 124"
            d="M25.571,21.749a13.591,13.591,0,0,0,.581-3.4l-5.78,1.724Z"
            transform="translate(-10.187 -9.178)"
            fill="#1c1c1c"
          />
          <path
            id="Path_125"
            data-name="Path 125"
            d="M3.511,8.343,8.687,10l7.3-2.494V2.94a.429.429,0,0,0-.337-.5l-.014,0L8.2.03a.673.673,0,0,0-.4,0Q4.08,1.229.368,2.443A.43.43,0,0,0,0,2.924l0,.024V7.838q0,1,.1,1.99L3.183,8.366a.444.444,0,0,1,.331-.023Z"
            transform="translate(0.002 0)"
            fill="#1c1c1c"
          />
          <path
            id="Path_126"
            data-name="Path 126"
            d="M8.842,21.568h0L3.69,19.917.581,21.4c.056.312.125.624.2.933A10.724,10.724,0,0,0,2.3,25.769a9.434,9.434,0,0,0,1.435,1.667,12.319,12.319,0,0,0,4.416,2.5.526.526,0,0,0,.346-.018c.736-.3,1.472-.578,2.158-.945a9.885,9.885,0,0,0,3.628-3.2,9.6,9.6,0,0,0,1.087-2.047Z"
            transform="translate(-0.29 -9.96)"
            fill="#1c1c1c"
          />
        </g>
      </g>
    </svg>
  )
}

export default AdventureAssurance
