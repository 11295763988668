// @ts-nocheck
import React, { useContext, useEffect, useState } from "react"
import { StoreContext } from "../../context/StoreContext"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import PreorderNotification from "../PreorderNotification"

const AddToCart = ({
  productLists,
  newSingle,
  innerDivRef,
  price,
  variantName,
  hasVariants,
  productName,
  newVariantLoader,
  categoryName,
  available,
  noProductSelected,
  variant,
  single,
  preorder,
  variants,
  preorderDate,
  disabled,
  quantity,
  id,
}: any) => {
  const {
    addVariantToCart,
    changeBannerInfo,
    loading,
    openAddToCartSidebar,
    setAddToCartSidebarTypeFunction,
  } = useContext(StoreContext)

  const [isAdding, setIsAdding] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  let buttonText = "ADD TO CART"
  let disableATC = true
  let buttonStyle =
    "flex justify-center items-center text-center uppercase bg-[#1C1C1C] rounded-[5px] p-[10px] w-full font-sans font-expanded text-white text-[12px] font-bold"
  let disabledButtonStyle =
    "uppercase bg-gray-500 rounded-[5px] p-[10px] w-full font-sans font-expanded text-white text-[12px] font-bold"

  useEffect(() => {
    if (variantName) {
      setErrorMessage("")
    }
  }, [variantName])

  const addToCart = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAddToCartSidebarTypeFunction("addToCart")
    openAddToCartSidebar()
    e.preventDefault()
    // Notify customer of updated cart
    setIsAdding(true)
    addVariantToCart([
      {
        variantId: variant?.storefrontId,
        quantity: quantity as number,
        preorder: preorder,
        preorderDate: preorderDate,
      },
    ])
    // @ts-ignore
    changeBannerInfo(price, productName, variantName)
    if (price[0] === "$") price = price.substring(1)
    // if (productLists.length) {
    //   // @ts-ignore
    //   fbq("track", "AddToCart", {
    //     value: price,
    //     currency: "USD",
    //     num_items: productLists.length,
    //     content_type: "product_group",
    //     content_name: productName,
    //     content_category: categoryName,
    //   })
    // } else {
    //   // @ts-ignore
    //   fbq("track", "AddToCart", {
    //     value: price,
    //     currency: "USD",
    //     num_items: productLists.quantity,
    //     content_type: "product_group",
    //     content_name: productName,
    //     content_category: categoryName,
    //   })
    // }
    setTimeout(() => setIsAdding(false), 1500)
  }

  if (variantName && (!available || disabled)) {
    if (noProductSelected || disabled) {
      buttonText = "SELECT KIT"
      buttonStyle = `${buttonStyle} opacity-60`
    } else {
      buttonText = "OUT OF STOCK"
      buttonStyle = `${buttonStyle} opacity-60`
    }
  } else {
    if (preorder) {
      buttonText = "RESERVE NOW"
      buttonStyle = `${buttonStyle} hover:bg-paloverde`
    }
    if (newSingle == false) {
      disableATC = true
    } else {
      disableATC = false
      buttonStyle = `${buttonStyle} hover:bg-paloverde`
    }
  }

  // Add to cart script
  useEffect(() => {
    const handleAddToCart = () => {
      // Create the item object
      const item = {
        price: price,
        productName: productName,
        variantName: variantName,
        categoryName: categoryName,
        quantity,
        id,
        productLists: productLists,
      }

      // Call the geq.addToCart function with the item
      // @ts-ignore
      window.geq.addToCart(item)
    }

    const addClickListeners = () => {
      const addToCartButtons = document.querySelectorAll(".AddToCart")
      addToCartButtons.forEach(button => {
        button.addEventListener("click", handleAddToCart)
      })
    }

    addClickListeners()

    // Clean up event listeners on unmount if necessary
    return () => {
      const addToCartButtons = document.querySelectorAll(".AddToCart")
      addToCartButtons.forEach(button => {
        button.removeEventListener("click", handleAddToCart)
      })
    }
  }, [])

  const [preorderPopup, setPreorderPopup] = useState(false)
  const [preorderToggleAddtoCart, setPreorderToggleAddtoCart] = useState(false)

  const preOrderCartCallback = e => {
    addToCart(e)
    setPreorderPopup?.(false)
  }

  return (
    <div className="relative" ref={innerDivRef}>
      {preorderPopup && preorder ? (
        <div className="absolute w-full bottom-[60px] z-50">
          <PreorderNotification
            preorderToggleAddtoCart={preorderToggleAddtoCart}
            preOrderCartCallback={preOrderCartCallback}
          />
        </div>
      ) : (
        ""
      )}
      <div className="mb-0">
        {newVariantLoader ? (
          <div style={{ paddingTop: "10px" }}>
            <Skeleton height={40} />
          </div>
        ) : (
          <button
            className={`AddToCart ${disableATC ? disabledButtonStyle : buttonStyle
              }`}
            onClick={event => {
              if (
                variant?.selectedOptions?.length >= 1 &&
                variants?.length > 1 &&
                preorder
              ) {
                setPreorderPopup?.(true)
                return null
              }
              if (
                variant?.selectedOptions?.length == 1 &&
                variants?.length === 1
              ) {
                addToCart(event)
                return null
              }
              if (preorder === true) {
                setPreorderPopup?.(true)
              } else {
                addToCart(event)
              }
            }}
            disabled={disableATC || loading}
          >
            {variantName
              ? isAdding
                ? "Item Added!"
                : buttonText
              : "ADD TO CART"}
          </button>
        )}

        {errorMessage?.length > 0 && (
          <p className="font-sans italic text-[#1c1c1c] text-center text-sm mt-1 transition-all duration-[250ms]">
            {errorMessage}
          </p>
        )}
      </div>
    </div>
  )
}

export default AddToCart
