/* eslint-disable */

import React, { useState, useEffect } from "react"
import Seo from "../../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import "./litechaserOverview.scss"
import { useStoryblok } from "../../utils/storyblok"
import DynamicComponents from "../../components/DynamicComponents"
import loadable from "@loadable/component"
import useScrollBlock from "../../hooks/useScrollBlock"

const ModalVideo = loadable(() => import("react-modal-video"))

const LitechaserOverview = () => {
  const [showVideo, setShowVideo] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    if (showVideo) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [showVideo])

  const pageData = useStaticQuery(graphql`
    query {
      storyblokEntry(full_slug: { eq: "pages/litechaser-pro-iphone13" }) {
        id
        name
        content
        slug
        full_slug
        uuid
        created_at
      }
      shopifyProduct(handle: { eq: "litechaser-pro-iphone13" }) {
        handle
        title
      }
    }
  `)

  const product = pageData.shopifyProduct

  const story = pageData.storyblokEntry
    ? useStoryblok(pageData.storyblokEntry)
    : { content: null }

  const stickyNavComponent =
    story.content && story.content["sticky_nav"]
      ? story.content["sticky_nav"].map(blok => (
          <>
            {blok ? (
              <div {...sbEditable(blok)} className="sticky top-0 z-50">
                <DynamicComponents
                  blok={blok}
                  product={product}
                  key={blok._uid}
                />
              </div>
            ) : null}
          </>
        ))
      : null

  return (
    <div className="relative">
      <Seo title="LiteChaser Overview" />
      <div className="sticky top-0 z-50">{stickyNavComponent}</div>
      <div class="lightchaser13-overview">
        <div class="product-hero container-wrapper">
          <div class="hero-text vertical-center">
            <h3>iPhone® 13 Pro/Pro Max</h3>
            <h2 className="display-font-logo font-bold">LiteChaser Pro</h2>
            <p>
              The iPhone® 13 Pro/Pro Max has yet again revolutionized the
              mobile content-creating space. Maximize your creative experience
              and push the boundaries of what a mobile phone can accomplish with
              our expansive line of filters, lenses and accessories.
            </p>
          </div>
        </div>

        <div class="video-container">
          <a
            href="#data"
            id="inline"
            className="play-button x-y-center"
            onClick={e => {
              e.preventDefault()
              setShowVideo(true)
            }}
          />
          {/* <!-- Modal --> */}
          <ModalVideo
            channel="youtube"
            videoId="XKmILnohG0o"
            isOpen={showVideo}
            onClose={() => setShowVideo(false)}
          />
        </div>

        <div class="features">
          <h3 className="display-font-logo font-bold section-title">
            The Newly-Designed LiteChaser Pro For iPhone® 13
          </h3>
          <div class="item feature-one">
            <div class="text vertical-center">
              <h3 className="font-extrabold">New Filters</h3>
              <p>
                Our new lineup of filters features VNDs, Mist Diffusion, VND
                Mist combos, and creative morphic streak options.
              </p>
            </div>
          </div>

          <div class="item feature-two">
            <div class="text vertical-center">
              <h3 className="font-extrabold">Future-Proof Design</h3>
              <p>
                LCP13 filters and lenses will be fully-compatible with future
                LiteChaser Pro models.
              </p>
            </div>
          </div>

          {/* <div class="item feature-three">
            <div class="text vertical-center">
              <h3 className="font-extrabold">New Lenses</h3>
              <p>
                Offering an 18mm Wide, Anamorphic Blue, and Anamorphic Gold;
                with the ability to mount filters directly to each lens.
              </p>
            </div>
          </div> */}
        </div>

        <div class="wide-banner">
          <div class="x-y-center text-center">
            <h3 className="font-extrabold">New Grip Features</h3>
            <p>
              The LCP13 Grip now features both a 1/4''-20 thread and top cold
              shoe mount, perfect for mounting external accessories such as
              lights and mics.
            </p>
          </div>
        </div>

        <div class="filter-offerings">
          <h3 className="display-font-logo font-bold section-title">
            New FX Filter Offerings
          </h3>
          <div class="filter-listing container-wrapper">
            <div class="filter">
              <div class="image listing-one"></div>
              <div class="tag">
                <p className="font-extrabold">Mist [1/4]</p>
                <span class="sub-text">1/4 stregth diffusion.</span>
              </div>
            </div>

            <div class="filter">
              <div class="image listing-two"></div>
              <div class="tag">
                <p className="font-extrabold">BlueMorphic</p>
                <span class="sub-text">
                  Anamorphic-style blue streak effects.
                </span>
              </div>
            </div>

            <div class="filter">
              <div class="image listing-three"></div>
              <div class="tag">
                <p className="font-extrabold">GoldMorphic</p>
                <span class="sub-text">
                  Anamorphic-style gold streak effects.
                </span>
              </div>
            </div>

            <div class="filter wide">
              <div class="image combo"></div>
              <div class="tag">
                <p className="font-extrabold">Expanded VND Range</p>
                <span class="sub-text">
                  Available in 3-5 and 6-7 stop variations, with 1/4 strength
                  diffusion options. No filter-stacking needed.
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="chart">
          <h3 className="display-font-logo font-bold section-title">
            Compatibility & Usage
          </h3>
          <img
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP13-COMP-TABLE-DESKTOP.png?v=1659470596"
            alt=""
            class="desktop-only"
          />
          <img
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/LCP13-COMP-TABLE-MOBILE.png?v=1659470596"
            alt=""
            class="mobile-only"
          />
        </div>

        <div class="build-kit">
          <img
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Build-Your-Kit-Mark_72f50798-d363-4207-821a-4e3997a3fc4d.png?v=1642644431"
            alt=""
            class="be-prepare"
          />
          <a
            href="/products/litechaser-pro-iphone13"
            className="bc-build-btn font-extrabold"
          >
            Build Your LCP13 setup here
          </a>
        </div>
      </div>
    </div>
  )
}

export default LitechaserOverview
