/* eslint-disable */
import React, { useContext, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { StoreContext } from "../../context/StoreContext"

/*
 * Example lineitems to be passed into AddToCart:
 * [{
 *   variantId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8yOTEwNjAyMjc5Mg==',
 *   quantity: 1,
 *   customAttributes: [{ key: 'myKey', value: 'myValue' }]
 * }, {
 *   ...
 * }]
 */
const AddToCart = ({
  productLists,
  price,
  variantName,
  productName,
  categoryName,
  available,
  noProductSelected,
  preorder,
  backorder,
  style,
  ...props
}) => {
  const { addVariantToCart, changeBannerInfo, loading } =
    useContext(StoreContext)
  const [isAdding, setIsAdding] = useState(false)

  let buttonText = "ADD TO CART"
  let disableATC = true
  let buttonStyle =
    "text-11 tracking-widest min-w-200px w-full bg-obsidian text-white p-2 font-bold font-expanded uppercase"

  const addToCart = async e => {
    e.preventDefault()

    try {
      setIsAdding(true)
      if (productLists?.length > 0) {
        addVariantToCart(productLists)
      } else {
        addVariantToCart([productLists])
      }
      changeBannerInfo(price, productName, variantName)
      if (price[0] === "$") {
        price = price.substring(1)
      }

      const numItems = Array.isArray(productLists)
        ? productLists.length
        : productLists.quantity

      fbq("track", "AddToCart", {
        value: price,
        currency: "USD",
        num_items: numItems,
        content_type: "product_group",
        content_name: productName,
        content_category: categoryName,
      })

      setTimeout(() => setIsAdding(false), 1500)
    } catch (error) {
      console.error("Error adding to cart:", error)
      setIsAdding(false)
    }
  }

  if (productLists.length < 1) {
    console.log("Lineitem(s) is/are missing")
  }

  if (!available) {
    if (noProductSelected) {
      buttonText = "SELECT KIT"
      buttonStyle = `${buttonStyle} opacity-60`
    } else {
      buttonText = "OUT OF STOCK"
      buttonStyle = `${buttonStyle} opacity-60`
    }
  } else {
    if (preorder) {
      buttonText = "RESERVE NOW"
      disableATC = false
      buttonStyle = `${buttonStyle} hover:bg-paloverde`
    }
    disableATC = false
    buttonStyle = `${buttonStyle} hover:bg-paloverde`
  }

  // Add to cart script
  useEffect(() => {
    const handleAddToCart = () => {
      // Create the item object
      const item = {
        price: price,
        productName: productName,
        variantName: variantName,
        categoryName: categoryName,
        productLists: productLists,
      }

      // Call the geq.addToCart function with the item
      window?.geq?.addToCart(item)
    }

    const addClickListeners = () => {
      const addToCartButtons = document.querySelectorAll(".AddToCart")
      addToCartButtons.forEach(button => {
        button.addEventListener("click", handleAddToCart)
      })
    }

    addClickListeners()

    // Clean up event listeners on unmount if necessary
    return () => {
      const addToCartButtons = document.querySelectorAll(".AddToCart")
      addToCartButtons.forEach(button => {
        button.removeEventListener("click", handleAddToCart)
      })
    }
  }, [])

  return (
    <button
      type="submit"
      className={`AddToCart ${buttonStyle}`}
      onClick={addToCart}
      disabled={disableATC || loading}
      style={style}
      {...props}
    >
      {isAdding ? "Item Added!" : buttonText}
    </button>
  )
}

export default AddToCart

// Validate prop types
AddToCart.propTypes = {
  productLists: PropTypes.array,
  available: PropTypes.bool,
  preorder: PropTypes.bool,
  backorder: PropTypes.bool,
}

AddToCart.defaultProps = {
  productLists: [],
  available: false,
  preorder: false,
  backorder: false,
}
