/* eslint-disable */
import { sbEditable } from "@storyblok/storyblok-editable"
import React, { useContext } from "react"
// import { Link } from 'gatsby';
// import { StaticImage } from 'gatsby-plugin-image';
// import WhiteShippingIcon from '../../assets/WhiteShippingIcon';
// import ShippingIcon from '../Icons/ShippingIcon';
import * as styles from "./utilityBar.module.scss"
import { StoreContext } from "../../context/StoreContext"
// import BlackCashBackIcon from '../../assets/BlackCashBackIcon';
// import WhiteCashBackIcon from '../../assets/WhiteCashBackIcon';

const UtilityBar = ({ data }) => {
  const content = data?.content
  const { openPopUpModal } = useContext(StoreContext)

  return (
    <div
      {...sbEditable(content)}
      className={`${styles.utilityBarWrapper} ${
        openPopUpModal ? "z-0" : "z-[99999]"
      } md:flex flex-row justify-center items-center bg-[#4D4D4D] text-white font-bold text-[0.6rem] md:p-[7px] md:h-[41px]`}
    >
      <ul
        className={`${styles.utilityItemsWrapper} text-white md:items-center md:h-full text-2xs`}
      >
        {/* <li className="flex items-center m-2 pr-4 border-r-[1px] border-obsidian md:border-white">
        <span className="w-[30px] inline mr-2 mt-[10px]">
          <StaticImage
            src="../../images/Shipping-Icon-White-sticky2.svg"
            width={32}
            placeholder="tracedSVG"
            className=""
          />
          {' '}
        </span>
        <span className="mt-[2px]">Free U.S. Shipping</span>
      </li>
      <li className="flex items-center m-2 pr-4 border-r-[1px] border-obsidian md:border-white">
        <span className="mr-2">
          <StaticImage
            src="../../images/AdventureAssurance-White-Sticky.svg"
            width={16}
            placeholder="tracedSVG"
            className="mt-[3px]"
          />
          {' '}
        </span>
        <span className="mt-[2px]">AdventureAssurance™</span>
      </li>
      <li className="flex items-center m-2 border-obsidian md:border-white">
        <span className="w-[30px] inline mb-[2px]">
          <StaticImage
            src="../../images/CashBackIcon.svg"
            width={18}
            placeholder="tracedSVG"
            className="mt-[4px]"
          />
          {' '}
        </span>
        <span className="mt-[2px]">Earn 5% Cash Back</span>
      </li>
      <li className="m-2 pr-4 hidden">
        <Link to="/#">Learn more..</Link>
      </li> */}
        <li className="flex items-center m-2 border-obsidian md:border-white text-xs text-center">
          <p className="mt-[2px] font-condensed font-normal">
            {content?.description}
            &nbsp;
            <a
              href={content?.hyperlink?.url}
              className="underline text-paloverde"
            >
              {content?.hyperlink_text}
            </a>
          </p>
        </li>
      </ul>
    </div>
  )
}

export default UtilityBar
