/* eslint-disable */
import React, { useContext } from "react"
import PropTypes from "prop-types"
import { LiteChaserContext } from "../../context/LiteChaserContext"
import LiteChaserPartsSelector from "../LiteChaserPartsSelector"
import LiteChaserCaseSelector from "../LiteChaserCaseSelector"
import LiteChaserGripSelector from "../LiteChaserGripSelector"
import Tooltip from "../Tooltip"

const LCSecondarySection = ({ blok }) => {
  const { productsByParts } = useContext(LiteChaserContext)

  const sectionTitleLowerCase = blok.section_title
    ? blok.section_title.toLowerCase().split(" ").join("-")
    : "undefined"

  let Component

  // Dynamically render selector boxes
  switch (sectionTitleLowerCase) {
    case "cases":
      Component = LiteChaserCaseSelector
      break
    case "grips":
      Component = LiteChaserGripSelector
      break
    default:
      Component = LiteChaserPartsSelector
  }

  return (
    <div className="my-2">
      <div className="flex flex-row">
        <h2 className="text-base">
          {blok.display_title}
          &#58;
        </h2>
        <div className="pt-[0.2rem] pl-1">
          <Tooltip tooltipText={blok.tool_tips} />
        </div>
      </div>
      <span className="hidden bg-mojave bg-sage bg-glacier bg-lc-black w-1/2 w-1/3 border-mojave border-sage border-lc-black border-glacier" />
      <div className="flex flex-row items-start">
        <div className="h-full w-[100px] m-2">
          <img
            src={blok.section_img}
            alt={blok.section_title}
            className="flex w-[100px] w-full border-r-[1px] border-solid border-black pr-2 pb-2 top-0 "
          />
        </div>
        <div className="flex flex-row flex-wrap items-center w-[calc(100%-100px)] lc-partRow">
          {/* eslint-disable-next-line */}
          {productsByParts && productsByParts[sectionTitleLowerCase]
            ? productsByParts[sectionTitleLowerCase].map(product => (
                <Component
                  product={product}
                  sectionTitle={blok.section_title}
                  key={product.id}
                />
              ))
            : null}
        </div>
      </div>
    </div>
  )
}

export default LCSecondarySection

LCSecondarySection.propTypes = {
  blok: PropTypes.shape({
    section_title: PropTypes.string,
    display_title: PropTypes.string,
    section_img: PropTypes.string,
    tool_tips: PropTypes.string,
  }),
}

LCSecondarySection.defaultProps = {
  blok: PropTypes.shape({
    section_title: "Title Is NOT Defined",
    display_title: "Title Is NOT Defined",
    section_img:
      "https://cdn.shopify.com/s/files/1/1050/9944/files/polarpro_main_logo_668e63cb-fba2-4ac0-abf4-5e172925e526.png?v=1647568397",
    tool_tips:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...",
  }),
}
