// extracted by mini-css-extract-plugin
export var MobilePlayBtn = "style-module--MobilePlayBtn--dc9de";
export var breakBanner = "style-module--breakBanner--f3782";
export var dataLeft = "style-module--dataLeft--101bc";
export var dataRight = "style-module--dataRight--f7dcc";
export var fourbyfiveMchOverview = "style-module--fourbyfiveMchOverview--023c7";
export var introData = "style-module--introData--651cc";
export var introDataRight = "style-module--introDataRight--f52c0";
export var leftStrike = "style-module--leftStrike--38784";
export var productHero = "style-module--productHero--4d9f6";
export var sandBackg = "style-module--sandBackg--400ff";