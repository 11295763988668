import React from "react"

function RightIcon({ color, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.5"
      height="15.484"
      viewBox="0 0 31.5 15.484"
    >
      <path
        fill={color}
        className={className}
        d="M9.426 20.813h21.23a.844.844 0 00.844-.844v-3.938a.844.844 0 00-.844-.844H9.426v-3.238a1.688 1.688 0 00-2.881-1.193L.494 16.807a1.687 1.687 0 000 2.386l6.051 6.051a1.688 1.688 0 002.881-1.193z"
        transform="rotate(180 15.75 12.871)"
      ></path>
    </svg>
  )
}

export default RightIcon
