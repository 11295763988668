/* eslint-disable */
import React, { useState } from "react"
import PropTypes from "prop-types"
import { sbEditable } from "@storyblok/storyblok-editable"
/* eslint-disable import/no-cycle */
import { FaChevronRight, FaChevronDown } from "react-icons/fa"
import DynamicComponents from "../DynamicComponents"

/* eslint-disable camelcase */
const FooterSection = ({ blok }) => {
  const { name, menu_items } = blok
  const [mobileActive, setMobileActive] = useState(false)

  const onMobileActivate = e => {
    e.preventDefault()

    setMobileActive(!mobileActive)
  }

  return (
    <div className="md:min-w-[150px] md:mx-4">
      <ul className="flex flex-col w-full mr-0">
        <button
          type="button"
          onClick={onMobileActivate}
          className={`flex flex-row w-full justify-between items-center md:justify-start md:m-auto ${
            mobileActive ? "" : "border-b-[1px] border-[#686868] md:border-0"
          }`}
        >
          <h3 className="text-white mt-4 mb-6 text-[16px]">{name}</h3>
          <span className="text-white text-lg mr-4 md:hidden">
            {mobileActive ? <FaChevronDown /> : <FaChevronRight />}
          </span>
        </button>
        <div
          className={`${
            mobileActive
              ? "block border-b-[1px] border-[#686868] pb-2 md:pb-auto md:border-0"
              : "hidden"
          } md:block`}
        >
          {menu_items
            ? menu_items.map(miniBlok => (
                <div
                  className="w-full md:w-auto"
                  // eslint-disable-next-line
                  {...sbEditable(miniBlok)}
                >
                  <DynamicComponents blok={miniBlok} key={miniBlok._uid} />{" "}
                  {/* eslint-disable-line */}
                </div>
              ))
            : null}
        </div>
      </ul>
    </div>
  )
}

FooterSection.propTypes = {
  blok: PropTypes.shape({
    name: PropTypes.string,
    menu_items: PropTypes.arrayOf(
      PropTypes.shape({
        _uid: PropTypes.string,
      }),
    ),
  }),
}

FooterSection.defaultProps = {
  blok: {
    name: "FOOTER SECTION NAME IS MISSING",
    menu_items: [
      {
        _uid: "NA",
      },
    ],
  },
}

export default FooterSection
