/* eslint-disable */

import React, { FC } from "react"
import { Link } from "gatsby"
import LoginBanner from "../components/LoginBanner"
import * as style from "./welcome.module.scss"
import { useLocation } from "@reach/router"

const Welcome: FC<RoutedHTMLAttr> = () => {
  const location = useLocation()

  return (
    <div className={style.profileUserCredWrapper}>
      <LoginBanner />
      <div className="relative w-full md:w-2/5 py-0 md:py-6">
        <div className="relative w-full">
          <div className={style.profileCredWrapper}>
            <div className={style.profileCredFormOuterWrapper}>
              <h1 className={style.profileCredFormTitleLanding}>
                Welcome to
                <br />
                Polarpro.
              </h1>
              <div className={style.profileCredLandingWrapper}>
                <p>
                  <Link
                    to={`/signup/${location.search ? location.search : ""}`}
                    className={`${style.registerBtn} ${style.btn} w-full rounded`}
                  >
                    CREATE ACCOUNT
                  </Link>
                </p>
                <p>
                  <Link
                    to={`/login/${location.search ? location.search : ""}`}
                    className={`${style.btn} w-full rounded`}
                  >
                    LOGIN
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Welcome
