import React from "react"

interface MsgProps {
  msg: string
}

const FormError = ({ msg }: MsgProps) => (
  <div className="text-red-500 bg-red-50 rounded-lg text-xs p-1 mt-1 font-condensed text-center">
    {msg}
  </div>
)

export default FormError
