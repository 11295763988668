/* eslint-disable */

import React, { useState, useEffect } from "react"
import Seo from "../../components/seo"
import loadable from "@loadable/component"
import useScrollBlock from "../../hooks/useScrollBlock"
import * as style from "./elementsApparelOverview.module.scss"

const ModalVideo = loadable(() => import("react-modal-video"))

const ElementsApparelOverview = () => {
  const [showVideo, setShowVideo] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    if (showVideo) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [showVideo])

  return (
    <>
      <Seo title="Elements Apparel Overview" />
      <div className={style.elementsApparel}>
        <div className={style.tileSection}>
          <img
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Elements-Collection-Title-Desktop.png?v=1635476968"
            alt="The Elements Collection"
            className={style.elementsCollectionLogo}
          />
          <div class={style.tileContainer}>
            <a
              href="/products/short-sleeve-tech-tee"
              className={`${style.tile} ${style.shortSleeve}`}
            ></a>
            <a
              href="/products/long-sleeve-tech-tee"
              className={`${style.tile} ${style.longSleeve}`}
            ></a>
            <a
              href="/products/tonopah-tech-flannel"
              className={`${style.tile} ${style.techFlannel}`}
            ></a>
            <a
              href="/products/kenai-fleece"
              className={`${style.tile} ${style.fleece}`}
            ></a>
            <a
              href="/products/gullfoss-rain-shell"
              className={`${style.tile} ${style.rainShell}`}
            ></a>
            <a
              href="/products/faroe-puffy-jacket"
              className={`${style.tile} ${style.puffyJacket}`}
            ></a>
          </div>
        </div>

        <div
          className={`${style.featureVideo} ${style.videoContainer} ${style.elementsVideo}`}
        >
          <a
            href="#data"
            id="inline"
            className="play-button x-y-center"
            onClick={e => {
              e.preventDefault()
              setShowVideo(true)
            }}
          />
          {/* <!-- Modal --> */}
          <ModalVideo
            channel="vimeo"
            videoId="641249380"
            isOpen={showVideo}
            onClose={() => setShowVideo(false)}
          />
        </div>

        <div className={style.bannerHighlight}>
          <div className={style.data}>
            <img
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Blend-In-Stand-Out-Desktop.png?v=1635469822"
              alt=""
            />
            <p>
              Connecting with nature is at our brand's core. We always strive to
              stay in touch with what it feels like being in our element,
              creating content that feels as visceral as being there. Over the
              years, we've made gear enabling our photo and video community to
              share that passion for capturing content in demanding situations.
              These core values have led us here; with a carefully crafted
              palette and thoughtfully designed clothing line to help you blend
              in with your environment, or stand out in striking contrast. Push
              boundaries and venture further.
            </p>
          </div>
        </div>

        <div className={style.fullLine}>
          <div className={`${style.baseLayers} ${style.layersSection}`}>
            <h3 className={`${style.sectionTitle} display-font-logo font-bold`}>
              Base Layers
            </h3>
            <div className={style.sideBySide}>
              <div className={`${style.item} ${style.techTee}`}>
                <a
                  href="/products/short-sleeve-tech-tee"
                  className={`${style.shopBtn} font-bold`}
                >
                  Shop Short Sleeve Tech Tee
                </a>
              </div>
              <div className={`${style.item} ${style.techTeeLs}`}>
                <a
                  href="/products/long-sleeve-tech-tee"
                  className={`${style.shopBtn} font-bold`}
                >
                  Shop Long Sleeve Tech Tee
                </a>
              </div>
            </div>
            <p className={style.blurp}>
              Our new tech tees are built to move and keep you cool in the most
              demanding shooting environments. Ideal for travel, backpacking,
              and high output activities, these 80% recycled polyester + 20%
              Merino wool tees are strong and ultra-lightweight, keeping you
              cool and comfortable for days on end. This material works great as
              a base layer for colder environments, or an outer layer in warm
              climates. The quick-wicking material keeps you dry, and the
              standard/longer cut provides plenty of freedom to move.
            </p>
          </div>

          <div className={`${style.midLayers} ${style.layersSection}`}>
            <h3 className={`${style.sectionTitle} display-font-logo font-bold`}>
              Mid Layers
            </h3>
            <div className={style.sideBySide}>
              <div className={`${style.item} ${style.tonopah}`}>
                <a
                  href="/products/tonopah-tech-flannel"
                  className={`${style.shopBtn} font-bold`}
                >
                  Shop Tonopah
                </a>
              </div>
              <div className={`${style.item} ${style.kenai}`}>
                <a
                  href="/products/kenai-fleece"
                  className={`${style.shopBtn} font-bold`}
                >
                  Shop Kenai
                </a>
              </div>
            </div>
            <p className={style.blurp}>
              The Tonopah Tech Flannel delivers added warmth and utility while
              maintaining durability and comfort in a midweight, Merino wool
              blend designed to insulate in cooler climates. For those looking
              for something a bit better suited for layering, the Kenai Fleece
              is the way to go. This 100% polyester fleece makes for the perfect
              mid-layer, whether trekking through epic Alaskan terrain or just
              hanging out by the campfire, this fleece will keep you comfortable
              anywhere your adventures takes you.
            </p>
          </div>

          <div className={`${style.outerLayers} ${style.layersSection}`}>
            <h3 className={`${style.sectionTitle} display-font-logo font-bold`}>
              Outer Layers
            </h3>
            <div className={style.sideBySide}>
              <div className={`${style.item} ${style.gullfoss}`}>
                <a
                  href="/products/gullfoss-rain-shell"
                  className={`${style.shopBtn} font-bold`}
                >
                  Shop Gullfoss
                </a>
              </div>
              <div className={`${style.item} ${style.faroe}`}>
                <a
                  href="/products/faroe-puffy-jacket"
                  className={`${style.shopBtn} font-bold`}
                >
                  Shop Faroe
                </a>
              </div>
            </div>
            <p className={style.blurp}>
              The Gullfoss rain shell and Faroe puffy make up the ultimate
              outerwear arsenal, keeping you warm and dry when conditions are at
              their very worst. A 2.5L shell, Gullfoss features a 20k waterproof
              and breathability rating, ensuring you stay dry as you work,
              photograph, and adventure through epic terrain. The Faroe puffy
              features 300g Primaloft Silver insulation and DWR coating,
              ensuring you stay warm and dry in snow or light rain.
            </p>
          </div>
          <a
            href="/collections/elements-collection"
            className={`${style.exploreAll} font-bold`}
          >
            Explore the full line
          </a>
        </div>
      </div>
    </>
  )
}

export default ElementsApparelOverview
