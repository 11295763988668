/* eslint-disable */
import React, { useState, useEffect } from "react"
// import { Link } from "gatsby";
import loadable from "@loadable/component"
import * as style from "./style.module.scss"
import "./style.scss"
import { useStaticQuery, graphql } from "gatsby"
import { sbEditable } from "@storyblok/storyblok-editable" // eslint-disable-line
import { useStoryblok } from "../../utils/storyblok"
import DynamicComponents from "../../components/DynamicComponents"

import useScrollBlock from "../../hooks/useScrollBlock"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import ReconVndVisualizer from "../Dopple/ReconVnd"

const ModalVideo = loadable(() => import("react-modal-video"))

const BelowReconVndOverview = () => {
  const [showVideo, setShowVideo] = useState(false)
  const [showVideo2, setShowVideo2] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  }

  const scrollUp = e => {
    e.preventDefault()
    window.scrollTo({
      top: 0,
      behaviour: "smooth",
    })
  }

  useEffect(() => {
    if (showVideo || showVideo2) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [showVideo, showVideo2])

  const pageData = useStaticQuery(graphql`
    query {
      storyblokEntry(full_slug: { eq: "/products/recon-vnd-matte-box" }) {
        id
        name
        content
        slug
        full_slug
        uuid
        created_at
      }
      shopifyProduct(handle: { eq: "recon-vnd-matte-box" }) {
        handle
        title
      }
    }
  `)

  const product = pageData.shopifyProduct

  const story = pageData.storyblokEntry
    ? useStoryblok(pageData.storyblokEntry)
    : { content: null }

  const stickyNavComponent =
    story.content && story.content["sticky_nav"]
      ? story.content["sticky_nav"].map(blok => (
          <>
            {blok ? (
              <div {...sbEditable(blok)} className="sticky top-0 z-50">
                <DynamicComponents
                  blok={blok}
                  product={product}
                  key={blok._uid}
                />
              </div>
            ) : null}
          </>
        ))
      : null

  // add event listener for next config cta btn
  useEffect(() => {
    document
      .getElementsByClassName("nextConfigBtn")[0]
      .addEventListener("click", function (e) {
        e.preventDefault()
        let elem = document.querySelector(
          "div.six-config .react-multiple-carousel__arrow--right",
        )
        elem.click()
      })
  })

  return (
    <div className={`${style.reconVndOverview} overflow-hidden`}>
      <h3
        className={`${style.sectionTitleRecon} display-font-logo font-bold section-title text-obsidian mt-20`}
      >
        <span className="tracking-normal font-black text-obsidian ">
          Explore Recon
        </span>
      </h3>
      <div class={`${style.productHero} text-white container-wrapper`}>
        <div class={`${style.introData}`}>
          <h2 className="display-font-logo font-bold">Recon | VND Matte Box</h2>
          <p>
            Powerful light control and unrivaled setup time at just a fraction
            of the footprint, Recon revolutionizes the space between a full-size
            matte box and standalone VND filter.
          </p>
        </div>
      </div>

      <div class={style.videoContainer}>
        {/* <a
          href="#data"
          id="inline"
          className="cta-round play-button x-y-center"
          onClick={(e) => {
            e.preventDefault();
            setShowVideo(true);
          }}
        /> */}
        <div className="block lg:flex lg:flex-row m-auto justify-center w-[45%] lg:w-full absolute left-0 right-0 bottom-[15%] lg:bottom-[3vw] xl:bottom-[4vw] gap-0 md:gap-8">
          <a
            href="#data"
            onClick={e => {
              e.preventDefault()
              setShowVideo(true)
            }}
            className="border-solid border-2 text-white block lg:inline-block px-8 py-2 font-bold text-center font-expanded hover:bg-[#ffffff1a] mt-10 uppercase w-full lg:w-[230px] text-[.6rem] pb-[7px]"
          >
            WATCH VIDEO
          </a>
          <a
            href="#"
            onClick={scrollUp}
            className="border-solid border-2 text-white border-paloverde hover:bg-paloverde text-center block lg:inline-block px-8 py-2 font-bold font-expanded mt-4 lg:mt-10 uppercase w-full lg:w-[230px] text-[.6rem] pb-[7px]"
          >
            BUY NOW
          </a>
        </div>

        {/* modal */}
        <ModalVideo
          channel="youtube"
          videoId="VZrBB_oLGAA"
          isOpen={showVideo}
          onClose={() => setShowVideo(false)}
        />
      </div>

      <div className="fast-versatility max-w-full lg:max-w-[64vw] m-auto">
        <h3 className="display-font-logo font-bold section-title text-obsidian mt-[100px] lg:mt-20">
          <span className="!leading-[20px] text-lg tracking-normal font-black  text-obsidian">
            Lightning-Fast Setup, Unmatched Versatility
          </span>
        </h3>

        <div className="flex items-center flex-col-reverse lg:flex-row-reverse">
          <div className="py-14 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black font-expanded uppercase text-3xl lg:text-base xl:text-xl 2xl:text-3xl px-[2vw] text-center lg:text-left text-obsidian mb-2">
              THREAD-ON VND OR BASE PLATE
            </p>
            <p className="hidden 4xl:block text-center lg:text-sm lg:text-left font-condensed px-[2vw] text-obsidian">
              Recon was specifically engineered to eliminate setup
              <br /> time and get you from packed to shooting in seconds. <br />
              Once the VND Base or Thread Base is installed, <br />
              you're ready to get filming.
            </p>
            <p className="block 4xl:hidden text-center lg:text-sm lg:text-left font-condensed px-[2vw] text-obsidian">
              Recon was specifically engineered to eliminate setup time and get
              you from packed to shooting in seconds. Once the VND Base or
              Thread Base is installed, you're ready to get filming.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-Features-Block-1-Desktop.webp?v=1670887939"
              alt=""
            />
            <img
              class="block lg:hidden w-full m-0"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-Features-Block-1-Mobile.webp?v=1670888231"
              alt=""
            />
          </div>
        </div>

        <div className="flex items-center flex-col-reverse lg:flex-row">
          <div className="py-14 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black font-expanded uppercase text-3xl lg:text-base xl:text-xl 2xl:text-3xl px-[2vw] text-center lg:text-left text-obsidian mb-2">
              SNAP-ON <br />
              MATTE BOX
            </p>
            <p className="hidden 4xl:block text-center lg:text-sm lg:text-left font-condensed px-[2vw] text-obsidian">
              The ultra-compact hood and flag can transform Recon <br />
              into a compact and powerful matte box system. Utilizing <br />a
              quick-release lever, the hood can be deployed or <br />
              stowed away instantly.
            </p>
            <p className="block 4xl:hidden text-center lg:text-sm lg:text-left font-condensed px-[2vw] text-obsidian">
              The ultra-compact hood and flag can transform Recon into a compact
              and powerful matte box system. Utilizing a quick-release lever,
              the hood can be deployed or stowed away instantly.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-Features-Block-2-Desktop.webp?v=1670887939"
              alt=""
            />
            <img
              class="block lg:hidden w-full m-0"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-Features-Block-2-Mobile.webp?v=1670888232"
              alt=""
            />
          </div>
        </div>

        <div className="flex items-center flex-col-reverse lg:flex-row-reverse">
          <div className="py-14 lg:py-0 px-5 lg:px-0 w-full flex lg:w-1/3 flex-col items-left justify-center bg-cover lg:h-auto lg:bg-none">
            <p className="font-black font-expanded uppercase text-3xl lg:text-base xl:text-xl 2xl:text-3xl px-[2vw] text-center lg:text-left text-obsidian mb-2">
              ADD STAGE <br />2 FILTERS
            </p>
            <p className="hidden 4xl:block text-center lg:text-sm lg:text-left font-condensed px-[2vw] text-obsidian">
              Revolutionizing the way filters are handled, <br />
              Recon's filter design allows for wicked-fast <br />
              filter swaps without ever worrying about laying <br />a finger on
              the glass.
            </p>
            <p className="block 4xl:hidden text-center lg:text-sm lg:text-left font-condensed px-[2vw] text-obsidian">
              Revolutionizing the way filters are handled, Recon's filter design
              allows for wicked-fast filter swaps without ever worrying about
              laying a finger on the glass.
            </p>
          </div>
          <div class="w-full lg:w-2/3">
            <img
              class="hidden lg:block"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-Features-Block-3-Desktop.webp?v=1670887939"
              alt=""
            />
            <img
              class="block lg:hidden w-full m-0"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-Features-Block-3-Mobile.webp?v=1670888232"
              alt=""
            />
          </div>
        </div>

        <div class="block mb-8">
          <video
            autoPlay={true}
            muted={true}
            loop={true}
            playsInline={true}
            className="hidden lg:block"
          >
            <source src="https://cdn.shopify.com/videos/c/o/v/5ce6022e47f042f1bbc715e7206e814f.mp4" />
          </video>

          <video
            autoPlay={true}
            muted={true}
            loop={true}
            playsInline={true}
            className="block lg:hidden"
          >
            <source src="https://cdn.shopify.com/videos/c/o/v/047edc8d4c284067aef6a7bb5f2711fc.mp4" />
          </video>
        </div>
      </div>

      <div className="six-config bg-black pb-0 lg:pb-20 pt-1">
        <h3 className="display-font-logo font-bold section-title text-white mt-20">
          <span className="tracking-normal font-black text-white">
            Six Powerful Configurations
          </span>
        </h3>
        <div className="max-w-full lg:max-w-[64vw] m-auto">
          <div class="block relative">
            <div id={style.sixPowerfulConfig}>
              <Carousel
                responsive={responsive}
                swipeable={false}
                draggable={false}
                showDots={true}
                infinite={true}
                renderDotsOutside={true}
                containerClass={`${style.sixConfig} sixConfigArrow reconCustomSlider`}
                dotListClass={`${style.reconCustomDotSlider} ${style.sixConfig} reconCustomDotSlider`}
              >
                <div className={`${style.item} flex flex-col lg:flex-row`}>
                  <div className="text-white w-2/5 p-0 flex flex-col justify-center">
                    <p className="text-paloverde font-black font-condensed text-5xl border-[#272727] border-bottom border-b-2 relative lg:absolute top-0 w-[65vw] lg:w-[20vw] mx-10 lg:mx-0">
                      01
                    </p>
                    <p className="hidden lg:block font-black font-expanded text-3xl m-0">
                      VND ONLY
                    </p>
                    <p className="hidden lg:block text-xs">
                      This setup utilizes the VND 3-6+ [Core] by itself.
                    </p>
                  </div>
                  <div className="w-full lg:w-3/5">
                    <video
                      autoPlay={true}
                      muted={true}
                      loop={true}
                      playsInline={true}
                      className="block"
                    >
                      <source src="https://cdn.shopify.com/videos/c/o/v/791515ee53a94234bca6ebce6a0b56fb.mp4" />
                    </video>
                  </div>
                  <div className="text-white px-10 block lg:hidden py-32">
                    <p className="font-black font-expanded text-3xl m-0">
                      VND ONLY
                    </p>
                    <p className="text-xs">
                      This setup utilizes the VND 3-6+ [Core] by itself.
                    </p>
                  </div>
                </div>

                <div className={style.item}>
                  <div className={`${style.item} flex flex-col lg:flex-row`}>
                    <div className="text-white w-2/5 p-0 flex flex-col justify-center">
                      <p className="text-paloverde font-black font-condensed text-5xl border-[#272727] border-bottom border-b-2 relative lg:absolute top-0 w-[65vw] lg:w-[20vw] mx-10 lg:mx-0">
                        02
                      </p>
                      <p className="hidden lg:block font-black font-expanded text-3xl m-0">
                        VND + FX
                      </p>
                      <p className="hidden lg:block text-xs">
                        This setup utilizes the VND 3-6+ [Core] and Stage 2
                        filters.
                      </p>
                    </div>
                    <div className="w-full lg:w-3/5">
                      <video
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        playsInline={true}
                        className="block"
                      >
                        <source src="https://cdn.shopify.com/videos/c/o/v/680b64ffce51459da514bb2c41894c13.mp4" />
                      </video>
                    </div>
                    <div className="text-white px-10 block lg:hidden py-32">
                      <p className="font-black font-expanded text-3xl m-0">
                        VND + FX
                      </p>
                      <p className="text-xs">
                        This setup utilizes the VND 3-6+ [Core] and Stage 2
                        filters.
                      </p>
                    </div>
                  </div>
                </div>

                <div className={style.item}>
                  <div className={`${style.item} flex flex-col lg:flex-row`}>
                    <div className="text-white w-2/5 p-0 flex flex-col justify-center">
                      <p className="text-paloverde font-black font-condensed text-5xl border-[#272727] border-bottom border-b-2 relative lg:absolute top-0 w-[65vw] lg:w-[20vw] mx-10 lg:mx-0">
                        03
                      </p>
                      <p className="hidden lg:block font-black font-expanded text-3xl m-0">
                        VND + FX + MATTE BOX
                      </p>
                      <p className="hidden lg:block text-xs">
                        This setup utilizes the VND 3-6+ [Core], Stage 2
                        filters, and the Hood/Flag.
                      </p>
                    </div>
                    <div className="w-full lg:w-3/5">
                      <video
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        playsInline={true}
                        className="block"
                      >
                        <source src="https://cdn.shopify.com/videos/c/o/v/9d2fcf1722f9432b9a79c5733efd3383.mp4" />
                      </video>
                    </div>
                    <div className="text-white px-10 block lg:hidden py-32">
                      <p className="font-black font-expanded text-3xl m-0">
                        VND + FX + MATTE BOX
                      </p>
                      <p className="text-xs">
                        This setup utilizes the VND 3-6+ [Core], Stage 2
                        filters, and the Hood/Flag.
                      </p>
                    </div>
                  </div>
                </div>

                <div className={style.item}>
                  <div className={`${style.item} flex flex-col lg:flex-row`}>
                    <div className="text-white w-2/5 p-0 flex flex-col justify-center">
                      <p className="text-paloverde font-black font-condensed text-5xl border-[#272727] border-bottom border-b-2 relative lg:absolute top-0 w-[65vw] lg:w-[20vw] mx-10 lg:mx-0">
                        04
                      </p>
                      <p className="hidden lg:block font-black font-expanded text-3xl m-0">
                        THREAD BASE + FX
                      </p>
                      <p className="hidden lg:block text-xs">
                        This setup utilizes the Thread Base and Stage 2 filters.
                      </p>
                    </div>
                    <div className="w-full lg:w-3/5">
                      <video
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        playsInline={true}
                        className="block"
                      >
                        <source src="https://cdn.shopify.com/videos/c/o/v/e8927042de454d9396e543cdc6b2689e.mp4" />
                      </video>
                    </div>
                    <div className="text-white px-10 block lg:hidden py-32">
                      <p className="font-black font-expanded text-3xl m-0">
                        THREAD BASE + FX
                      </p>
                      <p className="text-xs">
                        This setup utilizes the Thread Base and Stage 2 filters.
                      </p>
                    </div>
                  </div>
                </div>

                <div className={style.item}>
                  <div className={`${style.item} flex flex-col lg:flex-row`}>
                    <div className="text-white w-2/5 p-0 flex flex-col justify-center">
                      <p className="text-paloverde font-black font-condensed text-5xl border-[#272727] border-bottom border-b-2 relative lg:absolute top-0 w-[65vw] lg:w-[20vw] mx-10 lg:mx-0">
                        05
                      </p>
                      <p className="hidden lg:block font-black font-expanded text-3xl m-0">
                        THREAD BASE + FX + MATTE BOX
                      </p>
                      <p className="hidden lg:block text-xs">
                        This setup utilizes the Thread Base, Stage 2 filters,
                        and the Hood/Flag.
                      </p>
                    </div>
                    <div className="w-full lg:w-3/5">
                      <video
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        playsInline={true}
                        className="block"
                      >
                        <source src="https://cdn.shopify.com/videos/c/o/v/712212a2afcd488c90e54488f9a9d49b.mp4" />
                      </video>
                    </div>
                    <div className="text-white px-10 block lg:hidden py-32">
                      <p className="font-black font-expanded text-3xl m-0">
                        THREAD BASE + FX + MATTE BOX
                      </p>
                      <p className="text-xs">
                        This setup utilizes the Thread Base, Stage 2 filters,
                        and the Hood/Flag.
                      </p>
                    </div>
                  </div>
                </div>

                <div className={style.item}>
                  <div className={`${style.item} flex flex-col lg:flex-row`}>
                    <div className="text-white w-2/5 p-0 flex flex-col justify-center">
                      <p className="text-paloverde font-black font-condensed text-5xl border-[#272727] border-bottom border-b-2 relative lg:absolute top-0 w-[65vw] lg:w-[20vw] mx-10 lg:mx-0">
                        06
                      </p>
                      <p className="hidden lg:block font-black font-expanded text-3xl m-0">
                        THREAD BASE + MATTE BOX
                      </p>
                      <p className="hidden lg:block text-xs">
                        This setup utilizes the Thread Base and the Hood/Flag.
                      </p>
                    </div>
                    <div className="w-full lg:w-3/5">
                      <video
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        playsInline={true}
                        className="block"
                      >
                        <source src="https://cdn.shopify.com/videos/c/o/v/48a1876207194548b58dfec3b6f9ecce.mp4" />
                      </video>
                    </div>
                    <div className="text-white px-10 block lg:hidden py-32">
                      <p className="font-black font-expanded text-3xl m-0">
                        THREAD BASE + MATTE BOX
                      </p>
                      <p className="text-xs">
                        This setup utilizes the Thread Base and the Hood/Flag.
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel>
              {/* custom next cta btn */}
              <a
                className={`${style.nextConfigBtn} nextConfigBtn text-white hover:text-paloverde absolute left-0 bottom-0 font-bold font-condensed`}
                href="#"
              >
                NEXT CONFIGURATION{" "}
              </a>
            </div>
            {/* six powerful config img carousel end*/}
          </div>
        </div>
      </div>

      <div className="two-base-platform px-[15px] lg:px-0 max-w-full lg:max-w-[64vw] m-auto mb-[200px]">
        <h3 className="display-font-logo font-bold section-title text-obsidian mt-[100px] lg:mt-20">
          <span className="tracking-normal font-black text-obsidian">
            Two Base Platforms
          </span>
        </h3>

        <div className="block lg:flex flex-row-reverse items-center border-bottom border-b-2 pb-20 pr-0 lg:pr-[2vw]">
          <div className="block sm:w-full flex lg:w-1/2 flex-col items-left justify-center pl-0 lg:pl-[3vw]">
            <p className="uppercase bg-paloverde inline-block text-white font-condensed font-bold px-4 pt-2 pb-[4px] text-sm rounded-3xl w-fit">
              Base 1 Platform
            </p>
            <p className="font-black font-expanded uppercase text-4xl lg:text-2xl 2xl:text-3xl text-left text-obsidian mb-2">
              VND 3-6+ <span className="font-normal">[core]</span>
            </p>
            <p className="hidden 4xl:block text-left text-obsidian text-sm font-condensed">
              The 82mm VND 3-6+ filter can be thought of as the “core” of the
              Recon system. <br />
              Featuring 3-6 Stops with haptic feedback for focal lengths at or
              below 35mm. <br />
              Recon can also be pushed to 7.5 Stops at focal lengths above 35mm.
            </p>
            <p className="block 4xl:hidden text-left text-obsidian text-sm font-condensed">
              The 82mm VND 3-6+ filter can be thought of as the “core” of the
              Recon system. Featuring 3-6 Stops with haptic feedback for focal
              lengths at or below 35mm. Recon can also be pushed to 7.5 Stops at
              focal lengths above 35mm.
            </p>
            <p className="border-bottom border-b-2 inline-block font-black mt-4 mb-4">
              DETAILS / FEATURES
            </p>
            <ul className="font-condensed text-sm font-bold m-0">
              <li className={`${style.reconList} m-0`}>
                ◉ Thread Size: <span className="text-paloverde">82mm</span>
              </li>
              <li className={`${style.reconList} m-0`}>
                ◉ Stop Range (at/below 35mm):{" "}
                <span className="text-paloverde">3-6</span>
              </li>
              <li className={`${style.reconList} m-0`}>
                ◉ Stop Range (above 35mm):{" "}
                <span className="text-paloverde">6-7.5</span>
              </li>
              <li className={`${style.reconList} m-0`}>
                ◉ Accepts Recon Stage 2 Filters:{" "}
                <span className="text-paloverde">Yes</span>
              </li>
              <li className={`${style.reconList} m-0`}>
                ◉ Accepts Recon Hood+Flag:{" "}
                <span className="text-paloverde">Yes</span>
              </li>
              <li className={`${style.reconList} m-0`}>
                ◉ Haptic Feedback:{" "}
                <span className="text-paloverde">Yes, at 3 and 6</span>
              </li>
              <li className={`${style.reconList} m-0`}>
                ◉ Includes Front/Back Defender Protection:{" "}
                <span className="text-paloverde">Yes</span>
              </li>
            </ul>
          </div>
          <div class="block lg:w-1/2 mt-10 lg:mt-0 relative">
            <div id={style.base1Slider}>
              <Carousel
                responsive={responsive}
                swipeable
                draggable
                showDots={true}
                infinite={true}
                renderDotsOutside={true}
                containerClass={`${style.base1Slider} reconCustomSlider`}
                dotListClass={`${style.reconCustomDotSlider} ${style.base1Slider} reconCustomDotSlider`}
              >
                <div className={style.item}>
                  <img
                    class=""
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-VND-Platform-Carousel-Image-1.webp?v=1671055855"
                    alt=""
                  />
                </div>
                <div className={style.item}>
                  <img
                    class=""
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-VND-Platform-Carousel-Image-2.webp?v=1671055855"
                    alt=""
                  />
                </div>
                <div className={style.item}>
                  <img
                    class=""
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-VND-Platform-Carousel-Image-3.webp?v=1671055855"
                    alt=""
                  />
                </div>
                <div className={style.item}>
                  <img
                    class=""
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-VND-Platform-Carousel-Image-4.webp?v=1671055855"
                    alt=""
                  />
                </div>
                <div className={style.item}>
                  <img
                    class=""
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-VND-Platform-Carousel-Image-5.webp?v=1671055855"
                    alt=""
                  />
                </div>
                <div className={style.item}>
                  <img
                    class=""
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-VND-Platform-Carousel-Image-6.webp?v=1671055855"
                    alt=""
                  />
                </div>
              </Carousel>
            </div>
          </div>
        </div>

        <div className="block lg:flex items-center pt-24">
          <div className="block sm:w-full flex lg:w-1/2 flex-col items-left justify-center pr-0 lg:pr-[3vw] pl-0 lg:pl-[2vw]">
            <p className="uppercase bg-paloverde inline-block text-white font-condensed font-bold px-4 pt-2 pb-[4px] text-sm rounded-3xl w-fit">
              Base 2 Platform
            </p>
            <p className="font-black font-expanded uppercase text-4xl lg:text-2xl 2xl:text-3xl text-left text-obsidian mb-2">
              THREAD BASE
            </p>
            <p className="hidden 4xl:block text-left text-obsidian text-sm font-condensed">
              Recon can be utilized without the VND3-6+ Filter in this
              configuration. <br />
              The 82mm Thread Base acts as a mounting point for Stage 2 Filters,
              as <br />
              well as the Hood + Flag for an ultra-minimal matte box setup.
            </p>
            <p className="block 4xl:hidden text-left text-obsidian text-sm font-condensed">
              Recon can be utilized without the VND3-6+ Filter in this
              configuration. The 82mm Thread Base acts as a mounting point for
              Stage 2 Filters, as well as the Hood + Flag for an ultra-minimal
              matte box setup.
            </p>
            <p className="border-bottom border-b-2 inline-block font-black mt-4 mb-4">
              DETAILS / FEATURES
            </p>
            <ul className="font-condensed text-sm font-bold m-0">
              <li className={`${style.reconList} m-0`}>
                ◉ Thread Size: <span className="text-paloverde">82mm</span>
              </li>
              <li className={`${style.reconList} m-0`}>
                ◉ Accepts Recon Stage 2 Filters:{" "}
                <span className="text-paloverde">Yes</span>
              </li>
              <li className={`${style.reconList} m-0`}>
                ◉ Accepts Recon Hood + Flag:{" "}
                <span className="text-paloverde">Yes</span>
              </li>
            </ul>
          </div>
          <div class="block lg:w-1/2 pl-0 mt-10 lg:mt-0 relative">
            <div id={style.base2Slider}>
              <Carousel
                responsive={responsive}
                swipeable
                draggable
                showDots={true}
                infinite={true}
                renderDotsOutside={true}
                containerClass={`${style.base2Slider} reconCustomSlider`}
                dotListClass={`${style.reconCustomDotSlider} ${style.base2Slider} reconCustomDotSlider`}
              >
                <div className={style.item}>
                  <img
                    class=""
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-Thread-Base-Carousel-Image-1.webp?v=1671055644"
                    alt=""
                  />
                </div>

                <div className={style.item}>
                  <img
                    class=""
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-Thread-Base-Carousel-Image-2.webp?v=1671055644"
                    alt=""
                  />
                </div>

                <div className={style.item}>
                  <img
                    class=""
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-Thread-Base-Carousel-Image-3.webp?v=1671055644"
                    alt=""
                  />
                </div>

                <div className={style.item}>
                  <img
                    class=""
                    src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-Thread-Base-Carousel-Image-4.webp?v=1671055644"
                    alt=""
                  />
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>

      <div class="features-banner">
        <h3 className="display-font-logo font-bold section-title text-obsidian mt-[100px] lg:mt-20">
          <span className="tracking-normal  text-obsidian">
            System Features
          </span>
        </h3>
        <div class={style.highlightContainer}>
          <img
            class="desktop-only"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/RECON-VND3-6-FEATURES-DESKTOP.webp?v=1670886413"
            alt="RECON VND3-6 FEATURES"
          />
          <img
            class="mobile-only w-full"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/RECON-VND3-6-FEATURES-MOBILE.webp?v=1670886385"
            alt="RECON VND3-6 FEATURES"
          />

          <img
            class="desktop-only"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/RECON-STAGE-2-FILTERS-FEATURES-DESKTOP.webp?v=1670886413"
            alt="RECON STAGE-2 FILTERS"
          />
          <img
            class="mobile-only w-full"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/RECON-STAGE-2-FILTERS-FEATURES-MOBILE.webp?v=1670886385"
            alt="RECONS STAGE-2 FILTERS"
          />

          <img
            class="desktop-only"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/RECON-DEFENDER-FEATURES-DESKTOP.webp?v=1670886413"
            alt="RECON-DEFENDER"
          />
          <img
            class="mobile-only w-full"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/RECON-DEFENDER-FEATURES-MOBILE.webp?v=1670886385"
            alt="RECON-DEFENDER"
          />

          <img
            class="desktop-only"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/RECON-HOOD-FLAG-FEATURES-DESKTOP.webp?v=1670886413"
            alt="RECON-HOOD-FLAG"
          />
          <img
            class="mobile-only w-full"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/RECON-HOOD-FLAG-FEATURES-MOBILE.webp?v=1670886385"
            alt="RECON-HOOD-FLAG"
          />
        </div>
      </div>

      <div className="compare">
        <h3 className="display-font-logo font-bold section-title text-obsidian mt-[100px] lg:mt-20">
          <span className="tracking-normal font-black text-obsidian">
            Filter Comparisons
          </span>
        </h3>

        <div className="block lg:flex my-10 items-center">
          <div className="hidden lg:block sm:w-full w-[100vw] flex lg:w-2/5 flex-col items-left justify-center bg-cover h-[92vw] lg:h-auto lg:bg-none">
            <p className="font-black font-expanded text-2xl lg:text-2xl pl-0 lg:pl-[4vw] 2xl:pl-[18vw] text-left text-obsidian mb-2 uppercase">
              BlueMorphic
            </p>
            <p className="text-left pl-[4vw] 2xl:pl-[18vw] text-obsidian text-xs">
              Create stunning anamorphic-style blue streak effects.
            </p>
          </div>
          <div class="hidden lg:block lg:w-3/5">
            <widgetic-embed
              id="63939deeecb2a1736e8b4568"
              resize="fill-width"
              width="2373"
              height="925"
              autoscale="on"
              adaptive="414"
            ></widgetic-embed>
          </div>
          <div class="block lg:hidden sm:w-full">
            <widgetic-embed
              id="63939eacecb2a19e6e8b4568"
              resize="fill-width"
              width="1284"
              height="1788"
              autoscale="on"
              adaptive="414"
            ></widgetic-embed>
          </div>
          <div className="block lg:hidden sm:w-full w-[100vw] items-left justify-center bg-cover lg:bg-none pt-8">
            <p className="font-black font-expanded text-2xl lg:text-2xl pl-0 lg:pl-[4vw] 2xl:pl-[18vw] text-center text-obsidian mb-2 uppercase">
              BlueMorphic
            </p>
            <p className="text-center pl-0 lg:pl-[4vw] 2xl:pl-[18vw] text-obsidian text-xs">
              Create stunning anamorphic-style blue streak effects.
            </p>
          </div>
        </div>

        <div className="block lg:flex flex-row-reverse items-center">
          <div className="hidden lg:block sm:w-full w-[100vw] flex lg:w-2/5 flex-col items-left justify-center bg-cover h-[92vw] lg:h-auto lg:bg-none">
            <p className="font-black font-expanded uppercase text-2xl lg:text-2xl pl-[4vw] text-left text-obsidian mb-2">
              GoldMorphic
            </p>
            <p className="text-left pl-[4vw] text-obsidian text-xs">
              Create stunning anamorphic-style warm streak effects.
            </p>
          </div>
          <div class="hidden lg:block lg:w-3/5">
            <widgetic-embed
              id="63939e36ecb2a17d6e8b456b"
              resize="fill-width"
              width="2374"
              height="925"
              autoscale="on"
              adaptive="414"
            ></widgetic-embed>
          </div>
          <div class="block lg:hidden sm:w-full">
            <widgetic-embed
              id="63939f2fecb2a1bc6e8b4567"
              resize="fill-width"
              width="1284"
              height="1788"
              autoscale="on"
              adaptive="414"
            ></widgetic-embed>
          </div>
          <div className="block lg:hidden sm:w-full w-[100vw] items-left justify-center bg-cover lg:bg-none pt-8">
            <p className="font-black font-expanded uppercase text-2xl lg:text-2xl px-[4vw] text-center text-obsidian mb-2">
              GoldMorphic
            </p>
            <p className="text-center px-[4vw] text-obsidian text-xs">
              Create stunning anamorphic-style warm streak effects.
            </p>
          </div>
        </div>

        <div className="block lg:flex my-10 items-center">
          <div className="hidden lg:block sm:w-full w-[100vw] flex lg:w-2/5 flex-col items-left justify-center bg-cover h-[92vw] lg:h-auto lg:bg-none">
            <p className="font-black font-expanded uppercase text-2xl lg:text-2xl pl-0 lg:pl-[4vw] 2xl:pl-[18vw] text-left text-obsidian mb-2">
              MIST [1/4]
            </p>
            <p className="text-left pl-0 lg:pl-[4vw] 2xl:pl-[18vw] text-obsidian text-xs">
              highlights and gives a subtle bloom <br />
              to light sources. 1/4 diffusion strength.
            </p>
          </div>
          <div class="hidden lg:block lg:w-3/5">
            <widgetic-embed
              id="63939e70ecb2a1916e8b456a"
              resize="fill-width"
              width="2374"
              height="925"
              autoscale="on"
              adaptive="414"
            ></widgetic-embed>
          </div>
          <div class="block lg:hidden sm:w-full">
            <widgetic-embed
              id="63939f60ecb2a1c66e8b4567"
              resize="fill-width"
              width="1284"
              height="1788"
              autoscale="on"
              adaptive="414"
            ></widgetic-embed>
          </div>
          <div className="block lg:hidden sm:w-full w-[100vw] items-left justify-center bg-cover lg:bg-none pt-8">
            <p className="font-black font-expanded uppercase text-2xl lg:text-2xl pl-0 lg:pl-[4vw] 2xl:pl-[18vw] text-center text-obsidian mb-2">
              MIST [1/4]
            </p>
            <p className="text-center pl-0 lg:pl-[4vw] 2xl:pl-[18vw] text-obsidian text-xs">
              Smooths highlights and gives a subtle bloom <br />
              to light sources. 1/4 diffusion strength.
            </p>
          </div>
        </div>
      </div>
      {/* compare end */}

      <div className="explore-prebuilt px-[15px] lg:px-0 max-w-full lg:max-w-[64vw] m-auto text-white">
        <h3 className="display-font-logo font-bold section-title text-obsidian mt-[100px] lg:mt-20">
          <span className="tracking-normal font-black text-obsidian">
            Explore Pre-Built Kits & Components
          </span>
        </h3>
        <div className="baseAndDirector block lg:flex flex-row text-center gap-0 lg:gap-10">
          <div
            className={`${style.baseKit} w-full lg:w-1/2 text-center rounded-2xl p-10 relative pb-[240px] mb-8 lg:mb-0`}
          >
            <p className="title font-black text-3xl md:text-2xl xl:text-3xl mb-2">
              Base Kit
            </p>
            <p className="subTitle text-sm px-0 xl:px-10 hidden lg:block">
              The Base Kit includes everything you need <br />
              to get started with the Recon system.
            </p>
            <p className="subTitle text-sm px-0 xl:px-10 block lg:hidden">
              The Base Kit includes everything you need to get started with the
              Recon system.
            </p>
            <img
              className="m-auto mt-10 mb-6 max-h-[250px] w-full object-contain"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-Kits-Components-Tiles-Base-Kit-Render.webp?v=1670884262"
              alt=""
            />
            <a
              onClick={scrollUp}
              className="border-solid border-2 inline-block px-6 py-2 font-bold font-expanded text-[.6rem] pb-[7px] hover:bg-[#ffffff1a] mt-10 uppercase w-[230px]"
              href="#"
            >
              Shop Base Kit
            </a>
            <div className="included bg-obsidian absolute bottom-0 left-0 right-0 pt-4 pb-8 rounded-b-2xl px-12">
              <p className="font-bold text-lg border-[#272727] border-bottom border-b-2">
                Kit Includes
              </p>
              <p className="hidden lg:block text-sm font-medium font-condensed">
                VND 3-6+ Filter | Thread Base | Aero Hood + Flag <br />
                Defender Protective Cap (Front & Back) | Soft Storage Pouch
              </p>
              <p className="block lg:hidden text-sm font-medium font-condensed">
                VND 3-6+ Filter | Thread Base | Aero Hood + Flag Defender
                Protective Cap (Front & Back) | Soft Storage Pouch
              </p>
            </div>
          </div>
          <div
            className={`${style.directorsKit} w-full lg:w-1/2 text-center rounded-2xl p-10 relative pb-[240px]`}
          >
            <p className="title font-black text-3xl md:text-2xl xl:text-3xl mb-2">
              Directors Kit
            </p>
            <p className="hidden lg:block subTitle text-sm px-0 xl:px-10">
              The Directors Kit includes all the components needed <br />
              for a professional shoot.
            </p>
            <p className="block lg:hidden subTitle text-sm px-0 xl:px-10">
              The Directors Kit includes all the components needed for a
              professional shoot.
            </p>
            <img
              className="m-auto mt-10 mb-6 max-h-[250px] w-full object-contain"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-Kits-Components-Tiles-Directors-Kit-Render.webp?v=1670884262"
              alt=""
            />
            <a
              onClick={scrollUp}
              className="border-solid border-2 inline-block px-4 py-2 font-bold font-expanded text-[.6rem] pb-[7px] hover:bg-[#ffffff1a] mt-10 uppercase w-[230px]"
              href="#"
            >
              Shop Directors Kit
            </a>
            <div className="included bg-obsidian absolute bottom-0 left-0 right-0 pt-4 pb-8 rounded-b-2xl px-12">
              <p className="font-bold text-lg border-[#272727] border-bottom border-b-2">
                Kit Includes
              </p>
              <p className="hidden lg:block text-sm font-medium font-condensed ">
                VND 3-6+ Filter | Mist [1/4] Filter | BlueMorphic Filter |
                Thread Base <br />
                Aero Hood + Flag | Defender Protective Cap (Front & Back) | Soft
                Storage Pouch
              </p>
              <p className="block lg:hidden text-sm font-medium font-condensed ">
                VND 3-6+ Filter | Mist [1/4] Filter | BlueMorphic Filter |
                Thread Base Aero Hood + Flag | Defender Protective Cap (Front &
                Back) | Soft Storage Pouch
              </p>
            </div>
          </div>
        </div>

        <div
          className={`${style.stage2Filters} w-full items-center justify-center text-center rounded-2xl p-10 mt-10 relative pb-[240px]`}
        >
          <p className="title font-black text-3xl md:text-2xl xl:text-3xl mb-2">
            Stage 2 Filters
          </p>
          <p className="subTitle text-sm px-0 xl:px-10">
            Explore Recon's full lineup of filter offerings, from Mist Diffusion
            to Streak FX.
          </p>
          <img
            className="m-auto mt-10 mb-6 max-h-[250px] w-full object-contain"
            src="https://cdn.shopify.com/s/files/1/1050/9944/files/Recon-Kits-Components-Tiles-Stage-2-Filters-Render.webp?v=1670884262"
            alt=""
          />
          <a
            className="border-solid border-2 inline-block px-8 py-2 font-bold font-expanded text-[.6rem] pb-[7px] hover:bg-[#ffffff1a] mt-10 uppercase w-[230px]"
            href="/collections/recon-filters-components"
            target="_blank"
          >
            Shop Filters
          </a>
          <div className="included bg-obsidian absolute bottom-0 left-0 right-0 pt-4 pb-8 px-12 rounded-b-2xl">
            <p className="font-bold text-lg border-[#272727] border-bottom border-b-2">
              Available Filters
            </p>
            <p className="text-sm font-medium font-condensed">
              Mist [1/4] | Mist-H [1/2] | BlueMorphic | GoldMorphic | ND16
            </p>
          </div>
        </div>
      </div>
      {/* explore prebuilt end */}
      <div className="launchCommercial">
        <h3 className="display-font-logo font-bold section-title text-obsidian mt-[100px] lg:mt-20">
          <span className="tracking-normal font-black text-obsidian">
            Launch Commercial
          </span>
        </h3>
        <div
          className={`${style.launchCommercialBg} relative items-center justify-center flex`}
        >
          <a
            href="#data"
            id="system-overview-play-btn"
            className="play-button small"
            onClick={e => {
              e.preventDefault()
              setShowVideo2(true)
            }}
          />
          {/* modal */}
          <ModalVideo
            channel="youtube"
            videoId="XjBzvB3bPQg"
            isOpen={showVideo2}
            onClose={() => setShowVideo2(false)}
          />
        </div>
        <div className="flex flex-col lg:flex-row">
          <div>
            <img
              className="m-auto"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/recon-commercial-image-block-1-desktop.webp?v=1671061713"
              alt=""
            />
          </div>
          <div>
            <img
              className="m-auto"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/recon-commercial-image-block-2-desktop.webp?v=1671061713"
              alt=""
            />
          </div>
          <div>
            <img
              className="m-auto"
              src="https://cdn.shopify.com/s/files/1/1050/9944/files/recon-commercial-image-block-3-desktop.webp?v=1671061713"
              alt=""
            />
          </div>
        </div>
        <a
          href="#"
          onClick={scrollUp}
          className="bg-paloverde hover:bg-[#e2b220] min-w-200px w-[368px] m-auto block text-center mt-16 px-30 pb-[10px] pt-3 font-expanded uppercase text-white font-bold text-xs"
        >
          Buy Now
        </a>
      </div>
    </div>
  )
}

export default BelowReconVndOverview
