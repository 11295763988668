import React from "react"

function FilterAndComponent({ cards, ...props }: any) {
  return (
    <>
      {!!props?.mobile_banner_image?.filename && !!props?.banner_image?.filename && <div className={` bg-black py-28`}>
        <img
          className="w-2/3 m-0 block lg:hidden m-auto"
          src={props?.mobile_banner_image?.filename}
          alt="Exceptional color accuracy"
        />
        <div className={`hidden lg:block`}>
          <img
            className="w-5/6 m-0"
            src={props?.banner_image?.filename}
            alt="Exceptional color accuracyn"
          />
        </div>
      </div>}
      <div
        className={`grid grid-cols-2 auto-cols-fr sm:grid-cols-2 md:grid-cols-3 xl-1600:grid-cols-4 gap-6 md:gap-6 xl:gap-10 bg-black text-center px-[2vw] pb-0${props?.mobile_banner_image?.filename && !!props?.banner_image?.filename ? ' pt-0' : ' pt-0'}`}>
        {cards?.map((item: any, index: any) => (
          <div key={index} className="flex-1">
            <div className="h-full">
              <a className="bg-[#1c1c1c] w-full h-full inline-block text-white p-5 rounded-lg border-transparent transition-all ease-in-out duration-300 border hover:border hover:border-paloverde">
                <span className="block font-black text-2xl">{item?.title}</span>
                <span className="block">{item.price}</span>
                <img
                  src={item?.media?.filename}
                  alt={item?.title}
                  className="m-auto"
                />
              </a>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default FilterAndComponent
